<template>
  <div class="IncidentsMilesWidget ctrack-h-100" ref="IncidentsMiles">
    <div id="vueapp" class="vue-app ctrack-h-100">
        
      <!-- Incidents vs Miles Chart -->
      <div class="cd-chart-innerbox" :class="hideloaderClass">        
        <div class="cd-chart-box-top">
          <div class="cd-chart-box-header">
            <div class="cd-chart-box-col1"><h3>{{widgetTitle}} <span>{{widgetTitleDuration}}</span></h3> 
            </div>
            <div class="cd-chart-box-col2">              
              <!-- <div class="ctrack-select-main ctrack-select-icon2">
                <select class="ctrack-select-box">
                  <option>500 Miles</option>  
                  <option>600 Miles</option>  
                  <option>700 Miles</option>  
                  <option>800 Miles</option>  
                </select>                
              </div> -->               
              <div class="md-view-detail-link" @click="showDrill = true">
                <!-- <i class="material-icons">launch</i> Show Drill Downs -->
                <svg version="1.1" width="20" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 89.64 90.91" style="enable-background:new 0 0 89.64 90.91;" xml:space="preserve">
                  <g>
                    <path d="M5.93,84.99c27.97,0,55.8,0,83.71,0c0,2,0,3.93,0,5.92c-29.87,0-59.73,0-89.64,0C0,60.64,0,30.4,0,0.09
                      c1.96,0,3.91,0,5.93,0C5.93,28.38,5.93,56.61,5.93,84.99z"/>
                    <path d="M67.5,80.38c0-21.98,0-43.9,0-65.87c3.52,0,7,0,10.53,0c0,21.97,0,43.88,0,65.87C74.53,80.38,71.06,80.38,67.5,80.38z"/>
                    <path d="M30.45,25.58c-1.48,1.49-2.94,2.95-4.4,4.42c-2.77,2.77-5.54,5.53-8.3,8.31c-0.93,0.94-2.01,1.29-3.27,0.94
                      c-2.07-0.58-2.86-3.07-1.52-4.76c0.32-0.4,0.71-0.76,1.07-1.12c3.77-3.78,7.54-7.56,11.33-11.31c0.53-0.52,0.66-0.87,0.28-1.62
                      C23,15.15,23.27,9.97,26.97,5.33c3.65-4.58,8.59-6.28,14.29-4.83c5.75,1.46,9.26,5.35,10.22,11.18c0.89,5.44-0.86,10.1-5.41,13.36
                      c-4.63,3.33-9.62,3.59-14.74,1.02C31.07,25.94,30.83,25.79,30.45,25.58z M29.98,13.82c0,4.37,3.46,7.85,7.79,7.86
                      c4.42,0.01,7.94-3.46,7.95-7.82c0.01-4.27-3.54-7.74-7.91-7.75C33.48,6.1,29.98,9.55,29.98,13.82z"/>
                    <path d="M41.48,35.67c3.53,0,6.97,0,10.49,0c0,14.87,0,29.69,0,44.59c-3.48,0-6.95,0-10.49,0C41.48,65.41,41.48,50.59,41.48,35.67z
                      "/>
                    <path d="M26.12,80.4c-3.5,0-6.94,0-10.46,0c0-8.75,0-17.46,0-26.24c3.45,0,6.92,0,10.46,0C26.12,62.87,26.12,71.58,26.12,80.4z"/>
                  </g>
                </svg>
              </div>          
            </div>
          </div>
        </div>       
        <div class="cd-chart-box-middle">
          <div class="ctrack-md-loader" :class="loaderHide?'ctrack-chart-hide-loader':''"></div>
          <div class="cd-chart-box-img" :class="loaderHide?'':'ctrack-chart-hide-loader'">
            <highcharts :options="chartOptions"></highcharts>
          </div>
        </div> 
         <div class="cd-chart-box-bottom cd-chart-box-bottom-blank">&nbsp;</div>
         <div class="md-no-data-found-msg"><span class="md-error-dataIcon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"><path d="M754.269,1479.679a.994.994,0,0,1-.1-1.255q2.3-4.585,4.6-9.167l6.755-13.474c.041-.083.08-.167.127-.247a.977.977,0,0,1,1.739.008c1.273,2.494,2.52,5,3.8,7.539l-1.774.883c-.959-1.907-1.908-3.794-2.89-5.746-3.322,6.624-6.612,13.188-9.918,19.78h19.855l-4.808-9.814,1.778-.872c.4.814.789,1.607,1.178,2.4,1.423,2.9,2.85,5.8,4.26,8.711a1.463,1.463,0,0,1,.139.795.968.968,0,0,1-1.076.773h-8.463q-7.16,0-14.322,0A1.117,1.117,0,0,1,754.269,1479.679Zm10.951-4.834a1.3,1.3,0,0,1,1.3-1.349,1.336,1.336,0,0,1,0,2.67A1.3,1.3,0,0,1,765.221,1474.845Zm.963-2.283a.937.937,0,0,1-.582-.905q-.067-1.2-.135-2.406c-.075-1.327-.145-2.654-.229-3.98a1.294,1.294,0,1,1,2.559,0c-.012.143-.023.287-.034.431h0c-.11,1.975-.217,3.949-.332,5.924a.958.958,0,0,1-.9,1A.941.941,0,0,1,766.184,1472.562Zm4.017-7.073,1.752-.917.792,1.5-1.757.917C770.721,1466.482,770.467,1466,770.2,1465.489Z" transform="translate(-753.998 -1455.009)"></path></svg></span> {{$t("Dashboard.noDataFound")}}</div>
      </div>
      <!-- Incidents vs Miles Chart -->
     

    </div> 
    <md-dialog class="md-driver-drilldown" :md-active.sync="showDrill">
      <DrillDownGrid v-if="showDrill" :data="widgetData" :gridSettingsKey="widgetData.widgetName"></DrillDownGrid>
    </md-dialog>   
  </div>
</template>

<script>
import Vue from 'vue'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import HighChartMore from 'highcharts/highcharts-more'
import HighChartAccessibility from 'highcharts/modules/accessibility'
import dashboardService from "../../../service/dashboard.service"
import { getters } from "../shared/observeDashboardFilter";
import JQuery from "jquery";

HighChartMore(Highcharts);
HighChartAccessibility(Highcharts);
Vue.use(HighchartsVue, { });

export default {
    name: 'incidentsmileschart',
    components: {},
    data: function () {
      return {
        widgetTitle: '',
        widgetTitleDuration: '',
        showDrill: false,
        loaderHide: false,
        widgetTitle: "",
        widgetTitleDuration: "",
        hideloaderClass: "",
        showPrevious: false,
        chartOptions: {
          credits: false,
          title: {
            text: ' '
          },
          xAxis: {
            min: 0,
            title: {
              text: ''
            },

          },
          yAxis: {
            min: 0,
            lineWidth: 1,
            // tickInterval: 100,
            title: {
              text: ''
            },
            labels: {
              formatter: function () {
                if (this.value != 0) {
                  return this.value;
                }
              }
            },
          },
          tooltip: { 
                       
            formatter: function () {
              let tooltipStr = "";
              if (this.point.series.userOptions.tooltipDetail[this.point.index].data) {                
                let eventTotal = 0;
                this.point.series.userOptions.tooltipDetail[this.point.index].data.forEach(element => {
                  tooltipStr += "<br><strong>" + element.name + "</strong> : " + element.value;
                  eventTotal += element.value;
                });
                tooltipStr += "<br><div style='border-top:solid 1px #E8E8E8;padding:6px 0 0;margin-top:2px;'><strong>"+this.point.series.userOptions.totalCap+"</strong> : " + eventTotal + "</div>";
              }
              return "<strong>" + this.point.series.userOptions.tooltipDetail[this.point.index].name + "</strong>" + tooltipStr;

            },
            backgroundColor: {
              linearGradient: [0, 0, 0, 60],
              stops: [
                [0, '#FFFFFF'],
                [1, '#FFFFFF']
              ]
            },
            // shadow:false,
            useHTML:true,
            borderWidth: 0,
            style: {
              color: '#333333',
              fontSize: "14px",
              fontFamily: "Roboto"
            }
          },
          series: [
            /* {
              type: 'polygon',
              name:" ",
              color: "#fff",
              enableMouseTracking: false,
            },
            {
              type: 'polygon',
              name:" ",
              color: "#fff",
              enableMouseTracking: false,
            }, */
            {
              type: 'line',
              name:" ",
              marker: {
                enabled: false
              },
              states: {
                hover: {
                  lineWidth: 0
                }
              },
              enableMouseTracking: false
            },
            {
              type: 'scatter',
              name:" ",
              tooltipDetail: [],
              totalCap: '',
              color: "#FFB30A",
              marker: {
                radius: 6,
                symbol: "circle"
              }
            }
          ]
        }
      }
    },
    props: {
      widgetData: Object
    },
    created() {
      this.getIcidentsMilesChartData();
    },
    methods: {
      getIcidentsMilesChartData() {
        let self = this;
        let jquery = JQuery;
        self.loaderHide = false;
        dashboardService.getWidgetData(this.widgetData.api, getters.getFilterParams() ).then(returnResult => {
          /* jquery.each( returnResult.data.series, function( key, value ) {        
            if(key<4) {
              self.chartOptions.series[key].name = value.name;
              // self.chartOptions.series[key].data = value.data;

              if(key!=2) {
                self.chartOptions.series[key].color = value.color;
              }
              if(key==3) {
                self.chartOptions.series[key].tooltipDetail = value.tooltipDetail;
              }

            }
          }); */
          if(returnResult.data.series.length) {
            // self.chartOptions.series[0].name = returnResult.data.series[0].name;
            // self.chartOptions.series[1].name = returnResult.data.series[1].name;
            self.chartOptions.series[0].name = returnResult.data.series[2].name;
            self.chartOptions.series[1].name = returnResult.data.series[3].name;

            // self.chartOptions.series[0].data = returnResult.data.series[0].data;
            // self.chartOptions.series[1].data = returnResult.data.series[1].data;
            self.chartOptions.series[0].data = returnResult.data.series[2].data;
            self.chartOptions.series[1].data = returnResult.data.series[3].data;
            // self.chartOptions.series[3].data = returnResult.data.series[4].data;

            // self.chartOptions.series[0].color = returnResult.data.series[0].color;
            // self.chartOptions.series[1].color = returnResult.data.series[1].color;
            self.chartOptions.series[1].color = returnResult.data.series[3].color;

            self.chartOptions.series[1].tooltipDetail = returnResult.data.series[3].tooltipDetail;
            self.chartOptions.series[1].totalCap = self.$t("Dashboard.total");
          }

          self.chartOptions.xAxis.title.text = returnResult.data.xAxisTitle;
          self.chartOptions.yAxis.title.text = returnResult.data.yAxisTitle;
          self.widgetTitle = returnResult.data.chartTitle;
          self.widgetTitleDuration = returnResult.data.chartTitleDuration ? "(" + returnResult.data.chartTitleDuration + ")" : "";
          this.loaderHide = true;
        // }, 5000);
        })
        .catch(err => {
          self.hideloaderClass = 'cd-chart-ifDataBlank'
          self.loaderHide=true
        });

      }
    }
};

</script>

<style scoped>
.divhide{display: none}
/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
*{font-family: 'Roboto', sans-serif;}
.ctrack-h-100{height: 100%;}
[class*="md-chart-arrow"]{display:inline-block;vertical-align:middle;}
[class*="md-chart-arrow"] svg{display:block;}
.md-chart-arrow-down{transform:rotate(180deg); -webkit-transform:rotate(180deg); -moz-transform:rotate(180deg); -ms-transform:rotate(180deg); -o-transform:rotate(180deg);}
.cd-chart-innerbox{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;width:100%;height:100%;background-color:#FFF;border-radius:5px;align-content: space-between; -webkit-align-content: space-between;}
.ctrack-align-content-top{align-content: start; -webkit-align-content: start;}
.cd-chart-box-middle{padding:20px 30px;-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
.cd-chart-box-bottom{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;border-top:1px solid #E8E8E8;margin-top:20px;padding:18px 0 0;}
.cd-chart-box-bottom-blank{margin-top:0;border:0;padding-top:0;height:1px;}
.cd-chart-box-top{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;padding:15px 20px;border-bottom:1px solid #E8E8E8;}
.cd-chart-box-img {text-align: center;}
.cd-chart-box-header{display:table;width:100%;}
.cd-chart-box-header > div{display:table-cell;}
.cd-chart-box-col1 h3{color:#013C5E;font-size:18px;font-weight:700;}
.cd-chart-box-col1 h3 span{color:#979797;font-weight:400;}
.cd-chart-box-col2{text-align:right;font-size:14px;font-weight:bold;color:#333333;}
.ctrack-select-main{display:inline-block;}
.ctrack-select-box{display:inline-block;background: none; font-family:'Roboto',sans-serif;font-weight:bold;color:#333333;font-size:14px;border:0;padding-right:2px;outline: 0}
.ctrack-select-icon2{position:relative;}
.ctrack-select-icon2:before{content:"";position:absolute;top:6px;right:2px;z-index:1;border:solid 7px #333333;border-left:solid 4px transparent;border-right:solid 4px transparent;border-bottom:transparent;-webkit-pointer-events:none;-moz-pointer-events:none;pointer-events:none;}
.ctrack-select-icon2 select{-webkit-appearance:none;-moz-appearance:none;appearance:none;padding-right:16px;}

/* Rounded Toggle Button*/
.ctrack-switch{position:relative;display:inline-block;vertical-align: middle;width:31px;height:19px;}
.ctrack-switch input{opacity:0;width:0;height:0;}
.ctrack-slider{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:#D7D7D7;-webkit-transition:.4s;transition:.4s;}
.ctrack-slider:before{position:absolute;content:"";height:15px;width:15px;left:2px;bottom:2px;background-color:white;-webkit-transition:.4s;transition:.4s;}
.ctrack-switch input:checked + .ctrack-slider{background-color:#40C1AC;}
.ctrack-switch input:focus + .ctrack-slider{box-shadow:0 0 1px #002B49;}
.ctrack-switch input:checked + .ctrack-slider:before{-webkit-transform:translateX(12px);-ms-transform:translateX(12px);transform:translateX(12px);}
.ctrack-slider.ctrack-round{border-radius:10px;}
.ctrack-slider.ctrack-round:before{border-radius:50%;}

/* Toggle in Bottom of the charts*/
.cd-chart-toggles-main{display:inline-block;width:100%;padding:0 20px 20px;}
.cd-chart-toggles-main h5{font-size:16px;color:#333333;font-weight:500;}
.cd-chart-check-toggles{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.md-chart-toggle-repeater{position:relative;width:100%;min-height:1px;padding-right:15px;-ms-flex-preferred-size:0;flex-basis:0;max-width:100%;white-space:nowrap;}
.md-chart-toggle-repeater .md-chart-toggle-label{display:inline-block;vertical-align:middle;padding-right:8px;font-size: 15px;color: #8a8a8a;}

/*No Data Section */
.cd-chart-ifDataBlank .cd-chart-box-top,.cd-chart-ifDataBlank .cd-chart-box-middle,.cd-chart-ifDataBlank .cd-chart-box-bottom,.cd-chart-ifDataBlank .ctrack-d-flexcol{visibility:hidden;}
.cd-chart-ifDataBlank .md-no-data-found-msg{display: block}
.md-no-data-found-msg{padding:15px 15px 15px 50px;position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);font-size:18px;color:#9e4e4e;border:1px solid #ffb2b2;background-color:#ffb2b2;border-radius:3px;display:none}
.md-no-data-found-msg .md-error-dataIcon{position:absolute;left:15px;top:50%;transform:translateY(-50%);}
.md-no-data-found-msg .md-error-dataIcon svg{display:block;}

/* Responsive CSS */
@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1399px){}
@media only screen and (max-width:1299px){}
@media only screen and (max-width:1199px){}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){}
@media only screen and (max-width:767px){}
@media only screen and (max-width:575px){
  .cd-chart-box-middle {padding: 15px}
  .cd-chart-box-col1 h3{font-size: 16px}
  .cd-chart-box-col1 h3 span{display: block; font-size: 14px}
}


</style>
