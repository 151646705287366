import axios from "axios";

class ConfigurationService {

    updateUserConfigProperty(property) {
        return axios({
            method: 'put',
            url: `/api/configuration/UpdateUserConfigProperty`,
            headers: {
                'silently': true
            },
            data: property
        })
    }
}

export default new ConfigurationService(); 
