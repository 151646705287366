<template>
    <div class="DistanceDrivenWidget ctrack-h-100" ref="DistanceDriven">
        <div id="vueapp" class="vue-app ctrack-h-100">

            <!-- Distance Driven Chart -->
            <div class="dExecutive-inner error-color">
                <div class="dExecutive-title">
                    <h4>
                        <span class="dExe-title-icon">
                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="26" height="21"
                                viewBox="0 0 26 21">
                                <path
                                    d="M121.024,674.384H110.975a2.423,2.423,0,1,1,0-1.615h10.049a2.423,2.423,0,1,1,0,1.615Zm1.684-5.115c-.52-.577-5.083-5.716-5.083-8.617a5.688,5.688,0,0,1,11.376,0c0,2.9-4.564,8.04-5.084,8.617a.815.815,0,0,1-1.209,0Zm-1.833-8.617a2.438,2.438,0,1,0,2.438-2.424A2.432,2.432,0,0,0,120.875,660.653Zm-12.793,8.617c-.52-.577-5.083-5.716-5.083-8.617a5.688,5.688,0,0,1,11.375,0c0,2.9-4.563,8.04-5.083,8.617a.815.815,0,0,1-1.209,0Zm-1.833-8.617a2.438,2.438,0,1,0,2.438-2.424A2.431,2.431,0,0,0,106.249,660.653Z"
                                    transform="translate(-102.999 -654.998)" /></svg>
                        </span>
                        Distance Driven</h4>
                </div>
                <div class="dExecutive-content">
                    <div class="dExe-chart-row dExe-chart-fullwidth">
                        <div class="dExe-chart-col1">
                            <h3 class="ctrack-text-left">
                                <span class="dExe-value-icon md-chart-arrow-up">
                                    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="22" height="11"
                                        viewBox="0 0 22 11">
                                        <path d="M107.008,284.158l11.223-11.223,11.223,11.223Z"
                                            transform="translate(-107.008 -272.935)" /></svg>
                                </span>20%
                            </h3>
                        </div>
                        <div class="dExe-chart-col2">
                            <!-- <div class="dExe-chart-main"><img
                            src="../../../assets/images/dashboard/md-distance-driven-graph.svg" /></div> -->
                            <div class="dExe-chart-main">
                                <highcharts :options="chartOptions" ref="chart"></highcharts>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Distance Driven Chart -->

        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import HighChartMore from 'highcharts/highcharts-more'
import HighChartAccessibility from 'highcharts/modules/accessibility';
import dashboardService from "../../../service/dashboard.service";
HighChartMore(Highcharts);
HighChartAccessibility(Highcharts);
Vue.use(HighchartsVue, {});

export default {
    name: 'distancedrivenchart',
    components: {},
    data: function () {
        return {

            chartOptions: {
                title: {
                    text: ' ',
                },
                credits: {
                    enabled: false
                },
                chart: {
                    // width:300 height:200,
                    type: 'spline',

                },
                // legend: {
                //     layout: 'vertical',
                //     align: 'right',
                //     verticalAlign: 'middle'
                // },
                yAxis: {
                    labels: {
                        enabled: false,
                    },
                    title: {
                        text: ' ',
                    },
                    gridLineWidth: 0,
                },
                xAxis: {
                    lineColor: 'transparent',
                    minorTickLength: 0,
                    tickLength: 0,
                    labels: {
                        enabled: false
                    },
                    gridLineWidth: 0,
                },
                tooltip: {
                    enabled: true
                },

                legend: {
                    symbolWidth: 0,
                    symbolRadius: 0,
                    layout: 'vertical',
                    align: 'middle',
                    verticalAlign: 'middle',
                    tickWidth: 0,
                    tickLength: 0,
                    y: 180,
                    x: 300,

                    labelFormatter: function () {
                        var lastVal = this.yData[this.yData.length - 1];
                        return '<span style="color:' + this.color + '">' + this.name + ': ' +
                            lastVal + ' KM </span></n>';
                    }
                },

                // legend: {
                //     layout: 'vertical',
                //     align: 'right',
                //     verticalAlign: 'middle'
                // },

                plotOptions: {
                    // height:'20px',
                    series: {
                        label: {
                            connectorAllowed: false,
                        },
                        pointStart: 0,
                        marker: {
                            enabled: false
                        },
                    }

                },
                series: {},

            }
        }
    },
    created() {
        this.getDistanceDrivenChartData();
    },
    methods: {

        getDistanceDrivenChartData() {
            setTimeout(() => {
                let self = this;
                self.loaderHide = false;
                const returnResult = {
                    // chartTitle: "History", chartTitleDuration : "Last 7 Days",

                    series: [{
                        name: 'LastWeek',
                        color: '#77BF41',
                        data: [1.5, 2.5, 3, 3.5, 4, 4.5, 5.5, 6]
                    }, {
                        name: 'This Week',
                        color: '#D1D1D1',
                        data: [1, 2, 2.5, 3, 3.5, 4, 5, 5.5]
                    }]
                };
                // returnResult.categories;
                self.chartOptions.series = returnResult.series;
                // self.chartTitle = returnResult.chartTitle; self.chartTitleDuration = "(" +
                // returnResult.chartTitleDuration + ")";

                self.loaderHide = true;
            }, 5000);
        }


    }
};

</script>
<style scoped>

/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');*{font-family:'Roboto',sans-serif;}
.ctrack-h-100{height:100%;}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.md-dashboard-content{padding-top:10px;color:#C5C5C5;}
[class*="md-chart-arrow"]{display:inline-block;vertical-align:middle;}
[class*="md-chart-arrow"] svg{display:block;}
.md-chart-arrow-down{transform:rotate(180deg);-webkit-transform:rotate(180deg);-moz-transform:rotate(180deg);-ms-transform:rotate(180deg);-o-transform:rotate(180deg);}
.dExecutive-inner{height:100%;width:100%;display:inline-block;background-color:#FFFFFF;border-top:6px solid #CDCDCD;border-radius:5px;padding:0 20px 20px;}
.dExecutive-title{display:inline-block;width:100%;padding:10px 0;}
.dExecutive-title h4{color:#333333;font-size:20px;font-weight:500;position:relative;padding-left:40px;display:inline-block;width:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
.dExecutive-title h4 .dExe-title-icon{position:absolute;left:0;top:50%;transform:translateY(-50%);}
.dExecutive-title h4 .dExe-title-icon svg{display:block;}
.dExecutive-content{display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;justify-content:space-between;-webkit-justify-content:space-between;height:calc(100% - 60px);}
.dExecutive-main div[class*="ctrack-col-"]{margin-bottom:20px;}
.dExe-chart-row{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:nowrap;flex-wrap:nowrap;-webkit-box-align:center;-ms-flex-align:center;align-items:center;justify-content:space-between;-webkit-justify-content:space-between;width:100%;}
.ctrack-align-end{-webkit-box-align:flex-end;-ms-flex-align:flex-end;align-items:flex-end;}
.dExe-chart-col1 h3{font-size:50px;display:inline-block;width:100%;vertical-align:middle;position:relative;font-weight:700;white-space:nowrap}
.dExe-chart-col1 h3 span{display:inline-block;vertical-align:middle;padding-right:5px;}
.dExe-chart-col1 h3 span.md-chart-arrow-down{padding-right:0;padding-left:5px;}
.dExe-chart-col1 h3 svg{display:block;}
.dExe-chart-fullwidth{-ms-flex-wrap:wrap;flex-wrap:wrap;}
.dExe-chart-fullwidth > div{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
.dExe-chart-fullwidth .dExe-chart-col1 h3{text-align:center;}
.dExe-chart-main{padding-left:5px;}
.dExe-chart-fullwidth .dExe-chart-main{padding-left:0;}
.dExe-chart-lbl{display:inline-block;width:100%;margin-top:5px;}
.dExe-chart-lbl span{display:inline-block;vertical-align:middle;padding-right:8px;}
.dExe-chart-lbl span svg{display:block}
.dExe-chart-bottom{display:table;width:100%;}
.dExe-chart-bottom > div{display:table-cell;}
.dExe-chart-bottom-inner{text-align:center;display:inline-block;font-size:20px;font-weight:500;margin-top:15px;}
.dExe-chart-bottom-inner small{font-size:14px;margin-bottom:-5px;}
.dExe-chart-bottom-inner small,.dExe-chart-bottom-inner label{display:block;}
.dExe-chart-bottom-col2{text-align:right;}
.error-color{border-color:#FA4B4B;}
.success-color{border-color:#76CD24;}
.error-color .dExe-chart-col1 h3,.error-color .md-chart-color{color:#FA4B4B;}
.success-color .dExe-chart-col1 h3,.success-color .md-chart-color{color:#76CD24;}
.dExe-chart-fullwidth .dExe-chart-col1 h3.ctrack-text-left{text-align: left}

/* Responsive CSS */
@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1399px){}
@media only screen and (max-width:1299px){}
@media only screen and (max-width:1199px){}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){}
@media only screen and (max-width:767px){}
@media only screen and (max-width:575px){
  .dExecutive-inner{padding:0 15px 15px}
  .dExe-chart-row{-ms-flex-wrap:wrap;flex-wrap:wrap;}
  .dExe-chart-row > div{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;text-align:center;}
  .dExe-chart-col1{margin-bottom:5px;}
  .dExe-chart-labels{display:table;width:100%;padding:5px 0 10px;}
  .dExe-chart-labels > div{display:table-cell;width:auto;}
  .dExe-chart-main{padding-left:0;}
}

</style>
