<template>
    <v-dialog v-model="dialog"
              max-width="450"
              @click:outside="closeDialog">
        <v-card>
            <v-toolbar dark color="#002B49"
                       style="margin-bottom: 16px;">
                <v-toolbar-title>{{title}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon
                       @click="closeDialog"
                       style="margin: 0 !important;">
                    <v-icon>mdi-close-thick</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-title v-if="entityName" style="padding-top: 0; white-space: pre-wrap;">
                {{entityName}}
            </v-card-title>
            <v-card-text>
                {{confirmationMessage}}
            </v-card-text>
            <v-card-actions>
                <v-btn @click="confirmDelete"
                       color="primary">
                    {{$t("ContactLabels.yes")}}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="closeDialog">
                    {{$t("ContactLabels.no")}}
                </v-btn>
            </v-card-actions>
            <v-card-text v-if="notice" class="textDeleteBtn">
                {{notice}}
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        props: {
            title: {
                type: String,
                default() {
                    return this.$t("schedule.dataView.confirm");
                },
            },
            confirmationMessage: {
                type: String,
                default() {
                    return this.$t("schedule.dataView.confirm");
                },
            },
            notice: {
                type: String,
                default() {
                    return '';
                },
            },

        },
        data() {
            return {
                id : null,
                dialog: false,
                entityName : null,
            }
        },
        methods: {
            openDialog(id, entityName = null) {
                this.id = id;
                this.entityName = entityName
                this.dialog = true;
            },
            closeDialog() {
                this.id = null;
                this.entityName = null;
                this.dialog = false;
            },
            confirmDelete() {
                this.$emit('confirm', this.id);
                this.id = null;
                this.entityName = null;
                this.dialog = false;
            },
        },
    }
</script>
<style scoped>
    .textDeleteBtn {
        margin-top: 10px;
        -webkit-text-fill-color: darkgray;
    }

    .v-btn {
        margin-left: 15% !important;
        margin-right: 15% !important;
    }
</style>