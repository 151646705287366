export default class RangeSelectionStrategy {
	constructor(component) {
		var self = this;
		component.$watch('start.time', {
			handler() {
				self.normalizeTimeRange();
			},
			deep: true
		});
		component.$watch('end.time', {
			handler() {
				self.normalizeTimeRange();
			},
			deep: true
		});
		this.component = component;
		if (component.value == null || component.value == undefined ) {
			component.setDate(new Date(), component);
		} else {
			component.setDate(component.value.start, component);
			component.setDate(component.value.start, component.start, true);
			component.setDate(component.value.end, component.end, true);
		}
	}
	dateChange (startDate, endDate) {
		if (!this.isDirty(startDate, endDate)) {
			return;
		}
		this.component.$emit('input', {
			start: startDate,
			end: endDate
		});
		if (this.component.change !== undefined) {
			this.component.change(startDate, endDate);
		}
	}
	getTitle (titleFormat) {
		if (this.component.start.date.year == 0) {
			return '';
		}
		return this.component.$moment(this.component.start.toDate(true)).format(titleFormat) + ' - ' + this.component.$moment(this.component.end.toDate(true)).format(titleFormat);
	}
	getFormattedDate (format) {
		if (this.component.start.date.year == 0) {
			return '';
		}
		return this.component.$moment(this.component.start.toDate(true)).format(format) + ' - ' + this.component.$moment(this.component.end.toDate(true)).format(format);
	}
	isSelected (date) {
		return this.component.start.isDay(date) || this.component.end.isDay(date);
	}
	isInRange (date) {
		return date >= this.component.$moment(this.component.start.toDate()).seconds(-1).toDate() && date <= this.component.end.toDate();
	}
	isDirty(startDate, endDate) {
		var component = this.component;
		var toString = (value) => { return component.$moment(value).format('DDMMYYYHHmm'); };
		if (this.component.value !== null
			&& this.component.value !== undefined
			&& startDate.getYear() > 0
			&& toString(this.component.value.start) === toString(startDate) 
			&& toString(this.component.value.end) === toString(endDate)) {
			return false;
		}
		return true;
	}
	selectDate (date) {
		let startSelected = true;
		if (this.component.start.selected && this.component.end.selected) {
			this.component.end.selected = false;
			this.component.setDate(date, this.component.start);
			startSelected = true;
		}
		else if (this.component.start.selected && !this.component.end.selected) {
			this.component.end.selected = true;
			this.component.setDate(date, this.component.end);
			startSelected = false;
		}
		if (this.component.start.toDate() > this.component.end.toDate()) {
			var temp = this.component.start.date;
			this.component.start.date = this.component.end.date;
			this.component.end.date = temp;
			this.component.start.selected = true;
			this.component.end.selected = true;
			startSelected = !startSelected;
		}
		this.normalizeDateRange(startSelected);
		this.normalizeTimeRange();
	}
	normalizeDateRange(startSelected) {
		let difference = this.component.end.toDate().diff(this.component.start.toDate(), 'days');
		let days = this.component.maxRange - 1;
		if (this.component.maxRange > 0 && difference > days) {
			if (startSelected) {
				this.component.setDate(this.component.start.toDate().add(days, 'days').toDate(), this.component.end);
			}
			else {
				this.component.setDate(this.component.end.toDate().subtract(days, 'days').toDate(), this.component.start);
			}
		}
	}
	normalizeTimeRange() {
		let start = this.component.start.toDate(true);
		let end = this.component.end.toDate(true);
		if (start > end) {
			setTimeout(() => {
				this.component.end.time.hour = this.component.start.time.hour;
				this.component.end.time.minute = this.component.start.time.minute;
				this.component.end.time.isAm = this.component.start.time.isAm;
			}, 0);
		}
	}	
}
