import CryptoJS from 'crypto-js'
import loginService from '../service/login.service.js';
import RBACService from "../service/rbac.service";
import constant from "./../../constant";
var encryptionKey = "jTpRk@8Y58cm9dU%iLG5"


const state = () => ({
    claims: JSON.parse(localStorage.getItem('claims') ? CryptoJS.AES.decrypt(localStorage.getItem('claims'), encryptionKey).toString(CryptoJS.enc.Utf8) : "[]"),
    operatorRoleId : '',
})

const getters = {
    getClaims: state => state.claims,
    getOperatorRoleId: state => state.operatorRoleId,
}

const actions = {
    setClaims({ commit }, report) {
        if (report.claims) {
            let claims = report.claims.map(claim => (claim.applicationClaimName));
            commit("mutateClaims", claims);
        } else {
            loginService.getUserClaims(report.userid).then(response => {
                let claims = response.map(claim => (claim.ApplicationClaimName ?? claim.applicationClaimName));
                commit("mutateClaims", claims);
            })
        }
    },
    setClaimsAfterTenantSwitch({ commit }, claims) {
        let newClaims = claims.map(claim => (claim.applicationClaimName));

        commit("mutateClaims", newClaims);
    },
    async setOperatorRoleId({ commit }, params) {
        let driverSecurityRoleName = String(constant.driverSecurityRoleName).toLowerCase();
        params.SecurityRoleName = driverSecurityRoleName

        await RBACService.getSecurityRoleIdByName(params)
            .then(role => {
            if (role) {
                //console.log('mutateOperatorRoleId', role);
                commit("mutateOperatorRoleId", role);
                };
            }); 
    },
}

const mutations = {
    mutateClaims(state, claims) {
        localStorage.setItem("claims", CryptoJS.AES.encrypt(JSON.stringify(claims), encryptionKey).toString())
        state.claims = claims
    },
    mutateOperatorRoleId(state, operatorRoleId) {
        state.operatorRoleId = operatorRoleId
    },
}

export default {
    modules: {
        claimsStore: {
            namespaced: true,
            state,
            getters,
            actions,
            mutations
        }
    }
};