<template>
  <div class="DriveRelationWidget ctrack-h-100" ref="DriveRelation">
    <div id="vueapp" class="vue-app ctrack-h-100">
        
      <!-- Drive Relation Chart -->
      <div class="cd-chart-innerbox">
        <div class="cd-chart-box-top">
          <div class="cd-chart-box-header">
            <div class="cd-chart-box-col1"><h3>{{$t("Dashboard.driverBehaviour")}}</h3></div>
            <div class="cd-chart-box-col2">
              <div class="md-view-detail-link" @click="showDrill = true">
              <!-- <i class="material-icons">launch</i> Show Drill Downs -->
              <svg version="1.1" width="20" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 89.64 90.91" style="enable-background:new 0 0 89.64 90.91;" xml:space="preserve">
                  <g>
                    <path d="M5.93,84.99c27.97,0,55.8,0,83.71,0c0,2,0,3.93,0,5.92c-29.87,0-59.73,0-89.64,0C0,60.64,0,30.4,0,0.09
                      c1.96,0,3.91,0,5.93,0C5.93,28.38,5.93,56.61,5.93,84.99z"/>
                    <path d="M67.5,80.38c0-21.98,0-43.9,0-65.87c3.52,0,7,0,10.53,0c0,21.97,0,43.88,0,65.87C74.53,80.38,71.06,80.38,67.5,80.38z"/>
                    <path d="M30.45,25.58c-1.48,1.49-2.94,2.95-4.4,4.42c-2.77,2.77-5.54,5.53-8.3,8.31c-0.93,0.94-2.01,1.29-3.27,0.94
                      c-2.07-0.58-2.86-3.07-1.52-4.76c0.32-0.4,0.71-0.76,1.07-1.12c3.77-3.78,7.54-7.56,11.33-11.31c0.53-0.52,0.66-0.87,0.28-1.62
                      C23,15.15,23.27,9.97,26.97,5.33c3.65-4.58,8.59-6.28,14.29-4.83c5.75,1.46,9.26,5.35,10.22,11.18c0.89,5.44-0.86,10.1-5.41,13.36
                      c-4.63,3.33-9.62,3.59-14.74,1.02C31.07,25.94,30.83,25.79,30.45,25.58z M29.98,13.82c0,4.37,3.46,7.85,7.79,7.86
                      c4.42,0.01,7.94-3.46,7.95-7.82c0.01-4.27-3.54-7.74-7.91-7.75C33.48,6.1,29.98,9.55,29.98,13.82z"/>
                    <path d="M41.48,35.67c3.53,0,6.97,0,10.49,0c0,14.87,0,29.69,0,44.59c-3.48,0-6.95,0-10.49,0C41.48,65.41,41.48,50.59,41.48,35.67z
                      "/>
                    <path d="M26.12,80.4c-3.5,0-6.94,0-10.46,0c0-8.75,0-17.46,0-26.24c3.45,0,6.92,0,10.46,0C26.12,62.87,26.12,71.58,26.12,80.4z"/>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
          <div class="cd-chart-box-top" :class="loaderHide?'':'ctrack-chart-hide-loader'">
          <div class="cd-chart-indicator-style1">
            <div class="ctrack-row">
              <div class="ctrack-col-sm-6 ctrack-col-lg-6 ctrack-col-xl-6"><div class="cd-chart-indicator-inner"><span :style="'background-color:'+defaultCircleColor" class="cd-chart-indicator-color"></span>{{overspeedLabel}} ({{overspeedOriginalValue}})</div></div>
              <div class="ctrack-col-sm-6 ctrack-col-lg-6 ctrack-col-xl-6 md-indicator-align-right"><div class="cd-chart-indicator-inner"><span :style="'background-color:'+hbCircleColor" class="cd-chart-indicator-color"></span>{{hbLabel}} ({{hbOriginalValue}})</div></div>
              <!-- <div class="ctrack-col-sm-6 ctrack-col-lg-6 ctrack-col-xl-4" style="visibility:hidden"><div class="cd-chart-indicator-inner"><span :style="'background-color:'+cdCircleColor" class="cd-chart-indicator-color"></span>{{cdLabel}} ({{cdOriginalValue}})</div></div> -->
              <div class="ctrack-col-sm-6 ctrack-col-lg-6 ctrack-col-xl-6"><div class="cd-chart-indicator-inner"><span :style="'background-color:'+haCircleColor" class="cd-chart-indicator-color"></span>{{haLabel}} ({{haOriginalValue}})</div></div>
              <div class="ctrack-col-sm-6 ctrack-col-lg-6 ctrack-col-xl-6 md-indicator-align-right"><div class="cd-chart-indicator-inner"><span :style="'background-color:'+hcCircleColor" class="cd-chart-indicator-color"></span>{{hcLabel}} ({{hcOriginalValue}})</div></div>
              <!-- <div class="ctrack-col-sm-6 ctrack-col-lg-6 ctrack-col-xl-4" style="visibility:hidden"><div class="cd-chart-indicator-inner"><span :style="'background-color:'+ndCircleColor" class="cd-chart-indicator-color"></span>{{ndLabel}} ({{ndOriginalValue}})</div></div> -->
            </div>
          </div>
        </div>
        <div class="ctrack-md-loader" :class="loaderHide?'ctrack-chart-hide-loader':''"></div>
        <div class="cd-chart-box-middle" :class="loaderHide?'':'ctrack-chart-hide-loader'">
          
          <div class="cd-chart-box-img" >
            
            <!-- <img src="../../../assets/images/dashboard/drive-relation-chart.svg" /> -->
            <!-- <div style="float: left;max-width: 100%;width:100%; margin-top: 30px;"> -->
              <div class="md-drive-relation-main" >
                  <div class="md-drive-relation-chart md-drive-rc-onload">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 750 358">            

                          <g id="md-rc-parent-5" :data-cat="hcCat" class="md-rc-circle-main">
                              <defs>
                                  <linearGradient id="md-line-dark-green" x1="0%" y1="0%" x2="100%" y2="0%">
                                    <stop offset="0%" :stop-color="defaultCircleColor" stop-opacity="0.7" />
                                    <stop offset="100%" stop-color="#008D72" stop-opacity="1.0" />
                                  </linearGradient>
                                </defs>
                              <line class="md-rc-circle-line" x1="90%" y1="61%" x2="23%" y2="51%" stroke-width="3"
                                  stroke="url(#md-line-dark-green)" stroke-linecap="round" stroke-dasharray="0,6"></line>
                              <g class="md-rc-circle-inner md-move-animation-left"> <!-- data-info="<div class='md-rc-tooltip-box-inner'><div class='md-rc-tooltip-box-title'>Circle Title</div><div class='md-rc-tooltip-box-data'>Value: <strong>12%</strong></div></div>" -->
                                  <circle ref="RcRefCirDarkGreen" :r="hcRadius" cx="90%" cy="61%" fill="#FFFFFF" stroke-width="2" stroke="#008D72" style="transform-origin: 90% 61%;" class="md-rc-circle-back"></circle>
                                  <circle cx="90%" cy="61%" :fill="hcCircleColor" stroke="none" style="transform-origin: 90% 61%;" class="md-rc-circle-front"></circle>
                                  <text x="90%" y="61%" text-anchor="middle" dy="0.3em" fill="#FFFFFF" font-family="'Roboto', sans-serif" font-size="22" font-weight="bold">{{hcValue}}</text>    
                              </g>    
                          </g>

                          <g id="md-rc-parent-4" :data-cat="cdCat" class="md-rc-circle-main" style="visibility:hidden">
                              <defs>
                                  <linearGradient id="md-line-blue" x1="0%" y1="0%" x2="100%" y2="0%">
                                    <stop offset="0%" :stop-color="defaultCircleColor" stop-opacity="0.7" />
                                    <stop offset="100%" stop-color="#00A7DA" stop-opacity="1.0" />
                                  </linearGradient>
                                </defs>
                              <line class="md-rc-circle-line" x1="72%" y1="80%" x2="23%" y2="51%" stroke-width="3"
                                  stroke="url(#md-line-blue)" stroke-linecap="round" stroke-dasharray="0,6"></line>
                              <g class="md-rc-circle-inner md-move-animation-bottom"> <!-- data-info="<div class='md-rc-tooltip-box-inner'><div class='md-rc-tooltip-box-title'>Circle Title</div><div class='md-rc-tooltip-box-data'>Value: <strong>12%</strong></div></div>" -->
                                  <circle ref="RcRefCirBlue" :r="cdRadius" cx="72%" cy="80%" fill="#FFFFFF" stroke-width="2" stroke="#00A7DA" style="transform-origin: 72% 80%;" class="md-rc-circle-back md-move-animation"></circle>
                                  <circle cx="72%" cy="80%" :fill="cdCircleColor" stroke="none" style="transform-origin: 72% 80%;" class="md-rc-circle-front"></circle>
                                  <text x="72%" y="80%" text-anchor="middle" dy="0.3em" fill="#FFFFFF" font-family="'Roboto', sans-serif" font-size="22" font-weight="bold">{{cdValue}}</text> 
                              </g>       
                          </g>

                          <g id="md-rc-parent-3" :data-cat="haCat" class="md-rc-circle-main">
                              <defs>
                                  <linearGradient id="md-line-green" x1="0%" y1="0%" x2="100%" y2="0%">
                                    <stop offset="0%" :stop-color="defaultCircleColor" stop-opacity="0.7" />
                                    <stop offset="100%" stop-color="#77BF41" stop-opacity="1.0" />
                                  </linearGradient>
                                </defs>
                              <line class="md-rc-circle-line" x1="82%" y1="22%" x2="23%" y2="51%" stroke-width="3" stroke="url(#md-line-green)" stroke-linecap="round" stroke-dasharray="0,6"></line>
                              <g class="md-rc-circle-inner  md-move-animation-bottom"> <!-- data-info="<div class='md-rc-tooltip-box-inner'><div class='md-rc-tooltip-box-title'>Circle Title</div><div class='md-rc-tooltip-box-data'>Value: <strong>12%</strong></div></div>" -->
                                  <circle ref="RcRefCirGreen" :r="haRadius" cx="82%" cy="22%" fill="#FFFFFF" stroke-width="2" stroke="#77BF41" style="transform-origin: 82% 22%;" class="md-rc-circle-back md-move-animation"></circle>
                                  <circle cx="82%" cy="22%" :fill="haCircleColor" stroke="none" style="transform-origin: 82% 22%;" class="md-rc-circle-front"></circle>
                                  <text x="82%" y="22%" text-anchor="middle" dy="0.3em" fill="#FFFFFF" font-family="'Roboto', sans-serif" font-size="22" font-weight="bold">{{haValue}}</text>     
                              </g>   
                          </g>

                          <g id="md-rc-parent-2" :data-cat="hbCat" class="md-rc-circle-main">
                              <defs>
                                  <linearGradient id="md-line-orange" x1="0%" y1="0%" x2="100%" y2="0%">
                                    <stop offset="0%" :stop-color="defaultCircleColor" stop-opacity="0.7" />
                                    <stop offset="100%" stop-color="#F47520" stop-opacity="1.0" />
                                  </linearGradient>
                                </defs>
                              <line class="md-rc-circle-line" x1="55%" y1="60%" x2="23%" y2="51%" stroke-width="3" stroke="url(#md-line-orange)" stroke-linecap="round" stroke-dasharray="0,6"></line>
                              <g class="md-rc-circle-inner md-move-animation-left"> <!-- data-info="<div class='md-rc-tooltip-box-inner'><div class='md-rc-tooltip-box-title'>Circle Title</div><div class='md-rc-tooltip-box-data'>Value: <strong>12%</strong></div></div>" -->
                                  <circle ref="RcRefCirOrange" :r="hbRadius" cx="55%" cy="60%" fill="#FFFFFF" stroke-width="2" stroke="#F47520" style="transform-origin: 55% 60%;" class="md-rc-circle-back md-move-animation"></circle>
                                  <circle cx="55%" cy="60%" :fill="hbCircleColor" stroke="none" style="transform-origin: 55% 60%;" class="md-rc-circle-front"></circle>
                                  <text x="55%" y="60%" text-anchor="middle" dy="0.3em" fill="#FFFFFF" font-family="'Roboto', sans-serif" font-size="22" font-weight="bold">{{hbValue}}</text>    
                              </g>    
                          </g>

                          <g id="md-rc-parent-1" :data-cat="ndCat" class="md-rc-circle-main" style="visibility:hidden">
                              <defs>
                                  <linearGradient id="md-line-red" x1="0%" y1="0%" x2="100%" y2="0%">
                                    <stop offset="0%" :stop-color="defaultCircleColor" stop-opacity="0.7" />
                                    <stop offset="100%" stop-color="#F0313A" stop-opacity="1.0" />
                                  </linearGradient>
                                </defs>
                              <line class="md-rc-circle-line" x1="62%" y1="20%" x2="23%" y2="51%" stroke-width="3" stroke="url(#md-line-red)" stroke-linecap="round" stroke-dasharray="0,6"></line>
                              <g class="md-rc-circle-inner  md-move-animation-left"> <!--  data-info="<div class='md-rc-tooltip-box-inner'><div class='md-rc-tooltip-box-title'>Circle Title</div><div class='md-rc-tooltip-box-data'>Value: <strong>12%</strong></div></div>" -->
                                  <circle ref="RcRefCirRed" :r="ndRadius" cx="62%" cy="20%" fill="#FFFFFF" stroke-width="2" stroke="#F0313A" style="transform-origin: 62% 20%;" class="md-rc-circle-back md-move-animation"></circle>
                                  <circle cx="62%" cy="20%" :fill="ndCircleColor" stroke="none" style="transform-origin: 62% 20%;" class="md-rc-circle-front"></circle>
                                  <text x="62%" y="20%" text-anchor="middle" dy="0.3em" fill="#FFFFFF" font-family="'Roboto', sans-serif" font-size="22" font-weight="bold">{{ndValue}}</text>    
                              </g>    
                          </g>

                          
                          <g id="md-rc-child-1" class="md-rc-circle-child ">
                              <line x1="15.5%" y1="16%" x2="23%" y2="51%" v-if="driverName[0]" class="md-child-circle-line" stroke-width="2" :stroke="defaultCircleColor"></line>
                              <g class="md-rc-circle-inner" :data-info="dataInfo[0]" v-if="driverName[0]">
                                  <circle ref="RcRefChild1" r="6%" cx="15.5%" cy="16%" fill="#FFFFFF" stroke-width="2" :stroke="defaultCircleColor" style="transform-origin: 15.5% 16%;" class="md-rc-circle-back md-pulse-animation"></circle>
                                  <circle cx="15.5%" cy="16%" :fill="defaultCircleColor" stroke="none" style="transform-origin: 15.5% 16%;" class="md-rc-circle-front"></circle>
                                  <text x="15.5%" y="16%" text-anchor="middle" dy="0.3em" fill="#FFFFFF" font-family="'Roboto', sans-serif" font-size="14" font-weight="bold" v-if="driverName[0]">{{ driverName[0] | subStr }}</text>   
                              </g>     
                          </g>

                          <g id="md-rc-child-2" class="md-rc-circle-child ">
                              <line x1="36.8%" y1="26%" x2="23%" y2="51%" v-if="driverName[1]" class="md-child-circle-line" stroke-width="2" :stroke="defaultCircleColor"></line>
                              <g class="md-rc-circle-inner" :data-info="dataInfo[1]" v-if="driverName[1]">
                                  <circle ref="RcRefChild2" r="6%" cx="36.8%" cy="26%" fill="#FFFFFF" stroke-width="2" :stroke="defaultCircleColor" style="transform-origin: 36.8% 26%;" class="md-rc-circle-back md-pulse-animation"></circle>
                                  <circle cx="36.8%" cy="26%" :fill="defaultCircleColor" stroke="none" style="transform-origin: 36.8% 26%;" class="md-rc-circle-front"></circle>
                                  <text x="36.8%" y="26%" text-anchor="middle" dy="0.3em" fill="#FFFFFF" font-family="'Roboto', sans-serif" font-size="14" font-weight="bold" v-if="driverName[1]">{{ driverName[1] | subStr }}</text>    
                              </g>    
                          </g>

                          <g id="md-rc-child-3" class="md-rc-circle-child ">
                              <line x1="39%" y1="69%" x2="23%" y2="51%" v-if="driverName[2]" class="md-child-circle-line" stroke-width="2" :stroke="defaultCircleColor"></line>
                              <g class="md-rc-circle-inner" :data-info="dataInfo[2]" v-if="driverName[2]">
                                  <circle ref="RcRefChild3" r="6%" cx="39%" cy="69%" fill="#FFFFFF" stroke-width="2" :stroke="defaultCircleColor" style="transform-origin: 39% 69%;" class="md-rc-circle-back md-pulse-animation"></circle>
                                  <circle cx="39%" cy="69%" :fill="defaultCircleColor" stroke="none" style="transform-origin: 39% 69%;" class="md-rc-circle-front"></circle>
                                  <text x="39%" y="69%" text-anchor="middle" dy="0.3em" fill="#FFFFFF" font-family="'Roboto', sans-serif" font-size="14" font-weight="bold" v-if="driverName[2]">{{ driverName[2] | subStr }}</text>     
                              </g>   
                          </g>

                          <g id="md-rc-child-4" class="md-rc-circle-child ">
                              <line x1="20%" y1="88.5%" x2="23%" y2="51%" v-if="driverName[3]" class="md-child-circle-line" stroke-width="2" :stroke="defaultCircleColor"></line>
                              <g class="md-rc-circle-inner" :data-info="dataInfo[3]" v-if="driverName[3]">
                                  <circle ref="RcRefChild4" r="6%" cx="20%" cy="88.5%" fill="#FFFFFF" stroke-width="2" :stroke="defaultCircleColor" style="transform-origin: 20% 88.5%;" class="md-rc-circle-back md-pulse-animation"></circle>
                                  <circle cx="20%" cy="88.5%" :fill="defaultCircleColor" stroke="none" style="transform-origin: 20% 88.5%;" class="md-rc-circle-front"></circle>
                                  <text x="20%" y="88.5%" text-anchor="middle" dy="0.3em" fill="#FFFFFF" font-family="'Roboto', sans-serif" font-size="14" font-weight="bold" v-if="driverName[3]">{{ driverName[3] | subStr }}</text>   
                              </g>     
                          </g>

                          <g id="md-rc-child-5" class="md-rc-circle-child">
                              <line x1="5%" y1="56.5%" x2="23%" y2="51%" v-if="driverName[4]"  class="md-child-circle-line" stroke-width="2" :stroke="defaultCircleColor"></line>
                              <g class="md-rc-circle-inner" :data-info="dataInfo[4]" v-if="driverName[4]">
                                  <circle ref="RcRefChild5" r="6%" cx="5%" cy="56.5%" fill="#FFFFFF" stroke-width="2" :stroke="defaultCircleColor" style="transform-origin: 5% 56.5%;" class="md-rc-circle-back md-pulse-animation"></circle>
                                  <circle cx="5%" cy="56.5%" :fill="defaultCircleColor" stroke="none" style="transform-origin: 5% 56.5%;" class="md-rc-circle-front"></circle>
                                  <text x="5%" y="56.5%" text-anchor="middle" dy="0.3em" fill="#FFFFFF" font-family="'Roboto', sans-serif" font-size="14" font-weight="bold" v-if="driverName[4]">{{ driverName[4] | subStr }}</text>    
                              </g>    
                          </g>

                          <g id="md-rc-child-6" class="md-rc-circle-child">
                              <line x1="27%" y1="14%" x2="23%" y2="51%" v-if="driverName[5]"  class="md-child-circle-line" stroke-width="2" :stroke="defaultCircleColor"></line>
                              <g class="md-rc-circle-inner" :data-info="dataInfo[5]" v-if="driverName[5]">
                                  <circle ref="RcRefChild6" r="6%" cx="27%" cy="14%" fill="#FFFFFF" stroke-width="2" :stroke="defaultCircleColor" style="transform-origin: 27% 14%;" class="md-rc-circle-back md-pulse-animation"></circle>
                                  <circle cx="27%" cy="14%" :fill="defaultCircleColor" stroke="none" style="transform-origin: 27% 14%;" class="md-rc-circle-front"></circle>
                                  <text x="27%" y="14%" text-anchor="middle" dy="0.3em" fill="#FFFFFF" font-family="'Roboto', sans-serif" font-size="14" font-weight="bold" v-if="driverName[5]">{{ driverName[5] | subStr }}</text>    
                              </g>    
                          </g>

                          <g id="md-rc-child-7" class="md-rc-circle-child ">
                              <line x1="41%" y1="47%" x2="23%" y2="51%" v-if="driverName[6]"  class="md-child-circle-line" stroke-width="2" :stroke="defaultCircleColor"></line>
                              <g class="md-rc-circle-inner" :data-info="dataInfo[6]" v-if="driverName[6]">
                                  <circle ref="RcRefChild7" r="6%" cx="41%" cy="47%" fill="#FFFFFF" stroke-width="2" :stroke="defaultCircleColor" style="transform-origin: 41% 47%;" class="md-rc-circle-back md-pulse-animation"></circle>
                                  <circle cx="41%" cy="47%" :fill="defaultCircleColor" stroke="none" style="transform-origin: 41% 47%;" class="md-rc-circle-front"></circle>
                                  <text x="41%" y="47%" text-anchor="middle" dy="0.3em" fill="#FFFFFF" font-family="'Roboto', sans-serif" font-size="14" font-weight="bold" v-if="driverName[6]">{{ driverName[6] | subStr }}</text>    
                              </g>    
                          </g>

                          <g id="md-rc-child-8" class="md-rc-circle-child ">
                              <line x1="31%" y1="85%" x2="23%" y2="51%" v-if="driverName[7]"  class="md-child-circle-line" stroke-width="2" :stroke="defaultCircleColor"></line>
                              <g class="md-rc-circle-inner" :data-info="dataInfo[7]" v-if="driverName[7]">
                                  <circle ref="RcRefChild8" r="6%" cx="31%" cy="85%" fill="#FFFFFF" stroke-width="2" :stroke="defaultCircleColor" style="transform-origin: 31% 85%;" class="md-rc-circle-back md-pulse-animation"></circle>
                                  <circle cx="31%" cy="85%" :fill="defaultCircleColor" stroke="none" style="transform-origin: 31% 85%;" class="md-rc-circle-front"></circle>
                                  <text x="31%" y="85%" text-anchor="middle" dy="0.3em" fill="#FFFFFF" font-family="'Roboto', sans-serif" font-size="14" font-weight="bold" v-if="driverName[7]">{{ driverName[7] | subStr }}</text>    
                              </g>    
                          </g>

                          <g id="md-rc-child-9" class="md-rc-circle-child ">
                              <line x1="10%" y1="78%" x2="23%" y2="51%" v-if="driverName[8]"  class="md-child-circle-line" stroke-width="2" :stroke="defaultCircleColor"></line>
                              <g class="md-rc-circle-inner" :data-info="dataInfo[8]" v-if="driverName[8]">
                                  <circle ref="RcRefChild9" r="6%" cx="10%" cy="78%" fill="#FFFFFF" stroke-width="2" :stroke="defaultCircleColor" style="transform-origin: 10% 78%;" class="md-rc-circle-back md-pulse-animation"></circle>
                                  <circle cx="10%" cy="78%" :fill="defaultCircleColor" stroke="none" style="transform-origin: 10% 78%;" class="md-rc-circle-front"></circle>
                                  <text x="10%" y="78%" text-anchor="middle" dy="0.3em" fill="#FFFFFF" font-family="'Roboto', sans-serif" font-size="14" font-weight="bold" v-if="driverName[8]">{{ driverName[8] | subStr }}</text>    
                              </g>    
                          </g>

                          <g id="md-rc-child-10" class="md-rc-circle-child ">
                              <line x1="7%" y1="32.5%" x2="23%" y2="51%" v-if="driverName[9]"  class="md-child-circle-line" stroke-width="2" :stroke="defaultCircleColor"></line>
                              <g class="md-rc-circle-inner" :data-info="dataInfo[9]" v-if="driverName[9]">
                                  <circle ref="RcRefChild10" r="6%" cx="7%" cy="32.5%" fill="#FFFFFF" stroke-width="2" :stroke="defaultCircleColor" style="transform-origin: 7% 32.5%;" class="md-rc-circle-back md-pulse-animation"></circle>
                                  <circle cx="7%" cy="32.5%" :fill="defaultCircleColor" stroke="none" style="transform-origin: 7% 32.5%;" class="md-rc-circle-front"></circle>
                                  <text x="7%" y="32.5%" text-anchor="middle" dy="0.3em" fill="#FFFFFF" font-family="'Roboto', sans-serif" font-size="14" font-weight="bold" v-if="driverName[9]">{{ driverName[9] | subStr }}</text>    
                              </g>    
                          </g>
                          <!-- <g id="md-rc-child-1" class="md-rc-circle-child">
                              <line x1="13%" y1="18%" x2="23%" y2="51%" class="md-child-circle-line" stroke-width="2" :stroke="defaultCircleColor"></line>
                              <g class="md-rc-circle-inner" :data-info="dataInfo[0]">
                                  <circle ref="RcRefChild5" r="6%" cx="13%" cy="18%" fill="#FFFFFF" stroke-width="2" :stroke="defaultCircleColor" style="transform-origin: 13% 18%;" class="md-rc-circle-back md-pulse-animation"></circle>
                                  <circle cx="13%" cy="18%" :fill="defaultCircleColor" stroke="none" style="transform-origin: 13% 18%;" class="md-rc-circle-front"></circle>
                                  <text x="13%" y="18%" text-anchor="middle" dy="0.3em" fill="#FFFFFF" font-family="'Roboto', sans-serif" font-size="14" font-weight="bold">{{ driverName1 | subStr }}</text>   
                              </g>     
                          </g>

                          <g id="md-rc-child-2" class="md-rc-circle-child">
                              <line x1="35%" y1="22%" x2="23%" y2="51%" class="md-child-circle-line" stroke-width="2" :stroke="defaultCircleColor"></line>
                              <g class="md-rc-circle-inner" :data-info="dataInfo[1]">
                                  <circle ref="RcRefChild4" r="6%" cx="35%" cy="22%" fill="#FFFFFF" stroke-width="2" :stroke="defaultCircleColor" style="transform-origin: 35% 22%;" class="md-rc-circle-back md-pulse-animation"></circle>
                                  <circle cx="35%" cy="22%" :fill="defaultCircleColor" stroke="none" style="transform-origin: 35% 22%;" class="md-rc-circle-front"></circle>
                                  <text x="35%" y="22%" text-anchor="middle" dy="0.3em" fill="#FFFFFF" font-family="'Roboto', sans-serif" font-size="14" font-weight="bold">{{ driverName2 | subStr }}</text>    
                              </g>    
                          </g>

                          <g id="md-rc-child-3" class="md-rc-circle-child">
                              <line x1="40%" y1="69%" x2="23%" y2="51%" class="md-child-circle-line" stroke-width="2" :stroke="defaultCircleColor"></line>
                              <g class="md-rc-circle-inner" :data-info="dataInfo[2]">
                                  <circle ref="RcRefChild3" r="6%" cx="40%" cy="69%" fill="#FFFFFF" stroke-width="2" :stroke="defaultCircleColor" style="transform-origin: 40% 69%;" class="md-rc-circle-back md-pulse-animation"></circle>
                                  <circle cx="40%" cy="69%" :fill="defaultCircleColor" stroke="none" style="transform-origin: 40% 69%;" class="md-rc-circle-front"></circle>
                                  <text x="40%" y="69%" text-anchor="middle" dy="0.3em" fill="#FFFFFF" font-family="'Roboto', sans-serif" font-size="14" font-weight="bold">{{ driverName3 | subStr }}</text>     
                              </g>   
                          </g>

                          <g id="md-rc-child-4" class="md-rc-circle-child">
                              <line x1="21%" y1="89%" x2="23%" y2="51%" class="md-child-circle-line" stroke-width="2" :stroke="defaultCircleColor"></line>
                              <g class="md-rc-circle-inner" :data-info="dataInfo[3]">
                                  <circle ref="RcRefChild2" r="6%" cx="21%" cy="89%" fill="#FFFFFF" stroke-width="2" :stroke="defaultCircleColor" style="transform-origin: 21% 89%;" class="md-rc-circle-back md-pulse-animation"></circle>
                                  <circle cx="21%" cy="89%" :fill="defaultCircleColor" stroke="none" style="transform-origin: 21% 89%;" class="md-rc-circle-front"></circle>
                                  <text x="21%" y="89%" text-anchor="middle" dy="0.3em" fill="#FFFFFF" font-family="'Roboto', sans-serif" font-size="14" font-weight="bold">{{ driverName4 | subStr }}</text>   
                              </g>     
                          </g>

                          <g id="md-rc-child-5" class="md-rc-circle-child">
                              <line x1="5%" y1="60%" x2="23%" y2="51%"  class="md-child-circle-line" stroke-width="2" :stroke="defaultCircleColor"></line>
                              <g class="md-rc-circle-inner" :data-info="dataInfo[4]">
                                  <circle ref="RcRefChild1" r="6%" cx="5%" cy="60%" fill="#FFFFFF" stroke-width="2" :stroke="defaultCircleColor" style="transform-origin: 6% 60%;" class="md-rc-circle-back md-pulse-animation"></circle>
                                  <circle cx="5%" cy="60%" :fill="defaultCircleColor" stroke="none" style="transform-origin: 6% 60%;" class="md-rc-circle-front"></circle>
                                  <text x="5%" y="60%" text-anchor="middle" dy="0.3em" fill="#FFFFFF" font-family="'Roboto', sans-serif" font-size="14" font-weight="bold">{{ driverName5 | subStr }}</text>    
                              </g>    
                          </g> -->
                          
                          <g id="md-rc-parent-default" :data-cat="overspeedCat" class="md-rc-circle-main">
                              <g class="md-rc-circle-inner"> <!-- data-info="<div class='md-rc-tooltip-box-inner'><div class='md-rc-tooltip-box-title'>Selected Circle</div><div class='md-rc-tooltip-box-data'>Value: <strong>16%</strong></div></div>" -->
                                  <circle ref="RcRefDefault" :r="defaultCircleRadius" cx="23%" cy="51%" style="transform-origin: 23% 51%;" class="md-rc-circle-back md-pulse-animation2 active-main" fill="#FFFFFF" stroke-width="2" :stroke="defaultCircleColor"></circle>
                                  <circle cx="23%" cy="51%" style="transform-origin: 23% 51%;" class="md-rc-circle-front" :fill="defaultCircleColor" stroke="none"></circle>
                                  <text x="23%" y="51%" text-anchor="middle" dy="0.3em" fill="#FFFFFF" font-family="'Roboto', sans-serif" font-size="22" font-weight="bold">{{defaultCircleValue}}</text>
                              </g>
                          </g>        

                      </svg>      
                      <div id="md-rc-tooltip-box"></div>
                  </div>  
                </div>
            <!-- </div> -->
          </div>
        </div>
 
      </div>
        <!-- Drive Relation Chart -->

    </div> 
    <md-dialog class="md-driver-drilldown" :md-active.sync="showDrill">
      <DrillDownGrid v-if="showDrill" :data="widgetData" :gridSettingsKey="widgetData.widgetName"></DrillDownGrid>
    </md-dialog>   
  </div>
</template>

<script>

// import Vue from 'vue'
import JQuery from "jquery";
import dashboardService from "../../../service/dashboard.service";
import { mutations, getters } from "../shared/observeDashboardFilter";

export default {
  name: 'driverelationchart',  
  components: {
      // JQuery
  },  
  data: function() {
      return {
        showDrill:false,
        loaderHide:false,
        defaultCircleRadius: "12%",
        defaultCircleColor: "#FFB30A",
        defaultCircleValue: "0%",
        overspeedOriginalValue: "-",
        overspeedLabel: this.$tc("Dashboard.overspeeding"),
        overspeedCat: "os",
        
        haRadius: "7%",
        haValue: "0%",
        haCircleColor: "#77BF41",
        haOriginalValue: "-",
        haLabel: this.$tc("FleetLabels.HarshAcceleration"),
        haCat:"ha",

        hbRadius: "7%",
        hbValue: "0%",
        hbCircleColor: "#F47520",
        hbOriginalValue: "-",
        hbLabel: this.$tc("FleetLabels.HarshBraking"),
        hbCat:"hb",

        cdRadius: "7%",
        cdValue: "0%",
        cdCircleColor: "#00A7DA",
        cdOriginalValue: "-",
        cdLabel: "Continuous Driving",
        cdCat:"cd",

        hcRadius: "7%",
        hcValue: "0%",
        hcCircleColor: "#008D72",
        hcOriginalValue: "-",
        hcLabel: this.$tc("FleetLabels.HarshCornering"),
        hcCat:"hc",

        ndRadius: "7%",
        ndValue: "0%",
        ndCircleColor: "#F0313A",
        ndOriginalValue: "-",
        ndLabel: "Night Driving",
        ndCat:"nd",

        // driver: [{"firstname":""},{"firstname":""},{"firstname":""},{"firstname":""},{"firstname":""}],
        // driverName1: "",
        // driverName2: "",
        // driverName3: "",
        // driverName4: "",
        // driverName5: "",
        dataInfo:[],
        driverName:[],
        driver1:[],
        selectedCat:[],
      }
  },
  props: {
    widgetData: Object
  },
  filters: {  
      subStr: function(string) {       
        return (string.length > 5 ) ? string.substring(0,5) + '..' : string;
      }
  },
  updated() {
    let self = this;
    let svgShape = this.$refs;
    let jquery = JQuery;
    svgShape = Object.keys(svgShape);
    svgShape.forEach(element => {
      element = self.$refs[element];
      if (element && (element.tagName == 'circle') && element.nextSibling && element.nextSibling.nextSibling) {
        let reduceRadius = 3;
        if (element.classList.contains("active-main")) {
          reduceRadius = 7;
        }
        element.nextSibling.setAttribute('r', element.getBBox().width / 2 - reduceRadius);
      }
    });


    jquery("[data-info]").hover(function () {
      jquery("#md-rc-tooltip-box").css("display", "block");
      // jquery("#md-rc-tooltip-box").html(jquery(this).data("info"));
      jquery("#md-rc-tooltip-box").html(jquery(this).attr('data-info'));
    });

    jquery("[data-info]").mouseleave(function () {
      jquery("#md-rc-tooltip-box").css("display", "none");
    });

    jquery(".md-drive-relation-main")
      .mousemove(function (e) {
        jquery("#md-rc-tooltip-box").css("top", e.clientY - jquery("#md-rc-tooltip-box").height() - 35);
        jquery("#md-rc-tooltip-box").css("left", e.clientX - jquery("#md-rc-tooltip-box").width() / 2);
      })
      .mouseover();

  },
  created(){
    this.DriveRelationChartData();     
  },
  mounted() {
    let self = this;
    let jquery = JQuery;
    
    jquery('.md-rc-circle-main').click(function () {
      
      // jquery('.md-rc-circle-main').removeClass('md-rc-default');
      let dataCat = jquery(this).data("cat");
      let defaultDataCat = jquery("#md-rc-parent-default").data("cat");
      if (jquery(this)[0].id == "md-rc-parent-default") {
        // if child are hiding than remove lines          
        if (jquery("#md-rc-parent-default").hasClass('active')) {
          jquery(document).find(".md-rc-circle-line").removeClass("active");
          // update tooltip when parent closed
          self.selectedCat = [];
          self.selectedCat.push(dataCat);
          self.createTooltip(self.driver1[defaultDataCat]);
          self.$forceUpdate();
        }
        jquery('.md-drive-relation-chart').toggleClass('md-drive-rc-onload');
        jquery('.md-rc-circle-child').toggleClass('active');
        jquery('#md-rc-parent-default').toggleClass('active');
      } else {
        if (jquery("#md-rc-parent-default").hasClass('active')) {
          // draw line
          var fromlineColor = jquery(this).find('.md-rc-circle-back').first().attr('stroke');
          var tolineColor = jquery("#md-rc-parent-default").find('.md-rc-circle-back').first().attr('stroke');
           
          if(jquery(this).find(".md-rc-circle-line").hasClass('active'))
          {
              // Remove tooltip when line removed
              var filteredAry = self.selectedCat.filter(function(e) { return e !== dataCat });
              self.selectedCat = filteredAry;
              self.createTooltip(self.driver1[defaultDataCat]);
              self.$forceUpdate();
          }
          else
          {
            // Add tooltip when line removed
            self.selectedCat.push(dataCat);
            self.createTooltip(self.driver1[defaultDataCat]);
          }
          jquery(this).find(".md-rc-circle-line").toggleClass("active");
          jquery(this).find("linearGradient stop").first().attr("stop-color", tolineColor);
          jquery(this).find("linearGradient stop").last().attr("stop-color", fromlineColor);
          self.$forceUpdate();
          //jquery(this).find(".linearGradient")              

        } else {
          // Change default circle color
          jquery('.md-drive-relation-chart').removeClass('md-drive-rc-onload');
          jquery('.md-rc-circle-child').addClass('active');
          jquery('#md-rc-parent-default').addClass('active');

          var fromColor = jquery(this).find('.md-rc-circle-back').first().attr('stroke');
          var toColor = jquery("#md-rc-parent-default").find('.md-rc-circle-back').first().attr('stroke');

          var fromText = jquery(this).find('text').text();
          var toText = jquery("#md-rc-parent-default").find('text').text();

          var fromRadius = jquery(this).find('.md-rc-circle-back').attr('r');
          var toRadius = jquery("#md-rc-parent-default").find('.md-rc-circle-back').attr('r');
          var fromInnerRadius = jquery(this).find('.md-rc-circle-back')[0].getBBox().width / 2;
          var toInnerRadius = jquery("#md-rc-parent-default").find('.md-rc-circle-back')[0].getBBox().width / 2;

          // set color on default circle and its child circle
          jquery("#md-rc-parent-default").find('.md-rc-circle-back').attr("stroke", fromColor);
          jquery("#md-rc-parent-default").find('.md-rc-circle-front').attr("fill", fromColor);
          

          // update text
          jquery(this).find('text').text(toText);
          jquery("#md-rc-parent-default").find('text').text(fromText);

          // update radius
          jquery(this).find('.md-rc-circle-back').first().attr('r', toRadius);
          jquery(this).find('.md-rc-circle-front').attr('r', toInnerRadius - 3);
          jquery("#md-rc-parent-default").find('.md-rc-circle-back').first().attr('r', fromRadius);
          jquery("#md-rc-parent-default").find('.md-rc-circle-front').attr('r', fromInnerRadius - 7);

          // set color on clicked circle
          jquery(this).find('.md-rc-circle-back').attr("stroke", toColor);
          jquery(this).find('.md-rc-circle-front').attr("fill", toColor);

          //change cat data attr
          jquery(this).data("cat",jquery("#md-rc-parent-default").data("cat"));
          jquery("#md-rc-parent-default").data("cat",dataCat);

          self.selectedCat = [];
          self.selectedCat.push(dataCat);
          self.createTooltip(self.driver1[dataCat]);
          self.drivernameupdate(self.driver1[dataCat]);
          self.$forceUpdate();
          setTimeout(() => {
            jquery(".md-rc-circle-child.active").find('.md-rc-circle-back,.md-child-circle-line').attr("stroke", fromColor);
            jquery(".md-rc-circle-child.active").find('.md-rc-circle-front').attr("fill", fromColor);
          }, 100);
          

        }

      }

    });
  },
  methods: {
    calRadius(r){
      let radius = r/2;
      if(radius < 7) {
        radius = 7;
      } else if (radius > 12) {
        radius = 12;
      }
      return radius+"%";
    },
    createTooltip(topdriver){
      let self = this;
      self.dataInfo = [];
      if(topdriver)
      {
        topdriver.forEach((element, i) => {
            let tooltipHtml = '';
            tooltipHtml = `<div class='md-rc-tooltip-box-inner'><div class='md-rc-tooltip-box-title'><span style="font-weight:bold; font-family:Roboto; font-size:14px">${element.fullname}</span></div>`;
            tooltipHtml += (/* element.OS > 0 && */ self.selectedCat.indexOf("os") >=0 ) ? `<div class='md-rc-tooltip-box-data tooltip-data-os'>OS: <span style="font-weight:bold; font-family:Roboto; font-size:14px">${element.OS}</span></div>` : '';
            tooltipHtml += (/* element.HA > 0 && */ self.selectedCat.indexOf("ha") >=0 ) ? `<div class='md-rc-tooltip-box-data tooltip-data-ha'>HA: <span style="font-weight:bold; font-family:Roboto; font-size:14px">${element.HA}</span></div>` : '';
            tooltipHtml += (/* element.HB > 0 && */ self.selectedCat.indexOf("hb") >=0 ) ? `<div class='md-rc-tooltip-box-data tooltip-data-hb'>HB: <span style="font-weight:bold; font-family:Roboto; font-size:14px">${element.HB}</span></div>` : '';
            tooltipHtml += (/* element.HC > 0 && */ self.selectedCat.indexOf("hc") >=0 ) ? `<div class='md-rc-tooltip-box-data tooltip-data-hc'>HC: <span style="font-weight:bold; font-family:Roboto; font-size:14px">${element.HC}</span></div>` : '';
            tooltipHtml += (/* element.CD > 0 && */ self.selectedCat.indexOf("cd") >=0 ) ? `<div class='md-rc-tooltip-box-data tooltip-data-cd'>CD: <span style="font-weight:bold; font-family:Roboto; font-size:14px">${element.CD}</span></div>` : '';
            tooltipHtml += (/* element.ND > 0 && */ self.selectedCat.indexOf("nd") >=0 ) ? `<div class='md-rc-tooltip-box-data tooltip-data-nd'>ND: <span style="font-weight:bold; font-family:Roboto; font-size:14px">${element.ND}</span></div>` : '';
            tooltipHtml += `</div>`;
            self.dataInfo[i] = tooltipHtml; 
          // return true;         
          });
        }
    },
    drivernameupdate(drivern){  
      this.driverName = [];
      if(drivern)
      {
        drivern.forEach((element, i) => {
          this.driverName[i] = drivern[i].firstname;
        }); 
      }
        // this.driverName1 = drivern[0].firstname;
        // this.driverName2 = drivern[1].firstname;
        // this.driverName3 = drivern[2].firstname;
        // this.driverName4 = drivern[3].firstname;
        // this.driverName5 = drivern[4].firstname;
    },
    DriveRelationChartData() {
      
      setTimeout(() => {
        let self = this;
        self.loaderHide=false;        
        dashboardService.getWidgetData(this.widgetData.api, getters.getFilterParams() ).then(results => {      
        if(results.data)
        {
          self.defaultCircleRadius = self.calRadius(results.data[0].percentage);//"12%";
          self.defaultCircleValue = results.data[0].percentage+"%";
          self.defaultCircleColor = results.data[0].color;
          self.overspeedOriginalValue = results.data[0].originalValue;
          self.overspeedLabel = results.data[0].name;
          
          self.haRadius = self.calRadius(results.data[1].percentage);//"8%";
          self.haValue = results.data[1].percentage+"%";
          self.haCircleColor = results.data[1].color;
          self.haOriginalValue = results.data[1].originalValue;
          self.haLabel = results.data[1].name;

          self.hcRadius = self.calRadius(results.data[2].percentage); //"11%";
          self.hcValue = results.data[2].percentage+"%";
          self.hcCircleColor = results.data[2].color;
          self.hcOriginalValue = results.data[2].originalValue;
          self.hcLabel = results.data[2].name 

          self.hbRadius = self.calRadius(results.data[3].percentage); //"10%";
          self.hbValue = results.data[3].percentage+"%";
          self.hbCircleColor = results.data[3].color;
          self.hbOriginalValue = results.data[3].originalValue;
          self.hbLabel = results.data[3].name;

          /* self.cdRadius = self.calRadius(results.data[4].percentage); //"9%";
          self.cdValue = results.data[4].percentage+"%";
          self.cdCircleColor = results.data[4].color;
          self.cdOriginalValue = results.data[4].originalValue;
          self.cdLabel = results.data[4].name;

          self.ndRadius = self.calRadius(results.data[5].percentage); //"7%";
          self.ndValue = results.data[5].percentage+"%";
          self.ndCircleColor = results.data[5].color;
          self.ndOriginalValue = results.data[5].originalValue;
          self.ndLabel = results.data[5].name; */
          
          self.driver1['os'] = results.data[0].toplist
          self.driver1['ha'] = results.data[1].toplist
          self.driver1['hc'] = results.data[2].toplist
          self.driver1['hb'] = results.data[3].toplist
          //self.driver1['cd'] = results.data[4].toplist
          //self.driver1['nd'] = results.data[5].toplist
          self.driver = self.driver1['os'];
          self.selectedCat.push("os");
          //self.selectedCat.push("ha");        
          self.createTooltip(self.driver);
          self.drivernameupdate(self.driver);
        }
      });
      this.loaderHide=true; 
      }, 2000);      

    },
    /* setHAV(hav) {
      this.haValue = hav;
      this.$forceUpdate();
    }  */   
  }
};

</script>

<style scoped>
/*
=============================================================
Please put your custom CSS below (CTrack BS Row Structure) 
============================================================
*/
/*CTrack BS Row Structure (Please don't change or use this CSS)*/
.tooltip-data-os{display:none;}
.ctrack-row{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-right:-10px;margin-left:-10px;}
[class*="ctrack-col-"]{position:relative;width:100%;min-height:1px;padding-right:10px;padding-left:10px;}
.ctrack-d-flex{display:-webkit-box!important;display:-ms-flexbox!important;display:flex!important;}
.ctrack-align-items-center{-webkit-box-align:center!important;-ms-flex-align:center!important;align-items:center!important;}
.ctrack-justify-content-center{-webkit-box-pack:center!important;-ms-flex-pack:center!important;justify-content:center!important;}
.ctrack-h-100{height: 100%;}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}

.ctrack-col-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}

@media only screen and (min-width:576px){
.ctrack-col-sm-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-sm-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-sm-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-sm-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-sm-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-sm-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-sm-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-sm-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-sm-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-sm-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-sm-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-sm-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}

@media only screen and (min-width:768px){
.ctrack-col-md-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-md-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-md-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-md-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-md-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-md-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-md-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-md-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-md-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-md-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-md-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-md-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}

@media only screen and (min-width:992px){
.ctrack-col-lg-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-lg-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-lg-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-lg-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-lg-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-lg-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-lg-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-lg-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-lg-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-lg-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-lg-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-lg-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}

@media only screen and (min-width:1200px){
.ctrack-col-xl-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-xl-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-xl-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-xl-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-xl-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-xl-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-xl-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-xl-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-xl-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-xl-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-xl-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-xl-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}

/*CTrack BS Row Structure (Please don't change or use above CSS)*/
/*
=============================================================
Start your custom CSS from here
============================================================
*/

/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
*{font-family: 'Roboto', sans-serif;}
.ctrack-h-100{height: 100%;}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.cd-chart-innerbox{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;width:100%;height:100%;background-color:#FFF;border-radius:5px;align-content: space-between; -webkit-align-content: space-between;}
.cd-chart-box-middle{padding:20px 30px;-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
.cd-chart-box-bottom{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;border-top:1px solid #E8E8E8;margin-top:20px;padding:18px 0 0;}
.cd-chart-box-top{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;padding:15px 20px;border-bottom:1px solid #E8E8E8;}
/* .cd-chart-box-img {text-align: center;} */
.cd-chart-indicator-style1{padding:0 35px 8px;color:#333333;display:inline-block;width:100%;font-size:15px;font-weight:500;}
.cd-chart-indicator-inner{display:inline-block;width:100%;position:relative;padding-left:32px;margin-bottom:12px;}
.cd-chart-indicator-inner .cd-chart-indicator-color{position:absolute;left:0;top:50%;width:25px;height:11px;border-radius:3.5px;transform:translateY(-50%);}
.cd-chart-box-header{display:table;width:100%;}
.cd-chart-box-header > div{display:table-cell;}
.cd-chart-box-col2{text-align:right;}
.cd-chart-box-col1 h3{color:#013C5E;font-size:18px;font-weight:700;}
.cd-chart-box-col1 h3 span{color:#979797;font-weight:400;}
.cd-chart-box-col2{font-size:14px;font-weight:bold;color:#333333;}

.md-indicator-align-right .cd-chart-indicator-inner{width:auto;}
.md-indicator-align-right{text-align:right;}


/* Responsive CSS */
@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1399px){}
@media only screen and (max-width:1299px){}
@media only screen and (max-width:1199px){
  .cd-chart-indicator-style1{padding: 0 20px 8px}
  .cd-chart-indicator-style2{padding: 0 10px 15px}
  .md-indicator-align-right{text-align: left;}
}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){
  .md-indicator-align-right{text-align: right;}
}
@media only screen and (max-width:767px){}
@media only screen and (max-width:575px){
  .cd-chart-box-middle {padding: 15px}
  .cd-chart-box-bottom{margin-top: 0;}
  .cd-chart-indicator-style1{padding:0 15px 5px;}

}

/* Network chart */
 *{box-sizing:border-box; margin: 0; padding: 0;} 
body{font-family: 'Roboto', sans-serif;}
header{text-align: center;padding: 5px 0;border-bottom: 1px solid #CCC; color: #888;}

/*Relation Chart Animation*/
.md-rc-circle-line, .md-rc-circle-child{visibility: hidden; opacity: 0;transition: opacity .2s ease-in-out; -webkit-transition: opacity .2s ease-in-out; -moz-transition: opacity .2s ease-in-out; -ms-transition: opacity .2s ease-in-out; -o-transition: opacity .2s ease-in-out;}
.md-rc-circle-line.active, .md-rc-circle-child.active{visibility: visible; opacity: 1;transition: opacity 2s ease-in-out; -webkit-transition: opacity 2s ease-in-out; -moz-transition: opacity 2s ease-in-out; -ms-transition: opacity 2s ease-in-out; -o-transition: opacity 2s ease-in-out;}
.md-rc-circle-line.active{transition: opacity .5s ease-in-out; -webkit-transition: opacity .5s ease-in-out; -moz-transition: opacity .5s ease-in-out; -ms-transition: opacity .5s ease-in-out; -o-transition: opacity .5s ease-in-out;}
.md-drive-rc-onload #md-rc-parent-default{transform:translate(-10%,0);-webkit-transform:translate(-10%,0);-moz-transform:translate(-10%,0);}
.md-drive-rc-onload #md-rc-parent-1{transform:translate(-25%,0);-webkit-transform:translate(-25%,0);-moz-transform:translate(-25%,0);}
.md-drive-rc-onload #md-rc-parent-2{transform:translate(-12%,5%);-webkit-transform:translate(-12%,5%);-moz-transform:translate(-12%,5%);}
.md-drive-rc-onload #md-rc-parent-3{transform:translate(-15%,0);-webkit-transform:translate(-15%,0);-moz-transform:translate(-15%,0);}
.md-drive-rc-onload #md-rc-parent-4{transform:translate(-5%,0);-webkit-transform:translate(-5%,0);-moz-transform:translate(-5%,0);}

#md-rc-tooltip-box{display:none;position:fixed;top:0px;left:0px;z-index:1;background-color:#FFFFFF;border:0px solid #ffffff;border-radius:5px;padding:8px 10px;font-size:13px;line-height:17px;font-family:'Roboto',sans-serif;color:#333333;box-shadow:0px 0px 9px rgba(0,0,0,0.45);-webkit-box-shadow:0px 0px 9px rgba(0,0,0,0.45);-moz-box-shadow:0px 0px 9px rgba(0,0,0,0.45);margin-left: -12px;z-index: 9999;}
#md-rc-tooltip-box:after{content:"";position:absolute;left:50%;margin-left:-4px;bottom:-7px;border-top:solid 10px #FFF;border-left:8px solid transparent;border-right:8px solid transparent;}
#md-rc-tooltip-box strong, .md-rc-tooltip-box-title{font-weight:bold;}

.md-rc-circle-main, .md-rc-circle-child{cursor: pointer;}
.md-rc-circle-main{transition: transform .5s ease-in-out; -webkit-transition: transform .5s ease-in-out; -moz-transition: transform .5s ease-in-out; -ms-transition: transform .5s ease-in-out; -o-transition: transform .5s ease-in-out;}
.md-pulse-animation1{-webkit-animation-name:md-pulse-animation;-webkit-animation-duration:3s;-webkit-animation-iteration-count:infinite;animation-name:md-pulse-animation;animation-duration:3s;animation-iteration-count:infinite;}
@-webkit-keyframes md-pulse-animation{
    0%{transform:scale(0.98);}
    70%{transform:scale(1);}
    100%{transform:scale(0.98);}
}
@keyframes md-pulse-animation{
    0%{transform:scale(0.98);}
    70%{transform:scale(1);}
    100%{transform:scale(0.98);}
}
.md-pulse-animation2{-webkit-animation-name:md-pulse-animation2;-webkit-animation-duration:3s;-webkit-animation-iteration-count:infinite;animation-name:md-pulse-animation2;animation-duration:3s;animation-iteration-count:infinite;}
@-webkit-keyframes md-pulse-animation2{
    0%{transform:scale(0.95);}
    70%{transform:scale(1);}
    100%{transform:scale(0.95);}
}
@keyframes md-pulse-animation2{
    0%{transform:scale(0.95);}
    70%{transform:scale(1);}
    100%{transform:scale(0.95);}
}
.md-child-circle-line{-webkit-animation-name:md-opacity-animation;-webkit-animation-duration:2s;-webkit-animation-iteration-count:1;animation-name:md-opacity-animation;animation-duration:2s;animation-iteration-count:1;-webkit-animation-timing-function:linear;animation-timing-function:linear;}
@-webkit-keyframes md-opacity-animation{
    0%{opacity:0}
    50%{opacity:0}
    100%{opacity:1}
}
@keyframes md-opacity-animation{
    0%{opacity:0}
    50%{opacity:0}
    100%{opacity:1}
}
.md-move-animation-top,.md-move-animation-right,.md-move-animation-bottom,.md-move-animation-left{-webkit-animation-duration:2s;-webkit-animation-iteration-count:infinite;animation-duration:2s;animation-iteration-count:infinite;-webkit-animation-timing-function:linear;animation-timing-function:linear;}
.md-move-animation-top{-webkit-animation-name:md-move-animation-top;animation-name:md-move-animation-top;}
@-webkit-keyframes md-move-animation-top{
    0%{transform:translate(0px,0px)}
    50%{transform:translate(0,5px)}
    100%{transform:translate(0px,0px)}
}
@keyframes md-move-animation-top{
    0%{transform:translate(0px,0px)}
    50%{transform:translate(0,5px)}
    100%{transform:translate(0px,0px)}
}
.md-move-animation-right{-webkit-animation-name:md-move-animation-right;animation-name:md-move-animation-right;}
@-webkit-keyframes md-move-animation-right{
    0%{transform:translate(0px,0px)}
    50%{transform:translate(5px,0)}
    100%{transform:translate(0px,0px)}
}
@keyframes md-move-animation-right{
    0%{transform:translate(0px,0px)}
    50%{transform:translate(5px,0)}
    100%{transform:translate(0px,0px)}
}
.md-move-animation-bottom{-webkit-animation-name:md-move-animation-bottom;animation-name:md-move-animation-bottom;}
@-webkit-keyframes md-move-animation-bottom{
    0%{transform:translate(0px,0px)}
    50%{transform:translate(0,-5px)}
    100%{transform:translate(0px,0px)}
}
@keyframes md-move-animation-bottom{
    0%{transform:translate(0px,0px)}
    50%{transform:translate(0,-5px)}
    100%{transform:translate(0px,0px)}
}
.md-move-animation-left{-webkit-animation-name:md-move-animation-left;animation-name:md-move-animation-left;}
@-webkit-keyframes md-move-animation-left{
    0%{transform:translate(0px,0px)}
    50%{transform:translate(-5px,0px)}
    100%{transform:translate(0px,0px)}
}
@keyframes md-move-animation-left{
    0%{transform:translate(0px,0px)}
    50%{transform:translate(-5px,0px)}
    100%{transform:translate(0px,0px)}
}
/* Network Chart */
</style>
