<template>
    <td :class="className" style="position: relative; overflow: visible;">
        <v-menu bottom offset-y attach absolute
                ref="cellMenu"               
                :close-on-content-click="notTrue">
            <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs"
                        v-on="on"
                        style="cursor:pointer">{{value.icon}}</v-icon>
            </template>
            <v-list style="padding: 0;">
                <v-list-item class="titleListItem" v-if="value?.title">
                    <v-toolbar dense
                               color="#5cc2ae">
                        <v-toolbar-title class="heading"
                                         style="color: #113651 !important; text-shadow: 0 0 black; display: contents;">
                            {{value?.title || ''}}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon
                               @click.stop="$refs.cellMenu.save();"
                               style="margin-right: -16px;">
                            <v-icon style="color: #113651 !important;">close</v-icon>
                        </v-btn>
                    </v-toolbar>
                </v-list-item>
                <v-list-group v-for="(item, index) in mainList"
                              :key="index"
                              :value="false"
                              no-action>
                    <template v-slot:activator>
                        <v-list-item-content @click.stop="">
                            <v-list-item-title>{{item}}</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item v-for="(item,eIndex) in subEntries(item)"
                                 :key="eIndex"
                                 link>
                        <v-list-item-title @click.stop="clickHandler(item)" 
                                           style="margin-left:8px;"
                                           class="hoverable">
                            {{item.child}}
                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </v-menu>
    </td>
</template>
<script>
    export default {
        name: "iconCell",
        data() {
            return {
                notTrue: false,
                value: this.dataItem[this.field]
            };
        },
        props: {
            field: String,
            dataItem: Object,
            format: String,
            className: String,
            columnIndex: Number,
            columnsCount: Number,
            rowType: String,
            level: Number,
            expanded: Boolean,
            editor: String
        },
        methods: {
            subEntries(item) {
                return this.value.list.filter(i => i.item == item).sort((a, b) => { return a.child - b.child })
            },
            clickHandler(item) {
                if (item.id) {
                    this.closeMenu();
                    this.$emit(this.editor, item);
                };
                
            },
            closeMenu() {
                this.$refs.cellMenu.isActive = false;     
            },
        },
        computed: {
            mainList() {
                return Array.isArray(this.value.list) ? [...new Set(this.value.list.map(i => i.item))].sort() : []
            },
        },
    };
</script>
<style scoped>
    .gridLink {
        color: #288dc0;
        cursor: pointer;
    }
    .titleListItem {
        padding: 0;
        position: sticky;
        top: 0px;
        background-color: white;
        z-index: 8;
    }
    ::v-deep .v-list-item.on-hover {
        background-color: #eeeeee
    }

    ::v-deep .v-list-group__header {
        border-top: thin solid white;
        border-bottom: thin solid white;
        padding-top: 4px;
        padding-bottom: 2px;
    }

        ::v-deep .v-list-group__header:hover {
            background-color: #66c2af !important;
            border-top: thin inset #102b49;
            border-bottom: thin groove #102b49;
        }

    ::v-deep .hoverable {
        padding-top: 4px;
        padding-bottom: 2px;
        border-top: thin solid white;
        border-bottom: thin solid white;
    }

        ::v-deep .hoverable:hover {
            background-color: #66c2af !important;
            border-top: thin inset #102b49;
            border-bottom: thin groove #102b49;
        }
    ::v-deep .v-list-item__title.hoverable {
        padding-left: 8px;
        padding-right: 8px;
    }
    ::v-deep .v-list-item--link {
        padding-left: 12px;
    }
</style>