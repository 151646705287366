import axios from 'axios';

class DeviceService {

    GetById(id) {
        return axios.get('/api/device/' + id );
    }
    Poll(deviceId, cellnumber) {
        return axios.get(`/api/device/poll/${deviceId}`, { params: { cellnumber: cellnumber } });
    }
    Immobilize(deviceId, cellnumber) {
        return axios.get(`/api/device/immobilize/${deviceId}`, { params: { cellnumber: cellnumber } });
    }
    Deimmobilize(deviceId, cellnumber) {
        return axios.get(`/api/device/deimmobilize/${deviceId}`, { params: { cellnumber: cellnumber } });
    }
    DeimmobilizeForcedOff(deviceId, cellnumber) {
        return axios.get(`/api/device/deimmobilizeforcedoff/${deviceId}`, { params: { cellnumber: cellnumber } });
    }
    SetHighUpdate(deviceId, cellnumber, duration ) {
        return axios.get(`/api/device/setHighUpdate/${deviceId}`, { params: { cellnumber: cellnumber, duration : duration } });
    }
    GetAllCommunicable() {
        return axios.get('/api/device/communicable/');
    }
    GetAll() {
        return axios.get('/api/device/all/');
    }

    SetDigitalOutputStates(deviceId, model) {
        return axios.post(`/api/device/setDigitalOutputStates/${deviceId}`, model)
    }

    DeleteAsset(id) {
        return axios.delete(`/api/device/${id}`);
    }
}

export default new DeviceService();