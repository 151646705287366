<template>
    <div>
        <v-list-item v-for="(type,index) in list" 
                     :key="index" 
                     class="groupItem">
            <div v-if="typeof(type) != 'object'" 
                 style="display: contents;">
                <v-list-item-content @click="$emit('filterByField',{filter:filter,type:emitValue(type)})">
                    <v-list-item-title style="display: inline-flex;" :class="[preFilter.length == 0 ? 'hoverable' : 'nonExpandingOption']">
                        {{type}}
                        <v-spacer></v-spacer>
                        <v-icon>
                            play_arrow
                        </v-icon>
                    </v-list-item-title>
                </v-list-item-content>
            </div>
            <v-list-item-content v-else>
                <v-list-item-group>
                    <v-list-group :value="false" 
                                  sub-group>
                        <template v-slot:activator 
                                  style="padding: 0;width:100%;">
                            <v-list-item-title  @click="$emit('filterByField',{filter:filter,type:emitValue(type.value)})">
                                {{type.value}}
                            </v-list-item-title>
                            <v-list-item-icon @click.stop="$emit('filterByField',{filter:filter,type:emitValue(type.value)})">
                                <v-icon>
                                    play_arrow
                                </v-icon>
                            </v-list-item-icon>
                        </template>
                        <filterOption :filter="filter"
                                      :list="type.options"
                                      :preFilter="emitValue(type.value)"
                                      @filterByField="(i) => $emit('filterByField',i)">
                        </filterOption>
                    </v-list-group>
                </v-list-item-group>
            </v-list-item-content>
        </v-list-item>
    </div>
</template>
<script>
    import filterOption from '@/views/Shared/GridTools/QuickFilterOption'
    export default {
        name: 'filterOption',
        components: {
            filterOption,
        },
        props: {
            filter:Object,
            list: Array,
            preFilter: {
                type: Array,
                default() {
                    return []
                },
            },
        },
        methods: {
            subCatClicked(event, type) {
                this.$emit('filterByField', { filter: filter, type: emitValue(type.value) })
            },
            emitValue(value) {
                return value ? [...this.preFilter, value] : [];
            },
        },
    };
</script>
<style scoped>
    .inlineTitle {
        display: inline-flex;
    }

    .groupItem {
        margin-left: 8px;
        font-size: 14px;
        min-height: 36px;
    }

    .v-list-group--sub-group {
        margin-right: -8px;
    }

    .v-list-item-group {
        width: 100%;
    }

    .v-list-item--link {
        margin-left: 4px;
        padding-right: 8px;
        padding-top: 2px;
    }

    .v-list-item__content {
        padding: 0;
    }



    ::v-deep .v-list-item__icon {
        margin: 0;
    }

    .nonExpandingOption {
        padding-left: 16px;
        padding-top: 4px;
        padding-bottom: 2px;
        border-top: thin solid white;
        border-bottom: thin solid white;
        padding-right: 8px;
    }

        .nonExpandingOption:hover {
            background-color: #66c2af !important;
            border-top: thin inset #102b49; 
            border-bottom: thin groove #102b49;
           
        }
    hoverable {
        padding-top: 4px;
        padding-bottom: 2px;
        border-top: thin solid white;
        border-bottom: thin solid white;
    }
    .hoverable:hover {
        background-color: #66c2af !important;
        border-top: thin inset #102b49;
        border-bottom: thin groove #102b49;
    }
    .v-list-item--active {
        background-color: white;
    }

    .v-list-item--link:before {
        background-color: white;
    }

    .v-list-item {
        background-color: white !important;
        border-top: thin solid white;
        border-bottom: thin solid white;
    }


    ::v-deep .v-list-group__header {
        min-height: 28px;
        padding-left: 0;
        padding-right: 8px;
        border-top: thin solid white;
        border-bottom: thin solid white;
        padding-top: 4px;
        padding-bottom: 2px;
        padding-right: 16px;
    }

    ::v-deep .v-list-group__header:hover {
        background-color: #66c2af !important;
        border-top: thin inset #102b49;
        border-bottom: thin groove #102b49;
    }
</style>