<template>
  <div class="driveTimeVsIdleTimeChart ctrack-h-100" ref="driveTimeVsIdleTime">
    <div id="vueapp" class="vue-app ctrack-h-100">
        
      <!-- Drive Time vs Idle Time Chart -->
      <div class="cd-chart-innerbox" :class="hideloaderClass">        
        <div class="cd-chart-box-top">
          <div class="cd-chart-box-header">
            <div class="cd-chart-box-col1">
              <h3>{{chartTitle}}</h3> 
            </div>
            <div class="cd-chart-box-col2" :class="loaderHide?'':'ctrack-chart-hide-loader'">
              <div class="md-view-detail-link" @click="showDrill = true;">
                  <svg version="1.1" width="20" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 89.64 90.91" style="enable-background:new 0 0 89.64 90.91;" xml:space="preserve"><g>
                      <path d="M5.93,84.99c27.97,0,55.8,0,83.71,0c0,2,0,3.93,0,5.92c-29.87,0-59.73,0-89.64,0C0,60.64,0,30.4,0,0.09
                      c1.96,0,3.91,0,5.93,0C5.93,28.38,5.93,56.61,5.93,84.99z"/>
                      <path d="M67.5,80.38c0-21.98,0-43.9,0-65.87c3.52,0,7,0,10.53,0c0,21.97,0,43.88,0,65.87C74.53,80.38,71.06,80.38,67.5,80.38z"/>
                      <path d="M30.45,25.58c-1.48,1.49-2.94,2.95-4.4,4.42c-2.77,2.77-5.54,5.53-8.3,8.31c-0.93,0.94-2.01,1.29-3.27,0.94
                      c-2.07-0.58-2.86-3.07-1.52-4.76c0.32-0.4,0.71-0.76,1.07-1.12c3.77-3.78,7.54-7.56,11.33-11.31c0.53-0.52,0.66-0.87,0.28-1.62
                      C23,15.15,23.27,9.97,26.97,5.33c3.65-4.58,8.59-6.28,14.29-4.83c5.75,1.46,9.26,5.35,10.22,11.18c0.89,5.44-0.86,10.1-5.41,13.36
                      c-4.63,3.33-9.62,3.59-14.74,1.02C31.07,25.94,30.83,25.79,30.45,25.58z M29.98,13.82c0,4.37,3.46,7.85,7.79,7.86
                      c4.42,0.01,7.94-3.46,7.95-7.82c0.01-4.27-3.54-7.74-7.91-7.75C33.48,6.1,29.98,9.55,29.98,13.82z"/>
                      <path d="M41.48,35.67c3.53,0,6.97,0,10.49,0c0,14.87,0,29.69,0,44.59c-3.48,0-6.95,0-10.49,0C41.48,65.41,41.48,50.59,41.48,35.67z
                      "/>
                      <path d="M26.12,80.4c-3.5,0-6.94,0-10.46,0c0-8.75,0-17.46,0-26.24c3.45,0,6.92,0,10.46,0C26.12,62.87,26.12,71.58,26.12,80.4z"/>
                  </g>
                  </svg>
              </div>
            </div>
          </div>
        </div>       
        <div class="cd-chart-box-middle">
          <div class="ctrack-md-loader" :class="loaderHide?'ctrack-chart-hide-loader':''"></div>
          <div class="cd-chart-box-img">
            <highcharts :options="chartOptions" :class="loaderHide?'':'ctrack-chart-hide-loader'"></highcharts>
            <!-- <img width="100%" src="../../../assets/images/dashboard/drive-time-vs-idle-time-chart.svg" /> -->
          </div>
        </div> 
        <div class="cd-chart-box-bottom" :class="loaderHide?'':'ctrack-chart-hide-loader'">
          <div class="cd-chart-indicator-style1">
            <div class="ctrack-row">
              <div class="ctrack-col-12" :key="i" v-for="(series, i) in chartOptions.series[0].data" ><div class="cd-chart-indicator-inner ctrack-text-truncate"><span :style="'background-color:'+series.color" class="cd-chart-indicator-color"></span>{{series.name}} ({{series.formattedTime}} {{series.timeUnit}})</div></div>
              <!-- <div class="ctrack-col-6" ><div class="cd-chart-indicator-inner ctrack-text-truncate"><span style="background-color:#2A48C2" class="cd-chart-indicator-color"></span>Drive Time</div></div> -->
              <!-- <div class="ctrack-col-6 "><div class="cd-chart-indicator-inner ctrack-text-truncate"><span style="background-color:#FFC20E" class="cd-chart-indicator-color"></span>Idle Time</div></div>               -->
            </div>
          </div>
        </div>
        <div class="md-no-data-found-msg"><span class="md-error-dataIcon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"><path d="M754.269,1479.679a.994.994,0,0,1-.1-1.255q2.3-4.585,4.6-9.167l6.755-13.474c.041-.083.08-.167.127-.247a.977.977,0,0,1,1.739.008c1.273,2.494,2.52,5,3.8,7.539l-1.774.883c-.959-1.907-1.908-3.794-2.89-5.746-3.322,6.624-6.612,13.188-9.918,19.78h19.855l-4.808-9.814,1.778-.872c.4.814.789,1.607,1.178,2.4,1.423,2.9,2.85,5.8,4.26,8.711a1.463,1.463,0,0,1,.139.795.968.968,0,0,1-1.076.773h-8.463q-7.16,0-14.322,0A1.117,1.117,0,0,1,754.269,1479.679Zm10.951-4.834a1.3,1.3,0,0,1,1.3-1.349,1.336,1.336,0,0,1,0,2.67A1.3,1.3,0,0,1,765.221,1474.845Zm.963-2.283a.937.937,0,0,1-.582-.905q-.067-1.2-.135-2.406c-.075-1.327-.145-2.654-.229-3.98a1.294,1.294,0,1,1,2.559,0c-.012.143-.023.287-.034.431h0c-.11,1.975-.217,3.949-.332,5.924a.958.958,0,0,1-.9,1A.941.941,0,0,1,766.184,1472.562Zm4.017-7.073,1.752-.917.792,1.5-1.757.917C770.721,1466.482,770.467,1466,770.2,1465.489Z" transform="translate(-753.998 -1455.009)"></path></svg></span> {{$t("Dashboard.noDataFound")}}</div>
      </div>
      <!-- Drive Time vs Idle Time Chart -->
        
    </div> 
    <md-dialog class="md-driver-drilldown" :md-active.sync="showDrill">
      <DrillDownGrid v-if="showDrill" :data="widgetData" :gridSettingsKey="widgetData.widgetName"></DrillDownGrid>
    </md-dialog>   
  </div>
</template>

<script>
import Vue from 'vue'
import HighchartsVue from 'highcharts-vue'
import dashboardService from "../../../service/dashboard.service";
import { mutations, getters } from "../shared/observeDashboardFilter";
import JQuery from "jquery";

Vue.use(HighchartsVue, {});
export default {
  name: 'driveTimeVsIdleTimeChart',
  components: {},
  data: function() {
    return {
      loaderHide:false,
      chartTitle:"",
      chartSubTitle:"",
      hideloaderClass:"",
      showDrill:false,
      chartOptions:{
        chart: {
          type: 'pie',
          events: {
            redraw: function() {
              if(document.getElementById("datalableicon"))
              {
                document.getElementById("datalableicon").remove();
              }
              // let svgUp = '<svg xmlns="http://www.w3.org/2000/svg" width="76.862" height="85.534" viewBox="0 0 76.862 85.534"><path d="M303.1,546.759H267.229c-1.8,0-2.537-.749-2.571-2.54-.079-4.129.031-8.227,1.594-12.14a23.378,23.378,0,0,1,12.719-13.215.9.9,0,0,1,1.26.3,28.813,28.813,0,0,0,18.557,10.324c10.69,1.415,19.717-2.035,26.933-10.09a1.281,1.281,0,0,1,1.826-.4c8.094,3.919,12.771,10.3,13.774,19.277.218,1.953.157,3.941.18,5.912.022,1.815-.739,2.566-2.535,2.566q-7.685,0-15.372,0Zm-15.4-26.586c-9.763-7.423-12.457-20.183-8.212-29.877,7.343,4.125,15.406,5.209,23.62,5.208,8.187,0,16.222-1.081,23.545-5.189,3.828,8.209,2.417,20.488-6.505,28.467a25.623,25.623,0,0,1-17.107,6.56A25.28,25.28,0,0,1,287.695,520.173Zm10.463-29.3a42.971,42.971,0,0,1-14.515-3.4,13.155,13.155,0,0,1-4.614-3.291,81.509,81.509,0,0,1,47.924-.065l.144.335c-1.036.754-2.021,1.6-3.121,2.244a30.326,30.326,0,0,1-10.082,3.391,59.6,59.6,0,0,1-10.8,1Q300.633,491.086,298.158,490.871ZM277.674,479.9a9.637,9.637,0,0,1,1.279-4.83,23.956,23.956,0,0,1,9.967-10.147,27.446,27.446,0,0,1,15.86-3.626c9.111.646,16.536,4.471,21.589,12.289a25.8,25.8,0,0,1,2.012,4.773,6.791,6.791,0,0,1,.173,1.553A85.5,85.5,0,0,0,277.674,479.9Z" transform="translate(-264.639 -461.226)" fill="#d5d5d5"/></svg>';
              let totalMilesLabel = this.userOptions.chartSubTitle + "<br>";
              /* let totalMilesValue = 0;
              $.each(this.userOptions.series[0].data, function (i,value) {
                totalMilesValue += value.y
              }) */
              let innerSize = this.userOptions.plotOptions.pie.innerSize; 
              this.renderer.html(totalMilesLabel+/* totalMilesValue */this.userOptions.totalTime,
                this.plotLeft + this.series[0].center[0],
                this.plotTop + this.series[0].center[1], // this.series[0].center[1]-30
                innerSize,
                innerSize).css({
                      // width: this.userOptions.plotOptions.pie.innerSize,
                      color: "#013C5E",
                      fontSize: "18px",
                      fontWeight: "700",
                      textAlign: 'center'
                })
                .attr({
                  align: 'center',
                  id:"datalableicon"
                })
                .add();
            },
        },
        options3d: {
                enabled: false,
                alpha: 45
            }
        },
        credits: {
                  enabled: false
        },
        title: {
              text: ''
        },     
        accessibility: {
          point: {
              valueSuffix: '%'
                }
          },
        tooltip: {
          backgroundColor: {
              linearGradient: [0, 0, 0, 60],
              stops: [
                  [0, '#FFFFFF'],
                  [1, '#FFFFFF']
              ]
          },
          outside: true,
          borderWidth: 0,
          formatter: function () {
              //let fistSeries = this.series.tooltipOptions.seriesData[1];
              //let secondSeries = this.series.tooltipOptions.seriesData[0];
              return '<b>' + this.point.name + '</b> : <b>' + this.point.formattedTime + " " + this.point.timeUnit + '</b><br/>'
          },
          style: {
              color: '#333333',
              fontSize: "14px",
              fontFamily: "Roboto"
          }
        },
        plotOptions: {
          pie: {         
            // colors: ['#EA2129','#5EAD2E','#FFA908'], 
            borderWidth: 4,
            allowPointSelect: true,
            cursor: 'pointer',
            innerSize: "50%",
            dataLabels:{
              enabled: true,
               // format: '<br>{point.percentage:.1f}%',
                formatter: function() {
                  return "<br>"+ this.percentage.toFixed(2) + "%";
                },
                distance: '-25%',
                position:"center",
                color:"#FFFFFF", 
                shadow:false,
                style:{
                  fontSize:"20px",
                  fontWeight:"bold",                  
                  fontFamily: 'Roboto,Medium',
                  textOutline:0,                                  
                  },
              }
            },
            series: {              
                  states: {                     
                    inactive: {
                      opacity:1
                    },
                    hover: {                    
                    halo: {
                        size: 0
                      }
                    }
                  }
                }, 
          },
        series:[{data:{}}],    
      }

    }   
  },
  props: {
    widgetData: Object
  },
  created(){
    this.DriveTimeVsIdleTimeChartData();        
  },  
  methods: {    
    DriveTimeVsIdleTimeChartData() {
      let self = this;
      self.loaderHide=false;
      dashboardService.getWidgetData( this.widgetData.api, getters.getFilterParams()).then(returnResult => {
        
        /* let  returnResult = {
          "Id": "DriveTimeVsIdleTimeChart",
          "data": {
            "chartTitle": "Drive Time vs Idle Time",
            "chartSubTitle": "Total Time",
            "totalTime": "23:45:48 HH:mm:ss",
            "series": [
              {
                "data": [
                  {
                    "name": "Drive Time",
                    "color": "#2A48C2",
                    "y": 34544.99,
                    "formattedTime": "16:45:20",
                    "timeUnit": "HH:mm:ss"
                  },
                  {
                    "name": "IdleTime",
                    "color": "#FFC20E",
                    "y": 654.89,
                    "formattedTime": "16:45:20",
                    "timeUnit": "HH:mm:ss"
                  }
                ]
              }
            ]
          }
        } */
        self.chartOptions.series = returnResult.data.series;
        self.chartOptions.series[0].data[0].className = "";
        self.chartOptions.series[0].data[1].className = "md-indicator-align-right";
        self.chartTitle = returnResult.data.chartTitle;
        self.chartOptions.chartSubTitle = returnResult.data.chartSubTitle;
        self.chartOptions.totalTime = returnResult.data.totalTime;
        self.loaderHide=true;
      })
       .catch(err => {
          self.hideloaderClass = 'cd-chart-ifDataBlank'
          self.loaderHide=true
      });
      
    }
  }
};
</script>
<style scoped>
/*
=============================================================
Please put your custom CSS below (CTrack BS Row Structure) 
============================================================
*/
/*CTrack BS Row Structure (Please don't change or use this CSS)*/
.ctrack-row{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-right:-10px;margin-left:-10px;}
[class*="ctrack-col-"]{position:relative;width:100%;min-height:1px;padding-right:10px;padding-left:10px;}
.ctrack-d-flex{display:-webkit-box!important;display:-ms-flexbox!important;display:flex!important;}
.ctrack-align-items-center{-webkit-box-align:center!important;-ms-flex-align:center!important;align-items:center!important;}
.ctrack-justify-content-center{-webkit-box-pack:center!important;-ms-flex-pack:center!important;justify-content:center!important;}
.ctrack-h-100{height: 100%;}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}

.ctrack-col-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}

@media only screen and (min-width:576px){
.ctrack-col-sm-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-sm-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-sm-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-sm-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-sm-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-sm-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-sm-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-sm-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-sm-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-sm-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-sm-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-sm-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}

@media only screen and (min-width:768px){
.ctrack-col-md-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-md-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-md-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-md-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-md-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-md-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-md-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-md-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-md-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-md-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-md-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-md-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}

@media only screen and (min-width:992px){
.ctrack-col-lg-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-lg-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-lg-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-lg-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-lg-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-lg-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-lg-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-lg-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-lg-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-lg-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-lg-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-lg-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}

@media only screen and (min-width:1200px){
.ctrack-col-xl-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-xl-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-xl-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-xl-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-xl-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-xl-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-xl-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-xl-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-xl-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-xl-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-xl-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-xl-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}

/*CTrack BS Row Structure (Please don't change or use above CSS)*/
/*
=============================================================
Start your custom CSS from here
============================================================
*/

/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
*{font-family: 'Roboto', sans-serif;}
.ctrack-h-100{height: 100%;}
[class*="md-chart-arrow"]{display:inline-block;vertical-align:middle;}
[class*="md-chart-arrow"] svg{display:block;}
.md-chart-arrow-down{transform:rotate(180deg); -webkit-transform:rotate(180deg); -moz-transform:rotate(180deg); -ms-transform:rotate(180deg); -o-transform:rotate(180deg);}
.cd-chart-innerbox{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;width:100%;height:100%;background-color:#FFF;border-radius:5px;align-content: space-between; -webkit-align-content: space-between;}
.ctrack-align-content-top{align-content: start; -webkit-align-content: start;}
.cd-chart-box-middle{padding:20px 30px;-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
.cd-chart-box-bottom{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;border-top:1px solid #E8E8E8;padding:18px 0 0;}
.cd-chart-box-top{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;padding:15px 20px;border-bottom:1px solid #E8E8E8;}
.cd-chart-box-img {text-align: center;}
.cd-chart-indicator-style1{padding:0 35px 0;color:#333333;display:inline-block;width:100%;font-size:15px;font-weight:500;}
.cd-chart-indicator-inner{display:inline-block;width:100%;position:relative;padding-left:32px;margin-bottom:12px;}
.cd-chart-indicator-inner .cd-chart-indicator-color{position:absolute;left:0;top:50%;width:25px;height:11px;border-radius:3.5px;transform:translateY(-50%);}
.md-indicator-align-right .cd-chart-indicator-inner{width:auto;}
.md-indicator-align-right{text-align:right;}


.cd-chart-box-header{display:table;width:100%;}
.cd-chart-box-header > div{display:table-cell;vertical-align: middle;}
.cd-chart-box-col1 h3{color:#013C5E;font-size:18px;font-weight:700;}
.cd-chart-box-col1 h3 span{color:#979797;font-weight:400;}
.cd-chart-box-col2{text-align:right;font-size:14px;font-weight:bold;color:#333333;}
.ctrack-select-main{display:inline-block;}
.ctrack-select-box{display:inline-block;font-family:'Roboto',sans-serif;font-weight:bold;color:#333333;font-size:14px;border:0;padding-right:2px;outline: 0}
.ctrack-chart-info{display:inline-block;vertical-align:middle;}
.ctrack-chart-info-btn,.ctrack-chart-info-btn svg{display:block;}
.ctrack-chart-info a{color:#e8e8e8 !important;}
.ctrack-chart-info a:hover{color:#cacaca !important;}

.cd-chart-left-selectbox h3{display: inline-block}
.ctrack-select-style2{width:100%;border:1px solid #d8d8d8;border-radius:5px;font-size:14px;font-family:'Roboto',sans-serif;color:#333333;padding:9px 30px 9px 10px;outline:0;-webkit-appearance:none;-moz-appearance:none;appearance:none;}
.cd-chart-left-selectbox .ctrack-select-main{padding-left:18px;width:168px;position:relative;}
.cd-chart-left-selectbox .ctrack-select-main:after{content:"";position:absolute;top:12px;right:16px;z-index:1;border-top:solid 2px #333333;border-right:solid 2px #333333;width:8px;height:8px;-webkit-pointer-events:none;-moz-pointer-events:none;pointer-events:none;-webkit-transform:rotate(135deg);-moz-transform:rotate(135deg);transform:rotate(135deg);}

.cd-indicator-pos-center{display:inline-block;width:100%;position:relative;top:-5px;}
.cd-indicator-pos-center .cd-indicator-pos-col{display:inline-block;vertical-align:middle;}
.cd-indicator-pos-center .cd-indicator-pos-col +  .cd-indicator-pos-col{padding-left: 40px;}
.cd-indicator-pos-center .cd-chart-indicator-inner{width:auto;padding-left: 40px;}
.cd-chart-indicator-inner{display:inline-block;width:100%;position:relative;padding-left:32px;margin-bottom:12px;text-align: left; color: #333333;font-size: 15px;font-weight: 500;}
.cd-chart-indicator-inner .cd-chart-indicator-color{position:absolute;left:0;top:50%;width:25px;height:11px;border-radius:3.5px;transform:translateY(-50%);}

/*No Data Section */
.cd-chart-ifDataBlank .cd-chart-box-top,.cd-chart-ifDataBlank .cd-chart-box-middle,.cd-chart-ifDataBlank .cd-chart-box-bottom,.cd-chart-ifDataBlank .ctrack-d-flexcol{visibility:hidden;}
.cd-chart-ifDataBlank .md-no-data-found-msg{display: block !important}
.md-no-data-found-msg{padding:15px 15px 15px 50px;position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);font-size:18px;color:#9e4e4e;border:1px solid #ffb2b2;background-color:#ffb2b2;border-radius:3px;display:none}
.md-no-data-found-msg .md-error-dataIcon{position:absolute;left:15px;top:50%;transform:translateY(-50%);}
.md-no-data-found-msg .md-error-dataIcon svg{display:block;}

/* Responsive CSS */
@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1349px){
.cd-indicator-pos-center .cd-indicator-pos-col + .cd-indicator-pos-col{padding-left: 20px;}
}
@media only screen and (max-width:1399px){
  .cd-chart-indicator-style1{padding:0 25px;}
}
@media only screen and (max-width:1299px){
  .cd-indicator-pos-center{top:0;padding-bottom:10px;text-align:left;}
  .cd-indicator-pos-center .cd-indicator-pos-col{width:100%;}
  .cd-indicator-pos-center .cd-indicator-pos-col + .cd-indicator-pos-col{padding-left:0;}
  .cd-chart-indicator-style1{padding: 0 15px;}
}
@media only screen and (max-width:1199px){
  .md-indicator-align-right{text-align: left;}
}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){
  .cd-chart-indicator-style1{padding: 0 30px;}
  .md-indicator-align-right{text-align: right;}
  .cd-indicator-pos-center{text-align:center;}
  .cd-indicator-pos-center .cd-indicator-pos-col{width:auto}
  .cd-indicator-pos-center .cd-indicator-pos-col + .cd-indicator-pos-col{padding-left:40px;}
}
@media only screen and (max-width:767px){}
@media only screen and (max-width:575px){
  .cd-chart-indicator-style1{padding: 0 15px;}
  .cd-indicator-pos-center .cd-chart-indicator-inner{padding-left: 35px;}
  .cd-indicator-pos-center .cd-indicator-pos-col + .cd-indicator-pos-col{padding-left:20px;}
}

</style>
