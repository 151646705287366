<template>
    <td :class="className">
        <span v-if="dataItem.cameras.length > 0">
            <v-tooltip bottom>
                <template #activator="tooltip">
                    <v-btn icon
                           v-on="{...tooltip.on }"
                           @click.stop="clickHandler">
                        <v-icon>mdi-video-wireless</v-icon>
                    </v-btn>
                </template>
                <span>Show live feed</span>
            </v-tooltip>
        </span>
    </td>
</template>
<script>
    export default {
        name: "LiveFeedCell",
        data() {
            return {
            };
        },
        props: {
            field: String,
            dataItem: Object,
            format: String,
            className: String,
            columnIndex: Number,
            columnsCount: Number,
            rowType: String,
            level: Number,
            expanded: Boolean,
            editor: String,
            cameras: Array
        },
        methods: {
            clickHandler() {
                this.$emit(this.editor, this.dataItem);
            }
            //showLiveFeed(event) {
            //    this.$vehicleEventHub.$emit('show-live-video-feed', this.dataItem);
            //    event.stopPropagation();
            //}
        }
    };
</script>
<style scoped>
</style>