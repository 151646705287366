<template>
    <!-- <md-dialog :md-active.sync="openPopUp"> -->
    <v-app class="grid-container-card" v-if="(columns.length>0) && (fileName)">
        <v-card class="list-overflow">
            <v-toolbar dark color="rgb(40, 141, 192, 0)" class="toolbar">
                <v-toolbar-title class="heading">{{headerName}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <emailDialog v-model="showEmailDialog" :send-email="exportEmail"></emailDialog>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon dark
                               v-bind="attrs"
                               v-on="on"
                               v-show="selected.length > 0">
                            <v-icon>mdi-export-variant</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="exportPDF">
                            <v-list-item-title><v-icon>mdi-file-pdf-box</v-icon>{{ $t("Location.exportPDF") }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="exportExcel">
                            <v-list-item-title><v-icon>mdi-file-excel</v-icon>{{ $t("Location.exportExcel") }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="showEmailDialog=!showEmailDialog">
                            <v-list-item-title><v-icon>mdi-email</v-icon>{{ $t("LocationLabels.exportToEmail") }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <ColumnSelector :columns.sync="columns"
                                :getDefaults="getColumns"
                                :dark="false"
                                :persist="setColumnConfig"
                                :additionalClass="additionalClass"></ColumnSelector>
            </v-toolbar>
            <div class="grid-container" style="overflow:auto">
                <grid-pdf-export ref="exporter" :fileName="`Dashboard_${fileName}`" :margin="'1cm'">
                    <Grid ref="dataGrid"
                          style="height: calc(100vh - 250px); "
                          :data-items="result"
                          :pageable="pageable"
                          :filterable="allowFilter"
                          :filter="filter"
                          :sortable="sortable"
                          :skip="skip"
                          :sort="sort"
                          :take="take"
                          :total="total"
                          :columns="columns"
                          :selected-field="'selected'"
                          :column-menu="true"
                          :reorderable="true"
                          :resizable="true"
                          @columnreorder="columnReorder"
                          @columnresize="columnResize"
                          @headerselectionchange="onHeaderSelectionChange"
                          @selectionchange="onSelectionChange"
                          @rowclick="onRowClick"
                          @filterchange="filterChange"
                          @sortchange="sortChangeHandler"
                          @pagechange="pageChangeHandler"
                          @ContactClick="showContactData">
                    </Grid>
                </grid-pdf-export>
            </div>
            <!-- </md-dialog> -->
        </v-card>
    </v-app>
</template>
<script>
    import contactService from "../../../service/contact.service";
    import dashboardService from "../../../service/dashboard.service";
    import {
        mutations,
        getters
    } from "../shared/observeDashboardFilter";
    import {
        i18n
    } from "../../../shared/i18n";
    import {
        ImageCell,
        DataCell,
        StatusCell,
        NavigateCell,
        ColumnSelector,
        GridOptions
    } from "../../Shared/index";
    import emailDialog from "@/components/dialogs/email-dialog.vue"

    export default {
        name: "drilldowngrid",
        components: {
            ColumnSelector,
            emailDialog
        },
        mixins: [GridOptions],
        data() {
            return {
                sort: [],
                columns: [],
                defaultColumns: [],
                openPopUp: true,
                headerName: '',
                allowFilter: true,
                config: {},
                columnConfig: {},
                additionalClass: "bottomauto",
                fileName: '',
                showEmailDialog: false,
            };
        },

        created() {
            this.config = this.$session.get("configProperties");
            this.columnConfig = this.getColumnConfig();
            this.apiCall();
        },
        props: {
            data: Object,
            gridSettingsKey: {
                type: String,
                default: "dashboardDrilldown"
            }
        },

        methods: {
            getColumnConfig() {
                if (this.gridSettingsKey === '') {
                    return {};
                }
                if (this.config.columnConfig !== undefined && this.config.columnConfig.length > 0) {
                    for (let i = 0; i < this.config.columnConfig.length; i++) {
                        if (this.config.columnConfig[i].name === this.gridSettingsKey) {
                            return this.config.columnConfig[i];
                        }
                    }
                }
                return {
                    name: this.gridSettingsKey
                };
            },
            setColumnWidths() {
                if (this.columnConfig.widths === undefined) {
                    return;
                }
                const columns = this.columnConfig.widths.split(',');
                columns.forEach(c => {
                    const fragments = c.split(':');
                    const field = fragments[0];
                    const width = fragments[1];
                    const column = this.columns.find(c => c.field === field);
                    if (width !== undefined && width != 0 && column) {
                        column.width = width;
                    }
                });
            },
            setColumnVisibilityAndOrder() {
                if (this.columnConfig.columns === undefined) {
                    return;
                }
                this.columns.forEach(c => {
                    c.hidden = true;
                    c.visible = false;
                });
                let columns = this.columnConfig.columns.split(',');
                for (let orderIndex = 0; orderIndex < columns.length; orderIndex++) {
                    let column = columns[orderIndex];
                    this.columns.forEach(c => {
                        if (c.field === column || c.field === 'selected') {
                            c.orderIndex = (c.field === 'selected') ? 0 : c.index;
                            c.hidden = false;
                            c.visible = true;
                        }
                        if (c.field === '' || c.field === undefined) {
                            c.orderIndex = c.index;
                            c.hidden = false;
                            c.visible = true;
                        }
                    });
                }
            },
            getDefaultColumns() {
                var columns = this.defaultColumns;

                for (let i = 0; i < columns.length; i++) {
                    var column = columns[i];
                    /*  column.hidden = false;
                     column.visible = true; */
                    if (column.default == undefined || column.default) {
                        column.hidden = false;
                        column.visible = true;
                    } else {
                        column.hidden = true;
                        column.visible = false;
                    }
                }
                return columns;
            },
            getColumns(schema) {
                if (schema == undefined) {
                    schema = this.selectedColumns;
                }

                for (let i = 0; i < schema.length; i++) {
                    var column = schema[i];
                    column.visible = column.default;
                    column.hidden = !column.default;
                    column.orderIndex = i;
                }
                return schema;
            },

            getImgUrl(profileicon) {
                if (profileicon == undefined && profileicon == "") {
                    return require(`@/icons/vehicles/car-inactive.svg`);
                } else {
                    return profileicon;
                }
            },
            showContactData() { },
            highlightRow() {
                // update class
                // let firstName = "407c2f0a-1c19-4d19-832d-45bef0085fbb";
                // $('.FirstName:contains("407c2f0a-1c19-4d19-832d-45bef0085fbb")').parent('tr').css('background', '#ff00ff')
            },
            apiCall() {
                var self = this;
                let params1 = getters.getFilterParams();

                let paramsToSend = Object.assign({}, params1);
                if (this.data.sortBy) {
                    //self.params= getters.getFilterParams();
                    paramsToSend['sort'] = this.data.sortBy;
                }
                dashboardService
                    .getWidgetData(this.data.drillAPI, paramsToSend /* getters.getFilterParams() */)
                    .then(result => {
                        self.selectedColumns = [];
                        self.defaultColumns = [];
                        self.headerName = result.titleOfDrillDown;
                        self.fileName = result.titleOfDrillDown.split(" ").join("_");
                        // self.columns = self.getColumns(result.schema);
                        if (result.count == 0) {
                            self.allowFilter = false;
                        }
                        const cte = {
                            field: (result.count > 0) ? "selected" : "",
                            width: (result.count > 0) ? "50px" : "520px",
                            headerSelectionValue: false,
                            columnMenu: false,
                            default: true,
                            reorderable: false,
                            resizable: false
                        }
                        let defaultCount = 0;
                        result.schema.forEach(headerField => {
                            if (headerField.default)
                                defaultCount++;
                        });
                        self.selectedColumns.push(cte);
                        self.defaultColumns.push(cte);
                        result.schema.forEach(headerField => {

                            let headerField1 = headerField;
                            headerField1.width = (defaultCount < 4 && headerField1.default) ? "280px" : "150px",
                                headerField1.className = headerField1.field
                            //headerField1.orderIndex =
                            self.selectedColumns.push(headerField1);
                            //self.defaultColumns.push(headerField);
                        });
                        let ttColums = [...self.selectedColumns];
                        self.columns = self.getColumns(ttColums);
                        self.defaultColumns = [...self.columns]
                        self.setColumnVisibilityAndOrder();
                        self.setColumnWidths();
                        if (result.sort) {
                            // self.sort.push({ field: 'RedCount', dir: 'asc' });
                            self.sort = result.sort;
                        }
                        /* self.dataItems = result.data.map(contact => ({
                          ...contact,
                                selected: false
                        })); */
                        result.data.forEach(contact => {
                            contact.selected = false,
                                self.dataItems.push(contact)
                        });

                        // if(self.columns.length > 10)
                        // self.additionalClass = "";
                        //$(".customSettingsScroll").addClass("bottom0");

                    }).then(() => {
                        self.highlightRow()
                    });
            }
        }
    };
</script>
<style scoped>
    .list-overflow {
        height: calc(100vh - 112px);
    }

    .grid-container-card {
        overflow: hidden;
        height: calc(100vh - 60px);
    }

    .heading {
        font-size: 36px;
        margin-bottom: 0;
    }

    .heading {
        font-size: 20px;
        padding-top: 4px;
    }

    .setting {
        margin: -50px -8px 0 0;
    }

    .upperheading {
        height: 65px;
    }

    .md-dialog-headerMain {
        position: relative;
    }

        .md-dialog-headerMain .bottomRightButtonsContainer > div {
            display: inline-block;
            vertical-align: middle;
            margin: 0;
        }

        .md-dialog-headerMain .bottomRightButtonsContainer {
            position: absolute;
            right: 10px;
            top: 15px;
        }
</style>
<style>
    .k-animation-container {
        z-index: 9911 !important
    }

    .md-driver-drilldown .md-dialog-container {
        overflow: auto;
    }

    .customSettingsScroll.bottom0 {
        bottom: auto !important
    }

        .customSettingsScroll.bottom0 .md-menu-content-container {
            max-height: 400px !important
        }
</style>