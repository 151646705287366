import reportService from "../service/reports.service";
import Common from "../../constant/common";
import CommonUtil from '../shared/common-utils';

const state = () => ({
    tripData: [],
    detailedSummaryData: {},
    drivingStyleSummaryData: {},
    stopIdleSummaryData: {},
    dailyTimecardSummaryData: {},
})

const getters = { 
    allModules: state => {
        return ["tripSummary", "detailedSummary", "drivingStyleSummary", "dailyTimecardSummary", "stopIdleSummary"]
    },
    getTripData: state => state.tripData,
    getDetailedSummaryData: state => state.detailedSummaryData,
    getDrivingStyleSummaryData: state => state.drivingStyleSummaryData,
    getStopIdleSummaryData: state => state.stopIdleSummaryData,
    getDailyTimecardSummaryData: state => state.dailyTimecardSummaryData
}

const actions = {
    getTrips({ commit }, data) {
        reportService.getAllTrips(
            data.startDate,
            data.endDate,
            data.idslist,
            data.type
        ).then(response => {
            /*var tripData = response.payload*/
            var tripData = response.payload.map((trip) => {
                        return {
                            id: trip.id,
                            vehicleName: trip.vehicleName,
                            totalDistance: (trip.totalDistance / 1000).toFixed(2) + " " + CommonUtil.getDistanceUnit(),
                            totalDriveTime: Common.converttime(trip.totalDriveTime)
                        }
                    })

            commit('setTrips', tripData);
        });
    },

    getDetailedTrip({ commit }, data) {
        reportService
            .getAveragesReportSummary(
                data.startDate,
                data.endDate,
                data.idslist,
                "detailedTripSummary",
                data.type
            )
            .then(response => {
                commit('setDetailedSummary', response);
            })
    },

    getDrivingStyleSummary({ commit }, data) {
        reportService
            .getAveragesReportSummary(
                data.startDate,
                data.endDate,
                data.idslist,
                "driverBehaviour",
                data.type
            )
            .then(response => {
                commit('setDrivingStyleSummary', response);
            })
    },

    getStopIdleSummary({ commit }, data) {
        reportService
            .getAveragesReportSummary(
                data.startDate,
                data.endDate,
                data.idslist,
                "idleReport",
                data.type
            )
            .then(response => {
                var chartData = {
                    Chart1: [(response.totalStopTime / 3600).toFixed(2), (response.averageStopTime / 3600).toFixed(2), 0, 0],
                    Chart2: [0, 0, (response.totalIdleTime / 3600).toFixed(2), (response.averageIdleTime / 3600).toFixed(2)]
                }
                commit('setStopIdleSummary', chartData);
            })
    },

    getDailyTimecardSummary({ commit }, data) {
        reportService
            .getAveragesReportSummary(
                data.startDate,
                data.endDate,
                data.idslist,
                "dailyReport",
                data.type
            )
            .then(response => {
                var chartData = {
                    Chart1: [(response.totalDistance / 1000).toFixed(2), (response.averageVehicleDistance / 1000).toFixed(2), 0, 0],
                    Chart2: [0, 0, (response.totalActiveHours / 3600).toFixed(2), (response.averageActiveTime / 3600).toFixed(2)]
                }
                commit('setDailyTimecardSummary', chartData);
            })
    }
}

const mutations = {
    setTrips(state, tripData) {
        state.tripData = tripData;
    },

    setDetailedSummary(state, detailedSummaryData) {
        state.detailedSummaryData = detailedSummaryData
    },

    setDrivingStyleSummary(state, drivingStyleSummaryData) {
        state.drivingStyleSummaryData = drivingStyleSummaryData
    },

    setStopIdleSummary(state, stopIdleSummaryData) {
        state.stopIdleSummaryData = stopIdleSummaryData
    },

    setDailyTimecardSummary(state, dailyTimecardSummaryData) {
        state.dailyTimecardSummaryData = dailyTimecardSummaryData
    },
}

export default {
    modules: {
        driverReportStore: {
            namespaced: true,
            state,
            getters,
            actions,
            mutations
        }
    }
};