<template>
  <div class="milesPerDayBusinessAndPrivateChart ctrack-h-100" ref="milesPerDayBusinessAndPrivate">
    <div id="vueapp" class="vue-app ctrack-h-100">
        
      <!-- Miles Per Day Business & Private Chart -->
      <div class="cd-chart-innerbox" :class="hideloaderClass">        
        <div class="cd-chart-box-top">
          <div class="cd-chart-box-header">
            <div class="cd-chart-box-col1"><h3>{{chartTitle}}<!-- Miles Per Day Business &amp; Private --></h3></div>
            <div class="cd-chart-box-col2">
              <label class="cd-chart-right-lbl">{{chartAltTitleCaption}}<!-- Total Miles For the Period: --> <strong>{{chartAltTitleValue}}</strong></label>
              <div class="md-view-detail-link" @click="showDrill = true;">
                    <svg version="1.1" width="20" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 89.64 90.91" style="enable-background:new 0 0 89.64 90.91;" xml:space="preserve"><g>
                        <path d="M5.93,84.99c27.97,0,55.8,0,83.71,0c0,2,0,3.93,0,5.92c-29.87,0-59.73,0-89.64,0C0,60.64,0,30.4,0,0.09
                        c1.96,0,3.91,0,5.93,0C5.93,28.38,5.93,56.61,5.93,84.99z"/>
                        <path d="M67.5,80.38c0-21.98,0-43.9,0-65.87c3.52,0,7,0,10.53,0c0,21.97,0,43.88,0,65.87C74.53,80.38,71.06,80.38,67.5,80.38z"/>
                        <path d="M30.45,25.58c-1.48,1.49-2.94,2.95-4.4,4.42c-2.77,2.77-5.54,5.53-8.3,8.31c-0.93,0.94-2.01,1.29-3.27,0.94
                        c-2.07-0.58-2.86-3.07-1.52-4.76c0.32-0.4,0.71-0.76,1.07-1.12c3.77-3.78,7.54-7.56,11.33-11.31c0.53-0.52,0.66-0.87,0.28-1.62
                        C23,15.15,23.27,9.97,26.97,5.33c3.65-4.58,8.59-6.28,14.29-4.83c5.75,1.46,9.26,5.35,10.22,11.18c0.89,5.44-0.86,10.1-5.41,13.36
                        c-4.63,3.33-9.62,3.59-14.74,1.02C31.07,25.94,30.83,25.79,30.45,25.58z M29.98,13.82c0,4.37,3.46,7.85,7.79,7.86
                        c4.42,0.01,7.94-3.46,7.95-7.82c0.01-4.27-3.54-7.74-7.91-7.75C33.48,6.1,29.98,9.55,29.98,13.82z"/>
                        <path d="M41.48,35.67c3.53,0,6.97,0,10.49,0c0,14.87,0,29.69,0,44.59c-3.48,0-6.95,0-10.49,0C41.48,65.41,41.48,50.59,41.48,35.67z
                        "/>
                        <path d="M26.12,80.4c-3.5,0-6.94,0-10.46,0c0-8.75,0-17.46,0-26.24c3.45,0,6.92,0,10.46,0C26.12,62.87,26.12,71.58,26.12,80.4z"/>
                    </g>
                    </svg>
                </div>
            </div>
          </div>
        </div>       
        <div class="cd-chart-box-middle">
          <div class="ctrack-md-loader" :class="loaderHide?'ctrack-chart-hide-loader':''"></div>
          <div class="cd-chart-box-img">
            <highcharts :options="chartOptions" ref="milesPerDayChart" :class="loaderHide?'':'ctrack-chart-hide-loader'"></highcharts>
            <!-- <img width="100%" src="../../../assets/images/dashboard/md-miles-er-day-business-and-private-chart.svg" /> -->
          </div>
        </div> 
        <div class="cd-chart-box-bottom cd-chart-indicator-right md-bottom-indicWithCheck">
          <div class="cd-chart-indicator-style1">
            <div class="ctrack-row">
              <div class="ctrack-col-md-4" :key="i" v-for="(series, i) in chartOptions.series" :class="series.className"><div class="cd-chart-indicator-inner ctrack-text-truncate"><span :style="'background-color:'+series.color" class="cd-chart-indicator-color"></span>{{series.name}}<div class="ctrack-right-toggle-btns"><label class="ctrack-switch"><input type="checkbox" checked @click="toggleSeries(i)"><span class="ctrack-slider ctrack-round"></span></label></div></div></div>
              <!-- <div class="ctrack-col-md-4"><div class="cd-chart-indicator-inner ctrack-text-truncate"><span style="background-color:#ED7D31" class="cd-chart-indicator-color"></span>Private Miles<div class="ctrack-right-toggle-btns"><label class="ctrack-switch"><input type="checkbox" checked><span class="ctrack-slider ctrack-round"></span></label></div></div></div>   
              <div class="ctrack-col-md-4"><div class="cd-chart-indicator-inner ctrack-text-truncate"><span style="background-color:#008D72" class="cd-chart-indicator-color"></span>Total Miles<div class="ctrack-right-toggle-btns"><label class="ctrack-switch"><input type="checkbox" checked><span class="ctrack-slider ctrack-round"></span></label></div></div></div>             -->
            </div>
          </div>
        </div> 
        <div class="md-no-data-found-msg"><span class="md-error-dataIcon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"><path d="M754.269,1479.679a.994.994,0,0,1-.1-1.255q2.3-4.585,4.6-9.167l6.755-13.474c.041-.083.08-.167.127-.247a.977.977,0,0,1,1.739.008c1.273,2.494,2.52,5,3.8,7.539l-1.774.883c-.959-1.907-1.908-3.794-2.89-5.746-3.322,6.624-6.612,13.188-9.918,19.78h19.855l-4.808-9.814,1.778-.872c.4.814.789,1.607,1.178,2.4,1.423,2.9,2.85,5.8,4.26,8.711a1.463,1.463,0,0,1,.139.795.968.968,0,0,1-1.076.773h-8.463q-7.16,0-14.322,0A1.117,1.117,0,0,1,754.269,1479.679Zm10.951-4.834a1.3,1.3,0,0,1,1.3-1.349,1.336,1.336,0,0,1,0,2.67A1.3,1.3,0,0,1,765.221,1474.845Zm.963-2.283a.937.937,0,0,1-.582-.905q-.067-1.2-.135-2.406c-.075-1.327-.145-2.654-.229-3.98a1.294,1.294,0,1,1,2.559,0c-.012.143-.023.287-.034.431h0c-.11,1.975-.217,3.949-.332,5.924a.958.958,0,0,1-.9,1A.941.941,0,0,1,766.184,1472.562Zm4.017-7.073,1.752-.917.792,1.5-1.757.917C770.721,1466.482,770.467,1466,770.2,1465.489Z" transform="translate(-753.998 -1455.009)"></path></svg></span> </div>       
      </div>
      <!-- Miles Per Day Business & Private Chart --> 
    </div>  
    <md-dialog class="md-driver-drilldown" :md-active.sync="showDrill">
      <DrillDownGrid v-if="showDrill" :data="widgetData" :gridSettingsKey="widgetData.widgetName"></DrillDownGrid>
    </md-dialog>  
  </div>
</template>

<script>
import Vue from 'vue'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import HighChartMore from 'highcharts/highcharts-more'
import HighChartAccessibility from 'highcharts/modules/accessibility';
import dashboardService from "../../../service/dashboard.service";
import { mutations, getters } from "../shared/observeDashboardFilter";
import JQuery from "jquery";

HighChartMore(Highcharts);
HighChartAccessibility(Highcharts);
Vue.use(HighchartsVue, { });
export default {
  name: 'milesPerDayBusinessAndPrivateChart',
  components: {},
  data: function() {
    return {
      chartTitle: "",
      chartAltTitleCaption: "",
      chartAltTitleValue: "",
      hideloaderClass:"",
      loaderHide: false,
      showDrill:false,
      chartOptions: {
        credits: {
            enabled: false
        },
        chart: {
            // zoomType: 'xy'
            //margin:[50,50,50,50]
            marginLeft:50,
           // marginRight:50,
            //width:"100"
        },
        title: {
            text: 't',
            style: {
                color: '#FFFFFF'
            }
        },
        xAxis: {
            categories: [],
            //offset: 5,
            labels: {
                style: {
                    color: '#333333',
                    fontSize: "14px",
                    fontFamily: "Roboto",
                    opacity: 1
                },
                /* formatter: function () {
                    return '<span style="width: 6px; height: 6px; border-radius: 50%;background:#288DC1; display:block;margin: auto; top: -10px; position: relative;"/></span><br>' + this.value;
                }, */
                useHTML: true
            },
        },
        yAxis: [{            
            labels: {
                // format: '{value}',
                style: {
                    // fontWeight:'bold',
                    color: '#333333',
                    fontFamily: 'Roboto,Regular',
                    fontSize: "14px",
                }
            },
            title: {
                text: null
            },
            lineWidth: 1,
            //gridLineDashStyle: 'longdash',
            //lineColor: '#2A48C2',
            /* title: {
                text: 'Miles',
                align: 'high',
                offset: 0,
                rotation: 0,
                y: -15,
                x: 50,
                style: {
                    fontWeight: 'bold',
                    color: '#2A48C2'
                }
            }, */
        }],
        tooltip: {
            backgroundColor: {
                linearGradient: [0, 0, 0, 60],
                stops: [
                    [0, '#FFFFFF'],
                    [1, '#FFFFFF']
                ]
            },
            borderWidth: 0,
            seriesData: [],
            formatter: function () {
                let fistSeries = this.series.tooltipOptions.seriesData[0];
                let secondSeries = this.series.tooltipOptions.seriesData[1];
                let thirdSeries = this.series.tooltipOptions.seriesData[2];
                
                return '<b>' + this.x + '</b><br/>' + fistSeries.name + ': <b>' + fistSeries.data[this.point.x] +'</b><' +
                    'br/></br>' + secondSeries.name + ': <b>' + secondSeries.data[this.point.x] +'</b><br/>' + thirdSeries.name + ': <b>' + thirdSeries.data[this.point.x] +'</b>'
            },
            style: {
                color: '#333333',
                fontSize: "14px",
                fontFamily: "Roboto"
            }
        },
        legend: {
            enabled: false,
            layout: 'vertical',
            align: 'left',
            x: 120,
            verticalAlign: 'top',
            y: 100,
            floating: true,
            backgroundColor:'rgba(255,255,255,0.25)'
        },
        plotOptions: {
            column: {
                //  borderRadius:5,
                // color: '', //'#0082CA',
                borderRadiusTopLeft: 5,
                borderRadiusTopRight: 5

            },            
            series: {
                lineWidth: 4,
                pointStart:0,
            }
        },
      }, 
    }
  }, 
  props: {
    widgetData: Object
  }, 
  created() {
      this.getMilesPerDayBusinessAndPrivateChartData();  
    },  
    methods: {
      toggleSeries(id) {          
        this.$refs.milesPerDayChart.chart.series[id].setVisible(!this.$refs.milesPerDayChart.chart.series[id].visible);
      },
      getMilesPerDayBusinessAndPrivateChartData() {
        let self = this;
        self.loaderHide = false;
        //let chartApi = "/api/Utilization/BusinessPrivateHistory";
        dashboardService.getWidgetData( this.widgetData.api, getters.getFilterParams()).then(returnResult => {
      /*   setTimeout(() => {
            let self = this;
            self.loaderHide = false;
            const returnResult = {
                "id": "MilesPerDayBusinessAndPrivateChart",
                "data": {
                    "chartTitle": "Miles Per Day Business & Private",
                    "chartAltTitleCaption": "Total Miles", // "Total Miles For the Period",
                    "chartAltTitleValue": "2220",
                    "categories": ["2/23/2020", "2/24/2020", "2/25/2020", "2/26/2020", "2/27/2020", "2/28/2020", "2/29/2020"],
                    "series": [
                        {
                            "name": "Business Miles",                          
                            "type": "column",
                            "yAxis": 0,
                            "color": "#2A48C2",
                            "data": [280, 240, 222, 360, 260, 240, 210],                            
                            "zIndex": 1
                        },
                        {
                            "name": "Private Miles",                          
                            "type": "column",
                            "yAxis": 0,
                            "color": "#ED7D31",
                            "data": [120, 140, 122, 260, 160, 140, 110],                           
                            "zIndex": 1
                        },
                        {
                            "name": "Total Miles",                          
                            "type": "line",
                            "color": "#008D72",
                            "data": [400, 380, 344, 620, 420, 380, 320],
                            "timeUnit": "mins",
                            "zIndex": 2,
                            "marker": {
                                "lineColor": "#DA9707"
                            }
                        }                      
                    ]
                }
            }; */
            // returnResult.categories; self.data.series = returnResult.series;
            self.chartOptions.xAxis.categories = returnResult.data.categories;
            self.chartOptions.series = returnResult.data.series;
            self.chartOptions.tooltip.seriesData = returnResult.data.series,                
            // self.chartOptions.yAxis[0].title.text = returnResult.data.series[1].name + "("+ returnResult.data.series[1].timeUnit +")",                
            // self.chartOptions.yAxis[1].title.text = returnResult.data.series[0].name + "("+ returnResult.data.series[0].timeUnit +")",                
            
            self.chartTitle = returnResult.data.chartTitle;
            self.chartAltTitleCaption = returnResult.data.chartAltTitleCaption + ": " ;
            self.chartAltTitleValue = returnResult.data.chartAltTitleValue;

            self.loaderHide = true;

            // hide first axis

        })
        .catch(err => {
              self.hideloaderClass = 'cd-chart-ifDataBlank'
              self.loaderHide=true
          });
      }
    }
};

</script>
<style scoped>
/*
=============================================================
Please put your custom CSS below (CTrack BS Row Structure) 
============================================================
*/
/*CTrack BS Row Structure (Please don't change or use this CSS)*/
.ctrack-row{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-right:-10px;margin-left:-10px;}
[class*="ctrack-col-"]{position:relative;width:100%;min-height:1px;padding-right:10px;padding-left:10px;}
.ctrack-d-flex{display:-webkit-box!important;display:-ms-flexbox!important;display:flex!important;}
.ctrack-align-items-center{-webkit-box-align:center!important;-ms-flex-align:center!important;align-items:center!important;}
.ctrack-justify-content-center{-webkit-box-pack:center!important;-ms-flex-pack:center!important;justify-content:center!important;}
.ctrack-h-100{height: 100%;}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}

.ctrack-col-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}

@media only screen and (min-width:576px){
.ctrack-col-sm-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-sm-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-sm-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-sm-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-sm-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-sm-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-sm-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-sm-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-sm-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-sm-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-sm-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-sm-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}

@media only screen and (min-width:768px){
.ctrack-col-md-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-md-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-md-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-md-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-md-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-md-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-md-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-md-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-md-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-md-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-md-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-md-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}

@media only screen and (min-width:992px){
.ctrack-col-lg-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-lg-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-lg-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-lg-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-lg-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-lg-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-lg-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-lg-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-lg-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-lg-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-lg-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-lg-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}

@media only screen and (min-width:1200px){
.ctrack-col-xl-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-xl-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-xl-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-xl-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-xl-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-xl-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-xl-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-xl-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-xl-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-xl-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-xl-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-xl-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}

/*CTrack BS Row Structure (Please don't change or use above CSS)*/
/*
=============================================================
Start your custom CSS from here
============================================================
*/

/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
*{font-family: 'Roboto', sans-serif;}
.ctrack-h-100{height: 100%;}
[class*="md-chart-arrow"]{display:inline-block;vertical-align:middle;}
[class*="md-chart-arrow"] svg{display:block;}
.md-chart-arrow-down{transform:rotate(180deg); -webkit-transform:rotate(180deg); -moz-transform:rotate(180deg); -ms-transform:rotate(180deg); -o-transform:rotate(180deg);}
.cd-chart-innerbox{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;width:100%;height:100%;background-color:#FFF;border-radius:5px;align-content: space-between; -webkit-align-content: space-between;}
.ctrack-align-content-top{align-content: start; -webkit-align-content: start;}
.cd-chart-box-middle{padding:20px 30px;-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
.cd-chart-box-bottom{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;border-top:1px solid #E8E8E8;margin-top:20px;padding:18px 0 0;}
.cd-chart-box-top{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;padding:15px 20px;border-bottom:1px solid #E8E8E8;}
.cd-chart-box-img {text-align: center;}
.cd-chart-box-header{display:table;width:100%;}
.cd-chart-box-header > div{display:table-cell;vertical-align: middle;}
.cd-chart-box-col1 h3{color:#013C5E;font-size:18px;font-weight:700;}
.cd-chart-box-col1 h3 span{color:#979797;font-weight:400;}
.cd-chart-box-col2{text-align:right;font-size:14px;font-weight:bold;color:#333333;}
.ctrack-select-main{display:inline-block;}
.ctrack-select-box{display:inline-block;font-family:'Roboto',sans-serif;font-weight:bold;color:#333333;font-size:14px;border:0;padding-right:2px;outline: 0}
.ctrack-chart-info{display:inline-block;vertical-align:middle;}
.ctrack-chart-info-btn,.ctrack-chart-info-btn svg{display:block;}
.ctrack-chart-info a{color:#e8e8e8 !important;}
.ctrack-chart-info a:hover{color:#cacaca !important;}
.cd-indicator-pos-col{display: block;}
.cd-chart-indicator-inner{display:inline-block;vertical-align: middle; width:100%;position:relative;padding-left:32px;margin-bottom:12px;text-align: left; color: #333333;font-size: 15px;font-weight: 500;line-height: 21px;}
.cd-chart-indicator-inner .cd-chart-indicator-color{position:absolute;left:0;top:50%;width:25px;height:11px;border-radius:3.5px;transform:translateY(-50%);}
.cd-indicator-pos-right{position:absolute;top:-5px;right:0;}
.cd-indicator-pos-right .cd-chart-indicator-inner{margin-bottom:10px;}
.cd-chart-right-lbl{font-size:16px;font-weight:normal;color:#666666;display: inline-block;vertical-align: middle;}
.cd-chart-right-lbl strong{color:#013C5E;font-weight:700;}

.md-bottom-indicWithCheck{padding:0 20px;}
.md-bottom-indicWithCheck .ctrack-row{margin-left:-20px;margin-right:-20px;}
.md-bottom-indicWithCheck [class*="ctrack-col-"]{padding:20px;border-right:solid 1px #E8E8E8;}
.md-bottom-indicWithCheck [class*="ctrack-col-"]:last-child{border:0;}

.cd-chart-indicator-right{display:inline-block;width:100%;}
.cd-chart-indicator-right .cd-chart-indicator-inner{margin-bottom:0;padding-right: 40px;}
.cd-chart-indicator-right .ctrack-right-toggle-btns{position:absolute;right:0;top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%);}
.cd-chart-indicator-right .ctrack-col-12:last-child .cd-chart-indicator-inner:before{display:none;}

/* Rounded Toggle Button*/
.ctrack-switch{position:relative;display:inline-block;vertical-align: middle;width:31px;height:19px;}
.ctrack-switch input{opacity:0;width:0;height:0;}
.ctrack-slider{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:#D7D7D7;-webkit-transition:.4s;transition:.4s;}
.ctrack-slider:before{position:absolute;content:"";height:15px;width:15px;left:2px;bottom:2px;background-color:white;-webkit-transition:.4s;transition:.4s;}
.ctrack-switch input:checked + .ctrack-slider{background-color:#40C1AC;}
.ctrack-switch input:focus + .ctrack-slider{box-shadow:0 0 1px #002B49;}
.ctrack-switch input:checked + .ctrack-slider:before{-webkit-transform:translateX(12px);-ms-transform:translateX(12px);transform:translateX(12px);}
.ctrack-slider.ctrack-round{border-radius:10px;}
.ctrack-slider.ctrack-round:before{border-radius:50%;}

/*No Data Section */
.cd-chart-ifDataBlank .cd-chart-box-top,.cd-chart-ifDataBlank .cd-chart-box-middle,.cd-chart-ifDataBlank .cd-chart-box-bottom,.cd-chart-ifDataBlank .ctrack-d-flexcol{visibility:hidden;}
.cd-chart-ifDataBlank .md-no-data-found-msg{display: block !important}
.md-no-data-found-msg{padding:15px 15px 15px 50px;position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);font-size:18px;color:#9e4e4e;border:1px solid #ffb2b2;background-color:#ffb2b2;border-radius:3px;display:none}
.md-no-data-found-msg .md-error-dataIcon{position:absolute;left:15px;top:50%;transform:translateY(-50%);}
.md-no-data-found-msg .md-error-dataIcon svg{display:block;}

/* Responsive CSS */
@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1399px){}
@media only screen and (max-width:1299px){
  .md-bottom-indicWithCheck{padding:0 15px;}
  .md-bottom-indicWithCheck .ctrack-row{margin-left:-10px;margin-right:-10px;}
  .md-bottom-indicWithCheck [class*="ctrack-col-"]{padding:10px;}
}
@media only screen and (max-width:1199px){
  
}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){
 .cd-indicator-pos-right{position:static;float:right;width: 100%;}
 .cd-indicator-pos-right .cd-indicator-pos-col{display:inline-block;}
.cd-indicator-pos-right .cd-indicator-pos-col + .cd-indicator-pos-col{padding-left:20px;}


}
@media only screen and (max-width:767px){
  .cd-chart-box-header > div{display: inline-block;width: 100%;}
  .cd-chart-box-col2{text-align: left;}
  .cd-chart-box-header{position: relative;padding-right: 30px;}
  .md-view-detail-link{position:absolute;right:0;top:2px;}


}
@media only screen and (max-width:575px){
  .cd-chart-box-middle {padding: 15px}
  .cd-chart-box-col1 h3 span{display: block}
}

</style>
