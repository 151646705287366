import Vue from "vue";

const state = Vue.observable({
    "companyname": '',
    "loginuser": '',
    "isLoaded" : false,
});

const mutations = {
    updateCompanyName(company) {
        Vue.set(state, "companyname", company);
    },
    updateUserName(user) {
        Vue.set(state, "loginuser", user);
    },
    updateLoadedState(isLoaded) {
        Vue.set(state, "isLoaded", isLoaded);
    },
};

const getters = {
  getGlobalFilterState(type) {
    return state[type];
  },
};

export { getters, mutations, state };
