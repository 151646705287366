import driverService from "@/service/drivers.service";
import Vue from 'vue';

const state = () => ({
    operators: [],
    tenantId : "",
})

const getters = {
    getOperatorsData: state =>  state.tenantId == Vue.prototype.$session.get('tenantDetails').tenantId ? state.operators : [],
}

const actions = {
    async getOperators({ commit }, ids) {
        await driverService.getManageDriversByIds(ids).then(operatorData => {
            commit('setOperators', operatorData.sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1)));
        })
    },
    async clearOperatorsStore({ commit }) {
        commit('setOperators', []);
    },
}

const mutations = {
    setOperators(state, operatorData) {
        state.operators = operatorData;
        state.tenantId = Vue.prototype.$session.get('tenantDetails') ? Vue.prototype.$session.get('tenantDetails').tenantId : '';
    },
}

export default {
    modules: {
        driverStore: {
            namespaced: true,
            state,
            getters,
            actions,
            mutations
        }
    }
};