import Vue from "vue";

const state = Vue.observable({
  filterParams: {
    "businessGroups": [],
    "drivers": ['All'],
    "vehicles": ['All'],
    "distance": "",
    "distanceUnit": "miles",
    "tenant": "Testing",
    "tripType": "all",
    "selectedWeekDay": ["sun", "mon", "tue", "wed", "thu", "fri", "sat"],
    "distanceMaxValue": "",
    "calendar": {
      "type": "",
      "from": "",
      "to": ""
    }
  }
  
});

const mutations = {  
  setCalendarFilter(to, from, type) {    
    Vue.set(state.filterParams.calendar, "to", to);
    Vue.set(state.filterParams.calendar, "from", from);
    Vue.set(state.filterParams.calendar, "type", type);
    
  },
  setDefaultFilters(filterData){
    if(filterData.distance)
    Vue.set(state.filterParams, "distance", filterData.distance);

    if(filterData.distanceUnit)
    Vue.set(state.filterParams, "distanceUnit", filterData.distanceUnit);

    if(filterData.distanceMaxValue)
    Vue.set(state.filterParams, "distanceMaxValue", filterData.distanceMaxValue);

    if(filterData.businessGroups)
      Vue.set(state.filterParams, "businessGroups", filterData.businessGroups);

    if(filterData.vehicles)
      Vue.set(state.filterParams, "vehicles", filterData.vehicles);

    if(filterData.drivers)
      Vue.set(state.filterParams, "drivers", filterData.drivers);

    if(filterData.businessGroupName)
      Vue.set(state.filterParams, "businessGroupName", filterData.businessGroupName);
  },
  setDashboardFilters(filterData)
  {
    Vue.set(state.filterParams, "businessGroups", filterData.businessGroups);
    Vue.set(state.filterParams, "distance", filterData.distance);
    Vue.set(state.filterParams, "distanceUnit", filterData.distanceUnit);
    Vue.set(state.filterParams, "drivers", filterData.drivers);
    Vue.set(state.filterParams, "vehicles", filterData.vehicals);
    Vue.set(state.filterParams, "tripType", filterData.tripType);
    Vue.set(state.filterParams, "selectedWeekDay", filterData.selectedWeekDay);
    Vue.set(state.filterParams, "distanceMaxValue", filterData.distanceMaxValue);
    Vue.set(state.filterParams, "businessGroupName", filterData.businessGroupName);
  }

};

const getters = {
  getCalendarFilter() {
      return state.filterParams.calendar;
  },
  getFilterParams() {
    if(state.filterParams.vehicles.includes("All"))
    {
      let globalDashboardStateNew = JSON.parse(localStorage.getItem('globalDashboardState'))
      if(globalDashboardStateNew && globalDashboardStateNew.vehicles != undefined && globalDashboardStateNew.vehicles.length)
      {
        Vue.set(state.filterParams, "vehicles", globalDashboardStateNew.vehicles);
      }
    }
    return state.filterParams;
  }

};

export { getters, mutations };
