<template>
  <md-field>
    <label :for="id" class="customeLabels">
      <slot
        v-if="multiple"
        :value="value"
        name="placeholder"
      >{{placeholder}}: {{ value ? PageName && value.length>0? activeVehcileorDriver  + '('+value.length+')': value.length : '0' }}</slot>
      <slot v-if="!multiple" :value="value" name="placeholder">{{value ? value : placeholder }}</slot>
    </label>
    <md-select @md-opened="onDropDownOpen()" @md-closed="onDropDownClose()" :name="id" :id="id">
      <template v-if="multiple">
        <div :class="className">
          <div class="searchWithiCheckBoxContainer">
            <md-checkbox v-if="searchable" v-model="allvalue" @change="selectAllItems($event)">
              <input
                @input="onInput()"
                v-model="searchValue"
                @click.stop
                type="text"
                class="filterSearchInput"
              />
            </md-checkbox>
          </div>

          <div class="searchWithCheckBoxList">
            <div class="searchableCheckboxList">
              <md-checkbox
                v-model="value"
                :value="item.id"
                :key="item.id"
                @change="onItemClick($event)"
                v-for="(item) in filteredList"
              >
                <slot :item="item" name="value">
                  <div class="searchTruncateText">{{vehicleInfoFormats(item)}}</div>
                </slot>
              </md-checkbox>
            </div>
          </div>
        </div>
      </template>
      <template v-if="!multiple">
        <div v-if="searchable" class="md-checkbox" style="margin-left:25px;">
          <input @input="onInput()" v-model="searchValue" @click.stop type="text" />
        </div>
        <md-divider></md-divider>
        <md-option
          style="margin-left:12px; margin-right:12px;"
          v-model="value"
          :value="item.id"
          :key="item.id"
          @change="onItemClick($event)"
          v-for="(item) in filteredList"
        >
          <slot :item="item" name="value">{{getNestedValue(item)}}</slot>
        </md-option>
      </template>
    </md-select>
  </md-field>
</template>
<script>
import _ from "lodash";

export default {
  name: "",
  props: {
    value: { type: String | Array | Object, required: false },
    placeholder: { type: String, required: false, default: "" },
    id: { type: String, required: false, default: "select" },
    multiple: { type: Boolean, required: false, default: false },
    "min-count": { type: Number, required: false, default: 0 },
    searchKey: { type: String, required: true, default: "" },
    dataList: { type: Array, required: true },
    searchable: { type: Boolean, required: false, default: true },
    className: { type: String, required: false, default: "searchWithCheckBox" },
    activeVehcileorDriver: { type: Number, required: false, default: 0 },
    PageName: { type: String, required: false, default: "" },
  },
  data() {
    return {
      filteredList: [],
      searchValue: "",
      allvalue: true,
      typing: true
    };
  },
  computed: {},
  methods: {
     vehicleInfoFormats(item) {
      return this.$vehicleInfoFormat(item);
    },
    onDropDownClose() {
      this.$emit("md-closed", this.value);
    },
    onDropDownOpen() {
      this.filteredList = [];
      this.filteredList = [...this.dataList];
      this.searchData()
      if (this.value.length == 0)
      {
         this.allvalue = false;
      }     
      else if (this.filteredList.length != this.value.length)
      {
         this.allvalue = false;
      }
      else 
      {
         this.allvalue = true;
      }
      this.$emit("md-opened", this.value);
    },
    getNestedValue(item) {
      if (!this.searchKey) {
        return;
      }
      const tokens = this.searchKey.split(".");
      let value = item[tokens[0]];

      if (tokens.length < 2) {
        return value;
      }
      tokens.splice(0, 1);
      tokens.forEach(token => {
        value = value[token];
      });

      return value;
    },
    searchData() {
      if (this.searchValue) {
        this.filteredList = this.filteredList.filter(item => {
          const value = this.getNestedValue(item);
          if (value) {
            return value.toLowerCase().includes(this.searchValue.toLowerCase());
          }
          return false;
        });
      }
    },
    selectAllItems(selectAll) {
      let allItemIds = [];
      const filteredListLength = this.filteredList.length;
      if (this.searchValue && filteredListLength < 1) {
        return;
      }
      if (selectAll) {
        //allItemIds = this.dataList.map(item => item.id);
        allItemIds = this.filteredList.map(item => item.id);
      }
      this.$emit("input", allItemIds);
      this.$emit("change", allItemIds);
    },
    onItemClick(value) {
      if (this.multiple) {
        this.allvalue = this.value.length == this.dataList.length;
      }
      this.$emit("change", value);
    },
    onInput() {
      this.typing = true;
    }
  },
  watch: {
    searchValue: _.debounce(function() {
      this.typing = false;
      this.filteredList = [...this.dataList];
      this.searchData();
       this.allvalue = this.filteredList.length == this.dataList.length;
    }, 500),
    dataList: function() {
      this.searchValue = "",
      this.filteredList = [...this.dataList];
    }
  }
};
</script>
<style scoped>
.md-field label {
  top: 12px;
  left: 12px;
}
.searchWithCheckBox {
  width: 250px;
  padding: 10px;
}
.searchWithCheckBoxList {
}
.searchableCheckboxList {
  height: 200px;
  overflow: auto;
}
.searchableCheckboxList .md-checkbox {
  display: flex;
}
.searchWithCheckBox .filterSearchInput {
  height: 30px;
  position: relative;
  top: -4px;
  width: 190px;
}
.searchWithiCheckBoxContainer {
  height: 40px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
}
.searchWithiCheckBoxContainer .md-checkbox {
  display: flex;
  align-items: center;
}
.searchTruncateText {
  width: 170px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.customSearchSelect{
  width:560px;
  padding: 10px;
}
.customSearchSelect .filterSearchInput {
  height: 30px;
  position: relative;
  top: -4px;
  width: 490px;
}
@media only screen and (max-width: 767px) {
  .customSearchSelect .filterSearchInput {
    width: 190px;
  }

  .customSearchSelect {
    width: 250px;    
  }
}
</style>
<style>
.searchableCheckboxList .md-checkbox .md-checkbox-container:before {
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  z-index: 6;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
</style>