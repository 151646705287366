<template>
  <div class="HarshAccelerationWidget ctrack-h-100" ref="HarshAcceleration">
    <div id="vueapp" class="vue-app ctrack-h-100">

    <!-- Harsh Acceleration -->
    <div class="dBehaviour-inner" :class="widgetColorClass">        
      <div class="dBehaviour-box-col">
        <div class="dBehaviour-box-icon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="35" height="21" viewBox="0 0 35 21"><path d="M532.451,202.744a3.132,3.132,0,1,0,3.255,3.132A3.194,3.194,0,0,0,532.451,202.744Zm0,4.695a1.565,1.565,0,1,1,1.628-1.563A1.6,1.6,0,0,1,532.451,207.439ZM553,200.431v3.339a1.54,1.54,0,0,1-1.57,1.508h-1.68a4.337,4.337,0,0,0-8.525,0h-4.514a4.333,4.333,0,0,0-8.52,0h-2.1a1.54,1.54,0,0,1-1.57-1.508v-3.339Zm-7.511,2.313a3.132,3.132,0,1,0,3.255,3.132A3.2,3.2,0,0,0,545.49,202.744Zm0,4.695a1.565,1.565,0,1,1,1.628-1.563A1.6,1.6,0,0,1,545.49,207.439Zm6.892-10.37-6.17-5.861a2.142,2.142,0,0,0-1.471-.583h-3.114V189.5a1.538,1.538,0,0,0-1.57-1.508H526.087a1.541,1.541,0,0,0-1.57,1.508v.236l-6.371.705,11.289,1.412L518,192.949l11.4,1.556-11.4.949,6.518,1.181V199.6H553v-1.1A1.981,1.981,0,0,0,552.382,197.069Zm-2.69.2h-5.971a.241.241,0,0,1-.246-.236V192.6a.241.241,0,0,1,.246-.236h1.136a.271.271,0,0,1,.173.066l4.83,4.428A.235.235,0,0,1,549.692,197.265Z" transform="translate(-518 -187.996)" /></svg></div>
        <div class="dBehaviour-box-content">
          <h3>
            <span class="dBehaviour-box-value-icon" :class="widgetIcon"><svg v-if="widgetIcon!=''" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10"><path d="M638.392,193H628.62a.626.626,0,0,1-.527-.962c.213-.383,4.509-8.038,4.876-8.691a.6.6,0,0,1,1.059,0c.269.47,4.587,8.154,4.887,8.711A.623.623,0,0,1,638.392,193Z" transform="translate(-628 -183.028)" /></svg></span>{{widgetValue}}
          </h3>
          <p class="ctrack-text-truncate">{{widgetTitle}}</p>
        </div>
      </div>      
    </div>
    <!-- Harsh Acceleration -->    

    </div>    
  </div>
</template>

<script>
import dashboardService from "../../../service/dashboard.service";
import { getters } from "../shared/observeDashboardFilter";

export default {
  name: 'harshaccelerationwidget',
  components: {},
  data: function() {
        return {
        widgetTitle:'',
        widgetValue :'',
        widgetIcon :'',
        widgetColorClass:''

        }
  },
  props: {
    widgetData: Object
  },
  created(){
      this.getHarshAccelerationWidgetData();        
  },  
  methods: {
      getHarshAccelerationWidgetData() {
        let self = this;
        dashboardService.getWidgetData(this.widgetData.api, getters.getFilterParams() ).then(returnResult => {
          /* const returnResult =  {
              hwTitle: "Harsh Acceleration",
              value: "23",
              type:"%",
              icon: "up",
              color: "red"
          }; */
          self.widgetTitle = returnResult.hwTitle;
          self.widgetValue = returnResult.value + returnResult.type;
          self.widgetIcon =  (returnResult.icon == "down") ? "md-chart-arrow-down" : (returnResult.icon == "up") ? "md-chart-arrow-up" : "";
          self.widgetColorClass = (returnResult.color == "green") ? "success-color" : (returnResult.color == "red") ? "error-color" : "";          
        });
     
      
    }
  }
};

</script>
<style scoped>

/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
*{font-family: 'Roboto', sans-serif;}
.ctrack-h-100{height: 100%;}
.error-color .dBehaviour-box-col h3{color:#FA4B4B;}
.success-color .dBehaviour-box-col h3{color:#76CD24;}
[class*="md-chart-arrow"]{display:inline-block;vertical-align:middle;}
[class*="md-chart-arrow"] svg{display:block;}
.md-chart-arrow-down{transform:rotate(180deg); -webkit-transform:rotate(180deg); -moz-transform:rotate(180deg); -ms-transform:rotate(180deg); -o-transform:rotate(180deg);}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}

.dBehaviour-inner{display:inline-block;width:100%;height:100%;background-color:#FFF;padding:13px 20px;border-radius: 5px; box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, .05); -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, .05);}
.dBehaviour-box-row{display:inline-block;width:100%;padding-left:40px;position:relative;}
.dBehaviour-box-col{display:inline-block;vertical-align: middle;position:relative;width:100%;text-align:right;padding-left:40px;}
.dBehaviour-box-icon{position:absolute;color:#656565; left:0;top:50%;transform:translateY(-50%); -webkit-transform:translateY(-50%); -moz-transform:translateY(-50%); -ms-transform:translateY(-50%); -o-transform:translateY(-50%);}
.dBehaviour-box-content{display:block;width:100%;padding-left:5px;}
.dBehaviour-box-content h3{font-size:20px;font-weight:500;}
.dBehaviour-box-value-icon{margin-right:8px;}
.dBehaviour-box-content p{font-size:15px;color:#333333;font-weight:500;}


/* Responsive CSS */
@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1399px){}
@media only screen and (max-width:1299px){}
@media only screen and (max-width:1199px){}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){}
@media only screen and (max-width:767px){}
@media only screen and (max-width:575px){}

</style>
