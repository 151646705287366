import _ from 'lodash';
import axios from 'axios';
import Vue from 'vue';

class ConfigurationService {

    getUserConfigProperties(id) {
        return axios.get(`/api/configuration/GetUserConfigProperties/${id}`)
    }

    getAllTenantConfig() {
        return axios.get(`/api/configuration/GetAllTenantConfig`)
    }

    getTenantConfigProps() {
        return axios.get(`/api/configuration/GetTenantConfigProps`)
    }
    getSpecificTenantConfigProps(propertyName) {
        return axios.get(`/api/configuration/GetSpecificTenantConfigProps/${propertyName}`)
    }
    getTenantConfigPropsCategory(categoryName) {
        return axios.get(`/api/configuration/GetTenantConfigPropsCategory/${categoryName}`)
    }

    saveUserConfig(id, body) {
        return axios.put(`/api/configuration/SaveUserConfig/${id}`, body)
    }

    saveAssetConfig(id, body) {
        return axios.put(`/api/configuration/SaveAssetConfig/${id}`, body)
    }

    saveTenantConfig(body) {
        return axios.put(`/api/configuration/SaveTenantConfig`, body)
    }

    getDeviceConfig(id) {
        return axios.get(`/api/configuration/GetDeviceConfig/${id}`)
    }

    updateSortingConfig(name, sort) {
        const $session = Vue.prototype.$session;
        const configProps = $session.get("configProperties");
        const userId = $session.get("tenantDetails").userId;

        let columnConfig = _.find(configProps.columnConfig, c => c.name == name);
        if (columnConfig == undefined) {
            columnConfig = { name: name };
            configProps.columnConfig.push(columnConfig);
        }
        configProps.columnConfig = configProps.columnConfig.filter(i => i.name);
        columnConfig.sort = JSON.stringify(sort);

        return this.saveUserConfig(userId, {
            overrideProperties: {
                columnConfig: configProps.columnConfig
            }
        }).then(() => {
            $session.set("configProperties", configProps);
        });
    }

    getWorkingHoursFor(model) {
        return axios.post(`/api/configuration/GetWorkingHoursFor`, model);
    }
     getAppConfig() {
        return axios.get('/app/value');
    }
}

export default new ConfigurationService();