<template>
    <v-card>
        <div class="IdlingDrillPopup md-exModal-style2">
            <div class="md-expopup-Header">
                <div class="md-expopup-Header-title">
                    <h4>
                        <span class="dExe-title-icon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="27" height="16" viewBox="0 0 27 16"><path d="M753.062,194.068a3.5,3.5,0,0,0-4.518.333,1.519,1.519,0,0,1-.993.139,2.151,2.151,0,0,0-2.223,1,1.829,1.829,0,0,0,.06,2.167,2.164,2.164,0,0,0,2.366.9,1.226,1.226,0,0,1,.856.2c1.692,1.222,2.688,1.279,4.839.046a2.8,2.8,0,0,0,.382.656,4.623,4.623,0,0,0,7.156-.415.975.975,0,0,1,1.318-.4,7.127,7.127,0,0,0,7.732-1.933,6.386,6.386,0,0,0,1.346-7.378,7.058,7.058,0,0,0-6.495-4.356,7.211,7.211,0,0,0-7.417,5.678c-.154.924-.33,1.488-1.51,1.718A10.763,10.763,0,0,0,753.062,194.068Zm13.961,1.482-.239.089L765,192.294l-1.723.769-.94-4.231.268-.093,1.673,3.028,1.7-.727Zm-10.861,1.085.977-2.522.153.063v2.119l1.035.261-.947,2.409-.218-.056.126-1.965Zm-5.89-1.106a4.559,4.559,0,0,1,1.056.815,4.844,4.844,0,0,1,.458,1.275C751.332,196.832,749.82,196.7,750.271,195.529Z" transform="translate(-745.001 -185.003)" /></svg> </span>
                        {{popupTitle}}
                    </h4>
                    <a href="#" class="md-expopup-Header-closebtn" v-if="chartNarrative!=''" @click="closeFormDialog(false)"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="12.728" height="12.728" viewBox="0 0 12.728 12.728"><path d="M1131,162.414l-4.949,4.95-1.415-1.415,4.95-4.949-4.95-4.95,1.415-1.414,4.949,4.949,4.95-4.949,1.414,1.414-4.949,4.95,4.949,4.949-1.414,1.415Z" transform="translate(-1124.635 -154.636)" /></svg></a>
                </div>
            </div>
            <div class="ctrack-modal-body">
                <div class="ctrack-md-loader" :class="loaderHide?'ctrack-chart-hide-loader':''"></div>
                <div class="md-exModal-inner">
                    <div class="ctrack-row">
                        <div class="ctrack-col-lg-6 ctrack-col-xl-7">
                            <div class="md-exModal-left">
                                <div class="ctrack-row">
                                    <div class="ctrack-col-sm-12 md-idling-mobile">
                                        <div class="md-exModal-topText">
                                            {{ chartNarrative }}
                                        </div>
                                    </div>
                                    <div class="ctrack-col-sm-12">
                                        <div class="md-exModal-topChart-main">
                                            <div class="md-exModal-topChartTitle">
                                                <label :class="chartProgressColor"><span class="dExe-value-icon" :class="chartProgressIcon"><svg v-if="chartProgressIcon!=''" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="6.868" height="3.666" viewBox="0 0 6.868 3.666"><path d="M805.45,221.3l3.434-3.666,3.434,3.666Z" transform="translate(-805.45 -217.629)" /></svg></span> {{ chartProgressValue }}</label> {{ chartTitle }}
                                            </div>
                                            <div class="md-exModal-topChart">
                                                <highcharts :options="chartOptions"></highcharts>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ctrack-col-sm-12" v-if="chartNarrative!=''">
                                        <div class="md-exModal-bSection">
                                            <div class="md-exModal-bHeader">
                                                <div class="md-exModal-bHeader-col1">
                                                    <h5>{{ gridTitle }}</h5>
                                                </div>
                                                <div class="md-exModal-bHeader-col2" @click="closeFormDialog(true)">
                                                    <a href="#">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23.327" viewBox="0 0 23 23.327"><g transform="translate(0 0.004)"><path d="M1.522,21.874H23v1.519H0V.09H1.522Z" transform="translate(0 -0.07)" /><path d="M67.5,31.728V14.51h2.7V31.728Z" transform="translate(-50.177 -11.105)" /><path d="M16.953,6.557,15.825,7.69c-.71.71-1.421,1.418-2.128,2.131a.807.807,0,0,1-.839.241.768.768,0,0,1-.39-1.221,3.629,3.629,0,0,1,.274-.287q1.45-1.454,2.905-2.9a.292.292,0,0,0,.072-.415,3.392,3.392,0,0,1,.341-3.875A3.36,3.36,0,0,1,19.725.125a3.336,3.336,0,0,1,2.621,2.867,3.312,3.312,0,0,1-1.387,3.426,3.437,3.437,0,0,1-3.78.262C17.112,6.649,17.05,6.61,16.953,6.557Zm-.121-3.016A2.018,2.018,0,1,0,18.84,1.564,2,2,0,0,0,16.833,3.541Z" transform="translate(-9.145)" /><path d="M41.48,35.67h2.69V47.1H41.48Z" transform="translate(-30.836 -26.512)" /><path d="M18.342,60.889H15.66V54.16h2.682Z" transform="translate(-11.642 -40.261)" /></g></svg>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="md-exModal-bottomTbl md-tbl-idleByServerity">
                                                <md-table v-model="gridData" md-sort="idleTime" md-sort-order="desc" md-card>
                                                    <md-table-empty-state :md-label="$t('Dashboard.dataNotAvailable')">
                                                    </md-table-empty-state>

                                                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                                                        <md-table-cell :md-label="$t('Dashboard.vehicleRegistration')" md-sort-by="vehicleReg">{{ item.vehicleReg }}</md-table-cell>
                                                        <md-table-cell :md-label="$t('Dashboard.driver')" md-sort-by="driver">{{ item.driver }}</md-table-cell>
                                                        <md-table-cell :md-label="$t('Dashboard.idleTime')" md-sort-by="idleTime">{{ item.formattedIdleTime }}</md-table-cell>
                                                        <md-table-cell :md-label="$t('Dashboard.idleFuelCost')" md-sort-by="idleFuelCost"><!-- {{ item.idleFuelCost }} -->{{Number((item.idleTime * spentFuelUse * spentFuelCost).toFixed(2))}}</md-table-cell>
                                                    </md-table-row>

                                                </md-table>
                                            </div>
                                        </div>
                                    </div>




                                </div>
                            </div>
                        </div>
                        <div class="ctrack-col-lg-6 ctrack-col-xl-5">
                            <div class="md-exModal-right" v-if="chartNarrative!=''">
                                <div class="ctrack-row">
                                    <div class="ctrack-col-sm-12 md-idling-desktop">
                                        <div class="md-exModal-topText">
                                            {{ chartNarrative }}
                                        </div>
                                    </div>
                                    <div class="ctrack-col-sm-12">
                                        <div class="md-exModal-bRightSection">
                                            <div class="md-exModal-bRtopSection">
                                                <div class="md-idlling-fuel">
                                                    <div class="ctrack-row ctrack-align-items-center ctrack-fuel-rangeSlider">
                                                        <div class="ctrack-col-md-6 ctrack-col-lg-6">
                                                            <div class="ctrack-row ctrack-align-items-center">
                                                                <div class="ctrack-col-9">
                                                                    <label class="md-fuel-form-lbl">
                                                                        <span class="md-fuel-form-icon">
                                                                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="20.13" height="20.694"
                                                                                 viewBox="0 0 20.13 20.694">
                                                                                <path d="M742.459,578.191a10.042,10.042,0,1,1,.027,0Zm-3.7-17.942a.326.326,0,0,0-.2.344q.007,7.258,0,14.516a.324.324,0,0,0,.205.342,7.952,7.952,0,0,0,7.574-.092,8.6,8.6,0,0,0,3.882-10.5,8.181,8.181,0,0,0-11.456-4.61Zm4.274,13.03v-1.557h1.105v.238h-.8v.359h.753v.274h-.758v.436h.852v.25Zm1.9-.08a5.7,5.7,0,0,0,1.731-2.128l.787.429a5.056,5.056,0,0,1-2.408,1.729A.174.174,0,0,1,744.935,573.2Zm1.94-2.526c.091-.448.2-.877.26-1.31a5.154,5.154,0,0,0-.146-2.122.2.2,0,0,1,.121-.282c.445-.236.882-.485,1.323-.728.047-.026.1-.045.173-.079a6.143,6.143,0,0,1-.841,4.971Zm-3.951-1.272q-.783-.406-1.564-.816a.224.224,0,0,0-.28.011.768.768,0,0,1-1-.082.834.834,0,0,1-.141-1.021.79.79,0,0,1,.919-.372.8.8,0,0,1,.569.846.229.229,0,0,0,.144.246q1.71,1.053,3.417,2.11a1.532,1.532,0,0,1,.173.143l-.06.066Q744.012,569.966,742.923,569.4Zm1.791-5.342,1.133-1.516a5.757,5.757,0,0,1,2.6,3.184l-1.638.962A5.627,5.627,0,0,0,744.714, 564.058Zm-1.629-.092V562.4h1.02c.005.073.01.141.017.24h-.75v.391h.635c.005.094.01.161.016.26h-.654v.672Z"
                                                                                      transform="translate(-732.403 -557.497)" />
                                                                            </svg>
                                                                        </span>{{$t("Dashboard.avgIdleFuelUsedPerHour")}} <!-- Average Idle fuel used per hour --> <br>{{$t("Dashboard.defaultIs")}}<!-- Default is --> {{spentFuelUse}} {{fuelMeasureType}}/{{$t("Dashboard.hr")}}.
                                                                    </label>
                                                                </div>
                                                                <div class="ctrack-col-3 md-pl-0">
                                                                    <div class="md-distance-maxRange">
                                                                        <input type="number" v-model.number="idleFuelUse" id="idleFuelUseNumber" :step="intervalslider1" min="0" class="md-number-field2" @keydown="updateValues" @paste.prevent />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="ctrack-col-md-6 ctrack-col-lg-6">
                                                            <div class="ctrack-fuelRange">
                                                                <vue-slider v-model.number="idleFuelUse" :min="minslider1" :interval="intervalslider1" :max="maxslider1" :marks="[minslider1, maxslider1]"></vue-slider>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="ctrack-row ctrack-align-items-center ctrack-fuel-rangeSlider">
                                                        <div class="ctrack-col-md-6 ctrack-col-lg-6">
                                                            <div class="ctrack-row ctrack-align-items-center">
                                                                <div class="ctrack-col-9">
                                                                    <label class="md-fuel-form-lbl">
                                                                        <span class="md-fuel-form-icon">
                                                                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="18.298" height="20.319" viewBox="0 0 18.298 20.319"> <path d="M750.8,696.786l.017-.017-4.131-4.194-1.176,1.2,2.34,2.382a2.826,2.826,0,0,0,.987,5.452,2.7,2.7,0,0,0,1.109-.237v8.139a1.109,1.109,0,1,1-2.218,0v-5.08a2.237,2.237,0,0,0-2.218-2.258H744.4v-7.9a2.238,2.238,0,0,0-2.218-2.258h-6.654a2.237,2.237,0,0,0-2.218,2.258v18.062H744.4v-8.466h1.663v5.644a2.773,2.773,0,1,0,5.545,0V698.784A2.861,2.861,0,0,0,750.8,696.786Zm-12.5,8a.6.6,0,0,0,.137.224,1.088,1.088,0,0,0,.258.194,3.493,3.493,0,0,0,.4.188q.385.158.7.324a2.37,2.37,0,0,1,.542.382,1.539,1.539,0,0,1,.351.5,1.689,1.689,0,0,1,.125.679,1.707,1.707,0,0,1-.113.639,1.433,1.433,0,0,1-.32.489,1.574,1.574,0,0,1-.5.331,2.2,2.2,0,0,1-.649.162v.8H738.6v-.8a2.474,2.474,0,0,1-.637-.158,1.619,1.619,0,0,1-.927-.926,2.183,2.183,0,0,1-.143-.833h1.148a1.348,1.348,0,0,0,.073.479.807.807,0,0,0,.2.307.694.694,0,0,0,.28.162,1.123,1.123,0,0,0,.32.046.933.933,0,0,0,.327-.053.663.663,0,0,0,.236-.146.6.6,0,0,0,.143-.22.784.784,0,0,0,.048-.277.938.938,0,0,0-.044-.3.637.637,0,0,0-.143-.235,1.088,1.088,0,0,0-.256-.194,2.715,2.715,0,0,0-.383-.174,7.09,7.09,0,0,1-.707-.313,2.351,2.351,0,0,1-.544-.378,1.52,1.52,0,0,1-.352-.5,1.875,1.875,0,0,1-.014-1.31,1.452,1.452,0,0,1,.318-.493,1.641,1.641,0,0,1,.494-.342,2.042,2.042,0,0,1,.645-.168v-.865h.635v.877a1.84,1.84,0,0,1,.62.2,1.549,1.549,0,0,1,.472.388,1.719,1.719,0,0,1,.3.564,2.369,2.369,0,0,1,.105.73h-1.148a1.131,1.131,0,0,0-.2-.726.645.645,0,0,0-.526-.24.786.786,0,0,0-.308.055.576.576,0,0,0-.212.15.605.605,0,0,0-.123.224.95.95,0,0,0-.04.279A.87.87,0,0,0,738.3,704.791Zm3.885-4.878h-6.654v-5.644h6.654Zm6.654,0a1.129,1.129,0,1,1,1.109-1.129A1.122,1.122,0,0,1,748.838,699.913Z" transform="translate(-733.313 -692.011)" /></svg>
                                                                        </span>{{$t("Dashboard.fuelCost")}}<!-- Fuel Cost -->
                                                                    </label>
                                                                </div>
                                                                <div class="ctrack-col-3 md-pl-0">
                                                                    <div class="md-distance-maxRange">
                                                                        <input type="number" v-model.number="fuelCost" id="fuelCostNumber" :step="intervalslider1" :min="minslider2" :max="maxslider2" class="md-number-field2" @keydown="updateValues" @paste.prevent />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="ctrack-col-md-6 ctrack-col-lg-6">
                                                            <div class="ctrack-fuelRange">
                                                                <vue-slider v-model.number="fuelCost" :interval="intervalslider1" :min="minslider2" :max="maxslider2" :marks="[minslider2, maxslider2]"></vue-slider>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="ctrack-row ctrack-align-items-center ctrack-fuel-rangeSlider">
                                                        <div class="ctrack-col-md-6 ctrack-col-lg-6">
                                                            <div class="ctrack-row ctrack-align-items-center">
                                                                <div class="ctrack-col-9">
                                                                    <label class="md-fuel-form-lbl">
                                                                        <span class="md-fuel-form-icon">
                                                                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="27.023" height="16.581"
                                                                                 viewBox="0 0 27.023 16.581">
                                                                                <path d="M748.835,666.215a3.408,3.408,0,0,1-.72-1.3.154.154,0,0,0-.177-.136q-4.483.007-8.967.007c-.592,0-1.184,0-1.776-.009a.254.254,0,0,0-.289.215,3.231,3.231,0,0,1-5.618,1.07,3.472,3.472,0,0,1-.636-1.264c-.023-.088-.068-.107-.141-.134a5.805,5.805,0,0,1-.767-.314,1.4,1.4,0,0,1-.739-1.236,10.5,10.5,0,0,1,.536-4.037,3.023,3.023,0,0,1,1.109-1.482,5.287,5.287,0,0,0,4.687,2.9,5.383,5.383,0,0,0,5.306-5.456,5.538,5.538,0,0,0-.76-2.8c.619.019,1.239.034,1.856.09a9.85,9.85,0,0,1,4.672,1.568,7.887,7.887,0,0,1,1.761,1.59q.491.606.95,1.241a.962.962,0,0,0,.7.4c.623.083,1.25.151,1.867.27a15.045,15.045,0,0,1,1.676.427,3.128,3.128,0,0,1,1.779,1.494,6.092,6.092,0,0,1,.865,3.135,3.938,3.938,0,0,1-.182,1.164,1.194,1.194,0,0,1-.89.869c-.13.032-.262.055-.388.1a.221.221,0,0,0-.128.123,3.336,3.336,0,0,1-2.138,2.4,2.9,2.9,0,0,1-1.021.18A3.263,3.263,0,0,1,748.835,666.215Zm-16.817-2.349a1.8,1.8,0,1,0,1.8-1.833h0A1.839,1.839,0,0,0,732.018,663.866Zm19.2-1.832a1.8,1.8,0,1,0,.016,0Zm-9.956-4.2a.433.433,0,0,0,.059.01c1.766,0,3.532,0,5.3-.005a1.441,1.441,0,0,0,.536-.128.355.355,0,0,0,.189-.47,2.41,2.41,0,0,0-.166-.376,5.371,5.371,0,0,0-2.46-2.37,8.166,8.166,0,0,0-2.615-.768c-.277-.037-.555-.059-.842-.088Zm-10.137-2.789a4.21,4.21,0,1,1,4.209,4.327A4.27,4.27,0,0,1,731.129,655.043Zm2.75.927a1.32,1.32,0,0,0,1.211,1.168v.609h.5v-.612a1.248,1.248,0,0,0,1.208-1.316,1.2,1.2,0,0,0-.15-.61,1.113,1.113,0,0,0-.4-.409,4.7,4.7,0,0,0-.7-.315,2.055,2.055,0,0,1-.527-.256.364.364,0,0,1-.123-.3.446.446,0,0,1,.466-.455c.281,0,.487.2.621.6l.687-.29a1.218,1.218,0,0,0-1.082-.972v-.472h-.5v.472a1.145,1.145,0,0,0-.811.439,1.359,1.359,0,0,0-.258.809,1.21,1.21,0,0,0,.145.6,1.074,1.074,0,0,0,.375.4,3.37,3.37,0,0,0,.642.29,2.4,2.4,0,0,1,.59.275.387.387,0,0,1,.132.319.491.491,0,0,1-.132.346.54.54,0,0,1-.415.143q-.6,0-.709-.669Z"
                                                                                      transform="translate(-728.988 -650.716)" />
                                                                            </svg>
                                                                        </span>{{$t("Dashboard.idleTime")}}<!-- Idle Time -->
                                                                    </label>
                                                                </div>
                                                                <div class="ctrack-col-3 md-pl-0">
                                                                    <div class="md-distance-maxRange">
                                                                        <input type="number" :step="intervalslider1" v-model.number="idleTime" id="idleTimeNumber" min="0" class="md-number-field2" @keydown="updateValues" @paste.prevent />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="ctrack-col-md-6 ctrack-col-lg-6">
                                                            <div class="ctrack-fuelRange">
                                                                <vue-slider v-model.number="idleTime" :interval="intervalslider1" :min="minslider3" :max="maxslider3" :marks="[minslider3, maxslider3]"></vue-slider>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="md-exModal-bRBottomSection">
                                                <div class="md-exModal-parameter">
                                                    <div class="md-drawer-section2">
                                                        <div class="md-dr-s2-top">
                                                            <div class="md-dr-s2-content">
                                                                <div class="md-drawer-row">
                                                                    <div class="md-drawer-col1">
                                                                        <div class="md-dr-s2-title">
                                                                            {{$t("Dashboard.parameter")}}
                                                                            <div class="md-para-info-icon" @click="parameterDetaildDialog = true" title="Click to view Parameter Information">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M25.9,16.9a9,9,0,1,0-9,9A9,9,0,0,0,25.9,16.9Z" transform="translate(-7.9 -7.9)" fill="currentColor" /><path d="M187.093,87.949a.151.151,0,0,0-.205-.064,18.16,18.16,0,0,1-1.891.942.083.083,0,0,1-.02-.026.285.285,0,0,1-.036-.151,31.535,31.535,0,0,1,1.017-4.737,18.506,18.506,0,0,0,.636-2.808.76.76,0,0,0-.225-.569.81.81,0,0,0-.577-.2,2.721,2.721,0,0,0-1.438.547,8.089,8.089,0,0,0-1.807,1.79.21.21,0,0,0-.013.234.155.155,0,0,0,.2.068c.228-.109,1.383-.656,1.552-.776a.664.664,0,0,1,.355-.151h.01c0,.008,0,.019,0,.034a1.53,1.53,0,0,1-.062.4,37.94,37.94,0,0,0-1.461,6.9,1.264,1.264,0,0,0,.241.81.8.8,0,0,0,.655.305,2.08,2.08,0,0,0,1.079-.43,13.339,13.339,0,0,0,1.97-1.873A.218.218,0,0,0,187.093,87.949Zm-.033-10.662a.771.771,0,0,0-.61-.286.987.987,0,0,0-.789.415,1.534,1.534,0,0,0-.323.984,1.153,1.153,0,0,0,.238.746.748.748,0,0,0,.6.294.989.989,0,0,0,.783-.437,1.566,1.566,0,0,0,.339-.995A1.078,1.078,0,0,0,187.06,77.286Z" transform="translate(-175.901 -74.747)" fill="#fff" /></svg>
                                                                            </div>
                                                                            <md-dialog class="md-para-info-data-modal" :md-active.sync="parameterDetaildDialog">
                                                                                <div class="md-para-info-data">
                                                                                    <div class="md-expopup-Header">
                                                                                        <div class="md-expopup-Header-title">
                                                                                            <h4>
                                                                                                <span class="dExe-title-icon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="27" height="16" viewBox="0 0 27 16"><path d="M753.062,194.068a3.5,3.5,0,0,0-4.518.333,1.519,1.519,0,0,1-.993.139,2.151,2.151,0,0,0-2.223,1,1.829,1.829,0,0,0,.06,2.167,2.164,2.164,0,0,0,2.366.9,1.226,1.226,0,0,1,.856.2c1.692,1.222,2.688,1.279,4.839.046a2.8,2.8,0,0,0,.382.656,4.623,4.623,0,0,0,7.156-.415.975.975,0,0,1,1.318-.4,7.127,7.127,0,0,0,7.732-1.933,6.386,6.386,0,0,0,1.346-7.378,7.058,7.058,0,0,0-6.495-4.356,7.211,7.211,0,0,0-7.417,5.678c-.154.924-.33,1.488-1.51,1.718A10.763,10.763,0,0,0,753.062,194.068Zm13.961,1.482-.239.089L765,192.294l-1.723.769-.94-4.231.268-.093,1.673,3.028,1.7-.727Zm-10.861,1.085.977-2.522.153.063v2.119l1.035.261-.947,2.409-.218-.056.126-1.965Zm-5.89-1.106a4.559,4.559,0,0,1,1.056.815,4.844,4.844,0,0,1,.458,1.275C751.332,196.832,749.82,196.7,750.271,195.529Z" transform="translate(-745.001 -185.003)" /></svg> </span> {{$t("Dashboard.parameterInformation")}}<!-- Parameter Information -->
                                                                                            </h4>
                                                                                            <a href="#" class="md-expopup-Header-closebtn" @click="parameterDetaildDialog = false"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="12.728" height="12.728" viewBox="0 0 12.728 12.728"><path d="M1131,162.414l-4.949,4.95-1.415-1.415,4.95-4.949-4.95-4.95,1.415-1.414,4.949,4.949,4.95-4.949,1.414,1.414-4.949,4.95,4.949,4.949-1.414,1.415Z" transform="translate(-1124.635 -154.636)" /></svg></a>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="md-para-info-body">
                                                                                        <div class="md-exModal-bottomTbl md-para-info-tbl">
                                                                                            <md-table>
                                                                                                <md-table-row>
                                                                                                    <md-table-head>{{$t("Dashboard.vehicleType")}}<!-- Vehicle Type --></md-table-head>
                                                                                                    <md-table-head>{{$t("Dashboard.fuelType")}}<!-- Fuel Type --></md-table-head>
                                                                                                    <md-table-head>{{$t("Dashboard.engineSizeliter")}}<!-- Engine Size (liter) --></md-table-head>
                                                                                                    <md-table-head>{{$t("Dashboard.grossVehicleWeight")}}<!-- Gross Vehicle Weight (GVW) (lb) --></md-table-head>
                                                                                                    <md-table-head v-if="fuelMeasureType=='gal'">{{$t("Dashboard.idlingFuelUseGalHr")}}<!-- Idling fuel use (Gal/hr with no load) --></md-table-head>
                                                                                                    <md-table-head v-if="fuelMeasureType=='ltr'">{{$t("Dashboard.idlingFuelUseLiterHr")}}<!-- idling Fuel used (liter/hr with no load) --></md-table-head>
                                                                                                </md-table-row>

                                                                                                <md-table-row>
                                                                                                    <md-table-cell>{{$t("Dashboard.tractorSemitrailer")}}<!-- Tractor-Semitrailer --></md-table-cell>
                                                                                                    <md-table-cell>{{$t("Dashboard.diesel")}}</md-table-cell>
                                                                                                    <md-table-cell>-</md-table-cell>
                                                                                                    <md-table-cell>80,000</md-table-cell>
                                                                                                    <md-table-cell v-if="fuelMeasureType=='gal'">0.64</md-table-cell>
                                                                                                    <md-table-cell v-if="fuelMeasureType=='ltr'">2.42</md-table-cell>
                                                                                                </md-table-row>

                                                                                                <md-table-row>
                                                                                                    <md-table-cell>{{$t("Dashboard.bucketTruck")}}<!-- Bucket Truck --></md-table-cell>
                                                                                                    <md-table-cell>{{$t("Dashboard.diesel")}}</md-table-cell>
                                                                                                    <md-table-cell>-</md-table-cell>
                                                                                                    <md-table-cell>37,000</md-table-cell>
                                                                                                    <md-table-cell v-if="fuelMeasureType=='gal'">0.9</md-table-cell>
                                                                                                    <md-table-cell v-if="fuelMeasureType=='ltr'">3.41</md-table-cell>
                                                                                                </md-table-row>
                                                                                                <md-table-row>
                                                                                                    <md-table-cell>{{$t("Dashboard.combinationTruck")}}<!-- Combination Truck --></md-table-cell>
                                                                                                    <md-table-cell>{{$t("Dashboard.diesel")}}</md-table-cell>
                                                                                                    <md-table-cell>-</md-table-cell>
                                                                                                    <md-table-cell>32,000</md-table-cell>
                                                                                                    <md-table-cell v-if="fuelMeasureType=='gal'">0.49</md-table-cell>
                                                                                                    <md-table-cell v-if="fuelMeasureType=='ltr'">1.85</md-table-cell>
                                                                                                </md-table-row>
                                                                                                <md-table-row>
                                                                                                    <md-table-cell>{{$t("Dashboard.transitBus")}}<!-- Transit Bus --></md-table-cell>
                                                                                                    <md-table-cell>{{$t("Dashboard.diesel")}}</md-table-cell>
                                                                                                    <md-table-cell>-</md-table-cell>
                                                                                                    <md-table-cell>30,000</md-table-cell>
                                                                                                    <md-table-cell v-if="fuelMeasureType=='gal'">0.97</md-table-cell>
                                                                                                    <md-table-cell v-if="fuelMeasureType=='ltr'">3.67</md-table-cell>
                                                                                                </md-table-row>
                                                                                                <md-table-row>
                                                                                                    <md-table-cell>{{$t("Dashboard.mediumHeavyTruck")}}<!-- Medium Heavy Truck --></md-table-cell>
                                                                                                    <md-table-cell>{{$t("Dashboard.diesel")}}</md-table-cell>
                                                                                                    <md-table-cell>10-{{$t("june")}}</md-table-cell>
                                                                                                    <md-table-cell>23,000 - 33,000</md-table-cell>
                                                                                                    <md-table-cell v-if="fuelMeasureType=='gal'">0.44</md-table-cell>
                                                                                                    <md-table-cell v-if="fuelMeasureType=='ltr'">1.67</md-table-cell>
                                                                                                </md-table-row>
                                                                                                <md-table-row>
                                                                                                    <md-table-cell>{{$t("Dashboard.towTruck")}}<!-- Tow Truck --></md-table-cell>
                                                                                                    <md-table-cell>{{$t("Dashboard.diesel")}}</md-table-cell>
                                                                                                    <md-table-cell>-</md-table-cell>
                                                                                                    <md-table-cell>26,000</md-table-cell>
                                                                                                    <md-table-cell v-if="fuelMeasureType=='gal'">0.59</md-table-cell>
                                                                                                    <md-table-cell v-if="fuelMeasureType=='ltr'">2.23</md-table-cell>
                                                                                                </md-table-row>
                                                                                                <md-table-row>
                                                                                                    <md-table-cell>{{$t("Dashboard.deliveryTruck")}}<!-- Delivery Truck --></md-table-cell>
                                                                                                    <md-table-cell>{{$t("Dashboard.diesel")}}</md-table-cell>
                                                                                                    <md-table-cell>-</md-table-cell>
                                                                                                    <md-table-cell>19,500</md-table-cell>
                                                                                                    <md-table-cell v-if="fuelMeasureType=='gal'">0.84</md-table-cell>
                                                                                                    <md-table-cell v-if="fuelMeasureType=='ltr'">3.18</md-table-cell>
                                                                                                </md-table-row>
                                                                                                <md-table-row>
                                                                                                    <md-table-cell>{{$t("Dashboard.mediumHeavyTruck")}}<!-- Medium Heavy Truck --></md-table-cell>
                                                                                                    <md-table-cell>{{$t("Dashboard.gas")}}</md-table-cell>
                                                                                                    <md-table-cell>7-{{$t("may")}}</md-table-cell>
                                                                                                    <md-table-cell>19,700 - 26,000</md-table-cell>
                                                                                                    <md-table-cell v-if="fuelMeasureType=='gal'">0.84</md-table-cell>
                                                                                                    <md-table-cell v-if="fuelMeasureType=='ltr'">3.18</md-table-cell>
                                                                                                </md-table-row>
                                                                                                <md-table-row>
                                                                                                    <md-table-cell>{{$t("Dashboard.compactSedan")}}<!-- Compact Sedan --></md-table-cell>
                                                                                                    <md-table-cell>{{$t("Dashboard.diesel")}}</md-table-cell>
                                                                                                    <md-table-cell>2</md-table-cell>
                                                                                                    <md-table-cell>-</md-table-cell>
                                                                                                    <md-table-cell v-if="fuelMeasureType=='gal'">0.17</md-table-cell>
                                                                                                    <md-table-cell v-if="fuelMeasureType=='ltr'">0.64</md-table-cell>
                                                                                                </md-table-row>
                                                                                                <md-table-row>
                                                                                                    <md-table-cell>{{$t("Dashboard.largeSedan")}}<!-- Large Sedan --></md-table-cell>
                                                                                                    <md-table-cell>{{$t("Dashboard.gas")}}</md-table-cell>
                                                                                                    <md-table-cell>4.6</md-table-cell>
                                                                                                    <md-table-cell>-</md-table-cell>
                                                                                                    <md-table-cell v-if="fuelMeasureType=='gal'">0.39</md-table-cell>
                                                                                                    <md-table-cell v-if="fuelMeasureType=='ltr'">1.48</md-table-cell>
                                                                                                </md-table-row>
                                                                                                <md-table-row>
                                                                                                    <md-table-cell>{{$t("Dashboard.compactSedan")}}<!-- Compact Sedan --></md-table-cell>
                                                                                                    <md-table-cell>{{$t("Dashboard.gas")}}</md-table-cell>
                                                                                                    <md-table-cell>2</md-table-cell>
                                                                                                    <md-table-cell>-</md-table-cell>
                                                                                                    <md-table-cell v-if="fuelMeasureType=='gal'">0.16</md-table-cell>
                                                                                                    <md-table-cell v-if="fuelMeasureType=='ltr'">0.61</md-table-cell>
                                                                                                </md-table-row>
                                                                                                <md-table-row>
                                                                                                    <md-table-head></md-table-head>
                                                                                                    <md-table-head></md-table-head>
                                                                                                    <md-table-head colspan="2">{{$t("Dashboard.avgFuelUsedPerHour")}} <!-- Average fuel used per hour --></md-table-head>
                                                                                                    <md-table-head v-if="fuelMeasureType=='gal'">0.59</md-table-head>
                                                                                                    <md-table-head v-if="fuelMeasureType=='ltr'">2.21</md-table-head>
                                                                                                </md-table-row>

                                                                                            </md-table>
                                                                                            <div class="md-para-info-bottom-data">
                                                                                                <strong>{{$t("Dashboard.source")}}:</strong> {{$t("Dashboard.argonneNationalLaboratory")}}<br>
                                                                                                <strong>{{$t("Dashboard.idleFuelConsumptionCalculation")}}<!-- Idle Fuel consumption calculation --></strong><br>
                                                                                                <strong>{{$t("Dashboard.idleFuelUsed")}}<!-- Idle Fuel used --></strong> = {{$t("Dashboard.idleTime")}} * {{$t("Dashboard.idleFuelUsedPerHour")}}<!-- idling fuel used per hour -->. <br>
                                                                                                {{$t("Dashboard.idleSpentColumnText")}}<!-- Therefore The Spent column will be calculated using the 0.59g or 2.21L per hour average -->.
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </md-dialog>
                                                                        </div>
                                                                    </div>
                                                                    <div class="md-drawer-col2 md-dr-spent">
                                                                        <div class="dm-drawer-triangle-top">
                                                                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 133 38.5">
                                                                                <path d="M0,38.5c0-7.3,5-14.1,13.2-17.8L52.7,2.9c8.7-3.8,18.6-3.8,27.3,0l39.8,17.9	c8.2,3.7,13.3,10.5,13.3,17.8L0,38.5z" />
                                                                                <path d="M0,38.5c0-7.3,5-14.1,13.2-17.8L52.7,2.9c8.7-3.8,18.6-3.8,27.3,0l39.8,17.9	c8.2,3.7,13.3,10.5,13.3,17.8" />
                                                                            </svg>
                                                                        </div>
                                                                        <div class="md-dr-s2-title">{{$t("Dashboard.spent")}}<!-- SPENT --></div>
                                                                    </div>
                                                                    <div class="md-drawer-col3 md-dr-change">
                                                                        <div class="md-dr-s2-title">{{$t("Dashboard.change")}}<!-- CHANGE --></div>
                                                                    </div>
                                                                </div>

                                                                <div class="md-drawer-row">
                                                                    <div class="md-drawer-col1 ctrack-text-truncate md-drawer-title">
                                                                        <span class="md-drawer-title-icon">
                                                                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="20.13" height="20.694"
                                                                                 viewBox="0 0 20.13 20.694">
                                                                                <path d="M742.459,578.191a10.042,10.042,0,1,1,.027,0Zm-3.7-17.942a.326.326,0,0,0-.2.344q.007,7.258,0,14.516a.324.324,0,0,0,.205.342,7.952,7.952,0,0,0,7.574-.092,8.6,8.6,0,0,0,3.882-10.5,8.181,8.181,0,0,0-11.456-4.61Zm4.274,13.03v-1.557h1.105v.238h-.8v.359h.753v.274h-.758v.436h.852v.25Zm1.9-.08a5.7,5.7,0,0,0,1.731-2.128l.787.429a5.056,5.056,0,0,1-2.408,1.729A.174.174,0,0,1,744.935,573.2Zm1.94-2.526c.091-.448.2-.877.26-1.31a5.154,5.154,0,0,0-.146-2.122.2.2,0,0,1,.121-.282c.445-.236.882-.485,1.323-.728.047-.026.1-.045.173-.079a6.143,6.143,0,0,1-.841,4.971Zm-3.951-1.272q-.783-.406-1.564-.816a.224.224,0,0,0-.28.011.768.768,0,0,1-1-.082.834.834,0,0,1-.141-1.021.79.79,0,0,1,.919-.372.8.8,0,0,1,.569.846.229.229,0,0,0,.144.246q1.71,1.053,3.417,2.11a1.532,1.532,0,0,1,.173.143l-.06.066Q744.012,569.966,742.923,569.4Zm1.791-5.342,1.133-1.516a5.757,5.757,0,0,1,2.6,3.184l-1.638.962A5.627,5.627,0,0,0,744.714, 564.058Zm-1.629-.092V562.4h1.02c.005.073.01.141.017.24h-.75v.391h.635c.005.094.01.161.016.26h-.654v.672Z"
                                                                                      transform="translate(-732.403 -557.497)" />
                                                                            </svg>
                                                                        </span>{{$t("Dashboard.totalIdleTime")}}<!-- Total Idle Time --> ({{$t("Dashboard.hr")}}.)
                                                                    </div>
                                                                    <div class="md-drawer-col2 md-drawer-spent md-drawer-param-first">
                                                                        <div class="md-drawer-param-main">
                                                                            <div class="md-drawer-parameters">{{Number(spentIdleTime).toLocaleString()}}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="md-drawer-col3 md-drawer-change md-drawer-param-first">
                                                                        <div class="md-drawer-param-main">
                                                                            <div class="md-drawer-parameters">{{Number(idleTime).toLocaleString()}}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="md-drawer-row">
                                                                    <div class="md-drawer-col1 ctrack-text-truncate md-drawer-title">
                                                                        <span class="md-drawer-title-icon">
                                                                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="27.023" height="16.581"
                                                                                 viewBox="0 0 27.023 16.581">
                                                                                <path d="M748.835,666.215a3.408,3.408,0,0,1-.72-1.3.154.154,0,0,0-.177-.136q-4.483.007-8.967.007c-.592,0-1.184,0-1.776-.009a.254.254,0,0,0-.289.215,3.231,3.231,0,0,1-5.618,1.07,3.472,3.472,0,0,1-.636-1.264c-.023-.088-.068-.107-.141-.134a5.805,5.805,0,0,1-.767-.314,1.4,1.4,0,0,1-.739-1.236,10.5,10.5,0,0,1,.536-4.037,3.023,3.023,0,0,1,1.109-1.482,5.287,5.287,0,0,0,4.687,2.9,5.383,5.383,0,0,0,5.306-5.456,5.538,5.538,0,0,0-.76-2.8c.619.019,1.239.034,1.856.09a9.85,9.85,0,0,1,4.672,1.568,7.887,7.887,0,0,1,1.761,1.59q.491.606.95,1.241a.962.962,0,0,0,.7.4c.623.083,1.25.151,1.867.27a15.045,15.045,0,0,1,1.676.427,3.128,3.128,0,0,1,1.779,1.494,6.092,6.092,0,0,1,.865,3.135,3.938,3.938,0,0,1-.182,1.164,1.194,1.194,0,0,1-.89.869c-.13.032-.262.055-.388.1a.221.221,0,0,0-.128.123,3.336,3.336,0,0,1-2.138,2.4,2.9,2.9,0,0,1-1.021.18A3.263,3.263,0,0,1,748.835,666.215Zm-16.817-2.349a1.8,1.8,0,1,0,1.8-1.833h0A1.839,1.839,0,0,0,732.018,663.866Zm19.2-1.832a1.8,1.8,0,1,0,.016,0Zm-9.956-4.2a.433.433,0,0,0,.059.01c1.766,0,3.532,0,5.3-.005a1.441,1.441,0,0,0,.536-.128.355.355,0,0,0,.189-.47,2.41,2.41,0,0,0-.166-.376,5.371,5.371,0,0,0-2.46-2.37,8.166,8.166,0,0,0-2.615-.768c-.277-.037-.555-.059-.842-.088Zm-10.137-2.789a4.21,4.21,0,1,1,4.209,4.327A4.27,4.27,0,0,1,731.129,655.043Zm2.75.927a1.32,1.32,0,0,0,1.211,1.168v.609h.5v-.612a1.248,1.248,0,0,0,1.208-1.316,1.2,1.2,0,0,0-.15-.61,1.113,1.113,0,0,0-.4-.409,4.7,4.7,0,0,0-.7-.315,2.055,2.055,0,0,1-.527-.256.364.364,0,0,1-.123-.3.446.446,0,0,1,.466-.455c.281,0,.487.2.621.6l.687-.29a1.218,1.218,0,0,0-1.082-.972v-.472h-.5v.472a1.145,1.145,0,0,0-.811.439,1.359,1.359,0,0,0-.258.809,1.21,1.21,0,0,0,.145.6,1.074,1.074,0,0,0,.375.4,3.37,3.37,0,0,0,.642.29,2.4,2.4,0,0,1,.59.275.387.387,0,0,1,.132.319.491.491,0,0,1-.132.346.54.54,0,0,1-.415.143q-.6,0-.709-.669Z"
                                                                                      transform="translate(-728.988 -650.716)" />
                                                                            </svg>
                                                                        </span>{{$t("Dashboard.fuelConsumed")}}<!-- Fuel Consumed --> ({{fuelMeasureType}}.)
                                                                    </div>
                                                                    <div class="md-drawer-col2 md-drawer-spent">
                                                                        <div class="md-drawer-param-main">
                                                                            <div class="md-drawer-parameters">{{Number(spentFuelConsumed).toLocaleString()}}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="md-drawer-col3 md-drawer-change">
                                                                        <div class="md-drawer-param-main">
                                                                            <div class="md-drawer-parameters">{{Number(changedFuelConsumed).toLocaleString()}}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div class="md-drawer-row">
                                                                    <div class="md-drawer-col1 ctrack-text-truncate md-drawer-title">
                                                                        <span class="md-drawer-title-icon">
                                                                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="18.298" height="20.319"
                                                                                 viewBox="0 0 18.298 20.319">
                                                                                <path d="M750.8,696.786l.017-.017-4.131-4.194-1.176,1.2,2.34,2.382a2.826,2.826,0,0,0,.987,5.452,2.7,2.7,0,0,0,1.109-.237v8.139a1.109,1.109,0,1,1-2.218,0v-5.08a2.237,2.237,0,0,0-2.218-2.258H744.4v-7.9a2.238,2.238,0,0,0-2.218-2.258h-6.654a2.237,2.237,0,0,0-2.218,2.258v18.062H744.4v-8.466h1.663v5.644a2.773,2.773,0,1,0,5.545,0V698.784A2.861,2.861,0,0,0,750.8,696.786Zm-12.5,8a.6.6,0,0,0,.137.224,1.088,1.088,0,0,0,.258.194,3.493,3.493,0,0,0,.4.188q.385.158.7.324a2.37,2.37,0,0,1,.542.382,1.539,1.539,0,0,1,.351.5,1.689,1.689,0,0,1,.125.679,1.707,1.707,0,0,1-.113.639,1.433,1.433,0,0,1-.32.489,1.574,1.574,0,0,1-.5.331,2.2,2.2,0,0,1-.649.162v.8H738.6v-.8a2.474,2.474,0,0,1-.637-.158,1.619,1.619,0,0,1-.927-.926,2.183,2.183,0,0,1-.143-.833h1.148a1.348,1.348,0,0,0,.073.479.807.807,0,0,0,.2.307.694.694,0,0,0,.28.162,1.123,1.123,0,0,0,.32.046.933.933,0,0,0,.327-.053.663.663,0,0,0,.236-.146.6.6,0,0,0,.143-.22.784.784,0,0,0,.048-.277.938.938,0,0,0-.044-.3.637.637,0,0,0-.143-.235,1.088,1.088,0,0,0-.256-.194,2.715,2.715,0,0,0-.383-.174,7.09,7.09,0,0,1-.707-.313,2.351,2.351,0,0,1-.544-.378,1.52,1.52,0,0,1-.352-.5,1.875,1.875,0,0,1-.014-1.31,1.452,1.452,0,0,1,.318-.493,1.641,1.641,0,0,1,.494-.342,2.042,2.042,0,0,1,.645-.168v-.865h.635v.877a1.84,1.84,0,0,1,.62.2,1.549,1.549,0,0,1,.472.388,1.719,1.719,0,0,1,.3.564,2.369,2.369,0,0,1,.105.73h-1.148a1.131,1.131,0,0,0-.2-.726.645.645,0,0,0-.526-.24.786.786,0,0,0-.308.055.576.576,0,0,0-.212.15.605.605,0,0,0-.123.224.95.95,0,0,0-.04.279A.87.87,0,0,0,738.3,704.791Zm3.885-4.878h-6.654v-5.644h6.654Zm6.654,0a1.129,1.129,0,1,1,1.109-1.129A1.122,1.122,0,0,1,748.838,699.913Z"
                                                                                      transform="translate(-733.313 -692.011)" />
                                                                            </svg>
                                                                        </span>{{$t("Dashboard.fuelCost")}}<!-- Fuel Cost --> ({{currencySymbol}})
                                                                    </div>
                                                                    <div class="md-drawer-col2 md-drawer-spent">
                                                                        <div class="md-drawer-param-main">
                                                                            <div class="md-drawer-parameters"><input type="number" v-model.number="spentFuelCost" id="spentFuelCostNumber" :step="intervalslider1" :min="minslider2" :max="maxslider2" class="md-number-field2" @keydown="updateValues" @paste.prevent /></div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="md-drawer-col3 md-drawer-change">
                                                                        <div class="md-drawer-param-main">
                                                                            <div class="md-drawer-parameters">{{fuelCost}}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="md-drawer-row">
                                                                    <div class="md-drawer-col1 ctrack-text-truncate md-drawer-title">
                                                                        <span class="md-drawer-title-icon">
                                                                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="21" height="21"
                                                                                 viewBox="0 0 21 21">
                                                                                <path d="M1238.5,307a10.5,10.5,0,1,0,10.5,10.5A10.5,10.5,0,0,0,1238.5,307Zm2.863,14.554a3.335,3.335,0,0,1-2.238,1.023v1.486h-1.243v-1.478a3.256,3.256,0,0,1-3.021-2.835l1.922-.5a1.672,1.672,0,0,0,1.769,1.624,1.37,1.37,0,0,0,1.034-.347,1.174,1.174,0,0,0,.331-.84.925.925,0,0,0-.331-.772,5.975,5.975,0,0,0-1.471-.667,8.659,8.659,0,0,1-1.6-.7,2.647,2.647,0,0,1-.937-.972,2.883,2.883,0,0,1-.36-1.459,3.234,3.234,0,0,1,.643-1.964,2.875,2.875,0,0,1,2.023-1.065v-1.147h1.243v1.147a3.007,3.007,0,0,1,2.7,2.358l-1.712.7q-.5-1.446-1.55-1.446a1.131,1.131,0,0,0-.845.323,1.074,1.074,0,0,0-.319.783.877.877,0,0,0,.308.72,5.286,5.286,0,0,0,1.315.62,11.665,11.665,0,0,1,1.737.764,2.729,2.729,0,0,1,1.006.993,2.858,2.858,0,0,1,.375,1.482A3.135,3.135,0,0,1,1241.363,321.554Z"
                                                                                      transform="translate(-1228 -307)" />
                                                                            </svg>
                                                                        </span>{{$t("Dashboard.totalCost")}}<!-- Total Cost -->
                                                                    </div>
                                                                    <div class="md-drawer-col2 md-drawer-spent md-drawer-param-last">
                                                                        <div class="md-drawer-param-main">
                                                                            <div class="md-drawer-parameters active">{{currencySymbol}}{{ Number(spentTotalCost).toLocaleString()}}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="md-drawer-col3 md-drawer-change md-drawer-param-last">
                                                                        <div class="md-drawer-param-main">
                                                                            <div class="md-drawer-parameters active">{{currencySymbol}}{{ Number(changedTotalCost).toLocaleString()}}</div>
                                                                        </div>
                                                                        <div class="dm-drawer-triangle-bottom">
                                                                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 133 38.5">
                                                                                <path d="M133,0c0,7.3-5,14.1-13.2,17.8L80.3,35.7c-8.7,3.8-18.6,3.8-27.3,0L13.3,17.8	C5.1,14.1,0,7.3,0,0L133,0z" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div class="md-dr-s2-bottom">
                                                            <div class="md-drawer-row ctrack-align-items-center">
                                                                <div class="md-drawer-col1 md-s2-blue-btn">
                                                                    <div class="md-dr-s2-btns">
                                                                        <div class="md-s2-btn-title">{{$t("Dashboard.totalSaving")}}<!-- Total Saving --></div>
                                                                        <div class="md-s2-btn-value">{{currencySymbol}}{{Number(totalSaving).toLocaleString()}}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-card>
</template>
<script>
import Vue from 'vue'
import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
import dashboardService from "../../../service/dashboard.service";
import { getters } from "../shared/observeDashboardFilter";

Vue.use(HighchartsVue, { });
export default {
  name: "idlingdrillpopup",
  components: {
    
  },
  data: function () {
      return {
        idleFuelUse:0,
        fuelCost:0,
        idleTime:0,
        maxslider1: 0,
        minslider1: 0,
        intervalslider1: 0.01,
        minslider2:0,
        maxslider2:0,
        minslider3:0,
        maxslider3:0,
        spentIdleTime:0,        
        //spentFuelConsumed:15000,        
        spentFuelCost:0,        
        //spentTotalCost:15000,        
        spentFuelUse:0,        
        fuelMeasureType:"",        
        parameterDetaildDialog: false,
        chartNarrative: "",
        popupTitle: "",
        chartTitle: "",
        chartProgressValue: "",
        chartProgressIcon : "",
        chartProgressColor : "",
        gridTitle: "",
        loaderHide:false,
        currencySymbol:"",
        chartOptions: {
            credits: {
                enabled: false
            },
            chart: {
                height: 200,
            },
            title: {
                  text: ''
              },            
            yAxis: {
              lineWidth: 1,
              lineColor: "#CCCCCC",
              // tickAmount: 6,
              // endOnTick: false,
              maxPadding: 0.25,
              title: {
                  text: "Idle Hours"
              },
              labels: {
                  enabled: true,
                  style: {
                      color:'#333333',
                      fontSize: "14px",
                      fontFamily: 'Roboto, Regular',
                  },
                  formatter: function() {
                    return this.value;
                  }
              },
            },
            xAxis: {
                categories: [],
                lineColor: "#CCCCCC",
                labels: {                    
                    style: {
                        color:'#333333',
                        fontSize: "14px",
                        fontFamily: 'Roboto, Regular',
                    },                  
                },
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle',
                enabled: false
              },

            plotOptions: {              
                series: {
                    label: {
                        connectorAllowed: false,
                        enabled:false
                    },
                    lineWidth: 4,
                    pointStart:0,                  
                },
                line: {
                    dataLabels: {
                        enabled: true,
                        format: '{y}',
                        style: {
                            color:'#333333',
                            // fontSize: "14px",
                            fontFamily: 'Roboto, Regular',
                        },
                    },
                    enableMouseTracking: true,
                    marker: {
                      radius: 3,
                      fillColor: "#288DC1"
                    }
                }
            },
            tooltip: {
              backgroundColor: {
                  linearGradient: [0, 0, 0, 60],
                  stops: [
                      [0, '#FFFFFF'],
                      [1, '#FFFFFF']
                  ]
              },
              borderWidth: 0,
              formatter: function () {
                return  '<b>' + this.x + '</b> <br/> ' + ': <b>' + this.y + '</b>';
              },
              style: {
                  color:'#333333',
                  fontSize: "14px",
                  fontFamily: "Roboto"
              }
          }, 
            series: {},

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 630,
                        maxHeight: 230,
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        },
                    }
                }]
            }          
        },
        gridData: []

      }
    }, 
  created() {  
     let userLocalStorage = JSON.parse(this.$localStorage.get('vue-session-key'))["configProperties"];
     if(userLocalStorage.currency==="Rand")
     {this.currencySymbol="R";}
     else if(userLocalStorage.currency==="Dollar"){
    this.currencySymbol="$";
     }
      this.getIdleDrillLineChartData();

  }, 
   props: {     
    widgetData: Object,
    chartProgress: Object,
  },
  computed:{
    changedFuelConsumed : function () {
      // `this` points to the vm instance
      return Number((this.idleTime * this.idleFuelUse).toFixed(2))
    },
    changedTotalCost : function () {
      // `this` points to the vm instance
      return  (this.changedFuelConsumed*this.fuelCost).toFixed(2) 
    },
    totalSaving : function () {
      // `this` points to the vm instance
      return  Number((this.spentTotalCost - this.changedTotalCost).toFixed(2))
    },
    spentFuelConsumed : function(){
      return Number((this.spentIdleTime * this.spentFuelUse).toFixed(2))
    },
    spentTotalCost : function () {
      // `this` points to the vm instance
      return  (this.spentFuelConsumed*this.spentFuelCost).toFixed(2) 
    }      
  },  
  methods: {
    updateValues(e){
      const key = e.key;
      // If is '.' key, stop it
      if(e.srcElement.id == "idleTimeNumber")
      {
        if (key === '.' || key === '-' || key === '+')
          return e.preventDefault();
      }
      else
      {
        if (key == '.' && e.target.value.split('.').length > 1)
          return e.preventDefault();
        if (key === '-' || key === '+')
          return e.preventDefault();
      }
      
    },
    closeFormDialog(openDrill) {
      this.$emit("closeIdlingMdDialog", openDrill);
    },
    getIdleDrillLineChartData() {
      let self = this;
      self.loaderHide=false;
      dashboardService.getWidgetData(this.widgetData.drillAPI, getters.getFilterParams() ).then(returnResult => {
          self.popupTitle = returnResult.data.popupTitle;
          self.chartOptions.xAxis.categories = returnResult.data.categories;
          self.chartOptions.series = returnResult.data.series;
          self.gridData = returnResult.data.gridData;
          self.chartNarrative = returnResult.data.chartNarrative;
          self.idleFuelUse = returnResult.data.goalSeekData.idleFuelUse;
          self.fuelCost = returnResult.data.goalSeekData.fuelCost;
          self.idleTime = returnResult.data.goalSeekData.idleTime;
          self.fuelMeasureType = returnResult.data.goalSeekData.fuelMeasureType == "liter" ? "ltr" : "gal";
          //self.maxslider1 = Math.ceil(returnResult.data.goalSeekData.idleFuelUse) + 1;
          if(returnResult.data.goalSeekData.idleFuelUse > returnResult.data.goalSeekData.maxslider1)
            self.maxslider1 = Math.ceil(returnResult.data.goalSeekData.idleFuelUse) + 1;
          else
            self.maxslider1 = returnResult.data.goalSeekData.maxslider1;

          self.minslider1 = returnResult.data.goalSeekData.minslider1;
          self.intervalslider1 = returnResult.data.goalSeekData.intervalslider1;
          self.minslider2 = returnResult.data.goalSeekData.minslider2;
          self.maxslider2 = returnResult.data.goalSeekData.maxslider2;
          self.minslider3 = returnResult.data.goalSeekData.minslider3;
          self.maxslider3 = returnResult.data.goalSeekData.maxslider3;
          self.spentIdleTime = returnResult.data.goalSeekData.idleTime;
          self.spentFuelCost = returnResult.data.goalSeekData.fuelCost;
          self.spentFuelUse = returnResult.data.goalSeekData.idleFuelUse;
          
          self.gridTitle = returnResult.data.gridTitle;
          self.chartTitle = returnResult.data.chartTitle;
          // use from background widget 
          self.chartProgressValue = self.chartProgress.value + self.chartProgress.type;
          self.chartProgressIcon = (self.chartProgress.icon == "down") ? "md-chart-arrow-down" : (self.chartProgress.icon == "up") ? "md-chart-arrow-up" : "";
          self.chartProgressColor = (self.chartProgress.color == "green") ? "active-green-color" : (self.chartProgress.color == "red") ? "active-red-color" : "";
          self.sortColumn = returnResult.data.sortColumn;
          self.sortOrder = returnResult.data.sortOrder;

          $(".md-dialog.dChartDetailsModal-lg").removeClass("lowZindex");
          self.loaderHide=true; 
      }).catch(err => {
        $(".md-dialog.dChartDetailsModal-lg").removeClass("lowZindex");
        // self.hideloaderClass = 'cd-chart-ifDataBlank'
        self.loaderHide=true
      });

      setTimeout(() => {
          jQuery(".IdlingDrillPopup .highcharts-yaxis-grid").last().find(".highcharts-grid-line").last().attr('stroke', "#fff");          
      },100);
    }
  }
};


</script>
<style scoped>
/*
=============================================================
Please put your custom CSS below (CTrack BS Row Structure) 
============================================================
*/
/*CTrack BS Row Structure (Please don't change or use this CSS)*/
.ctrack-row{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-right:-10px;margin-left:-10px;}
[class*="ctrack-col"]{position:relative;width:100%;min-height:1px;padding-right:10px;padding-left:10px;}
.ctrack-d-flex{display:-webkit-box!important;display:-ms-flexbox!important;display:flex!important;}
.ctrack-align-items-center{-webkit-box-align:center!important;-ms-flex-align:center!important;align-items:center!important;}
.ctrack-justify-content-center{-webkit-box-pack:center!important;-ms-flex-pack:center!important;justify-content:center!important;}
.ctrack-h-100{height: 100%;}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}

.ctrack-col{-ms-flex-preferred-size:0;flex-basis:0;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;max-width:100%;}
.ctrack-col-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}

@media only screen and (min-width:576px){
.ctrack-col-sm-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-sm-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-sm-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-sm-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-sm-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-sm-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-sm-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-sm-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-sm-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-sm-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-sm-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-sm-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}

@media only screen and (min-width:768px){
.ctrack-col-md-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-md-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-md-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-md-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-md-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-md-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-md-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-md-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-md-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-md-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-md-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-md-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}

@media only screen and (min-width:992px){
.ctrack-col-lg-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-lg-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-lg-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-lg-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-lg-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-lg-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-lg-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-lg-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-lg-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-lg-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-lg-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-lg-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}

@media only screen and (min-width:1200px){
.ctrack-col-xl-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-xl-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-xl-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-xl-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-xl-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-xl-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-xl-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-xl-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-xl-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-xl-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-xl-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-xl-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}

/*CTrack BS Row Structure (Please don't change or use above CSS)*/
/*
=============================================================
Start your custom CSS from here
============================================================
*/

/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');*{font-family:'Roboto',sans-serif;}
[class*="md-chart-arrow"]{display:inline-block;vertical-align:middle;}
[class*="md-chart-arrow"] svg{display:block;}
.md-chart-arrow-down{transform:rotate(180deg);-webkit-transform:rotate(180deg);-moz-transform:rotate(180deg);-ms-transform:rotate(180deg);-o-transform:rotate(180deg);}

/*Overspeed Drill Popup CSS*/
.ctrack-md-modal{border-radius:10px;outline:0;box-shadow:1px 2px 16px rgba(0,0,0,0.26);-webkit-box-shadow:1px 2px 16px rgba(0,0,0,0.26);-moz-box-shadow:1px 2px 16px rgba(0,0,0,0.26);}
.ctrack-md-dialog-content{height:100%;display:inline-block;width:100%;overflow: auto;-webkit-overflow-scrolling: touch;}
.md-expopup-Header-title{display:inline-block;width:100%;padding:12px 20px;border-bottom:1px solid #E8E8E8;position: relative;}
.md-expopup-Header-title h4{color:#333333;font-size:20px;font-weight:500;position:relative;padding-left:40px;display:inline-block;width:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;vertical-align:middle;}
.md-expopup-Header-title h4 .dExe-title-icon{position:absolute;left:0;top:50%;transform:translateY(-50%);}
.md-expopup-Header-title h4 .dExe-title-icon svg{display:block;}
.md-expopup-Header-closebtn{color:#013C5E !important;position:absolute;right:20px;top:20px;}
.md-expopup-Header-closebtn:hover{color:#333 !important;}
.ctrack-modal-body,.md-exModal-inner,.md-exModal-top,.md-exModal-bottom,.md-exModal-topText,.md-exModal-topChart-main,.md-exModal-topChartTitle,.md-exModal-topChart,.md-exModal-bSection,.md-exModal-bottomTbl,.md-speed-calculator{display:inline-block;width:100%;}
.md-exModal-inner{padding:20px;}
.md-exModal-top{padding:0 0 10px;}
.md-exModal-topText{font-size:18px;color:#333333;font-weight:normal;line-height:31px;padding:0 15px;}
.md-exModal-topText span{color:#FA4B4B;}
.md-exModal-topChartTitle{text-align:center;color:#333333;font-weight:500;}
.md-exModal-topChartTitle label{font-size:16px;padding:0 4px;display:inline-block;vertical-align:middle;}
.md-exModal-topChart{padding-bottom:15px;}
.md-exModal-bHeader{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:10px;}
.md-exModal-bHeader > div svg{display:block;}
.md-exModal-bHeader h5{font-size:18px;font-weight:bold;color:#002B49;}
.md-exModal-bRightSection{display: inline-block; width: 100%;}
.md-exModal-bRBottomSection{margin-top:auto;}
.md-exModal-bRightSection > div{width:100%;}
.active-green-color{color:#76CD24 !important}
.active-red-color{color:#FA4B4B;}

/* Fuel section */
.md-number-field2{border:1px solid #CCCCCC;background-color:#FFF;outline:0;border-radius:2px;padding:2px 5px 2px 2px;font-size:13px !important;color:#898989;font-weight:500;display:inline-block;width:100%;vertical-align:middle;height:30px; max-width:100%; text-align: right;}
/* input[type=number]::-webkit-inner-spin-button{opacity:1;} */
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button, input[type=number] {-webkit-appearance: none;appearance: none; margin: 0;-moz-appearance:textfield;}
.ctrack-fuelRange{display:block;width:94%;margin:0 auto;}
.md-pl-0{padding: 0;}
.md-fuel-form-lbl{position:relative;display:inline-block;width:100%;vertical-align:middle;padding-left:35px;font-weight:500;color:#898989;line-height:16px;word-break: break-all;word-break: break-word;}
.md-fuel-form-icon{position:absolute;left:0;top:0;color:#002B49;width:28px;text-align:center;}
.md-fuel-form-icon svg{display:block;margin: 0 auto;}
.ctrack-fuel-rangeSlider{padding-bottom:20px;}
.md-drawer-row > div.md-drawer-col2, .md-drawer-row > div.md-drawer-col3 {width: 84px;}
.md-dr-s2-title {white-space: nowrap;overflow: hidden; text-overflow: ellipsis;}

/*Goal Seek Drawer CSS*/
.md-drawer-row{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:nowrap;flex-wrap:nowrap;}
.md-drawer-row > div{-ms-flex-preferred-size:0;flex-basis:0;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;max-width:100%;position:relative;width:100%;min-height:1px;}
.md-drawer-section1 .md-drawer-col1{-webkit-box-flex:0;-ms-flex:0 0 45%;flex:0 0 45%;max-width:45%;}
.md-drawer-section1 .md-drawer-col3{-webkit-box-flex:0;-ms-flex:0 0 13%;flex:0 0 13%;max-width:13%;}
.md-drawer-rSlider {padding: 0 20px;}
.md-drawer-title{position:relative;padding-left:50px;vertical-align:middle;width:100%;font-size:16px;color:#898989;line-height:26px;font-weight:500;}
.md-drawer-title-icon{position:absolute;left:0;top:50%;width: 30px;text-align: center;color:#002B49;transform:translateY(-50%);-webkit-transform:translateY(-50%);-moz-transform:translateY(-50%);-ms-transform:translateY(-50%);-o-transform:translateY(-50%);}
.md-drawer-title-icon svg{display:block;margin: 0 auto}
.md-drawer-label{background-color:#EEF3F6;color: #8999AF;border-radius:5px;font-size:14px;font-weight:500;text-align:center;padding:8px 8px 7px;}
.md-drawer-section1 .md-drawer-row{margin-bottom:20px;}
.md-drawer-section2{border-top:1px solid #E2E2E2;padding:40px 0 0;}
.md-dr-s2-top{display:inline-block;width:100%;}
.md-dr-s2-title{display:inline-block;width:100%;font-size:15px;color:#002B49;font-weight:700;margin-bottom:8px;padding-top: 10px;}
.md-drawer-section2 .md-drawer-title{background-color:#F7F7F7;}
.md-drawer-section2 .md-drawer-title{font-size:15px;color:#333333;}
.md-drawer-section1 .md-drawer-row{-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.md-dr-s2-content .md-drawer-title{padding-left:80px;padding-top: 10px; padding-bottom: 8px;}
.md-dr-s2-content .md-drawer-title-icon{left:25px;}
.md-dr-s2-top .md-drawer-col1 .md-dr-s2-title{padding-left: 25px;}
.md-drawer-section2 .md-drawer-row:nth-child(odd) .md-drawer-title{background:#F0F0F0;}
.md-drawer-section2 .md-drawer-row > .md-drawer-col1{-webkit-box-flex:0;-ms-flex:0 0 48%;flex:0 0 48%;max-width:48%;}
.md-drawer-parameters{font-size:15px;background:#fcd8d8;color:#000000;padding: 10px;border: 2px solid #fcd8d8; border-radius: 9px;}
.md-drawer-section2 .md-drawer-col2,.md-drawer-section2 .md-drawer-col3{text-align:center;padding: 0 11px;}
.md-drawer-param-main{position:relative;height:100%;background-color:#fcd8d8;}
.md-drawer-parameters.active{font-weight:bold;background-color:#fab9b9;}
.md-drawer-section2 .md-drawer-col3{margin:0 25px;}
.md-drawer-param-last .md-drawer-param-main{border-radius:0 0 10px 10px;}
.md-drawer-param-first .md-drawer-param-main{border-radius:10px 10px 0 0;}
.md-drawer-spent:before, .md-drawer-change:before{content:"";position:absolute;background:#f03f3f;left:0;top:0;right:0;bottom:0;}
.md-drawer-change:before{background: #81D249}
.md-dr-spent .md-dr-s2-title, .md-dr-change .md-dr-s2-title{color: #FFF}
.md-drawer-spent.md-drawer-param-last:before {border-radius: 0 0 25px 25px;bottom: -12px;}
.md-dr-change{background-color:#81D249;border-radius:25px 25px 0 0;}
.md-drawer-change .md-drawer-param-main{background-color:#e6f6da;}
.md-drawer-change .md-drawer-parameters{background:#e6f6da; border-color: #e6f6da}
.md-drawer-change .md-drawer-parameters.active{background-color:#cfddc4;}
.dm-drawer-triangle-top{position:absolute;top:-27px;left:0;width:100%;color:#f03f3f;/*background-color:#FFF;*/z-index:-1;}
.md-dr-spent{z-index:1;background-color:#f03f3f;}
.dm-drawer-triangle-bottom{position:absolute;top:45px;left:0;width:100%;color:#81D249;}
.md-dr-s2-bottom{display:inline-block;width:100%;margin:45px 0 25px;padding:10px;}
.md-dr-s2-bottom .md-drawer-row > div{padding:10px;}
.md-dr-s2-btns{padding:10px;background-color:#E1E7F0;text-align:center;font-size:15px;font-weight:700;color:#002B49;border-radius:10px;}
.md-s2-btn-title,.md-s2-btn-value{display:inline-block;width:100%;vertical-align: middle;}
.md-s2-btn-value{font-size:22px;font-weight:normal;color:#000000;}
.md-s2-green-btn .md-dr-s2-btns{color:#6CB23B;background-color:#e6f6db;}
.md-s2-green-btn .dBehaviour-box-value-icon{color: #6CB23B}
.dm-drawer-triangle-top svg{vertical-align:middle;display:inline-block;height:100%;width:100%;background-color:#FFF}
.md-idling-mobile{display: none}
.md-exModal-right{display:-webkit-box;display:-ms-flexbox;display:flex;width:100%;height:100%;}
.md-exModal-right > .ctrack-row{align-content:space-between;}



/* PARAMETER on Modal */
.md-idlling-fuel{padding-top:20px;}
.md-exModal-parameter{display:inline-block;width:100%;}
.md-exModal-parameter .md-dr-s2-bottom{margin:30px 0 0;padding:0;}
.md-exModal-parameter .md-drawer-section2{border:0;padding:20px 0 0;}
.md-exModal-parameter .md-dr-s2-top .md-drawer-col1 .md-dr-s2-title{padding-left:0;}
.md-exModal-parameter .dm-drawer-triangle-top{top:-10px;}
.md-exModal-parameter .md-dr-s2-content .md-drawer-title{padding-top:10px;padding-bottom:5px;padding-left:55px;}
.md-exModal-parameter .md-drawer-parameters{padding:8px 0;}
.md-exModal-parameter .dm-drawer-triangle-bottom{top:40px;}
.md-exModal-parameter .md-dr-s2-content .md-drawer-title-icon{left:15px;}
.md-exModal-parameter .md-dr-spent .md-dr-s2-title,.md-exModal-parameter .md-dr-change .md-dr-s2-title, .md-exModal-parameter .md-drawer-section2 .md-drawer-title, .md-exModal-parameter .md-drawer-parameters{font-size:14px;}
.md-exModal-parameter .md-dr-s2-bottom{margin:15px 0 0;padding:0;}
.md-exModal-parameter .md-dr-s2-bottom .md-drawer-row > div{padding:5px;}
.md-exModal-parameter .md-drawer-section2 .md-drawer-col3{margin: 0 0 0 20px;}
.md-para-info-icon{display: inline-block; vertical-align: middle; cursor: pointer;}
.md-para-info-icon svg{display: inline-block}




/* Responsive CSS */
@media only screen and (min-width:1200px){
  .md-exModal-bottom .ctrack-col-xl-5{-ms-flex:0 0 44%;flex:0 0 44%;max-width:44%;}
  .md-exModal-bottom .ctrack-col-xl-7{-ms-flex:0 0 56%;flex:0 0 56%;max-width:56%;}
  .md-exModal-style2 .md-exModal-top .ctrack-col-xl-5{-ms-flex:0 0 47.666667%;flex:0 0 47.666667%;max-width:47.666667%;}
  .md-exModal-style2 .md-exModal-top .ctrack-col-xl-7{-ms-flex:0 0 52.333333%;flex:0 0 52.333333%;max-width:52.333333%;}
}
@media only screen and (min-width:1300px){}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1399px){
  .md-exModal-parameter .md-drawer-section2 .md-drawer-row > .md-drawer-col1{-ms-flex: 0 0 45%;flex: 0 0 45%;max-width: 45%;}
  .md-exModal-parameter .md-dr-spent .md-dr-s2-title,.md-exModal-parameter .md-dr-change .md-dr-s2-title, .md-exModal-parameter .md-drawer-section2 .md-drawer-title, .md-exModal-parameter .md-drawer-parameters{font-size: 13px;}
}
@media only screen and (max-width:1299px){}
@media only screen and (max-width:1199px){
  .md-s2-btn-value{font-size:18px;}
}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){
  .md-exModal-topChart-main{padding-top:15px;}
  .md-exModal-bRtopSection{padding:20px 0 5px;}
  .md-s2-btn-value{font-size:16px;}
  .md-dr-s2-btns{padding: 8px;font-size: 14px;}
  .md-exModal-parameter .dm-drawer-triangle-top{top:-25px;}
  .md-idling-mobile{display: block}
  .md-idling-desktop{display: none}
  .md-exModal-topText{padding: 0 0 15px}
  .md-exModal-right{display:inline-block;}

}
@media only screen and (max-width:767px){
    .ctrack-fuelRange{margin:35px auto 0;}
    .ctrack-fuel-rangeSlider{padding-bottom:30px;}
    /*Goal Seek Drawer CSS*/
    .md-drawer-box{width:80%;right: -80%;top: 120px;}
    .md-drawer-section1{padding:20px 15px;}
    .md-drawer-header{padding:15px;font-size:20px;}
    .drawer-btn-link{padding:10px;}
    .md-drawer-section1 .md-drawer-col1{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
    .md-drawer-section1 .md-drawer-row{margin-bottom:15px;}
    .md-drawer-rSlider{padding:5px 5px 0;}
    .md-number-drawer-row{margin-top:0;}
    .md-drawer-section2 .md-drawer-col3{margin:0 15px;}
    .md-dr-s2-content .md-drawer-title{padding-left:50px;}
    .md-dr-s2-content .md-drawer-title-icon{left:15px;}
    .md-dr-s2-top .md-drawer-col1 .md-dr-s2-title{padding-left:20px;}
    .md-drawer-section2 .md-drawer-title{font-size:13px;}
    .md-drawer-title{font-size:14px;padding-left:35px;}
    .md-drawer-parameters{padding:12px 0;font-size:13px;}
    .md-dr-s2-title{font-size:14px;}
    .md-drawer-section2 .md-drawer-col2,.md-drawer-section2 .md-drawer-col3{padding:0 8px;}
    .md-dr-s2-bottom{margin:30px 0 0px;}
    .md-s2-btn-value{font-size:18px;}
    .md-form-group{padding:10px 0 0;}    
    .md-pl-0{padding-right:10px;}

}
@media only screen and (max-width:575px){
    .md-exModal-topText{padding:0;font-size:16px;line-height:28px;}
    .md-speed-cal-col1{-ms-flex:0 0 44%;flex:0 0 44%;max-width:44%;}

    

    /*Goal Seek Drawer CSS*/
    .md-dr-s2-content .md-drawer-row{flex-wrap:nowrap;-ms-flex-wrap:nowrap;}
    .md-dr-s2-content{overflow:auto;padding:35px 0 70px;display:inline-block;width:100%;}
    .md-dr-s2-top .md-drawer-row > div{flex:0 0 150px;min-width:150px;max-width:100%;}
    .md-drawer-section2{padding-top:20px;padding-right: 10px;}
    .md-dr-s2-top .md-drawer-row > .md-drawer-col1, .md-exModal-parameter .md-drawer-section2 .md-drawer-row > .md-drawer-col1{-ms-flex:0 0 200px;flex:0 0 200px;max-width:200px;}
    .md-dr-s2-bottom{margin-top:0;}
    .md-drawer-section2 .md-dr-s2-bottom .md-drawer-row > .md-drawer-col1{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
    .md-dr-s2-bottom .md-drawer-row > div{padding-top:0;}



}

</style>


<style type="text/css">
.md-exModal-bottomTbl .md-table.md-theme-default .md-table-head{background-color:#7F9CAD;color:#FFF;}
.md-exModal-bottomTbl .md-table-head{line-height:normal;}
.md-exModal-bottomTbl .md-table-head-container{height:32px;padding:0;}
.md-exModal-bottomTbl .md-table-head-label,.md-exModal-bottomTbl .md-table-cell-container{height:32px;line-height:32px;/*padding:0 5px;*/font-family:'Roboto',sans-serif;font-size:14px;}
.md-exModal-bottomTbl .md-table.md-theme-default .md-sortable:hover svg, .md-exModal-bottomTbl .md-table.md-theme-default .md-sortable.md-sorted svg {fill: #FFF}
.md-exModal-bottomTbl .md-table-head-label{font-weight:500;}
.md-exModal-bottomTbl .md-table-head:last-child:not(:first-child) .md-table-head-label,.md-exModal-bottomTbl .md-table-cell:last-child .md-table-cell-container{padding-right:5px;}
.md-exModal-bottomTbl .md-table.md-theme-default .md-table-row td,.md-exModal-bottomTbl .md-table.md-theme-default .md-table-row th{border:0;border-right:solid 1px #E8E8E8;}
.md-exModal-bottomTbl .md-table-cell-container{color:#333333;line-height:normal;height:auto;white-space: nowrap;}
.md-exModal-bottomTbl .md-table-cell{height:32px;vertical-align:middle;}
.md-exModal-bottomTbl .md-table.md-theme-default .md-table-row:nth-child(odd) td{background:#F3F3F3;}
.md-exModal-bottomTbl .md-table.md-theme-default .md-table-row:hover:not(.md-header-row) .md-table-cell{background:#d2dce2;}
.ctrack-fuelRange .vue-slider-ltr .vue-slider-mark-label{margin-top:-25px;left:auto;right:0;transform:translateX(0)}
.ctrack-fuelRange .vue-slider-ltr .vue-slider-mark-active[style="height: 100%; width: 4px; left: 0%;"] .vue-slider-mark-label.vue-slider-mark-label-active{left:0;}
.md-para-info-data-modal + .md-overlay + .md-overlay{z-index:9912 !important;}
.md-dialog.md-theme-default.md-para-info-data-modal{z-index:9913 !important;border:6px solid #002B49;border-radius:5px;}
.md-para-info-data-modal .md-exModal-bottomTbl .md-table.md-theme-default .md-table-head{background-color:#002B49;}
.md-para-info-data-modal .md-expopup-Header-title h4{color:#002B49;}
.md-drawer-parameters .md-number-field2{background-color:#ffffff;border-color:#f03f3f;text-align:center;color:#333;}




/* PARAMETER Info Table */
.md-exModal-bottomTbl.md-para-info-tbl .md-table-head-container{height:30px;}
.md-exModal-bottomTbl.md-para-info-tbl .md-table-head-label,.md-exModal-bottomTbl.md-para-info-tbl .md-table-cell-container{height:30px;line-height:30px;font-size:12px;padding:0 15px;}
.md-exModal-bottomTbl.md-para-info-tbl .md-table-cell{font-weight:normal;height:30px;min-width:100px;}
.md-para-info-data-modal .md-dialog-container{display:inline-block;width:100%;}
.md-para-info-data{display:inline-block;width:100%;height:100%;}
.md-para-info-data-modal .md-para-info-body{display:inline-block;width:100%;height:calc(100% - 53px);}
.md-para-info-data-modal .md-exModal-bottomTbl{height:100%;display:inline-block;width:100%;overflow-y:auto;-webkit-overflow-scrolling:touch;-ms-overflow-style:-ms-autohiding-scrollbar;}
.md-para-info-data-modal .md-content{padding:5px;}
.md-para-info-bottom-data{display:inline-block;width:100%;padding:10px;}




@media only screen and (max-width:1599px){
.md-exModal-bottomTbl .md-table-head-label,.md-exModal-bottomTbl .md-table-cell-container{font-size:13px;}
}

</style>