import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VuePassword from 'vue-password'
import VueMoment from 'vue-moment'
import MomentTz from 'moment-timezone'
import { extendMoment } from 'moment-range'
import VueLocalStorage from 'vue-localstorage'
import './assets/styles.css';
import './assets/ctracktheme.css';
import VueSession from 'vue-session'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import * as L from 'leaflet';
import 'leaflet.icon.glyph';
import VueBlu from 'vue-blu';
import 'vue-blu/dist/css/vue-blu.min.css';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.plugins.register(ChartDataLabels);
Chart.helpers.merge(Chart.defaults.global.plugins.datalabels, {
    display: false,
    color: '#fff'
});
import 'leaflet-geosearch/assets/css/leaflet.css';
import Autocomplete from 'vuejs-auto-complete';
import vuetify from './plugins/vuetify';
import DatetimePicker from 'vuetify-datetime-picker'
import './shared/directives';
import jQuery from 'jquery'
import '@mdi/font/css/materialdesignicons.css'
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import VueResizeObserver from "vue-resize-observer";
import VueKonva from 'vue-konva';
import Particles from "vue2-particles";
import customIcon from '@/components/icons/custom-icon.vue';
import './shared/geopoint.js';
import VueCryptojs from 'vue-cryptojs';
import { getters } from "./shared/observeFilter";
import version from "./assets/json/version.json";
import VueAppInsights from 'vue-application-insights'; // Main JS (in UMD format)
//Pinia Stores
import { createPinia, PiniaVuePlugin } from 'pinia'
import { useUserStore } from '@/stores/user-store';
import { useGroupsFilterStore } from '@/stores/groups-store';
import { useDriverStore } from '@/stores/driver-store';
import { useAssetStore } from '@/stores/asset-store';
import { useLocationStore } from '@/stores/location-store';
import { useContactStore } from '@/stores/contact-store';
import { useTenantStore } from '@/stores/tenant-store';
import { useColumnStore } from '@/stores/column-store';
import { useAlertPoliciesStore } from '@/stores/alert-policies-store';
import { useRecentActivityStore } from '@/stores/recent-activity-store';
import { useInsightsStore } from '@/stores/insights-store';
import { useCommonStore } from '@/stores/common-store';
import { useVendorStore } from '@/stores/vendor-store';
import { useAMMStore } from '@/stores/AMM-store';
import { useConfigStore } from '@/stores/config-store';
const pinia = createPinia();
Vue.use(pinia);
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
pinia.use(piniaPluginPersistedstate);
Vue.prototype.$userStore = useUserStore();
Vue.prototype.$groupsFilterStore = useGroupsFilterStore();
Vue.prototype.$driverStore = useDriverStore();
Vue.prototype.$assetStore = useAssetStore();
Vue.prototype.$locationStore = useLocationStore();
Vue.prototype.$contactStore = useContactStore();
Vue.prototype.$tenantStore = useTenantStore();
Vue.prototype.$columnStore = useColumnStore();
Vue.prototype.$alertPoliciesStore = useAlertPoliciesStore();
Vue.prototype.$recentActivity = useRecentActivityStore();
Vue.prototype.$insights = useInsightsStore();
Vue.prototype.$common = useCommonStore();
Vue.prototype.$vendorStore = useVendorStore();
Vue.prototype.$AMMStore = useAMMStore();
Vue.prototype.$configStore = useConfigStore();
window.jQuery = jQuery
window.$ = jQuery
import {
    GridComponent,
    OldGridComponent,
    SearchSelect,
    BaseDatePicker,
    InseegoDateTimePicker,
    InseegoTimeBar,
    InseegoTimeBarRangePicker,
    BasePagination,
    OldBasePagination,
    NotificationPage,
    AlertNotification,
    GroupsGlobal,
    ConfirmDialog
} from "./shared/index";
import { DrillDownGrid, ExecutiveCommonDrillPopup, IdlingDrillPopup } from "./views/Mydashboard/index";
import Toasted from 'vue-toasted';
import { i18n } from './shared/i18n.js';
import toast from "./shared/toast";
import httpInterceptor from './shared/http-interceptor.js';
import GlobalFilters from './shared/filters.js';
import VueDraggable from 'vue-draggable';
import 'vue-draggable/polyfills'
import { Datetime } from 'vue-datetime';
import VueSlider from "vue-slider-component";
import 'vue-slider-component/theme/default.css'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import '@progress/kendo-ui';
import { ChartSeriesItem, StockChart, Sparkline, SparklineSeriesItem, ChartInstaller } from '@progress/kendo-charts-vue-wrapper';
import '@progress/kendo-theme-material/dist/all.css';
import { DropdownsInstaller } from '@progress/kendo-dropdowns-vue-wrapper';

import { GridPdfExport } from '@progress/kendo-vue-pdf';
import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
import { ExportEmailDialog, PopupDialog } from './views/Shared';
import VTooltip from "v-tooltip";
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import 'leaflet.markercluster.freezable';
import 'leaflet.path.drag';
import bar from '../src/components/charts/barV3'
import doughnut from '../src/components/charts/doughnutV3'
import horizontalBar from '../src/components/charts/horizontalBarV3'
import emailDialog from '@/components/dialogs/email-dialog'
import Constant from "@/../constant";
import './plugins/replay';
import VueApexCharts from 'vue-apexcharts'
import './assets/js/kendo-ui-license.js';

import {
    MaskedTextBox,
    NumericTextBox,
    ColorPicker,
    Slider,
    RangeSlider,
    InputsInstaller
} from '@progress/kendo-inputs-vue-wrapper'; // import { Chart, ChartSeriesItem, ChartInstaller } from '@progress/kendo-charts-vue-wrapper';
// import VueDragAndDropList from 'vue-drag-and-drop-list';
import Vuer from "v-viewer";
import html2canvas from "html2canvas";
import CKEditor from 'ckeditor4-vue';

import store from './store';

Vue.use(Particles);
Vue.use(VueApexCharts);
Vue.use(ChartInstaller, ChartSeriesItem);

Vue.use(InputsInstaller);
import './shared/validation';
httpInterceptor.initialize();
Vue.mixin(GlobalFilters);

Vue.use(Toasted, { router, singleton: true });
Vue.use(DatetimePicker);
Vue.use(VueKonva);
Vue.use(VueDraggable);
Vue.use(Autocomplete);
Vue.use(DropdownsInstaller);
Vue.use(VTooltip);
Vue.use(VueVideoPlayer, /* {
  options: global default options,
  events: global videojs events
} */)
Vue.component('bar', bar);
Vue.component('doughnut', doughnut);
Vue.component('horizontalBar', horizontalBar);
Vue.component('customIcon', customIcon);
Vue.use(VueResizeObserver);
Vue.use(Vuer, { name: "vuer" });
import "viewerjs/dist/viewer.css";
import commonService from './service/common.service'

Vue.use(html2canvas);
Vue.use(CKEditor);
Vue.use(VueCryptojs);
// Vue.use(VueDraggable);
// Vue.use(VueDragAndDropList);
Vue.component('confirm-dialog', ConfirmDialog);
Vue.component('app-grid', GridComponent);
Vue.component("old-app-grid", OldGridComponent);
Vue.component('search-select', SearchSelect);
Vue.component('BaseDatePicker', BaseDatePicker);
Vue.component('InseegoDateTimePicker', InseegoDateTimePicker);
Vue.component('InseegoTimeBar', InseegoTimeBar);
Vue.component('InseegoTimeBarRangePicker', InseegoTimeBarRangePicker);
Vue.component('datetime', Datetime);
Vue.component('vue-slider', VueSlider);
Vue.component('BasePagination', BasePagination);
Vue.component("OldBasePagination", OldBasePagination);
Vue.component('Notification', NotificationPage);
Vue.component('AlertNotification', AlertNotification);
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
Vue.component('VuePassword', VuePassword);
Vue.component('Grid', Grid);
Vue.component('grid-pdf-export', GridPdfExport);
Vue.component('grid-toolbar', GridToolbar);
Vue.prototype.$toast = toast;
Vue.component("DrillDownGrid", DrillDownGrid);
Vue.component("ExecutiveCommonDrillPopup", ExecutiveCommonDrillPopup);
Vue.component("IdlingDrillPopup", IdlingDrillPopup);
Vue.component('export-email-dialog', ExportEmailDialog);
Vue.component('emailDialog', emailDialog);
Vue.component('popup-dialog', PopupDialog);
Vue.component('groups-global', GroupsGlobal);
Vue.component('l-marker-cluster', Vue2LeafletMarkerCluster);
Vue.prototype.$livemapEventHub = new Vue();
Vue.component('apexchart', VueApexCharts);
Vue.prototype.$contactEventHub = new Vue();
Vue.prototype.$driverEventHub = new Vue();
Vue.prototype.$vehicleEventHub = new Vue();
Vue.prototype.$userEventHub = new Vue();
Vue.prototype.$drawerEventHub = new Vue();
Vue.prototype.$dialogEventHub = new Vue();
Vue.prototype.$dashboardEventHub = new Vue();
Vue.prototype.$reportEventHub = new Vue();
Vue.prototype.$jobEventHub = new Vue();
Vue.prototype.$settingsEventHub = new Vue();
Vue.prototype.$fleetAlertEventHub = new Vue();
Vue.prototype.$gridEventHub = new Vue();
Vue.prototype.$assetMaintenance = new Vue();
Vue.prototype.$routesEventHub = new Vue();
Vue.prototype.$schedulesEventHub = new Vue();
Vue.prototype.$tachoEventHub = new Vue();
Vue.prototype.$assetMaintenanceEventHub = new Vue();
Vue.prototype.$constant = Constant;
Vue.prototype.$globalFilters = getters;
Vue.prototype.$groupFilters = new Vue();
Vue.prototype.$signalREventHub = new Vue();
Vue.prototype.$softwareVersion = "v" + (config.VUE_APP_Version || version.versioning.versionnumber);

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('./assets/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

Vue.use(VueLocalStorage)
Vue.use(VueMaterial)
Vue.use(VueSession, { persist: true })
const moment = extendMoment(MomentTz);
Vue.use(VueMoment, {
    moment
})
Vue.use(VueBlu)

commonService.getAppInsightsKey().then(results => {
    if (results !== "") {
        Vue.use(VueAppInsights, {
            id: results,
            //router
        });
    }
});

Vue.prototype.$config = (setting) => {
    if (config[setting]) {
        return config[setting];
    }
    const configProperties = Vue.prototype.$session.get("configProperties");
    if (setting) {
        return configProperties[setting];
    }
    return configProperties;
};

Vue.config.productionTip = false;
Vue.config.performance = true;
const opts = {
    theme: {
        themes: {
            light: {
                primary: "#002B49", // #E53935 Dark Blue
                secondary: "#40C1AC", // #FFCDD2 //Teal
                accent: "#40C1AC", // Teal
            },
            default: {
                primary: "#002B49", // #E53935 Dark Blue
                secondary: "#40C1AC", // #FFCDD2 //Teal
                accent: "#40C1AC", // Teal
            },
        },
    },
}


new Vue({
    vuetify,
    components: {
        MaskedTextBox,
        NumericTextBox,
        ColorPicker,
        Slider,
        RangeSlider
    },
    store,
    router,
    VuePassword,
    i18n,
    render: h => h(App)
}).$mount('#app');