<template>
  <div class="FuelConsumptionWidget ctrack-h-100" ref="FuelConsumption">
    <div id="vueapp" class="vue-app ctrack-h-100">
        
      <!-- Fuel Consumption Widget -->
      <div class="dBehaviour-inner" :class="widgetColorClass">        
        <div class="dBehaviour-box-col">
          <div class="dBehaviour-box-icon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33"><path d="M494.484,450a16.472,16.472,0,1,1,.045,0Zm-6.059-28.611a.521.521,0,0,0-.336.547q.012,11.574,0,23.148a.515.515,0,0,0,.336.545,13.36,13.36,0,0,0,12.417-.146,13.544,13.544,0,0,0-6.153-25.524A13.878,13.878,0,0,0,488.425,421.389Zm7.008,20.779v-2.484h1.812v.381H495.93v.571h1.236v.439h-1.242v.693h1.4v.4Zm3.111-.129a9.125,9.125,0,0,0,2.837-3.392l1.291.684c-.638,1.038-3.142,2.757-3.947,2.757A.29.29,0,0,1,498.543,442.038Zm3.179-4.028c.148-.715.322-1.4.427-2.089a8.019,8.019,0,0,0-.239-3.385.319.319,0,0,1,.2-.449c.73-.376,1.448-.774,2.169-1.161.077-.041.16-.072.284-.125a9.579,9.579,0,0,1-1.378,7.926Zm-6.477-2.027q-1.282-.649-2.564-1.3a.377.377,0,0,0-.458.017,1.289,1.289,0,0,1-1.637-.129,1.316,1.316,0,0,1,1.276-2.22,1.275,1.275,0,0,1,.932,1.348c-.018.223.083.3.236.391q2.8,1.679,5.6,3.366a2.5,2.5,0,0,1,.283.227l-.1.106Zm2.936-8.52,1.858-2.416a9.241,9.241,0,0,1,4.26,5.078l-2.686,1.534A9.029,9.029,0,0,0,498.182,427.463Zm-2.67-.146v-2.492h1.672c.008.116.017.225.028.382h-1.231v.622h1.042c.009.15.017.257.026.415h-1.072v1.073Z" transform="translate(-477.999 -417.001)" /></svg></div>
          <div class="dBehaviour-box-content">
            <h3>
              <span class="dBehaviour-box-value-icon" :class="widgetIcon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10"><path d="M638.392,193H628.62a.626.626,0,0,1-.527-.962c.213-.383,4.509-8.038,4.876-8.691a.6.6,0,0,1,1.059,0c.269.47,4.587,8.154,4.887,8.711A.623.623,0,0,1,638.392,193Z" transform="translate(-628 -183.028)" /></svg></span>{{widgetValue}}
            </h3>
            <p class="ctrack-text-truncate">{{widgetTitle}}</p>
          </div>
        </div>        
      </div>
      <!-- Fuel Consumption Widget -->

    </div>    
  </div>
</template>

<script>
export default {
  name: 'fuelconsumptionwidget',
  components: {},
  data: function() {
        return {}
    },  
  created(){
        this.getFuelConsumptionWidgetData();        
  },
  methods: {
        getFuelConsumptionWidgetData(){
            let self = this;
            const returnResult = {
                title: "Fuel Consumption",
                value: "19",
                type:"%",
                icon: "up",
                color: "red"
            };
            
            self.widgetTitle = returnResult.title;
            self.widgetValue = returnResult.value + returnResult.type;
            self.widgetIcon =  (returnResult.icon == "down") ? "md-chart-arrow-down" : "md-chart-arrow-up";
            self.widgetColorClass = (returnResult.color == "green") ? "success-color" : "error-color";
        }
    }
  };

</script>
<style scoped>

/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
*{font-family: 'Roboto', sans-serif;}
.ctrack-h-100{height: 100%;}
.error-color .dBehaviour-box-col h3{color:#FA4B4B;}
.success-color .dBehaviour-box-col h3{color:#76CD24;}
[class*="md-chart-arrow"]{display:inline-block;vertical-align:middle;}
[class*="md-chart-arrow"] svg{display:block;}
.md-chart-arrow-down{transform:rotate(180deg); -webkit-transform:rotate(180deg); -moz-transform:rotate(180deg); -ms-transform:rotate(180deg); -o-transform:rotate(180deg);}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}

.dBehaviour-inner{display:inline-block;width:100%;height:100%;background-color:#FFF;padding:13px 20px;border-radius: 5px; box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, .05); -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, .05);}
.dBehaviour-box-row{display:inline-block;width:100%;padding-left:40px;position:relative;}
.dBehaviour-box-col{display:inline-block;vertical-align: middle;position:relative;width:100%;text-align:right;padding-left:40px;}
.dBehaviour-box-icon{position:absolute;color:#656565; left:0;top:50%;transform:translateY(-50%); -webkit-transform:translateY(-50%); -moz-transform:translateY(-50%); -ms-transform:translateY(-50%); -o-transform:translateY(-50%);}
.dBehaviour-box-content{display:block;width:100%;padding-left:5px;}
.dBehaviour-box-content h3{font-size:20px;font-weight:500;}
.dBehaviour-box-value-icon{margin-right:8px;}
.dBehaviour-box-content p{font-size:15px;color:#333333;font-weight:500;}


/* Responsive CSS */
@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1399px){}
@media only screen and (max-width:1299px){}
@media only screen and (max-width:1199px){}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){}
@media only screen and (max-width:767px){}
@media only screen and (max-width:575px){}

</style>
