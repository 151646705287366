import axios from "axios";

class ReportService {
    //Reports
    getFavouriteReportList() {
        return axios.get(`/api/report/GetFavouriteReportList`)
    }

    getReportSessionAPI(reportName) {
        let params = {
            "reportName": reportName
        }
        return axios.get(`/api/report/GetReportSessionAPI`, { params: params })
    }

    getAuditActions() {
        return axios.get(`/api/report/GetAuditActions`)
    }
    getTemperatureAssetList() {
        return axios.get(`/api/report/GetTemperatureAssetList`)
    }

    getallStatusList() {
        let returnStatusses = []
        return axios.get(`/api/report/GetAllStatusList`)
            .then(response => {
                response.forEach((item, index) => {
                    switch (item.Value) {
                        case "ARMResponse":
                            break
                        case "ExcessFuelUsage":
                            break
                        case "HarshCount":
                            break
                        case "OilPressure":
                            break
                        case "WaterLevel":
                            break
                        case "NxPartnerMissing":
                            break
                        default:
                            returnStatusses.push(item)
                            break
                    }
                })
                return JSON.stringify(returnStatusses)
            });
    }

    getEdgeStatusEnums() {
        return axios.get(`/api/report/GetEdgeStatusEnums`)
    }

    deleteFavouriteReport(id) {
        return axios.delete(`/api/report/DeleteFavouriteReport/${id}`)
    }

    bpCommentPopUp(id, data) {
        return axios.put(`/api/report/BPCommentPopUp/${id}`, data)
    }

    getAllTrips(startTime, endTime, idslist, type = "vehicle") {
        const params = {
            type: type,
            startTime: startTime,
            endTime: endTime,
            sortBy: 'vehicleId',
            pageSize: 1000
        };
        return axios.post(`/api/report/GetAllTrips`, {'ids': idslist}, {params: params})
    }

    getAveragesReportSummary(starttime, endtime, idslist, pageName, type) {
        const params = {
            type: type,
            startTime: starttime,
            endTime: endtime,
            pageName: pageName,
            sortBy: 'vehicleId',
            pageSize: 10,
        };
        return axios.post(`/api/report/GetAveragesReportSummary`, { 'ids': idslist }, { params: params })
    }

    saveFavouriteReport(body) {
        return axios.post(`/api/report/SaveFavouriteReport`, body)
    }

    saveReportSession(body) {
        return axios.post(`/api/report/SaveReportSession`, body)
    }

    validateReportParameters(body) {
        return axios.post(`/api/report/ValidateReportParameters`, body)
    }

    exportCSVDocument(body) {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        };
        return fetch(`/api/report/ExportCSVDocument`, requestOptions);
    }

    //Schedule
    getScheduleAll() {
        return axios.get(`/api/report/GetScheduleAll`)
    }

    deleteSchedulebyID(params) {
        return axios.delete(`/api/report/DeleteSchedulebyID/id/${params.id}/schedulerId/${params.schedulerId}`)
    }

    addReportSchedule(body) {
        return axios.post(`/api/report/AddReportSchedule`, body)
    }

    sendRecipientEmail(body) {
        return axios.post(`/api/report/SendRecipientEmail`, body)
    }
    GetReportSchedule(id) {
        return axios.get(`/api/report/GetReportSchedule/${id}`)
    }
    UpdateSchedule(body) {
        return axios.post(`/api/report/UpdateSchedule`, body)
    }
    ExtractedDownloadFiles(body) {
        return axios.post(`/api/report/ExtractedDownloadFiles`, body)
    }
    async GetExtractionFile(file) {
        return await axios.get(`/api/report/GetExtractionFile/${file}`);
    }
    //LPD
    getAllPagedTrips(startTime, endTime, idslist, type) {
        let params = {
            type: type,
            startTime: startTime,
            endTime: endTime,
            sortBy: 'vehicleId',
            pageSize: 1000
        };

        return axios.post(`/api/report/GetAllPagedTrips`, { 'ids': idslist }, { params: params })
    }

    getAllPagedEventSummary(startTime, endTime, idslist, type) {
        let params = {
            type: type,
            startTime: startTime,
            endTime: endTime,
            sortBy: 'vehicleId',
            pageSize: 1000
        };

        return axios.post(`/api/report/getAllPagedTrips`, { 'ids': idslist }, { params: params })
    }
    //Tacho
    getTachoData(model) {
        return axios.post(`/api/report/GetTachoData`, model);
    }
}

export default new ReportService();