import { ReportsService } from "../service";

const state = () => ({
    downloadFileToPrompt : [],
})

const getters = {
    getDownloadFileToPrompt: state => state.downloadFileToPrompt,
}

const actions = {
    saveReportSession({ commit }, payload) {
        return ReportsService.saveReportSession(payload);
    },
    addFileToDownloadPrompt({ commit }, file) {
        commit('addDownloadFileToPrompt', file);
    },
    removeDownloadFileFromPrompt({ commit }, file) {
        commit('removeDownloadFileFromPrompt', file);
    },
}

const mutations = {
    addDownloadFileToPrompt(state, file) {
        state.downloadFileToPrompt.push(file);
    },
    removeDownloadFileFromPrompt(state, file) {
        state.downloadFileToPrompt.splice(state.downloadFileToPrompt.indexOf(file),1);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
