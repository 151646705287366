<template>
    <td :class="className">
        <div>
            {{valid}}
        </div>
    </td>
</template>

<script>
    export default {
        props: {
            field: String,
            dataItem: Object,
            format: String,
            className: String,
            columnIndex: Number,
            columnsCount: Number,
            rowType: String,
            level: Number,
            expanded: Boolean,
            editor: String
        },
        computed: {
            valid() {
                return this.dataItem[this.field] ? 'Valid' : 'Error';
            }
        }
    };
</script>
<style scoped>

</style>
