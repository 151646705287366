<template>
    <v-list class="list-overflow"
            style="max-height: 80vh;min-width:360px;max-width:360px;">
        <v-list-item class="titleListItem">
            <v-toolbar dense
                       color="#5cc2ae">
                <v-toolbar-title class="heading"
                                 style="color: #113651 !important; text-shadow: 0 0 black; display: contents;">
                    {{$t('Dashboard.filter')}}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon
                       @click="$emit('closeMenu')"
                       style="margin-right: -16px;"
                       auto-id="filter_close">
                    <v-icon style="color: #113651 !important;">close</v-icon>
                </v-btn>
            </v-toolbar>
        </v-list-item>
        <v-list-item-group>
            <v-list-item v-for="(filter,index) in filterOptions"
                         :key="getKey(filter,index)"
                         v-if="filter.title && filter.title != ' '"
                         :class="[filter.groupBy ? 'groupHeader' : 'hoverable']">
                <v-list-item-content @click="applyFilter(filter)"
                                     v-if="filter.filter">
                    <v-list-item-title style="display: inline-flex; ">
                        {{$t(filter.title)}}
                        <v-spacer></v-spacer>
                        <v-icon>play_arrow</v-icon>
                    </v-list-item-title>
                </v-list-item-content>
                <v-list-item-content v-if="filter.groupBy && filterGroupingList(filter)"
                                     style="padding: 0;">
                    <v-list-group :value="false">
                        <template v-slot:activator
                                  style="padding: 0;">
                            <v-list-item-title>
                                {{$t(filter.title)}}
                            </v-list-item-title>
                        </template>
                        <filterOption :filter="filter"
                                      :list="filterGroupingList(filter)"
                                      :preFilter="[]"
                                      @filterByField="(i) => filterByField(i.filter,i.type)">
                        </filterOption>
                    </v-list-group>
                </v-list-item-content>
            </v-list-item>
        </v-list-item-group>
        <v-list-item class="restoreListItem">
            <v-btn text block outlined
                   @click="clearFilters(); $emit('closeMenu');">
                {{$t("UserLabels.restoreToDefaults")}}
            </v-btn>
        </v-list-item>
    </v-list>
</template>
<script>
    import filterOption from '@/views/Shared/GridTools/QuickFilterOption'
    export default {
        name: "QuickFilterList",
        components: {
            filterOption,
        },
        data() {
            return {
                groupingList: {},
                filterOptions: [],
            }
        },
        props: {
            columns: Array,
            persist: Function,
            filterChange: Function,
            gridSettingsKey: String,
            filterShortcuts: {
                type: Array,
                default() {
                    return []
                }
            },
            dark: {
                type: Boolean,
                required: false,
                default: true,
            },
            data: {
                type: Array,
                default() {
                    return []
                },
            },
            appliedFilters: {
                type: Object,
                default() {
                    return {
                        filters: [],
                    };
                },
            },
        },
        methods: {
            clearFilters() {
                this.filterChange({
                    filter: {
                        logic: 'and',
                        filters: [],
                    },
                });
            },
            applyFilter(config) {
                this.filterChange(config);
                if (config.columns.required) {
                    for (var x = 0; x < this.columns.length; x++) {
                        if (this.columns[x].field == 'selected') {
                            this.columns[x].orderIndex = ((config.columns.required.length + 1) * -1);
                        } else {
                            if (config.columns.required.includes(this.columns[x].field)) {
                                this.columns[x].hidden = false;
                                this.columns[x].visible = true;
                                this.columns[x].orderIndex = (config.columns.required.length * -1) - config.columns.required.indexOf(this.columns[x].field) * -1
                            };
                        };
                    };
                };
                if (config.columns.preset) {
                    for (var x = 0; x < this.columns.length; x++) {
                        this.columns[x].hidden = !config.columns.preset.includes(this.columns[x].field);
                        this.columns[x].visible = !this.columns[x].hidden;
                        this.columns[x].orderIndex = config.columns.preset.includes(this.columns[x].field)
                            ? columns.indexOf(this.columns[x].field)
                            : this.columns[x].orderIndex + config.columns.preset.length;
                    };
                };
                if (config.columns.required || config.columns.preset) {
                    this.applyColumns();
                };
            },
            applyColumns() {
                this.columns.sort((a, b) => {
                    if (a.orderIndex === undefined || b.orderIndex === undefined) {
                        return 1;
                    };
                    return (a.orderIndex > b.orderIndex) ? 1 : -1;
                });
                for (var x = 0; x < this.columns.length; x++) {
                    this.columns[x].orderIndex = x;
                };
                if (this.persist !== undefined) {
                    this.persist();
                };
            },
            filterByField(config, value) {
                let filters = [];
                if (typeof (config.groupBy) == 'object') {
                    filters = this.getMultipleFilters(config, value);
                } else {
                    if (Array.isArray(value)) {
                        //if value is an array, but groupby is not an object, then pass through the first object only.
                        filters = this.getFilters(config, value[0]);
                    } else {
                        filters = this.getFilters(config, value);
                    };
                };
                let tempConfig = {
                    ...config,
                    filter: {
                        logic: 'and',
                        filters: filters,
                    },
                };
                this.applyFilter(tempConfig);
            },
            getMultipleFilters(config, value) {
                let targetValue = value.shift();
                let filters = this.getFilters({
                    groupBy: config.groupBy.value,
                    dataType: config.groupBy.dataType || 'String',
                    valueConversion: config.groupBy.valueConversion || function (e) { return e },
                    processBlanks: config.processBlanks || false,
                }, targetValue);
                if (config.groupBy && value.length > 0) {
                    let nextLevel = this.getMultipleFilters(config.groupBy, value);
                    return [...filters, ...nextLevel];
                } else {
                    return filters;
                };
            },
            getFilters(config, value) {
                let self = this;
                let emptyString = self.$t('quickFilter.none').toUpperCase();
                if (value == emptyString) {
                    return [
                        {
                            field: config.groupBy,
                            operator: function (data) {
                                return data
                                    ? config.dataType == 'Custom'
                                        ? config.valueConversion(data)
                                            ? false
                                            : true
                                        : config.dataType == 'CSV'
                                            ? data.toUpperCase().includes(emptyString)
                                                ? true
                                                : false
                                            : config.dataType == 'Array'
                                                ? data.map(i => i.toUpperCase()).includes(emptyString)
                                                : false
                                    : true //If Data has no value - return true
                            },
                        },
                    ];
                } else {
                    return [
                        {
                            field: config.groupBy,
                            operator: config.dataType == 'Custom'
                                ? function (data) {
                                    if (data || config.processBlanks) {
                                        let val = config.valueConversion(data);
                                        if (Array.isArray(val)) {
                                            return val.map(i => isNaN(i) ? i.toUpperCase() : i).includes(value.toUpperCase());
                                        } else {
                                        return (val ? val.toUpperCase() : '') == value.toUpperCase();
                                        };
                                    } else {
                                        return false;
                                    };
                                }
                                : config.dataType == 'CSV'
                                    ? 'contains'
                                    : config.dataType == 'Array'
                                        ? function (data) {
                                            return data
                                                ? data.map(i => i.toUpperCase()).includes(value)
                                                : false
                                        }
                                        : config.dataType == 'Date'
                                            ? function (data) {
                                                try {
                                                    return data
                                                        ? (self.parseDate(data)?.toString() || '') == value
                                                        : false
                                                } catch (e) {
                                                    return false;
                                                };
                                            }
                                            : 'eq',
                            value: value,
                        },
                    ];
                };
            },
            parseDate(value) {
                return value
                ? !isNaN(new Date(!isNaN(parseFloat(value)) ? parseFloat(value) : value.toUpperCase()).getTime())
                    ? this.$formatUTCTimeToRegionalFormat(!isNaN(parseFloat(value)) ? parseFloat(value) : value.toUpperCase(), false)
                        : null
                    : null
            },
            parseDateForSort(value) {
                return value
                ? !isNaN(new Date(!isNaN(parseFloat(value)) ? parseFloat(value) : value.toUpperCase()).getTime())
                    ? new Date(!isNaN(parseFloat(value)) ? parseFloat(value) : value.toUpperCase()).getTime()
                        : null
                    : null
            },
            //List Compilation
            parseValues(groupOption, value) {
                let self = this;
                switch (groupOption.dataType) {
                    case 'CSV':
                        return [...new Set([...value.map(i => i[groupOption.groupBy]).join(',').split(',').filter(i => i).map(i => isNaN(i) ? i.toUpperCase() : i)])];
                        break;
                    case 'Array':
                        return [...new Set([...value.map(i => i[groupOption.groupBy]).reduce((list, entry) => { return [...list, ...entry] }, []).filter(i => i).map(i => isNaN(i) ? i.toUpperCase() : i)])];
                        break;
                    case 'Date':
                        return [...new Set([...value.map(i => self.parseDate(i[groupOption.groupBy]))])];
                        break;
                    case 'Custom':
                        return value.map(i => groupOption.valueConversion(i[groupOption.groupBy]))
                            .filter(i => i)
                            .reduce((list, entry) => {
                                if (Array.isArray(entry)) {
                                    return [...list, ...entry];
                                } else {
                                    return [...list, entry];
                                };
                            }, [])
                            .map(i => isNaN(i) ? i.toUpperCase() : i);
                        break;
                    case 'String':
                        return value.map(i => i[groupOption.groupBy]).filter(i => i).map(i => isNaN(i) ? i.toUpperCase() : i);
                        break;
                    case 'SubGroup':
                        let response = self.parseSubGroup(groupOption.groupBy, value);
                        return response;
                        break;
                    default:
                        return value
                            .map(i => i[groupOption.groupBy])
                            .filter(i => i)
                            .map(i => isNaN(i) ? i.toUpperCase() : i);
                        break;
                };
            },
            parseSubGroup(groupBy, value) {
                //let list = [...new Set(value.map(i => i[groupBy.value]).filter(i => i).map(i => isNaN(i) ? i.toUpperCase() : i))].sort();
                let list = [...new Set(this.parseValues({
                    dataType: groupBy.dataType,
                    groupBy: groupBy.value,
                    valueConversion: groupBy.valueConversion || function (e) { return e },
                }, value))].sort();
                if (groupBy.groupBy) {
                    return list.map(i => ({
                        value: i,
                        options: this.parseSubGroup(
                            groupBy.groupBy,
                            this.preFilterForSubGroups(groupBy, value, i),
                        ),
                    }));
                } else {
                    return list;
                };
            },
            preFilterForSubGroups(groupBy, value, filterValue) {
                switch (groupBy.dataType) {
                    case 'CSV':
                        return value.filter(v => v[groupBy.value]
                            && v[groupBy.value].split(',').filter(i => i).map(i => isNaN(i) ? i.toUpperCase() : i).includes(filterValue)
                        );
                        break;
                    case 'Array':
                        return value.filter(v => v[groupBy.value]
                            && v[groupBy.value].filter(i => i).map(i => isNaN(i) ? i.toUpperCase() : i).includes(filterValue)
                        );
                        break;
                    case 'Date':
                        return value.filter(v => v[groupBy.value]
                            && this.parseDate(v[groupBy.value]) == filterValue
                        );
                        break;
                    case 'Custom':
                        return value
                            .filter(v => v[groupBy.value]) //Ensure there is a value
                            .map(v => ({ ...v, _convertedValue: groupBy.valueConversion(v[groupBy.value]) })) //create a new field with the converted value
                            .map(v => ({
                                ...v,
                                _convertedValue: isNaN(v._convertedValue)
                                    ? Array.isArray(v._convertedValue)
                                        ? v._convertedValue.map(i => isNaN(i)
                                            ? i.toUpperCase()
                                            : i
                                        )
                                        : v._convertedValue.toUpperCase()
                                    : v._convertedValue
                            })) //Parse converted value to uppercawse for comparison
                            .filter(v => Array.isArray(v._convertedValue)
                                ? v._convertedValue.includes(filterValue)
                                : v._convertedValue == filterValue); //Filter on converted value
                        break;
                    case 'String':
                    default:
                        return value.filter(v => v[groupBy.value] // Exists
                            && (
                                (isNaN(v[groupBy.value])
                                    ? v[groupBy.value].toUpperCase() //Not a number - Uppercase
                                    : v[groupBy.value]) == filterValue) //Is a Number - Straight Compate
                        );
                        break;
                };
            },
            //Key
            getKey(filter) {
                return filter.groupName
                    ? filter.groupName
                    : filter.groupBy && typeof filter.groupBy != 'object'
                        ? filter.groupBy
                        : filter.title
            },
            filterGroupingList(filter) {
                return filter.groupName
                    ? this.groupingList[filter.groupName]
                    : this.groupingList[filter.groupBy]
            },
        },
        watch: {
            data:
            {
                immediate: true,
                deep: true,
                handler(value, oldvalue) {    
                    //determine items that need grouping                   
                    if (value?.length > 0) {
                        //Confirm it was not selection that changed
                        if (value.map(i => i.selected).filter(i => i).length == (oldvalue ? oldvalue.map(i => i.selected).filter(i => i).length : 0) && !(value == oldvalue)) {
                            let self = this;
                            self.groupingList = {}; // Removes persistent data - only remove if page monitors group changes.
                            self.$columnStore.quickFilter[self.gridSettingsKey].filter(i => i.groupBy)
                                .map(i => ({
                                    ...i,
                                    groupName: i.groupName || i.groupBy,
                                    valueConversion: Object.keys(i).includes('valueConversion')
                                        ? i.valueConversion
                                        : function (i) { return i },
                                    processBlanks: i.processBlanks || false,
                                }))
                                .forEach(groupOption => {
                                    //Create Group if not exist
                                    if (!Object.keys(self.groupingList).includes(groupOption.groupName)) {
                                        self.groupingList[groupOption.groupName] = [];
                                    };
                                    let entries = self.parseValues(groupOption, value);
                                    //Merge with existing list
                                    if (groupOption.dataType == 'SubGroup') {
                                        //Strigify Objects before reducing list
                                        self.groupingList[groupOption.groupName] = [
                                            ...new Set([
                                                ...self.groupingList[groupOption.groupName].map(i => JSON.stringify(i)),
                                                ...entries.map(i => JSON.stringify(i))
                                            ])
                                        ].map(i => JSON.parse(i));
                                    } else {
                                        self.groupingList[groupOption.groupName] = [
                                            ...new Set([
                                                ...self.groupingList[groupOption.groupName],
                                                ...entries
                                            ])
                                        ];
                                    };
                                    //Sort
                                    self.groupingList[groupOption.groupName] = self.groupingList[groupOption.groupName].filter(i => i).sort((a, b) => {
                                        return groupOption.dataType == 'Date'
                                            ? self.parseDateForSort(a) < self.parseDateForSort(b) ? 1 : -1
                                            : groupOption.dataType == 'SubGroup'
                                                ? a.value > b.value ? 1 : -1
                                                : a > b ? 1 : -1
                                    });
                                    //Add None Option
                                    if (!groupOption.processBlanks) {
                                        self.groupingList[groupOption.groupName] = [
                                            self.$t('quickFilter.none').toUpperCase(),
                                            ...self.groupingList[groupOption.groupName],
                                        ];
                                    };
                                   
                                });
                            let options = self.$columnStore.quickFilter[self.gridSettingsKey]
                            self.filterOptions = options
                                ? options.filter(i => i.groupBy ? self.groupingList[i.groupName || i.groupBy].length > 0 : true)
                                : [];
                        };
                    } else {
                        this.groupingList = {};
                    };
                },
            },
        }
    };
</script>
<style scoped>
    .customSettingsScroll {
        top: 170px !important;
    }

    .setToDefault {
        color: #002B49;
        background-color: rgb(255, 255, 255);
        border: 2px solid #002B49;
    }

    /*==========css for Setting column on vehicle page=============*/
    .customSettingsScroll.bottomauto {
        bottom: auto !important;
        top: 150px !important;
    }

.customSettingsScroll.bottomauto>>>.md-menu-content-container {
            max-height: 400px !important
        }

    /*==========end=============*/

    .list-overflow {
        overflow-y: auto;
        max-height: calc(100vh - 25px);
        padding: 0;
    }

    .menuable__content__active {
        z-index: 15 !important;
    }

    .titleListItem {
        padding: 0;
        position: sticky;
        top: 0px;
        background-color: white;
        z-index: 8;
    }

    .shortCutListItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        min-height: 32px;
        height: 32px;
        background-color: rgb(80, 194, 173);
        border-top-style: inset;
        border-width: thin;
        border-bottom-style: outset;
        position: sticky;
        top: 48px;
        z-index: 9;
        flex-direction: row;
    }

    .v-list-item:after {
        display: none;
    }

    .restoreListItem {
        position: sticky;
        bottom: 0px;
        background-color: white;
        padding-bottom: 8px;
        padding-top: 8px;
        border-top-style: inset;
        border-width: thin;
    }

    .groupHeader {
        padding: 0;
    }

    .groupItem {
        margin-left: 8px;
        font-size: 14px;
        min-height: 36px;
    }

    ::v-deep .v-list-item.on-hover {
        background-color: #eeeeee
    }

    ::v-deep .v-list-group__header {
        border-top: thin solid white;
        border-bottom: thin solid white;
        padding-top: 4px;
        padding-bottom: 2px;
    }

        ::v-deep .v-list-group__header:hover {
            background-color: #66c2af !important;
            border-top: thin inset #102b49;
            border-bottom: thin groove #102b49;
        }

    ::v-deep .hoverable {
        padding-top: 4px;
        padding-bottom: 2px;
        border-top: thin solid white;
        border-bottom: thin solid white;
    }

        ::v-deep .hoverable:hover {
            background-color: #66c2af !important;
            border-top: thin inset #102b49;
            border-bottom: thin groove #102b49;
        }

    ::v-deep .v-list-item__title.hoverable {
        padding-left: 8px;
        padding-right: 8px;
    }
</style>