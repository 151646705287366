<template>
  <div class="DriverHarshBrakingWidget ctrack-h-100" ref="HarshBrakingWidget">
    <div id="vueapp" class="vue-app ctrack-h-100">
        
    <!-- Harsh Braking -->
    <div class="dBehaviour-inner" :class="widgetColorClass">
      <div class="dBehaviour-box-col">
        <div class="dBehaviour-box-icon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="36" height="33" viewBox="0 0 36 33"><path d="M315.485,216a.694.694,0,1,1,0-1.388H347.3a.694.694,0,1,1,0,1.388Zm-3.257-.2a.687.687,0,0,1,0-.981.708.708,0,0,1,.994,0,.687.687,0,0,1,0,.981.708.708,0,0,1-.994,0Zm17.024-3.315-5.144-5.079-2.061,2.036a1.314,1.314,0,0,1-1.839,0l-1.962-1.938-5.025,4.96a.708.708,0,0,1-.994,0,.687.687,0,0,1,0-.981l5.024-4.961-1.892-1.868-2.138,2.111a.708.708,0,0,1-.994,0,.687.687,0,0,1,0-.981l2.138-2.111-1.963-1.938a1.274,1.274,0,0,1,0-1.815l2.061-2.035-2.025-2a1.463,1.463,0,0,1,0-2.09,7.073,7.073,0,0,1,8.505-1.071l3.326,1.932a13.2,13.2,0,0,0,8.269,1.663,3.355,3.355,0,0,0,1.918-.932l.665-.657L346.8,183.2a.709.709,0,0,1,.994,0,.687.687,0,0,1,0,.981l-11.18,11.038,5.586,5.516,4.6-4.541a.71.71,0,0,1,.995,0,.689.689,0,0,1,0,.981l-5.1,5.032-5.206,5.138-4.429,4.373-.773.763a2.168,2.168,0,0,1-3.038,0Zm-3.7-5.611,4.69,4.63a.749.749,0,0,0,1.05,0l.275-.272-3.932-3.883-1.283-1.265Zm-12.084-6.045,7.656,7.559,1.986-1.962-7.656-7.558Zm23.5,5.073,1.76-1.737-6.5-6.413a14.626,14.626,0,0,1-2.779-.005v.974a.7.7,0,0,1-1.406,0v-1.181q-.714-.14-1.411-.351l-.406.97a.708.708,0,0,1-.918.377.691.691,0,0,1-.381-.906l.381-.912a14.375,14.375,0,0,1-1.757-.864l-.081-.047-.752.844a.708.708,0,0,1-.992.062.687.687,0,0,1-.063-.979l.574-.644-2.011-1.168a5.654,5.654,0,0,0-6.8.856.085.085,0,0,0-.027.064.088.088,0,0,0,.027.064l10.75,10.612.013.014.366.361.8-.79-4.486-4.428a.689.689,0,0,1,0-.981.71.71,0,0,1,.995,0l4.982,4.918,1.308,1.291a7.051,7.051,0,0,1,8.809,0Zm-18.08-7.19a.686.686,0,0,1,0-.98.708.708,0,0,1,.994,0,.686.686,0,0,1,0,.98.708.708,0,0,1-.994,0Z" transform="translate(-312 -182.998)"/></svg></div>
        <div class="dBehaviour-box-content">
          <h3>
            <span class="dBehaviour-box-value-icon" :class="widgetIcon"><svg v-if="widgetIcon!=''" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10"><path d="M638.392,193H628.62a.626.626,0,0,1-.527-.962c.213-.383,4.509-8.038,4.876-8.691a.6.6,0,0,1,1.059,0c.269.47,4.587,8.154,4.887,8.711A.623.623,0,0,1,638.392,193Z" transform="translate(-628 -183.028)" /></svg></span>{{widgetValue}}
          </h3>
          <p class="ctrack-text-truncate">{{widgetTitle}}</p>
        </div>
      </div>
      
    </div>
    <!-- Harsh Braking -->

    </div>
  </div>
</template>

<script>
import dashboardService from "../../../service/dashboard.service";
import { getters } from "../shared/observeDashboardFilter";

export default {
  name: 'harshbrakingwidget',
  components: {},
  data: function() {
        return {
          widgetTitle:'-',
          widgetValue:'',
          widgetIcon:'',
          widgetColorClass:''

        }
  },
  props: {
    widgetData: Object
  },
  created(){
      this.getDriverHarshBrakingWidgetData();        
  },  
  methods: {
      getDriverHarshBrakingWidgetData() {
        let self = this;
        dashboardService.getWidgetData(this.widgetData.api, getters.getFilterParams() ).then(returnResult => {
          /* const returnResult =  {
              hwTitle: "Harsh Braking",
              value: "23",
              type:"%",
              icon: "up",
              color: "red"
          }; */        
          self.widgetTitle = returnResult.hwTitle;
          self.widgetValue = returnResult.value + returnResult.type;
          self.widgetIcon =  (returnResult.icon == "down") ? "md-chart-arrow-down" : (returnResult.icon == "up") ? "md-chart-arrow-up" : "";
          self.widgetColorClass = (returnResult.color == "green") ? "success-color" : (returnResult.color == "red") ? "error-color" : "";
        });
      
       
    }
  }
  
};

</script>
<style scoped>

/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
*{font-family: 'Roboto', sans-serif;}
.ctrack-h-100{height: 100%;}
.error-color .dBehaviour-box-col h3{color:#FA4B4B;}
.success-color .dBehaviour-box-col h3{color:#76CD24;}
[class*="md-chart-arrow"]{display:inline-block;vertical-align:middle;}
[class*="md-chart-arrow"] svg{display:block;}
.md-chart-arrow-down{transform:rotate(180deg); -webkit-transform:rotate(180deg); -moz-transform:rotate(180deg); -ms-transform:rotate(180deg); -o-transform:rotate(180deg);}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}

.dBehaviour-inner{display:inline-block;width:100%;height:100%;background-color:#FFF;padding:13px 20px;border-radius: 5px; box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, .05); -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, .05);}
.dBehaviour-box-row{display:inline-block;width:100%;padding-left:40px;position:relative;}
.dBehaviour-box-col{display:inline-block;vertical-align: middle;position:relative;width:100%;text-align:right;padding-left:40px;}
.dBehaviour-box-icon{position:absolute;color:#656565; left:0;top:50%;transform:translateY(-50%); -webkit-transform:translateY(-50%); -moz-transform:translateY(-50%); -ms-transform:translateY(-50%); -o-transform:translateY(-50%);}
.dBehaviour-box-content{display:block;width:100%;padding-left:5px;}
.dBehaviour-box-content h3{font-size:20px;font-weight:500;}
.dBehaviour-box-value-icon{margin-right:8px;}
.dBehaviour-box-content p{font-size:15px;color:#333333;font-weight:500;}


/* Responsive CSS */
@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1399px){}
@media only screen and (max-width:1299px){}
@media only screen and (max-width:1199px){}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){}
@media only screen and (max-width:767px){}
@media only screen and (max-width:575px){}

</style>
