<template>
  <div class="MissedJobWidget ctrack-h-100" ref="MissedJob">
    <div id="vueapp" class="vue-app ctrack-h-100">
      
      <!-- Missed Job Chart -->
      <div class="dExecutive-inner" :class="widgetColorClass">
        <div class="dExecutive-title"><h4><span class="dExe-title-icon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20"><path d="M109.247,439a2.186,2.186,0,0,1-2.192-1.748c-.015-.068-.036-.133-.055-.2V430.83c.048,0,.095-.011.142-.011h7.564c.052,0,.105.01.161.015,0,.047.008.081.011.116a1.775,1.775,0,0,0,1.772,1.678c.6,0,1.2.011,1.8-.005a1.747,1.747,0,0,0,1.622-1.381c.031-.136.045-.275.068-.424h.171q2.418,0,4.838,0c.949,0,1.9.008,2.848.012v6.222c-.008.011-.022.02-.024.032A2.195,2.195,0,0,1,125.609,439q-4.6,0-9.207,0h-3.578Q111.035,439,109.247,439Zm7.394-8.191v-2.7h1.718v2.7ZM120.125,429c0-.329,0-.634,0-.937a1.766,1.766,0,0,0-1.409-1.743,4.766,4.766,0,0,0-.826-.045h-.256a9.159,9.159,0,0,0-1.208.017,1.779,1.779,0,0,0-1.551,1.834c0,.288,0,.576,0,.874H107v-4.388a.253.253,0,0,0,.029-.056,2.171,2.171,0,0,1,2.273-1.917q1.794,0,3.588,0h.23c0-.466,0-.9,0-1.331a2.2,2.2,0,0,1,2.2-2.308c1.463-.017,2.925-.013,4.388,0a2.138,2.138,0,0,1,2.116,1.8,7.794,7.794,0,0,1,.057,1.145c.006.224,0,.448,0,.691h.314c1.216,0,2.433-.007,3.649,0a2.147,2.147,0,0,1,2.06,1.579c.039.128.067.259.1.389V429Zm-4.785-8.18a.406.406,0,0,0-.459.4c-.012.46,0,.921,0,1.4h5.248v-1.249c0-.408-.139-.555-.525-.556H115.34Z" transform="translate(-107 -418.987)"/></svg></span> 
        {{widgetTitle}}</h4></div>
        <div class="dExecutive-content">
          <div class="ctrack-md-loader" :class="loaderHide?'ctrack-chart-hide-loader':''"></div>
          <div class="dExe-chart-row">
            <div class="dExe-chart-col1">
              <h3 :class="loaderHide?'':'ctrack-chart-hide-loader'">
                <span class="dExe-value-icon" :class="widgetIcon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="22" height="11" viewBox="0 0 22 11"><path d="M107.008,284.158l11.223-11.223,11.223,11.223Z" transform="translate(-107.008 -272.935)"/></svg></span>{{widgetValue}}
              </h3>
            </div>
            <div class="dExe-chart-col2">
              <div class="dExe-chart-main">
                  <highcharts :options="chartOptions" :class="loaderHide?'':'ctrack-chart-hide-loader'"></highcharts>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Missed Job Chart -->

    </div>    
  </div>
</template>

<script>
import Vue from 'vue'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import HighChartMore from 'highcharts/highcharts-more'
import HighChartAccessibility from 'highcharts/modules/accessibility';
import dashboardService from "../../../service/dashboard.service";
import { mutations, getters } from "../shared/observeDashboardFilter";

HighChartMore(Highcharts);
HighChartAccessibility(Highcharts);
Vue.use(HighchartsVue, { });
export default {
  name: 'missedjobchart',
  components: {},
  props: {
      widgetData: Object
  },
  data: function() {        
    return {  
      loaderHide:false,
      widgetTitle:"",
      widgetValue:"",
      widgetIcon:"",
      widgetColorClass:"",           
        chartOptions:{              
                chart: {
                  type: 'column',
                  height:210  
                },
                title: {
                    text: ''
                },
                credits: {enabled: false},       
                colorArray:['#999999','#fa4b4b'], 
                xAxis: {                    
                      categories: [],
                      labels: {
                      formatter () {
                        return `<span style="color: ${this.chart.userOptions.colorArray[this.pos]}">${this.value}</span>`
                      },
                      style:{
                        fontSize:'14px',
                        fontFamily:"Roboto,Medium",
                        opacity: 1
                        },                      
                      }  
                },
                yAxis: {       
                  labels: {
                    enabled: false
                },
                gridLineWidth: 0,
                title: {
                        enabled:false
                      } 
                },
                tooltip: { enabled: false },
                legend: {
                      enabled: false                    },
                plotOptions: {
                  series: {                                                         
                        stacking: 'normal',                                    
                        dataLabels: {         
                            enabled: true,
                            verticalAlign:'top',                            
                            color:"#ffffff", 
                            shadow:false,
                            style:{
                              fontSize:"18px",
                              // fontWeight:"bold",                  
                              fontFamily: 'Roboto,Bold',
                              textOutline:0,                                  
                              },        
                        }
                  } ,
                  legend: {
                    enabled: false
                  }
              },
              series: [{                     
                  borderRadiusTopLeft: '25%',
                  borderRadiusTopRight: '25%'
              }]
        }
    }
  },  
  created(){
    this.getMissedJobChartData();     
  },  
  methods: {    
    getMissedJobChartData() {
      let self = this;
      self.loaderHide = false;
      let apiUrl =  this.widgetData.api;
      dashboardService.getWidgetData(apiUrl, getters.getFilterParams())
      .then(returnResult => {
      });
      setTimeout(() => {
        let self = this;
        self.loaderHide=false;        
        const returnResult = { 
            id:"MissedJobChart",
            data:{
              valueData :  {
                  widgetTitle: "Missed/Late Jobs",
                  value: "19",
                  type:"%",
                  icon: "down",
                  color: "red"
              },
              chartData : {                        
                  categories: ['Last Week', 'This Week'],   
                  series: [{                 
                    data: [{
                        name: "Last Week",
                        y: 32,                                         
                        color:'#CCCCCC',                                
                      },
                      {
                      name: "This Week",
                      y: 40,                                        
                      color:'#fa4b4b'
                      }
                    ] 
                  }],
              }
            }
        };                
        self.widgetTitle = returnResult.data.valueData.widgetTitle;
        self.widgetValue = returnResult.data.valueData.value + returnResult.data.valueData.type;
        self.widgetIcon =  (returnResult.data.valueData.icon == "down") ? "md-chart-arrow-down" : (returnResult.data.valueData.icon == "up") ? "md-chart-arrow-up" : "";
        self.widgetColorClass = (returnResult.data.valueData.color == "green") ? "success-color" : (returnResult.data.valueData.color == "red") ? "error-color" : "";
        self.chartOptions.series = returnResult.data.chartData.series 
        self.chartOptions.xAxis.categories = returnResult.data.chartData.categories;  
        
        let lastWeekColor = (returnResult.data.valueData.color == "green") ? "#76CD24" : (returnResult.data.valueData.color == "red") ? "#FA4B4B" : "#000000";
        self.chartOptions.series[0].data[1].color = lastWeekColor;                          
        self.chartOptions.colorArray[1] = lastWeekColor;  
        self.loaderHide = true;         
         
          // self.chartOptions.xAxis.categories = returnResult.categories;
          // self.chartOptions.series = returnResult.series;
          // self.chartTitle = returnResult.chartTitle;
          // self.chartTitleDuration = "(" + returnResult.chartTitleDuration + ")";

          // self.loaderHide=true; 
      }, 5000);
    }
  }
};

</script>
<style scoped>

/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');*{font-family:'Roboto',sans-serif;}
.ctrack-h-100{height:100%;}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.md-dashboard-content{padding-top:10px;color:#C5C5C5;}
[class*="md-chart-arrow"]{display:inline-block;vertical-align:middle;}
[class*="md-chart-arrow"] svg{display:block;}
.md-chart-arrow-down{transform:rotate(180deg);-webkit-transform:rotate(180deg);-moz-transform:rotate(180deg);-ms-transform:rotate(180deg);-o-transform:rotate(180deg);}
.dExecutive-inner{height:100%;width:100%;display:inline-block;background-color:#FFFFFF;border-top:6px solid #CDCDCD;border-radius:5px;padding:0 20px 20px;}
.dExecutive-title{display:inline-block;width:100%;padding:10px 0;}
.dExecutive-title h4{color:#333333;font-size:20px;font-weight:500;position:relative;padding-left:40px;display:inline-block;width:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
.dExecutive-title h4 .dExe-title-icon{position:absolute;left:0;top:50%;transform:translateY(-50%);}
.dExecutive-title h4 .dExe-title-icon svg{display:block;}
.dExecutive-content{display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;justify-content:space-between;-webkit-justify-content:space-between;height:calc(100% - 60px);}
.dExecutive-main div[class*="ctrack-col-"]{margin-bottom:20px;}
.dExe-chart-row{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:nowrap;flex-wrap:nowrap;-webkit-box-align:center;-ms-flex-align:center;align-items:center;justify-content:space-between;-webkit-justify-content:space-between;width:100%;}
.ctrack-align-end{-webkit-box-align:flex-end;-ms-flex-align:flex-end;align-items:flex-end;}
.dExe-chart-col1 h3{font-size:50px;display:inline-block;width:100%;vertical-align:middle;position:relative;font-weight:700;white-space:nowrap}
.dExe-chart-col1 h3 span{display:inline-block;vertical-align:middle;padding-right:5px;}
.dExe-chart-col1 h3 span.md-chart-arrow-down{padding-right:0;padding-left:5px;}
.dExe-chart-col1 h3 svg{display:block;}
.dExe-chart-fullwidth{-ms-flex-wrap:wrap;flex-wrap:wrap;}
.dExe-chart-fullwidth > div{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
.dExe-chart-fullwidth .dExe-chart-col1 h3{text-align:center;}
.dExe-chart-main,.cd-chart-box-img{padding-left:5px;min-width: 240px;width: 100%}
.dExe-chart-fullwidth .dExe-chart-main{padding-left:0;}
.dExe-chart-lbl{display:inline-block;width:100%;margin-top:5px;}
.dExe-chart-lbl span{display:inline-block;vertical-align:middle;padding-right:8px;}
.dExe-chart-lbl span svg{display:block}
.dExe-chart-bottom{display:table;width:100%;}
.dExe-chart-bottom > div{display:table-cell;}
.dExe-chart-bottom-inner{text-align:center;display:inline-block;font-size:20px;font-weight:500;margin-top:15px;}
.dExe-chart-bottom-inner small{font-size:14px;margin-bottom:-5px;}
.dExe-chart-bottom-inner small,.dExe-chart-bottom-inner label{display:block;}
.dExe-chart-bottom-col2{text-align:right;}
.error-color{border-color:#FA4B4B;}
.success-color{border-color:#76CD24;}
.error-color .dExe-chart-col1 h3,.error-color .md-chart-color{color:#FA4B4B;}
.success-color .dExe-chart-col1 h3,.success-color .md-chart-color{color:#76CD24;}

/* Responsive CSS */
@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1399px){
    .dExe-chart-col1 h3{font-size:40px;}
    .dExe-chart-main,.cd-chart-box-img{min-width: 170px}
}
@media only screen and (max-width:1299px){
    .dExe-chart-col1 h3{font-size:35px;}
    .dExe-chart-main,.cd-chart-box-img{min-width: 170px}
}
@media only screen and (max-width:1199px){}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){}
@media only screen and (max-width:767px){}
@media only screen and (max-width:575px){
  .dExecutive-inner{padding:0 15px 15px}
  .dExe-chart-row{-ms-flex-wrap:wrap;flex-wrap:wrap;}
  .dExe-chart-row > div{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;text-align:center;}
  .dExe-chart-col1{margin-bottom:5px;}
  .dExe-chart-labels{display:table;width:100%;padding:5px 0 10px;}
  .dExe-chart-labels > div{display:table-cell;width:auto;}
  .dExe-chart-main{padding-left:0;}
}

</style>
