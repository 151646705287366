<template>
  <div class="timeLineRow notrequiredforOtherDevice" id="timeLineRow">
    <div class="timeSlider">
      <div class="previousTime" v-if="timeLineUpdated && showPreviousButton && dataLoaded" @click="onPreviousClick()">
        <div class="previousTimeData">
          <md-icon>keyboard_arrow_left</md-icon>
        </div>
      </div>
      <div style="flex:1; margin-top:24px;">
        <vue-slider
          v-if="enableSlider && showSlider && !timeLineUpdated"
          style="padding:0;"
          v-model="slideRange"
          :min="0"
          :max="24"
          :min-range="1"
          :height="4"
          :process-dragable="true"
          :tooltip="false"
        ></vue-slider>
      </div>

      <div v-if="showSlider" class="timeLineAction">
        <div class="timeLineSetting">
        <div @click="restoreTimeLine()" class="extrasmallBtn">
          <button class="smallNormalBtn">
            <md-icon>refresh</md-icon>
          </button>
        </div>
        <div @click="updateTimeLine()" class="extrasmallBtn">
          <button class="smallNormalBtn">
            <md-icon>done</md-icon>
          </button>
        </div>
        </div>
      </div>

      <div class="nextTime" v-if="timeLineUpdated && showNextButton && dataLoaded" @click="onNextClick()">
        <div class="nextTimeData">
          <md-icon>keyboard_arrow_right</md-icon>
        </div>
      </div>
    </div>
    <div
      class="timeLineDisplay"
      @click="toggleTimeLineSlider()"
      id="timeLineDisplay"
      style="padding:5px;"
    >
      <div class="timeRangeSlider"></div>
      <div :key="itemIndex" v-for="(item,itemIndex) in timeLine" class="timeline-header-cell">
        <div>
          <label>{{item}}</label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TimeLine",
  props: {
    enableSlider: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      }
    },
    dataLoaded: {
      type: Boolean,
      required: false,
      default: () => {
        return true;
      }
    }
  },
  data() {
    return {
      min: 0,
      max: 24,
      slideRange: [0, 24],
      tooltip: false,
      showSlider: false,
      timeLineUpdated: false,
      sliderTimeDiff: 0,
      showNextButton: true,
      showPreviousButton: true,
      disableClick: true
    };
  },
  computed: {
    timeLine: function() {
      const timeLine = [];
      const diff = this.max - this.min;
      let count = 0;
      for (let index = this.min; index < this.max; index++) {
        if (index == this.min || index == this.max || index % 2 != 0) {
          if (diff < 3 && count == 1) {
            timeLine.push(index);
            count = 0;
            continue;
          } else {
            timeLine.push("");
          }
        } else {
          timeLine.push(index);
        }

        count++;
      }
      
      return timeLine;
    }
  },
  methods: {
    toggleTimeLineSlider() {
      if (this.enableSlider) {
        this.showSlider = !this.showSlider;
      }
    },
    updateTimeLine() {
      this.showSlider = false;
      this.timeLineUpdated = true;
      this.min = this.slideRange[0];
      this.max = this.slideRange[1];
      this.sliderTimeDiff = this.max - this.min;
      this.$emit("onChange", this.slideRange);
    },
    restoreTimeLine() {
      this.showSlider = false;
      this.timeLineUpdated = false;
      this.slideRange = [0, 24];
      this.min = this.slideRange[0];
      this.max = this.slideRange[1];
      this.$emit("onChange", this.slideRange);
    },
    onNextClick() {
      this.showNextButton = true;
      this.showPreviousButton = true;
      if (this.sliderTimeDiff > 0) {
        let min = this.slideRange[0];
        let max = this.slideRange[1];

        if (max < 24) {
          max = max + this.sliderTimeDiff;
          if (max >= 24) {
            max = 24;
            this.showNextButton = false;
          }
        } else {
          this.showNextButton = false;
        }
        min = max - this.sliderTimeDiff;
        this.min = min;
        this.max = max;
        this.slideRange = [min, max];
        this.$emit("onChange", this.slideRange);
      }
    },
    onPreviousClick() {
      this.showPreviousButton = true;
      this.showNextButton = true;
      if (this.sliderTimeDiff > 0) {
        let min = this.slideRange[0];
        let max = this.slideRange[1];

        if (min > 0) {
          min = min - this.sliderTimeDiff;
          if (min <= 0) {
            min = 0;
            this.showPreviousButton = false;
          }
          max = min + this.sliderTimeDiff;
        } else {
          this.showPreviousButton = false;
        }
        this.min = min;
        this.max = max;
        this.slideRange = [min, max];
        this.$emit("onChange", this.slideRange);
      }
    }
  }
};
</script>
<style scoped>
/* Vue Slider css */

.vue-slider-component .vue-slider-tooltip {
  display: none;
}
.vue-slider-component .vue-slider-process {
  background-color: #043965;
}
.vue-slider-component .vue-slider-dot .vue-slider-dot-handle {
  background-color: #043965;
  border: 2px solid #fff;
}
.vue-slider {
  height: 5px;
  padding: 5px;
  margin-top: 20px;
}
.vue-slider-dot {
  top: -5px;
}
.timeLineRow {
  position: relative;
  flex:1;
}
.timeSlider {
  width: 100%;
  position: absolute;
  display: flex;
  z-index: 9990;
  top: 15px;
}

.previousTime {
  width: 30px;
  margin-right: 10px;
  position: relative;
}
.previousTimeData {
  background: rgba(0, 59, 93, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  top: -15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.previousTimeData i {
  color: #fff !important;
}
.nextTimeData {
  position: absolute;
  background: rgba(0, 59, 93, 0.5);
  left: 0;
  right: 0;
  bottom: -20px;
  top: -15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nextTimeData i {
  color: #fff !important;
}

.nextTime {
  width: 30px;
  margin-left: 10px;
  position: relative;
}

.extrasmallBtn .md-icon-button {
  width: 20px !important;
  min-width: 20px !important;
  height: 20px !important;
  margin: 0 6px !important;
}
.timeLineAction {
  display: flex;
  padding-left:5px;
  padding-right:5px;
  position: relative;
  top:-13px;
}
.smallNormalBtn{
  border:none;
  background: none;
  background: #288dc0;
  border-radius:5px;
  color:#fff !important;
  margin:0;
  padding:0;
  margin-bottom:3px;
}
.smallNormalBtn .md-icon{
  color: #fff !important;
  font-size:14px !important;
}


/* Vue Slider css end */
</style>

