import {
    ConfigurationService
} from "../../service/index";

export default {
    data() {
        return {
            config: {},
            columnConfig: {}
        };
    },
    props: {
        gridSettingsKey: {
            type: String,
            default: ""
        }
    },
    created() {
        this.config = this.$session.get("configProperties");
        this.columnConfig = this.getColumnConfig();
    },
    methods: {
        getColumnConfig() {
            if (this.gridSettingsKey === '') {
                return {};
            }
            if (this.config.columnConfig !== undefined && this.config.columnConfig.length > 0) {
                for (let i = 0; i < this.config.columnConfig.length; i++) {
                    if (this.config.columnConfig[i].name === this.gridSettingsKey) {
                        return this.config.columnConfig[i];
                    }
                }
            }
            return {
                name: this.gridSettingsKey
            };
        },
        getPageSize() {
            let pageSize = 10;
            if (this.columnConfig.pagination !== undefined) {
                pageSize = this.columnConfig.pagination;
            } else if (this.config.pagination !== undefined) {
                pageSize = this.config.pagination;
            } else {
                try {
                    //let tenantConfigProps = ConfigurationService.getTenantConfigProps();
                    let tenantConfigProps = ConfigurationService.getSpecificTenantConfigProps("pagination");
                    pageSize = tenantConfigProps.configProperties.pagination
                } catch (e) { };
            };
            return parseInt(pageSize);
        },
        saveColumnConfig() {
            if (this.$isMainTenant()) {
                if (this.gridSettingsKey === '') {
                    return;
                }

                let found = false;
                const userId = this.$session.get("tenantDetails").userId;

                if (this.config.columnConfig === undefined) {
                    this.config.columnConfig = [];
                }
            //Duplicate Check
            this.config.columnConfig.reduce((a, b) => { return a.includes(b) ? a : [...a, b] }, []);
            
                for (let i = 0; i < this.config.columnConfig.length; i++) {
                    if (this.config.columnConfig[i].name === this.gridSettingsKey) {
                        this.config.columnConfig[i] = this.columnConfig;
                        found = true;
                    }
                }
                if (!found) {
                    this.config.columnConfig.push(this.columnConfig);
                }
                if (this.config.pagination !== undefined && parseInt(this.config.pagination) === this.columnConfig.pagination) {
                    delete this.columnConfig.pagination;
                }
                if (this.columnConfig.sort === undefined || this.columnConfig.sort === null || this.columnConfig.sort === '') {
                    delete this.columnConfig.sort;
                }
                this.$session.set("configProperties", this.config);
                ConfigurationService.saveUserConfig(userId, {
                    overrideProperties: {
                        columnConfig: this.config.columnConfig
                    }
                });
            }
        }    }

}