import axios from 'axios';
class vendorService {
    getVendors(id) {
        return axios.get(`/api/vendor/GetVendors/${id}`);
    }
    upsertVendor(body) {
        if (body?.id) return axios.put(`/api/vendor/EditVendor`, body);
        return axios.post(`/api/vendor/CreateVendor`, body);
    }
    deleteVendors(ids) {
        return axios.post(`/api/vendor/DeleteVendors`, ids);
    }
    bulkUploadVendors(vendors) {
        return axios.post(`/api/vendor/BulkUploadVendors`, vendors);
    }
}

export default new vendorService();