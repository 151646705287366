<template>
    <td :class="className">
     

        <div v-if="dataItem[field]">
            <svg @click.stop="clickHandler"
                 style="cursor: pointer; fill: #002b49;"
                 xmlns="http://www.w3.org/2000/svg"
                 width="24px" height="24px"
                 viewBox="0 0 24 24">
                <title>{{$t('Tacho.showTacho')}}</title>
            <path d="M11,2V22C5.9,21.5 2,17.2 2,12C2,6.8 5.9,2.5 11,2M13,2V11H22C21.5,6.2 17.8,2.5 13,2M13,13V22C17.7,21.5 21.5,17.8 22,13H13Z" /></svg>
        </div>
        <div v-else>
            &nbsp;
        <!--{{dataItem[field]}}-->
        </div>

    </td>
</template>
<!-- The field name will be parsed for a tooltip -->
<script>
    export default {
        name: "TachoCell",
        props: {
            field: String,
            dataItem: Object,
            format: String,
            className: String,
            columnIndex: Number,
            columnsCount: Number,
            rowType: String,
            level: Number,
            expanded: Boolean,
            editor: String
        },
        methods: {
            clickHandler() {
                this.$emit(this.editor, this.dataItem);
            }
        }
    };
</script>
<style scoped>
    .gridLink {
        color: #288dc0;
        cursor: pointer;
    }
</style>