import snapshotService from "../service/snapshot.service"
import { i18n } from '../shared/i18n.js';

const state = () => ({
    behaviour: [],
    behaviourDetails: [],
    fleetAlertPriorityCategory: {},
    fleetAlertPriorityCategoryList: [],
    fleetAlertPriorityAlert: {},
    fleetAlertPriorityAlertList: [],
    fleetAlertPriorityActioned: {},
    healthCheckNonReporting: [],
    serviceLicense: {},
    utilisation: {},
    assetInspection: {},
    fuel: {},

    unscheduledJobs: 6,
    todaysJobs: [8, 15],
    jobsRemaining: [5, 9],
    jobsCompleted: [4, 9],
})

const getters = {
    allModules: state => {
        return [
            { "x": 0, "y": 0, "w": 4, "h": 2, "i": "todaysJobs", "t": i18n.t("Snapshot.todaysJobs") },
            { "x": 4, "y": 0, "w": 4, "h": 2, "i": "jobsRemaining", "t": i18n.t("Snapshot.jobsRemaining") },
            { "x": 8, "y": 0, "w": 4, "h": 2, "i": "jobsCompleted", "t": i18n.t("Snapshot.jobsCompleted") },
            { "x": 0, "y": 2, "w": 4, "h": 2, "i": "unscheduledJobs", "t": i18n.t("Snapshot.unscheduledJobs") },
            { "x": 4, "y": 2, "w": 4, "h": 2, "i": "todaysFleetAlerts", "t": i18n.t("Snapshot.todaysFleetAlerts") },
            { "x": 8, "y": 2, "w": 4, "h": 2, "i": "topGeofenceVisits", "t": i18n.t("Snapshot.topGeofenceVisits") },
            { "x": 0, "y": 4, "w": 4, "h": 2, "i": "topGeofenceViolations", "t": i18n.t("Snapshot.topGeofenceViolations") },
            { "x": 4, "y": 4, "w": 4, "h": 2, "i": "serviceDue", "t": i18n.t("Snapshot.serviceDue") },
            { "x": 8, "y": 4, "w": 4, "h": 2, "i": "serviceOverdue", "t": i18n.t("Snapshot.serviceOverdue") },
            { "x": 0, "y": 6, "w": 4, "h": 2, "i": "todaysViolations", "t": i18n.t("Snapshot.todayViolations") },
            { "x": 4, "y": 6, "w": 4, "h": 2, "i": "currentEfficiency", "t": i18n.t("Snapshot.currentEfficiency") },
            { "x": 8, "y": 6, "w": 4, "h": 2, "i": "idleAndStopSummary", "t": i18n.t("Snapshot.idleAndStopSummary") },
            { "x": 0, "y": 8, "w": 4, "h": 2, "i": "drivingBehavior", "t": i18n.t("Snapshot.drivingBehavior") },
            { "x": 4, "y": 0, "w": 4, "h": 2, "i": "locations", "t": i18n.t("Snapshot.locations"), v: "" },
            { "x": 0, "y": 4, "w": 4, "h": 2, "i": "fleetAlertPriority", "t": i18n.t("Snapshot.todaysFleetAlerts"), v: "bar" },
            
        ]
    },
    newModules: state => {
        return [
            { "x": 0, "y": 0, "w": 4, "h": 2, "i": "serviceLicense", "t": i18n.t("Snapshot.serviceAndLicense"), v: "" },
            { "x": 4, "y": 0, "w": 4, "h": 2, "i": "drivingBehavior", "t": i18n.t("Snapshot.drivingBehavior"), v: "" },
            { "x": 8, "y": 0, "w": 4, "h": 2, "i": "fleetAlertPriority", "t": i18n.t("Snapshot.todaysFleetAlerts"), v: "bar" },
            { "x": 0, "y": 2, "w": 4, "h": 2, "i": "healthCheck", "t": i18n.t("Snapshot.healthCheck"), v: "" },
            { "x": 4, "y": 2, "w": 4, "h": 2, "i": "utilisation", "t": i18n.t("Snapshot.utilisation"), v: "" },
            { "x": 8, "y": 2, "w": 4, "h": 2, "i": "assetInspection", "t": i18n.t("Snapshot.assetInspection"), v: "" },
            { "x": 0, "y": 4, "w": 4, "h": 2, "i": "fuelConsumption", "t": i18n.t("Snapshot.fuelEvents"), v: "text" }
        ]
    },
    getBehaviourData: state => state.behaviour,
    getBehaviourDetailsData: state => state.behaviourDetails,
    getFleetAlertPriorityCategoryData: state => state.fleetAlertPriorityCategory,
    getFleetAlertPriorityCategoryListData: state => state.fleetAlertPriorityCategoryList,
    getFleetAlertPriorityAlertData: state => state.fleetAlertPriorityAlert,
    getFleetAlertPriorityAlertListData: state => state.fleetAlertPriorityAlertList,
    getFleetAlertPriorityActionedData: state => state.fleetAlertPriorityActioned,
    getHealthCheckNonReportingData: state => state.healthCheckNonReporting,
    getServiceLicenseData: state => state.serviceLicense,
    getUtilisationData: state => state.utilisation,
    getAssetInspectionData: state => state.assetInspection,
    getFuelData: state => state.fuel,

    getUnscheduledJobs: state => state.unscheduledJobs,
    getTodaysJobs: state => state.todaysJobs,
    getJobsRemaining: state => state.jobsRemaining,
    getJobsCompleted: state => state.jobsCompleted,
}

const actions = {
    async getBehaviourSummary({ commit, state }, model) {
        let behaviourData = state.behaviour
        if (state.behaviour.length < 1) {
            behaviourData = [
                { id: "harshAccelerationCounts", label: i18n.t("Reporting.Enum.Status.Display.Description.HarshAcceleration"), value: 0 },
                { id: "harshBrakingCounts", label: i18n.t("Reporting.Enum.Status.Display.Description.HarshBraking"), value: 0 },
                { id: "harshCorneringCounts", label: i18n.t("Reporting.Enum.Status.Display.Description.HarshCornering"), value: 0 },
                { id: "overSpeedingCounts", label: i18n.t("Reporting.Enum.Status.Display.Description.OverSpeeding"), value: 0 },
                { id: "overSpeedingStreetCounts", label: i18n.t("Reporting.Enum.Status.Display.Description.OverSpeedingStreet"), value: 0 },
                { id: "excessiveIdlingCounts", label: i18n.t("Reporting.Enum.Status.Display.Description.ExcessiveIdling"), value: 0 },
                { id: "overRevvingCounts", label: i18n.t("Reporting.Enum.Status.Display.Description.OverRevving"), value: 0 }
            ]
        }

        if (model.type == "initial") {
            await snapshotService.getInitialSnapshotData(model.data).then(results => {
                behaviourData[0].value = results.TenantScorePayload.HarshAccelerationCounts
                behaviourData[1].value = results.TenantScorePayload.HarshBrakingCounts
                behaviourData[2].value = results.TenantScorePayload.HarshCorneringCounts
                behaviourData[3].value = results.TenantScorePayload.OverSpeedingCounts
                behaviourData[4].value = results.TenantScorePayload.OverSpeedingStreetCounts
                behaviourData[5].value = results.TenantScorePayload.ExcessiveIdlingCounts
                behaviourData[6].value = results.TenantScorePayload.OverRevvingCounts
            })
        } else if (model.type == "signalR") {
            behaviourData[0].value = model.data.HarshAccelerationCounts
            behaviourData[1].value = model.data.HarshBrakingCounts
            behaviourData[2].value = model.data.HarshCorneringCounts
            behaviourData[3].value = model.data.OverSpeedingCounts
            behaviourData[4].value = model.data.OverSpeedingStreetCounts
            behaviourData[5].value = model.data.ExcessiveIdlingCounts
            behaviourData[6].value = model.data.OverRevvingCounts
        }

        commit('setBehaviourSummary', behaviourData);
    },
    async getBehaviourDetails({ commit, state }, model) {
        let behaviourDetailsData = state.behaviourDetails
        let behaviourData = state.behaviour
        if (state.behaviour.length < 1) {
            behaviourData = [
                { id: "harshAccelerationCounts", label: i18n.t("Reporting.Enum.Status.Display.Description.HarshAcceleration"), value: 0 },
                { id: "harshBrakingCounts", label: i18n.t("Reporting.Enum.Status.Display.Description.HarshBraking"), value: 0 },
                { id: "harshCorneringCounts", label: i18n.t("Reporting.Enum.Status.Display.Description.HarshCornering"), value: 0 },
                { id: "overSpeedingCounts", label: i18n.t("Reporting.Enum.Status.Display.Description.OverSpeeding"), value: 0 },
                { id: "overSpeedingStreetCounts", label: i18n.t("Reporting.Enum.Status.Display.Description.OverSpeedingStreet"), value: 0 },
                { id: "excessiveIdlingCounts", label: i18n.t("Reporting.Enum.Status.Display.Description.ExcessiveIdling"), value: 0 },
                { id: "overRevvingCounts", label: i18n.t("Reporting.Enum.Status.Display.Description.OverRevving"), value: 0 }
            ]
        }

        if (model.type == "initial") {
            await snapshotService.getInitialSnapshotData(model.data).then(results => {
                behaviourDetailsData = results.DeviceEventCounts

                behaviourData[0].value = results.HarshAccelerationCounts
                behaviourData[1].value = results.HarshBrakingCounts
                behaviourData[2].value = results.HarshCorneringCounts
                behaviourData[3].value = results.OverSpeedingCounts
                behaviourData[4].value = results.OverSpeedingStreetCounts
                behaviourData[5].value = results.ExcessiveIdlingCounts
                behaviourData[6].value = results.OverRevvingCounts
            })

        } else if (model.type == "signalR") {
            behaviourDetailsData = model.data.DeviceEventCounts

            behaviourData[0].value = model.data.HarshAccelerationCounts
            behaviourData[1].value = model.data.HarshBrakingCounts
            behaviourData[2].value = model.data.HarshCorneringCounts
            behaviourData[3].value = model.data.OverSpeedingCounts
            behaviourData[4].value = model.data.OverSpeedingStreetCounts
            behaviourData[5].value = model.data.ExcessiveIdlingCounts
            behaviourData[6].value = model.data.OverRevvingCounts
        }

        commit('setBehaviourSummary', behaviourData);
        commit('setBehaviourDetails', behaviourDetailsData);
    },
    async getFleetAlertPriority({ commit, state }, model) {
        let fleetAlertPriorityCategoryData = state.fleetAlertPriorityCategory
        let fleetAlertPriorityCategoryListData = state.fleetAlertPriorityCategoryList
        let fleetAlertPriorityAlertData = state.fleetAlertPriorityAlert
        let fleetAlertPriorityAlertListData = state.fleetAlertPriorityAlertList
        let fleetAlertPriorityActionedData = state.fleetAlertPriorityActioned

        if (model.type == "initial") {
            await snapshotService.getInitialSnapshotData(model.data).then(results => {
                if (results.AlertCategoryCount) {
                    fleetAlertPriorityCategoryData = results.AlertCategoryCount
                }
                if (results.AssetCategoryList) {
                    fleetAlertPriorityCategoryListData = results.AssetCategoryList
                }
                if (results.AlertPriorityCount) {
                    fleetAlertPriorityAlertData = results.AlertPriorityCount
                }
                if (results.AssetPriorityList) {
                    fleetAlertPriorityAlertListData = results.AssetPriorityList
                }
                if (results.FleetAlertActionedCount) {
                    fleetAlertPriorityActionedData = results.FleetAlertActionedCount
                }
            })
        } else if (model.type == "signalR") {
            if (model.data.AlertCategoryCount) {
                fleetAlertPriorityCategoryData = model.data.AlertCategoryCount
            }
            if (model.data.AssetCategoryList) {
                fleetAlertPriorityCategoryListData = model.data.AssetCategoryList
            }
            if (model.data.AlertPriorityCount) {
                fleetAlertPriorityAlertData = model.data.AlertPriorityCount
            }
            if (model.data.AssetPriorityList) {
                fleetAlertPriorityAlertListData = model.data.AssetPriorityList
            }
            if (model.data.FleetAlertActionedCount) {
                fleetAlertPriorityActionedData = model.data.FleetAlertActionedCount
            }
        }

        commit('setFleetAlertPriorityCategory', fleetAlertPriorityCategoryData);
        commit('setFleetAlertPriorityCategoryList', fleetAlertPriorityCategoryListData);
        commit('setFleetAlertPriorityAlert', fleetAlertPriorityAlertData);
        commit('setFleetAlertPriorityAlertList', fleetAlertPriorityAlertListData);
        commit('setFleetAlertPriorityActioned', fleetAlertPriorityActionedData);
    },
    async getHealthCheckNonReporting({ commit, state }, model) {
        let healthCheckNonReportingData = state.healthCheckNonReporting

        if (model.type == "initial") {
            await snapshotService.getInitialSnapshotData(model.data).then(results => {
                if (results.HealthCheckResultList) {
                    healthCheckNonReportingData = results.HealthCheckResultList
                }
            })
        } else if (model.type == "signalR") {
            healthCheckNonReportingData = model.data
        }

        commit('setHealthCheckNonReporting', healthCheckNonReportingData);
    },
    async getServiceLicense({ commit, state }, model) {
        let serviceLicenseData = state.serviceLicense

        if (model.type == "initial") {
            await snapshotService.getInitialSnapshotData(model.data).then(results => {
                serviceLicenseData = results
            })
        } else if (model.type == "signalR") {
            serviceLicenseData = model.data
        }

        commit('setServiceLicense', serviceLicenseData);
    },
    async getUtilisation({ commit, state }, model) {
        let utilisationData = state.utilisation

        if (model.type == "initial") {
            await snapshotService.getInitialSnapshotData(model.data).then(results => {
                utilisationData = results
            })
        } else if (model.type == "signalR") {
            utilisationData = model.data
        }

        commit('setUtilisation', utilisationData);
    },
    async getAssetInspection({ commit, state }, model) {
        let assetInspectionData = state.assetInspection

        if (model.type == "initial") {
            await snapshotService.getInitialSnapshotData(model.data).then(results => {
                assetInspectionData = results
            })
        } else if (model.type == "signalR") {
            assetInspectionData = model.data
        }

        commit('setAssetInspection', assetInspectionData);
    },

    async getFuel({ commit, state }, model) {
        let fuelData = state.getFuel

        if (model.type == "initial") {
            await snapshotService.getInitialSnapshotData(model.data).then(results => {
                fuelData = results
            })
        } else if (model.type == "signalR") {
            fuelData = model.data
        }

        commit('setFuel', fuelData);
    },

    clearAllData({ commit }) {
        commit('setClearAllData');
    }
}

const mutations = {
    setBehaviourSummary(state, behaviourData) {
        state.behaviour = behaviourData;
    },
    setBehaviourDetails(state, behaviourDetailsData) {
        state.behaviourDetails = behaviourDetailsData;
    },
    setFleetAlertPriorityCategory(state, fleetAlertPriorityCategoryData) {
        state.fleetAlertPriorityCategory = fleetAlertPriorityCategoryData
    },
    setFleetAlertPriorityCategoryList(state, fleetAlertPriorityCategoryListData) {
        state.fleetAlertPriorityCategoryList = fleetAlertPriorityCategoryListData
    },
    setFleetAlertPriorityAlert(state, fleetAlertPriorityAlertData) {
        state.fleetAlertPriorityAlert = fleetAlertPriorityAlertData
    },
    setFleetAlertPriorityAlertList(state, fleetAlertPriorityAlertListData) {
        state.fleetAlertPriorityAlertList = fleetAlertPriorityAlertListData
    },
    setFleetAlertPriorityActioned(state, fleetAlertPriorityActionedData) {
        state.fleetAlertPriorityActioned = fleetAlertPriorityActionedData
    },
    setFleetAlertPriorityDetails(state, fleetAlertPriorityDetailsData) {
        state.fleetAlertPriorityDetails = fleetAlertPriorityDetailsData
    },
    setHealthCheckNonReporting(state, healthCheckNonReportingData) {
        state.healthCheckNonReporting = healthCheckNonReportingData
    },
    setServiceLicense(state, serviceLicenseData) {
        state.serviceLicense = serviceLicenseData
    },
    setUtilisation(state, utilisationData) {
        state.utilisation = utilisationData
    },
    setAssetInspection(state, assetInspectionData) {
        state.assetInspection = assetInspectionData
    },
    setFuel(state, fuelData) {
        state.fuel = fuelData
    },
    setClearAllData(state) {
        state.behaviour = []
        state.behaviourDetails = []
        state.fleetAlertPriorityCategory = { }
        state.fleetAlertPriorityCategoryList = []
        state.fleetAlertPriorityAlert = { }
        state.fleetAlertPriorityAlertList = []
        state.fleetAlertPriorityActioned = { }
        state.healthCheckNonReporting = []
        state.serviceLicense = { }
        state.utilisation = { }
        state.assetInspection = { }
        state.fuel = { }
        state.unscheduledJobs = 6
        state.todaysJobs = [8, 15]
        state.jobsRemaining = [5, 9]
        state.jobsCompleted = [4, 9]
    }
}

export default {
    modules: {
        snapshotStore: {
            namespaced: true,
            state,
            getters,
            actions,
            mutations
        }
    }
};