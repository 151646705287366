<template>
  <div class="FuelWasteWidget ctrack-h-100" ref="FuelWaste">
    <div id="vueapp" class="vue-app ctrack-h-100">
      
      <!-- Fuel Waste Chart -->
      <div class="dExecutive-inner" :class="widgetColorClass">
        <div class="dExecutive-title"><h4><span class="dExe-title-icon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23"><path d="M489.488,440a11.474,11.474,0,1,1,.032,0Zm-4.223-19.94a.363.363,0,0,0-.234.382q.008,8.066,0,16.133a.359.359,0,0,0,.234.38,9.312,9.312,0,0,0,8.654-.1,9.44,9.44,0,0,0-4.289-17.789A9.672,9.672,0,0,0,485.266,420.059Zm4.884,14.482v-1.732h1.263v.266H490.5v.4h.861v.306h-.866v.483h.973v.279Zm2.168-.09a6.36,6.36,0,0,0,1.977-2.364l.9.477a5.783,5.783,0,0,1-2.751,1.922A.2.2,0,0,1,492.318,434.451Zm2.216-2.807c.1-.5.224-.974.3-1.456a5.589,5.589,0,0,0-.167-2.359.222.222,0,0,1,.137-.313c.509-.262,1.009-.539,1.512-.809.054-.029.112-.05.2-.087a6.676,6.676,0,0,1-.96,5.524Zm-4.514-1.413q-.894-.452-1.787-.908a.263.263,0,0,0-.32.012.9.9,0,0,1-1.141-.09.917.917,0,0,1,.889-1.547.888.888,0,0,1,.65.94c-.013.156.058.209.165.273q1.955,1.17,3.905,2.346a1.747,1.747,0,0,1,.2.158l-.069.074Zm2.047-5.938,1.3-1.684a6.44,6.44,0,0,1,2.969,3.539l-1.872,1.069A6.293,6.293,0,0,0,492.066,424.292Zm-1.861-.1v-1.737h1.165c.005.081.012.157.02.266h-.858v.434h.726c.006.1.012.179.018.289h-.747v.748Z" transform="translate(-477.999 -417.001)"/></svg></span> 
        {{widgetTitle?widgetTitle:'Fuel Waste'}}</h4></div>
        <div class="dExecutive-content">
          <div class="dExe-chart-row dExe-chart-fullwidth">
            <div class="dExe-chart-col1">
              <h3 :class="loaderHide?'':'ctrack-chart-hide-loader'">
                <span class="dExe-value-icon" :class="widgetIcon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="22" height="11" viewBox="0 0 22 11"><path d="M107.008,284.158l11.223-11.223,11.223,11.223Z" transform="translate(-107.008 -272.935)"/></svg></span>{{widgetValue}}
              </h3>
            </div>
            <div class="dExe-chart-col2">
              <!-- <div class="dExe-chart-main"><img src="../../../assets/images/dashboard/md-fuel-graph.svg" /></div> -->
              <div class="ctrack-md-loader" :class="loaderHide?'ctrack-chart-hide-loader':''"></div>
              <div class="dExe-chart-main">
                <highcharts :options="chartOptions" :class="loaderHide?'':'ctrack-chart-hide-loader'"></highcharts>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Fuel Waste Chart -->

    </div>    
  </div>
</template>

<script>
export default {
  name: 'fuelwastechart',
  components: {},
  data: function() {        
        return {      
            loaderHide:false,
            widgetTitle:"",
            widgetValue:"",
            widgetIcon:"",
            widgetColorClass:"",       
            chartOptions:{              
                    chart: {
                      type: 'bar',
                      height:150
                      },
                  title: {
                      text: ''
                  },
                  credits: {enabled: false},
                  colorArray:['#CCCCCC','#fa4b4b'],                    
                 xAxis: {                    
                      categories: [],                                       
                      labels: {                      
                    formatter () {
                      return `<span style="color: ${this.chart.userOptions.colorArray[this.pos]}">${this.value}</span>`
                    },
                     style:{
                      fontSize:'14px',
                      fontFamily:"Roboto,Medium",
                       opacity: 1,
                       width:150,
                       },                                
                     } 
                  },
                yAxis: {       
                  labels: {
                     enabled: false
                 },
                 gridLineWidth: 0,
                 title: {
                        enabled:false
                      } 
                     },
                tooltip: { enabled: false },
                legend: {
                      enabled: false
                  },
              plotOptions: {
                     series: {
                      stacking: 'normal',                                      
                     },
                  legend: {
                   enabled: false
                 }
               },
              series: [{                     
                      borderRadiusTopLeft: '25%',
                      borderRadiusTopRight: '25%'
                      }]
                   }
                 }
                }, 
    
          created(){
      this.getFuelWasteChartData();     
        },  
  methods: {    
    getFuelWasteChartData() {
      setTimeout(() => {
        let self = this;
        self.loaderHide=false;        
        const returnResult = { 
            id:"FuelWasteChart",
            data:{
              valueData :  {
                  widgetTitle: "Fuel Waste",
                  value: "25",
                  type:"%",
                  icon: "down",
                  color: "red"
              },
              chartData : {                        
                  categories: ['Last Week: 50 ', 'This Week: 55'],    
                  series: [{                 
                    data: [
                      {
                        name: "Last Week",
                        y: 50,                                         
                        color:'#CCCCCC',                                
                      },
                      {
                      name: "This Week",
                      y: 55,                                        
                      color:'#fa4b4b'
                      }
                    ] 
                  }],
              }
            }
        };                
        self.widgetTitle = returnResult.data.valueData.widgetTitle;
        self.widgetValue = returnResult.data.valueData.value + returnResult.data.valueData.type;
        self.widgetIcon =  (returnResult.data.valueData.icon == "down") ? "md-chart-arrow-down" : (returnResult.data.valueData.icon == "up") ? "md-chart-arrow-up" : "";
        self.widgetColorClass = (returnResult.data.valueData.color == "green") ? "success-color" : (returnResult.data.valueData.color == "red") ? "error-color" : "";
        self.chartOptions.series = returnResult.data.chartData.series 
        self.chartOptions.xAxis.categories = returnResult.data.chartData.categories;  
        
        let lastWeekColor = (returnResult.data.valueData.color == "green") ? "#76CD24" : (returnResult.data.valueData.color == "red") ? "#FA4B4B" : "#000000";
        self.chartOptions.series[0].data[1].color = lastWeekColor;                          
        self.chartOptions.colorArray[1] = lastWeekColor;  
        self.loaderHide = true; 
        /* const returnResult = {   
            categories: ['Last Week: 50 ', 'This Week: 55'],  
             series: [{                  
                  data: [{
                         name: "Last Week",
                         y: 50,                                         
                         color:'#CCCCCC',                                
                        },
                       {
                        name: "This Week",
                        y: 55,                                        
                        color:'#fa4b4b'
                        }] 
                   } ],              
            };
          self.chartOptions.xAxis.categories = returnResult.categories;
          self.chartOptions.series = returnResult.series;
          self.chartTitle = returnResult.chartTitle;
          self.chartTitleDuration = "(" + returnResult.chartTitleDuration + ")";

          self.loaderHide=true; */ 
      }, 5000);
    }
  }
};

</script>
<style scoped>

/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');*{font-family:'Roboto',sans-serif;}
.ctrack-h-100{height:100%;}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.md-dashboard-content{padding-top:10px;color:#C5C5C5;}
[class*="md-chart-arrow"]{display:inline-block;vertical-align:middle;}
[class*="md-chart-arrow"] svg{display:block;}
.md-chart-arrow-down{transform:rotate(180deg);-webkit-transform:rotate(180deg);-moz-transform:rotate(180deg);-ms-transform:rotate(180deg);-o-transform:rotate(180deg);}
.dExecutive-inner{height:100%;width:100%;display:inline-block;background-color:#FFFFFF;border-top:6px solid #CDCDCD;border-radius:5px;padding:0 20px 20px;}
.dExecutive-title{display:inline-block;width:100%;padding:10px 0;}
.dExecutive-title h4{color:#333333;font-size:20px;font-weight:500;position:relative;padding-left:40px;display:inline-block;width:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
.dExecutive-title h4 .dExe-title-icon{position:absolute;left:0;top:50%;transform:translateY(-50%);}
.dExecutive-title h4 .dExe-title-icon svg{display:block;}
.dExecutive-content{display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;justify-content:space-between;-webkit-justify-content:space-between;height:calc(100% - 60px);}
.dExecutive-main div[class*="ctrack-col-"]{margin-bottom:20px;}
.dExe-chart-row{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:nowrap;flex-wrap:nowrap;-webkit-box-align:center;-ms-flex-align:center;align-items:center;justify-content:space-between;-webkit-justify-content:space-between;width:100%;}
.ctrack-align-end{-webkit-box-align:flex-end;-ms-flex-align:flex-end;align-items:flex-end;}
.dExe-chart-col1 h3{font-size:50px;display:inline-block;width:100%;vertical-align:middle;position:relative;font-weight:700;white-space:nowrap}
.dExe-chart-col1 h3 span{display:inline-block;vertical-align:middle;padding-right:5px;}
.dExe-chart-col1 h3 span.md-chart-arrow-down{padding-right:0;padding-left:5px;}
.dExe-chart-col1 h3 svg{display:block;}
.dExe-chart-fullwidth{-ms-flex-wrap:wrap;flex-wrap:wrap;}
.dExe-chart-fullwidth > div{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
.dExe-chart-fullwidth .dExe-chart-col1 h3{text-align:center;}
.dExe-chart-main{padding-left:5px;min-width: 250px;width: 100%}
.dExe-chart-fullwidth .dExe-chart-main{padding-left:0;}
.dExe-chart-lbl{display:inline-block;width:100%;margin-top:5px;}
.dExe-chart-lbl span{display:inline-block;vertical-align:middle;padding-right:8px;}
.dExe-chart-lbl span svg{display:block}
.dExe-chart-bottom{display:table;width:100%;}
.dExe-chart-bottom > div{display:table-cell;}
.dExe-chart-bottom-inner{text-align:center;display:inline-block;font-size:20px;font-weight:500;margin-top:15px;}
.dExe-chart-bottom-inner small{font-size:14px;margin-bottom:-5px;}
.dExe-chart-bottom-inner small,.dExe-chart-bottom-inner label{display:block;}
.dExe-chart-bottom-col2{text-align:right;}
.error-color{border-color:#FA4B4B;}
.success-color{border-color:#76CD24;}
.error-color .dExe-chart-col1 h3,.error-color .md-chart-color{color:#FA4B4B;}
.success-color .dExe-chart-col1 h3,.success-color .md-chart-color{color:#76CD24;}

/* Responsive CSS */
@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1399px){
    .dExe-chart-col1 h3{font-size:40px;}
}
@media only screen and (max-width:1299px){
    .dExe-chart-col1 h3{font-size:35px;}
}
@media only screen and (max-width:1199px){}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){}
@media only screen and (max-width:767px){}
@media only screen and (max-width:575px){
  .dExecutive-inner{padding:0 15px 15px}
  .dExe-chart-row{-ms-flex-wrap:wrap;flex-wrap:wrap;}
  .dExe-chart-row > div{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;text-align:center;}
  .dExe-chart-col1{margin-bottom:5px;}
  .dExe-chart-labels{display:table;width:100%;padding:5px 0 10px;}
  .dExe-chart-labels > div{display:table-cell;width:auto;}
  .dExe-chart-main{padding-left:0;}
}

</style>
