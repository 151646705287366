<template>
    <v-list-item>
        <v-btn icon @click.stop="openMsg" v-tooltip="'Message Vehicle Driver'">
            <v-icon>message</v-icon>
        </v-btn>
        <v-btn icon @click.stop="showReplay" v-tooltip="'View Replay'" v-if="$checkRBACAccess('replayRead')">
            <svg width="20" height="24" viewBox="0 0 17 21" fill="#666666" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 6.37521C4.54392 6.37521 5.46331 5.84819 6.00068 5.04866L11.75 5.04023C12.3167 5.04023 12.8167 5.25507 13.25 5.68474C13.6833 6.11441 13.9 6.61019 13.9 7.17207C13.9 7.73395 13.6833 8.22972 13.25 8.6594C12.8167 9.08907 12.3167 9.30391 11.75 9.30391H4.3C3.13333 9.30391 2.125 9.71705 1.275 10.5433C0.425 11.3696 0 12.3612 0 13.518C0 14.6748 0.425 15.6664 1.275 16.4927C2.125 17.319 3.13333 17.7321 4.3 17.7321H11.75L12.5 17.7343V20.2128L16.5 16.3119L12.5 12.3056V15.6402L11.75 15.6498H4.3C3.73333 15.6498 3.23333 15.435 2.8 15.0053C2.36667 14.5757 2.15 14.0799 2.15 13.518C2.15 12.9561 2.36667 12.4604 2.8 12.0307C3.23333 11.601 3.73333 11.3862 4.3 11.3862H11.75C12.9167 11.3862 13.9167 10.973 14.75 10.1467C15.5833 9.32043 16 8.32888 16 7.17207C16 6.01525 15.5833 5.0237 14.75 4.19741C13.9167 3.37111 12.9167 2.95797 11.75 2.95797L6.45992 2.92411C6.22534 1.51764 4.98943 0.444824 3.5 0.444824C1.84315 0.444824 0.5 1.77239 0.5 3.41002C0.5 5.04765 1.84315 6.37521 3.5 6.37521Z"></path></svg>
        </v-btn>
        <v-btn icon @click.stop="editVehicle" v-tooltip="'Edit Vehicle'">
            <v-icon>edit</v-icon>
        </v-btn>
        <v-btn icon @click.stop="showMap" v-tooltip="'Show on Livemap'">
            <v-icon>my_location</v-icon>
        </v-btn>
    </v-list-item>
</template>

<script>
    export default {
        props: {
            value: Object
        },
        methods: {
            showMap() {
                this.$emit("toggleSearchItems")
                if (!this.$map.mapInitialized()) {
                    const data = {
                        name: 'livemapRead',
                        params:
                        {
                            selected: {
                                vehicles: [this.value.id]
                            },
                        }
                    }
                    this.$router.push(data);
                }
                else {
                    this.$livemapEventHub.$emit("display-vehicle", this.value.id);
                }
                
            },
            showReplay() {

                const data = {
                    name: 'replaydetails',
                    params:
                    {
                        //date: format(parse(dataItem.alertGenerateDateTime, this.$getUserDateTimeFormat(), new Date()), "yyyy-MM-dd"),
                        selected: {
                            vehicles: [this.value.id]
                        },
                    }
                }
                this.$router.push(data);
            },
            editVehicle() {
                this.$emit("toggleSearchItems")
                this.$router.push({ name: 'vehiclesUpdate', params: { id: this.value.id } })
            },
            openMsg() {
                this.$emit("toggleSearchItems")
                this.$eventBus.$emit("messageWindow", this.value.driverIdData);
                this.$dashboardEventHub.$emit("messageDrawer");
            }
        }
    }
</script>