<template>
  <div class="driveTimeWidget ctrack-h-100" ref="driveTime">
    <div id="vueapp" class="vue-app ctrack-h-100">
        
      <!-- Drive Time Widget -->
        <div class="dBehaviour-inner" :class="compareWidgetColorClass">        
          <div class="dBehaviour-box-col">
            <div class="dBehaviour-box-icon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"><path d="M1015,180a14,14,0,1,0,14,14A14,14,0,0,0,1015,180Zm6.052,16.663h-5.935c-.02,0-.039,0-.059-.006s-.038.006-.059.006a.968.968,0,0,1-.968-.968V185.81a.968.968,0,0,1,1.937,0v8.916h5.084a.968.968,0,0,1,0,1.937Z" transform="translate(-1001 -180)"/></svg></div>
            <div class="dBehaviour-box-content">
              <h3>
                <span class="dBehaviour-box-value-icon" :class="compareWidgetIcon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10"><path d="M638.392,193H628.62a.626.626,0,0,1-.527-.962c.213-.383,4.509-8.038,4.876-8.691a.6.6,0,0,1,1.059,0c.269.47,4.587,8.154,4.887,8.711A.623.623,0,0,1,638.392,193Z" transform="translate(-628 -183.028)" /></svg></span>
                {{compareWidgetValue}}
              </h3>
              <p class="ctrack-text-truncate">{{widgetTitle}}</p>
              <p class="md-uw-value">{{widgetValue}}</p>
            </div>
          </div>        
        </div>
        <!-- Drive Time Widget -->     

    </div>    
  </div>
</template>

<script>
import dashboardService from "../../../service/dashboard.service";
import { mutations, getters } from "../shared/observeDashboardFilter";
export default {
  name: 'driveTimeWidget',
  components: {},
  data: function() {
    return {
      widgetTitle:"-",
      widgetValue:"0",
      compareWidgetValue:"0",
      compareWidgetIcon:"",
      compareWidgetColorClass:"" 
    }
  }, 
  props: {
    widgetData: Object
  },
  created(){
    this.getDriveTimeWidgetData();        
  },   
  methods: {
    getDriveTimeWidgetData()
    { 
      let self = this;   
      dashboardService.getWidgetData( this.widgetData.api, getters.getFilterParams()).then(returnResult => {
      
      self.widgetTitle = returnResult.hwTitle;
      self.widgetValue = returnResult.value + " " + returnResult.type;
      self.compareWidgetValue = returnResult.compareValue + returnResult.compareType;
      self.compareWidgetIcon =  (returnResult.compareIcon == "down") ? "md-chart-arrow-down" : (returnResult.compareIcon == "up") ? "md-chart-arrow-up" : "";
      self.compareWidgetColorClass = (returnResult.compareColor == "green") ? "success-color" : (returnResult.compareColor == "red") ? "error-color" : "";
    });
  }
  }
};

</script>
<style scoped>
/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
*{font-family: 'Roboto', sans-serif;}
.ctrack-h-100{height: 100%;}
.error-color .dBehaviour-box-col h3{color:#FA4B4B;}
.success-color .dBehaviour-box-col h3{color:#76CD24;}
[class*="md-chart-arrow"]{display:inline-block;vertical-align:middle;}
[class*="md-chart-arrow"] svg{display:block;}
.md-chart-arrow-down{transform:rotate(180deg); -webkit-transform:rotate(180deg); -moz-transform:rotate(180deg); -ms-transform:rotate(180deg); -o-transform:rotate(180deg);}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}

.dBehaviour-inner{display:inline-block;width:100%;height:100%;background-color:#FFF;padding:13px 20px;border-radius: 5px; box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, .05); -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, .05);}
.dBehaviour-box-row{display:inline-block;width:100%;padding-left:40px;position:relative;}
.dBehaviour-box-col{display:inline-block;vertical-align: middle;position:relative;width:100%;text-align:right;padding-left:40px;}
.dBehaviour-box-icon{position:absolute;color:#656565; left:0;top:50%;transform:translateY(-50%); -webkit-transform:translateY(-50%); -moz-transform:translateY(-50%); -ms-transform:translateY(-50%); -o-transform:translateY(-50%);}
.dBehaviour-box-content{display:block;width:100%;padding-left:5px;}
.dBehaviour-box-content h3{font-size:20px;font-weight:500;}
.dBehaviour-box-value-icon{margin-right:8px;}
.dBehaviour-box-content p{font-size:15px;color:#333333;font-weight:500;}
.dBehaviour-box-content p.md-uw-value{font-size:18px;color: #999999;font-weight: 400;}


/* Responsive CSS */
@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1399px){}
@media only screen and (max-width:1299px){}
@media only screen and (max-width:1199px){}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){}
@media only screen and (max-width:767px){}
@media only screen and (max-width:575px){}

</style>