export { default as CommonService } from './common.service';
export { default as ConfigurationService } from './config.service';
export { default as ContactService } from './contact.service';
export { default as DashboardService } from './dashboard.service';
export { default as DriverService } from './drivers.service';
export { default as GroupManagementService } from './groupmanagement.service';
export { default as LocationsService } from './locations.service';
export { default as LoginService } from './login.service';
export { default as MessageService } from './message.service';
export { default as ReplayService } from './replay.service';
export { default as ReportsService } from './reports.service';
export { default as RuleService } from './rule.service';
export { default as UserService } from './user.service';
export { default as VehiclesService } from './vehicles.service';
export { default as VideoService } from './video.service';
export { default as BulkImportService } from "./bulkimport.service";
export { default as VehicleMaintenanceService } from "./vehiclemaintenance.service";
