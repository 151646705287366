<template>
  <div class="MileageWidget ctrack-h-100" ref="Mileage">
    <div id="vueapp" class="vue-app ctrack-h-100">

      <!-- Mileage Widget -->
      <div class="dBehaviour-inner" :class="widgetColorClass">        
        <div class="dBehaviour-box-col">
          <div class="dBehaviour-box-icon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="33" height="31" viewBox="0 0 33 31"><path d="M557,195.782l-4.68,2.3c-0.49-1.182-.978-2.358-1.471-3.545l2.129-1.059a13.947,13.947,0,0,0-4.4-5.092,13.121,13.121,0,0,0-6.928-2.338v3.315H538.58v-3.2a12.835,12.835,0,0,0-9.183,5.815l3.152,2.15-1.527,2.767-3.016-2.089a18.929,18.929,0,0,0-.324,11.7l0.922-.424,0.915-.419,1.859,2.982-4.574,2.963c-0.272-.507-0.55-0.991-0.8-1.491a19.079,19.079,0,0,1-1.877-6.453c-0.045-.416-0.068-0.834-0.1-1.251,0-.062-0.018-0.124-0.027-0.186v-1.745c0.009-.062.021-0.124,0.027-0.186,0.081-.821.128-1.647,0.246-2.462a18.8,18.8,0,0,1,2.179-6.532,15.6,15.6,0,0,1,7.61-6.947,17.05,17.05,0,0,1,9.44-1.151,15.441,15.441,0,0,1,6.651,2.676,17.579,17.579,0,0,1,6.544,8.923c0.1,0.3.2,0.6,0.3,0.9v0.07ZM545.464,191.8l1.527,1.582-1.121,1.669q-1.5,2.239-3.011,4.477a0.282,0.282,0,0,0-.026.336,3.186,3.186,0,0,1-2.167,4.535,3.083,3.083,0,0,1-3.59-2.684,3.115,3.115,0,0,1,3.418-3.574,1.452,1.452,0,0,1,.346.066,0.269,0.269,0,0,0,.376-0.149q2.07-3.065,4.151-6.119C545.4,191.9,545.432,191.849,545.464,191.8Zm10.307,15.051h-3.062v7.136h-3.073V199.72C551.681,202.1,553.708,204.457,555.771,206.855Zm-20.239,3.207h9.188v3.159h-9.188v-3.159Z" transform="translate(-524 -183)"/></svg></div>
          <div class="dBehaviour-box-content">
            <h3>
              <span class="dBehaviour-box-value-icon" :class="widgetIcon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10"><path d="M638.392,193H628.62a.626.626,0,0,1-.527-.962c.213-.383,4.509-8.038,4.876-8.691a.6.6,0,0,1,1.059,0c.269.47,4.587,8.154,4.887,8.711A.623.623,0,0,1,638.392,193Z" transform="translate(-628 -183.028)" /></svg></span>{{widgetValue}}
            </h3>
            <p class="ctrack-text-truncate">{{widgetTitle}}</p>
          </div>
        </div>        
      </div>
      <!-- Mileage Widget -->

    </div>    
  </div>
</template>

<script>
export default {
  name: 'mileagewidget',
  components: {},
  data: function() {
        return {}
    },  
  created(){
        this.getMileageWidgetData();        
  },
  methods: {
        getMileageWidgetData(){
            let self = this;
            const returnResult = {
                title: "Mileage",
                value: "14",
                type:"%",
                icon: "up",
                color: "red"
            };
            
            self.widgetTitle = returnResult.title;
            self.widgetValue = returnResult.value + returnResult.type;
            self.widgetIcon =  (returnResult.icon == "down") ? "md-chart-arrow-down" : "md-chart-arrow-up";
            self.widgetColorClass = (returnResult.color == "green") ? "success-color" : "error-color";
        }
    }
  };


</script>
<style scoped>

/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
*{font-family: 'Roboto', sans-serif;}
.ctrack-h-100{height: 100%;}
.error-color .dBehaviour-box-col h3{color:#FA4B4B;}
.success-color .dBehaviour-box-col h3{color:#76CD24;}
[class*="md-chart-arrow"]{display:inline-block;vertical-align:middle;}
[class*="md-chart-arrow"] svg{display:block;}
.md-chart-arrow-down{transform:rotate(180deg); -webkit-transform:rotate(180deg); -moz-transform:rotate(180deg); -ms-transform:rotate(180deg); -o-transform:rotate(180deg);}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}

.dBehaviour-inner{display:inline-block;width:100%;height:100%;background-color:#FFF;padding:13px 20px;border-radius: 5px; box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, .05); -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, .05);}
.dBehaviour-box-row{display:inline-block;width:100%;padding-left:40px;position:relative;}
.dBehaviour-box-col{display:inline-block;vertical-align: middle;position:relative;width:100%;text-align:right;padding-left:40px;}
.dBehaviour-box-icon{position:absolute;color:#656565; left:0;top:50%;transform:translateY(-50%); -webkit-transform:translateY(-50%); -moz-transform:translateY(-50%); -ms-transform:translateY(-50%); -o-transform:translateY(-50%);}
.dBehaviour-box-content{display:block;width:100%;padding-left:5px;}
.dBehaviour-box-content h3{font-size:20px;font-weight:500;}
.dBehaviour-box-value-icon{margin-right:8px;}
.dBehaviour-box-content p{font-size:15px;color:#333333;font-weight:500;}


/* Responsive CSS */
@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1399px){}
@media only screen and (max-width:1299px){}
@media only screen and (max-width:1199px){}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){}
@media only screen and (max-width:767px){}
@media only screen and (max-width:575px){}

</style>
