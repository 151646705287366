<template>
    <v-list-item>
        <v-btn icon @click.stop="openMsg" v-tooltip="'Message Vehicle Driver'">
            <v-icon>message</v-icon>
        </v-btn>
        <v-btn icon @click.stop="editVehicle" v-tooltip="'Edit Vehicle'">
            <v-icon>edit</v-icon>
        </v-btn>
        <v-btn icon @click.stop="showMap" v-tooltip="'Show on Livemap'">
            <v-icon>my_location</v-icon>
        </v-btn>
    </v-list-item>
</template>

<script>
    export default {
        props: {
            value: Object
        },
        methods: {
            showMap() {
                this.$emit("toggleSearchItems")
                if (!this.$map.mapInitialized()) {
                    this.$router.push('/dashboard/livemap').then((results) => {
                        this.$livemapEventHub.$emit("display-vehicle", this.value.id);
                    });
                }
                else {
                    this.$livemapEventHub.$emit("display-vehicle", this.value.id);
                }

            },
            editVehicle() {
                this.$emit("toggleSearchItems")
                this.$router.push({ name: 'vehiclesUpdate', params: { id: this.value.id } })
            },
            openMsg() {
                this.$emit("toggleSearchItems")
                this.$eventBus.$emit("messageWindow", this.value.driverIdData);
                this.$dashboardEventHub.$emit("messageDrawer");
            }
        }
    }
</script>