<template>
  <div>
    <md-dialog :md-active="showDialog">
      <md-dialog-title>{{$t("Settings.exportToEmail")}}</md-dialog-title>
      <md-content>
        <div class="formLabel">
          {{$t("Settings.subject")}}
          <span class="mandatoryStar">*</span>
        </div>
        <div>
          <input
            type="text"
            class="inputDesign"
            name="subject"
            v-model="email.subject"
            v-validate="'required'"
          />
          <div class="validation-error" v-if="errors.has('subject')">{{ errors.first('subject') }}</div>
        </div>
        <div class="formLabel">
          {{$t("Settings.recipients")}}
          <span class="mandatoryStar">*</span>
        </div>
        <div>
          <md-chips
            id="recipients"
            name="recipients"
            class="inputDesign"
            ref="recipients"
            v-model="email.to"
            v-validate="'required|emailList'"
          ></md-chips>
          <div
            class="validation-error"
            v-if="errors.has('recipients')"
          >{{ errors.first('recipients') }}</div>
        </div>
        <div class="formLabel">
          {{$t("Settings.message")}}
          <span class="mandatoryStar">*</span>
        </div>
        <div>
          <textarea
            class="inputDesign"
            name="message"
            v-model="email.message"
            v-validate="'required'"
          ></textarea>
          <div class="validation-error" v-if="errors.has('message')">{{ errors.first('message') }}</div>
        </div>
      </md-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="closeDialog()"> {{$t("Settings.close")}} </md-button>
        <md-button class="md-primary" @click="validate()">{{$t("ForgotPassword.sendEmail")}}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>
<script>
export default {
  name: "ExportEmailDialog",
  props: {
    value: Boolean,
    sendEmail: Function
  },
  data: () => ({
    showDialog: false,
    email: {
      to: [],
      subject: "",
      message: ""
    }
  }),
  watch: {
    value(value) {
      this.showDialog = value;
      this.$nextTick(() => {
        if (this.$refs.recipients === undefined) {
          return;
        }
        var input = $(this.$refs.recipients.$el).find("input");
        input.unbind("keypress");
        input.keypress(e => {
          if (e.charCode === 32) {
            this.updateRecipients();
          }
        });
      });
    }
  },
  methods: {
    closeDialog() {
      this.email = {
        to: [],
        subject: "",
        message: ""
      };
      this.$emit("input", false);
    },
    updateRecipients() {
      var result = $(this.$refs.recipients.$el)
        .find("input")
        .val();
      if ($.trim(result) !== "") {
        this.email.to.push(result);
        this.$nextTick(function() {
          $(this.$refs.recipients.$el)
            .find("input")
            .val("");
        });
      }
    },
    validate() {
      this.updateRecipients();
      this.$validator.validate().then(valid => {
        if (valid) {     
          this.email.to=this.email.to.join(';') ;
          this.sendEmail(this.email);
            this.$toast.show(this.$t('ContactLabels.emailSent'));
          this.closeDialog();
        } else {
            this.$toast.show(this.$t('ContactLabels.emailFailed'));
        }
      });
    }
  }
};
</script>
<style scoped>
.md-dialog {
  max-width: 768px;
  width: 500px;
}

.md-content {
  padding: 0px 24px;
}

.formLabel {
  padding-top: 10px;
  padding-bottom: 10px;
}

textarea.inputDesign {
  height: 100px !important;
}
.md-chips.md-field{height: auto;}
</style>