<template>
    <v-list-item style="color: darkOrange !important;"> 
        <v-icon style="color: darkOrange !important; margin-right: 6px;">
            info
        </v-icon>
        {{$t('Dashboard.search.itemOutsideOfGroup')}}
    </v-list-item>
</template>
<script>
    export default {

    }
</script>