<template>
    <v-list-item>
        <v-btn icon @click.stop="editContact" v-tooltip="'Edit contact'">
            <v-icon>edit</v-icon>
        </v-btn>
    </v-list-item>
</template>

<script>
    export default {
        props: {
            value: Object
        },
        methods: {
            editContact() {
                this.$emit("toggleSearchItems")
                this.$router.push({ name: 'contactUpdate', params: { id: contact.id } })
            }
        }
    }
</script>