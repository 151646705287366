<script>
    import { HorizontalBar } from 'vue-chartjs'

    export default {
        extends: HorizontalBar,
        props: {
            chartData: {
                type: Object,
                default: null
            },
            options: {
                type: Object,
                default: null
            }
        },
        mounted() {
            this.renderChart(this.chartData, this.options)
        },
        watch: {
            chartData: {
                deep: true,
                handler(chartData) {
                    this.chartData = chartData
                    this.renderChart(this.chartData, this.options)
                }
            }
        }
    }
</script>