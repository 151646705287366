import {
    exportElement
} from './export-element';

export function getGridPdf(grid, pdfExportOptions, data, callback) {

    if (pdfExportOptions === void 0) {
        pdfExportOptions = {};
    }

    renderGrid();
    var gridOuterWrapper;
    var gridInnerWrapper;
    var finalContainer;
    var gridInstance;

    function renderGrid() {
        gridOuterWrapper = document.createElement('div');
        gridOuterWrapper.setAttribute('style', 'position:absolute; left: -5000px; top: 0px;');
        gridInnerWrapper = document.createElement('div');
        gridOuterWrapper.appendChild(gridInnerWrapper);
        document.body.appendChild(gridOuterWrapper);
        var customDataProps = data && {
            dataItems: data,
            total: data.length,
            pageSize: data.length,
            skip: 0
        };
        var gridConstructor = Object.getPrototypeOf(grid).constructor;
        gridInstance = new gridConstructor({
            propsData: Object.assign({}, grid.$props, customDataProps),
            store: grid.$store,
            mounted: function mounted() {
                if (gridInstance.$el.style) {
                    gridInstance.$el.style.width = '1000px';
                }
                prepareFinalElementForExport();
            }
        });
        gridInstance.$mount(gridInnerWrapper);

        kendo.drawing.drawDOM(finalContainer).then(function (group) {
            group.options.set("pdf", pdfExportOptions);
            kendo.drawing.pdf.toBlob(group, function (blob) {
                gridInstance.$destroy();
                document.body.removeChild(gridOuterWrapper);
                document.body.removeChild(finalContainer);
                gridOuterWrapper = finalContainer = undefined;
                callback(blob);
            });
        });       

    }

    function prepareFinalElementForExport() {
        finalContainer = document.createElement('div');
        finalContainer.className = 'k-grid-pdf-export-element';
        var result = exportElement(gridInstance.$el);
        finalContainer.appendChild(result);
        document.body.appendChild(finalContainer);
        return result;
    }
}