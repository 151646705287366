import Vue from 'vue'
import { defineStore } from 'pinia'
//$recentActivity
export const useRecentActivityStore = defineStore('recent', {
    state: () => ({
        activities: localStorage.getItem('recent') ? JSON.parse(localStorage.getItem('recent')) : [],     
    }),
    actions: {
        getRecentActivities() {
            return this.activities.filter(i =>
                i?.tenantId == (Vue.prototype.$session.get('tenantDetails')?.tenantId || '') &&
                i?.userId == (Vue.prototype.$session.get('tenantDetails')?.userId || '')
            ).sort((a, b) => { return new Date(b.asAt) - new Date(a.asAt) });
        },
        logRecentActivity(page, id, displayName, action) {
            //Strip out entries for other tenants and users
            var activitiesNotThisUserOrTenant = this.activities.filter(i =>
                i?.tenantId != (Vue.prototype.$session.get('tenantDetails')?.tenantId || '') ||
                i?.userId != (Vue.prototype.$session.get('tenantDetails')?.userId || '')
            );
            //isolate entries for this user only
            var activitiesThisUserThisTenant = this.activities.filter(i =>
                i?.tenantId == (Vue.prototype.$session.get('tenantDetails')?.tenantId || '') &&
                i?.userId == (Vue.prototype.$session.get('tenantDetails')?.userId || '') &&
                !(i?.page == page && i?.id == id)
            );
            //add entry
            activitiesThisUserThisTenant.push({
                page: page,
                id: id,
                displayName: displayName,
                action: action,
                asAt: new Date().toISOString(),
                userId: Vue.prototype.$session.get('tenantDetails')?.userId || '',
                tenantId: Vue.prototype.$session.get('tenantDetails')?.tenantId || '',
            }); 
            //trim list
            activitiesThisUserThisTenant = activitiesThisUserThisTenant.slice(-20);
            //stitch together
            this.activities = [...activitiesThisUserThisTenant, ...activitiesNotThisUserOrTenant];
            //update
            localStorage.setItem("recent", JSON.stringify(this.activities))
        },
        clearMyActivity() {
            var activitiesNotThisUserOrTenant = this.activities.filter(i =>
                i?.tenantId != (Vue.prototype.$session.get('tenantDetails')?.tenantId || '') ||
                i?.userId != (Vue.prototype.$session.get('tenantDetails')?.userId || '')
            );
            this.activities = [...activitiesNotThisUserOrTenant];
            localStorage.setItem("recent", JSON.stringify(this.activities));
        },
    }
})