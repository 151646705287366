<template>
  <div class="HaltsWidget ctrack-h-100" ref="Halts">
    <div id="vueapp" class="vue-app ctrack-h-100">

      <!-- Halts Chart -->
      <div class="dExecutive-inner success-color">
        <div class="dExecutive-title"><h4><span class="dExe-title-icon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23"><path d="M852.827,675c-.154-.019-.308-.04-.463-.057a11.585,11.585,0,0,1-10.183-9.464c-.08-.431-.122-.869-.18-1.305v-1.348a1.591,1.591,0,0,0,.038-.193,11.021,11.021,0,0,1,1.887-5.463,11.385,11.385,0,0,1,7.6-4.992c.417-.077.84-.12,1.261-.178h1.437a.886.886,0,0,0,.149.037,10.3,10.3,0,0,1,3.47.826,11.434,11.434,0,0,1,6.981,8.66c.086.429.121.868.18,1.3v1.348a1.082,1.082,0,0,0-.038.173,10.778,10.778,0,0,1-1.312,4.505,11.435,11.435,0,0,1-8.192,5.966c-.438.085-.886.122-1.329.182Zm.292-21.047a9.59,9.59,0,1,0,.36-.006C853.358,653.947,853.239,653.949,853.118,653.953ZM850.327,667.5a.735.735,0,0,1-.823-.833q0-3.164,0-6.33a.735.735,0,0,1,.828-.828h6.33a.735.735,0,0,1,.833.822q0,1.594,0,3.188t0,3.164a.731.731,0,0,1-.816.817Z" transform="translate(-842 -652)" /></svg></span> Halts</h4></div>
        <div class="dExecutive-content">
          <div class="dExe-chart-row dExe-chart-fullwidth">
            <div class="dExe-chart-col1">
              <h3>
                <span class="dExe-value-icon md-chart-arrow-down"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="22" height="11" viewBox="0 0 22 11"><path d="M107.008,284.158l11.223-11.223,11.223,11.223Z" transform="translate(-107.008 -272.935)"/></svg></span>10%
              </h3>
            </div>
            <div class="dExe-chart-col2">
              <!-- <div class="dExe-chart-main"><img src="../../../assets/images/dashboard/md-halts-graph.svg" /></div> -->
              <div class="ctrack-md-loader" :class="loaderHide?'ctrack-chart-hide-loader':''"></div>
              <div class="dExe-chart-main">
                <highcharts :options="chartOptions" :class="loaderHide?'':'ctrack-chart-hide-loader'"></highcharts>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Halts Chart -->
       

    </div>    
  </div>
</template>

<script>
export default {
  name: 'haltschart',
  components: {},
 data: function() {
        const color = {
          'Last Week: 32h': '#CCCCCC',
          'This Week: 40h': '#76CD24',         
				}
        return {  
          loaderHide:false,         
            chartOptions:{              
                    chart: {
                      type: 'bar',
                       height:110,
                      width:330
                      },
                  title: {
                      text: ''
                  },
                  credits: {enabled: false},       
                  
                 xAxis: {                    
                      categories: [],
                      labels: {
                    formatter () {
                      return `<span style="color: ${color[this.value]}">${this.value}</span>`
                    },
                     style:{
                      fontSize:'14px',
                      fontFamily:"Roboto,Medium",
                       opacity: 1
                       },  
                        // x:-50,                     
                     }  
                  },
                yAxis: {       
                  labels: {
                     enabled: false                    
                 },
                 gridLineWidth: 0,
                 title: {
                        enabled:false
                      },
                       
                     },
                tooltip: { enabled: false },
                legend: {
                      enabled: false
                  },
              plotOptions: {                   
                     series: {
                      stacking: 'normal'
                     },
                  legend: {
                   enabled: false
                 }
               },
            series: [{                     
                      borderRadiusTopLeft: '25%',
                      borderRadiusTopRight: '25%'
                      }]
                   }
                 }
               },  
           created(){
      this.getHaltsChartData();     
        },  
  methods: {    
    getHaltsChartData() {
      setTimeout(() => {
        let self = this;
        self.loaderHide=false;
        const returnResult = {            
             categories: ['Last Week: 32h', 'This Week: 40h'],  
             series: [{                  
                  data: [{
                         name: "Last Week",
                         y: 32,                                         
                         color:'#CCCCCC',                                
                        },
                       {
                        name: "This Week",
                        y: 40,                                        
                        color:'#76CD24'
                        }
                      ] 
                   } ],              
            };
          self.chartOptions.xAxis.categories = returnResult.categories;
          self.chartOptions.series = returnResult.series;
          self.chartTitle = returnResult.chartTitle;
          self.chartTitleDuration = "(" + returnResult.chartTitleDuration + ")";

          self.loaderHide=true; 
      }, 5000);
    }
  }
};

</script>
<style scoped>


/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');*{font-family:'Roboto',sans-serif;}
.ctrack-h-100{height:100%;}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.md-dashboard-content{padding-top:10px;color:#C5C5C5;}
[class*="md-chart-arrow"]{display:inline-block;vertical-align:middle;}
[class*="md-chart-arrow"] svg{display:block;}
.md-chart-arrow-down{transform:rotate(180deg);-webkit-transform:rotate(180deg);-moz-transform:rotate(180deg);-ms-transform:rotate(180deg);-o-transform:rotate(180deg);}
.dExecutive-inner{height:100%;width:100%;display:inline-block;background-color:#FFFFFF;border-top:6px solid #CDCDCD;border-radius:5px;padding:0 20px 20px;}
.dExecutive-title{display:inline-block;width:100%;padding:10px 0;}
.dExecutive-title h4{color:#333333;font-size:20px;font-weight:500;position:relative;padding-left:40px;display:inline-block;width:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
.dExecutive-title h4 .dExe-title-icon{position:absolute;left:0;top:50%;transform:translateY(-50%);}
.dExecutive-title h4 .dExe-title-icon svg{display:block;}
.dExecutive-content{display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;justify-content:space-between;-webkit-justify-content:space-between;height:calc(100% - 60px);}
.dExecutive-main div[class*="ctrack-col-"]{margin-bottom:20px;}
.dExe-chart-row{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:nowrap;flex-wrap:nowrap;-webkit-box-align:center;-ms-flex-align:center;align-items:center;justify-content:space-between;-webkit-justify-content:space-between;width:100%;}
.ctrack-align-end{-webkit-box-align:flex-end;-ms-flex-align:flex-end;align-items:flex-end;}
.dExe-chart-col1 h3{font-size:50px;display:inline-block;width:100%;vertical-align:middle;position:relative;font-weight:700;white-space:nowrap}
.dExe-chart-col1 h3 span{display:inline-block;vertical-align:middle;padding-right:5px;}
.dExe-chart-col1 h3 span.md-chart-arrow-down{padding-right:0;padding-left:5px;}
.dExe-chart-col1 h3 svg{display:block;}
.dExe-chart-fullwidth{-ms-flex-wrap:wrap;flex-wrap:wrap;}
.dExe-chart-fullwidth > div{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
.dExe-chart-fullwidth .dExe-chart-col1 h3{text-align:center;}
.dExe-chart-main{padding-left:5px;}
.dExe-chart-fullwidth .dExe-chart-main{padding-left:0;}
.dExe-chart-lbl{display:inline-block;width:100%;margin-top:5px;}
.dExe-chart-lbl span{display:inline-block;vertical-align:middle;padding-right:8px;}
.dExe-chart-lbl span svg{display:block}
.dExe-chart-bottom{display:table;width:100%;}
.dExe-chart-bottom > div{display:table-cell;}
.dExe-chart-bottom-inner{text-align:center;display:inline-block;font-size:20px;font-weight:500;margin-top:15px;}
.dExe-chart-bottom-inner small{font-size:14px;margin-bottom:-5px;}
.dExe-chart-bottom-inner small,.dExe-chart-bottom-inner label{display:block;}
.dExe-chart-bottom-col2{text-align:right;}
.error-color{border-color:#FA4B4B;}
.success-color{border-color:#76CD24;}
.error-color .dExe-chart-col1 h3,.error-color .md-chart-color{color:#FA4B4B;}
.success-color .dExe-chart-col1 h3,.success-color .md-chart-color{color:#76CD24;}

/* Responsive CSS */
@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1399px){}
@media only screen and (max-width:1299px){}
@media only screen and (max-width:1199px){}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){}
@media only screen and (max-width:767px){}
@media only screen and (max-width:575px){
  .dExecutive-inner{padding:0 15px 15px}
  .dExe-chart-row{-ms-flex-wrap:wrap;flex-wrap:wrap;}
  .dExe-chart-row > div{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;text-align:center;}
  .dExe-chart-col1{margin-bottom:5px;}
  .dExe-chart-labels{display:table;width:100%;padding:5px 0 10px;}
  .dExe-chart-labels > div{display:table-cell;width:auto;}
  .dExe-chart-main{padding-left:0;}
}

</style>
