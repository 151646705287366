import Vue from 'vue';
import { CtrackSharedMisc } from 'ctrack-shared-misc';
import { CtrackSharedReplay } from 'ctrack-shared-replay';
import Constant from "@/../constant";
import store from '../store';
import _ from 'lodash';

Vue.use(CtrackSharedMisc, () => {
    const session = Vue.prototype.$session;
    if (!session) {
        return;
    }
    const configProps = session.get("configProperties");
    if (!configProps) {
        return;
    }
    const tenantDetails = session.get("tenantDetails");
    const baseMapLayer = configProps["baseMapLayer"];

    return {
        userId: tenantDetails.userId,
        baseMapLayer: baseMapLayer == null ? "Normal" : baseMapLayer,
        coordinate: {
            latitude: configProps.latitude,
            longitude: configProps.longitude
        },
        defaultVehicleId: configProps.defaultVehicleId,
        mapProviders: {
            normal: Constant.normalUrl + appConfig.Map.HereApiKey,
            terrain: Constant.terrianUrl + appConfig.Map.HereApiKey,
            satellite: Constant.sateliteUrl + appConfig.Map.HereApiKey,
            traffic: Constant.trafficUrl + appConfig.Map.HereApiKey
        },
        units: {
            distance: configProps.distanceMesurementUnit,
            speed: configProps.speedMesurementUnit
        },
        formats: {
            date: configProps.dateFormat,
            time: configProps.timeFormat
        },
        visibility: {
            clusteringEnabled: configProps.clusterVehicles || configProps.clusterLocations,
            tollgatesEnabled:  _.some(tenantDetails.claims, claim=> claim.applicationClaimName === 'TollGateRead' || claim.ApplicationClaimName === 'TollGateRead')
        }
    };
},
    (claim) => {
        return store.getters["claimsStore/getClaims"].includes(claim);
    }
);

Vue.use(CtrackSharedReplay, () => {
    const session = Vue.prototype.$session;
    if (!session) {
        return;
    }
    const configProps = session.get("configProperties");
    if (!configProps) {
        return;
    }
    const companyPrivacy = session.get("companyPrivacy");
    return {
        columnConfig: configProps.columnConfig,
        coordinate: {
            latitude: configProps.latitude,
            longitude: configProps.longitude
        },
        timeZone: configProps.timeZone,
        privacy: (companyPrivacy) ? configProps.privacy: companyPrivacy,
        units: {
            speed: configProps.speedMesurementUnit
        },
        visibility: {
            listitem: {
                contextmenu: true
            }
        },
        zoomLevel: configProps.zoomLevel
    };
});