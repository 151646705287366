import Vue from 'vue';
import { defineStore } from 'pinia'
import vendorService from "@/service/vendor.service";
export const useVendorStore = defineStore('vendors', {
    state: () => ({
        vendors: [],
        tenantId: "",
    }),
    //$vendorStore - Sorted by name
    actions: {

        /** Reads Vendor list from backend service and overwrites the current state
         * @param {Array<String>} ids
         */
        async getVendors(ids = null) {
            if (ids && !Array.isArray(ids)) { ids = [ids] };
            await vendorService.getVendors(ids).then(vendorResponse => {
                this.vendors = vendorResponse
                    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
                this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
            }).catch((err) => {
                Vue.prototype.$toast.error('API Failed to load vendors');
                this.vendors = [];
                this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
            });
        },

        /** Clears the current store       
         */
        async clearVendorStore() {
            this.vendors = [];
            this.tenantId = "";
        },

        /** Retreive the Store State if the TenantId Matches the store tenant         
         * @returns Array of vendors
         */
        getVendorsData() {
            return this.tenantId == (Vue.prototype.$session.get('tenantDetails')?.tenantId || '')
                ? this.vendors
                : []
        },

        /** Removes vendors based on provided ids
         * @param {[String]} ids
         */
        removeVendorsByIds(ids) {
            if (!Array.isArray(ids)) { ids = [ids] };
            this.vendors = [
                ...this.vendors
                    .filter(i => !ids.includes(i.id))
                    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
            ];
        },

        /** Reads vendors records based on ids provided and adds them to the store
         * @param {[String]} ids
         */
        async addVendorsByIds(ids) {
            if (!Array.isArray(ids)) { ids = [ids] };
            await vendorService.getVendors(ids).then(vendorResponse => {
                let stripIds = [...(Array.isArray(ids) ? ids : []), ...vendorResponse.map(i => i.id)];
                this.vendors = [...new Set([
                    ...this.vendors.filter(i => !stripIds.includes(i.id)),
                    ...vendorResponse
                ])].sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
                this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
            }).catch((err) => {
                Vue.prototype.$toast.error('API Failed to load contacts');
                this.vendors = [];
                this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
            });
        },

        /** Upserts store states based on provided object array
         * @param {[VendorEntry]} vendorList
         */
        async addVendorsByObject(vendorList) {
            if (!Array.isArray(vendorList)) { vendorList = [vendorList] };
            let stripIds = vendorList.map(i => i.id);
            this.vendors = [...new Set([
                ...this.vendors.filter(i => !stripIds.includes(i.id)),
                ...vendorList
            ])].sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
        },

        /** Replaces state with provided array
         * @param {[VendorEntry]} vendorData
         */
        replaceVendorsState(vendorData) {
            this.vendors = vendorData
                .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
            this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
        },

    },
})