import Vue from 'vue'
import { defineStore } from 'pinia'
import userService from "@/service/user.service"

export const useUserStore = defineStore('user', {
    state: () => ({
        allUsers:[],
        users: [],
        tenantId: "",
    }),
    //$userStore - Sorted by displayName
    actions: {
        /** Reads user list from backend service and overwrites the current state
        * @param {Array<String>} ids
        */
        async getUsers(ids = null) {
            if (ids && !Array.isArray(ids)) { ids = [ids] };
            await userService.getUsersByIds(Vue.prototype.$session.get("applicationId"), ids).then(results => {
                this.users = results.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1);
                this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
            }).catch((err) => {
                Vue.prototype.$toast.error('API Failed to load users');
                this.users = [];
                this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
            });
        },
         /** Reads user list from backend service and overwrites the current state
        * @param {Array<String>} ids
        */
        async getAllUsers() {
            await userService.getAllUsersListed(Vue.prototype.$session.get("applicationId")).then(results => {
                this.allUsers = results.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1);
                this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
            }).catch((err) => {
                Vue.prototype.$toast.error('API Failed to load users');
                this.allUsers = [];
                this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
            });
        },

        /** Clears the current store
         */
        clearUsersStore() {
            this.allUsers = [];
            this.users = [];
            this.tenantId = "";
        },

        /** Retreive the Store State if the TenantId Matches the store tenant         
         * @returns Array<UserEntry> 
         */
        getUsersData() {
            let sessionTenant = (Vue.prototype.$session.get('tenantDetails')?.tenantId || '')
            return this.tenantId == sessionTenant
                ? this.users
                : [];
        },

        /** Retreive the Store State if the TenantId Matches the store tenant         
         * @returns Array<UserEntry> 
         */
        getAllUsersData() {
            let sessionTenant = (Vue.prototype.$session.get('tenantDetails')?.tenantId || '')
            return this.tenantId == sessionTenant
                ? this.allUsers
                : [];
        },

        /** Removes users based on provided ids         
         * @param {Array<String>} ids
         */
        removeUsersByIds(ids) {
            if (!Array.isArray(ids)) { ids = [ids] };
            this.users = [
                ...this.users
                    .filter(i => !ids.includes(i.id))
                    .sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1))
            ];
        },

        /** Reads user records based on ids provided and adds them to the store
         * @param {Array<String>} ids
         */
        async addUsersByIds(ids) {
            if (!Array.isArray(ids)) { ids = [ids] };
            let self = this;
            await userService.getUsersByIds(Vue.prototype.$session.get("applicationId"), ids).then(userData => {
                let stripIds = [...(Array.isArray(ids) ? ids : []), ...userData.map(i => i.id)];
                self.users = [...new Set([
                    ...self.users.filter(i => !stripIds.includes(i.id)),
                    ...userData
                ])].sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1));
                self.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
            }).catch((err) => {
                Vue.prototype.$toast.error('API Failed to load users');
                this.users = [];
                this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
            });
        },

        /** Upserts store states based on provided object array
         * @param {Array<UserEntry>} userList
         */
        async addUsersByObject(userList) {
            if (!Array.isArray(userList)) { userList = [userList] };
            let targetIds = userList.map(i => i.id);
            this.users = [
                ...this.users.filter(i => !targetIds.includes(i.id)),
                ...userList
            ].sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1));
        },

        /** Replaces state with provided array
         * @param {Array<UserEntry>} userData
         */
        replaceUserState(userData) {
            if (!Array.isArray(userData)) { userData = [userData] };
            this.users = userData.sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1));
            this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
        },
    },
})
