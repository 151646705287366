<template>
    <!--<div>
        <md-dialog :md-active="showDialog">
            <md-content>{{display}}</md-content>
            <md-dialog-actions>
                <md-button class="md-primary" @click="closeDialog()">{{$tc("Settings.close")}}</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>-->
    <v-dialog v-model="showDialog"
              max-width="500"
              @click:outside="closeDialog">
        <v-card>
            <v-card-title class="content">
                {{display}}
            </v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="closeDialog">
                    {{$tc("Settings.close")}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: "PopupDialog",
        props: {
            value: Boolean,
            dataItem: Object,
            dataFields: String
        },
        computed: {
            display() {
                var text = [];
                var fields = this.dataFields.split(",");
                for (var i = 0; i < fields.length; i++) {
                    var field = fields[i];
                    text.push(this.dataItem[field]);
                }
                return text.toString();
            }
        },
        data: () => ({
            showDialog: false
        }),
        watch: {
            value(value) {
                this.showDialog = value;
            }
        },
        methods: {
            closeDialog() {
                this.$emit("input", false);
            }
        }
    };
</script>
<style scoped>
    .v-dialog {
        max-width: 768px;
        width: 500px;
    }

    .content {
        padding: 24px;
        word-break: break-word;
    }
</style>