import Vue from 'vue';
import { defineStore } from 'pinia'
import contactService from "@/service/contact.service";
export const useContactStore = defineStore('contacts', {
    state: () => ({
        contacts: [],
        tenantId: "",
    }),
    //$contactStore - Sorted by firstName
    actions: {

        /** Reads driver list from backend service and overwrites the current state
         * @param {Array<String>} ids
         */
        async getContacts(ids = null) {
            if (ids && !Array.isArray(ids)) { ids = [ids] };
            await contactService.getContactByIds(ids).then(contactResponse => {
                let contactData = contactResponse.data || [];
                this.contacts = contactData
                    .map(contact => ({
                        ...contact,
                        fullName: contact.firstName + ' ' + contact.lastName,
                    }))
                    .sort((a, b) => (a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1));
                this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
            }).catch((err) => {
                Vue.prototype.$toast.error('API Failed to load contacts');
                this.contacts = [];
                this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
            });
        },

        /** Clears the current store       
         */
        async clearContactsStore() {
            this.contacts = [];
            this.tenantId = "";
        },

        /** Retreive the Store State if the TenantId Matches the store tenant         
         * @returns Array of Contacts
         */
        getContactsData() {
            let sessionTenant = (Vue.prototype.$session.get('tenantDetails')?.tenantId || '')
            return this.tenantId == sessionTenant
                ? this.contacts
                : []
        },

        /** Removes Contacts based on provided ids
         * @param {[String]} ids
         */
        removeContactsByIds(ids) {
            if (!Array.isArray(ids)) { ids = [ids] };
            this.contacts = [
                ...this.contacts
                    .filter(i => !ids.includes(i.id))
                    .sort((a, b) => (a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1))
            ];
        },

        /** Reads Contacts records based on ids provided and adds them to the store
         * @param {[String]} ids
         */
        async addContactsByIds(ids) {
            if (!Array.isArray(ids)) { ids = [ids] };
            let self = this;
            await contactService.getContactByIds(ids).then(contactResponse => {
                let contactData = contactResponse.data || [];
                let stripIds = [...(Array.isArray(ids) ? ids : []), ...contactData.map(i => i.id)];
                self.contacts = [...new Set([
                    ...self.contacts.filter(i => !stripIds.includes(i.id)),
                    ...contactData.map(contact => ({ ...contact, fullName: contact.firstName + ' ' + contact.lastName }))
                ])].sort((a, b) => (a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1));
                self.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
            }).catch((err) => {
                Vue.prototype.$toast.error('API Failed to load contacts');
                this.contacts = [];
                this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
            });
        },

        /** Upserts store states based on provided object array
         * @param {[contactEntry]} contactList
         */
        async addContactsByObject(contactList) {
            if (!Array.isArray(contactList)) { contactList = [contactList] };
            let targetIds = contactList.map(i => i.id);
            this.contacts = [
                ...this.contacts.filter(i => !targetIds.includes(i.id)),
                ...contactList.map(contact => ({ ...contact, fullName: contact.firstName + ' ' + contact.lastName }))
            ].sort((a, b) => (a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1));
        },

        /** Replaces state with provided array
         * @param {[ContactEntry]} contactData
         */
        replaceContactsState(contactData) {
            this.contacts = contactData
                .map(contact => ({ ...contact, fullName: contact.firstName + ' ' + contact.lastName }))
                .sort((a, b) => (a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1));
            this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
        },

    },
})