export default class SingleSelectionStrategy {
	constructor(component) {
		this.component = component;
		if (component.value == null || component.value == undefined ) {
			component.setDate(new Date(), component);
		} else {
			component.setDate(component.value, component);
			component.setDate(component.value, component.start, true);
		}
	}
	dateChange (startDate) {
		if (!this.isDirty(startDate)) {
			return;
		}
		this.component.$emit('input', startDate);
		if (this.component.change !== undefined) {
			this.component.change(startDate);
		}
	}
	getTitle  (titleFormat) {
		if (this.component.start.date.year == 0) {
			return '';
		}
		return this.component.$moment(this.component.start.toDate(true)).format(titleFormat);
	}
	getFormattedDate  (format) {
		if (this.component.start.date.year == 0) {
			return '';
		}
		return this.component.$moment(this.component.start.toDate(true)).format(format);
	}
	isSelected (date) {
		return this.component.start.isDay(date);
	}
	isInRange(date) {
		return false;
	}
	isDirty(startDate) {
		var component = this.component;
		var toString = (value) => { return component.$moment(value).format('DDMMYYYHHmm'); };
		if (this.component.value !== null
			&& this.component.value !== undefined
			&& startDate.getYear() > 0
			&& toString(this.component.value.start) === toString(startDate)) {
			return false;
		}
		return true;
	}
	selectDate (date) {
		this.component.setDate(date, this.component.start);
	}
}
