import axios from 'axios';

class WorkingHoursService {
    getVehicles(ids) {
        return axios.post('/api/workinghours/vehicles', ids);
    }
    getDrivers(ids) {
        return axios.post('/api/workinghours/drivers', ids);
    }
}

export default new WorkingHoursService();