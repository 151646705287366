<template>
    <td :class="className">
        <v-list v-if="value != null && $checkRBACAccess('epodRead')">
            <v-list-group>
                <template v-slot:activator>
                    <div style="width:100%;">
                        ({{value.length}})
                        <v-icon v-for="attachment in value"
                                :key="attachment.urlId">{{determineIcon(attachment.type)}}</v-icon>
                    </div>
                </template>
                <v-list-item v-for="attachment in value"
                             :key="attachment.urlId"
                             link >
                    <v-list-item-icon>
                        <v-icon small>{{determineIcon(attachment.type)}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title @click="clickHandler(attachment)">{{trimFileName(attachment.fileName)}}</v-list-item-title>
                </v-list-item>
            </v-list-group>
            
        </v-list>
    </td>
</template>
<!-- The field name will be parsed for a tooltip -->
<script>
    export default {
        name: "iconCell",
        data() {
            return {
                value: this.dataItem[this.field]
            };
        },
        props: {
            field: String,
            dataItem: Object,
            format: String,
            className: String,
            columnIndex: Number,
            columnsCount: Number,
            rowType: String,
            level: Number,
            expanded: Boolean,
            editor: String
        },
        methods: {
            clickHandler(attachment) {
                this.$emit(this.editor, { attachment: attachment, dataItem: this.dataItem });
            },
            trimFileName(fileName) {
                return fileName.length > 15 ? '...' + fileName.substring(fileName.length - 15) : fileName;
            },
            determineIcon(fileType) {
                let icon = 'attachment';
                switch (fileType) {
                    case 'Signature':
                        icon = "draw"
                        break;
                    case 'Photo':
                        icon = "photo_camera";
                        break;
                    case 'Text':
                        icon = "text_fields";
                        break;
                    case 'Pdf':
                        icon = "picture_as_pdf";
                        break;
                };
                return icon
            },
        },
        
    };
</script>
<style scoped>
    .gridLink {
        color: #40C1AC;
        cursor: pointer;
    }
</style>