<template>
  <div class="idleTimeVsDriveTimeChart ctrack-h-100" ref="idleTimeVsDriveTime">
    <div id="vueapp" class="vue-app ctrack-h-100">
        
      <!-- Idle Time Vs Drive  Time Chart -->
      <div class="cd-chart-innerbox" :class="hideloaderClass">        
        <div class="cd-chart-box-top">
          <div class="cd-chart-box-header">
            <div class="cd-chart-box-col1">
              <h3>{{chartTitle}} <!-- Idle Time Vs Drive  Time --> <span>{{chartTitleDuration}}<!-- (by day for week) --></span></h3> 
            </div>
            <div class="cd-chart-box-col2" :class="loaderHide?'':'ctrack-chart-hide-loader'">
              <label class="cd-chart-right-lbl">{{chartAltTitleCaption}}<strong>{{chartAltTitleValue}}</strong></label>
              <div class="md-view-detail-link" @click="showDrill = true;">
                    <svg version="1.1" width="20" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 89.64 90.91" style="enable-background:new 0 0 89.64 90.91;" xml:space="preserve"><g>
                        <path d="M5.93,84.99c27.97,0,55.8,0,83.71,0c0,2,0,3.93,0,5.92c-29.87,0-59.73,0-89.64,0C0,60.64,0,30.4,0,0.09
                        c1.96,0,3.91,0,5.93,0C5.93,28.38,5.93,56.61,5.93,84.99z"/>
                        <path d="M67.5,80.38c0-21.98,0-43.9,0-65.87c3.52,0,7,0,10.53,0c0,21.97,0,43.88,0,65.87C74.53,80.38,71.06,80.38,67.5,80.38z"/>
                        <path d="M30.45,25.58c-1.48,1.49-2.94,2.95-4.4,4.42c-2.77,2.77-5.54,5.53-8.3,8.31c-0.93,0.94-2.01,1.29-3.27,0.94
                        c-2.07-0.58-2.86-3.07-1.52-4.76c0.32-0.4,0.71-0.76,1.07-1.12c3.77-3.78,7.54-7.56,11.33-11.31c0.53-0.52,0.66-0.87,0.28-1.62
                        C23,15.15,23.27,9.97,26.97,5.33c3.65-4.58,8.59-6.28,14.29-4.83c5.75,1.46,9.26,5.35,10.22,11.18c0.89,5.44-0.86,10.1-5.41,13.36
                        c-4.63,3.33-9.62,3.59-14.74,1.02C31.07,25.94,30.83,25.79,30.45,25.58z M29.98,13.82c0,4.37,3.46,7.85,7.79,7.86
                        c4.42,0.01,7.94-3.46,7.95-7.82c0.01-4.27-3.54-7.74-7.91-7.75C33.48,6.1,29.98,9.55,29.98,13.82z"/>
                        <path d="M41.48,35.67c3.53,0,6.97,0,10.49,0c0,14.87,0,29.69,0,44.59c-3.48,0-6.95,0-10.49,0C41.48,65.41,41.48,50.59,41.48,35.67z
                        "/>
                        <path d="M26.12,80.4c-3.5,0-6.94,0-10.46,0c0-8.75,0-17.46,0-26.24c3.45,0,6.92,0,10.46,0C26.12,62.87,26.12,71.58,26.12,80.4z"/>
                    </g>
                    </svg>
                </div>
            </div>
          </div>
        </div>       
        <div class="cd-chart-box-middle">
          <div class="ctrack-md-loader" :class="loaderHide?'ctrack-chart-hide-loader':''"></div>
          <div class="cd-chart-box-img"> 
            <highcharts :options="options" ref="idleTimeDriveTimeChart" :class="loaderHide?'':'ctrack-chart-hide-loader'"></highcharts>           
            <!-- <img width="100%" src="../../../assets/images/dashboard/md-idle-time-vs-drive-time-chart.svg" /> -->
            <div class="cd-indicator-pos-center" :class="loaderHide?'':'ctrack-chart-hide-loader'">
              <div class="cd-indicator-pos-col" :key="i" v-for="(series, i) in options.series">
                <div class="cd-chart-indicator-inner"><span class="cd-chart-indicator-color" :style="'background-color:'+series.color"></span>{{series.name}}</div>
              </div>
              <!-- <div class="cd-indicator-pos-col">
                <div class="cd-chart-indicator-inner"><span class="cd-chart-indicator-color" style="background-color: #FFC20E;"></span>Idle Time</div>
              </div> -->
            </div>
          </div>
        </div> 
        <div class="md-no-data-found-msg"><span class="md-error-dataIcon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"><path d="M754.269,1479.679a.994.994,0,0,1-.1-1.255q2.3-4.585,4.6-9.167l6.755-13.474c.041-.083.08-.167.127-.247a.977.977,0,0,1,1.739.008c1.273,2.494,2.52,5,3.8,7.539l-1.774.883c-.959-1.907-1.908-3.794-2.89-5.746-3.322,6.624-6.612,13.188-9.918,19.78h19.855l-4.808-9.814,1.778-.872c.4.814.789,1.607,1.178,2.4,1.423,2.9,2.85,5.8,4.26,8.711a1.463,1.463,0,0,1,.139.795.968.968,0,0,1-1.076.773h-8.463q-7.16,0-14.322,0A1.117,1.117,0,0,1,754.269,1479.679Zm10.951-4.834a1.3,1.3,0,0,1,1.3-1.349,1.336,1.336,0,0,1,0,2.67A1.3,1.3,0,0,1,765.221,1474.845Zm.963-2.283a.937.937,0,0,1-.582-.905q-.067-1.2-.135-2.406c-.075-1.327-.145-2.654-.229-3.98a1.294,1.294,0,1,1,2.559,0c-.012.143-.023.287-.034.431h0c-.11,1.975-.217,3.949-.332,5.924a.958.958,0,0,1-.9,1A.941.941,0,0,1,766.184,1472.562Zm4.017-7.073,1.752-.917.792,1.5-1.757.917C770.721,1466.482,770.467,1466,770.2,1465.489Z" transform="translate(-753.998 -1455.009)"></path></svg></span> </div>
      </div>
      <!-- Idle Time Vs Drive  Time Chart -->
        
    </div>  
    <md-dialog class="md-driver-drilldown" :md-active.sync="showDrill">
      <DrillDownGrid v-if="showDrill" :data="widgetData" :gridSettingsKey="widgetData.widgetName"></DrillDownGrid>
    </md-dialog>   
  </div>
</template>

<script>
import Vue from 'vue'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import HighChartMore from 'highcharts/highcharts-more'
import HighChartAccessibility from 'highcharts/modules/accessibility';
import dashboardService from "../../../service/dashboard.service";
import { mutations, getters } from "../shared/observeDashboardFilter";
import JQuery from "jquery";

HighChartMore(Highcharts);
HighChartAccessibility(Highcharts);
Vue.use(HighchartsVue, { });
export default {
    name: 'idleTimeVsDriveTimeChart',
    components: {},
    data: function() {
        return {
        chartTitle: "",
        chartTitleDuration: "",
        chartAltTitleCaption: "",
        chartAltTitleValue: "",
        loaderHide: false,
        hideloaderClass:"",
        showDrill:false,
        options: {
            credits: {
                enabled: false
            },
            chart: {
                zoomType: 'xy',
                //margin:[50,50,50,50]
                marginLeft:50,
                marginRight:50,
                //width:"100"
            },
            title: {
                text: 't',
                style: {
                    color: '#FFFFFF'
                }
            },
            xAxis: {
                categories: [],
                //offset: 5,
                labels: {
                    style: {
                        color: '#333333',
                        fontSize: "14px",
                        fontFamily: "Roboto",
                        opacity: 1
                    },
                    /* formatter: function () {
                        return '<span style="width: 6px; height: 6px; border-radius: 50%;background:#288DC1; display:block;margin: auto; top: -10px; position: relative;"/></span><br>' + this.value;
                    }, */
                    useHTML: true
                },
            },
            yAxis: [{            
                labels: {
                    // format: '{value}',
                    style: {
                        // fontWeight:'bold',
                        color: '#333333',
                        fontFamily: 'Roboto,Regular',
                        fontSize: "14px",
                    }
                },
                type: 'logarithmic',
                lineWidth: 2,
                gridLineDashStyle: 'longdash',
                //lineColor: '#2A48C2',
                title: {
                    text: 'Miles',
                    align: 'high',
                    offset: 0,
                    rotation: 0,
                    y: -15,
                    x: 70,
                    style: {
                        fontWeight: 'bold',
                        color: '#2A48C2'
                    }
                },
            }, 
            { // Secondary yAxis
                title: {
                    text: 'Fuel',
                    align: 'high',
                    offset: 0,
                    rotation: 0,
                    y: -15,
                    x: -70,
                    style: {
                        fontWeight: 'bold',
                        color: '#FFC20E'
                    }
                },
                lineWidth: 2,
                gridLineDashStyle: 'longdash',
                //lineColor: '#FFC20E',
                labels: {
                    // format: '{value}',
                    
                    style: {
                        color: '#333333',
                        fontFamily: 'Roboto,Regular',
                        fontSize: "14px",
                        align: "left"
                    },
                    // type: 'spline',
                },            
                opposite: true
            }],
            tooltip: {
                backgroundColor: {
                    linearGradient: [0, 0, 0, 60],
                    stops: [
                        [0, '#FFFFFF'],
                        [1, '#FFFFFF']
                    ]
                },
                borderWidth: 0,
                seriesData: [],
                formatter: function () {
                    let fistSeries = this.series.tooltipOptions.seriesData[0];
                    let secondSeries = this.series.tooltipOptions.seriesData[1];
                    return '<b>' + this.x + '</b><br/>' + fistSeries.name + ': <b>' + fistSeries.formattedData[this.point.x] /* + ' ' + fistSeries.timeUnit */ + '</b><' +
                        'br/></br>' + secondSeries.name + ': <b>' + secondSeries.formattedData[this.point.x] /* + ' ' + secondSeries.timeUnit */ + '</b>'
                },
                style: {
                    color: '#333333',
                    fontSize: "14px",
                    fontFamily: "Roboto"
                }
            },
            legend: {
                enabled: false,
                layout: 'vertical',
                align: 'left',
                x: 120,
                verticalAlign: 'top',
                y: 100,
                floating: true,
                backgroundColor:'rgba(255,255,255,0.25)'
            },
            plotOptions: {
                column: {
                    //  borderRadius:5,
                    // color: '', //'#0082CA',
                    borderRadiusTopLeft: 5,
                    borderRadiusTopRight: 5

                },
                spline: {
                    // color: '#DA9707'
                    marker: {
                        enabled: true,
                        symbol: 'circle',
                        radius: 5,
                        fillColor: '#FFFFFF',
                        lineWidth: 4,
                        // lineColor: '#DA9707'
                    },
                    states: {
                        hover: {
                            enabled: true,
                            fillColor: "#DA9707",
                            halo: {
                                size: 6,
                                opacity: 1,
                                attributes: {                                
                                    'stroke-width': 10,
                                    stroke: "#FFFFFF",
                                    lineColor: "#FFFFFF"

                                }
                            }
                        }
                    }
                },
                series: {
                    lineWidth: 4,
                    pointStart:0,
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                    }]
                }
            },
        }, 
        }
    },
    props: {
        widgetData: Object
    },
    created() {
      this.getIdleTimeVsDriveTimeChartData();  
    },  
    methods: {
        getIdleTimeVsDriveTimeChartData() {
            let self = this;
            self.loaderHide = false;
            dashboardService.getWidgetData( this.widgetData.api, getters.getFilterParams()).then(returnResult => {
            /* });
            setTimeout(() => {
                
                const returnResult = {
                    "id": "IdleTimeDriveTimeChart",
                    "data": {
                        "chartTitle": "History of Drive Time Vs Idle Time",
                        "chartTitleDuration": "by day for week",    
                        "chartAltTitleCaption": "Total Time",
                        "chartAltTitleValue": "100:45:34",
                        "categories": ["Sun,05 Apr", "Mon,06 Apr", "Tue,07 Apr", "Wed,08 Apr", "Thu,09 Apr", "Fri,10 Apr", "Sat,11 Apr"],
                        "series": [
                            {
                                "name": "Drive Time",                          
                                "type": "column",
                                "yAxis": 0,
                                "color": "#2A48C2",
                                "data": [2800, 2400, 2220, 3600, 2600, 2400, 2100],
                                "formattedData": ["28:23:43", "24:24:42", "22:20:32", "36:00:13", "26:26:16", "24:24:27", "21:21:19"],
                                "timeUnit": "hrs",
                                "zIndex": 1
                            },
                            {
                                "name": "Idle Time",                          
                                "type": "line",
                                "yAxis": 1,
                                "color": "#FFC20E",
                                "data": [120, 75, 78, 60, 78, 74, 98],
                                "formattedData": ["12:32:43", "07:11:22", "08:33:33", "06:44:44", "7:34:22", "8:44:55", "09:08:09"],
                                "timeUnit": "hrs",
                                "zIndex": 2,
                                "marker": {
                                    "lineColor": "#DA9707"
                                }
                            }                      
                        ]
                    }
                }; */
                // returnResult.categories; self.data.series = returnResult.series;
                self.options.xAxis.categories = returnResult.data.categories;
                self.options.series = returnResult.data.series;
                self.options.tooltip.seriesData = returnResult.data.series,                
                self.options.yAxis[1].title.text = returnResult.data.series[1].name + "("+ returnResult.data.series[1].timeUnit +")",                
                self.options.yAxis[1].title.lineColor = returnResult.data.series[1].color ,                
                self.options.yAxis[0].title.text = returnResult.data.series[0].name + "("+ returnResult.data.series[0].timeUnit +")",                
                self.options.yAxis[0].title.tlineColorext = returnResult.data.series[0].color,                
                
                self.chartTitle = returnResult.data.chartTitle;
                self.chartTitleDuration = "(" + returnResult.data.chartTitleDuration + ")";
                self.chartAltTitleCaption = returnResult.data.chartAltTitleCaption + ": " ;
                self.chartAltTitleValue = returnResult.data.chartAltTitleValue;

                self.loaderHide = true;

                // hide first axis
            }).catch(err => {
                console.log(err)
                self.hideloaderClass = 'cd-chart-ifDataBlank'
                self.loaderHide=true
            });
        }
    }
};

</script>
<style scoped>

/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
*{font-family: 'Roboto', sans-serif;}
.ctrack-h-100{height: 100%;}
[class*="md-chart-arrow"]{display:inline-block;vertical-align:middle;}
[class*="md-chart-arrow"] svg{display:block;}
.md-chart-arrow-down{transform:rotate(180deg); -webkit-transform:rotate(180deg); -moz-transform:rotate(180deg); -ms-transform:rotate(180deg); -o-transform:rotate(180deg);}
.cd-chart-innerbox{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;width:100%;height:100%;background-color:#FFF;border-radius:5px;align-content: space-between; -webkit-align-content: space-between;}
.ctrack-align-content-top{align-content: start; -webkit-align-content: start;}
.cd-chart-box-middle{padding:20px 30px;-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
.cd-chart-box-bottom{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;border-top:1px solid #E8E8E8;margin-top:20px;padding:18px 0 0;}
.cd-chart-box-top{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;padding:15px 20px;border-bottom:1px solid #E8E8E8;}
.cd-chart-box-img {text-align: center;}
.cd-chart-box-header{display:table;width:100%;}
.cd-chart-box-header > div{display:table-cell;vertical-align: middle;}
.cd-chart-box-col1 h3{color:#013C5E;font-size:18px;font-weight:700;}
.cd-chart-box-col1 h3 span{color:#979797;font-weight:400;}
.cd-chart-box-col2{text-align:right;font-size:14px;font-weight:bold;color:#333333;}
.ctrack-select-main{display:inline-block;}
.ctrack-select-box{display:inline-block;font-family:'Roboto',sans-serif;font-weight:bold;color:#333333;font-size:14px;border:0;padding-right:2px;outline: 0}
.ctrack-chart-info{display:inline-block;vertical-align:middle;}
.ctrack-chart-info-btn,.ctrack-chart-info-btn svg{display:block;}
.ctrack-chart-info a{color:#e8e8e8 !important;}
.ctrack-chart-info a:hover{color:#cacaca !important;}
.cd-chart-left-selectbox h3{display: inline-block}
.ctrack-select-style2{width:100%;border:1px solid #d8d8d8;border-radius:5px;font-size:14px;font-family:'Roboto',sans-serif;color:#333333;padding:9px 30px 9px 10px;outline:0;-webkit-appearance:none;-moz-appearance:none;appearance:none;}
.cd-chart-left-selectbox .ctrack-select-main{padding-left:18px;width:168px;position:relative;}
.cd-chart-left-selectbox .ctrack-select-main:after{content:"";position:absolute;top:12px;right:16px;z-index:1;border-top:solid 2px #333333;border-right:solid 2px #333333;width:8px;height:8px;-webkit-pointer-events:none;-moz-pointer-events:none;pointer-events:none;-webkit-transform:rotate(135deg);-moz-transform:rotate(135deg);transform:rotate(135deg);}

.cd-indicator-pos-center{display:inline-block;width:100%; padding-top: 20px;}
.cd-indicator-pos-center .cd-indicator-pos-col{display:inline-block;vertical-align:middle;}
.cd-indicator-pos-center .cd-indicator-pos-col +  .cd-indicator-pos-col{padding-left: 40px;}
.cd-indicator-pos-center .cd-chart-indicator-inner{width:auto;padding-left: 40px;margin-bottom: 0;}
.cd-chart-indicator-inner{display:inline-block;width:100%;position:relative;padding-left:32px;margin-bottom:12px;text-align: left; color: #333333;font-size: 15px;font-weight: 500;}
.cd-chart-indicator-inner .cd-chart-indicator-color{position:absolute;left:0;top:50%;width:25px;height:11px;border-radius:3.5px;transform:translateY(-50%);}

.cd-chart-right-lbl{font-size:16px;font-weight:normal;color:#666666;display: inline-block;vertical-align: middle;}
.cd-chart-right-lbl strong{color:#013C5E;font-weight:700;}

/*No Data Section */
.cd-chart-ifDataBlank .cd-chart-box-top,.cd-chart-ifDataBlank .cd-chart-box-middle,.cd-chart-ifDataBlank .cd-chart-box-bottom,.cd-chart-ifDataBlank .ctrack-d-flexcol{visibility:hidden;}
.cd-chart-ifDataBlank .md-no-data-found-msg{display: block !important}
.md-no-data-found-msg{padding:15px 15px 15px 50px;position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);font-size:18px;color:#9e4e4e;border:1px solid #ffb2b2;background-color:#ffb2b2;border-radius:3px;display:none}
.md-no-data-found-msg .md-error-dataIcon{position:absolute;left:15px;top:50%;transform:translateY(-50%);}
.md-no-data-found-msg .md-error-dataIcon svg{display:block;}

/* Responsive CSS */
@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1399px){}
@media only screen and (max-width:1299px){}
@media only screen and (max-width:1199px){}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){}
@media only screen and (max-width:767px){
  .cd-chart-box-header > div{display: inline-block;width: 100%;}
  .cd-chart-box-col2{text-align: left;}
  .cd-chart-box-header{position: relative;padding-right: 30px;}
  .md-view-detail-link{position:absolute;right:0;top:2px;}
}

@media only screen and (max-width:575px){
  .cd-chart-box-middle {padding: 15px}
  .cd-chart-box-col1 h3 span{display: block}
  .cd-indicator-pos-center{margin-bottom:15px;}
  .cd-indicator-pos-center .cd-chart-indicator-inner{padding-left: 35px;}
  .cd-indicator-pos-center .cd-indicator-pos-col + .cd-indicator-pos-col{padding-left: 20px;}

}

</style>
