<template>
  <div class="driverUtilizationWidget ctrack-h-100" ref="driverUtilization">
    <div id="vueapp" class="vue-app ctrack-h-100">
        
      <!-- Driver Utilization Widget -->
        <div class="dBehaviour-inner" :class="compareWidgetColorClass">        
          <div class="dBehaviour-box-col">
            <div class="dBehaviour-box-icon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="22.862" height="25.534" viewBox="0 0 22.862 25.534"><path d="M276.078,486.76H265.409c-.536,0-.755-.223-.765-.758a9.128,9.128,0,0,1,.474-3.624,6.968,6.968,0,0,1,3.783-3.945.267.267,0,0,1,.375.09,8.562,8.562,0,0,0,5.52,3.082,8.676,8.676,0,0,0,8.011-3.012.38.38,0,0,1,.543-.118,6.9,6.9,0,0,1,4.1,5.754c.065.583.047,1.176.054,1.765.007.542-.22.766-.754.766H276.078Zm-4.581-7.936a7.57,7.57,0,0,1-2.442-8.919,16.628,16.628,0,0,0,14.029.006,7.491,7.491,0,0,1-1.935,8.5,7.606,7.606,0,0,1-5.088,1.958A7.5,7.5,0,0,1,271.5,478.823Zm3.112-8.747a12.741,12.741,0,0,1-4.317-1.015,3.913,3.913,0,0,1-1.372-.982,24.16,24.16,0,0,1,14.255-.019l.043.1c-.308.225-.6.477-.928.67a9,9,0,0,1-3,1.012,17.665,17.665,0,0,1-3.211.3Q275.345,470.14,274.609,470.076Zm-6.093-3.274a2.885,2.885,0,0,1,.38-1.442,7.139,7.139,0,0,1,2.964-3.029,8.139,8.139,0,0,1,4.717-1.083A7.878,7.878,0,0,1,283,464.917a7.715,7.715,0,0,1,.6,1.425,2.033,2.033,0,0,1,.052.464A25.344,25.344,0,0,0,268.516,466.8Z" transform="translate(-264.639 -461.226)"/></svg></div>
            <div class="dBehaviour-box-content">
              <h3>
                <span class="dBehaviour-box-value-icon" :class="compareWidgetIcon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10"><path d="M638.392,193H628.62a.626.626,0,0,1-.527-.962c.213-.383,4.509-8.038,4.876-8.691a.6.6,0,0,1,1.059,0c.269.47,4.587,8.154,4.887,8.711A.623.623,0,0,1,638.392,193Z" transform="translate(-628 -183.028)" /></svg></span>
                {{compareWidgetValue}}
              </h3>
              <p class="ctrack-text-truncate" :title="widgetTitle">{{widgetTitle}}</p>
              <p class="md-uw-value">{{widgetValue}}</p>
            </div>
          </div>        
        </div>
        <!-- Driver Utilization Widget -->

    </div>    
  </div>
</template>

<script>
import dashboardService from "../../../service/dashboard.service";
import { mutations, getters } from "../shared/observeDashboardFilter";
export default {
  name: 'driverUtilizationWidget',
  components: {},
  data: function() {
    return {
      widgetTitle:"-",
      widgetValue:"0",
      compareWidgetValue:"0",
      compareWidgetIcon:"",
      compareWidgetColorClass:"" 
    }
  }, 
  props: {
    widgetData: Object
  },
  created(){
    this.getDriverUtilizationWidgetData();        
  },   
  methods: {
    getDriverUtilizationWidgetData()
    { 
      let self = this;  
      dashboardService.getWidgetData( this.widgetData.api, getters.getFilterParams()).then(returnResult => {
        self.widgetTitle = returnResult.hwTitle;
        self.widgetValue = returnResult.value + returnResult.type;
        self.compareWidgetValue = returnResult.compareValue + returnResult.compareType;
        self.compareWidgetIcon =  (returnResult.compareIcon == "down") ? "md-chart-arrow-down" : (returnResult.compareIcon == "up") ? "md-chart-arrow-up" : "";
        self.compareWidgetColorClass = (returnResult.compareColor == "green") ? "success-color" : (returnResult.compareColor == "red") ? "error-color" : "";
      });  
        
      /* const returnResult =  {
          hwID: "DriverUtilizationWidget",
          hwTitle: "Driver Utilization",
          compareValue: "4",
          compareType:"%",
          compareIcon: "up",
          compareColor: "red",
          value:"84",
          type:"%"
      };
      self.widgetTitle = returnResult.hwTitle;
      self.widgetValue = returnResult.value + returnResult.type;
      self.compareWidgetValue = returnResult.compareValue + returnResult.compareType;
      self.compareWidgetIcon =  (returnResult.compareIcon == "down") ? "md-chart-arrow-down" : (returnResult.compareIcon == "up") ? "md-chart-arrow-up" : "";
      self.compareWidgetColorClass = (returnResult.compareColor == "green") ? "success-color" : (returnResult.compareColor == "red") ? "error-color" : ""; */
    }
  }
};

</script>
<style scoped>
/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
*{font-family: 'Roboto', sans-serif;}
.ctrack-h-100{height: 100%;}
.error-color .dBehaviour-box-col h3{color:#FA4B4B;}
.success-color .dBehaviour-box-col h3{color:#76CD24;}
[class*="md-chart-arrow"]{display:inline-block;vertical-align:middle;}
[class*="md-chart-arrow"] svg{display:block;}
.md-chart-arrow-down{transform:rotate(180deg); -webkit-transform:rotate(180deg); -moz-transform:rotate(180deg); -ms-transform:rotate(180deg); -o-transform:rotate(180deg);}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}

.dBehaviour-inner{display:inline-block;width:100%;height:100%;background-color:#FFF;padding:13px 20px;border-radius: 5px; box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, .05); -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, .05);}
.dBehaviour-box-row{display:inline-block;width:100%;padding-left:40px;position:relative;}
.dBehaviour-box-col{display:inline-block;vertical-align: middle;position:relative;width:100%;text-align:right;padding-left:40px;}
.dBehaviour-box-icon{position:absolute;color:#656565; left:0;top:50%;transform:translateY(-50%); -webkit-transform:translateY(-50%); -moz-transform:translateY(-50%); -ms-transform:translateY(-50%); -o-transform:translateY(-50%);}
.dBehaviour-box-content{display:block;width:100%;padding-left:5px;}
.dBehaviour-box-content h3{font-size:20px;font-weight:500;}
.dBehaviour-box-value-icon{margin-right:8px;}
.dBehaviour-box-content p{font-size:15px;color:#333333;font-weight:500;}
.dBehaviour-box-content p.md-uw-value{font-size:18px;color: #999999;font-weight: 400;}


/* Responsive CSS */
@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1399px){}
@media only screen and (max-width:1299px){}
@media only screen and (max-width:1199px){}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){}
@media only screen and (max-width:767px){}
@media only screen and (max-width:575px){}

</style>