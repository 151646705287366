import Vue from 'vue';
var serviceDateTimeFormat = "YYYY-MM-DDTHH:mm:ss";
var serviceDateFormat = "YYYY-MM-DD";
var serviceTimeFormat = "HH:mm";

export default {

	wait(ms) {
		var start = new Date().getTime();
		var end = start;
		while (end < start + ms) {
			end = new Date().getTime();
		}
	},
	formatAMPM(time) {
		return time.toLocaleString("en-US", {
			hour: "numeric",
			minute: "numeric",
			timeZone: "UTC",
			hour12: false
		});
	},
	formatDate(time) {
		return this.convertDate(time, 'x', "DD-MM-YYYY");
	},
	formatTime(time) {
		return this.convertDate(time, 'x', "HH:mm");
	},
	formatreportDateTime(time) {
		return this.convertDate(time, 'x', "DD-MM-YYYY HH:mm:ss");
	},
	convertTimestamp(string) {
		let dateString = string,
			dateTimeParts = dateString.split(' '),
			timeParts = dateTimeParts[1].split(':'),
			dateParts = dateTimeParts[0].split('-'),
			date;
		date = new Date(dateParts[2], parseInt(dateParts[1], 10) - 1, dateParts[0], timeParts[0], timeParts[1]);
		return date.getTime();
	},
	checkNullValue(val) {
		
		if (val == null || val == 0) {
			return '';
		} else if (val == -1) {
			return 0;
		} else {
			return val;
		}
	},
	convertTodate(date) {
		if (!date) {
			return "";
		}
		var myDate = new Date(date);
		var years = myDate.getFullYear();
		var Months = ('0' + (myDate.getMonth() + 1)).slice(-2);
		var dates = ('0' + myDate.getDate()).slice(-2);
		var hours = myDate.getHours();
		var minutes = ('0' + (myDate.getMinutes())).slice(-2);
		var seconds = myDate.getSeconds();
		var dataformate = years + '-' + Months + '-' + dates + ' ' + hours + ':' + minutes + ':' + seconds;
		return dataformate;
	},
	convertTime24to12(time24) {
		if (!time24) {
			return "";
		}
		return this.convertDate(time24, this.serviceDateTimeFormat, "HH:mm");
	},
	convertDistance(value, unit) {
		let calculatedValue = 0;
		if (unit == 'km') {
			calculatedValue = value / 1000;
		}
		else {
			calculatedValue = value / 1609.34;
		}
		calculatedValue = Math.round(calculatedValue);
		return `${calculatedValue} ${unit}`
	},
	converttime(sec) {
		if (!sec || sec === 0) {
			return "00:00:00";
		}
		// convert the seconds into hours,seconds and minutes
		let totalSeconds = sec;
		let hours = Math.floor(totalSeconds / 3600);
		totalSeconds %= 3600;
		let minutes = Math.floor(totalSeconds / 60);
		let seconds = totalSeconds % 60;
		// If you want strings with leading zeroes:
		if (minutes > 0 && minutes < 10) {
			minutes = String(minutes).padStart(2, "0");
		}
		if (hours > 0 && hours < 10) {
			hours = String(hours).padStart(2, "0");
		}
		if (seconds > 0 && seconds < 10) {
			seconds = String(seconds).padStart(2, "0");
		}
		return hours + ":" + minutes + ":" + seconds;
	},
	toHHMMSS(sec_num) {
		var hours = Math.floor(sec_num / 3600);
		var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
		var seconds = Math.round(sec_num - (hours * 3600) - (minutes * 60));
		if (hours < 10) { hours = "0" + hours; }
		if (minutes < 10) { minutes = "0" + minutes; }
		if (seconds < 10) { seconds = "0" + seconds; }
		return hours + ':' + minutes + ':' + seconds;
	},
	minutesToHHMM(min) {
		var hours = Math.floor(min / 60);
		var minutes = Math.floor(min - (hours * 60));
		if (hours < 10) { hours = "0" + hours; }
		if (minutes < 10) { minutes = "0" + minutes; }
		return hours + ":" + minutes
    },
	convertTime(sec) {
		if (!sec || sec === 0) {
			return "0 sec";
		}
		let suffix = "min";
		let showHours = false, showMins = false, showSecs = false;
		// convert the seconds into hours,seconds and minutes
		let totalSeconds = sec;
		let hours = Math.floor(totalSeconds / 3600);
		totalSeconds %= 3600;
		let minutes = Math.floor(totalSeconds / 60);
		let seconds = totalSeconds % 60;

		// If you want strings with leading zeroes:
		if (seconds > 0) {
			showSecs = minutes < 0 && hours < 0;
			suffix = "sec"
			seconds = String(seconds).padStart(2, "0");
			seconds = showSecs ? `:${seconds}` : `${seconds}`
		}

		if (minutes > 0) {
			showMins = true;
			suffix = "min"
			minutes =  String(minutes).padStart(2, "0");
			minutes = hours > 0 ? `:${minutes}` : `${minutes}`
		}
		if (hours > 0) {
			showHours = true;
			if(minutes > 0){
				showMins = true;
			} 
			suffix = hours > 1 ? "hours": "hour";
			hours = String(hours).padStart(2, "0");
		}
		return `${showHours ? hours : ''}${showMins ? minutes : ''} ${showSecs ? seconds : ''} ${suffix}`;
	},
	getNestedValue(item, key) {
		if (!key) {
			return;
		}
		const tokens = key.split(".");
		const firstTokenValue = item[tokens[0]];
		let value = firstTokenValue ? firstTokenValue : "";
		if (tokens.length < 2) {
			return value;
		}
		tokens.splice(0, 1);
		tokens.forEach(token => {
			value = value[token]
			if (!value) {
				return;
			}
		});
		return value;
	},
	convertDateToServiceFormat(timeInMillis) {
		return Vue.prototype.$moment(String(timeInMillis), 'x').format(this.serviceDateTimeFormat);
	},
	convertServiceDateToMillis(str) {
		return Vue.prototype.$moment(String(str), this.serviceDateTimeFormat).utc().format('x');
	},
	convertUtcToUserTimeZone(timeInMillis, zone, format) {
		return Vue.prototype.$moment(String(timeInMillis), 'x').tz(zone).format(format);
	},
	convertDateToUtc(datetime, zone = "Africa/Johannesburg") {
		const format = 'DD-MM-YYYY HH:mm:ss';
		const utcDate = Vue.prototype.$moment(String(datetime.getTime()), 'x').utc().format(format);
		const utcInMillis = Vue.prototype.$moment(utcDate, format).utc().format('x')
		return utcInMillis;
	},
	convertDate(date, sourceFormat, targetFormat) {
		return Vue.prototype.$moment(String(date), sourceFormat).format(targetFormat);
	},

}