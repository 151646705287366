<template>
  <td :class="className">
    <popup-dialog v-model="showDialog" :data-item="dataItem" :data-fields="editor"></popup-dialog>
    <div class="gridLink" @click="clickHandler">{{dataItem[field]}}</div>
  </td>
</template>
<script>
export default {
  name: "PopupCell",
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  data: () => ({
    showDialog: false
  }),
  methods: {
    clickHandler() {
      this.showDialog = !this.showDialog;
    }
  }
};
</script>
<style scoped>
    .gridLink {
        color: #002B49;
        cursor: pointer;
        text-decoration: underline;
        text-decoration-color: #002B49;
        text-shadow: 0 0 1px #40C1AC;
    }

        .gridLink:hover {
            color: #29897d;
        }
</style>