import Vue from 'vue';
import { defineStore } from 'pinia';
//this.$common
export const useCommonStore = defineStore('common', {
    state: () => ({
        tollgates: [],
    }),
    actions: {
        setTollgates(tollgates) {
            this.tollgates = tollgates;
        },
        getTollgates() {
            return this.tollgates;
        },
    },
})