import axios from 'axios';
import Vue from 'vue';

class RoutingService {
    getByLatLng(start, end) {
        return axios.get('https://route.ls.hereapi.com/routing/7.2/calculateroute.json', {
            params: {
                apiKey: appConfig.Map.HereApiKey,
                mode: "fastest;car;traffic:disabled",
                waypoint0: `geo!${start.latitude},${start.longitude}`,
                waypoint1: `geo!${end.latitude},${end.longitude}`
            }
        });
    }
    getByLatLngV8(start, end) {
        return axios.get('https://router.hereapi.com/v8/routes', {
            params: {
                apiKey : appConfig.Map.PlatformHereApiKey, 
                transportMode:"car",
                origin: `${start.latitude},${start.longitude}`,
                destination: `${end.latitude},${end.longitude}`,
                return:"elevation,summary,polyline,instructions,actions",
            }
        });
    }
}

export default new RoutingService();