<template>
    <td :class="columnError" :title="columnTitle">
        <div class="gridLink" @click="clickHandler">
            <v-icon>{{icon}}</v-icon>
            {{dataItem.location.type.toUpperCase()}}
        </div>
    </td>
</template>

<script>
    export default {
        props: {
            field: String,
            dataItem: Object,
            format: String,
            className: String,
            columnIndex: Number,
            columnsCount: Number,
            rowType: String,
            level: Number,
            expanded: Boolean,
            editor: String
        },
        computed: {
            icon() {
                switch (this.dataItem.location.type) {
                    case "marker":
                        return "mdi-map-marker"
                    case "line":
                        return "mdi-vector-line"
                    case "circle":
                        return "mdi-circle-outline"
                    case "polygon":
                        return "mdi-vector-polygon"
                    default:
                        return ""
                }
            },
            columnError() {
                if (this.dataItem.location.typeError) {
                    return "critical"
                }
                return ""
            },
            columnTitle() {
                if (this.dataItem.location.typeErrorMessage) {
                    return this.dataItem.location.typeErrorMessage.errors
                }
                return ""
            }
        },
        methods: {
            clickHandler() {
                this.$emit(this.editor, this.dataItem);
            }
        }
    };
</script>
<style scoped>
    .critical {
        background-color: #fdd !important;
    }

    .gridLink {
        color: #002B49;
        cursor: pointer;
        text-decoration-color: #002B49;
        text-shadow: 0 0 1px #40C1AC;
    }

        .gridLink:hover {
            color: #29897d;
        }
</style>
