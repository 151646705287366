import axios from 'axios';

const devUser = "20ffdd3e-e783-40c1-bb78-296c90b5d0cc"  
const signalR = (config.VUE_APP_SignalR || process.env.VUE_APP_SignalR) 
const normalUrl = `https://{s}.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?apiKey=`
const terrianUrl = `https://{s}.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/terrain.day/{z}/{x}/{y}/256/png8?apiKey=`
const sateliteUrl = `https://{s}.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/256/png8?apiKey=`
const trafficUrl = `https://1.traffic.maps.ls.hereapi.com/maptile/2.1/flowtile/newest/normal.day/{z}/{x}/{y}/256/png8?apiKey=`
const pois = `https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?apiKey=`
const NearByPlaceUrl = `https://places.cit.ls.hereapi.com/places/v1/discover/around?apiKey=`
const StreetUrl = `https://2.base.maps.ls.hereapi.com/maptile/2.1/streettile/newest/normal.day/{z}/{x}/{y}/256/png8?apiKey=`


const StatusIconMapping = {
    "Driving":"green",
    "Excessive Idling":"yellow",
    "Stop":"red",
    "Ignition off":"red",
    "default":"normal",
     1:"orange",
     2:"yellow",
     0:"normal",
};
const StatusIconMapped = {
    "18":"green",
    "15":"yellow",
    "13":"red",
    "14":"red",   
    "default":"normal",
    "19":"normal",       
};
const StatusColorMapping = {
    1:"backcolor1",
    2:"backcolor1",
    3:"backcolor2",
    4:"backcolor2",
    5:"backcolor3",
    6:"backcolor4",
    7:"backcolor4",
    8:"backcolor6",
    9:"backcolor7",
    10:"backcolor4",
    11:"backcolor4",
    12:"backcolor9",
    13:"backcolor11",
    14:"backcolor11",
    15:"backcolor12",
    16:"backcolor13",
    17:"backcolor14",
    18:"backcolor15",
    19:"backcolor15",
    20:"backcolor4",
    21:"backcolor17",
    22:"backcolor17",
    23:"backcolor18",
    24:"backcolor11",
    25:"backcolor18",
    26:"backcolor18",
    27:"backcolor0",
    28:"backcolor18",
    29:"backcolor11",
    30:"backcolor11",
    40:"backcolor18",
    41:"backcolor18",
    42:"backcolor18",
    43:"backcolor18",
    44:"backcolor18",
    45:"backcolor18",
    46:"backcolor18",
    47:"backcolor11",
    48:"backcolor18",
    49:"backcolor18",
    50:"backcolor18",
    51:"backcolor18",
    52:"backcolor18",
    53:"backcolor18",
    54:"backcolor18",
    55:"backcolor18",
    56:"backcolor18",
    57:"backcolor18",
    58:"backcolor11",
    59:"backcolor18",
    60:"backcolor4",
    61:"backcolor4",
    62:"backcolor4",
    63:"backcolor11",
};
const StatusColorMappingHex = {
    1:"#FF0000",
    2:"#FF0000",
    3:"#808080",
    4:"#808080",
    5:"#FFC0CB",
    6:"turquoise",
    7:"turquoise",
    8:"#C80000",
    9:"#00FFFF",
    10:"turquoise",
    11:"turquoise",
    12:"#800080",
    13:"#800000",
    14:"#800000",
    15:"#00008B",
    16:"#0000FF",
    17:"#D3D3D3",
    18:"#7CFC00",
    19:"#7CFC00",
    20:"turquoise",
    21:"#556B2F",
    22:"#556B2F",
    23:"#008000",
    24:"#800000",
    25:"#008000",
    26:"#008000",
    27:"#fff",
    28:"#008000",
    29:"#800000",
    30:"#800000",
    40:"#008000",
    41:"#008000",
    42:"#008000",
    43:"#008000",
    44:"#008000",
    45:"#008000",
    46:"#008000",
    47:"#800000",
    48:"#008000",
    49:"#008000",
    50:"#008000",
    51:"#008000",
    52:"#008000",
    53:"#008000",
    54:"#008000",
    55:"#008000",
    56:"#008000",
    57:"#008000",
    58:"#800000",
    59:"#008000",
    60:"turquoise",
    61:"turquoise",
    62:"turquoise",
    63:"#800000",
};
const jwtPrivateKey = "Hierdie is die key wat ons moet vervang met certificates";
const siderBarRoutes = [
    'mySettingsRead',
    'companySettings',
    'manageusers',
    'snapshotRead',
    'listReportDashboardRead',
    'livemapRead',
    'replayRead',
    'videos',
    'myDashboard',
    'LPDRead',
    'alertActivityRead',
    'summary',
    'jobsRead',
    'scheduleRead',
    'driversRead',
    'vehiclesRead',
    'locationsRead',
    'manageAssigmentRead',
    'userRead',
    'contactRead',
    'bulkImportRead',
    'manageGroupsUpdate',
    'settings',
    'vehicleMaintenanceRead'
];
const applicationName = "Clarity Application";
const adminSecurityRoleName = "Customer Administrator"
const driverSecurityRoleName = "Operator";

export default {
  devUser: devUser,
  normalUrl: normalUrl,
  terrianUrl: terrianUrl,
  sateliteUrl: sateliteUrl,
  trafficUrl: trafficUrl,
  StreetUrl: StreetUrl,
  poiUrl: pois,
  NearByPlaceUrl: NearByPlaceUrl,
  StatusIconMapping: StatusIconMapping,
  StatusColorMapping: StatusColorMapping,
  StatusColorMappingHex: StatusColorMappingHex,
  StatusIconMapped: StatusIconMapped,
  SinglaR: signalR,
  CancelToken: axios.CancelToken.source(),
  jwtPrivateKey: jwtPrivateKey,
  siderBarRoutes: siderBarRoutes,
  applicationName: applicationName,
  adminSecurityRoleName,
  driverSecurityRoleName,
}; 
 
