import Vue from 'vue';
import { defineStore } from 'pinia'
import locationService from "@/service/locations.service";
const sortingRule = (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase());
export const useLocationStore = defineStore('locations', {
    state: () => ({
        allLocations :[],
        locations: [],
        tenantId: "",
    }),
    //$locationStore
    actions: {

        /** Reads location list from backend service and overwrites the current state
         * @param {[String]} ids
         */
        async getLocations(ids = null, addToStore = true) {
            if (ids && !Array.isArray(ids)) { ids = [ids] };
            return await locationService.searchByGroupIds(ids).then(locationData => {
                locationData = (locationData.items || []).sort(sortingRule);
                if (addToStore) { this.locations = locationData };
                this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
                return locationData;
            }).catch((err) => {
                Vue.prototype.$toast.error('API Failed to load Locations');
                this.locations = [];
                this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
                return [];
            });
        },
        /** Reads entire location list from backend service and overwrites the current state
         */
        async getAllLocations() {
            await locationService.search().then(locationData => {
                locationData = locationData.items || [];
                this.allLocations = locationData
                    //.filter(locations => locations.position != null)
                    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
                this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
            }).catch((err) => {
                Vue.prototype.$toast.error('API Failed to load All Locations');
                this.locations = [];
                this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
            });
        },

        /** Clears the current store       
         */
        async clearLocationStore() {
            this.locations = [];
            this.allLocations = [];
            this.tenantId = "";
        },

        /** Retreive the Store State if the TenantId Matches the store tenant         
         * @returns Array of Locations
         */
        getLocationsData() {
           let sessionTenant = (Vue.prototype.$session.get('tenantDetails')?.tenantId || '')
            return this.tenantId == sessionTenant
                ? this.locations
                : []
        },

        /** Retreive the Store State if the TenantId Matches the store tenant         
         * @returns Array of Locations
         */
        getAllLocationsData() {
           let sessionTenant = (Vue.prototype.$session.get('tenantDetails')?.tenantId || '')
            return this.tenantId == sessionTenant
                ? this.allLocations
                : []
        },

        /** Removes locations from state based on Ids provided         
         * @param {[String]} ids
         */
        removeLocationsById(ids) {
            this.locations = [
                ...this.locations
                    .filter(i => !ids.includes(i.id))
                    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
            ];
            Vue.prototype.$livemapEventHub.$emit('refreshLocations');
        },

        /** Upserts store states based on provided Ids
         * @param {[String]} ids
         */
        async addLocationsByIds(ids) {
            let self = this;
            if (ids && !Array.isArray(ids)) { ids = [ids] };
            await locationService.searchByGroupIds(ids).then(locationData => {
                let stripIds = [...(Array.isArray(ids) ? ids : []), ...locationData.items.map(i => i.id)];
                //Locations
                self.locations = [...new Set([
                    ...self.locations.filter(i => !stripIds.includes(i.id)),
                    ...locationData.items])
                ].sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
                //All Locations
                self.allLocations = [...new Set([
                    ...self.allLocations.filter(i => !stripIds.includes(i.id)),
                    ...locationData.items])
                ].sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
                self.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
            }).catch((err) => {
                Vue.prototype.$toast.error('API Failed to load Locations');
                self.locations = [];
                self.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
            });
        },

        /** Upserts store states based on provided object array
         * @param {[locationEntry]} locationList
         */
        async addLocationsByObject(locationList) {
            //Locations
            if (!Array.isArray(locationList)) { locationList = [locationList] };
            let targetIds = locationList.map(i => i.id);
            this.locations = [...new Set([
                ...this.locations.filter(i => !targetIds.includes(i.id)),
                ...locationList
            ])].sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
            //All Locations
            this.allLocations = [
                ...this.allLocations.filter(i => !targetIds.includes(i.id)),
                ...locationList
            ].sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
        },

        /** Replaces state with provided array
         * @param {[locationDataEntry]} locationData
         */
        replaceLocationState(locationData) {
            if (!Array.isArray(locationData)) { locationData = [locationData] };
            this.locations = locationData.filter(locations => locations.position != null).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
            this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
        },
    },
})