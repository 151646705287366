import vehicleService from "@/service/vehicles.service";
import locationService from "@/service/locations.service";
import Vue from 'vue';

const state = () => ({
    assets: [],
    tenantId : "",
})

const getters = {
    getAssetsData: state => state.tenantId == Vue.prototype.$session.get('tenantDetails').tenantId ? state.assets : [],
}

const actions = {
    async getAssets({ commit }, ids) {
        await vehicleService.getAllVehicleSolutionByIds(ids, true).then(async assetsData => {
            await locationService.getAllVehicleLocationMapping().then(response => {
                assetsData.forEach((asset, index) => {
                    let locationData = response.data.filter(c => c.toAsset == asset.id)
                    if (locationData.length > 0) {
                        assetsData[index].assignedLocations = locationData.map(item => item.fromAsset)
                    } else {
                        assetsData[index].assignedLocations = []
                    }
                })
                commit('setAssets', assetsData.sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1)));
            })
            
        })
    },
    async clearAssetsStore({ commit }) {
        commit('setAssets', []);
    },
}

const mutations = {
    setAssets(state, assetsData) {
        state.assets = assetsData;
        state.tenantId = Vue.prototype.$session.get('tenantDetails') ? Vue.prototype.$session.get('tenantDetails').tenantId : '';
    },
}

export default {
    modules: {
        vehicleStore: {
            namespaced: true,
            state,
            getters,
            actions,
            mutations
        }
    }
};