<template>
  <InseegoDateTimePicker v-model="timeObject" :change="onChange" :format="formatted" v-bind="attrs"></InseegoDateTimePicker>
</template>

<script>
export default {
  name: "BaseDateTimePicker",
  inheritAttrs: false,
  props: {
    value: { required: true, default: new Date() },
    formatted: { required: false },
    format: { required: false }
  },
  data() {
    return {
      timeObject: null,
      attrs: null,
      formatUTC: false
    };
  },
  created() {
    this.timeObject = this.value ? this.getDateObject(this.value) : null;
    const { format, formatUTC, formatted, ...attrs } = this.$attrs;
    this.formatUTC = formatUTC;
    this.attrs = attrs;
  },
  methods: {
    getDateObject(value) {
      let type = typeof value;
      let date = null;

      if (type == "string") {
        date = this.$moment
          .tz(value, this.format, this.$getUserCurrentTimeZone())
          .utc()
          .toDate();
      } else {
        date = this.$moment
          .tz(value, this.$getUserCurrentTimeZone())
          .utc()
          .toDate();
      }
      return date;
    },
    onChange(value) {
      if (!Array.isArray(value)) {
        this.$emit("input", this.getFormattedDate(value));
        this.$emit("change", value);
      } else {
        const dateRange = {
          start: this.getFormattedDate(this.timeObject.start),
          end: this.getFormattedDate(this.timeObject.end)
        };
        this.$emit("input", dateRange);
        this.$emit("change", dateRange);
      }
    },
    getFormattedDate(date) {
      if (this.formatUTC == "true" || this.formatUTC == true) {
        return this.$moment
          .tz(date, this.$getUserTimeZone())
          .utc()
          .format(this.format.trim());
      }
      return this.$moment
        .tz(date, this.$getUserTimeZone())
        .format(this.format.trim());
    }
  }
};
</script>

<style scoped>
</style>>
