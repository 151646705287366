<template>
  <div class="ContinuousDrivingWidget ctrack-h-100" ref="ContinuousDriving">
    <div id="vueapp" class="vue-app ctrack-h-100">

    <!-- Continuous Driving -->
    <div class="dBehaviour-inner" :class="widgetColorClass">        
      <div class="dBehaviour-box-col">
        <div class="dBehaviour-box-icon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="29" height="27" viewBox="0 0 29 27"><path d="M956.97,213.008c.02-.577.04-1.1.056-1.63.05-1.77.108-3.542.143-5.314.01-.528.071-.741.368-.733,1.312.034,2.626.014,3.939.014.151,0,.305-.052.318.41.064,2.388.136,4.775.207,7.251Zm-7.483-.768c-.985-.018-1.971-.017-2.956-.015h-1.3a.809.809,0,0,1-.227-.078c.816-2.77,1.615-5.479,2.413-8.186q2.553-8.653,5.1-17.311c.135-.46.276-.667.554-.652.761.039,1.525.014,2.331.014-.046.253-.072.427-.108.594q-2.736,12.544-5.475,25.087c-.063.287-.1.549-.32.549Zm19.992-.03c-.106-.009-.263-.2-.3-.385q-2.8-12.741-5.576-25.5c-.013-.056-.014-.12-.031-.266a2.784,2.784,0,0,1,.34-.045c.218,0,.436,0,.654,0,.449,0,.9.006,1.348-.017.258-.014.4.148.536.6Q969.8,198,973.164,209.389c.266.9.529,1.8.834,2.841h-3.034c-.253,0-.506.006-.759.006S969.721,212.23,969.479,212.21Zm-12.123-11.6c.061-2.056.121-4.034.181-6.043h3.9c.06,2.015.12,4.008.18,6.043Zm.391-10.09c.042-1.485.084-2.943.127-4.455H961.1c.043,1.492.086,2.95.131,4.455Z" transform="translate(-944.999 -185.996)"/></svg>
        </div>
        <div class="dBehaviour-box-content">
          <h3>
            <span class="dBehaviour-box-value-icon" :class="widgetIcon"><svg v-if="widgetIcon!=''" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10"><path d="M638.392,193H628.62a.626.626,0,0,1-.527-.962c.213-.383,4.509-8.038,4.876-8.691a.6.6,0,0,1,1.059,0c.269.47,4.587,8.154,4.887,8.711A.623.623,0,0,1,638.392,193Z" transform="translate(-628 -183.028)" /></svg></span>{{widgetValue}}
          </h3>
          <p class="ctrack-text-truncate">{{widgetTitle?widgetTitle:'Continuous Driving'}}</p>
        </div>
      </div>
      
    </div>
    <!-- Continuous Driving -->  

    </div>    
  </div>
</template>

<script>
import dashboardService from "../../../service/dashboard.service";
import {
  getters
} from "../shared/observeDashboardFilter";

export default {
  name: 'continuousdrivingwidget',
  components: {},
  data: function () {
    return {
      widgetTitle: '',
      widgetValue: '',
      widgetIcon: '',
      widgetColorClass: ''
    }
  },
  props: {
    widgetData: Object
  },
  created() {
    this.getDriverContinuousDrivingWidgetData();
  },
  methods: {
    getDriverContinuousDrivingWidgetData() {
      let self = this;
      dashboardService.getWidgetData(this.widgetData.api, getters.getFilterParams()).then(returnResult => {
        self.widgetTitle = returnResult.hwTitle;
        self.widgetValue = returnResult.value + returnResult.type;
        self.widgetIcon = (returnResult.icon == "down") ? "md-chart-arrow-down" : (returnResult.icon == "up") ? "md-chart-arrow-up" : "";
        self.widgetColorClass = (returnResult.color == "green") ? "success-color" : (returnResult.color == "red") ? "error-color" : "";
      });

    }
  }

};

</script>
<style scoped>

/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
*{font-family: 'Roboto', sans-serif;}
.ctrack-h-100{height: 100%;}
.error-color .dBehaviour-box-col h3{color:#FA4B4B;}
.success-color .dBehaviour-box-col h3{color:#76CD24;}
[class*="md-chart-arrow"]{display:inline-block;vertical-align:middle;}
[class*="md-chart-arrow"] svg{display:block;}
.md-chart-arrow-down{transform:rotate(180deg); -webkit-transform:rotate(180deg); -moz-transform:rotate(180deg); -ms-transform:rotate(180deg); -o-transform:rotate(180deg);}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}

.dBehaviour-inner{display:inline-block;width:100%;height:100%;background-color:#FFF;padding:13px 20px;border-radius: 5px; box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, .05); -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, .05);}
.dBehaviour-box-row{display:inline-block;width:100%;padding-left:40px;position:relative;}
.dBehaviour-box-col{display:inline-block;vertical-align: middle;position:relative;width:100%;text-align:right;padding-left:40px;}
.dBehaviour-box-icon{position:absolute;color:#656565; left:0;top:50%;transform:translateY(-50%); -webkit-transform:translateY(-50%); -moz-transform:translateY(-50%); -ms-transform:translateY(-50%); -o-transform:translateY(-50%);}
.dBehaviour-box-content{display:block;width:100%;padding-left:5px;}
.dBehaviour-box-content h3{font-size:20px;font-weight:500;}
.dBehaviour-box-value-icon{margin-right:8px;}
.dBehaviour-box-content p{font-size:15px;color:#333333;font-weight:500;}


/* Responsive CSS */
@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1399px){}
@media only screen and (max-width:1299px){}
@media only screen and (max-width:1199px){}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){}
@media only screen and (max-width:767px){}
@media only screen and (max-width:575px){}

</style>
