import Vue from 'vue';


Vue.directive('numericOnly', {
  bind(el) {
    el.addEventListener('input', () => {
      let regex = /^[0-9+]*$/
      if (!regex.test(el.value)) {
        el.value = el.value.slice(0, -1)
      }
    })
  }
})

Vue.directive('contactNumber', {
  bind(el) {
    el.addEventListener('input', () => {
      let regex = /^[0-9+]*$/
      if (!regex.test(el.value)) {
        el.value = el.value.slice(0, -1)
      }
    })
  }
})

Vue.directive('restrict-input', {
  bind(el, binding, vnode) {

    const acceptType = el.accept || "";
    let pattern = el.pattern;
    if (!pattern && el.accept) {
      const acceptedFiles = acceptType.split(",").map(val => val.trim()).join("|");
      pattern = `([a-zA-Z0-9\s_\\.\-:])+(${acceptedFiles})$`
    }
    const regExpressions = {
      integer: /^[+-]?[0-9]*$/,
      decimal: /^[+-]?([0-9]*[.])?[0-9]+$/,
      words: /^[a-zA-Z0-9+ ]*$/,
      file: /(?!.*[.](?:)$).*/
    }
    // const minLength = el.minLength;
    const maxLength = el.maxLength || 0;
    let regex = null;

    var regParts = pattern.match(/^\/(.*?)\/([gim]*)$/);
    if (regParts) {
      regex = new RegExp(regParts[1], regParts[2]);
    } else if (pattern) {
      regex = new RegExp(pattern);
    }
    const value = binding.value;
    if (!regex && value) {
      regex = regExpressions[value];
    }
    const emit = (vnode, name, data) => {
      var handlers = (vnode.data && vnode.data.on) ||
        (vnode.componentOptions && vnode.componentOptions.listeners);

      if (handlers && handlers[name]) {
        handlers[name].fns(data);
      }
    }
    el.addEventListener('input', (inputEvent) => {
      const inputType = inputEvent.target.type
      const isTextType = inputType == 'text';
      const isNumberType = inputType == 'number';
      const isTextOrNumberType = isTextType || isNumberType;
      const isFileType = inputType == 'file';

      let valid = true;
      let inputValue = [];
      if (isTextOrNumberType) {
        inputValue.push(el.value);
      }
      else if (isFileType == true) {
        const files = el.files;
        Array.from(files).forEach(file => inputValue.push(file.name));
      }
      else {
        inputValue.push(el.value); // to be modified according to future requirements
      }

      if (regex) {
        const isValueArray = Array.isArray(inputValue)
        if (isValueArray) {
          inputValue.forEach(item => {
            if (!regex.test(item)) {
              valid = false;
            }
          })
        }
      }

      // Max length check for type Text
      if (isTextType) {
        let valueLength = el.value.length || 0;
        if (maxLength && maxLength > 0 && valueLength > maxLength) {
          valid = false;
        }
      }

      if (isNumberType) {
        let valueLength = el.value.length || 0;
        if (maxLength && maxLength > 0 && valueLength > maxLength) {
          el.value = el.value.slice(0, -1)
          inputEvent.target.value = el.value;
        }
      }

      if (valid === false && isTextOrNumberType) {
        const pressedKey = inputEvent.data;
        const position = el.value.indexOf(pressedKey);
        const strArray = [...el.value];
        strArray.splice(position, 1);
        el.value = strArray.join('');
        inputEvent.target.value = el.value;
      }

      if (isFileType) {
        const files = Array.from(el.files);
        let fileSizeError = false;
        let invalidFiles = [];
        if (maxLength > 0) {
          const maxFileSizeInKB = maxLength * 1024;
          invalidFiles = files.filter(file => file.size > maxFileSizeInKB);
          if (invalidFiles && invalidFiles.length > 0) {
            fileSizeError = true;
          }
        }
        if (valid === false) {
          emit(vnode, 'oninvalid', { files: files, error: 'Please select a valid file' })
        }
        else if (fileSizeError === true) {
          let sizeError = maxLength / 1024 < 1 ? `${maxLength}KB` : `${maxLength / 1024}MB`;
          emit(vnode, 'oninvalid', { files: invalidFiles, error: `Please select a file below ${sizeError}` })
        }
        else if (files.length > 0) {
          emit(vnode, 'onselect', files)
        }
      }
    })
    el.addEventListener('blur', () => {
      let valueLength = el.value.length || 0;
      const inputValue = el.value;
      if (inputValue && valueLength == 1 && inputValue == ('-')) {
        el.value = el.value.slice(0, -1)
      }
    })
  }
})

