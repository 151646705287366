import * as L from "leaflet";
import Constant from "../../../constant";

export default {
  data() {
    return {
        url: Constant.normalUrl + appConfig.Map.HereApiKey,
      attribution:
        '&copy; <a href="http://developer.here.com">Here Map</a> contributors',
    };
  },
  methods: {
    getMapRaw(id) {
      let container = L.DomUtil.get(id);
      if (container != null) {
          this.removeDanglingEvents(container, true);
          container._leaflet_id = null;
          container.innerHTML = "";
      }
      return L.map(id, {
        animate: false,
        zoom: 6,
        minZoom: 1,
        maxBoundsViscosity: 1,
        maxBounds: [L.latLng(-90, -200), L.latLng(90, 200)],
        zoomAnimation: false,
        fadeAnimation: false,
        markerZoomAnimation: false,
      });
    },
    getMap(id, center, zoom) {
      let map = this.getMapRaw(id);
      L.tileLayer(this.url, {
        attribution: this.attribution,
        maxZoom: 18,
      }).addTo(map);
      if (center !== undefined && zoom !== undefined) {
        map.setView(center, zoom);
      } else if (center !== undefined) {
        map.setView(center);
      }
      return map;
    },
    /* remove binded events which do not remove when map destroyed in close drawer */
    removeDanglingEvents(inputObj, checkPrefix) {
      if (inputObj !== null) {
        //Taken from the leaflet sourcecode directly, you can search for these constants and see how those events are attached, why they are never fully removed i don't know
        let msPointer = L.Browser.msPointer,
          POINTER_DOWN = msPointer ? "MSPointerDown" : "pointerdown",
          POINTER_MOVE = msPointer ? "MSPointerMove" : "pointermove",
          POINTER_UP = msPointer ? "MSPointerUp" : "pointerup",
          POINTER_CANCEL = msPointer ? "MSPointerCancel" : "pointercancel";

        for (let prop in inputObj) {
          let prefixOk = checkPrefix ? prop.indexOf("_leaflet_") !== -1 : true,
            propVal; //if we are in the _leaflet_events state kill everything, else only stuff that contains the string '_leaflet_'
          let dom = document.getElementById("mapshow");
          if (inputObj.hasOwnProperty(prop) && prefixOk) {
            //Map the names of the props to the events that were really attached => touchstart equals POINTER_DOWN etc
            let evt = [];
            if (prop.indexOf("touchstart") !== -1) {
              //indexOf because the prop names are really weird 'touchstarttouchstart36' etc
              evt = [POINTER_DOWN];
            } else if (prop.indexOf("touchmove") !== -1) {
              evt = [POINTER_MOVE];
            } else if (prop.indexOf("touchend") !== -1) {
              evt = [POINTER_UP, POINTER_CANCEL];
            }

            propVal = inputObj[prop];

            if (evt.length > 0 && typeof propVal === "function") {
              evt.forEach(function(domEvent) {
                if(dom)
                  dom.removeEventListener(domEvent, propVal, false);
              });
            }

            //Reference B-GONE, Garbage b collected.
            inputObj[prop] = null;
            delete inputObj[prop];
          }
        }
      }
    },
  },
};