import Vue from 'vue'
import Vuex from 'vuex'
import config from './config.store';
import livemap from './livemap.store';
import lpd from './live-productivity-dashboard.store';
import vehicleReports from './vehicle-reports.store';
import driverReports from './driver-reports.store';
import replay from './replay.store';
import report from './report.store';
import snapshots from './snapshot.store';
import vehicle from './vehicle.store';
import driver from './driver.store';
import claims from './claims.store';
import video from './video.store';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        config,
        livemap,
        vehicleReports,
        driverReports,
        snapshots,
        replay,
        report,
        vehicle,
        driver,
        lpd,
        claims,
        video
    }
})