<template>
    <div class="grid-container">
        <grid-pdf-export ref="exporter" :margin="'1cm'">
            <Grid ref="dataGrid"
                  :style="customStyle"
                  :data-items="result"
                  :pageable="pageable"
                  :filterable="true"
                  :filter="filter"
                  :sortable="sortable"
                  :skip="skip"
                  :sort="sort"
                  :take="take"
                  :total="total"
                  :columns="gridColumns"
                  :selected-field="'selected'"
                  :column-menu="true"
                  :reorderable="true"
                  :resizable="true"
                  @columnreorder="columnReorder"
                  @columnresize="columnResize"
                  @headerselectionchange="onHeaderSelectionChange"
                  @selectionchange="onSelectionChange"
                  @rowclick="rowclick"
                  @filterchange="filterChange"
                  @sortchange="sortChangeHandler"
                  @pagechange="pageChangeHandler"
                  @navigate="navigate"
                  @vehicleIdClick="vehicleIdClick"
                  @driverIdClick="driverIdClick"
                  @locationIdClick="locationIdClick">
                <!--<grid-toolbar>
                  <div class="DriverSettingCont">
                      <div class="bottomRightButtonsContainer" :class="{joblist: joblist}">-->
                <!--<export-email-dialog v-model="showEmailDialog" :send-email="exportEmail"></export-email-dialog>-->
                <!--<emailDialog v-model="showEmailDialog" :send-email="exportEmail"></emailDialog>-->
                <!-- <md-button
                  v-if="$checkRBACAccess('driversDelete')"
                  v-show="selected.length > 0"
                  class="md-icon-button"
                  @click="showDeleteDialog =!showDeleteDialog"
                >
                  <md-icon class="whiteIcon">delete</md-icon>
                </md-button> -->
                <!--<v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon dark
                               v-bind="attrs"
                               v-on="on"
                               v-show="selected.length > 0">
                            <v-icon>mdi-export-variant</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="exportPDF">
                            <v-list-item-title><v-icon>mdi-file-pdf-box</v-icon>{{ $t("Location.exportPDF") }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="exportExcel">
                            <v-list-item-title><v-icon>mdi-file-excel</v-icon>{{ $t("Location.exportExcel") }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="showEmailDialog=!showEmailDialog">
                            <v-list-item-title><v-icon>mdi-email</v-icon>{{ $t("LocationLabels.exportToEmail") }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>-->
                <!--<md-menu v-show="selected.length > 0">
                    <md-button class="md-icon-button" md-menu-trigger>
                        <md-icon class="whiteIcon">save_alt</md-icon>
                    </md-button>
                    <md-menu-content>
                        <md-menu-item @click="exportPDF">{{$tc("DriverLabels.exportToPDF")}}</md-menu-item>
                        <md-menu-item @click="exportExcel">{{$tc("DriverLabels.exportToexcel")}}</md-menu-item>
                        <md-menu-item @click="showEmailDialog=!showEmailDialog">{{$tc("DriverLabels.exportToEmail")}}</md-menu-item>
                    </md-menu-content>
                </md-menu>-->
                <!--<ColumnSelector v-if="showColumnCofig"
                                    :columns.sync="columns"
                                    :getDefaults="getColumns"
                                    :persist="setColumnConfig"></ColumnSelector>
                </div>
                </div>-->
                <!-- <div class="fullHeading headinNewHight">
                  <div class="full_flexHeading" style="padding-bottom: 0;">
                    <div class="freeSpace"></div>

                  </div>
                </div> -->
                <!--</grid-toolbar>-->
            </Grid>
        </grid-pdf-export>
    </div>
</template>
<script>

    import {
        ImageCell,
        DataCell,
        StatusCell,
        NavigateCell,
        ColumnSelector,
        GridOptions
    } from "../../views/Shared/index";

    import emailDialog from "@/components/dialogs/email-dialog.vue"

    export default {
        name: "app-grid",
        components: {
            ColumnSelector,
            emailDialog
        },
        mixins: [GridOptions],
        watch: {
            selected(value) {
                this.$emit('input', value.map((c) => c.id));
            },
            refreshData: {
                immediate: true,
                handler(value) {
                    if (value) {
                        this.refreshGrid();
                    }
                }
            }
        },
        props: {
            search: {
                type: Function,
                required: false,
                default: () => {
                }
            },
            navigate: {
                type: Function,
                required: false,
                default: () => { }
            },
            rowclick: {
                type: Function,
                required: false,
                default: () => { }
            },
            columns: {
                type: Array,
                required: true,
                default: function () {
                    return [];
                }
            },
            value: {
                required: false,
                default: () => {
                    return [];
                }
            },
            defaultSort: {
                required: false,
                default: () => {
                    return [];
                }
            },
            vehicleIdClick: {
                type: Function,
                required: false,
                default: () => { }
            },
            driverIdClick: {
                type: Function,
                required: false,
                default: () => { }
            },
            locationIdClick: {
                type: Function,
                required: false,
                default: () => { }
            },
            getColumns: {
                type: Function,
                required: false,
                default: () => { }
            },
            showColumnCofig:
            {
                type: Boolean,
                required: false,
                default: false
            },
            joblist:
            {
                type: Boolean,
                required: false,
                default: false
            },
            customStyle:
            {
                type: String,
                required: false,
                default: "height: calc(100vh - 236px);"
            },
            refreshData: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                gridColumns: [],
                showEmailDialog: false
            };
        },
        created() {
            this.$gridEventHub.$on('refreshGrid', this.refreshGrid);
            if (this.defaultSort && this.defaultSort.length) {
                this.sort = this.defaultSort;
            }
            this.search().then((result) => {
                result.data.forEach((value) => {
                    if (value.selected === undefined) {
                        value.selected = false;
                    }
                });
                this.dataItems = result.data;
            });

            this.gridColumns = [...this.columns];
            var IsAreadySelectColumn = this.gridColumns.filter(t => t.field === "selected")

            if (IsAreadySelectColumn.length == 0) {
                this.gridColumns.unshift(
                    {
                        field: "selected",
                        width: "50px",
                        columnMenu: false,
                        reorderable: false,
                        resizable: false
                    });
            }

        },
        methods: {
            refreshGrid() {
                this.search().then((result) => {
                    result.data.forEach((value) => {
                        value.selected = false;
                    });
                    this.dataItems = result.data;
                });
            }
        },
        beforeDestroy() {
            this.$gridEventHub.$off('refreshGrid', this.refreshGrid);
        },
    };
</script>
<style scoped>
    .DriverSettingCont {
        position: fixed;
        right: 15px;
        margin-top: -40px;
    }
</style>