import { useGroupsFilterStore } from '@/stores/groups-store';
import axios from 'axios';

class VehicleMaintenanceService {

    //Service Plan
    getServcePlanDetailById(id) {
        return axios.get('/api/AssetMaintenance/ServicePlan/GetVehicleServicePlanById/' + id);
    }
    getOtherTypeServicePlans() {
        return axios.get('/api/AssetMaintenance/ServicePlan/GetAllServiceType');
    }
    getVehicleServicePlanData(params) {
        return axios.get('/api/AssetMaintenance/ServicePlan/VehicleServicePlanData/', { params: params });
    }
    deleteServicePlan(id) {
        return axios.delete('/api/AssetMaintenance/ServicePlan/Delete/' + id);
    }
    getServicePlanLists(ids) {
        const groupFilterStore = useGroupsFilterStore();
        return axios.post('/api/AssetMaintenance/ServicePlan/GetList', ids ? ids : groupFilterStore.vehicles);
    }
    editServicePlan(body) {
        return axios.post('/api/AssetMaintenance/ServicePlan/Update', body);
    }
    createServicePlan(body) {
        return axios.post('/api/AssetMaintenance/ServicePlan/Create', body);
    }
    completeServicePlan(body, id) {
        return axios.post('/api/AssetMaintenance/ServicePlan/ServiceComplete/' + id, body);
    }
    addServicePlanTypeOtherNew(body) {
        return axios.post('/api/AssetMaintenance/ServicePlan/CreateServicePlanType', body);
    }
    saveServicePlanNote(body, id) {
        return axios.post('/api/AssetMaintenance/ServicePlan/saveServicePlanNote/' + id, body);
    }
    
    //Service History
    getServiceHistoryLists(params, body) {
        return axios.post('/api/AssetMaintenance/ServiceHistory/GetList', body, { params: params } );
    }

    //Licensing
    getLicensingDetail(id) {
        return axios.get('/api/AssetMaintenance/VehicleLicensing/GetVehicleLicensingById/' + id);
    }
    getOtherTypeLicenseTypes() {
        return axios.get('/api/AssetMaintenance/VehicleLicensing/GetAllLicenseType');
    }
    getVehicleLicensingData(params) {
        return axios.get('/api/AssetMaintenance/VehicleLicensing/CheckLicensingIsExit', { params: params });
    }
    deleteLicensing(id) {
        return axios.delete('/api/AssetMaintenance/VehicleLicensing/Delete/' + id);
    }
    editLicensing(body, id) {
        return axios.post('/api/AssetMaintenance/VehicleLicensing/Update/' + id, body);
    }
    getLicensingLists(ids) {
        const groupFilterStore = useGroupsFilterStore();
        return axios.post('/api/AssetMaintenance/VehicleLicensing/GetList', ids ? ids : groupFilterStore.vehicles);
    }
    completeLicnesing(body, id) {
        return axios.post('/api/AssetMaintenance/VehicleLicensing/Complete/' + id, body);
    }
    createLicensing(body) {
        return axios.post('/api/AssetMaintenance/VehicleLicensing/Create', body);
    }
    addLicenseTypeOtherNew(body) {
        return axios.post('/api/AssetMaintenance/VehicleLicensing/CreateLicenseType', body);
    }
    //Operators
    GetOperatorLicensingLists(ids) {
        const groupFilterStore = useGroupsFilterStore();
        return axios.post('/api/AssetMaintenance/OperatorLicensing/GetOperatorLicensingLists', ids ? ids : groupFilterStore.drivers);
    }
    GetDriverLicensingById(id) {
        return axios.get(`/api/AssetMaintenance/OperatorLicensing/GetDriverLicensingById/${id}`);
    }
    CheckLicensingIsExisting(params) {
        return axios.get(`/api/AssetMaintenance/OperatorLicensing/CheckLicensingIsExisting`, { params: params });
    }
    CreateOperatorLicensing(model) {
        return axios.post(`/api/AssetMaintenance/OperatorLicensing/Create`, model);
    }
    EditOperatorLicensing(id, model) {
        return axios.post(`/api/AssetMaintenance/OperatorLicensing/Edit/${id}`, model);
    }
    DeleteOperatorLicensing(id) {
        return axios.delete(`/api/AssetMaintenance/OperatorLicensing/Delete/${id}`);
    }
    CompleteOperatorLicensing(id, model) {
        return axios.post(`/api/AssetMaintenance/OperatorLicensing/Complete/${id}`, model);
    }

    //Inspection Lists
    getInspectionDetail(id) {
        return axios.get('/api/AssetMaintenance/VehicleInspection/GetVehicleInspection/' + id);
    }
    getInspectionLists(params, ids) {
        const groupFilterStore = useGroupsFilterStore();
        return axios.post('/api/AssetMaintenance/VehicleInspection/GetList', ids ? ids : groupFilterStore.vehicles, { params: params });
    }

    //azure blob
    downloadFile(url) {
        var blob = url.split('/');
        blob.splice(0, 3);
        var container = blob.shift();
        var image = blob.join('/')
        url = `/api/image?container=${container}&image=${image}`
        return fetch(url)
            .then(response => response.arrayBuffer());
    }
}

export default new VehicleMaintenanceService();