<template>
    <td :class="className">
        <v-chip v-for="entry in displayText" :key="entry"
                class="chipStyle">
            {{entry}}
        </v-chip>
        <v-chip v-if="containsOutside"
                class="chipStyle">
            {{$t('VehicleLabels.assignment.outsideGroup')}}
        </v-chip>
    </td>
</template>
<script>
    export default {
        name: "assignmentCell",
        props: {
            field: String,
            dataItem: Object,
            format: String,
            className: String,
            columnIndex: Number,
            columnsCount: Number,
            rowType: String,
            level: Number,
            expanded: Boolean,
            editor: String
        },
        computed: {
            value() {
                return this.dataItem[this.field];
            },
            chipList() {
                return this.value
                    ? Array.isArray(this.value)
                        ? this.value.sort()
                        : this.value.includes(',')
                            ? this.value.split(',').sort()
                            : [this.value]
                    : []
            },
            containsOutside() {
                return this.chipList.includes(this.$t('VehicleLabels.assignment.outsideGroup'));
            },
            displayText() {
                switch (this.chipList.filter(i => i != this.$t('VehicleLabels.assignment.outsideGroup')).length) {
                    case 0:
                        return [];
                        break;
                    case 1:
                        return [this.chipList[0]];
                        break;
                    default:
                        return [this.$t('assignment.multiple')];
                        break;
                };
            },
        },
    };
</script>
<style scoped>
    .chipStyle {
        background: #40C1AC !important;
        color: #002B49;
        margin: 2px;
    }
</style>
