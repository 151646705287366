import Vue from 'vue';

class DateUtil {

    configProperties() {
        return Vue.prototype.$session.get("configProperties");
    }

    getUserDateFormat() {
        return this.configProperties().dateFormat.toUpperCase();
    }

    getUserTimeFormat() {
        return 'HH:mm';//this.configProperties().timeFormat; //Fixed as per V1.11
    }

    getUserDateTimeFormat() {
        return `${this.getUserDateFormat()} ${this.getUserTimeFormat()}`;
    }

    getServiceDateFormat() {
        const format = process.env.VUE_APP_DateFormat.toUpperCase();
        return format;
    }

    getServiceTimeFormat() {
        const format = process.env.VUE_APP_TimeFormat;
        return format;
    }

    getServiceDateTimeFormat() {
        const format = process.env.VUE_APP_DateTimeFormat;
        return format;
    }

    getUserTimeZone() {
        return this.configProperties().timeZone;
    }

    getUserCurrentTimeZone() {
        return Vue.prototype.$moment.tz.guess();
    }

    setDefaultZone() {
        const zone = this.configProperties().timeZone;
        Vue.prototype.$moment.tz.setDefault(zone);
    }

    formatUTCDateTime(value, targetFormat, sourceFormat) {
        this.setDefaultZone();
        if (!sourceFormat) {
            sourceFormat = process.env.VUE_APP_DateTimeFormat;
        }
        if (!targetFormat) {
            targetFormat = this.getUserDateTimeFormat();
        }
        let date = this.formatUTCDateTimeToUserTz(value, targetFormat, sourceFormat);
        return date;
    }

    formatDateTime(value, targetFormat, sourceFormat) {
        this.setDefaultZone();
        if (!sourceFormat) {
            sourceFormat = process.env.VUE_APP_DateTimeFormat;
        }
        if (!targetFormat) {
            targetFormat = this.getUserDateTimeFormat();
        }
        let date = this.formattedDateTime(value, targetFormat, sourceFormat);
        return date;
    }

    formatDate(value, targetFormat, sourceFormat) {
        this.setDefaultZone();
        if (!sourceFormat) {
            sourceFormat = process.env.VUE_APP_DateFormat;
        }
        if (!targetFormat) {
            targetFormat = this.getUserDateFormat();
        }
        let date = this.formattedDateTime(value, targetFormat, sourceFormat);
        return date;
    }

    formatTime(value, targetFormat, sourceFormat) {
        this.setDefaultZone();
        if (!sourceFormat) {
            sourceFormat = process.env.VUE_APP_TimeFormat;
        }
        if (!targetFormat) {
            targetFormat = this.getUserTimeFormat();
        }
        let date = this.formattedDateTime(value, targetFormat, sourceFormat);
        return date;
    }

    formatLocalTime(value, targetFormat, sourceFormat) {
        this.setDefaultZone();
        if (!sourceFormat) {
            sourceFormat = process.env.VUE_APP_DateTimeFormat;
        }
        if (!targetFormat) {
            targetFormat = this.getUserDateTimeFormat();
        }
        let date = this.formatUTCDateTimeToUserTz(value, targetFormat, sourceFormat);
        return date;
    }

    formatDateTimeForOrderBy() {
        return `${this.getServiceDateFormat()} ${this.getUserTimeFormat()}`;
    }

    formatUTCDate(value, targetFormat, sourceFormat) {
        this.setDefaultZone();
        if (!sourceFormat) {
            sourceFormat = process.env.VUE_APP_DateFormat;
        }
        if (!targetFormat) {
            targetFormat = this.getUserDateFormat();
        }
        let date = this.formatUTCDateTime(value, targetFormat, sourceFormat);
        return date;
    }

    formatUTCTime(value, targetFormat, sourceFormat) {
        this.setDefaultZone();
        if (!sourceFormat) {
            sourceFormat = process.env.VUE_APP_DateTimeFormat;
        }
        if (!targetFormat) {
            targetFormat = "HH:mm" || this.getUserTimeFormat();
        }
        let date = this.formatUTCDateTime(value, targetFormat, sourceFormat);
        return date;
    }

    formatToServiceDate(value, targetFormat, sourceFormat) {
        this.setDefaultZone();
        const zone = this.configProperties().timeZone;
        if (!sourceFormat) {
            sourceFormat = process.env.VUE_APP_DateTimeFormat;
        }
        if (!targetFormat) {
            targetFormat = process.env.VUE_APP_DateTimeFormat;
        }
        return Vue.prototype.$moment.tz(Number(value), zone).utc().format(targetFormat);
        // return this.formatUTCDateTimeToUserTz(value, targetFormat, sourceFormat);
    }

    convertUTCDateToMillis(str, sourceFormat) {
        this.setDefaultZone();
        const targetFormat = process.env.VUE_APP_DateTimeFormat;
        const formattedDateTime = this.formatUTCDateTime(str, targetFormat, sourceFormat); // User selected tz sourceFormat initialized
        return Vue.prototype.$moment.utc(String(formattedDateTime), targetFormat).format('x');
    }

    // Assuming value is UTC date and no tz conversion required
    formattedDateTime(value, targetFormat, sourceFormat) {
        let formattedDateTime = '';
        let type = typeof value;
        if (type == 'string') {
            if (Number(value) != NaN) {
                type = 'number';
                value = Number(value);
            }
            else {
                formattedDateTime = Vue.prototype.$moment.utc(value, sourceFormat).format(targetFormat);
            }
        }
        if (type == 'number') {
            formattedDateTime = Vue.prototype.$moment(value).format(targetFormat);
        }
        if (type == 'object' && value instanceof Date) {
            const datetimeFormat = process.env.VUE_APP_DateTimeFormat;
            const formattedDate = Vue.prototype.$moment.utc(value).format(datetimeFormat);
            formattedDateTime = Vue.prototype.$moment.utc(formattedDate, datetimeFormat).format(targetFormat);
        }
        return formattedDateTime;
    }

    formatUTCDateToMillis(value, targetFormat, sourceFormat) {
        if (targetFormat == 'x') {
            const format = "YYYY-MM-DDTHH:mm:ss";
            let dateTime = Vue.prototype.$moment.utc(value, sourceFormat).tz(this.getUserTimeZone()).format(format);
            return new Date(dateTime).getTime();
        }
    }

    // date value should be of UTC timezone 
    formatUTCDateTimeToUserTz(value, targetFormat, sourceFormat) {
        let formattedDateTime = '';
        const zone = this.configProperties().timeZone;
        let type = typeof value;
        if (type == 'string') {
            if (!isNaN(value)) {
                type = 'number';
                value = Number(value);
            }
            else {
                if (targetFormat == 'x') {
                    return this.formatUTCDateToMillis(value, targetFormat, sourceFormat);
                }
                formattedDateTime = Vue.prototype.$moment.utc(value, sourceFormat).tz(zone).format(targetFormat);
            }
        }
        if (type == 'number') {
            if (targetFormat == 'x') {
                return this.formatUTCDateToMillis(value, targetFormat, sourceFormat);
            }
            formattedDateTime = Vue.prototype.$moment.utc(value).tz(zone).format(targetFormat);
        }
        if (type == 'object' && value instanceof Date) {
            const datetimeFormat = process.env.VUE_APP_DateTimeFormat;
            const formattedDate = Vue.prototype.$moment.utc(value).format(datetimeFormat);
            if (targetFormat == 'x') {
                return this.formatUTCDateToMillis(formattedDate, targetFormat, datetimeFormat);
            }
            formattedDateTime = Vue.prototype.$moment.utc(formattedDate, datetimeFormat).tz(zone).format(targetFormat);
        }
        return formattedDateTime;
    }

    getDateObjectFromUTC(value, sourceFormat) {

        let utcDateTime = value;

        if (sourceFormat == 'x') {
            utcDateTime = this.formatToServiceDate(utcDateTime);
            sourceFormat = null;
        }

        if (!sourceFormat) {
            sourceFormat = process.env.VUE_APP_DateTimeFormat;
        }
        const datetime = Vue.prototype.$moment
            .utc(utcDateTime, sourceFormat)
            .tz(this.getUserTimeZone())
            .format(sourceFormat);

        return Vue.prototype.$moment
            .tz(datetime, sourceFormat, this.getUserCurrentTimeZone())
            .toDate()

    }

    getDateObjectFromUTCToTimezone(value){
        let momentDate = Vue.prototype.$moment.utc(value).format('YYYY-MM-DDTHH:mm:ss');
        let date = Vue.prototype.$moment(momentDate).tz(this.getUserTimeZone());
        date = Vue.prototype.$moment.utc(date).tz(this.getUserCurrentTimeZone()).format('YYYY-MM-DDTHH:mm:ss');
        return date
    }
}

export default new DateUtil();