import axios from 'axios';

class InsightsService {
    getApplicationInsightsData(userId, organisationId) {
        let params = {
            userId: userId,
            organisationId: organisationId
        };
        return axios.get("/api/insights/GetApplicationInsightsData", { params: params })
    }
}

export default new InsightsService();