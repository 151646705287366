<template>
    <td :class="className">
        <div class="gridLink" @click.stop="clickHandler">
            {{dataItem[field]}}
        </div>
    </td>
</template>
<script>
export default {
  name: "NavigateCell",
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  methods:{
    clickHandler() {
      this.$emit(this.editor, this.dataItem);
    }
  }
};
</script>
<style scoped>
    .gridLink {
        color: #002B49;
        cursor: pointer;
        text-decoration: underline;
        text-decoration-color: #002B49;
        text-shadow: 0 0 1px #40C1AC;
    }
        .gridLink:hover {
            color: #29897d;
        }
</style>