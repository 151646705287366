<template>
  <div class="IdlingWidget ctrack-h-100" ref="Idling">
    <div id="vueapp" class="vue-app ctrack-h-100">

      <!-- Idling Widget -->
      <div class="dBehaviour-inner" :class="widgetColorClass">        
        <div class="dBehaviour-box-col">
          <div class="dBehaviour-box-icon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="39" height="23.104" viewBox="0 0 39 23.104"><path d="M756.642,198.095a5.05,5.05,0,0,0-6.525.481,2.193,2.193,0,0,1-1.435.2,3.106,3.106,0,0,0-3.211,1.439,2.641,2.641,0,0,0,.087,3.13,3.126,3.126,0,0,0,3.417,1.293,1.771,1.771,0,0,1,1.237.288c2.444,1.765,3.883,1.848,6.989.066a4.046,4.046,0,0,0,.552.947,6.676,6.676,0,0,0,10.335-.6,1.408,1.408,0,0,1,1.9-.58,10.293,10.293,0,0,0,11.167-2.792,9.222,9.222,0,0,0,1.944-10.656c-1.7-3.961-4.976-6-9.38-6.291-5-.334-9.92,3.432-10.712,8.2-.222,1.335-.476,2.15-2.18,2.482C759.415,195.982,758.191,197.168,756.642,198.095Zm20.163,2.14-.345.128-2.579-4.83-2.488,1.11c-.48-2.161-.919-4.136-1.358-6.111l.387-.134,2.416,4.373,2.46-1.049ZM761.119,201.8l1.412-3.643.221.091v3.06l1.495.377-1.368,3.479-.314-.08c.06-.932.119-1.863.182-2.839Zm-8.507-1.6a6.584,6.584,0,0,1,1.525,1.177,7,7,0,0,1,.662,1.842C754.144,202.087,751.96,201.889,752.612,200.2Z" transform="translate(-745.001 -185.003)" /></svg></div>
          <div class="dBehaviour-box-content">
            <h3>
              <span class="dBehaviour-box-value-icon" :class="widgetIcon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10"><path d="M638.392,193H628.62a.626.626,0,0,1-.527-.962c.213-.383,4.509-8.038,4.876-8.691a.6.6,0,0,1,1.059,0c.269.47,4.587,8.154,4.887,8.711A.623.623,0,0,1,638.392,193Z" transform="translate(-628 -183.028)" /></svg></span>{{widgetValue}}
            </h3>
            <p class="ctrack-text-truncate">{{widgetTitle}}</p>
          </div>
        </div>        
      </div>
      <!-- Idling Widget -->

    </div>    
  </div>
</template>

<script>
export default {
  name: 'idlingwidget',
  components: {},
  data: function() {
        return {}
  },  
  created(){
        this.getIdlingData();        
  },
  methods: {
        getIdlingData(){
            let self = this;
            const returnResult = {
                title: "Idling",
                value: "20",
                type:"%",
                icon: "up",
                color: "red"
            };
            
            self.widgetTitle = returnResult.title;
            self.widgetValue = returnResult.value + returnResult.type;
            self.widgetIcon =  (returnResult.icon == "down") ? "md-chart-arrow-down" : "md-chart-arrow-up";
            self.widgetColorClass = (returnResult.color == "green") ? "success-color" : "error-color";
        }
  }
};

</script>
<style scoped>

/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
*{font-family: 'Roboto', sans-serif;}
.ctrack-h-100{height: 100%;}
.error-color .dBehaviour-box-col h3{color:#FA4B4B;}
.success-color .dBehaviour-box-col h3{color:#76CD24;}
[class*="md-chart-arrow"]{display:inline-block;vertical-align:middle;}
[class*="md-chart-arrow"] svg{display:block;}
.md-chart-arrow-down{transform:rotate(180deg); -webkit-transform:rotate(180deg); -moz-transform:rotate(180deg); -ms-transform:rotate(180deg); -o-transform:rotate(180deg);}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}

.dBehaviour-inner{display:inline-block;width:100%;height:100%;background-color:#FFF;padding:13px 20px;border-radius: 5px; box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, .05); -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, .05);}
.dBehaviour-box-row{display:inline-block;width:100%;padding-left:40px;position:relative;}
.dBehaviour-box-col{display:inline-block;vertical-align: middle;position:relative;width:100%;text-align:right;padding-left:40px;}
.dBehaviour-box-icon{position:absolute;color:#656565; left:0;top:50%;transform:translateY(-50%); -webkit-transform:translateY(-50%); -moz-transform:translateY(-50%); -ms-transform:translateY(-50%); -o-transform:translateY(-50%);}
.dBehaviour-box-content{display:block;width:100%;padding-left:5px;}
.dBehaviour-box-content h3{font-size:20px;font-weight:500;}
.dBehaviour-box-value-icon{margin-right:8px;}
.dBehaviour-box-content p{font-size:15px;color:#333333;font-weight:500;}


/* Responsive CSS */
@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1399px){}
@media only screen and (max-width:1299px){}
@media only screen and (max-width:1199px){}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){}
@media only screen and (max-width:767px){}
@media only screen and (max-width:575px){}

</style>
