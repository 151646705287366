<template>
  <div class="OverspeedingEventsVsMPGChart ctrack-h-100" ref="OverspeedingEventsVsMPG">
    <div id="vueapp" class="vue-app ctrack-h-100">

      <!-- Overspeeding Events Vs MPG Chart -->
      <div class="cd-chart-innerbox">        
        <div class="cd-chart-box-top">
          <div class="cd-chart-box-header">
            <div class="cd-chart-box-col1"><h3>{{chartTitle}}<!-- Overspeeding Events Vs MPG --></h3></div>
            <div class="cd-chart-box-col2">
                <div class="md-view-detail-link">
                    <svg version="1.1" width="20" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 89.64 90.91" style="enable-background:new 0 0 89.64 90.91;" xml:space="preserve"><g>
                        <path d="M5.93,84.99c27.97,0,55.8,0,83.71,0c0,2,0,3.93,0,5.92c-29.87,0-59.73,0-89.64,0C0,60.64,0,30.4,0,0.09
                        c1.96,0,3.91,0,5.93,0C5.93,28.38,5.93,56.61,5.93,84.99z"/>
                        <path d="M67.5,80.38c0-21.98,0-43.9,0-65.87c3.52,0,7,0,10.53,0c0,21.97,0,43.88,0,65.87C74.53,80.38,71.06,80.38,67.5,80.38z"/>
                        <path d="M30.45,25.58c-1.48,1.49-2.94,2.95-4.4,4.42c-2.77,2.77-5.54,5.53-8.3,8.31c-0.93,0.94-2.01,1.29-3.27,0.94
                        c-2.07-0.58-2.86-3.07-1.52-4.76c0.32-0.4,0.71-0.76,1.07-1.12c3.77-3.78,7.54-7.56,11.33-11.31c0.53-0.52,0.66-0.87,0.28-1.62
                        C23,15.15,23.27,9.97,26.97,5.33c3.65-4.58,8.59-6.28,14.29-4.83c5.75,1.46,9.26,5.35,10.22,11.18c0.89,5.44-0.86,10.1-5.41,13.36
                        c-4.63,3.33-9.62,3.59-14.74,1.02C31.07,25.94,30.83,25.79,30.45,25.58z M29.98,13.82c0,4.37,3.46,7.85,7.79,7.86
                        c4.42,0.01,7.94-3.46,7.95-7.82c0.01-4.27-3.54-7.74-7.91-7.75C33.48,6.1,29.98,9.55,29.98,13.82z"/>
                        <path d="M41.48,35.67c3.53,0,6.97,0,10.49,0c0,14.87,0,29.69,0,44.59c-3.48,0-6.95,0-10.49,0C41.48,65.41,41.48,50.59,41.48,35.67z
                        "/>
                        <path d="M26.12,80.4c-3.5,0-6.94,0-10.46,0c0-8.75,0-17.46,0-26.24c3.45,0,6.92,0,10.46,0C26.12,62.87,26.12,71.58,26.12,80.4z"/>
                    </g>
                    </svg>
                </div>                
            </div>
          </div>
        </div>       
        <div class="cd-chart-box-middle">
          <div class="ctrack-md-loader" :class="loaderHide?'ctrack-chart-hide-loader':''"></div>
          <div class="cd-chart-box-img">
            <!-- <img width="100%" src="../../../assets/images/dashboard/md-overspeeding-events-vs-mpg-chart.svg" /> -->
            <highcharts :options="chartOptions" ref="OverSpeedEventChart" :class="loaderHide?'':'ctrack-chart-hide-loader'"></highcharts>
          </div>
        </div> 
        <div class="cd-chart-box-bottom cd-chart-indicator-right md-bottom-indicWithCheck">
          <div class="cd-chart-indicator-style1">
            <div class="ctrack-row">              
              <div class="ctrack-col-sm-6 ctrack-col-md-3" :key="i" v-for="(series, i) in chartOptions.series"><div class="cd-chart-indicator-inner ctrack-text-truncate"><span :style="'background-color:'+series.color" class="cd-chart-indicator-color"></span>{{series.name}}<div class="ctrack-right-toggle-btns"><label class="ctrack-switch"><input type="checkbox" checked @change="toggleSeries(i)"><span class="ctrack-slider ctrack-round"></span></label></div></div></div>   
              <!-- <div class="ctrack-col-sm-6 ctrack-col-md-3"><div class="cd-chart-indicator-inner ctrack-text-truncate"><span style="background-color:#F0313A" class="cd-chart-indicator-color"></span>OS-2<div class="ctrack-right-toggle-btns"><label class="ctrack-switch"><input type="checkbox" checked><span class="ctrack-slider ctrack-round"></span></label></div></div></div>   
              <div class="ctrack-col-sm-6 ctrack-col-md-3"><div class="cd-chart-indicator-inner ctrack-text-truncate"><span style="background-color:#20A6F4" class="cd-chart-indicator-color"></span>OS-3<div class="ctrack-right-toggle-btns"><label class="ctrack-switch"><input type="checkbox" checked><span class="ctrack-slider ctrack-round"></span></label></div></div></div>   
              <div class="ctrack-col-sm-6 ctrack-col-md-3"><div class="cd-chart-indicator-inner ctrack-text-truncate"><span style="background-color:#32333B" class="cd-chart-indicator-color"></span>OS-4<div class="ctrack-right-toggle-btns"><label class="ctrack-switch"><input type="checkbox" checked><span class="ctrack-slider ctrack-round"></span></label></div></div></div>   -->          
            </div>
          </div>
        </div>
      </div>
      <!-- Overspeeding Events Vs MPG Chart -->

    </div>    
  </div>
</template>

<script>
import Vue from 'vue'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import HighchartsVue3D from 'highcharts/highcharts-3d'

HighchartsVue3D(Highcharts);
Vue.use(HighchartsVue, {});
export default {
  name: 'OverspeedingEventsVsMPGChart',
  components: {},
  data: function() {
    return {
      loaderHide:false,
      chartTitle:"",
      chartOptions:{
        chart: {
          zoomType: 'xy',
          type: 'column',
          //spacing:[10,0,10,0],
          /* options3d: {
              enabled: true,
              alpha: 15,
              beta: -5,
              depth: 40,
              viewDistance: 30
          } */
        },
        credits: {
          enabled: false
        },
        title: {
            text: ''
        },
        xAxis: {
          title: {text: ' '},
          categories: [],
          crosshair: true,
          labels: {
            style: {
                color: '#333333',
                fontSize: "12px",
                fontFamily: "Roboto, Regular"
            }
          }
        },
        yAxis: [
          { 
            // Primary yAxis
            labels: {
                format: '{value}',
                style: {
                    color: "",
                    fontSize: "12px",
                    fontFamily: "Roboto, Regular"
                }
            },
            title: {
                text: '',            
                style: {
                  color: "",
                  fontSize: "14px",
                  fontFamily: 'Roboto, Regular',
                },
              // align: "high",
              verticalAlign: "bottom"
            }
          }, 
          { 
            // Secondary yAxis
            title: {
                text: 'MPG',            
                style: {
                  color: "",
                  fontSize: "14px",
                  fontFamily: 'Roboto, Regular',
                },
            },
            labels: {
                format: '',
                style: {
                    color: "",
                    fontSize: "12px",
                    fontFamily: "Roboto, Regular"
                }
            },
            opposite: true
        }],
        plotOptions: {
    	    spline: {
    	        color: "#FFFFFF",
    	        lineWidth: 2,
    	        marker: {
    	          enabled: true,
    	          symbol: 'circle',
    	          /* radius: 4, */
    	          fillColor: '#FFFFFF',
    	          lineWidth: 2,
    	          lineColor: "#32333B"
              },
              events: {
                hide: function (a) {
                    this.yAxis.setTitle({
                        text: ' '
                    });

                },
                show: function () {
                    this.yAxis.setTitle({
                        text: this.name
                    });
                }
              }
    	    },
          column: {
              depth: 25
          }
        },
        tooltip: {
            shared: true,                                
            backgroundColor: {
              linearGradient: [0, 0, 0, 60],
              stops: [
                  [0, '#FFFFFF'],
                  [1, '#FFFFFF']
              ]
            },
            headerFormat:'<span style="font-size: 14px;"><strong>{point.key} {point.series.xAxis.userOptions.title.text} </strong></span><br/>',
            borderWidth: 0,
            style: {
              color: '#333333',
              fontSize: "14px",
              fontFamily: "Roboto",
            },
        },
        legend: {
            enabled: false,
        },
        series: []
      }
    }
  }, 
  created(){
   this.getOverspeedingEventsVsMPGChartData();        
  },  
  methods: {
    getOverspeedingEventsVsMPGChartData(){
      setTimeout(() => {
        let self = this;
        self.loaderHide=false;
        const returnResult = {
          //'#EA2129','#5EAD2E','#FFA908'
            "Id":"OverspeedingEventsVsMPGChart",
            "data": {
            "chartTitle" : "Overspeeding Events Vs MPG",
            "categories": [
                '1', '2', '3', '4', '5', '6', '7', '8', '9', '10','11', '12', '13', '14', '15', '16', '17', '18', '19', '20','21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'
            ],
            "xAxisTitle": "January 2020",
            "PrimaryyAxisTitle": "Events",
            "PrimaryyAxisColor": "#008D72",
            "PrimaryyAxisSuffix": "",
            "series": [
              {
                "name": 'OS-1',
                "type": 'column',
                "yAxis": 0,
                "data": [49.9, 71.5, 106.4, 129.2, 176.0, 135.6, 148.5, 95.6, 54.4,
                      49.9, 71.5, 106.4, 129.2, 144.0, 135.6, 148.5, 216.4, 194.1, 54.4,
                      49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 45],
                "color": "#008D72",
                "tooltip": {
                    "valueSuffix": ' '
                }
              },
              {
                  "name": 'OS-2',
                  "type": 'column',
                  "yAxis": 0,
                  "data": [19, 51, 16, 119, 126, 145, 108, 295, 154,
                        149, 51, 16, 119, 126, 145, 108, 295, 154, 194,
                        149, 51, 16, 119, 126, 145, 108, 295, 154, 194, 95, 60],        
                  "color": "#F0313A",
                  "tooltip": {
                      "valueSuffix": ' '
                  }
              },
              {
                  "name": 'OS-3',
                  "type": 'column',
                  "yAxis": 0,
                  "data": [9, 21, 146, 79, 76, 35, 48, 195, 154,
                        9, 21, 146, 79, 76, 35, 48, 195, 154, 54,
                        9, 21, 146, 79, 76, 35, 48, 195, 154, 216, 95, 18],        	
                  "color": "#20A6F4",
                  "tooltip": {
                      "valueSuffix": ' '
                  }

              },
              {
                "name": 'MPG',
                "type": 'spline',
                "yAxis": 1,
                "data": [9.9, 51, 96.4, 59.2, 111.0, 164.0, 94.1, 195.6, 154.4,59.2, 111.0,
                        9.9, 51, 96.4, 59.2, 111.0, 164.0, 154.6, 48.5, 26.4, 154.4,
                        9.9, 51, 96.4, 59.2, 111.0, 164.0, 154.6, 48.5, 26.4, 94.1],
                "color": "#32333B",
                "tooltip": {
                      "valueSuffix": ' mpg'
                }
              }
            ]
          }

        };
        self.chartOptions.xAxis.categories = returnResult.data.categories;
        self.chartOptions.xAxis.title.text = returnResult.data.xAxisTitle;
        self.chartOptions.series = returnResult.data.series;
        self.chartTitle = returnResult.data.chartTitle;
        self.chartOptions.yAxis[0].labels.style.color = returnResult.data.PrimaryyAxisColor;
        self.chartOptions.yAxis[0].labels.format = "{value} " + returnResult.data.PrimaryyAxisSuffix;
        self.chartOptions.yAxis[0].title.text = returnResult.data.PrimaryyAxisTitle;
        self.chartOptions.yAxis[0].title.style.color = returnResult.data.PrimaryyAxisColor;
        returnResult.data.series.forEach((element, index) => {
          if(element.type == 'spline')
          {
            self.chartOptions.yAxis[1].labels.style.color = element.color;
            self.chartOptions.yAxis[1].labels.format = "{value}" + element.tooltip.valueSuffix;
            self.chartOptions.yAxis[1].title.text = element.name;
            self.chartOptions.yAxis[1].title.style.color = element.color;
          }             
        });       
        self.loaderHide=true;
      }, 1000);
    }, 
    toggleSeries(id) {      
      this.$refs.OverSpeedEventChart.chart.series[id].setVisible(!this.$refs.OverSpeedEventChart.chart.series[id].visible);
    },
  }
};

</script>

<style scoped>
/*
=============================================================
Please put your custom CSS below (CTrack BS Row Structure) 
============================================================
*/
/*CTrack BS Row Structure (Please don't change or use this CSS)*/
.ctrack-row{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-right:-10px;margin-left:-10px;}
[class*="ctrack-col-"]{position:relative;width:100%;min-height:1px;padding-right:10px;padding-left:10px;}
.ctrack-d-flex{display:-webkit-box!important;display:-ms-flexbox!important;display:flex!important;}
.ctrack-align-items-center{-webkit-box-align:center!important;-ms-flex-align:center!important;align-items:center!important;}
.ctrack-justify-content-center{-webkit-box-pack:center!important;-ms-flex-pack:center!important;justify-content:center!important;}
.ctrack-h-100{height: 100%;}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}

.ctrack-col-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}

@media only screen and (min-width:576px){
.ctrack-col-sm-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-sm-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-sm-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-sm-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-sm-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-sm-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-sm-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-sm-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-sm-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-sm-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-sm-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-sm-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}

@media only screen and (min-width:768px){
.ctrack-col-md-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-md-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-md-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-md-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-md-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-md-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-md-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-md-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-md-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-md-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-md-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-md-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}

@media only screen and (min-width:992px){
.ctrack-col-lg-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-lg-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-lg-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-lg-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-lg-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-lg-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-lg-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-lg-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-lg-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-lg-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-lg-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-lg-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}

@media only screen and (min-width:1200px){
.ctrack-col-xl-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-xl-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-xl-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-xl-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-xl-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-xl-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-xl-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-xl-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-xl-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-xl-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-xl-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-xl-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}

/*CTrack BS Row Structure (Please don't change or use above CSS)*/
/*
=============================================================
Start your custom CSS from here
============================================================
*/

/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
*{font-family: 'Roboto', sans-serif;}
.ctrack-h-100{height: 100%;}
[class*="md-chart-arrow"]{display:inline-block;vertical-align:middle;}
[class*="md-chart-arrow"] svg{display:block;}
.md-chart-arrow-down{transform:rotate(180deg); -webkit-transform:rotate(180deg); -moz-transform:rotate(180deg); -ms-transform:rotate(180deg); -o-transform:rotate(180deg);}
.cd-chart-innerbox{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;width:100%;height:100%;background-color:#FFF;border-radius:5px;align-content: space-between; -webkit-align-content: space-between;}
.ctrack-align-content-top{align-content: start; -webkit-align-content: start;}
.cd-chart-box-middle{padding:20px 30px;-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
.cd-chart-box-bottom{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;border-top:1px solid #E8E8E8;margin-top:0;padding:18px 0 0;}
.cd-chart-box-top{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;padding:15px 20px;border-bottom:1px solid #E8E8E8;}
.cd-chart-box-img {text-align: center;}
.cd-chart-box-header{display:table;width:100%;}
.cd-chart-box-header > div{display:table-cell;vertical-align: middle;}
.cd-chart-box-col1 h3{color:#013C5E;font-size:18px;font-weight:700;}
.cd-chart-box-col1 h3 span{color:#979797;font-weight:400;}
.cd-chart-box-col2{text-align:right;font-size:14px;font-weight:bold;color:#333333;}
.ctrack-select-main{display:inline-block;}
.ctrack-select-box{display:inline-block;font-family:'Roboto',sans-serif;font-weight:bold;color:#333333;font-size:14px;border:0;padding-right:2px;outline: 0}
.ctrack-chart-info{display:inline-block;vertical-align:middle;}
.ctrack-chart-info-btn,.ctrack-chart-info-btn svg{display:block;}
.ctrack-chart-info a{color:#e8e8e8 !important;}
.ctrack-chart-info a:hover{color:#cacaca !important;}
.cd-indicator-pos-col{display: block;}
.cd-chart-indicator-inner{display:inline-block;vertical-align: middle; width:100%;position:relative;padding-left:32px;margin-bottom:12px;text-align: left; color: #333333;font-size: 15px;font-weight: 500;line-height: 21px;}
.cd-chart-indicator-inner .cd-chart-indicator-color{position:absolute;left:0;top:50%;width:25px;height:11px;border-radius:3.5px;transform:translateY(-50%);}
.cd-indicator-pos-right{position:absolute;top:-5px;right:0;}
.cd-indicator-pos-right .cd-chart-indicator-inner{margin-bottom:10px;}
.cd-chart-right-lbl{font-size:16px;font-weight:normal;color:#666666;display: inline-block;vertical-align: middle;}
.cd-chart-right-lbl strong{color:#013C5E;font-weight:700;}

.md-bottom-indicWithCheck{padding:0 20px;}
.md-bottom-indicWithCheck .ctrack-row{margin-left:-20px;margin-right:-20px;}
.md-bottom-indicWithCheck [class*="ctrack-col-"]{padding:20px;border-right:solid 1px #E8E8E8;}
.md-bottom-indicWithCheck [class*="ctrack-col-"]:last-child{border:0;}

.cd-chart-indicator-right{display:inline-block;width:100%;}
.cd-chart-indicator-right .cd-chart-indicator-inner{margin-bottom:0;padding-right: 40px;}
.cd-chart-indicator-right .ctrack-right-toggle-btns{position:absolute;right:0;top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%);}
.cd-chart-indicator-right .ctrack-col-12:last-child .cd-chart-indicator-inner:before{display:none;}

/* Rounded Toggle Button*/
.ctrack-switch{position:relative;display:inline-block;vertical-align: middle;width:31px;height:19px;}
.ctrack-switch input{opacity:0;width:0;height:0;}
.ctrack-slider{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:#D7D7D7;-webkit-transition:.4s;transition:.4s;}
.ctrack-slider:before{position:absolute;content:"";height:15px;width:15px;left:2px;bottom:2px;background-color:white;-webkit-transition:.4s;transition:.4s;}
.ctrack-switch input:checked + .ctrack-slider{background-color:#40C1AC;}
.ctrack-switch input:focus + .ctrack-slider{box-shadow:0 0 1px #002B49;}
.ctrack-switch input:checked + .ctrack-slider:before{-webkit-transform:translateX(12px);-ms-transform:translateX(12px);transform:translateX(12px);}
.ctrack-slider.ctrack-round{border-radius:10px;}
.ctrack-slider.ctrack-round:before{border-radius:50%;}

/* Responsive CSS */
@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1399px){}
@media only screen and (max-width:1299px){
  .md-bottom-indicWithCheck{padding:0 15px;}
  .md-bottom-indicWithCheck .ctrack-row{margin-left:-10px;margin-right:-10px;}
  .md-bottom-indicWithCheck [class*="ctrack-col-"]{padding:10px;}
}
@media only screen and (max-width:1199px){
  
}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){
 .cd-indicator-pos-right{position:static;float:right;width: 100%;}
 .cd-indicator-pos-right .cd-indicator-pos-col{display:inline-block;}
.cd-indicator-pos-right .cd-indicator-pos-col + .cd-indicator-pos-col{padding-left:20px;}


}
@media only screen and (max-width:767px){
  .cd-chart-box-header > div{display: inline-block;width: 100%;}
  .cd-chart-box-col2{text-align: left;}
  .cd-chart-box-header{position: relative;padding-right: 30px;}
  .md-view-detail-link{position:absolute;right:0;top:2px;}
  .md-bottom-indicWithCheck [class*="ctrack-col-"]:nth-child(2n+2) {border-right: 0;}

}
@media only screen and (max-width:575px){
  .cd-chart-box-middle {padding: 15px}
  .cd-chart-box-col1 h3 span{display: block}
  .md-bottom-indicWithCheck [class*="ctrack-col-"]{border-right: 0;}  
}

</style>
