<template>
    <v-dialog v-model="dialog"
              max-width="450"
              @click:outside="closeDialog">
        <v-form ref="form"
                v-model="valid"
                lazy-validation>
            <v-card>
                <v-toolbar dark color="#002B49" style="margin-bottom: 16px;">
                    <v-toolbar-title>{{$t("Settings.exportToEmail")}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeDialog" style="margin: 0 !important;">
                        <v-icon>mdi-close-thick</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-text-field v-model="email.subject"
                                  :label="$t('Settings.subject')"
                                  outlined
                                  dense></v-text-field>
                    <v-combobox v-model="to"
                                :label="$t('Settings.recipients')"
                                :hide-no-data="!search"
                                :search-input.sync="search"
                                :rules="emailRules"
                                :items="contacts"
                                hide-selected
                                deletable-chips
                                small-chips
                                multiple
                                outlined
                                dense>
                        <template v-slot:no-data>
                            <v-list-item>
                                <span class="subheading">Add</span>
                                <v-chip label
                                        small>
                                    {{ search }}
                                </v-chip>
                            </v-list-item>
                        </template>
                    </v-combobox>
                    <v-textarea v-model="email.message"
                                :label="$t('Settings.message')"
                                outlined></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="validate" color="primary">
                        {{$tc("ForgotPassword.sendEmail")}}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeDialog">
                        {{$tc("Settings.close")}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    import contactService from "../../service/contact.service.js"

    export default {
        props: {
            value: Boolean,
            sendEmail: Function
        },
        data() {
            return {
                dialog: this.value,
                valid: true,
                data: {},
                component: "",
                search: null,
                to: [],
                email: {
                    to: [],
                    subject: "",
                    message: ""
                },
            }
        },
        computed: {
            getContactsData() { return this.$contactStore.getContactsData(); },
            contacts() {
                return this.getContactsData.map(item => ({
                    text: item.fullName,
                    value: item.emailAddresses
                }))
            },
            emailRules() {
                let allEmailsValid = true
                if (this.to.length < 1) {
                    return ["E-mail is required"]
                } else {
                    this.to.forEach(item => {
                        if (item.value) {
                            if (!/.+@.+/.test(item.value)) {
                                allEmailsValid = false
                            }
                        } else {
                            if (!/.+@.+/.test(item)) {
                                allEmailsValid = false
                            }
                        }
                    })
                }
                if (!allEmailsValid) {
                    return ["Email must be valid"]
                }
                return []
            }
        },
        created() {        
            this.$dialogEventHub.$on('openEmailDialog', this.openDialog);
        },
        methods: {
            async getContacts() { await this.$contactStore.getContacts(); },
            closeDialog() {
                this.to = []
                this.email = {
                    to: [],
                    subject: "",
                    message: ""
                };
                this.$emit("input", false);
                this.dialog = false;
            },
            async openDialog(data) {
                this.dialog = true;
                if (this.getContactsData.length == 0) {
                    await this.getContacts();
                };          
            },
            validate() {
                this.valid = this.$refs.form.validate()
                if (this.valid) {
                    let to = []
                    this.to.forEach(item => {
                        if (item.value) {
                            to.push(item.value)
                        } else {
                            to.push(item)
                        }
                    })
                    this.email.to = to.join(';');
                    this.sendEmail(this.email);
                    this.$toast.show(this.$t('ContactLabels.emailSent'));
                    this.closeDialog();
                } else {
                    this.$toast.show(this.$t('ContactLabels.emailFailed'));
                }
            }
        },
        watch: {
            value(value) {
                this.dialog = value
            }
        },
        beforeDestroy() {
            this.$dialogEventHub.$off('openEmailDialog', this.openDialog);
        },
    }
</script>