import Vue from 'vue';
import axios from "axios";
import moment from 'moment'

class VideoService {

    Add(deviceId, status, statusText, latitude, longitude, eventDate, seconds) {
        return axios.post("/api/video", {
            deviceId: deviceId,
            status: status,
            statusText: statusText,
            latitude: latitude,
            longitude: longitude,
            length: seconds,
            eventDateUTC: moment(eventDate).toISOString(true)
        });
    }

    Delete(ids) {
        return axios.post('/api/video/delete', ids);
    }

    Get(requestId) {
        return axios.get(`/api/video/${requestId}`);
    }

    GetAssignments() {
        return axios.get(`/api/video/assignments`);
    }

    GetAssignment(deviceId) {
        return axios.get(`/api/video/${deviceId}/assignments`);
    }

    GetImages(requestId) {
        return axios.get(`/api/video/${requestId}/images`);
    }

    GetRange(startDate, endDate) {
        return axios.get(`/api/video/${moment(startDate).toISOString()}/${moment(endDate).toISOString()}`);
    }

    GetRangeByDevice(deviceId, startDate, endDate) {
        return axios.get(`/api/video/${deviceId}/${moment(startDate).toISOString()}/${moment(endDate).toISOString()}`);
    }

    Retry(requestId) {
        return axios.put(`/api/video/${requestId}/retry`);
    }

    GetDownloadUrl(requestId) {
        return `/api/video/${requestId}/download`;
    }

    GetVideoUrl(requestId) {
        return `/api/video/${requestId}/content`;
    }

    GetLive(deviceId) {
        return axios
            .get(`/api/video/${deviceId}/live`)
            .catch((e) => {
                Vue.prototype.$toast.error('Live feed not available');
            });
    }
}

export default new VideoService();

