export { default as CarbonFootprintWidget } from "./modules/CarbonFootprintWidget.vue";
export { default as ContinuousDrivingWidget } from "./modules/ContinuousDrivingWidget.vue";
export { default as DistanceDrivenChart } from "./modules/DistanceDrivenChart.vue";
export { default as DistanceFuelChart } from "./modules/DistanceFuelChart.vue";
export { default as idleTimeVsDriveTimeChart } from "./modules/idleTimeVsDriveTimeChart.vue";
export { default as driveTimeVsIdleTimeChart } from "./modules/driveTimeVsIdleTimeChart.vue";
export { default as topMilesDrivenChart } from "./modules/topMilesDrivenChart.vue";
export { default as utilizationByGroupChart } from "./modules/utilizationByGroupChart.vue";
export { default as topMilesDrivenperDayChart } from "./modules/topMilesDrivenperDayChart.vue";
export { default as groupsWithLowestVehicleUtilChart } from "./modules/groupsWithLowestVehicleUtilChart.vue";
export { default as DistanceTravelWidget } from "./modules/DistanceTravelWidget.vue";
export { default as DriverBehaviourChart } from "./modules/DriverBehaviourChart.vue";
export { default as DriveRelationChart } from "./modules/DriveRelationChart.vue";
export { default as DriverHistoryChart } from "./modules/DriverHistoryChart.vue";
export { default as DriverRatingChart } from "./modules/DriverRatingChart.vue";
export { default as DriverRiskChart } from "./modules/DriverRiskChart.vue";
export { default as DriveTimeChart } from "./modules/DriveTimeChart.vue";
export { default as TopMostEfficientChart } from "./modules/TopMostEfficientChart.vue";
export { default as FuelConsumptionWidget } from "./modules/FuelConsumptionWidget.vue";
export { default as FuelCostWidget } from "./modules/FuelCostWidget.vue";
export { default as FuelUtilazationChart } from "./modules/FuelUtilazationChart.vue";
export { default as FuelWasteChart } from "./modules/FuelWasteChart.vue";
export { default as HaltsChart } from "./modules/HaltsChart.vue";
export { default as HarshAccelerationWidget } from "./modules/HarshAccelerationWidget.vue";
export { default as HarshBrakingWidget } from "./modules/HarshBrakingWidget.vue";
export { default as HarshChart } from "./modules/HarshChart.vue";
export { default as HarshCorneringWidget } from "./modules/HarshCorneringWidget.vue";
export { default as IdlingChart } from "./modules/IdlingChart.vue";
export { default as IdlingWidget } from "./modules/IdlingWidget.vue";
export { default as IncidentsMilesChart } from "./modules/IncidentsMilesChart.vue";
export { default as MileageChart } from "./modules/MileageChart.vue";
export { default as MileageWidget } from "./modules/MileageWidget.vue";
export { default as MilesCarbonFootprintChart } from "./modules/MilesCarbonFootprintChart.vue";
export { default as MissedJobChart } from "./modules/MissedJobChart.vue";
export { default as NightDrivingWidget } from "./modules/NightDrivingWidget.vue";
export { default as NoGeoFenceChart } from "./modules/NoGeoFenceChart.vue";
export { default as OverspeedChart } from "./modules/OverspeedChart.vue";
export { default as OverspeedWidget } from "./modules/OverspeedWidget.vue";
export { default as DrillDownGrid } from "./modules/DrillDownGrid.vue";
export { default as OverspeedingEventsVsMPGChart } from "./modules/OverspeedingEventsVsMPGChart.vue";
export { default as HarshEventVsMPG3DChart } from "./modules/HarshEventVsMPG3DChart.vue";
export { default as TotalHarshEventsChart } from "./modules/TotalHarshEventsChart.vue";
export { default as TotalOverspeedingEventsChart } from "./modules/TotalOverspeedingEventsChart.vue";
export { default as milesPerDayBusinessAndPrivateChart } from "./modules/milesPerDayBusinessAndPrivateChart.vue";
export { default as tripsMilesAndTimeChart } from "./modules/tripsMilesAndTimeChart.vue";
export { default as businessVsPrivateChart } from "./modules/businessVsPrivateChart.vue";
export { default as VehicleNotStartedChart } from "./modules/VehicleNotStartedChart.vue";
export { default as vehicleUtilizationWidget } from "./modules/vehicleUtilizationWidget.vue";
export { default as driverUtilizationWidget } from "./modules/driverUtilizationWidget.vue";
export { default as driveTimeWidget } from "./modules/driveTimeWidget.vue";
export { default as driveTimeVehicleDayWidget } from "./modules/driveTimeVehicleDayWidget.vue";
export { default as milesDrivenWidget } from "./modules/milesDrivenWidget.vue";
export { default as milesVehicleDayWidget } from "./modules/milesVehicleDayWidget.vue";
export { default as bpMileageWidget } from "./modules/bpMileageWidget.vue";
export { default as DatePickerModal } from "./modules/DatePickerModal.vue";
export { default as FilterModal } from "./modules/FilterModal.vue";
export { default as ExecutiveCommonDrillPopup } from "./modules/ExecutiveCommonDrillPopup.vue";
export { default as IdlingDrillPopup } from "./modules/IdlingDrillPopup.vue";
export { default as DashboardChartsSelector } from "./shared/DashboardChartsSelector.vue";
