<script>
    import { Doughnut, mixins } from 'vue-chartjs'

    export default {
        extends: Doughnut,
        mixins: [mixins.reactiveProp],
        props: {
            chartData: {
                type: Object,
                default: {
                    labels: [''],
                    datasets: [
                        {
                            backgroundColor: [
                                "#00C1D4",
                                "#e0e0e0",
                                "#666666",
                                "#002B49",
                            ],
                            data: []
                        },
                        {
                            backgroundColor: [
                                "#00C1D4",
                                "#e0e0e0",
                                "#666666",
                                "#002B49",
                            ],
                            data: []
                        }
                    ],
                }
            },
            options: {
                type: Object,
                default: {}
            }
        },
        mounted() {
            this.renderChart(this.chartData, this.options)
        },
        watch: {
            chartData: {
                deep: true,
                handler(chartData) {
                    this.chartData = chartData
                    this.renderChart(this.chartData, this.options)
                }
            }
        }
    }
</script>