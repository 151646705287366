import axios from 'axios';
import Vue from 'vue';
import moment from 'moment'
class assetManagementService {
    //#region Asset Types
    getAllAssetTypes() {
        return axios.get(`/api/AssetManagement/GetAllAssetTypes`)
    }
    getAssetTypeById(id) {
        return axios.get(`/api/AssetManagement/GetAssetTypeById/${id}`)
    }
    createAssetType(body) {
        return axios.post(`/api/AssetManagement/CreateAssetType`, body)
    }
    updateAssetType(body) {
        return axios.put(`/api/AssetManagement/UpdateAssetType`, body)
    }
    deleteAssetType(id) {
        return axios.get(`/api/AssetManagement/DeleteAssetType/${id}`);
    }
    //#endregion

    //#region Income Categories
    getAllIncomeCategories(tenantId, type) {
        return axios.get(`/api/AssetManagement/GetAllIncomeCategories/${tenantId}/${type}`)
    }
    createIncomeCategory(body) {
        return axios.post(`/api/AssetManagement/CreateIncomeCategory`, body)
    }
    updateIncomeCategory(body) {
        return axios.put(`/api/AssetManagement/UpdateIncomeCategory`, body)
    }
    deleteIncomeCategory(id) {
        return axios.get(`/api/AssetManagement/DeleteIncomeCategory/${id}`);
    }
    //#endregion

    //#region Income Types
    getAllIncomeTypes(tenantId, type) {
        return axios.get(`/api/AssetManagement/GetAllIncomeTypes/${tenantId}/${type}`)
    }
    createIncomeType(body) {
        return axios.post(`/api/AssetManagement/CreateIncomeType`, body)
    }
    updateIncomeType(body) {
        return axios.put(`/api/AssetManagement/UpdateIncomeType`, body)
    }
    deleteIncomeType(id) {
        return axios.get(`/api/AssetManagement/DeleteIncomeType/${id}`);
    }
    //#endregion

    //#region Expense Categories
    getAllExpenseCategories(tenantId, type) {
        return axios.get(`/api/AssetManagement/GetAllExpenseCategories/${tenantId}/${type}`)
    }
    createExpenseCategory(body) {
        return axios.post(`/api/AssetManagement/createExpenseCategory`, body)
    }
    updateExpenseCategory(body) {
        return axios.put(`/api/AssetManagement/updateExpenseCategory`, body)
    }
    deleteExpenseCategory(id) {
        return axios.get(`/api/AssetManagement/deleteExpenseCategory/${id}`);
    }
    //#endregion

    //#region Expense Types
    getAllExpenseTypes(tenantId, type) {
        return axios.get(`/api/AssetManagement/GetAllExpenseTypes/${tenantId}/${type}`)
    }
    createExpenseType(body) {
        return axios.post(`/api/AssetManagement/CreateExpenseType`, body)
    }
    updateExpenseType(body) {
        return axios.put(`/api/AssetManagement/updateExpenseType`, body)
    }
    deleteExpenseType(id) {
        return axios.get(`/api/AssetManagement/deleteExpenseType/${id}`);
    }
    //#endregion

    //#region Transactions
    getTransactions(filter) {
        const moment = Vue.prototype.$moment;
        if (filter.fromDate) {
            filter.fromDate = moment(String(moment(filter.fromDate).format('YYYY-MM-DDTHH:mm:ss') + 'Z')).utc().valueOf();
        };
        if (filter.toDate) {
            filter.toDate = moment(String(moment(filter.toDate).format('YYYY-MM-DDTHH:mm:ss') + 'Z')).utc().valueOf();
        };
        return axios.post(`/api/AssetManagement/GetTransactions`, filter)
    }
    getTransactionById(id) {
        return axios.get(`/api/AssetManagement/GetTransactionById/${id}`)
    }
    createTransaction(transaction) {
        return axios.post(`/api/AssetManagement/CreateTransaction`, transaction)
    }
    importTransactions(transactionList) {
        return axios.post(`/api/AssetManagement/ImportTransactions`, transactionList)
    }
    updateTransaction(transaction) {
        return axios.put(`/api/AssetManagement/UpdateTransaction`, transaction)
    }
    deleteTransactions(ids) {
        return axios.post(`/api/AssetManagement/DeleteTransactions`, ids)
    }
    //#endregion

    //#region Template Config
    getTenantConfig(tenantId) {
        return axios.get(`/api/AssetManagement/GetTenantConfig/${tenantId}`)
    }
    getTenantConfigProps(tenantId) {
        return axios.get(`/api/AssetManagement/GetTenantConfigProps/${tenantId}`)
    }
    createTenantConfig(model) {
        return axios.post(`/api/AssetManagement/CreateTenantConfig`, model)
    }
    updateTenantConfig(model) {
        return axios.put(`/api/AssetManagement/UpdateTenantConfig`, model)
    }
    updateTemplateConfigProps(tenantId, model) {
        return axios.put(`/api/AssetManagement/UpdateTemplateConfigProps/${tenantId}`, model)
    }
    //#endregion

    //#region Asset Template Config
    getAssetConfig(assetId) {
        return axios.get(`/api/AssetManagement/GetAssetConfig/${assetId}`)
    }
    getAssetConfigProps(assetId) {
        return axios.get(`/api/AssetManagement/GetAssetConfigProps/${assetId}`)
    }
    getAssetConfigList(model) {
        return axios.post(`/api/AssetManagement/GetAssetConfigList`, model)
    }
    getAssetConfigPropsList(model) {
        return axios.post(`/api/AssetManagement/GetAssetConfigPropsList`, model)
    }
    createAssetConfig(model) {
        return axios.post(`/api/AssetManagement/CreateAssetConfig`, model)
    }
    updateAssetConfig(assetId, model) {
        return axios.put(`/api/AssetManagement/UpdateAssetConfig/${assetId}`, model)
    }
    updateAssetConfigProps(tenantId, model) {
        return axios.put(`/api/AssetManagement/UpdateAssetConfigProps/${tenantId}`, model)
    }
    //#endregion

    //#region Operator Template Config
    getOperatorConfig(operatorId) {
        return axios.get(`/api/AssetManagement/GetOperatorConfig/${operatorId}`)
    }
    getOperatorConfigProps(operatorId) {
        return axios.get(`/api/AssetManagement/GetOperatorConfigProps/${operatorId}`)
    }
    getOperatorConfigList(model) {
        return axios.post(`/api/AssetManagement/GetOperatorConfigList`, model)
    }
    getOperatorConfigPropsList(model) {
        return axios.post(`/api/AssetManagement/GetOperatorConfigPropsList`, model)
    }
    createOperatorConfig(model) {
        return axios.post(`/api/AssetManagement/CreateOperatorConfig`, model)
    }
    updateOperatorConfig(operatorId, model) {
        return axios.put(`/api/AssetManagement/UpdateOperatorConfig/${operatorId}`, model)
    }
    updateOperatorConfigProps(operatorId, model) {
        return axios.put(`/api/AssetManagement/UpdateOperatorConfigProps/${operatorId}`, model)
    }
    //#endregion

    //#region Graphs

    operationalCostOverTimeDistance(model) {
        return axios.post(`/api/AssetManagement/OperationalCostOverTimeDistance`, model)
    }
    entityCostOverTimeDistance(model) {
        return axios.post(`/api/AssetManagement/EntityCostOverTimeDistance`, model)
    }
    totalCostOfOwnership(model) {
        return axios.post(`/api/AssetManagement/TotalCostOfOwnership`, model)
    }
    assetLifeCycle(model) {
        return axios.post(`/api/AssetManagement/AssetLifeCycle`, model)
    }

    //#endregion

    //#region Document Upload
    async uploadTransactionAttachment(transactionId, entityId, files, type) {
        var postedFiles = [];
        for (var file in files) {
            var arrayBuffer = await files[file].arrayBuffer();
            var base64String = btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));
            postedFiles.push({
                file: base64String,
                fileName : files[file].name,
            });
        };
        let params = {
            'transactionId': transactionId,
            'entityId': entityId,
            'applicationId': Vue.prototype.$session.get("applicationId"),
        }
        return axios.post('/api/AssetManagement/UploadDocuments', postedFiles, { params: params })
    }
    //#endregion

    //#region Document Types
    getAllDocumentTypes(tenantId) {
        return axios.get(`/api/AssetManagement/GetAllDocumentTypes/${tenantId}`)
    }
    getDocumentTypeById(id) {
        return axios.get(`/api/AssetManagement/GetDocumentTypeById/${id}`)
    }
    createDocumentType(body) {
        return axios.post(`/api/AssetManagement/CreateDocumentType`, body)
    }
    updateDocumentType(body) {
        return axios.put(`/api/AssetManagement/UpdateDocumentType`, body)
    }
    deleteDocumentType(id) {
        return axios.get(`/api/AssetManagement/DeleteDocumentType/${id}`);
    }
    //#endregion

    //#region Measurement Units
    getAllMeasurementUnits() {
        return axios.get(`/api/AssetManagement/GetAllMeasurementUnits`)
    }
    getMeasurementUnitById(id) {
        return axios.get(`/api/AssetManagement/GetMeasurementUnitById/${id}`)
    }
    createMeasurementUnit(body) {
        return axios.post(`/api/AssetManagement/CreateMeasurementUnit`, body)
    }
    updateMeasurementUnit(body) {
        return axios.put(`/api/AssetManagement/UpdateMeasurementUnit`, body)
    }
    deleteMeasurementUnit(id) {
        return axios.get(`/api/AssetManagement/DeleteMeasurementUnit/${id}`);
    }
    //#endregion
}

export default new assetManagementService();