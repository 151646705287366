<template>
  <div class="utilizationByGroupChart ctrack-h-100" ref="utilizationByGroup">
    <div id="vueapp" class="vue-app ctrack-h-100">
        
      <!-- Utilization by Group Chart -->
        <div class="cd-chart-innerbox">
            <div class="cd-chart-box-top">
                <div class="cd-chart-box-header">
                    <div class="cd-chart-box-col1">
                        <h3>{{chartTitle}}</h3>
                    </div> 
                    <div class="cd-chart-box-col2">
                        <div class="cd-chart-check-toggles cd-worst-best-section" :class="loaderHide?'':'ctrack-chart-hide-loader'">
                            <div class="md-chart-toggle-repeater">
                                <label class="ctrack-switch">
                                <input type="checkbox" v-model="showGroupAverageChart" @change="showGroupAverage">
                                <span class="ctrack-slider ctrack-round"></span>
                                </label>
                                <div class="md-chart-toggle-label">{{$t("Dashboard.groupAverage")}}</div>
                            </div>
                            <div class="md-chart-toggle-repeater md-chart-toggle-repeater-pos" :class="loaderHide?'':'ctrack-chart-hide-loader'" v-if="!showGroupAverageChart">
                                <label class="ctrack-switch">
                                <input type="checkbox" v-model="showSeriesTrendLines" @change="showTrendLines">
                                <span class="ctrack-slider ctrack-round"></span>
                                </label>
                                <div class="md-chart-toggle-label">{{$t("Dashboard.trendLines")}}</div>
                            </div>
                        </div> 
                        <div class="md-view-detail-link" @click="showDrill = true;">
                            <svg version="1.1" width="20" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 89.64 90.91" style="enable-background:new 0 0 89.64 90.91;" xml:space="preserve"><g>
                                <path d="M5.93,84.99c27.97,0,55.8,0,83.71,0c0,2,0,3.93,0,5.92c-29.87,0-59.73,0-89.64,0C0,60.64,0,30.4,0,0.09
                                c1.96,0,3.91,0,5.93,0C5.93,28.38,5.93,56.61,5.93,84.99z"/>
                                <path d="M67.5,80.38c0-21.98,0-43.9,0-65.87c3.52,0,7,0,10.53,0c0,21.97,0,43.88,0,65.87C74.53,80.38,71.06,80.38,67.5,80.38z"/>
                                <path d="M30.45,25.58c-1.48,1.49-2.94,2.95-4.4,4.42c-2.77,2.77-5.54,5.53-8.3,8.31c-0.93,0.94-2.01,1.29-3.27,0.94
                                c-2.07-0.58-2.86-3.07-1.52-4.76c0.32-0.4,0.71-0.76,1.07-1.12c3.77-3.78,7.54-7.56,11.33-11.31c0.53-0.52,0.66-0.87,0.28-1.62
                                C23,15.15,23.27,9.97,26.97,5.33c3.65-4.58,8.59-6.28,14.29-4.83c5.75,1.46,9.26,5.35,10.22,11.18c0.89,5.44-0.86,10.1-5.41,13.36
                                c-4.63,3.33-9.62,3.59-14.74,1.02C31.07,25.94,30.83,25.79,30.45,25.58z M29.98,13.82c0,4.37,3.46,7.85,7.79,7.86
                                c4.42,0.01,7.94-3.46,7.95-7.82c0.01-4.27-3.54-7.74-7.91-7.75C33.48,6.1,29.98,9.55,29.98,13.82z"/>
                                <path d="M41.48,35.67c3.53,0,6.97,0,10.49,0c0,14.87,0,29.69,0,44.59c-3.48,0-6.95,0-10.49,0C41.48,65.41,41.48,50.59,41.48,35.67z
                                "/>
                                <path d="M26.12,80.4c-3.5,0-6.94,0-10.46,0c0-8.75,0-17.46,0-26.24c3.45,0,6.92,0,10.46,0C26.12,62.87,26.12,71.58,26.12,80.4z"/>
                            </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ctrack-d-flexcol">
                <div class="ctrack-row chart-with-right-widget chart-with-right-widget-style2">
                    <div class="ctrack-col-sm-7 ctrack-col-md-8 ctrack-col-lg-9 ctrack-col-xl-10 ctrack-col-first">
                        <div class="ctrack-md-loader" :class="loaderHide?'ctrack-chart-hide-loader':''"></div>
                        <div class="d-chart-box-inner-left">                            
                            <div class="cd-chart-box-img">
                                <highcharts :options="chartOptions"  ref="utilizationGroupChart" id="utilizationGroupChart" :class="loaderHide?'':'ctrack-chart-hide-loader'"></highcharts>
                                <!-- <highcharts :options="chartOptions1" v-if="showGroupAverageChart" ref="utilizationGroupChart1" :class="loaderHide?'':'ctrack-chart-hide-loader'"></highcharts> -->
                                <!-- <img width="100%" src="../../../assets/images/dashboard/utilization-by-group-chart.svg"/> -->
                            </div>
                        </div>
                    </div>
                    <div class="ctrack-col-sm-5 ctrack-col-md-4 ctrack-col-lg-3 ctrack-col-xl-2 ctrack-col-second">
                        <div class="cd-chart-box-inner-right cd-chart-box-inner-right-style2">
                            <div class="cd-chart-indicator-right" :class="showGroupAverageChart ? 'cd-chart-indicator-blank-right' : '' " >
                              <div class="ctrack-row" v-show="!showGroupAverageChart" id="ChartsWithoutGroup" >
                                <div class="ctrack-col-12 cd-worst-best-section" v-if="chartOptions.series.length > 0">                                      
                                    <div class="md-chart-toggle-repeater">
                                        <div class="md-chart-toggle-label">{{$t("Dashboard.uncheck")}}</div>
                                        <label class="ctrack-switch">
                                            <input type="checkbox" class="check-all" :checked="checkAllSeries" @change="checkAll">
                                            <span class="ctrack-slider ctrack-round"></span>
                                        </label>
                                        <div class="md-chart-toggle-label">{{$t("Dashboard.checkAll")}}</div>
                                    </div>
                                    <!-- <div class="cd-chart-indicator-inner"><span style="background-color:#000000" class="cd-chart-indicator-color"></span>Check/Uncheck All<div class="ctrack-right-toggle-btns"><label class="ctrack-switch"><input type="checkbox" class="check-all" v-model="checkAll"><span class="ctrack-slider ctrack-round"></span></label></div></div> -->
                                </div>
                                <div class="ctrack-col-12" :key="i" v-for="(series, i) in chartOptions.series" v-if="series.showInLegend!=false"><div class="cd-chart-indicator-inner" :title="series.name"><span :style="'background-color:'+series.color" class="cd-chart-indicator-color"></span>{{series.name.split('>').pop().trim()}}<div class="ctrack-right-toggle-btns"><label class="ctrack-switch"><input type="checkbox" class="toggleSeriesCheckbox" :class="series.id" :checked="series.setChecked" @click="toggleSeries(i)"><span class="ctrack-slider ctrack-round"></span></label></div></div></div>
                                <!-- <div class="ctrack-col-12"><div class="cd-chart-indicator-inner"><span style="background-color:#002B49" class="cd-chart-indicator-color"></span>San Diego<div class="ctrack-right-toggle-btns"><label class="ctrack-switch"><input type="checkbox" checked><span class="ctrack-slider ctrack-round"></span></label></div></div></div>
                                <div class="ctrack-col-12"><div class="cd-chart-indicator-inner"><span style="background-color:#434348" class="cd-chart-indicator-color"></span>La Jolla<div class="ctrack-right-toggle-btns"><label class="ctrack-switch"><input type="checkbox" checked><span class="ctrack-slider ctrack-round"></span></label></div></div></div> -->
                              </div>
                              <div class="ctrack-row" v-show="showGroupAverageChart" id="ChartsWithGroup" >
                                <div class="ctrack-col-12" :key="i" v-for="(series, i) in chartOptions.series" v-if="series.ClassName == 'groupData' "><div class="cd-chart-indicator-inner"><span :style="'background-color:'+series.color" :class="series.type == 'line' ? 'cd-chart-indicator-color-line' : 'cd-chart-indicator-color'"></span>{{series.name}}</div></div>
                                <!-- <div class="ctrack-col-12"><div class="cd-chart-indicator-inner"><span style="background-color:#002B49" class="cd-chart-indicator-color"></span>San Diego<div class="ctrack-right-toggle-btns"><label class="ctrack-switch"><input type="checkbox" checked><span class="ctrack-slider ctrack-round"></span></label></div></div></div>
                                <div class="ctrack-col-12"><div class="cd-chart-indicator-inner"><span style="background-color:#434348" class="cd-chart-indicator-color"></span>La Jolla<div class="ctrack-right-toggle-btns"><label class="ctrack-switch"><input type="checkbox" checked><span class="ctrack-slider ctrack-round"></span></label></div></div></div> -->
                              </div>
                            </div>                            
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
        <!-- Utilization by Group Chart -->
        
    </div> 
    <md-dialog class="md-driver-drilldown" :md-active.sync="showDrill">
      <DrillDownGrid v-if="showDrill" :data="widgetData" :gridSettingsKey="widgetData.widgetName"></DrillDownGrid>
    </md-dialog>   
  </div>
</template>
<script>
import Vue from 'vue'
import HighchartsVue from 'highcharts-vue'
Vue.use(HighchartsVue, {});
import dashboardService from "../../../service/dashboard.service";
import { mutations, getters } from "../shared/observeDashboardFilter";
import JQuery from "jquery";

export default {
    name: 'utilizationByGroupChart',
    components: {},
    data: function() {
        return {
            loaderHide:false,
            chartTitle:"",
            showSeriesTrendLines:false,
            showGroupAverageChart:false,
            checkAllSeries:true,
            showDrill:false,
            chartOptions:{
                chart: {
                    type: 'scatter',
                    zoomType: 'xy'
                },
                title: {
                    text: ' '
                },
                credits: {
                    enabled: false
                }, 
                legend:{
                    enabled:false
                },   
                xAxis: {
                    //tickInterval: (24 * 3600 * 1000), // the number of milliseconds in a day
                    allowDecimals: false,
                    position:"on",
                    gridLineWidth:2,
                    categories:[],                
                },
                tooltip: {
                    backgroundColor: {
                        linearGradient: [0, 0, 0, 60],
                        stops: [
                            [0, '#FFFFFF'],
                            [1, '#FFFFFF']
                        ]
                    },
                    borderWidth: 0,
                    formatter: function () {
                        //let fistSeries = this.series.tooltipOptions.seriesData[1];
                        //let secondSeries = this.series.tooltipOptions.seriesData[0];
                        return '<b>' + this.series.name + '</b> : <b>' + this.y + '%</b><br/>'
                    },
                    style: {
                        color: '#333333',
                        fontSize: "14px",
                        fontFamily: "Roboto"
                    }
                    /* headerFormat: '<b>{series.name}</b><br>',
                    formatter: function(e) {
                    return this.point.category + "<br>" + this.point.y;
                    } */
                },
                yAxis: {
                    title: {
                        text: ' '
                    },
                    gridLineWidth:2
                },
                plotOptions: {
                    scatter: {                        
                        /* jitter: {
                            x: 0.24,
                            y: 0.5
                        }, */
                        marker: {
                            radius: 5,
                            symbol: 'circle'
                        }
                    },
                    line : {                        
                        marker: {
                            radius: 0,
                            symbol: "circle"
                        }, 
                    }
                },
                series: [],
            },            
        }
    },  
    props: {
        widgetData: Object
    },    
    mounted()
    {
        this.getUtilizationByGroupChart();
        /* let selectedFilters = getters.getFilterParams();
        let vehiclesIds = [];
        if(!selectedFilters.vehicles.includes("All"))
        {
            vehiclesIds = selectedFilters.vehicles;
        }
        let startTime = new Date(selectedFilters.calendar.from).getTime();
        let endTime = new Date(selectedFilters.calendar.to).getTime();
        let paramJson = {
          "ids": vehiclesIds,
          "startTime": startTime,
          "endTime": endTime
        }
        dashboardService.getMappedDriverFromVehicles(paramJson).then(result => {
        }); */
    },
    methods: {
        checkAll(){
            let self = this;
            self.checkAllSeries = !self.checkAllSeries;
            let value = self.checkAllSeries;
            self.$refs.utilizationGroupChart.chart.series.forEach(element => {
                    if (value && element.userOptions.ClassName != "groupData" ) {
                    if(element.userOptions.type!="line" || (element.userOptions.type=="line" && self.showSeriesTrendLines))
                    {    
                        element.setVisible(true,false);
                        element.userOptions.setChecked = true;
                    }
                    else{
                        element.setVisible(false,false);
                        element.userOptions.setChecked = true;
                    }                        
                    }
                    else
                    {
                        element.setVisible(false,false);
                        element.userOptions.setChecked = false;                         
                    }
            }); 
            if (value)
                $(".toggleSeriesCheckbox").prop("checked",true)
            else
                $(".toggleSeriesCheckbox").prop("checked",false)

            self.$refs.utilizationGroupChart.chart.redraw(); 
        },
        getUtilizationByGroupChart(){
            let self = this;
            self.loaderHide = false;
            dashboardService.getWidgetData( this.widgetData.api, getters.getFilterParams()).then(returnResult => {
            /*});
            setTimeout(() => {
                
                const returnResult = {
                    "id":"utilizationByGroupChart",
                    "data":{
                        "chartTitle" : "Utilization by Group", 
                        "yAxisTitle":"Utilization Percentage",
                        "categories":["Dec - 2019","Jan - 2020","Feb - 2020","Mar - 2020","Apr - 2020","May - 2020","June - 2020","July - 2020"],
                        "series": [
                            {
                                "name": "Los Angeles",
                                "color":"#4472c4",
                                "id":"series1",
                                "setChecked":true,
                                "data": [[75],[70],[80],[45],[65],[40],[10],[90]],
                                jitter: {
                                    x: -0.30,
                                    y: 0.30
                                },
                            },                    
                            {
                                "name": "San Diego",
                                "color":"#ed7d31",
                                "id":"series2",
                                "type":"scatter",
                                "setChecked":true,
                                "data": [[85],[80],[90],[65],[45],[30],[25],[45]],
                                jitter: {
                                    x: -0.25,
                                    y: 0.25
                                },
                            },
                            {
                                "name": "La golla",
                                "color":"#a5a5a5",
                                "id":"series3",
                                "setChecked":true,
                                "data": [[65],[34],[27],[65],[34],[98],[34],[98]],
                                jitter: {
                                    x: -0.20,
                                    y: 0.20
                                },
                            },
                            {
                                "name": "Carlsbad",
                                "color":"#ffc000",
                                "id":"series4",
                                "setChecked":true,
                                "data": [[11],[22],[33],[44],[55],[66],[77],[88]],
                                jitter: {
                                    x: -0.15,
                                    y: 0.15
                                },
                            },
                            {
                                "name": "Torrance",
                                "color":"#5a9ad5",
                                "id":"series5",
                                "setChecked":true,
                                "data": [[44],[87],[45],[98],[56],[76],[77],[99]],
                                jitter: {
                                    x: -0.10,
                                    y: 0.10
                                },
                            },
                            {
                                "name": "Santa Monica",
                                "color":"#70ad47",
                                "id":"series6",
                                "setChecked":true,
                                "data": [[90],[98],[76],[65],[45],[87],[48],[95]],
                                jitter: {
                                    x: -0.05,
                                    y: 0.05
                                },
                            },
                            {
                                "name": "Cardiff",
                                "color":"#264478",
                                "id":"series7",
                                "setChecked":true,
                                "data": [[63],[93],[84],[94],[49],[74],[49],[74]],
                                jitter: {
                                    x: 0.00,
                                    y: 0.50
                                },
                            },
                            {
                                "name": "Solana Beach",
                                "color":"#9e480e",
                                "id":"series8",
                                "setChecked":true,
                                "data": [[84],[86],[45],[87],[47],[98],[45],[98]],
                                jitter: {
                                    x: 0.05,
                                    y: -0.05
                                },
                            },
                            {
                                "name": "Huntington Beach",
                                "color":"#636363",
                                "id":"series9",
                                "setChecked":true,
                                "data": [[62],[74],[97],[94],[76],[56],[98],[59]],
                                jitter: {
                                    x: 0.10,
                                    y: -0.10
                                },
                            },
                            {
                                "name": "Del mar",
                                "color":"#997300",
                                "id":"series10",
                                "setChecked":true,
                                "data": [[96],[47],[87],[45],[78],[47],[35],[86]],
                                jitter: {
                                    x: 0.15,
                                    y: -0.15
                                },
                            },
                            {
                                "name": "Encinitas",
                                "color":"#997300",
                                "id":"series11",
                                "setChecked":true,
                                "data": [[95],[46],[96],[46],[66],[77],[55],[88]],
                                jitter: {
                                    x: 0.20,
                                    y: -0.20
                                },
                            },
                            {
                                "name": "Costa Mesa",
                                "color":"#43682b",
                                "id":"series12",
                                "setChecked":true,
                                "data": [[94],[34],[75],[28],[64],[49],[37],[83]],
                                jitter: {
                                    x: 0.25,
                                    y: -0.25
                                },
                            },
                            {
                                "name": "Irvine",
                                "color":"#698ed0",
                                "id":"series13",
                                "setChecked":true,
                                "data": [[93],[26],[85],[25],[64],[25],[75],[65]],
                                jitter: {
                                    x: 0.30,
                                    y: -0.30
                                },
                            },
                            {
                                "name": "Oceanside",
                                "color":"#f1975a",
                                "id":"series14",
                                "setChecked":true,
                                "data": [[75],[25],[45],[64],[74],[65],[25],[65]],
                                jitter: {
                                    x: 0.35,
                                    y: -0.35
                                },
                            },
                            {
                                "name": "Long Beach",
                                "color":"#b7b7b7",
                                "id":"series15",
                                "setChecked":true,
                                "data": [[78],[25],[81],[72],[81],[17],[2],[10]],
                                jitter: {
                                    x: 0.40,
                                    y: -0.40
                                },
                            },
                            {
                                "name": "Los Angeles",
                                "type":"line",
                                "showInLegend": false,
                                "linkedTo": 'series1',
                                "visible": false,
                                "color":"#4472c4",                                
                                "data": [[0, 75], [7, 90]],
                                
                            },
                            {
                                "name": "San Diego",
                                "id":"seriesline2",
                                "color":"#ed7d31",
                                "showInLegend": false,
                                "linkedTo": 'series2',
                                "visible": false,
                                "type":"line",
                                "data": [[0, 85], [7, 45]],                                
                            },
                            {
                                "name": "La golla",
                                "color":"#a5a5a5",
                                "showInLegend": false,
                                "linkedTo": 'series3',
                                "visible": false,
                                "type":"line",
                                "data": [[0, 65], [7, 98]],
                            },
                            {
                                "name": "Carlsbad",
                                "color":"#ffc000",
                                "showInLegend": false,
                                "linkedTo": 'series4',
                                "visible": false,
                                "type":"line",
                                "data": [[0, 11], [7, 88]],
                            },
                            {
                                "name": "Torrance",
                                "color":"#5a9ad5",
                                "showInLegend": false,
                                "linkedTo": 'series5',
                                "visible": false,
                                "type":"line",
                                "data": [[0, 44], [7, 99]],
                            },
                            {
                                "name": "Santa Monica",
                                "color":"#70ad47",
                                "showInLegend": false,
                                "linkedTo": 'series6',
                                "visible": false,
                                "type":"line",
                                "data": [[0, 90], [7, 95]],
                            },
                            {
                                "name": "Cardiff",
                                "color":"#264478",
                                "showInLegend": false,
                                "linkedTo": 'series7',
                                "visible": false,
                                "type":"line",
                                "data": [[0, 63], [7, 74]],
                            },
                            {
                                "name": "Solana Beach",
                                "color":"#9e480e",
                                "showInLegend": false,
                                "linkedTo": 'series8',
                                "visible": false,
                                "type":"line",
                                "data": [[0, 84], [7, 98]],
                            },
                            {
                                "name": "Huntington Beach",
                                "color":"#636363",
                                "showInLegend": false,
                                "linkedTo": 'series9',
                                "visible": false,
                                "type":"line",
                                "data": [[0, 62], [7, 59]],
                            },
                            {
                                "name": "Del mar",
                                "color":"#997300",
                                "showInLegend": false,
                                "linkedTo": 'series10',
                                "visible": false,
                                "type":"line",
                                "data": [[0, 96], [7, 86]],
                            },
                            {
                                "name": "Encinitas",
                                "color":"#997300",
                                "showInLegend": false,
                                "linkedTo": 'series11',
                                "visible": false,
                                "type":"line",
                                "data": [[0, 95], [7, 88]],
                            },
                            {
                                "name": "Costa Mesa",
                                "color":"#43682b",
                                "showInLegend": false,
                                "linkedTo": 'series12',
                                "visible": false,
                                "type":"line",
                                "data": [[0, 94], [7, 83]],
                            },
                            {
                                "name": "Irvine",
                                "color":"#698ed0",
                                "showInLegend": false,
                                "linkedTo": 'series13',
                                "visible": false,
                                "type":"line",
                                "data": [[0, 93], [7, 65]],
                            },
                            {
                                "name": "Oceanside",
                                "color":"#f1975a",
                                "showInLegend": false,
                                "linkedTo": 'series14',
                                "visible": false,
                                "type":"line",
                                "data": [[0, 75], [7, 65]],
                            },
                            {
                                "name": "Long Beach",
                                "color":"#b7b7b7",
                                "showInLegend": false,
                                "visible": false,
                                "linkedTo": 'series15',
                                "type":"line",
                                "data": [[0, 78], [7, 10]],
                            },
                            {
                                "name": "Group Average",
                                "color":"#000000",  
                                "showInLegend": false,  
                                "ClassName":"groupData",                    
                                "visible": false,
                                "data": [[0,75],[1,70],[2,80],[3,45],[4,65],[5,40],[6,0],[7,90]]
                            },
                            {
                                "name": "Group Trends",
                                "type":"line",
                                "id":"GroupTrends",
                                "ClassName":"groupData",
                                "showInLegend": false,                                
                                "visible": false,
                                "color":"#000000",
                                "data": [[0, 75], [7, 90]],
                                jitter: {
                                    x: -0.10,
                                    y: 0.10
                                },
                            }
                        ]
                    }                
                }; */
                
                let seriesData = returnResult.data.series;
                seriesData.forEach((element,key) => {
                   
                    if(element.showInLegend!=false)
                    {
                        //element.jitter.x = (key * 0.50)*0.05 - 0.50;                        
                        //element.shortName = ;                        
                    }
                    
                });
                self.loaderHide = true;   
                self.chartTitle = returnResult.data.chartTitle;
                self.chartOptions.series = returnResult.data.series;
                self.chartOptions.xAxis.categories = returnResult.data.categories;
                self.chartOptions.yAxis.title.text = returnResult.data.yAxisTitle;
                setTimeout(() => {
                    window.dispatchEvent(new Event('resize'));
                }, 100);
                             
            });
        },        
        toggleSeries(id) {   
            this.$refs.utilizationGroupChart.chart.series[id].setVisible(!this.$refs.utilizationGroupChart.chart.series[id].visible);
            this.$refs.utilizationGroupChart.chart.series[id].userOptions.setChecked = !this.$refs.utilizationGroupChart.chart.series[id].userOptions.setChecked;
            
            // this.chartOptions.series[id].setChecked = !this.$refs.utilizationGroupChart.chart.series[id].userOptions.setChecked;
            if(!this.showSeriesTrendLines)
                this.$refs.utilizationGroupChart.chart.series[id].linkedSeries[0].setVisible(false);
            
            let totalLength = $(".toggleSeriesCheckbox:checkbox").length;
            let totalCheckedLength = $(".toggleSeriesCheckbox:checkbox:checked").length;
            if(totalLength != totalCheckedLength)
            {                
                this.checkAllSeries = false;
                $(".check-all").prop("checked",false)                
            }
            else
            {
                this.checkAllSeries = true;
                $(".check-all").prop("checked",true)
            }            
        },
        showTrendLines()
        {
            //this.showSeriesTrendLines = !this.showSeriesTrendLines;
            //if(this.showSeriesTrendLines)
            this.$refs.utilizationGroupChart.chart.series.forEach(element => {
                if(element.userOptions.type=="line" && element.userOptions.ClassName != "groupData")
                {
                    if(this.showSeriesTrendLines && element.linkedParent.visible)
                        element.setVisible(true,false);
                    else
                        element.setVisible(false,false);
                }
            });
            this.$refs.utilizationGroupChart.chart.redraw();
        },
        showGroupAverage(){ 
            let self = this;             
            self.$refs.utilizationGroupChart.chart.series.forEach(element => {
                if(self.showGroupAverageChart)
                {   
                    if(element.userOptions.ClassName == "groupData")
                    {
                        element.setVisible(true,false);
                    }
                    else
                    {
                        element.setVisible(false,false);
                    }                    
                }
                else
                {
                    if(element.userOptions.ClassName == "groupData")
                    {
                        element.setVisible(false,false);
                    }
                    else
                    {
                        if(element.userOptions.type!="line" || (element.userOptions.type=="line" && self.showSeriesTrendLines))
                        {                             
                            if(element.userOptions.setChecked)
                            {                                
                                element.setVisible(true,false);                                
                            }
                            /* else
                            {
                                element.setVisible(false,false);
                                $(element.userOptions.id).prop("checked",false)
                            } */
                        }
                        else
                            element.setVisible(false,false);
                        
                    }                    
                }                 
            });
            self.$refs.utilizationGroupChart.chart.redraw();
        }
    }
};

</script>
<style scoped>
/*
=============================================================
Please put your custom CSS below (CTrack BS Row Structure) 
============================================================
*/
/*CTrack BS Row Structure (Please don't change or use this CSS)*/
.ctrack-row{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-right:-10px;margin-left:-10px;}
[class*="ctrack-col"]{position:relative;width:100%;min-height:1px;padding-right:10px;padding-left:10px;}
.ctrack-d-flex{display:-webkit-box!important;display:-ms-flexbox!important;display:flex!important;}
.ctrack-align-items-center{-webkit-box-align:center!important;-ms-flex-align:center!important;align-items:center!important;}
.ctrack-justify-content-center{-webkit-box-pack:center!important;-ms-flex-pack:center!important;justify-content:center!important;}
.ctrack-h-100{height: 100%;}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}

.ctrack-col{-ms-flex-preferred-size:0;flex-basis:0;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;max-width:100%;}
.ctrack-col-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}

@media only screen and (min-width:576px){
.ctrack-col-sm-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-sm-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-sm-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-sm-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-sm-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-sm-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-sm-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-sm-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-sm-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-sm-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-sm-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-sm-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}

@media only screen and (min-width:768px){
.ctrack-col-md-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-md-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-md-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-md-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-md-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-md-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-md-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-md-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-md-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-md-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-md-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-md-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}

@media only screen and (min-width:992px){
.ctrack-col-lg-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-lg-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-lg-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-lg-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-lg-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-lg-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-lg-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-lg-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-lg-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-lg-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-lg-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-lg-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}

@media only screen and (min-width:1200px){
.ctrack-col-xl-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-xl-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-xl-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-xl-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-xl-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-xl-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-xl-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-xl-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-xl-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-xl-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-xl-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-xl-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}

/*CTrack BS Row Structure (Please don't change or use above CSS)*/
/*
=============================================================
Start your custom CSS from here
============================================================
*/

/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
*{font-family: 'Roboto', sans-serif;}
.ctrack-h-100{height: 100%;}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.cd-chart-innerbox{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;width:100%;height:100%;background-color:#FFF;border-radius:5px;align-content: space-between; -webkit-align-content: space-between;}
.cd-chart-box-middle{padding:20px 30px;-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
.cd-chart-box-bottom{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;border-top:1px solid #E8E8E8;margin-top:20px;padding:18px 0 0;}
.cd-chart-box-top{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;padding:15px 20px;border-bottom:1px solid #E8E8E8;}
.cd-chart-box-img {text-align: center;}
.cd-chart-indicator-style1{padding:0 35px 8px;color:#333333;display:inline-block;width:100%;font-size:15px;font-weight:500;}
.cd-chart-indicator-inner{display:inline-block;width:100%;position:relative;padding-left:32px;margin-bottom:12px;}
.cd-chart-indicator-inner .cd-chart-indicator-color,.cd-chart-indicator-inner .cd-chart-indicator-color-line{position:absolute;left:0;top:50%;width:25px;height:11px;border-radius:3.5px;transform:translateY(-50%);}

/* Rounded Toggle Button*/
.ctrack-switch{position:relative;display:inline-block;vertical-align: middle;width:31px;height:19px;}
.ctrack-switch input{opacity:0;width:0;height:0;}
.ctrack-slider{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:#999999;-webkit-transition:.4s;transition:.4s;}
.ctrack-slider:before{position:absolute;content:"";height:15px;width:15px;left:2px;bottom:2px;background-color:white;-webkit-transition:.4s;transition:.4s;}
.ctrack-switch input:checked + .ctrack-slider{background-color:#40C1AC;}
.ctrack-switch input:focus + .ctrack-slider{box-shadow:0 0 1px #002B49;}
.ctrack-switch input:checked + .ctrack-slider:before{-webkit-transform:translateX(12px);-ms-transform:translateX(12px);transform:translateX(12px);}
.ctrack-slider.ctrack-round{border-radius:10px;}
.ctrack-slider.ctrack-round:before{border-radius:50%;}


.cd-chart-box-header{display:table;width:100%;}
.cd-chart-box-header > div{display:table-cell;vertical-align: middle;}
.cd-chart-box-col2{text-align:right;}
.cd-chart-box-col1 h3{color:#013C5E;font-size:18px;font-weight:700;}
.cd-chart-box-col1 h3 span{color:#979797;font-weight:400;}
.cd-chart-box-col2{font-size:14px;font-weight:bold;color:#333333;}
.cd-chart-indicator-style2{display:inline-block;width:100%;text-align:center;padding:0 25px 15px;}
.driver-rating-indicator-icon{display:inline-block;width:100%;color:#D7D7D7;}
.driver-rating-indicator-title{display:inline-block;width:100%;color:#333333;font-weight:500;}
.driver-rating-indicator-value{display:inline-block;width:100%;color:#999999;font-size:17px;font-weight:500;}
.indicator-success-color{color:#8BC53F;font-size:26px;font-weight:500;}
.driver-rating-indicators{display:inline-block;width:100%;}

.d-chart-box-inner-left{display:inline-block;width:100%;height: 100%;padding: 12px 0 12px 15px;}
.cd-chart-box-inner-right{display:inline-block;width:100%;height: 100%;border-left: 1px solid #EBEBEB;padding:0 30px 0 20px;}
.cd-chart-box-right-title{display:inline-block;width:100%;color:#CCCCCC;font-size:15px;font-weight:500;text-transform:uppercase;letter-spacing:3px;margin:18px 0 25px;}
.cd-chart-indicator-right{display:inline-block;width:100%;}
.cd-chart-indicator-right .cd-chart-indicator-inner{padding:0 35px 0 40px;color:#333333;font-size:15px;font-weight:500;margin-bottom:30px;position: relative;}
.cd-chart-indicator-right .cd-chart-indicator-inner:before {content: "";position: absolute;bottom: -15px;left: 0;width: 100%;height: 1px;background-color: #EBEBEB;}
.cd-chart-indicator-right .ctrack-right-toggle-btns{position:absolute;right:0;top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%);}
.cd-chart-indicator-right .ctrack-col-12:last-child .cd-chart-indicator-inner:before{display:none;}
.ctrack-d-flexcol{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}

.cd-chart-toggles-main{display:inline-block;width:100%;padding:0 20px 20px;}
.cd-chart-toggles-main h5{font-size:16px;color:#333333;font-weight:500;}
.cd-chart-check-toggles{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.md-chart-toggle-repeater{position:relative;width:100%;min-height:1px;padding-right:15px;padding-top:10px;-ms-flex-preferred-size:0;flex-basis:0;max-width:100%;white-space:nowrap;}
.md-chart-toggle-repeater .md-chart-toggle-label{display:inline-block;vertical-align:middle;padding-right:8px;font-size: 15px;color: #8a8a8a;}
.md-chart-toggle-repeater .md-chart-toggle-label:last-child{padding-right:0;}

.cd-chart-check-toggles.cd-worst-best-section{display:inline-block;vertical-align:middle;position: relative;}
.cd-worst-best-section .md-chart-toggle-repeater{padding:0;text-align: left;}
.cd-worst-best-section .md-chart-toggle-repeater .ctrack-switch + .md-chart-toggle-label{padding-left:8px;color:#333333;}
.cd-worst-best-section .md-chart-toggle-repeater .md-chart-toggle-label{font-size:13px;font-weight:400;color:#999999;}
.cd-worst-best-section .ctrack-switch input:checked + .ctrack-slider{background-color:#40C1AC;}
.cd-worst-best-section .ctrack-slider{background-color:#999999;}

.md-chart-toggle-repeater-pos{position:absolute;bottom:-50px;}
.chart-with-right-widget-style2{padding:35px 0 10px;}
.ctrack-col-second{/*max-width:190px;*/padding-top:40px;padding-bottom:45px;padding-left:0;}
.cd-chart-box-inner-right-style2 .ctrack-switch{width:21px;height:13px;top:-1px;}
.cd-chart-box-inner-right-style2 .ctrack-slider::before{width:9px;height:9px;}
.cd-chart-box-inner-right-style2 .ctrack-switch input:checked + .ctrack-slider:before{-webkit-transform:translateX(8px);transform:translateX(8px);}
.cd-chart-indicator-blank-right{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-align:center!important;-ms-flex-align:center!important;align-items:center!important;height:100%;color:#333333;}
.cd-chart-indicator-blank-right ul{display:inline-block;width:100%;}
.cd-chart-indicator-blank-right ul li{display:inline-block;width:100%;position:relative;font-weight:500;margin:2px 0;}

/* Chart Box Right Style 02 */
.cd-chart-box-inner-right-style2{border:0;padding:0 20px 0 0;position:relative;}
.cd-chart-box-inner-right-style2 .cd-chart-indicator-right{padding:0 10px;position:absolute;top:0;right:0;bottom:0;height:100%;overflow:auto;}
.cd-chart-box-inner-right-style2 .cd-chart-indicator-right .cd-chart-indicator-inner{padding:0 0 0 42px;margin-bottom:0;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
.cd-chart-box-inner-right-style2 .cd-chart-indicator-right .cd-chart-indicator-inner:before{display:none;}
.cd-chart-box-inner-right-style2 .cd-chart-indicator-inner .cd-chart-indicator-color{width:9px;height:9px;border-radius:100%;left:30px;margin-top:-1px;}
.cd-chart-box-inner-right-style2 .cd-chart-indicator-inner .cd-chart-indicator-color-line{width:25px;height:2px;border-radius:3.5px;left:14px;margin-top:-1px;}
.cd-chart-box-inner-right-style2 .cd-chart-indicator-right .ctrack-right-toggle-btns{right:auto;left:0;}



/* Responsive CSS */
@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){
.chart-with-right-widget > .ctrack-col-xl-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 73.666667%;max-width:73.666667%;}
.chart-with-right-widget > .ctrack-col-xl-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 26.333333%;max-width:26.333333%;}
}

@media only screen and (min-width:1400px){


}
@media only screen and (max-width:1399px){}
@media only screen and (max-width:1299px){}
@media only screen and (max-width:1199px){}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){
  .d-chart-box-inner-left{padding-right: 15px;}
  .cd-chart-box-inner-right{padding: 0 15px;}
  .cd-chart-box-right-title{margin-top: 10px;}
  .ctrack-col-second{padding-top:10px;padding-bottom:15px;}

}
@media only screen and (max-width:767px){}
@media only screen and (max-width:575px){
  .cd-chart-box-middle {padding: 15px}
  .cd-chart-box-header > div{display: inline-block;width: 100%;}
  .cd-chart-box-col2{text-align: left; margin-top: 5px;}
  .md-chart-toggle-repeater-pos{position: static;margin-top: 5px;}
  .ctrack-col-second{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
  .ctrack-col-second{padding-left: 15px;}
  .cd-chart-box-inner-right-style2 .cd-chart-indicator-right{position: static;max-height: 200px;}
  .ctrack-col-second {padding-bottom: 0}
  .cd-chart-box-header{position: relative;padding-right: 30px;}
  .md-view-detail-link{position:absolute;right:0;top:2px;}

}

</style>