import axios from 'axios';

class ruleService {
    //#region Policies
    createNewAlertPolicy(body) {
        return axios.post(`/api/rule/CreateNewAlertPolicy`, body);
    }
    getAllRulePolicy(params, id) {
        if (id) {
            return axios.get(`/api/rule/GetRulePolicy/${id}`, { params: params });
        } 
        return axios.get(`/api/rule/GetAllRulePolicy`, { params: params });
    }
    getRulePoliciesAssignments(params) {
        return axios.get(`/api/rule/GetRulePoliciesAssignments`, { params: params });
    }
    editPolicy(body, id) {
        return axios.put(`/api/rule/EditPolicy/${id}`, body);
    }
    deleteById(id) {
        return axios.delete(`/api/rule/DeleteById/${id}`);
    }
    multipleDeletePolicy(body) {
        return axios.delete(`/api/rule/MultipleDeletePolicy`, { data: body });
    }
    clonePolicy(body) {
        return axios.post(`/api/rule/ClonePolicy`, body);
    }
    getPolicyByVehicles(body) {
        return axios.post(`/api/rule/GetPolicyByVehicles`, body);
    }
    getPolicyByUserIds(body) {
        return axios.post(`/api/rule/GetPolicyByUserIds`, body);
    }
    //#endregion

    //#region Alerts
    updateAlertAction(body) {
        return axios.put(`/api/rule/UpdateAlertAction`, body);
    }
    getNotActionCount(params = {}) {
        return axios.get(`/api/rule/GetNotActionCount`, { params: params });
    }
    getAlertsByIds(body) {
        return axios.post(`/api/rule/GetAlertsByIds`, body);
    }
    getRuleInfo(startTIme, endTIme) {
        const params = {
            'startTIme': startTIme,
            'endTIme': endTIme,
        }
        return axios.get(`/api/rule/GetRuleInfo`, { params: params });
    }
    gettripFleetAlerts(starttime, endtime, vehicleId, driverId, alertType) {
        const params = {
            starttime: starttime,
            endtime: endtime,
            vehicleId: vehicleId,
            driverId: driverId,
            alertType: alertType
        }
        return axios.get(`/api/rule/GetTripFleetAlerts`, { params: params });
    }
     getAlertAudit(starttime, endtime, vehicleId) {
        const params = {
            starttime: starttime,
            endtime: endtime,
            vehicleId: vehicleId
        }
        return axios.get(`/api/rule/GetAlertAudit`, { params: params });
    }
    getActionedAlertSummary(starttime, endtime, actionValue) {
        const params = {
            starttime: starttime,
            endtime: endtime,
            actioned: actionValue
        }
        return axios.get(`/api/rule/GetActionedAlertSummary`, { params: params });
    }
    getAlertSummaryByCategory(starttime, endtime, alertType) {
        const params = {
            starttime: starttime,
            endtime: endtime,
            alertType: alertType
        }
        return axios.get(`/api/rule/GetAlertSummaryByCategory`, { params: params });
    }
    getAlertNotification(params = {}) {
        return axios.get(`/api/rule/GetAlertNotification`, { params: params });
    }

    //#endregion

    getRuleSuppressionActions() {
        return axios.get(`/api/rule/GetRuleSuppressionActions`);
    }
    getSuppressableStatuses() {
        return axios.get(`/api/rule/GetSuppressableStatuses`);
    }
    getSuppressedStatuses() {
        return axios.get(`/api/rule/GetSuppressedStatuses`);
    }
}
export default new ruleService();