import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { PublicClientApplication, } from "@azure/msal-browser";
var APPConfig = {};
class UserService {
    getAppConfig() {
        return axios.get('/app/value');
    }

    localisationCulture() {
        return axios.get('/api/localisation/LocalisationCulture');
    }

    localisationLanguage(culture) {
        return axios.get(`/api/localisation/localisationLanguage/${culture}`);
    }

    autoLogin() {
        return axios.post('/api/account/autologin', {
            bearerToken: this.getCookie("bearerToken"),
            computerId: this.getComputerId()
        });
    }

    follow(model) {
        return axios.post('/api/account/follow', model);
    }

    isAuthenticated() {
        return axios.get('/api/account/isAuthenticated');
    }

    async login(username, password, remember) {
        if (!APPConfig.Msal) {
            APPConfig = await this.getAppConfig();
        };
        if (APPConfig.Msal.Enabled) {
            const value = username.trim().toLowerCase();
            const isLocalUser = APPConfig.Msal.Suffixes.some(suffix => value.endsWith(suffix));
            if (isLocalUser) {
                return this.localLogin(username, password, remember);
            }
        }
        return axios.post('/api/account', {
            userName: username,
            password: password,
            remember: remember,
            computerId: this.getComputerId()
        });
    }

    async localLogin(username, password, remember) {
        if (!APPConfig.Msal) {
            APPConfig = await this.getAppConfig();
        };
        const msalConfig = {
            auth: {
                clientId: APPConfig.Msal.ClientId,
                authority: `https://login.microsoftonline.com/${APPConfig.Msal.TenantId}`,
                redirectUri: '/ms',
                navigateToLoginRequestUrl: false
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: false
            },
            system: {
                loggerOptions: {
                    loggerCallback: (level, message, containsPii) => {
                        if (containsPii) {
                            return;
                        }
                        console.log(level);
                        console.log(message);
                    },
                },
            },
        };
        const msalInstance = await PublicClientApplication.createPublicClientApplication(msalConfig);
        return msalInstance.loginPopup({
            scopes: [],
        })
            .then((response) => {
                return axios.post('/api/account', {
                    userName: username,
                    password: password,
                    remember: remember,
                    computerId: this.getComputerId(),
                    ctrackUsername: response.account.username
                });
            })
            .catch(error => {
                console.error(error);
            });
    }

    logout() {
        this.removeCookie("bearerToken");
        this.removeCookie("rememberme");
        return axios.get('/api/account/logout').then(() => {
            const authChannel = new BroadcastChannel('auth');
            this.removeCookie("bearerToken");
            this.removeCookie("rememberme");
            authChannel.postMessage('logout');
        });
    }

    resetPasswordLink(username) {
        return axios.get(`/api/account/forgot/${username}`);
    }

    getAllWorkingHours(body) {
        return axios.post(`/api/configuration/GetAllWorkingHours`, body)
    }

    getPasswordPolicy(body) {
        return axios.post('/api/account/passwordPolicy', body);
    }

    getComputerId() {
        let computerId = this.getCookie("computerId");
        if (computerId == '') {
            computerId = uuidv4();
        }
        this.setCookie("computerId", computerId, 30);
        return computerId;
    }

    getCookie(cname) {
        // get Cookie
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(";");
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    setCookie(cname, cvalue, exdays) {
        // Set Cookie
        let d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        let expires = "expires=" + d.toGMTString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    removeCookie(name) {
        // remove Cookie
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }

    twoFactorAuthentication(body) {
        return axios.post('/api/account/2fa/otp', body);
    }

    resendOTP(id) {
        return axios.get(`/api/account/2fa/otp/resend/${id}`);
    }

    getPasswordHint(username) {
        return axios.get(`/api/account/hint/${username}`);
    }

    sendEmailtoUser(username) {
        return axios.get(`/api/account/forgot/${username}`);
    }

    validateTwoFactorAuthentication(body) {
        return axios.post('/api/account/2fa/otp/validate', body);
    }

    getUserClaimsSlow(userid) {
        return axios.get(`/api/rbac/getUserClaims/${userid}`)
    }
    //user is signed in so just echo claims
    getUserClaims(userid) {
        return axios.get(`/api/account/getUserClaims/${userid}`)
    }
    refreshToken() {
        return axios.get(`/api/account/keepalive/${this.getComputerId()}/`);
    }

    switchTenant(tenantId) {
        return axios.get(`/api/account/switchTenant/${tenantId}`);
    }
}

export default new UserService();
