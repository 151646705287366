<template>
  <div class="bpMileageWidget ctrack-h-100" ref="bpMileage">
    <div id="vueapp" class="vue-app ctrack-h-100">
        
      <!-- B/P Mileage Widget -->
        <div class="dBehaviour-inner withLblValueInner" >        
          <div class="dBehaviour-box-col">
            <div class="dBehaviour-box-icon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="28" height="27.993" viewBox="0 0 28 27.993"><path d="M1205.888,207.989a1.452,1.452,0,0,1-1.555-1.518v-1.519h-18.667v1.519a1.453,1.453,0,0,1-1.556,1.518h-1.555a1.452,1.452,0,0,1-1.555-1.518v-12.15l3.266-9.113a2.192,2.192,0,0,1,2.178-1.518h1.332c-.014.185-.056.363-.056.552a7.054,7.054,0,0,0,.235,1.726h-1.511l-2.334,6.834h21.778l-2.334-6.834h-1.51a7.054,7.054,0,0,0,.235-1.726c0-.189-.041-.366-.056-.552h1.331a2.335,2.335,0,0,1,2.178,1.518l3.267,9.113v12.15a1.453,1.453,0,0,1-1.555,1.518Zm-4.667-9.871a2.334,2.334,0,1,0,2.333-2.279A2.364,2.364,0,0,0,1201.222,198.118Zm-17.112,0a2.334,2.334,0,1,0,2.334-2.279A2.365,2.365,0,0,0,1184.11,198.118Zm13.28-9.182-2.39-2.369-2.389,2.369a.486.486,0,0,1-.683,0l-1.365-1.354a.475.475,0,0,1,0-.677l2.389-2.369-2.389-2.369a.476.476,0,0,1,0-.678l1.365-1.354a.486.486,0,0,1,.683,0l2.389,2.369,2.39-2.369a.484.484,0,0,1,.682,0l1.365,1.354a.476.476,0,0,1,0,.678l-2.389,2.369,2.389,2.369a.475.475,0,0,1,0,.677l-1.365,1.354a.484.484,0,0,1-.682,0Z" transform="translate(-1181 -179.996)"/></svg></div>
            <div class="dBehaviour-box-content">
              <h3 class="withLblValue" :class="businessWidgetColorClass">
                <span class="ctrack-text-truncate">{{businessWidgetTitle}}</span>
                <label><span class="dBehaviour-box-value-icon" :class="businessWidgetIcon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10"><path d="M638.392,193H628.62a.626.626,0,0,1-.527-.962c.213-.383,4.509-8.038,4.876-8.691a.6.6,0,0,1,1.059,0c.269.47,4.587,8.154,4.887,8.711A.623.623,0,0,1,638.392,193Z" transform="translate(-628 -183.028)" /></svg></span>{{businessWidgetValue}}</label>
              </h3>
              <h3 class="withLblValue" :class="privateWidgetColorClass">
                <span class="ctrack-text-truncate">{{privateWidgetTitle}}</span>
                <label><span class="dBehaviour-box-value-icon" :class="privateWidgetIcon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10"><path d="M638.392,193H628.62a.626.626,0,0,1-.527-.962c.213-.383,4.509-8.038,4.876-8.691a.6.6,0,0,1,1.059,0c.269.47,4.587,8.154,4.887,8.711A.623.623,0,0,1,638.392,193Z" transform="translate(-628 -183.028)" /></svg></span>{{privateWidgetValue}}</label>
              </h3>
              <p class="md-uw-value">{{widgetTitle}}</p>
              <!-- <p class="md-uw-value">{{widgetValue}}</p> -->
            </div>
          </div>        
        </div>
        <!-- B/P Mileage Widget -->
    </div>    
  </div>
</template>

<script>
import dashboardService from "../../../service/dashboard.service";
import { mutations, getters } from "../shared/observeDashboardFilter";
export default {
  name: 'bpMileageWidget',
  components: {},
  data: function() {
    return {
      widgetTitle:"",
      widgetValue:"",
      businessWidgetColorClass:"",
      businessWidgetIcon:"",      
      businessWidgetValue:"0",
      businessWidgetTitle:"-",
      privateWidgetColorClass:"",
      privateWidgetIcon:"",      
      privateWidgetValue:"0",
      privateWidgetTitle:"-",
      compareWidgetIcon:"",
      compareWidgetColorClass:"" 
    }
  },
  props: {
    widgetData: Object
  }, 
  created(){
    this.getBpMileageWidgetData();        
  },   
  methods: {
    getBpMileageWidgetData()
    {   
      let self = this;  
      dashboardService.getWidgetData( this.widgetData.api, getters.getFilterParams()).then(returnResult => {
        self.widgetTitle = returnResult.hwTitle;
        // self.widgetValue = returnResult.value + returnResult.type;
        self.businessWidgetTitle = returnResult.businessTitle;
        self.businessWidgetValue = returnResult.businessValue + returnResult.businessType;      
        self.businessWidgetIcon =  (returnResult.businessIcon == "down") ? "md-chart-arrow-down" : (returnResult.businessIcon == "up") ? "md-chart-arrow-up" : "";
        self.businessWidgetColorClass = (returnResult.businessColor == "green") ? "success-color" : (returnResult.businessColor == "red") ? "error-color" : "";

        self.privateWidgetTitle = returnResult.privateTitle;
        self.privateWidgetValue = returnResult.privateValue + returnResult.privateType;      
        self.privateWidgetIcon =  (returnResult.privateIcon == "down") ? "md-chart-arrow-down" : (returnResult.privateIcon == "up") ? "md-chart-arrow-up" : "";
        self.privateWidgetColorClass = (returnResult.privateColor == "green") ? "success-color" : (returnResult.privateColor == "red") ? "error-color" : "";
      });       
      
    }
  }
};

</script>
<style scoped>
/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
*{font-family: 'Roboto', sans-serif;}
.ctrack-h-100{height: 100%;}
.error-color .dBehaviour-box-col h3,h3.error-color{color:#FA4B4B;}
.success-color .dBehaviour-box-col h3,h3.success-color{color:#76CD24;}
[class*="md-chart-arrow"]{display:inline-block;vertical-align:middle;}
[class*="md-chart-arrow"] svg{display:block;}
.md-chart-arrow-down{transform:rotate(180deg); -webkit-transform:rotate(180deg); -moz-transform:rotate(180deg); -ms-transform:rotate(180deg); -o-transform:rotate(180deg);}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}

.dBehaviour-inner{display:inline-block;width:100%;height:100%;background-color:#FFF;padding:13px 20px;border-radius: 5px; box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, .05); -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, .05);}
.dBehaviour-box-row{display:inline-block;width:100%;padding-left:40px;position:relative;}
.dBehaviour-box-col{display:inline-block;vertical-align: middle;position:relative;width:100%;text-align:right;padding-left:40px;}
.dBehaviour-box-icon{position:absolute;color:#656565; left:0;top:50%;transform:translateY(-50%); -webkit-transform:translateY(-50%); -moz-transform:translateY(-50%); -ms-transform:translateY(-50%); -o-transform:translateY(-50%);}
.dBehaviour-box-content{display:block;width:100%;padding-left:5px;}
.dBehaviour-box-content h3{font-size:20px;font-weight:500;}
.dBehaviour-box-value-icon{margin-right:8px;}
.dBehaviour-box-content p{font-size:15px;color:#333333;font-weight:500;}
.dBehaviour-box-content p.md-uw-value{font-size:18px;color: #999999;font-weight: 400;}

.dBehaviour-box-content h3.withLblValue{white-space:nowrap;display:-webkit-box;display:-ms-flexbox;display:flex;justify-content: space-between;}
.dBehaviour-box-content h3.withLblValue .ctrack-text-truncate{font-size:16px;color:#333333;font-weight:500;padding-right:5px;}
.dBehaviour-inner.withLblValueInner{padding:13px 10px;}
.withLblValueInner .dBehaviour-box-value-icon{margin-right:6px;}
.withLblValueInner .dBehaviour-box-value-icon svg{width:10px;}
.withLblValueInner h3.withLblValue label{font-size:20px;}


/* Responsive CSS */
@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1399px){}
@media only screen and (max-width:1299px){}
@media only screen and (max-width:1199px){}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){}
@media only screen and (max-width:767px){}
@media only screen and (max-width:575px){}

</style>