<template>
    <td :class="className">{{ result }}</td>
</template>
<script>
    export default {
        name: "FormatCell",
        props: {
            field: String,
            dataItem: Object,
            format: String,
            className: String,
            columnIndex: Number,
            columnsCount: Number,
            rowType: String,
            level: Number,
            expanded: Boolean,
            editor: String
        },
        computed: {
            result() {
                return this[this.editor](this.dataItem[this.field]);
            },
        },
    };
</script>
<style scoped>
    .rightAlign {
        text-align: right;
        padding-right: 16px !important;
    }
</style>