<template>
  <div class="DriveTimeWidget ctrack-h-100" ref="DriveTime">
    <div id="vueapp" class="vue-app ctrack-h-100">

      <!-- Drive Time Chart -->
      <div class="dExecutive-inner success-color">
        <div class="dExecutive-title"><h4><span class="dExe-title-icon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23"><path d="M1129.444,676a1.186,1.186,0,0,1-1.278-1.231v-1.231h-15.333v1.231a1.185,1.185,0,0,1-1.277,1.231h-1.278a1.186,1.186,0,0,1-1.278-1.231v-9.85l2.684-7.386a1.8,1.8,0,0,1,1.788-1.231h1.733a5.006,5.006,0,0,0-.11,1.045,5.131,5.131,0,0,0,.064.8h-1.687l-1.916,5.54h17.888l-1.916-5.54h-1.572a5.061,5.061,0,0,0-.045-1.847h1.617a1.918,1.918,0,0,1,1.788,1.231l2.684,7.386v9.85a1.186,1.186,0,0,1-1.278,1.231Zm-3.833-8a1.918,1.918,0,1,0,1.917-1.847A1.93,1.93,0,0,0,1125.611,668Zm-14.055,0a1.918,1.918,0,1,0,1.916-1.847A1.929,1.929,0,0,0,1111.556,668Zm4.46-10.651a4.547,4.547,0,1,1,4.542,4.347A4.46,4.46,0,0,1,1116.015,657.346Zm4.088.435,2.362,1.391.363-.565-2.044-1.174v-2.261h-.681Z" transform="translate(-1109 -653)"/></svg></span> Drive Time</h4></div>
        <div class="dExecutive-content">
          <div class="dExe-chart-row ctrack-align-end">
            <div class="dExe-chart-col1">
              <h3>
                <span class="dExe-value-icon md-chart-arrow-up"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="22" height="11" viewBox="0 0 22 11"><path d="M107.008,284.158l11.223-11.223,11.223,11.223Z" transform="translate(-107.008 -272.935)"/></svg></span>7%
              </h3>
              <div class="dExe-chart-labels">
                <div class="dExe-chart-lbl">
                  <span class="dExe-chart-lbl-icon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17"><path d="M1127.5,814a8.5,8.5,0,1,1,8.5-8.5A8.51,8.51,0,0,1,1127.5,814Zm-.85-12.749v5.1l4.42,2.721.68-1.105-3.825-2.3v-4.42Z" transform="translate(-1119 -797)"/></svg></span>45 hr.
                </div>
                <div class="dExe-chart-lbl md-chart-color">
                  <span class="dExe-chart-lbl-icon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17"><path d="M1127.5,814a8.5,8.5,0,1,1,8.5-8.5A8.51,8.51,0,0,1,1127.5,814Zm-.85-12.749v5.1l4.42,2.721.68-1.105-3.825-2.3v-4.42Z" transform="translate(-1119 -797)"/></svg></span>47 hr
                </div>
              </div>
            </div>
            <div class="dExe-chart-col2">
              <!-- <div class="dExe-chart-main"><img src="../../../assets/images/dashboard/md-drive-time-graph.svg" /></div> -->
              <div class="ctrack-md-loader" :class="loaderHide?'ctrack-chart-hide-loader':''"></div>
              <div class="dExe-chart-main"> 
                <highcharts :options="chartOptions" :class="loaderHide?'':'ctrack-chart-hide-loader'"></highcharts>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Drive Time Chart -->
        
    </div>    
  </div>
</template>

<script>
export default {
  name: 'drivetimechart',
  components: {},
 data: function() {        
        return {   
          loaderHide:false,          
            chartOptions:{              
                    chart: {
                      type: 'column',
                      height:190,
                      width:110 
                      },
                  title: {
                      text: ''
                  },
                  credits: {enabled: false},       
                  
                 xAxis: {  
                   tickLength: 0, 
                      labels:{
                        enabled:false,   
                          },            
                  },
                yAxis: {       
                  labels: {
                     enabled: false
                 },
                 gridLineWidth: 0,
                 title: {
                        enabled:false
                      } 
                     },
                tooltip: { enabled: false },
                legend: {
                      enabled: false
                  },
              plotOptions: {
                     series: {
                      stacking: 'normal'
                     },
                  legend: {
                   enabled: false
                 }
               },
            series: [{                     
                      borderRadiusTopLeft: '25%',
                      borderRadiusTopRight: '25%'
                      }]
                   }
                 }
             },  
    created(){
      this.getDriveTimeChartData();     
        },  
  methods: {    
    getDriveTimeChartData() {
      setTimeout(() => {
        let self = this;
        self.loaderHide=false;
        const returnResult = {    
             series: [{                 
                  data: [{
                         name: "Last Week",
                         y: 32,                                         
                         color:'#CCCCCC',                                
                        },
                       {
                        name: "This Week",
                        y: 40,                                        
                        color:'#76CD24'
                        }
                      ] 
                   } ],              
            };
         // self.chartOptions.xAxis.categories = returnResult.categories;
          self.chartOptions.series = returnResult.series;
          self.chartTitle = returnResult.chartTitle;
          self.chartTitleDuration = "(" + returnResult.chartTitleDuration + ")";
          self.loaderHide=true; 
      }, 5000);
    }
  }
};

</script>
<style scoped>

/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');*{font-family:'Roboto',sans-serif;}
.ctrack-h-100{height:100%;}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.md-dashboard-content{padding-top:10px;color:#C5C5C5;}
[class*="md-chart-arrow"]{display:inline-block;vertical-align:middle;}
[class*="md-chart-arrow"] svg{display:block;}
.md-chart-arrow-down{transform:rotate(180deg);-webkit-transform:rotate(180deg);-moz-transform:rotate(180deg);-ms-transform:rotate(180deg);-o-transform:rotate(180deg);}
.dExecutive-inner{height:100%;width:100%;display:inline-block;background-color:#FFFFFF;border-top:6px solid #CDCDCD;border-radius:5px;padding:0 20px 20px;}
.dExecutive-title{display:inline-block;width:100%;padding:10px 0;}
.dExecutive-title h4{color:#333333;font-size:20px;font-weight:500;position:relative;padding-left:40px;display:inline-block;width:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
.dExecutive-title h4 .dExe-title-icon{position:absolute;left:0;top:50%;transform:translateY(-50%);}
.dExecutive-title h4 .dExe-title-icon svg{display:block;}
.dExecutive-content{display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;justify-content:space-between;-webkit-justify-content:space-between;height:calc(100% - 60px);}
.dExecutive-main div[class*="ctrack-col-"]{margin-bottom:20px;}
.dExe-chart-row{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:nowrap;flex-wrap:nowrap;-webkit-box-align:center;-ms-flex-align:center;align-items:center;justify-content:space-between;-webkit-justify-content:space-between;width:100%;}
.ctrack-align-end{-webkit-box-align:flex-end;-ms-flex-align:flex-end;align-items:flex-end;}
.dExe-chart-col1 h3{font-size:50px;display:inline-block;width:100%;vertical-align:middle;position:relative;font-weight:700;white-space:nowrap}
.dExe-chart-col1 h3 span{display:inline-block;vertical-align:middle;padding-right:5px;}
.dExe-chart-col1 h3 span.md-chart-arrow-down{padding-right:0;padding-left:5px;}
.dExe-chart-col1 h3 svg{display:block;}
.dExe-chart-fullwidth{-ms-flex-wrap:wrap;flex-wrap:wrap;}
.dExe-chart-fullwidth > div{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
.dExe-chart-fullwidth .dExe-chart-col1 h3{text-align:center;}
.dExe-chart-main{padding-left:5px;}
.dExe-chart-fullwidth .dExe-chart-main{padding-left:0;}
.dExe-chart-lbl{display:inline-block;width:100%;margin-top:5px;}
.dExe-chart-lbl span{display:inline-block;vertical-align:middle;padding-right:8px;}
.dExe-chart-lbl span svg{display:block}
.dExe-chart-bottom{display:table;width:100%;}
.dExe-chart-bottom > div{display:table-cell;}
.dExe-chart-bottom-inner{text-align:center;display:inline-block;font-size:20px;font-weight:500;margin-top:15px;}
.dExe-chart-bottom-inner small{font-size:14px;margin-bottom:-5px;}
.dExe-chart-bottom-inner small,.dExe-chart-bottom-inner label{display:block;}
.dExe-chart-bottom-col2{text-align:right;}
.error-color{border-color:#FA4B4B;}
.success-color{border-color:#76CD24;}
.error-color .dExe-chart-col1 h3,.error-color .md-chart-color{color:#FA4B4B;}
.success-color .dExe-chart-col1 h3,.success-color .md-chart-color{color:#76CD24;}

/* Responsive CSS */
@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1399px){}
@media only screen and (max-width:1299px){}
@media only screen and (max-width:1199px){}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){}
@media only screen and (max-width:767px){}
@media only screen and (max-width:575px){
  .dExecutive-inner{padding:0 15px 15px}
  .dExe-chart-row{-ms-flex-wrap:wrap;flex-wrap:wrap;}
  .dExe-chart-row > div{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;text-align:center;}
  .dExe-chart-col1{margin-bottom:5px;}
  .dExe-chart-labels{display:table;width:100%;padding:5px 0 10px;}
  .dExe-chart-labels > div{display:table-cell;width:auto;}
  .dExe-chart-main{padding-left:0;}
}

</style>
