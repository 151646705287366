<template>
  <div class="DriverOverspeedWidget ctrack-h-100" ref="OverspeedWidget">
    <div id="vueapp" class="vue-app ctrack-h-100">

      <!-- Overspeeding -->
      <div class="dBehaviour-inner" :class="widgetColorClass">        
        <div class="dBehaviour-box-col">
          <div class="dBehaviour-box-icon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="40" height="33" viewBox="0 0 40 33"><path d="M119,214.247a1.9,1.9,0,0,1-.7-.59c-.574-.757-1.142-1.523-1.652-2.324a1.327,1.327,0,0,0-1.309-.649c-.911.019-1.822.016-2.732.014-.776,0-1.552,0-2.327.008a.925.925,0,0,1-.935-.558,17.113,17.113,0,0,1-2.264-10.175,17.529,17.529,0,0,1,5.989-11.737,19.843,19.843,0,0,1,11.725-5.123,20.239,20.239,0,0,1,17.486,6.453,17.092,17.092,0,0,1,4.676,13.363,16.737,16.737,0,0,1-2.408,7.4.9.9,0,0,1-.651.346c-.926.039-1.855.016-2.783.016v0c-.886,0-1.772.021-2.657-.008a1.024,1.024,0,0,0-.986.526c-.475.724-1.03,1.4-1.49,2.129a2.854,2.854,0,0,1-1.457,1.13A19.218,19.218,0,0,1,119,214.247Zm-.685-6.609c.715,1.047,1.445,2.084,2.214,3.091a2.221,2.221,0,0,0,.917.7,15.332,15.332,0,0,0,11.223-.026,1.851,1.851,0,0,0,.771-.6q1.177-1.573,2.284-3.2a.939.939,0,0,1,.884-.461c.834.01,1.67.009,2.506.008.851,0,1.7,0,2.555.009.417.006.7-.089.8-.516a27.326,27.326,0,0,0,.978-4.3,13.554,13.554,0,0,0-3.931-10.61,17.538,17.538,0,0,0-25.069.088,13.479,13.479,0,0,0-2.94,14.739.769.769,0,0,0,.878.6c.82-.039,1.644-.011,2.466-.01.844,0,1.688.025,2.531-.011h.068A.954.954,0,0,1,118.31,207.638Zm4.182-1.8a3.922,3.922,0,0,1,3.326-4.423,4.143,4.143,0,0,1,.961.009,6.489,6.489,0,0,0,.66.034,2.207,2.207,0,0,0,1.043-.168c3.348-2,6.677-4.036,10.012-6.06.178-.107.365-.2.584-.32l.48.57c-.183.193-.336.362-.5.524q-4.088,4.112-8.181,8.218a1.491,1.491,0,0,0-.5,1.156,3.917,3.917,0,0,1-3.865,3.958l-.128,0A4.063,4.063,0,0,1,122.493,205.834Zm9.693-9.68a11.618,11.618,0,0,0-5.648-3.434,10.869,10.869,0,0,0-10.206,2.594c-.272.234-.509.516-.777.013a13.869,13.869,0,0,1,21.659-1.554c-1.465.889-2.913,1.785-4.387,2.634a.165.165,0,0,1-.082.018A.98.98,0,0,1,132.186,196.153Z" transform="translate(-106.999 -183.001)"/></svg></div>
          <div class="dBehaviour-box-content">
            <h3>
              <span class="dBehaviour-box-value-icon" :class="widgetIcon"><svg v-if="widgetIcon!=''" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10"><path d="M638.392,193H628.62a.626.626,0,0,1-.527-.962c.213-.383,4.509-8.038,4.876-8.691a.6.6,0,0,1,1.059,0c.269.47,4.587,8.154,4.887,8.711A.623.623,0,0,1,638.392,193Z" transform="translate(-628 -183.028)" /></svg></span>{{widgetValue}}
            </h3>
            <p class="ctrack-text-truncate">{{widgetTitle}}</p>
          </div>
        </div>
        
      </div>
      <!-- Overspeeding -->

    </div>
  </div>
</template>

<script>
import dashboardService from "../../../service/dashboard.service";
import { getters } from "../shared/observeDashboardFilter";

export default {
  name: 'overspeedwidget',
  components: {},
  data: function() {
        return {
          widgetTitle:"",
          widgetValue:"",
          widgetIcon:"",
         widgetColorClass:"" 
        }
  }, 
  props: {
    widgetData: Object
  }, 
  created(){
    this.getDriverOverspeedWidgetData();        
  },  
  methods: {
      getDriverOverspeedWidgetData() {
        let self = this;
        dashboardService.getWidgetData(this.widgetData.api, getters.getFilterParams() ).then(returnResult => {                  
          
          self.widgetTitle = returnResult.hwTitle;
          self.widgetValue = returnResult.value + returnResult.type;
          self.widgetIcon =  (returnResult.icon == "down") ? "md-chart-arrow-down" : (returnResult.icon == "up") ? "md-chart-arrow-up" : "";
          self.widgetColorClass = (returnResult.color == "green") ? "success-color" : (returnResult.color == "red") ? "error-color" : "";
        });
    }
  }
  
};

</script>
<style scoped>

/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
*{font-family: 'Roboto', sans-serif;}
.ctrack-h-100{height: 100%;}
.error-color .dBehaviour-box-col h3{color:#FA4B4B;}
.success-color .dBehaviour-box-col h3{color:#76CD24;}
[class*="md-chart-arrow"]{display:inline-block;vertical-align:middle;}
[class*="md-chart-arrow"] svg{display:block;}
.md-chart-arrow-down{transform:rotate(180deg); -webkit-transform:rotate(180deg); -moz-transform:rotate(180deg); -ms-transform:rotate(180deg); -o-transform:rotate(180deg);}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}

.dBehaviour-inner{display:inline-block;width:100%;height:100%;background-color:#FFF;padding:13px 20px;border-radius: 5px; box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, .05); -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, .05);}
.dBehaviour-box-row{display:inline-block;width:100%;padding-left:40px;position:relative;}
.dBehaviour-box-col{display:inline-block;vertical-align: middle; position:relative;width:100%;text-align:right;padding-left:40px;}
.dBehaviour-box-icon{position:absolute;color:#656565; left:0;top:50%;transform:translateY(-50%); -webkit-transform:translateY(-50%); -moz-transform:translateY(-50%); -ms-transform:translateY(-50%); -o-transform:translateY(-50%);}
.dBehaviour-box-content{display:block;width:100%;padding-left:5px;}
.dBehaviour-box-content h3{font-size:20px;font-weight:500;}
.dBehaviour-box-value-icon{margin-right:8px;}
.dBehaviour-box-content p{font-size:15px;color:#333333;font-weight:500;}


/* Responsive CSS */
@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1399px){}
@media only screen and (max-width:1299px){}
@media only screen and (max-width:1199px){}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){}
@media only screen and (max-width:767px){}
@media only screen and (max-width:575px){}


</style>
