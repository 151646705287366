import Vue from 'vue';
import { i18n } from '../shared/i18n.js';
import { defineStore } from 'pinia';
import ruleService from "@/service/rule.service";
const sortingRule = (a, b) => a.ruleName.toLowerCase().localeCompare(b.ruleName.toLowerCase());
export const useAlertPoliciesStore = defineStore('alertPolicies', {
    state: () => ({
        policies: [],
        ruleSuppressionActions: [],
        suppressableStatuses: [],
        suppressedStatuses: [],
    }),
    //$alertPoliciesStore 
    actions: {
        clearAlertPolicies() {
            this.policies = [];
        },

        //#region Data Getters
        async getAlertPolicies(allowedClaims = [], token = null) {
            const params = {
                pageSize : 200
            };
            if (token) { params.nextPageToken = token };
            await ruleService.getAllRulePolicy(params, "").then(policyList => {
                policyList = policyList.data || [];
                if (allowedClaims.length > 0) {
                    [...new Set(policyList.map(i => i.triggerName))].forEach(triggerName => {
                        if (!allowedClaims.includes('FleetAlerts.' + triggerName)) {
                            policyList = policyList.filter(i => i.triggerName != triggerName);
                        };
                    })
                };
                this.policies = policyList.sort(sortingRule);               
            }).catch((err) => {
                Vue.prototype.$toast.error('API Failed to load Alert Policies');
                this.policies = [];
            });
        },
        async getAlertPoliciesAssignments(allowedClaims = [], token = null) {
            const params = {
                pageSize: 200
            };
            if (token) { params.nextPageToken = token };
            await ruleService.getRulePoliciesAssignments(params, "").then(policyList => {
                policyList = policyList.data || [];
                if (allowedClaims.length > 0) {
                    [...new Set(policyList.map(i => i.triggerName))].forEach(triggerName => {
                        if (!allowedClaims.includes('FleetAlerts.' + triggerName)) {
                            policyList = policyList.filter(i => i.triggerName != triggerName);
                        };
                    })
                };
                this.policies = policyList.sort(sortingRule);
            }).catch((err) => {
                Vue.prototype.$toast.error('API Failed to load Alert Policies');
                this.policies = [];
            });
        },
        async getRuleSuppressionActions() {
            await ruleService.getRuleSuppressionActions().then(list => {
                this.ruleSuppressionActions = list.map(action => {
                    action.text = i18n.t(action.type) 
                    return action
                });
            });
        },
        async getSuppressableStatuses() {
            await ruleService.getSuppressableStatuses().then(list => {
                this.suppressableStatuses = list.map(i => i.toString());
            });
        },
        async getSuppressedStatuses() {
            await ruleService.getSuppressedStatuses().then(list => {
                this.suppressedStatuses = list.map(i => i.toString());
            });
        },
        //#endregion

        //#region Data Setters
        upsertAlertPolicies(newEntries) {
            if (!Array.isArray(newEntries)) {
                newEntries = [newEntries];
            };
            this.policies = [
                ...this.policies.filter(policy => !newEntries.some(entry => entry.id == policy.id)),
                ...newEntries,
            ].sort(sortingRule);
        },
        removeAlertPolicy(id) {
            this.policies = this.policies.filter(i => i.id != id);
        },
        //#endregion
       
        //#region Data Readers
        getAlertPoliciesData() {
            return this.policies;
        },
        //#region By Asset
        getAlertPoliciesByAsset(assetId = null) {
            return this.policies
                .filter(policy => policy.policyType == 0
                    && policy.alertOn.some(asset => asset.id == assetId)
                );
        },
        getSupressionPoliciesByAsset(assetId = null) {
            return this.policies
                .filter(policy => policy.policyType == 1
                    && policy.isTriggerLocationLimited == true
                    && policy.alertOn.some(asset => asset.id == assetId)
                );
        },
        //#endregion
        //#region By Location
        getSupressionPoliciesByLocation(locationId = null) {
            return this.policies
                .filter(policy => policy.policyType == 1
                    && policy.isTriggerLocationLimited == true
                    && (policy.triggerLocations.length == 0 || policy.triggerLocations.some(id => id == locationId))
                );
        },
        //#endRegion
    }
})