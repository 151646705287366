<template>
    <v-list-item>
        <v-btn icon @click.stop="openMsg" v-tooltip="'Send message to driver'">
            <v-icon>message</v-icon>
        </v-btn>
        <v-btn icon @click.stop="editDriver" v-tooltip="'Edit driver'">
            <v-icon>edit</v-icon>
        </v-btn>
        <v-btn icon @click.stop="showMap" v-tooltip="'Show driver on map'">
            <v-icon>my_location</v-icon>
        </v-btn>
    </v-list-item>
</template>

<script>
    import userService from "@/service/user.service"
    export default {
        props: {
            value: Object
        },
        methods: {
            async showMap() {
                this.$emit("toggleSearchItems")
                if (!this.$map.mapInitialized()) {
                    let operators = this.$driverStore.getOperatorsSelectorData()
                    let driverId = null;
                    if (operators.length == 0) {
                        let userRecord = await userService.getUserByIdFast(this.value.id);
                        driverId = userRecord.driverId || null;
                    } else {
                        driverId = operators.find(i => i.id == this.value.id)?.driverId;
                    };
                    const data = {
                        name: 'livemapRead',
                        params:
                        {
                            selected: {
                                drivers: [driverId],
                            },
                        }
                    }
                    this.$router.push(data);
                }
                else {
                    this.$livemapEventHub.$emit("display-driver", this.value.id);
                }
            },
        editDriver() {
                this.$emit("toggleSearchItems")
                this.$router.push({ name: 'driversUpdate', params: { id: this.value.id } });
            },
            openMsg() {
                this.$emit("toggleSearchItems")
                this.$dashboardEventHub.$emit("messageDrawer");
                setTimeout(() => {
                    this.$drawerEventHub.$emit("openMessageDrawer", { value: this.value.id });
                }, 1000);
                
            }
        }
    }
</script>