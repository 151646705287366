<template>
    <v-menu offset-y >
        <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon small 
                            v-bind="attrs"
                            v-on="{ ...tooltip, ...menu }"
                            style="margin-bottom: 2px; margin-left: 4px;">
                        {{icon}}
                    </v-icon>
                </template>
                <span>{{title}}</span>
            </v-tooltip>
        </template>
        <v-card style="padding: 8px 12px 12px 12px;">
            <v-card-subtitle style="padding:0px;">{{title}}</v-card-subtitle>
            <div style="max-height:300px;overflow-y:auto;">
                <v-chip v-for="policy in policies"
                        :key="policy.suppressingPolicyId"
                        @click="openPrompt(policy.suppressingPolicyId)"
                        class="chipLink">
                    {{policy.suppressingPolicyName}}
                </v-chip>
            </div>
        </v-card>
    </v-menu>

</template>
<script>
    export default {
        props: {
            icon: {
                type: String,
                default() {
                    return 'mdi-comment-remove-outline'
                },
            },
            title: {
                type: String,
                default() {
                    return this.$t('FleetLabels.notificationSuppressed');
                },
            },
            policies: {
                type: Array,
                default() {
                    return [];
                },
            },
        },
        methods: {
            openPrompt(policyId) {
                this.$emit('openPolicy', policyId);
            },
        },
    }
</script>
<style scoped>
    .chipLink {
        color: #002B49;
        cursor: pointer;
        text-decoration: underline;
        text-decoration-color: #002B49;
        text-shadow: 0 0 1px #40C1AC;
        margin-top: 8px;
        margin-right: 8px;
    }
</style>
