<template>
    <td :class="className">
        {{value}}
    </td>
</template>
<script>
    export default {
        name: "RegionalDateCell",
        props: {
            field: String,
            dataItem: Object,
            format: String,
            className: String,
            columnIndex: Number,
            columnsCount: Number,
            rowType: String,
            level: Number,
            expanded: Boolean,
            editor: String
        },
        computed: {
            value() {
                return !isNaN(new Date(this.dataItem[this.field]).getTime())
                    ? this.$formatUTCTimeToRegionalFormat(this.dataItem[this.field], true)
                    : this.dataItem[this.field]
            },
        },
    };
</script>
<style scoped>
</style>