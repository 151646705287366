<template>
  <div class="driveTimeVehicleDayWidget ctrack-h-100" ref="driveTimeVehicleDay">
    <div id="vueapp" class="vue-app ctrack-h-100">
        
      <!-- Drive Time/vehicle/day Widget -->
      <div class="dBehaviour-inner" :class="compareWidgetColorClass">        
        <div class="dBehaviour-box-col">
          <div class="dBehaviour-box-icon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24.004" height="27.997" viewBox="0 0 24.004 27.997"><path d="M644.54,204.423a12.415,12.415,0,0,1-.873-16.388c.565.779,1.131,1.548,1.675,2.333.049.07-.028.267-.093.375a9.459,9.459,0,0,0,5.13,13.926,9.146,9.146,0,0,0,11.655-7.639,9.312,9.312,0,0,0-7.64-10.561,8.7,8.7,0,0,0-6.418,1.448.426.426,0,0,1-.591-.008c-.7-.526-1.418-1.029-2.135-1.544a11.746,11.746,0,0,1,16.292.733,12.471,12.471,0,0,1-.08,17.313,11.877,11.877,0,0,1-8.485,3.584A11.758,11.758,0,0,1,644.54,204.423Zm6.979-7.541c-.925-1.259-1.839-2.528-2.757-3.793l-4.055-5.587.048-.058,2.922,2.1c2.159,1.552,4.323,3.094,6.472,4.658a1.945,1.945,0,0,1-.283,3.357,1.856,1.856,0,0,1-.786.179A1.941,1.941,0,0,1,651.52,196.882Zm1.511-14.378c-.633,0-1.265.006-1.9,0a.676.676,0,0,1-.746-.739c-.011-.342-.012-.685,0-1.026a.678.678,0,0,1,.72-.734q1.916-.012,3.831,0a.676.676,0,0,1,.726.725c.012.355.013.709,0,1.063a.66.66,0,0,1-.7.709c-.254,0-.508.006-.762.006C653.812,182.507,653.422,182.5,653.031,182.5Z" transform="translate(-640.992 -179.998)"/></svg></div>
          <div class="dBehaviour-box-content">
            <h3>
              <span class="dBehaviour-box-value-icon" :class="compareWidgetIcon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10"><path d="M638.392,193H628.62a.626.626,0,0,1-.527-.962c.213-.383,4.509-8.038,4.876-8.691a.6.6,0,0,1,1.059,0c.269.47,4.587,8.154,4.887,8.711A.623.623,0,0,1,638.392,193Z" transform="translate(-628 -183.028)" /></svg></span>
              {{compareWidgetValue}}
            </h3>
            <p class="ctrack-text-truncate" :title="widgetTitle">{{widgetTitle}}</p>
            <p class="md-uw-value">{{widgetValue}}</p>
          </div>
        </div>        
      </div>
      <!-- Drive Time/vehicle/day Widget -->

    </div>    
  </div>
</template>

<script>
import dashboardService from "../../../service/dashboard.service";
import { mutations, getters } from "../shared/observeDashboardFilter";
export default {
  name: 'driveTimeVehicleDayWidget',
  components: {},
  data: function() {
    return {
      widgetTitle:"",
      widgetValue:"",
      compareWidgetValue:"",
      compareWidgetIcon:"",
      compareWidgetColorClass:"" 
    }
  }, 
  props: {
    widgetData: Object
  },
  created(){
    this.getDriveTimeVehicleDayData();        
  },   
  methods: {
    getDriveTimeVehicleDayData()
    {   
      let self = this; 
      dashboardService.getWidgetData( this.widgetData.api, getters.getFilterParams()).then(returnResult => {
        self.widgetTitle = returnResult.hwTitle;
        self.widgetValue = returnResult.value + " " + returnResult.type;
        self.compareWidgetValue = returnResult.compareValue + returnResult.compareType;
        self.compareWidgetIcon =  (returnResult.compareIcon == "down") ? "md-chart-arrow-down" : (returnResult.compareIcon == "up") ? "md-chart-arrow-up" : "";
        self.compareWidgetColorClass = (returnResult.compareColor == "green") ? "success-color" : (returnResult.compareColor == "red") ? "error-color" : "";
      });  
    }
  }
};

</script>
<style scoped>
/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
*{font-family: 'Roboto', sans-serif;}
.ctrack-h-100{height: 100%;}
.error-color .dBehaviour-box-col h3{color:#FA4B4B;}
.success-color .dBehaviour-box-col h3{color:#76CD24;}
[class*="md-chart-arrow"]{display:inline-block;vertical-align:middle;}
[class*="md-chart-arrow"] svg{display:block;}
.md-chart-arrow-down{transform:rotate(180deg); -webkit-transform:rotate(180deg); -moz-transform:rotate(180deg); -ms-transform:rotate(180deg); -o-transform:rotate(180deg);}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}

.dBehaviour-inner{display:inline-block;width:100%;height:100%;background-color:#FFF;padding:13px 20px;border-radius: 5px; box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, .05); -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, .05);}
.dBehaviour-box-row{display:inline-block;width:100%;padding-left:40px;position:relative;}
.dBehaviour-box-col{display:inline-block;vertical-align: middle;position:relative;width:100%;text-align:right;padding-left:40px;}
.dBehaviour-box-icon{position:absolute;color:#656565; left:0;top:50%;transform:translateY(-50%); -webkit-transform:translateY(-50%); -moz-transform:translateY(-50%); -ms-transform:translateY(-50%); -o-transform:translateY(-50%);}
.dBehaviour-box-content{display:block;width:100%;padding-left:5px;}
.dBehaviour-box-content h3{font-size:20px;font-weight:500;}
.dBehaviour-box-value-icon{margin-right:8px;}
.dBehaviour-box-content p{font-size:15px;color:#333333;font-weight:500;}
.dBehaviour-box-content p.md-uw-value{font-size:18px;color: #999999;font-weight: 400;}


/* Responsive CSS */
@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1399px){}
@media only screen and (max-width:1299px){}
@media only screen and (max-width:1199px){}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){}
@media only screen and (max-width:767px){}
@media only screen and (max-width:575px){}

</style>