import Vue from 'vue';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import DateUtil from '../shared/date-util';
import CommonUtil from '../shared/common-utils';
import filters from '@/shared/filters';

class LiveProductivityDashboardService {

    getBrowserId() {
        if (localStorage.getItem("browserClientId") == null) {
            const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
            const value = uint32.toString(16);
            localStorage.setItem("browserClientId", value);
            return value;
        }
        return localStorage.getItem("browserClientId");
    }

    getAll(startDate, endDate, movements, option, ids, doNotUseCache = false) {
        const formatDate = (value) => {
            return Vue.prototype.$moment.utc(value).tz(DateUtil.getUserTimeZone()).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
        };
        const customStopLocations = _.chain(movements).filter(m => _.startsWith(m.id, "stop")).map((m, i) => {
            return {
                "stopType": i + 1,
                "locationCategory": m.category
            };
        }).value()
        const body = {
            "clientId": this.getBrowserId(),
            "type": option == 0 ? "vehicle" : "driver",
            "ids": ids,
            "startTime": formatDate(startDate),
            "endTime": formatDate(endDate),
            "selectedTimeZone": DateUtil.getUserTimeZone(),
            "speedUnit": CommonUtil.getSpeedUnit(),
            "distanceUnit": CommonUtil.getDistanceUnit(),
            "customStopLocations": customStopLocations,
            "doNotUseCache": doNotUseCache,
        }
        return axios.post('/api/lpd/LPDService', body).then((response) => {
            _.each(response.lpd, item => {
                let results = [];
                _.each(movements, movement => {
                    var trips = item[movement.id];
                    if (trips) {
                        results.push(...trips);
                    }
                });
                item.updated = false;
                item.lastTripTime = _.chain(results).map(c => moment(c.eventId == 0 ? c.startTime : c.endTime).toDate()).max().value();
                if (item.vehicleInfo) {
                    item.vehicleInfo.profileImage = filters.methods.$getBlobStorageImage(item.vehicleInfo.profileImage);
                    item.vehicleInfo.vehicleIcon = filters.methods.$getBlobStorageImage(item.vehicleInfo.vehicleIcon);
                }
                if (item.driverInfo) {
                    item.driverInfo.profileImage = filters.methods.$getBlobStorageImage(item.driverInfo.profileImage);
                }
            });
            return response.lpd;
        });
    }

    getMovements() {
        const $session = Vue.prototype.$session;
        const movements = [
            { id: "driving", text: "Driving", colour: "#6FB30A" },
            { id: "speeding", text: "Speeding", colour: "#EDAD4C" },
            { id: "harshUsage", text: "Harsh Usage", colour: "#D9544F" },
            { id: "idle", text: "Idle", colour: "#0000FF" },
            { id: "ptoEvents", text: "PTO Events", colour: "#698285" },
            { id: "nonReporting", text: "Non-Reporting", colour: "#EEEEEE" },
            { id: "private", text: "Private Mode", colour: "#000" },
            {
                id: "stops1",
                text: "Stops 1",
                colour: "#800000",
                category: ""
            },
            {
                id: "stops2",
                text: "Stops 2",
                colour: "#ECEF00",
                category: ""
            },
            {
                id: "stops3",
                text: "Stops 3",
                colour: "#7F0081",
                category: ""
            },
            {
                id: "stops4",
                text: "Stops 4",
                colour: "#996633",
                category: ""
            },
            {
                id: "stops5",
                text: "Stops 5",
                colour: "#EEE",
                category: ""
            },
            {
                id: "stops6",
                text: "Stops 6",
                colour: "#1FDF9E",
                category: ""
            },
            {
                id: "stops7",
                text: "Stops 7",
                colour: "#EA80FF",
                category: ""
            },
            {
                id: "stops8",
                text: "Stops 8",
                colour: "#00ace6",
                category: ""
            }
        ];

        if (
            $session.get("tenantDetails") &&
            $session.get("tenantDetails").userId &&
            $session.get("configProperties") &&
            $session.get("configProperties").customStopList
        ) {
            let configProperties = $session.get("configProperties");
            if (configProperties.customStopList) {
                _.each(configProperties.customStopList, (movement, index) => {
                    let stop = _.find(movements, m => m.id == `stops${index + 1}`);
                    if (movement.stopName) {
                        stop.text = movement.stopName;
                        stop.category = movement.stopName;
                    }
                });
            }
        }
        return movements;
    }

}

export default new LiveProductivityDashboardService();