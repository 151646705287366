<template>
    <td :class="className">
      {{value}}
    </td>
</template>
<script>
  import Mustache from 'mustache';
  export default {
    name: "DataCell",
    data() {
      return {
        value : (this.format !== undefined) 
              ? Mustache.render(this.format, this.dataItem) 
              : this.dataItem[this.field]
      };
    },
    props: {
      field: String,
      dataItem: Object,
      format: String,
      className: String,
      columnIndex: Number,
      columnsCount: Number,
      rowType: String,
      level: Number,
      expanded: Boolean,
      editor: String
    }
  };
</script>
<style scoped>
</style>