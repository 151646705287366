import axios from 'axios';

class MessageService {

    GetUnreadMessagesForUserCount() {
        return axios.get(`/api/message/GetUnreadMessagesForUserCount`)
    }

    getAllSenderMessages() {
        return axios.get(`/api/message/getAllSenderMessages`)
    }

    getAllMessagesInThread(threadId) {
        return axios.get(`/api/message/getAllMessagesInThread/${threadId}`)
    }

    UpdateReadAcknowledgementForUser(threadId) {
        return axios.put(`/api/message/UpdateReadAcknowledgementForUser/${threadId}`)
    }

    sendPushNotification(body) {
        return axios.post(`/api/message/sendPushNotification`, body)
    }

    sendEmailNotification(body) {
        return axios.post(`/api/message/sendEmailNotification`, body)
    }

    async sendEmail(email, file, userId, tenant) {
        var arrayBuffer = await file.arrayBuffer();
        const base64String = btoa(new Uint8Array(arrayBuffer).reduce((acc, i) => acc += String.fromCharCode(i),''));
    

        
        var body = {
            "userID": userId,
            "tenantID": tenant,
            "payload": [{
                "to": email.to.toString(),
                "message": email.message,
                "subject": email.subject
            }],
            "files": { "exported.pdf": base64String }
        }

        return axios.post(`/api/message/sendEmail`, body);
    }

}
export default new MessageService();