<template>
    <div class="overspeedingChart ctrack-h-100" ref="overspeedingchart">
        <div id="vueapp" class="vue-app ctrack-h-100">

            <!-- Overspeed Chart -->
            <div class="dExecutive-inner" :class="[widgetColorClass, hideloaderClass]" @click="showExecutiveCommonDrillPopup = true">
                <div class="dExecutive-title">
                    <h4>
                        <span class="dExe-title-icon">
                            <svg
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="20"
                                viewBox="0 0 25 20"><path
                                d="M116.5,201.938a1.175,1.175,0,0,1-.439-.358c-.359-.458-.715-.923-1.033-1.408a.837.837,0,0,0-.817-.393c-.574.012-1.148.01-1.723.009-.48,0-.96,0-1.44,0a.581.581,0,0,1-.585-.338,10.123,10.123,0,0,1-1.415-6.167,10.517,10.517,0,0,1,3.743-7.113,12.591,12.591,0,0,1,7.329-3.105,12.871,12.871,0,0,1,10.929,3.911,10.172,10.172,0,0,1,2.923,8.1,9.938,9.938,0,0,1-1.506,4.487.57.57,0,0,1-.407.21c-.579.023-1.159.01-1.74.01h0c-.553,0-1.107.013-1.66,0a.644.644,0,0,0-.617.318c-.3.439-.643.847-.931,1.291a1.776,1.776,0,0,1-.91.685,12.354,12.354,0,0,1-9.7-.137Zm-.427-4c.446.634.9,1.263,1.383,1.873a1.386,1.386,0,0,0,.573.426,9.869,9.869,0,0,0,7.015-.016,1.147,1.147,0,0,0,.481-.363c.491-.634.965-1.283,1.428-1.937a.591.591,0,0,1,.552-.28c.518.005,1.038.005,1.556,0,.536,0,1.071,0,1.607.006.26,0,.437-.054.5-.312a16.072,16.072,0,0,0,.611-2.605,8.076,8.076,0,0,0-2.457-6.43,10.382,10.382,0,0,0-6.687-3.112,10.533,10.533,0,0,0-8.98,3.166,7.99,7.99,0,0,0-1.839,8.932.481.481,0,0,0,.549.363c.513-.023,1.028-.007,1.542-.006s1.055.015,1.581-.006h.043A.6.6,0,0,1,116.069,197.933Zm2.614-1.094a2.391,2.391,0,0,1,2.078-2.681,2.659,2.659,0,0,1,.6.005,4.018,4.018,0,0,0,.416.021,1.418,1.418,0,0,0,.652-.1c2.092-1.213,4.173-2.445,6.258-3.672.111-.066.228-.122.365-.194l.3.345c-.114.117-.21.22-.31.318q-2.554,2.492-5.113,4.981a.892.892,0,0,0-.311.7,2.41,2.41,0,0,1-2.416,2.4h-.079A2.513,2.513,0,0,1,118.683,196.839Zm6.058-5.867a7.3,7.3,0,0,0-3.53-2.082,6.944,6.944,0,0,0-6.379,1.572c-.17.141-.318.312-.486.007a8.836,8.836,0,0,1,13.537-.942c-.915.539-1.821,1.082-2.742,1.6a.1.1,0,0,1-.051.011A.621.621,0,0,1,124.741,190.972Z"
                                transform="translate(-108.999 -183.001)"/></svg>
                        </span>
                        {{widgetTitle}}
                    </h4>
                </div>
                <div class="dExecutive-content">
                    <div class="ctrack-md-loader" :class="loaderHide?'ctrack-chart-hide-loader':''"></div>
                    <div class="dExe-chart-row">
                        <div class="dExe-chart-col1">
                            <h3 :class="[loaderHide?'':'ctrack-chart-hide-loader', labelFontSize]">
                                <span class="dExe-value-icon" :class="widgetIcon">
                                    <svg
                                        fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22"
                                        height="11"
                                        viewBox="0 0 22 11"><path
                                        d="M107.008,284.158l11.223-11.223,11.223,11.223Z"
                                        transform="translate(-107.008 -272.935)"/></svg>
                                </span>{{widgetValue}}
                            </h3>
                        </div>
                        <div class="dExe-chart-col2">
                            <div class="dExe-chart-main">
                                <!-- <img src="../../../assets/images/dashboard/md-overspeeding-graph.svg" />
                                -->
                                <highcharts :options="chartOptions" ref="chart" :class="loaderHide?'':'ctrack-chart-hide-loader'"></highcharts>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="md-no-data-found-msg"><span class="md-error-dataIcon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"><path d="M754.269,1479.679a.994.994,0,0,1-.1-1.255q2.3-4.585,4.6-9.167l6.755-13.474c.041-.083.08-.167.127-.247a.977.977,0,0,1,1.739.008c1.273,2.494,2.52,5,3.8,7.539l-1.774.883c-.959-1.907-1.908-3.794-2.89-5.746-3.322,6.624-6.612,13.188-9.918,19.78h19.855l-4.808-9.814,1.778-.872c.4.814.789,1.607,1.178,2.4,1.423,2.9,2.85,5.8,4.26,8.711a1.463,1.463,0,0,1,.139.795.968.968,0,0,1-1.076.773h-8.463q-7.16,0-14.322,0A1.117,1.117,0,0,1,754.269,1479.679Zm10.951-4.834a1.3,1.3,0,0,1,1.3-1.349,1.336,1.336,0,0,1,0,2.67A1.3,1.3,0,0,1,765.221,1474.845Zm.963-2.283a.937.937,0,0,1-.582-.905q-.067-1.2-.135-2.406c-.075-1.327-.145-2.654-.229-3.98a1.294,1.294,0,1,1,2.559,0c-.012.143-.023.287-.034.431h0c-.11,1.975-.217,3.949-.332,5.924a.958.958,0,0,1-.9,1A.941.941,0,0,1,766.184,1472.562Zm4.017-7.073,1.752-.917.792,1.5-1.757.917C770.721,1466.482,770.467,1466,770.2,1465.489Z" transform="translate(-753.998 -1455.009)"></path></svg></span>{{$t("Dashboard.noDataFound")}}</div>
            </div>
            <!-- Overspeed Chart -->

        </div>
        <v-dialog v-model="showExecutiveCommonDrillPopup" >
            <div class="ctrack-md-dialog-content">
                <ExecutiveCommonDrillPopup v-if="showExecutiveCommonDrillPopup" :widgetData="widgetData" :chartProgress="chartProgress" @closeCommonMdDialog="closeCommonMdDialog"></ExecutiveCommonDrillPopup>                  
                <!-- <OverspeedDrillPopup v-if="showExecutiveCommonDrillPopup" :drillTitle="drilldownTitle" :widgetData="drilldownData" @closeCommonMdDialog="closeCommonMdDialog"></OverspeedDrillPopup>                   -->
            </div>
        </v-dialog>
        <!-- drill down or league table -->
        <md-dialog class="md-driver-drilldown" :md-active.sync="showDrill">
            <DrillDownGrid v-if="showDrill" :data="drillData" :gridSettingsKey="widgetData.widgetName"></DrillDownGrid>
        </md-dialog>
    </div>
</template>

<script>
import Vue from 'vue'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import HighChartMore from 'highcharts/highcharts-more'
import HighChartAccessibility from 'highcharts/modules/accessibility';
import dashboardService from "../../../service/dashboard.service";
import { mutations, getters } from "../shared/observeDashboardFilter";

HighChartMore(Highcharts);
HighChartAccessibility(Highcharts);
Vue.use(HighchartsVue, { });

export default {
    name: 'overspeedchart',
    components: {
        
    },
    data: function() {
        return {
            loaderHide:false,
            widgetTitle:"",
            widgetValue:"",
            widgetIcon:"",
            widgetColorClass:"",
            labelFontSize:"",
            showExecutiveCommonDrillPopup:false,
            chartProgress:null,
            hideloaderClass:"",
            showDrill: false,
            drillData: null,

            chartOptions: {
                title: {
                    text:'',                    
                    align:"center",                   
                    margin:0, 
                    style: {
                        color:'#999999', fontSize: '14px',fontFamily: 'Roboto,Medium',
                    }
                }, 
                subtitle: {
                    text:'',                    
                    align:"center", 
                    margin:0,
                    y:20, 
                    verticalAlign: 'bottom', 
                    style: {
                            color: '#FA4B4B',
                            fontSize: '14px',
                            fontFamily: 'Roboto,Medium',
                        }
                },
                credits: {
                    enabled: false
                }, 
                chart: {
                    // margin:[1,20,20,1],
                    // spacing:[5,5,5,5],
                    height:200,
                    spacingTop: 0,
                    spacingLeft: 0,
                    spacingRight: 0,
                },                
                yAxis: {
                    title: {
                        text:'This Week', 
                        offset: 0,
                        align:"high",
                        x:100, 
                        y:20,
                        rotation:0, 
                        style: {
                            color:'#999999', fontSize: '14px'
                        }
                    },
                    visible:false,
                    labels: {
                        enabled: false,
                    }, 
                    gridLineWidth: 0,
                }, 
                xAxis : {
                    lineColor: 'transparent',
                    minorTickLength: 0,
                    tickLength: 0,                    
                    categories: [],
                    title: {                        
                        offset: 0,
                        text: 'Last Week',
                        style: {
                            color: '#FA4B4B',
                            fontSize: '14px'
                        }
                    },
                    visible:false,
                    labels: {
                        enabled: false
                    },
                    gridLineWidth: 0
                },
                tooltip: {
                    backgroundColor: {
                        linearGradient: [0, 0, 0, 60],
                        stops: [
                            [0, '#FFFFFF'],
                            [1, '#FFFFFF']
                        ]
                    },
                    currentCategories: [],
                    previousCategories: [],
                    // shadow:false,
                    // useHTML:true,
                    borderWidth: 0,
                    formatter: function () {
                        let currCat = this.series.tooltipOptions.currentCategories;
                        let prevCat = this.series.tooltipOptions.previousCategories;                        
                        let axis = (this.series.userOptions.key == "previous") ? prevCat[this.point.x] : currCat[this.point.x] ;                        
                        //return '<b>' + axis + '</b> <br/> ' + this.series.name + ': <b>' + this.y + '</b>';
                         return '<b>' + axis + '</b> <br/> ' + this.point.name + ': <b>' + this.y + '</b>';
                    },
                    style: {
                        color:'#333333',
                        fontSize: "14px",
                        fontFamily: "Roboto"
                    }
                }, 
                legend: {
                    enabled: false
                },
                plotOptions : {
                    series: {
                        marker: {
                            enabled: false
                        },
                        dataLabels: {
                            enabled: true,                            
                            // align: 'right',
                            // verticalAlign: 'top',
                            style:{
                                fontSize:"12px",
                                fontWeight:"bold",                  
                                fontFamily: 'Roboto,Medium',
                                textOutline:0,                                  
                            },
                            formatter: function () {
                                var isLast = false;
                                if (this.point.x === this.series.data[this.series.data.length - 1].x && this.point.y === this.series.data[this.series.data.length - 1].y) 
                                    isLast = true;
                                
                                // return isLast ? this.y : '';
                                return isLast ?  '<span style="color:' + this.color + '">' + this.y +'</span></n>': '';

                            }
                        }
                    }
                },
                series:{},
            }
        }
    }, 
    props: {
        widgetData: Object
    },
    created(){
      this.getOverSpeedChartData();
    },  
    methods: {
        getOverSpeedChartData() {
            let self = this;
            self.loaderHide = false;
            
            dashboardService.getWidgetData(this.widgetData.api, getters.getFilterParams()).then(returnResult => {
                self.drillData = returnResult;            
                self.widgetTitle = returnResult.data.valueData.widgetTitle;
                self.widgetValue = returnResult.data.valueData.value + returnResult.data.valueData.type;
                self.widgetIcon =  (returnResult.data.valueData.icon == "down") ? "md-chart-arrow-down" : (returnResult.data.valueData.icon == "up") ? "md-chart-arrow-up" : "";
                self.widgetColorClass = (returnResult.data.valueData.color == "green") ? "success-color" : (returnResult.data.valueData.color == "red") ? "error-color" : "";
                let lastWeekColor = (returnResult.data.valueData.color == "green") ? "#76CD24" : (returnResult.data.valueData.color == "red") ? "#FA4B4B" : "#000000";
                self.chartOptions.xAxis.categories = returnResult.data.chartData.currentCategories;
                self.chartOptions.tooltip.previousCategories = returnResult.data.chartData.previousCategories;
                self.chartOptions.tooltip.currentCategories = returnResult.data.chartData.currentCategories;
                self.chartOptions.title.text = returnResult.data.chartData.series[0].name;
                self.chartOptions.subtitle.style.color = lastWeekColor;
                self.chartOptions.subtitle.text = returnResult.data.chartData.series[1].name;
                self.chartOptions.series = returnResult.data.chartData.series;
                self.chartOptions.series[1].color = lastWeekColor;
                self.chartOptions.series[1].dataLabels.color = lastWeekColor;
                // self.chartTitle = returnResult.chartTitle; self.chartTitleDuration = "(" +
                // returnResult.chartTitleDuration + ")";

                // provide chart progress in popup
                self.chartProgress = returnResult.data.valueData;

                if(returnResult.data.valueData.value.length == 4) {
                    self.labelFontSize = 'fourDigitFontLabel';
                } else if (returnResult.data.valueData.value.length == 5) {
                    self.labelFontSize = 'fiveDigitFontLabel';
                } else if (returnResult.data.valueData.value.length == 6) {
                    self.labelFontSize = 'sixDigitFontLabel';
                } else if (returnResult.data.valueData.value.length > 6) {
                    self.labelFontSize = 'moreThanSixDigitFontLabel';
                }

                self.loaderHide = true;
            // }, 5000);
            }).catch(err => {
                self.hideloaderClass = 'cd-chart-ifDataBlank'
                self.loaderHide=true
            });
        },
        closeCommonMdDialog(openDrill=false) {
            this.showExecutiveCommonDrillPopup = false;
            // show drill
            if(openDrill){
                this.showDrill = true;
            }
        },
    }
};

</script>
<style scoped>

/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');*{font-family:'Roboto',sans-serif;}
.ctrack-h-100{height:100%;}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.md-dashboard-content{padding-top:10px;color:#C5C5C5;}
[class*="md-chart-arrow"]{display:inline-block;vertical-align:middle;}
[class*="md-chart-arrow"] svg{display:block;}
.md-chart-arrow-down{transform:rotate(180deg);-webkit-transform:rotate(180deg);-moz-transform:rotate(180deg);-ms-transform:rotate(180deg);-o-transform:rotate(180deg);}
.dExecutive-inner{height:100%;width:100%;display:inline-block;background-color:#FFFFFF;border-top:6px solid #CDCDCD;border-radius:5px;padding:0 20px 20px;}
.dExecutive-title{display:inline-block;width:100%;padding:10px 0;}
.dExecutive-title h4{color:#333333;font-size:20px;font-weight:500;position:relative;padding-left:40px;display:inline-block;width:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
.dExecutive-title h4 .dExe-title-icon{position:absolute;left:0;top:50%;transform:translateY(-50%);}
.dExecutive-title h4 .dExe-title-icon svg{display:block;}
.dExecutive-content{display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;justify-content:space-between;-webkit-justify-content:space-between;height:calc(100% - 60px);}
.dExecutive-main div[class*="ctrack-col-"]{margin-bottom:20px;}
.dExe-chart-row{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:nowrap;flex-wrap:nowrap;-webkit-box-align:center;-ms-flex-align:center;align-items:center;justify-content:space-between;-webkit-justify-content:space-between;width:100%;}
.ctrack-align-end{-webkit-box-align:flex-end;-ms-flex-align:flex-end;align-items:flex-end;}
.dExe-chart-col1 h3{font-size:50px;display:inline-block;width:100%;vertical-align:middle;position:relative;font-weight:700;white-space:nowrap}
.dExe-chart-col1 h3 span{display:inline-block;vertical-align:middle;padding-right:5px;}
.dExe-chart-col1 h3 span.md-chart-arrow-down{padding-right:0;padding-left:5px;}
.dExe-chart-col1 h3 svg{display:block;}
.dExe-chart-fullwidth{-ms-flex-wrap:wrap;flex-wrap:wrap;}
.dExe-chart-fullwidth > div{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
.dExe-chart-fullwidth .dExe-chart-col1 h3{text-align:center;}
.dExe-chart-main{padding-left:5px; min-width: 240px;/*max-width: 350px; */width: 100%;display: inline-block}
.dExe-chart-fullwidth .dExe-chart-main{padding-left:0;}
.dExe-chart-lbl{display:inline-block;width:100%;margin-top:5px;}
.dExe-chart-lbl span{display:inline-block;vertical-align:middle;padding-right:8px;}
.dExe-chart-lbl span svg{display:block}
.dExe-chart-bottom{display:table;width:100%;}
.dExe-chart-bottom > div{display:table-cell;}
.dExe-chart-bottom-inner{text-align:center;display:inline-block;font-size:20px;font-weight:500;margin-top:15px;}
.dExe-chart-bottom-inner small{font-size:14px;margin-bottom:-5px;}
.dExe-chart-bottom-inner small,.dExe-chart-bottom-inner label{display:block;}
.dExe-chart-bottom-col2{text-align:right;}
.error-color{border-color:#FA4B4B;}
.success-color{border-color:#76CD24;}
.error-color .dExe-chart-col1 h3,.error-color .md-chart-color{color:#FA4B4B;}
.success-color .dExe-chart-col1 h3,.success-color .md-chart-color{color:#76CD24;}
.dExe-chart-col1 h3.fourDigitFontLabel {font-size: 16px;}
.dExe-chart-col1 h3.fiveDigitFontLabel {font-size: 16px;}
.dExe-chart-col1 h3.sixDigitFontLabel {font-size: 16px;}
.dExe-chart-col1 h3.moreThanSixDigitFontLabel {font-size: 16px;}


/*No Data Section */
.cd-chart-ifDataBlank .dExecutive-title,.cd-chart-ifDataBlank .dExecutive-content{visibility:hidden;}
.cd-chart-ifDataBlank .md-no-data-found-msg{display: block}
.md-no-data-found-msg{padding:15px 15px 15px 50px;position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);font-size:18px;color:#9e4e4e;border:1px solid #ffb2b2;background-color:#ffb2b2;border-radius:3px;white-space: nowrap; display:none}
.md-no-data-found-msg .md-error-dataIcon{position:absolute;left:15px;top:50%;transform:translateY(-50%);}
.md-no-data-found-msg .md-error-dataIcon svg{display:block;}

/*Overspeed Drill Popup CSS*/
.dChartDetailsModal-lg{width: 96%; max-width: 70%;}
.dChartDetailsModal-lg .ctrack-modal-body{padding:0;}
.ctrack-md-modal{border-radius:10px;outline:0;box-shadow:1px 2px 16px rgba(0,0,0,0.26);-webkit-box-shadow:1px 2px 16px rgba(0,0,0,0.26);-moz-box-shadow:1px 2px 16px rgba(0,0,0,0.26);}
.ctrack-md-dialog-content{height:100%;display:inline-block;width:100%;overflow: auto;-webkit-overflow-scrolling: touch;}

/* show common loader at top */
.md-dialog.dChartDetailsModal-lg.lowZindex{z-index:998 !important;}

/* Responsive CSS */
@media only screen and (min-width:576px){
/*Overspeed Drill Popup CSS*/
.ctrack-modal-dialog{max-width:500px;margin:1.75rem auto;}
.ctrack-modal-dialog-lg{max-width:1100px;padding: 15px}
}
@media only screen and (max-width:1799px){
/*Overspeed Drill Popup CSS*/
.dChartDetailsModal-lg{max-width: 80%;}
}

@media only screen and (max-width:1399px){
/*Overspeed Drill Popup CSS*/
.dChartDetailsModal-lg{max-width: 85%;}
}
@media only screen and (max-width:1299px){
/*Overspeed Drill Popup CSS*/
.dChartDetailsModal-lg{max-width: 90%;}
}

@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1399px){
    .dExe-chart-col1 h3{font-size:40px;}
    .dExe-chart-main{min-width: 170px}
}
@media only screen and (max-width:1299px){
    .dExe-chart-col1 h3{font-size:35px;}
    .dExe-chart-main{min-width: 170px}
}
@media only screen and (max-width:1199px){}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){}
@media only screen and (max-width:767px){
/*Overspeed Drill Popup CSS*/    
.dChartDetailsModal-lg, .dChartDetailsModal{max-height: 96%}
.dChartDetailsModal-lg{max-width: 100%;}
}
@media only screen and (max-width:575px){
  .dExecutive-inner{padding:0 15px 15px}
  .dExe-chart-row{-ms-flex-wrap:wrap;flex-wrap:wrap;}
  .dExe-chart-row > div{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;text-align:center;}
  .dExe-chart-col1{margin-bottom:5px;}
  .dExe-chart-labels{display:table;width:100%;padding:5px 0 10px;}
  .dExe-chart-labels > div{display:table-cell;width:auto;}
  .dExe-chart-main{padding-left:0;}
}

</style>

<style>
/*Overspeed Drill Popup CSS*/
.dChartDetailsModal-lg .md-dialog-container{display: inline-block;width: 100%;}
.dChartDetailsModal-lg .md-empty-state-label{
    font-size: 20px;
    font-weight: 300;
    line-height: 10px;
}
</style>
