<template>
  <div>
    <div class="tabularDataContainer">
      <div class="tabularData tableFixHead">
        <table>
          <thead class="tabularDataHeading">
            <tr>
              <th
                scope="col"
                v-for="(columnHeader,headerIndex) in filteredColumns"
                :key="columnHeader.label+headerIndex"
                :class="{draggable: isHeaderDraggable(headerIndex)}"
                :style="{width: columnHeader.width}"
              >
                <span v-if="columnHeader.key == checkboxColumnKey">
                  <md-checkbox
                    v-model="selectAllCheckbox"
                    @change="onSelectAllRows($event,filteredList)"
                    class="md-primary"
                  ></md-checkbox>
                </span>
                <span
                  @click="onHeaderClick(columnHeader)"
                  v-if="columnHeader.key != checkboxColumnKey"
                >
                  {{columnHeader.label}}
                  <span
                    v-if="currentSort == columnHeader.key"
                    class="sortingIconingrid"
                  >
                    <img :src="sortingTypeImage" />
                  </span>
                </span>
                <br />
                <div v-if="columnHeader.key != checkboxColumnKey">
                  <input
                    @input="onInput()"
                    v-model="columnHeader.searchInput"
                    :style="{display: columnHeader.search == true ? 'visible' : 'none' }"
                    @change="filterDataList()"
                  />
                </div>
              </th>
            </tr>
          </thead>

          <tbody v-if="dataList.length && !filteredList.length">
            <div :colspan="filteredList.length" style="text-align: center;">{{$t("CustomeViews.noRecordsFound")}}</div>
          </tbody>
          <!-- Start of table body  -->
          <tbody>
            <tr
              @click="onRowClick(item)"
              v-for="(item, itemIndex) in filteredList"
              :key="item.id"
              class="tabularDataContent"
            >
              <template>
                <td
                  v-for="(column,columnIndex) in filteredColumns"
                  :class="{draggable: isItemDraggable(itemIndex)}"
                  :key="column.key+columnIndex"
                  :style="{width: column.width }"
                  @click="onElementClick(item,column)"
                >
                  <slot
                    v-if="column.key == checkboxColumnKey"
                    :element="item"
                    :list="filteredList"
                    :name="column.key"
                  >
                    <md-checkbox
                      v-model="selected"
                      @change="onRowCheckBoxSelect($event)"
                      :value="item[getPrimaryKey()]"
                      class="md-primary"
                    ></md-checkbox>
                  </slot>
                  <slot
                    v-if="column.key != checkboxColumnKey"
                    :element="item"
                    :selectCount="selected.length"
                    :name="column.key"
                  >
                    <span
                      v-if="column.image && item[column.key]"
                      v-show="!item[column.image]"
                      class="circleShape"
                    >{{getItemValue(item,column) | nameInitials}}</span>
                    <span
                      v-if="column.image && item[column.key]"
                      v-show="item[column.image]"
                      class="circleShape"
                    >
                      <img :src="item[column.image]" />
                    </span>
                    <md-tooltip
                      v-if="column.tooltip && item[column.tooltip]"
                      md-direction="top"
                    >{{item[column.tooltip]}}</md-tooltip>
                    <span
                      :class="[column.hasOwnProperty('click')?'gridLink':'']"
                    >{{getItemValue(item,column) | ellipseText}}</span>
                  </slot>
                </td>
              </template>
            </tr>
          </tbody>
          <!-- End of table body  -->
        </table>
      </div>
    </div>
    <OldBasePagination
      :sort="currentSort"
      :sortOrder="currentSortOrder"
      v-if="showPagination == true"
      :search="search"
      @pageItems="initializeList"
    ></OldBasePagination>
  </div>
</template>
<script>
// import _ from "lodash";
// npm install --save vuedraggable
// import draggable from "vuedraggable";
import { i18n } from "../../shared/i18n";
export default {
  name: "old-app-grid",
  props: {
    isGlobelSearch:{type:Boolean,required:false,default:false},
    searchInput:{type:String,required:false},
    searchKeys:{type:Array,required:false},
    search: {
      type: Function,
      required: false,
      default: () => {
      }
    },
    columns: {
      type: Array,
      required: true,
      default: function() {
        return [];
      }
    },
    filter: {
      type: Function,
      required: false,
      default: () => {
        return null;
      }
    },
    searchPlaceHolder: { type: String, required: false },
    listItemDraggable: {
      type: Boolean,
      required: false,
      default: function() {
        return false;
      }
    },
    headersDraggable: {
      type: Boolean,
      required: false,
      default: function() {
        return false;
      }
    },
    checkbox: {
      type: Boolean,
      required: false,
      default: function() {
        return true;
      }
    },
    sort: {
      required: false
    },
    rowsSelected: {
      required: false,
      default: () => {
        return [];
      }
    }
  },
  directives: {
    loadedIfComplete: function(el, binding) {
      if (el.complete) {
        binding.value = true;
      }
    }
  },
  filters: {
    nameInitials: function(value) {
      var names = value.split(" "),
        initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    },
    ellipseText(value) {
      if (value && value.length > 20) {
        return `${value.substring(0, 20)}...`;
      }
      return value;
    }
  },
  watch: {
    rowsSelected(rowsSelected) {
      this.selected = rowsSelected;
      this.selectAllCheckbox = rowsSelected.length >= this.filteredList.length;
    },
    searchInput:function(searchItem){
        this.globalSearchItem=searchItem;
        this.searchGlobalDataList();
    }
  },
  components: {},
  computed: {
    filteredColumns: function() {
      const filteredColumns = this.columns.filter(
        column => column.visible != false
      );
      return filteredColumns;
    }
  },
  data() {
    return {
      typing: true,
      selectAllCheckbox: false,
      filteredList: [],
      currentSortOrder: "asc",
      currentSort: "",
      selected: [],
      drag: false,
      checkboxColumnKey: "checkbox",
      dataList: [],
      showPagination: false,
      sortingTypeImage: null,
      globalSearchItem:""
    };
  },
  mounted() {
    this.showPagination = true;
  },
  created() {
    if (this.checkbox && this.columns.length > 0) {
      const checkBoxExist = this.columns[0].key == this.checkboxColumnKey;
      if (!checkBoxExist) {
        this.columns.unshift({
          label: "",
          key: this.checkboxColumnKey,
          width: "5%",
          fixed: true,
          id: "id"
        });
      }
      this.selected = this.rowsSelected;
    }
    const doCheckBoxExist = this.columns[0].key == this.checkboxColumnKey;
    const defaultSort =
      doCheckBoxExist && this.columns.length > 1 ? this.columns[1].key : "";
    this.currentSort = this.sort ? this.sort : defaultSort;
    this.updateSortingImage();
  },
  methods: {
    getPrimaryKey() {
      return this.columns && this.columns.length > 0
        ? this.columns[0].id
        : "id";
    },
    updateSortingImage() {
      const img = require(`../../assets/images/updown_${this.currentSortOrder}.png`);
      this.sortingTypeImage = img;
    },
    onElementClick(item, column) {
      const hasClick = column.hasOwnProperty("click");
      if (hasClick == true) {
        column.click(item);
      }
    },
    checkHeaderMove(e) {
      const index = e.draggedContext.index;
      const futureIndex = e.draggedContext.futureIndex;
      return (
        this.isHeaderDraggable(index) && this.isHeaderDraggable(futureIndex)
      );
    },
    isHeaderDraggable(index) {
      return this.columns[index].fixed != true && this.headersDraggable;
    },
    checkItemMove(e) {
      const index = e.draggedContext.index;
      const futureIndex = e.draggedContext.futureIndex;
      return this.isItemDraggable(index) && this.isItemDraggable(futureIndex);
    },
    isItemDraggable(index) {
      return this.filteredList[index].fixed != true && this.listItemDraggable;
    },
    onRowClick(item) {
      this.$emit("onRowClick", item);
    },
    onRowCheckBoxSelect(selected) {
      this.selectAllCheckbox = this.filteredList.length == this.selected.length;
      this.$emit("selectedRows", this.selected);
    },
    onSelectAllRows(selectAll, filteredList) {
      this.selected = [];
      filteredList.forEach(row => {
        row.checked = selectAll;
        if (selectAll) {
          this.selected.push(row[this.getPrimaryKey()]);
        }
      });
      this.$emit("selectedRows", this.selected);
    },
    getItemValue(item, column) {
      let value = item[column.key] ? item[column.key] : "";
      const hasFilter = column.hasOwnProperty("filter");
      if (hasFilter == true) {
        const filteredValue = column.filter(value);
        value = filteredValue != undefined ? filteredValue : value;
      }
      return value;
    },
    onInput() {
      this.typing = true;
    },
    onHeaderClick(columnHeader) {
      const sortActive =
        columnHeader.sortActive == undefined || columnHeader.sortActive == true
          ? true
          : false;
      if (sortActive) {
        if (this.currentSort == columnHeader.key) {
          this.currentSortOrder =
            this.currentSortOrder == "desc" ? "asc" : "desc";
        }
        this.currentSort = columnHeader.key;
        this.updateSortingImage();
      }
    },
    filterDataList() {
      this.initializeList(this.dataList);
    },
    searchDataList() {
      const filteredColumns = this.columns.filter(
        columnHeader => columnHeader.search && columnHeader.searchInput
      );
      if (filteredColumns.length == 0) {
        return;
      }
      this.filteredList = this.filteredList.filter(item => {
        let found = false;
        filteredColumns.forEach(columnHeader => {
          if (
            columnHeader.search &&
            columnHeader.searchInput &&
            item[columnHeader.key] &&
            item[columnHeader.key]
              .toLowerCase()
              .includes(columnHeader.searchInput.toLowerCase())
          ) {
            found = true;
            return found;
          }
        });
        return found;
      });
    },
  searchGlobalDataList() {
      this.filteredList=this.dataList
      if(this.globalSearchItem.length>0){
        this.filteredList = this.filteredList.filter(item => {
          let found = false;
          this.searchKeys.forEach(columnHeader => {
            if (
              item[columnHeader] &&
              item[columnHeader].toLowerCase().includes(this.globalSearchItem.toLowerCase())
            ) {
              found = true;
              return found;
            }
          });
          return found;
        });
      }
    },
    
    initializeList(list) {
      this.dataList = [...list];
      const filteredList = this.filter(list);
      this.filteredList = filteredList ? [...filteredList] : [...list];
      const filteredListLength = this.filteredList.length;
      if (filteredListLength > 0) {
        const alreadySelectedList = this.filteredList.filter(item =>
          this.selected.includes(item[this.getPrimaryKey()])
        );
        this.selectAllCheckbox =
          filteredListLength == alreadySelectedList.length;
      }
      if(this.isGlobelSearch==true){
        this.searchGlobalDataList();
      }
      else{
        this.searchDataList();
      }
    },
    refreshGrid() {
      this.showPagination = false;
      this.selected = [];
      this.$nextTick(() => {
        this.showPagination = true;
      });
    }
  }
};
</script>
<style scoped>
.md-field.md-theme-default:after {
  background-color: #fff !important;
}
.md-has-value,
.md-has-placeholder {
  color: #fff !important;
}
.md-field.md-theme-default.md-has-value .md-input {
  -webkit-text-fill-color: rgba(255, 255, 255);
}
.md-field.md-theme-default label {
  color: rgba(255, 255, 255);
}

.circleShape img {
  border-radius: 100%;
}

.tabularDataContainer table td,
.tabularDataContainer table th {
  text-align: left;
  vertical-align: middle;
  padding-left: 10px !important;
  padding-right: 10px;
}
.tabularData table tr th {
  white-space: nowrap;
}
.tabularData table tr td {
  white-space: nowrap;
}
.tabularData table tr th {
  color: #053a49;
  font-weight: bold;
}

.tableFixHead {
  overflow-y: auto;
  height: 100%;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}
table {
  border-collapse: collapse;
}
.tabularDataContainer {
  position: absolute;
  top: 102px;
  left: 0;
  right: 0;
  bottom: 55px;
  overflow: auto;
}

.tabularDataContainer table {
  border-collapse: collapse;
  font-size: 15px;
}
.gridLink {
  color: #288dc0;
  cursor: pointer;
}
</style>