<template>
    <v-list-item>
        <v-btn icon @click.stop="editJob" v-tooltip="'Edit job'">
            <v-icon>edit</v-icon>
        </v-btn>
    </v-list-item>
</template>

<script>
    export default {
        props: {
            value: Object
        },
        methods: {
            editJob() {
                this.$emit("toggleSearchItems")
                this.$router.push({ name: 'jobsUpdate', params: { name: this.value.name } })
            }
        }
    }
</script>