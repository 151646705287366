import axios from 'axios';

class GroupManangementService {

    async getGroups() {
        return axios.get(`/api/groupManangement/GetGroups`)
    }

    async getGroupsHierarchies() {
        return axios.get(`/api/groupManangement/GetGroupsHierarchies`)
    }
     async getMinimalGroupsHierarchies() {
        return axios.get(`/api/groupManangement/GetMinimalGroupsHierarchies`)
    }

    GetUserGroupHierarchyAccess() {
        return axios.get(`/api/groupManangement/GetUserGroupHierarchyAccess`)
    }
    GetMinimalUserGroupHierarchyAccess() {
        return axios.get(`/api/groupManangement/GetMinimalUserGroupHierarchyAccess`)
    }

    updateGroup(payload) {
        return axios.put(`/api/groupManangement/UpdateGroup`, payload)
    }

    updateGroupsHierarchies(payload) {
        return axios.put(`/api/groupManangement/UpdateGroupsHierarchies`, payload)
    }

    deleteGroups(payload) {
        return axios.delete(`/api/groupManangement/DeleteGroups`, { data: payload })
    }

    createGroup(payload) {
        return axios.post(`/api/groupManangement/CreateGroup`, payload);
    }

    createGroupsHierarchies(payload) {
        return axios.post(`/api/groupManangement/CreateGroupsHierarchies`, payload);
    }

    GetEntitiesInGroupHierarchy(groupIds) {
        let payload = { ids: groupIds };
        return axios.post(`/api/groupManangement/GetEntitiesInGroupHierarchy`, payload);
    }

    GetEntityGroupHierarchyLinks(entityType, assetId) {
        let payload = {
            entityType: entityType,
            entityId: assetId
        };
        return axios.post(`/api/groupManangement/GetEntityGroupHierarchyLinks`, payload);
    }

    UpdateEntityGroupHierarchy(entityType, assetId, groupIds) {
        let payload = {
            entityType: entityType,
            entityId: assetId,
            groupsHierarchyIds: groupIds
        };
        return axios.post(`/api/groupManangement/UpdateEntityGroupHierarchy`, payload);
    }
}

export default new GroupManangementService();