<template>
  <div class="paginationContainer">
    <div>
      <ul>
        <li
          @click="selectedPage = selectedPage - 1"
          :class="{dissabledLink : selectedPage == 1 }"
           style="cursor:pointer"
        >&lt;&lt;</li>
        <li
          v-for="(page) in totalPages"
          :key="page"
          @click="selectedPage = page"
          :class="{paginationSuggested : selectedPage == page }"
          style="cursor:pointer"
        >{{page}}</li>
        <li
         style="cursor:pointer"
          @click="selectedPage = selectedPage + 1"
          :class="{dissabledLink : selectedPage == totalPages }"
        >&gt;&gt;</li>
      </ul>
    </div>
    <div class="gotoPage">
      <div class="paginationLabel">Go to page</div>
      <div class="simpleSelect">
        <div class="md-layout-item">
          <md-field>
            <md-select v-model="selectedPage" name="selectedPage" id="selectedPage">
              <md-option v-for="(page) in totalPages" :key="page" :value="page">{{page}}</md-option>
            </md-select>
          </md-field>
        </div>
      </div>
    </div>
    <div class="gotoPage" style="margin-left: 5px;">
      <div class="paginationLabel">Page Size</div>
      <input type="number"  @input="onInput()" v-model="pageSize" class="pagesize">
    </div>
  </div>
</template>
<script>

import _ from "lodash";

export default {
  name: "Pagination",
  props: {
    items: { type: Object | Array, required: true }
  },
  watch: {
    pageSize: {
      handler: "calculateTotalPages",
      immediate: true
    },
    pageSize: _.debounce(function() {
      this.typing = false;
      this.selectedPage = 1;
      this.calculateTotalPages();
      this.onPageSelect();
    }, 1000),
    selectedPage: function(selectedPage) {
      this.onPageSelect();
    }
  },
  data() {
    return {
      totalPages: 1,
      selectedPage: 1,
      pageSize: 10,
      totalItems: 0,
      listItems: []
    };
  },
  created() {
    this.listItems = this.isArray(this.items) ? this.items : Object.values(this.items);
    this.totalItems = this.listItems.length;
    this.calculateTotalPages();
  },
  mounted() {
    this.onPageSelect();
  },
  methods: {
     isArray(obj) {
      return Array.isArray(obj);
    },
    calculateTotalPages() {
      this.totalPages = Math.ceil(this.totalItems / this.pageSize);
    },
    onInput() {
      this.typing = true;
    },
    onPageSelect() {
      const paginatedList = this.paginateData(
        this.listItems,
        this.selectedPage,
        this.pageSize
      );
      this.$emit("pageItems", paginatedList);
    },
    paginateData(list, page, pageCount) {
      let startIndex = 0;
      const selectedPage = page - 1;
      if (selectedPage != 0) {
        startIndex = selectedPage * pageCount;
      }
      const endIndex = startIndex + pageCount;
      return list.slice(startIndex, endIndex);
    }
  }
};
</script>
<style scoped>
.paginationLabel {
  padding-top: 3px;
  margin-right: 10px;
}
.pagesize {
  background: none;
  border: none;
  border: 1px solid #ccc;
  color: #666;
  padding: 5px;
  height: 30px;
  border-radius: 3px;
  margin-top: 6px;
  width: 63px;
}
.paginationContainer {
  display: flex;
  align-items: center;
  bottom: 0px !important;
  position: absolute !important;
  padding: 5px !important;
  flex-wrap: wrap;
}
.paginationContainer ul {
  padding-left: 10px;
}
.paginationContainer ul li {
  border-radius: 3px;
  padding: 5px 15px;
  margin-right: 10px;
  background: #c5cace;
  color: #666;
  display: inline-block;
  margin-top: 10px;
}
.paginationSuggested {
  background: #288dc1 !important;
  color: #fff !important;
}
.dissabledLink {
  color: #fff !important;
  pointer-events: none !important;
}
.gotoPage {
  display: flex;
  align-items: center;
  color: #666;
}
.simpleSelect .md-layout-item {
  border: 1px solid #ccc;
  height: 30px;
  border-radius: 3px;
  padding-left: 5px;
}
.simpleSelect .md-icon {
  margin: 0;
}

.simpleSelect .md-field.md-theme-default:after {
  background: transparent !important;
}
.simpleSelect {
  padding-top: 10px;
}
.simpleSelect .md-menu.md-select {
  border-bottom: none !important;
  height: 28px !important;
  overflow: hidden;
  width: 80px !important;
}
.simpleSelect .md-field {
  margin: 0 !important;
  min-height: 30px;
  padding-top:0 !important;
}

</style>


