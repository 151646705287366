import Constant from "./../../constant";
import axios from 'axios';

class RBACService {
    getApplicationByName() {
        let params = {
            applicationName: Constant.applicationName
        };
        return axios.get("/api/rbac/GetApplicationByName", { params: params })
    }

    getSecurityRoles(params) {
        return axios.get("/api/rbac/GetSecurityRoles", { params: params })
    }

    
    getSecurityRoleByName(params) {
        return axios.get("/api/rbac/GetSecurityRoleByName", { params: params })
    }

    getSecurityRoleIdByName(params) {
        return axios.get("/api/rbac/GetSecurityRoleIdByName", { params: params })
    }
    

    getUnacceptedUserMultiTenantAccess(email = null) {
        let params = {
            "email": email
        }
        return axios.get("/api/rbac/GetUnacceptedUserMultiTenantAccess", { params: params })
    }

    getGrantedMultiTenantAccess() {
        return axios.get("/api/rbac/GetGrantedMultiTenantAccess")
    }

    updateUserMultiTenantAccess(model) {
        return axios.put("/api/rbac/UpdateUserMultiTenantAccess", model)
    }

    addUserToRole(data) {
        return axios.post(`/api/rbac/AddUserToRole`, data);
    }

    removeUserFromRole(data) {
        let params = {
            "roleId": data.roleId,
            "userId": data.userIds,
            'applicationId': data.applicationId,
        }
        return axios.post(`/api/rbac/RemoveUserFromRole`, data, { params: params });
    }

    getUserTenantAccess() {
        return axios.get("/api/rbac/GetUserTenantAccess");
    }

    getUserTenantAccessForSwitch() {
        return axios.get("/api/rbac/GetUserTenantAccessForSwitch");
    }

    addUserMultiTenantAccess(model) {
        return axios.post(`/api/rbac/AddUserMultiTenantAccess`, model);
    }

    deleteUserMultiTenantAccess(model) {
        return axios.delete(`/api/rbac/DeleteUserMultiTenantAccess`, { data: model });
    }
}

export default new RBACService();