<template>
  <div class="timeLineRow notrequiredforOtherDevice" id="timeLineRow">
    <InseegoTimeBarRangePicker 
      :global="true"
      :range="value"
      v-model="selectedRange">
        <div id="timeLineDisplay" class="timeBar" style="padding:5px;">
          <div :key="itemIndex" v-for="(item,itemIndex) in timeLine" class="timeline-header-cell" :style="{ width : item.width}">
            <div>
              <label>{{item.label}}</label>
            </div>
          </div>
        </div>
      </InseegoTimeBarRangePicker>
  </div>
</template>
<script>
export default {
  name: "InseegoTimeBar",
  props: {
    dataLoaded: {
      type: Boolean,
      required: false,
      default: () => {
        return true;
      }
    },
    value : Object
  },
  computed: {
    selectedRange: {
      get() {
        return this.value;
      },
      set(value) {
        this.setSlider(value);
        this.$emit('input', value);
      }
    },
    timeLine() {
        var segments = [];
        var startDate = this.$moment(this.value.startDate);
        var startMinutes = this.value.startDate.getMinutes();

        var total = Math.abs(this.$moment(this.value.endDate).diff(startDate, 'minutes')) + startMinutes;
        var runningTotal = 0;
				var startHour = this.value.startDate.getHours();
        var endHour = this.value.endDate.getHours();
				for (var hour = startHour;hour <= endHour; hour++) {
					var minutes = 60;
					if (hour == startHour) {
            //minutes-= this.value.startDate.getMinutes();
          } else if (hour == endHour) {
            minutes = total - runningTotal;
          }
          runningTotal += minutes;
					var segment = { 'label' : hour, 'width' :  ((minutes / total) * 100) + '%' };
					segments.push(segment);
        }
        return segments;
			}
  },
  methods: {
    setSlider(value){
        let now = this.getMomentDateObject(new Date().getHours());
        var segments = [];
				var startHour = value.startDate.getHours();
        var endHour = value.endDate.getHours();
        if(startHour == 0 && endHour == 23){
          endHour = 24;
        }
        else if (endHour != 23 || endHour != 24){
          endHour = endHour + 1;
        }
        segments = [startHour,endHour]
        this.$emit('onChange', segments);
				return segments;
    },
    getMomentDateObject(strDate) {
      const datetimeFormat = process.env.VUE_APP_DateTimeFormat;
      const userTimeZoneDate = this.$formatUTCDateTime(strDate, datetimeFormat);
      let moment = this.$moment(userTimeZoneDate, datetimeFormat).tz(this.$getUserTimeZone());
      return moment;
    }
  }
};
</script>
<style scoped>

.timeLineRow {
  position: relative;
  flex:1;
}

.timeBar {
  background: #eee;
}

</style>

