<template>
  <div class="HarshWidget ctrack-h-100" ref="harsh">
    <div id="vueapp" class="vue-app ctrack-h-100">

      <!-- Harsh Chart -->
      <div class="dExecutive-inner" :class="[widgetColorClass, hideloaderClass]" @click="showExecutiveCommonDrillPopup = true">
        <div class="dExecutive-title">
          <h4><span class="dExe-title-icon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24"><path d="M479.42,204a.505.505,0,0,1,0-1.01h22.091a.505.505,0,0,1,0,1.01Zm-2.262-.148a.516.516,0,0,1,0-.714.476.476,0,0,1,.689,0,.516.516,0,0,1,0,.714.476.476,0,0,1-.689,0Zm11.822-2.411-3.572-3.693-1.432,1.48a.881.881,0,0,1-1.276,0l-1.364-1.408-3.489,3.607a.477.477,0,0,1-.69,0,.518.518,0,0,1,0-.714l3.489-3.607-1.314-1.359-1.485,1.535a.477.477,0,0,1-.69,0,.518.518,0,0,1,0-.714l1.485-1.535-1.363-1.408a.958.958,0,0,1,0-1.32l1.432-1.48-1.407-1.454a1.1,1.1,0,0,1,0-1.52,4.744,4.744,0,0,1,5.906-.779l2.31,1.4a8.822,8.822,0,0,0,5.742,1.21,2.291,2.291,0,0,0,1.332-.678l.462-.477h0l8.109-8.384a.478.478,0,0,1,.69,0,.516.516,0,0,1,0,.714l-7.764,8.027,3.88,4.012,3.2-3.3a.477.477,0,0,1,.69,0,.518.518,0,0,1,0,.714l-3.54,3.659-3.614,3.737-3.076,3.181-.536.554a1.457,1.457,0,0,1-2.11,0Zm-2.567-4.081,3.256,3.368a.506.506,0,0,0,.73,0l.192-.2-2.731-2.824-.89-.921Zm-8.392-4.4,5.317,5.5,1.38-1.426-5.317-5.5Zm16.319,3.69,1.222-1.264-4.512-4.665a9.6,9.6,0,0,1-1.93,0v.708a.489.489,0,1,1-.977,0v-.859a9.8,9.8,0,0,1-.98-.256l-.282.706a.487.487,0,0,1-.451.311.472.472,0,0,1-.186-.038.511.511,0,0,1-.265-.659l.265-.663a9.758,9.758,0,0,1-1.219-.628l-.057-.034-.523.614a.477.477,0,0,1-.689.045.516.516,0,0,1-.044-.712l.4-.468-1.4-.85a3.793,3.793,0,0,0-4.721.623.065.065,0,0,0-.018.046.068.068,0,0,0,.018.047l7.466,7.717.009.011.254.263.556-.575-3.115-3.22a.518.518,0,0,1,0-.714.477.477,0,0,1,.69,0l3.46,3.577.908.939a4.724,4.724,0,0,1,6.117,0Zm-12.555-5.229a.516.516,0,0,1,0-.714.476.476,0,0,1,.689,0,.516.516,0,0,1,0,.714.476.476,0,0,1-.689,0Z" transform="translate(-477 -179.999)"/></svg></span> 
            {{widgetTitle}}
          </h4>
        </div>
        <div class="dExecutive-content">
          <div class="ctrack-md-loader" :class="loaderHide?'ctrack-chart-hide-loader':''"></div>
          <div class="dExe-chart-row">
            <div class="dExe-chart-col1">
              <h3 :class="loaderHide?'':'ctrack-chart-hide-loader'">
                <span class="dExe-value-icon" :class="widgetIcon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="22" height="11" viewBox="0 0 22 11"><path d="M107.008,284.158l11.223-11.223,11.223,11.223Z" transform="translate(-107.008 -272.935)"/></svg></span>{{widgetValue}}
              </h3>
            </div>
            <div class="dExe-chart-col2">
              <!-- <div class="dExe-chart-main"><img src="../../../assets/images/dashboard/md-ha-hb-hc-graph.svg" /></div> -->
               <div class="dExe-chart-main">
                <highcharts :options="chartOptions" ref="chart" :class="loaderHide?'':'ctrack-chart-hide-loader'" ></highcharts>
                 </div>
              
            </div>
          </div>
        </div>
        <div class="md-no-data-found-msg"><span class="md-error-dataIcon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"><path d="M754.269,1479.679a.994.994,0,0,1-.1-1.255q2.3-4.585,4.6-9.167l6.755-13.474c.041-.083.08-.167.127-.247a.977.977,0,0,1,1.739.008c1.273,2.494,2.52,5,3.8,7.539l-1.774.883c-.959-1.907-1.908-3.794-2.89-5.746-3.322,6.624-6.612,13.188-9.918,19.78h19.855l-4.808-9.814,1.778-.872c.4.814.789,1.607,1.178,2.4,1.423,2.9,2.85,5.8,4.26,8.711a1.463,1.463,0,0,1,.139.795.968.968,0,0,1-1.076.773h-8.463q-7.16,0-14.322,0A1.117,1.117,0,0,1,754.269,1479.679Zm10.951-4.834a1.3,1.3,0,0,1,1.3-1.349,1.336,1.336,0,0,1,0,2.67A1.3,1.3,0,0,1,765.221,1474.845Zm.963-2.283a.937.937,0,0,1-.582-.905q-.067-1.2-.135-2.406c-.075-1.327-.145-2.654-.229-3.98a1.294,1.294,0,1,1,2.559,0c-.012.143-.023.287-.034.431h0c-.11,1.975-.217,3.949-.332,5.924a.958.958,0,0,1-.9,1A.941.941,0,0,1,766.184,1472.562Zm4.017-7.073,1.752-.917.792,1.5-1.757.917C770.721,1466.482,770.467,1466,770.2,1465.489Z" transform="translate(-753.998 -1455.009)"></path></svg></span>{{$t("Dashboard.noDataFound")}}</div>
      </div>
      <!-- Harsh Chart -->

    </div>
    <v-dialog v-model="showExecutiveCommonDrillPopup">
        <div class="ctrack-md-dialog-content">
            <ExecutiveCommonDrillPopup v-if="showExecutiveCommonDrillPopup" :widgetData="widgetData" :chartProgress="chartProgress" @closeCommonMdDialog="closeCommonMdDialog"></ExecutiveCommonDrillPopup>
        </div>
    </v-dialog>
    <!-- drill down or league table -->
    <md-dialog class="md-driver-drilldown" :md-active.sync="showDrill">
        <DrillDownGrid v-if="showDrill" :data="drillData" :gridSettingsKey="widgetData.widgetName"></DrillDownGrid>
    </md-dialog>
  </div>
</template>

<script>
import dashboardService from "../../../service/dashboard.service";
import { mutations, getters } from "../shared/observeDashboardFilter";
export default {
  name: 'harshchart',
  components: {},
  data: function() {
      return {
        loaderHide:false,
        widgetTitle:"",
        widgetValue:"",
        widgetIcon:"",
        widgetColorClass:"",
        showExecutiveCommonDrillPopup:false,
        chartProgress:null,
        hideloaderClass:"",
        showDrill: false,
        drillData: null,

        chartOptions:{
          chart: {
            margin:[0,0,0,0],
            height:160,
            spacing:[0,0,0,0],
            type: 'pie',          
            options3d: {
              enabled: false,
              alpha: 45
            }
          },
          credits: {
            enabled: false
          },
          title: {
            text: ''
          },     
          accessibility: {
            point: {
              valueSuffix: '%'
              }
          },
          tooltip: {
              backgroundColor: {
                  linearGradient: [0, 0, 0, 60],
                  stops: [
                      [0, '#FFFFFF'],
                      [1, '#FFFFFF']
                  ]
              },
              // shadow:false,
              // useHTML:true,
              borderWidth: 0,
              formatter: function () {
                  return '<b>' + this.point.name + '</b> : <b>' + this.y + '</b>';
              },
              style: {
                  color:'#333333',
                  fontSize: "14px",
                  fontFamily: "Roboto"
              }
          },
          plotOptions: {
            pie: {         
              // colors: ['#EA2129','#5EAD2E','#FFA908'], 
              borderWidth: 0,
              //allowPointSelect: true,
              innerSize:"50%",
              cursor: 'pointer',                
              dataLabels:{                  
                enabled: true,                   
                distance: '-25%',
                position:"center",
                color:"#ffffff", 
                shadow:false,
                style:{
                  fontSize:"12px",
                  fontWeight:"bold",                  
                  fontFamily: 'Roboto,Medium',
                  textOutline:0,                                  
                  },
                }
              },
              series: {              
                    states: {                     
                      inactive: {
                        opacity:1
                      },
                      hover: {                    
                      halo: {
                          size: 0
                        }
                      }
                    }
              }, 
            },
            series:[{data:{}}],  
        }
      }
  },
  props: {
      widgetData: Object
  },
  created(){
    this.HarshEventChartData();        
  }, 
  methods: {
    HarshEventChartData() {
      let self = this;
      self.loaderHide=false;
      
      dashboardService.getWidgetData(this.widgetData.api, getters.getFilterParams()).then(returnResult => {
        self.drillData = returnResult;      
        self.widgetTitle = returnResult.data.valueData.widgetTitle;
        self.widgetValue = returnResult.data.valueData.value + returnResult.data.valueData.type;
        self.widgetIcon =  (returnResult.data.valueData.icon == "down") ? "md-chart-arrow-down" : (returnResult.data.valueData.icon == "up") ? "md-chart-arrow-up" : "";
        self.widgetColorClass = (returnResult.data.valueData.color == "green") ? "success-color" : (returnResult.data.valueData.color == "red") ? "error-color" : "";
        self.chartOptions.series = returnResult.data.chartData.series     
        
        // provide chart progress in popup
        self.chartProgress = returnResult.data.valueData;

        self.loaderHide=true;
      // }, 5000);
      }).catch(err => {
        self.hideloaderClass = 'cd-chart-ifDataBlank'
        self.loaderHide=true
      });
    
    },
    closeCommonMdDialog(openDrill) {
        this.showExecutiveCommonDrillPopup = false;
        // show drill
        if(openDrill){
            this.showDrill = true;
        }
    },

  }
};

</script>
<style scoped>

/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');*{font-family:'Roboto',sans-serif;}
.ctrack-h-100{height:100%;}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.md-dashboard-content{padding-top:10px;color:#C5C5C5;}
[class*="md-chart-arrow"]{display:inline-block;vertical-align:middle;}
[class*="md-chart-arrow"] svg{display:block;}
.md-chart-arrow-down{transform:rotate(180deg);-webkit-transform:rotate(180deg);-moz-transform:rotate(180deg);-ms-transform:rotate(180deg);-o-transform:rotate(180deg);}
.dExecutive-inner{height:100%;width:100%;display:inline-block;background-color:#FFFFFF;border-top:6px solid #CDCDCD;border-radius:5px;padding:0 20px 20px;}
.dExecutive-title{display:inline-block;width:100%;padding:10px 0;}
.dExecutive-title h4{color:#333333;font-size:20px;font-weight:500;position:relative;padding-left:40px;display:inline-block;width:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
.dExecutive-title h4 .dExe-title-icon{position:absolute;left:0;top:50%;transform:translateY(-50%);}
.dExecutive-title h4 .dExe-title-icon svg{display:block;}
.dExecutive-content{display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;justify-content:space-between;-webkit-justify-content:space-between;height:calc(100% - 60px);}
.dExecutive-main div[class*="ctrack-col-"]{margin-bottom:20px;}
.dExe-chart-row{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:nowrap;flex-wrap:nowrap;-webkit-box-align:center;-ms-flex-align:center;align-items:center;justify-content:space-between;-webkit-justify-content:space-between;width:100%;}
.ctrack-align-end{-webkit-box-align:flex-end;-ms-flex-align:flex-end;align-items:flex-end;}
.dExe-chart-col1 h3{font-size:16px !important;display:inline-block;width:100%;vertical-align:middle;position:relative;font-weight:700;white-space:nowrap}
.dExe-chart-col1 h3 span{display:inline-block;vertical-align:middle;padding-right:5px;}
.dExe-chart-col1 h3 span.md-chart-arrow-down{padding-right:0;padding-left:5px;}
.dExe-chart-col1 h3 svg{display:block;}
.dExe-chart-fullwidth{-ms-flex-wrap:wrap;flex-wrap:wrap;}
.dExe-chart-fullwidth > div{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
.dExe-chart-fullwidth .dExe-chart-col1 h3{text-align:center;}
.dExe-chart-main{padding-left:5px;min-width: 150px;max-width: 350px;width: 100%}
.dExe-chart-fullwidth .dExe-chart-main{padding-left:0;}
.dExe-chart-lbl{display:inline-block;width:100%;margin-top:5px;}
.dExe-chart-lbl span{display:inline-block;vertical-align:middle;padding-right:8px;}
.dExe-chart-lbl span svg{display:block}
.dExe-chart-bottom{display:table;width:100%;}
.dExe-chart-bottom > div{display:table-cell;}
.dExe-chart-bottom-inner{text-align:center;display:inline-block;font-size:20px;font-weight:500;margin-top:15px;}
.dExe-chart-bottom-inner small{font-size:14px;margin-bottom:-5px;}
.dExe-chart-bottom-inner small,.dExe-chart-bottom-inner label{display:block;}
.dExe-chart-bottom-col2{text-align:right;}
.error-color{border-color:#FA4B4B;}
.success-color{border-color:#76CD24;}
.error-color .dExe-chart-col1 h3,.error-color .md-chart-color{color:#FA4B4B;}
.success-color .dExe-chart-col1 h3,.success-color .md-chart-color{color:#76CD24;}

/*No Data Section */
.cd-chart-ifDataBlank .dExecutive-title,.cd-chart-ifDataBlank .dExecutive-content{visibility:hidden;}
.cd-chart-ifDataBlank .md-no-data-found-msg{display: block}
.md-no-data-found-msg{padding:15px 15px 15px 50px;position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);font-size:18px;color:#9e4e4e;border:1px solid #ffb2b2;background-color:#ffb2b2;border-radius:3px;white-space: nowrap; display:none}
.md-no-data-found-msg .md-error-dataIcon{position:absolute;left:15px;top:50%;transform:translateY(-50%);}
.md-no-data-found-msg .md-error-dataIcon svg{display:block;}

/*Overspeed Drill Popup CSS*/
.dChartDetailsModal-lg{width: 96%; max-width: 70%;}
.dChartDetailsModal-lg .ctrack-modal-body{padding:0;}
.ctrack-md-modal{border-radius:10px;outline:0;box-shadow:1px 2px 16px rgba(0,0,0,0.26);-webkit-box-shadow:1px 2px 16px rgba(0,0,0,0.26);-moz-box-shadow:1px 2px 16px rgba(0,0,0,0.26);}
.ctrack-md-dialog-content{height:100%;display:inline-block;width:100%;overflow: auto;-webkit-overflow-scrolling: touch;}

/* show common loader at top */
.md-dialog.dChartDetailsModal-lg.lowZindex{z-index:998 !important;}

/* Responsive CSS */
@media only screen and (min-width:576px){
/*Overspeed Drill Popup CSS*/
.ctrack-modal-dialog{max-width:500px;margin:1.75rem auto;}
.ctrack-modal-dialog-lg{max-width:1100px;padding: 15px}
}
@media only screen and (max-width:1799px){
/*Overspeed Drill Popup CSS*/
.dChartDetailsModal-lg{max-width: 80%;}
}

@media only screen and (max-width:1399px){
/*Overspeed Drill Popup CSS*/
.dChartDetailsModal-lg{max-width: 85%;}
}
@media only screen and (max-width:1299px){
/*Overspeed Drill Popup CSS*/
.dChartDetailsModal-lg{max-width: 90%;}
}

@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1399px){
    .dExe-chart-col1 h3{font-size:40px;}
}
@media only screen and (max-width:1299px){
    .dExe-chart-col1 h3{font-size:35px;}
}
@media only screen and (max-width:1199px){}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){}
@media only screen and (max-width:767px){
/*Overspeed Drill Popup CSS*/    
.dChartDetailsModal-lg, .dChartDetailsModal{max-height: 96%}
.dChartDetailsModal-lg{max-width: 100%;}
}
@media only screen and (max-width:575px){
  .dExecutive-inner{padding:0 15px 15px}
  .dExe-chart-row{-ms-flex-wrap:wrap;flex-wrap:wrap;}
  .dExe-chart-row > div{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;text-align:center;}
  .dExe-chart-col1{margin-bottom:5px;}
  .dExe-chart-labels{display:table;width:100%;padding:5px 0 10px;}
  .dExe-chart-labels > div{display:table-cell;width:auto;}
  .dExe-chart-main{padding-left:0;}
}


</style>
