<template>
    <td :class="className">
        <span v-if="value?.toolTip">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs"
                            v-on="on"
                            @click="clickHandler"
                            style="cursor:pointer">{{value?.icon}}</v-icon>
                </template>
                <span v-if="!Array.isArray(value?.toolTip)">{{value?.toolTip }}</span>
                <span v-else v-for="(warning,index) in tooltipList"
                      :key="index"
                      style="display: block;">
                    {{ warning }}
                </span>
            </v-tooltip>
        </span>
    </td>
</template>
<!-- The field name will be parsed for a tooltip -->
<script>
    export default {
        name: "iconCell",
        props: {
            field: String,
            dataItem: Object,
            format: String,
            className: String,
            columnIndex: Number,
            columnsCount: Number,
            rowType: String,
            level: Number,
            expanded: Boolean,
            editor: String
        },
        methods: {
            clickHandler() {
                this.$emit(this.editor, this.dataItem);
            }
        },
        computed: {
            tooltipList() {
                return Array.isArray(this.value?.toolTip) ? this.value?.toolTip : []
            },
            value() {
                return this.dataItem[this.field]
            },
        },
    };
</script>
<style scoped>
    .gridLink {
        color: #288dc0;
        cursor: pointer;
    }
</style>