<template>
    <v-list class="list-overflow" style="max-height: 80vh;">
        <v-list-item class="titleListItem">
            <v-toolbar dense color="#5cc2ae">
                <!--dark #5cc2ae -->
                <v-toolbar-title class="heading"
                                 style="color: #113651 !important; text-shadow: 0 0 black; display: contents;">
                    {{$t('Dashboard.selectColumns')}}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon
                       @click="$emit('close')"
                       style="margin-right: -16px;"
                       auto-id="contact_col_close">
                    <v-icon style=" color: #113651 !important;">close</v-icon>
                </v-btn>
            </v-toolbar>
        </v-list-item>
        <v-list-item v-if="columnShortcuts.length !=0" class="shortCutListItem">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon
                           v-bind="attrs"
                           v-on="on"
                           @click="toggleAllColumns(false)"
                           auto-id="contact_col_desel_all">
                        <v-icon style="color: #113651 !important;">filter_none</v-icon>
                    </v-btn>
                </template>
                {{$t('Deselect-All')}}
            </v-tooltip>
            <v-tooltip bottom
                       v-for="shortcut in columnShortcuts"
                       :key="shortcut.name">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon
                           v-bind="attrs"
                           v-on="on"
                           @click="loadColumnsAsPerShortcut(shortcut.columns)"
                           :auto-id="shortcut.name">
                        <v-icon style="color: #113651 !important;">{{shortcut.icon ?? 'list'}}</v-icon>
                    </v-btn>
                </template>
                {{$t(shortcut.name)}}
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon
                           v-bind="attrs"
                           v-on="on"
                           @click="toggleAllColumns(true)"
                           auto-id="contact_col_sel_all">
                        <v-icon style="color: #113651 !important;">library_add_check</v-icon>
                    </v-btn>
                </template>
                {{$t('addjob.selectall')}}
            </v-tooltip>
        </v-list-item>
        <v-list-item v-for="column in columns" :key="column.title" v-if="column.title && column.title != ' '" :auto-id="column.title">
            <kendo-switch @change="columnVisibility(column)" v-model="column.visible"></kendo-switch>
            &nbsp;{{column.title}}
        </v-list-item>
        <v-list-item class="restoreListItem">
            <v-btn text block outlined @click="columnDefaults()" auto-id="contact_col_restore">
                {{$t("UserLabels.restoreToDefaults")}}
            </v-btn>
        </v-list-item>
    </v-list>
</template>
<script>
    export default {
        props: {
            columns: Array,
            columnShortcuts: {
                type: Array
                , default() {
                    return []
                }
            },
            persist: Function,
            getDefaults: Function,
        },
        methods: {
            columnVisibility(column) {
                column.hidden = column.visible;
                if (this.persist !== undefined) {
                    this.persist();
                }
            },
            columnDefaults() {
                let self = this;
                if (self.getDefaults) {
                    self.getDefaults().forEach(col => {
                        self.columns.find(i => i.field == col.field).hidden = col.hidden == true;
                        self.columns.find(i => i.field == col.field).visible = !self.columns.find(i => i.field == col.field).hidden;
                    });
                    if (self.persist !== undefined) {
                        self.persist();
                    };
                };              
            },
            loadColumnsAsPerShortcut(columns) {
                for (var x = 0; x < this.columns.length; x++) {
                    this.columns[x].hidden = !columns.includes(this.columns[x].field)
                    this.columns[x].visible = !this.columns[x].hidden;
                    this.columns[x].orderIndex = columns.includes(this.columns[x].field)
                        ? columns.indexOf(this.columns[x].field)
                        : this.columns[x].orderIndex + columns.length
                };
                this.columns.sort((a, b) => {
                    if (a.orderIndex === undefined || b.orderIndex === undefined) {
                        return 1;
                    }
                    return (a.orderIndex > b.orderIndex) ? 1 : -1;
                })
                for (var x = 0; x < this.columns.length; x++) {
                    this.columns[x].orderIndex = x;
                };
                if (this.persist !== undefined) {
                    this.persist();
                };
            },
            toggleAllColumns(value) {
                for (var x = 0; x < this.columns.length; x++) {
                    this.columns[x].hidden = !value;
                    this.columns[x].visible = value;
                };
                //Always show select
                this.columns.find(i => i.field == 'selected').hidden = false;
                this.columns.find(i => i.field == 'selected').visible = true;
                if (this.persist !== undefined) {
                    this.persist();
                };
            },
        }

    }
</script>
<style scoped>
    .list-overflow {
        overflow-y: auto;
        max-height: calc(100vh - 25px);
        padding: 0;
    }
    .menuable__content__active {
        z-index: 15 !important;
    }

    .titleListItem {
        padding: 0;
        position: sticky;
        top: 0px;
        background-color: white;
        z-index: 8;
    }

    .shortCutListItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        min-height: 32px;
        height: 32px;
        background-color: rgb(80, 194, 173);
        border-top-style: inset;
        border-width: thin;
        border-bottom-style: outset;
        position: sticky;
        top: 48px;
        z-index: 9;
        flex-direction: row;
    }

    .v-list-item:after {
        display: none;
    }

    .restoreListItem {
        position: sticky;
        bottom: 0px;
        background-color: white;
        padding-bottom: 8px;
        padding-top: 8px;
        border-top-style: inset;
        border-width: thin;
    }
</style>