<template>
  <div class="DriverBehaviourChart ctrack-h-100" ref="DriverBehaviour">
    <div id="vueapp" class="vue-app ctrack-h-100">

      <!-- Driver Behaviour Chart -->
      <div class="dExecutive-inner" :class="[widgetColorClass, hideloaderClass]"
        @click="showExecutiveCommonDrillPopup = true">
        <div class="dExecutive-title">
          <h4><span class="dExe-title-icon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="21"
                height="23" viewBox="0 0 21 23">
                <path
                  d="M758.248,439.971a15.623,15.623,0,0,1-2.087-.482,1.588,1.588,0,0,1-1.134-1.351,1.7,1.7,0,0,1,.665-1.693l.163-.127a6.75,6.75,0,0,0-11.725-1.9,6.289,6.289,0,0,0-.933,1.716.19.19,0,0,0,.087.263,1.666,1.666,0,0,1,.7,1.651,1.623,1.623,0,0,1-1.165,1.41,7.144,7.144,0,0,1-2.379.5,1.166,1.166,0,0,1-1.224-.887,4.54,4.54,0,0,1-.216-1.291,9.248,9.248,0,0,1,.7-3.736,7.63,7.63,0,0,1,2.613-3.152,12.169,12.169,0,0,1,4.126-1.959.453.453,0,0,1,.333.054,4.529,4.529,0,0,0,5.318,0,.4.4,0,0,1,.287-.075,12.068,12.068,0,0,1,5.435,3,7.12,7.12,0,0,1,2.052,4.18,7.317,7.317,0,0,1,.047,2.45,3.14,3.14,0,0,1-.131.479,1.28,1.28,0,0,1-1.242.97A1.836,1.836,0,0,1,758.248,439.971Zm-11.9-.331c-.61-.08-1.216-.192-1.824-.288-.123-.019-.207-.054-.22-.209a5.221,5.221,0,0,1,1.085-3.793.61.61,0,0,1,.069-.053l1.854,1.829a.571.571,0,0,0,.07.069.527.527,0,0,1,.2.757,1.755,1.755,0,0,0,.127,1.557c.034.066.072.13.107.2,0,.005,0,.015-.009.065C747.32,439.727,746.831,439.7,746.348,439.64Zm4.815.108a6.02,6.02,0,0,0,.285-.69,1.957,1.957,0,0,0-.123-1.335.3.3,0,0,1,0-.256c.733-.739,1.475-1.467,2.229-2.211a5.262,5.262,0,0,1,1.132,3.361c-.013.66-.022.683-.673.789-.835.136-1.677.231-2.516.339a.942.942,0,0,1-.122.007C751.307,439.753,751.235,439.748,751.163,439.748Zm-2.684-1.192a1.008,1.008,0,1,1,2.017.015,1.031,1.031,0,0,1-1,1.022h0A1.03,1.03,0,0,1,748.479,438.557Zm.074-1.777a.187.187,0,0,1-.17-.018c-.749-.722-1.492-1.449-2.251-2.19a5.089,5.089,0,0,1,6.725.038l-2.3,2.245A2.067,2.067,0,0,0,748.552,436.78Zm-3.34-10.336a6.309,6.309,0,0,1-1.193-3.886,5.656,5.656,0,0,1,3.136-4.97,5.252,5.252,0,0,1,6.814,1.853,6.029,6.029,0,0,1-1.542,8.317,5.282,5.282,0,0,1-7.215-1.314Z"
                  transform="translate(-738.999 -417)" /></svg></span>
            {{widgetTitle}}</h4>
        </div>
        <div class="dExecutive-content">
          <div class="ctrack-md-loader" :class="loaderHide?'ctrack-chart-hide-loader':''"></div>
          <div class="dExe-chart-row">
            <div class="dExe-chart-col1">
              <h3 :class="loaderHide?'':'ctrack-chart-hide-loader'">
                <span class="dExe-value-icon" :class="widgetIcon"><svg fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg" width="22" height="11" viewBox="0 0 22 11">
                    <path d="M107.008,284.158l11.223-11.223,11.223,11.223Z" transform="translate(-107.008 -272.935)" />
                    </svg></span>{{widgetValue}}
              </h3>
            </div>
            <div class="dExe-chart-col2">
              <!-- <div class="dExe-chart-main"><img src="../../../assets/images/dashboard/md-driver-graph.svg" /></div> -->
              <div class="dExe-chart-main">
                <highcharts :options="chartOptions" :class="loaderHide?'':'ctrack-chart-hide-loader'"></highcharts>
              </div>
            </div>
          </div>
        </div>
        <div class="md-no-data-found-msg"><span class="md-error-dataIcon"><svg fill="currentColor"
              xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
              <path
                d="M754.269,1479.679a.994.994,0,0,1-.1-1.255q2.3-4.585,4.6-9.167l6.755-13.474c.041-.083.08-.167.127-.247a.977.977,0,0,1,1.739.008c1.273,2.494,2.52,5,3.8,7.539l-1.774.883c-.959-1.907-1.908-3.794-2.89-5.746-3.322,6.624-6.612,13.188-9.918,19.78h19.855l-4.808-9.814,1.778-.872c.4.814.789,1.607,1.178,2.4,1.423,2.9,2.85,5.8,4.26,8.711a1.463,1.463,0,0,1,.139.795.968.968,0,0,1-1.076.773h-8.463q-7.16,0-14.322,0A1.117,1.117,0,0,1,754.269,1479.679Zm10.951-4.834a1.3,1.3,0,0,1,1.3-1.349,1.336,1.336,0,0,1,0,2.67A1.3,1.3,0,0,1,765.221,1474.845Zm.963-2.283a.937.937,0,0,1-.582-.905q-.067-1.2-.135-2.406c-.075-1.327-.145-2.654-.229-3.98a1.294,1.294,0,1,1,2.559,0c-.012.143-.023.287-.034.431h0c-.11,1.975-.217,3.949-.332,5.924a.958.958,0,0,1-.9,1A.941.941,0,0,1,766.184,1472.562Zm4.017-7.073,1.752-.917.792,1.5-1.757.917C770.721,1466.482,770.467,1466,770.2,1465.489Z"
                transform="translate(-753.998 -1455.009)"></path>
            </svg></span>{{$t("Dashboard.noDataFound")}}</div>
      </div>
      <!-- Driver Behaviour Chart -->

    </div>
    <v-dialog v-model="showExecutiveCommonDrillPopup">
      <div class="ctrack-md-dialog-content">
        <ExecutiveCommonDrillPopup v-if="showExecutiveCommonDrillPopup" :widgetData="widgetData"
          :chartProgress="chartProgress" @closeCommonMdDialog="closeCommonMdDialog"></ExecutiveCommonDrillPopup>
      </div>
    </v-dialog>
    <!-- drill down or league table -->
    <md-dialog class="md-driver-drilldown" :md-active.sync="showDrill">
      <DrillDownGrid v-if="showDrill" :data="drillData" :gridSettingsKey="widgetData.widgetName"></DrillDownGrid>
    </md-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import HighChartMore from 'highcharts/highcharts-more'
import HighChartBorderRadius from 'highcharts-border-radius'
import HighChartAccessibility from 'highcharts/modules/accessibility';
import dashboardService from "../../../service/dashboard.service";
import {
  mutations,
  getters
} from "../shared/observeDashboardFilter";


HighChartBorderRadius(Highcharts);
HighChartMore(Highcharts);
HighChartAccessibility(Highcharts);
Vue.use(HighchartsVue, {});
export default {
  name: 'driverbehaviourchart',
  components: {},
  data: function () {
    return {
      loaderHide: false,
      widgetTitle: "",
      widgetValue: "",
      widgetIcon: "",
      widgetColorClass: "",
      showExecutiveCommonDrillPopup: false,
      chartProgress: null,
      hideloaderClass: "",
      showDrill: false,
      drillData: null,

      chartOptions: {
        chart: {
          type: 'column',
          height: 210
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        colorArray: ['#999999', '#fa4b4b'],
        xAxis: {
          categories: [],
          labels: {
            formatter() {
              return `<span style="color: ${this.chart.userOptions.colorArray[this.pos]}">${this.value}</span>`
            },
            style: {
              fontSize: '14px',
              fontFamily: "Roboto,Medium",
              opacity: 1
            },
          }
        },
        yAxis: {
          labels: {
            enabled: false
          },
          gridLineWidth: 0,
          title: {
            enabled: false
          }
        },
        tooltip: {
          backgroundColor: {
            linearGradient: [0, 0, 0, 60],
            stops: [
              [0, '#FFFFFF'],
              [1, '#FFFFFF']
            ]
          },
          borderWidth: 0,
          style: {
            color: '#333333',
            fontSize: "14px",
            fontFamily: "Roboto"
          },
          formatter: function () {
            return '<b>' + this.x + '</b> <br/> <b>' + this.y + '</b>';
          },
          //enabled: true 
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            dataLabels: {
              enabled: true,
              verticalAlign: 'top',
              color: "#FFFFFF",
              shadow: false,
              style: {
                fontSize: "14px",
                // fontWeight:"bold",                  
                fontFamily: 'Roboto,Bold',
                textOutline: 0,
              },
              formatter: function () {
                return this.point.y.toFixed(2);
              }
            }
          },
          legend: {
            enabled: false
          }
        },
        series: [{
          borderRadiusTopLeft: '25%',
          borderRadiusTopRight: '25%'
        }]
      }
    }
  },
  props: {
    widgetData: Object
  },
  created() {
    this.getDriverBehaviourChartData();
  },
  methods: {
    getDriverBehaviourChartData() {
      let self = this;
      self.loaderHide = false;

      dashboardService.getWidgetData(this.widgetData.api, getters.getFilterParams()).then(returnResult => {
        self.drillData = returnResult;
        self.widgetTitle = returnResult.data.valueData.widgetTitle;
        self.widgetValue = Number(returnResult.data.valueData.value).toFixed(2) + returnResult.data.valueData.type;
        self.widgetIcon = (returnResult.data.valueData.icon == "down") ? "md-chart-arrow-down" : (returnResult.data.valueData.icon == "up") ? "md-chart-arrow-up" : "";
        self.widgetColorClass = (returnResult.data.valueData.color == "green") ? "success-color" : (returnResult.data.valueData.color == "red") ? "error-color" : "";
        self.chartOptions.series = returnResult.data.chartData.series
        self.chartOptions.xAxis.categories = returnResult.data.chartData.categories;

        let lastWeekColor = (returnResult.data.valueData.color == "green") ? "#76CD24" : (returnResult.data.valueData.color == "red") ? "#FA4B4B" : "#CCCCCC";
        if (self.chartOptions.series[0].data[1])
          self.chartOptions.series[0].data[1].color = lastWeekColor;
        self.chartOptions.colorArray[1] = lastWeekColor;

        // provide chart progress in popup
        self.chartProgress = returnResult.data.valueData;

        self.loaderHide = true;

      }).catch(err => {
        self.hideloaderClass = 'cd-chart-ifDataBlank'
        self.loaderHide = true
      });
    },
    closeCommonMdDialog(openDrill) {
      this.showExecutiveCommonDrillPopup = false;
      // show drill
      if (openDrill) {
        this.showDrill = true;
      }
    },

  }
};

</script>
<style scoped>
/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');

* {
  font-family: 'Roboto', sans-serif;
}

.ctrack-h-100 {
  height: 100%;
}

.ctrack-text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.md-dashboard-content {
  padding-top: 10px;
  color: #C5C5C5;
}

[class*="md-chart-arrow"] {
  display: inline-block;
  vertical-align: middle;
}

[class*="md-chart-arrow"] svg {
  display: block;
}

.md-chart-arrow-down {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.dExecutive-inner {
  height: 100%;
  width: 100%;
  display: inline-block;
  background-color: #FFFFFF;
  border-top: 6px solid #CDCDCD;
  border-radius: 5px;
  padding: 0 20px 20px;
}

.dExecutive-title {
  display: inline-block;
  width: 100%;
  padding: 10px 0;
}

.dExecutive-title h4 {
  color: #333333;
  font-size: 20px;
  font-weight: 500;
  position: relative;
  padding-left: 40px;
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dExecutive-title h4 .dExe-title-icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.dExecutive-title h4 .dExe-title-icon svg {
  display: block;
}

.dExecutive-content {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  height: calc(100% - 60px);
}

.dExecutive-main div[class*="ctrack-col-"] {
  margin-bottom: 20px;
}

.dExe-chart-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  width: 100%;
}

.ctrack-align-end {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
}

.dExe-chart-col1 h3 {
  font-size: 16px !important;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  position: relative;
  font-weight: 700;
  white-space: nowrap
}

.dExe-chart-col1 h3 span {
  display: inline-block;
  vertical-align: middle;
  padding-right: 5px;
}

.dExe-chart-col1 h3 span.md-chart-arrow-down {
  padding-right: 0;
  padding-left: 5px;
}

.dExe-chart-col1 h3 svg {
  display: block;
}

.dExe-chart-fullwidth {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.dExe-chart-fullwidth>div {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.dExe-chart-fullwidth .dExe-chart-col1 h3 {
  text-align: center;
}

.dExe-chart-main,
.cd-chart-box-img {
  padding-left: 5px;
  ;
  min-width: 240px;
  width: 100%;
}

.dExe-chart-fullwidth .dExe-chart-main {
  padding-left: 0;
}

.dExe-chart-lbl {
  display: inline-block;
  width: 100%;
  margin-top: 5px;
}

.dExe-chart-lbl span {
  display: inline-block;
  vertical-align: middle;
  padding-right: 8px;
}

.dExe-chart-lbl span svg {
  display: block
}

.dExe-chart-bottom {
  display: table;
  width: 100%;
}

.dExe-chart-bottom>div {
  display: table-cell;
}

.dExe-chart-bottom-inner {
  text-align: center;
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  margin-top: 15px;
}

.dExe-chart-bottom-inner small {
  font-size: 14px;
  margin-bottom: -5px;
}

.dExe-chart-bottom-inner small,
.dExe-chart-bottom-inner label {
  display: block;
}

.dExe-chart-bottom-col2 {
  text-align: right;
}

.error-color {
  border-color: #FA4B4B;
}

.success-color {
  border-color: #76CD24;
}

.error-color .dExe-chart-col1 h3,
.error-color .md-chart-color {
  color: #FA4B4B;
}

.success-color .dExe-chart-col1 h3,
.success-color .md-chart-color {
  color: #76CD24;
}

/*No Data Section */
.cd-chart-ifDataBlank .dExecutive-title,
.cd-chart-ifDataBlank .dExecutive-content {
  visibility: hidden;
}

.cd-chart-ifDataBlank .md-no-data-found-msg {
  display: block
}

.md-no-data-found-msg {
  padding: 15px 15px 15px 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  color: #9e4e4e;
  border: 1px solid #ffb2b2;
  background-color: #ffb2b2;
  border-radius: 3px;
  white-space: nowrap;
  display: none
}

.md-no-data-found-msg .md-error-dataIcon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.md-no-data-found-msg .md-error-dataIcon svg {
  display: block;
}

/*Overspeed Drill Popup CSS*/
.dChartDetailsModal-lg {
  width: 96%;
  max-width: 70%;
}

.dChartDetailsModal-lg .ctrack-modal-body {
  padding: 0;
}

.ctrack-md-modal {
  border-radius: 10px;
  outline: 0;
  box-shadow: 1px 2px 16px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 1px 2px 16px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 1px 2px 16px rgba(0, 0, 0, 0.26);
}

.ctrack-md-dialog-content {
  height: 100%;
  display: inline-block;
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

/* show common loader at top */
.md-dialog.dChartDetailsModal-lg.lowZindex {
  z-index: 998 !important;
}

/* Responsive CSS */
@media only screen and (min-width:576px) {

  /*Overspeed Drill Popup CSS*/
  .ctrack-modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .ctrack-modal-dialog-lg {
    max-width: 1100px;
    padding: 15px
  }
}

@media only screen and (max-width:1799px) {

  /*Overspeed Drill Popup CSS*/
  .dChartDetailsModal-lg {
    max-width: 80%;
  }
}

@media only screen and (max-width:1399px) {

  /*Overspeed Drill Popup CSS*/
  .dChartDetailsModal-lg {
    max-width: 85%;
  }
}

@media only screen and (max-width:1299px) {

  /*Overspeed Drill Popup CSS*/
  .dChartDetailsModal-lg {
    max-width: 90%;
  }
}

@media only screen and (min-width:1200px) {}

@media only screen and (min-width:1300px) {}

@media only screen and (min-width:1400px) {}

@media only screen and (max-width:1399px) {
  .dExe-chart-col1 h3 {
    font-size: 40px;
  }

  .dExe-chart-main,
  .cd-chart-box-img {
    min-width: 170px
  }
}

@media only screen and (max-width:1299px) {
  .dExe-chart-col1 h3 {
    font-size: 35px;
  }

  .dExe-chart-main,
  .cd-chart-box-img {
    min-width: 170px
  }
}

@media only screen and (max-width:1199px) {}

@media only screen and (max-width:1024px) {}

@media only screen and (max-width:991px) {}

@media only screen and (max-width:767px) {

  /*Overspeed Drill Popup CSS*/
  .dChartDetailsModal-lg,
  .dChartDetailsModal {
    max-height: 96%
  }

  .dChartDetailsModal-lg {
    max-width: 100%;
  }
}

@media only screen and (max-width:575px) {
  .dExecutive-inner {
    padding: 0 15px 15px
  }

  .dExe-chart-row {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .dExe-chart-row>div {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
  }

  .dExe-chart-col1 {
    margin-bottom: 5px;
  }

  .dExe-chart-labels {
    display: table;
    width: 100%;
    padding: 5px 0 10px;
  }

  .dExe-chart-labels>div {
    display: table-cell;
    width: auto;
  }

  .dExe-chart-main {
    padding-left: 0;
  }
}


</style>
