<template>
  <md-menu class="header-menu">
    <md-button class="md-icon-button" md-menu-trigger>
      <md-icon class="blackIcon">menu</md-icon>
    </md-button>
    <md-menu-content class="customSettingsScroll" :class="additionalClass">
      <md-button class="setToDefault" @click="columnDefaults()">{{$t("UserLabels.restoreToDefaults")}}</md-button>
      <md-menu-item v-for="column in columns" :key="column.title" v-if="column.title">
        <kendo-switch @change="columnVisibility(column)" v-model="column.visible"></kendo-switch>
        &nbsp;{{column.title}}
      </md-menu-item>
    </md-menu-content>
  </md-menu>
</template>
<script>
export default {
  name: "DashboardChartsSelector",
  props: {
    columns: Array,
    getDefaults: Function,
    persist: Function,
    additionalClass: String
  },
  methods: {
    columnVisibility(column) {      
      column.hidden = column.visible;
      if (this.persist !== undefined) {
        this.persist();
      }
    },
    columnDefaults() {
      this.$emit("update:columns", this.getDefaults());
      // if (this.persist !== undefined) {
      //   this.persist();
      //}
    }
  }
};
</script>
<style scoped>
.customSettingsScroll {
  top: 170px !important;
  bottom: 310px !important;
}
.customSettingsScroll.dashboard-column-selector{
  top: 135px !important;
  bottom: auto !important;
  max-height: 250px !important;
}
.blackIcon{
    width:26px;
    height:24px;
    color:#959595;
}

.setToDefault {
  color: #002B49;
  background-color: rgb(255, 255, 255);
  border: 2px solid #002B49;
}
</style>