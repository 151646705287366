<template>
    <v-list-item @click="itemSelected"
                 style="border-bottom: solid thin lightgrey;"
                 :class="[ 'priority' + alert.priority ]">
        <v-list-item-content>
            <v-list-item-title class="splitContent">
                <strong>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-if="alert.isAlertSuppressed"
                                    v-bind="attrs"
                                    v-on="on"
                                    style="margin-bottom: 2px;">
                                mdi-comment-remove-outline
                            </v-icon>
                        </template>
                        <span>{{$t("FleetLabels.alertSuppressed")}}</span>
                    </v-tooltip>
                    {{ alert.alertType }}
                </strong>
                <div v-if="showToolbar">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-if="$checkRBACAccess('replayRead')" icon
                                   dark
                                   v-bind="attrs"
                                   v-on="on"
                                   @click.stop="openReplayPrompt">
                                <md-icon :md-src="require(`@/assets/images/replay.icon.svg`)">
                                </md-icon>
                            </v-btn>
                        </template>
                        <span>{{$t("LPD.replay")}}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs"
                                   v-on="on"
                                   icon small
                                   @click="$emit('showOnMap',alert)">
                                <v-icon>mdi-image-marker</v-icon>
                            </v-btn>
                        </template>
                        <span>{{$t("Replay.showonMap")}}</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="alert.vehicleId">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs"
                                   v-on="on"
                                   icon small
                                   @click="$emit('showCommentAudit',alert)">
                                <v-icon>mdi-clipboard-text-clock</v-icon>
                            </v-btn>
                        </template>
                        <span>{{$t("FleetLabels.commentAudit")}}</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="alert.actionComments.length != 0">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs"
                                   v-on="on"
                                   icon small
                                   @click="$emit('showCommentHistory',alert)">
                                <v-icon>mdi-format-list-checkbox</v-icon>
                            </v-btn>
                        </template>
                        <span>{{$t("FleetLabels.showCommentHistory")}}</span>
                    </v-tooltip>
                </div>
            </v-list-item-title>
            <v-list-item-subtitle v-if="alert.statusText">
                <v-chip small>{{alert.statusText}}</v-chip>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="assetName">
                {{ assetName }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="operatorName">
                {{ operatorName }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="splitContent">
                {{ dateDisplay }}
                <template v-if="alert.isAlertSuppressed && !showMSG">
                    <div>
                        <suppressedNotificationMethod v-for="notificationMethod in supressedNotificationMethods"
                                                      :key="notificationMethod.key"
                                                      :icon="notificationMethod.icon"
                                                      :title="notificationMethod.title"
                                                      :policies="notificationMethod.policies"
                                                      @openPolicy="openPrompt">
                        </suppressedNotificationMethod>
                    </div>
                </template>
            </v-list-item-subtitle>
            <template v-if="showMSG">
                <v-list-item-title>
                    <v-alert text shaped
                             class="messageBlock">
                        <strong style="display: block; white-space: pre-wrap;">{{ beautifiedDetails }}</strong>
                    </v-alert>
                </v-list-item-title>
                <v-list-item-subtitle v-if="alert.rulePolicyName" 
                                      class="policyBlock">
                    {{$t("FleetLabels.alertPolicy")}}&nbsp;:&nbsp; <v-chip small @click="openPrompt(alert.rulePolicyId,alert.rulePolicyName)" class="chipLink">{{alert.rulePolicyName}}</v-chip>
                </v-list-item-subtitle>
                <template v-if="alert.isAlertSuppressed">
                    <v-list-item-subtitle class="splitContent" style="border-top: solid thin; margin-top: 4px; padding-top: 4px;">
                        <div>
                            <v-icon small style="margin-bottom: 2px;">mdi-comment-remove-outline</v-icon>
                            {{$t("FleetLabels.suppressingPolicies")}}
                        </div>
                        <template v-if="showMSG">
                            <div>
                                <suppressedNotificationMethod v-for="notificationMethod in supressedNotificationMethods"
                                                              :key="notificationMethod.key"
                                                              :icon="notificationMethod.icon"
                                                              :title="notificationMethod.title"
                                                              :policies="notificationMethod.policies"
                                                              @openPolicy="openPrompt">
                                </suppressedNotificationMethod>
                            </div>
                        </template>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                        <v-chip v-for="policy in alert.suppressingPolicies"
                                :key="policy.suppressingPolicyId"
                                small
                                @click="openPrompt(policy.suppressingPolicyId,policy.suppressingPolicyName)"
                                class="chipLink" 
                                style="margin-bottom: 4px; margin-right: 4px; ">
                            {{policy.suppressingPolicyName}}
                            <v-tooltip v-for="ruleAction in policy.suppressedRuleActions" :key="ruleAction" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon small v-bind="attrs"
                                            v-on="on"
                                            style="margin-left:4px;">
                                        {{suppressionTooltip(ruleAction).icon}}
                                    </v-icon>
                                </template>
                                <span>{{suppressionTooltip(ruleAction).title}}</span>
                            </v-tooltip>
                        </v-chip>
                    </v-list-item-subtitle>
                </template>
            </template>
        </v-list-item-content>
        <v-list-item-action v-if="selectable">
            <v-icon>keyboard_arrow_right</v-icon>
        </v-list-item-action>
        <prompt ref="prompt"
                :title="promptTitle"
                :confirmationMessage="promptBody"
                :notice="$t('FleetLabels.openPolicy.notice')"
                @confirm="promptConfirm">
        </prompt>
    </v-list-item>
</template>
<script>
    import { format } from "date-fns";
    import prompt from "@/components/ConfirmationDialog/SubComponents/genericPrompt"
    import suppressedNotificationMethod from "@/components/fleet-alerts/parts/suppressedNotificationMethod.vue"
    import { RuleService } from "@/service/index";
    export default {
        components: {
            prompt,
            suppressedNotificationMethod,
        },
        props: {
            alertItem: {
                type: Object,
                default() {
                    return {
                        alertType: "",
                        alertGenerateDateTime: new date(),
                        priority: "LOW",
                    }
                },
            },
            selectable: {
                type: Boolean,
                default() {
                    return false;
                },
            },
            showMSG: {
                type: Boolean,
                default() {
                    return false;
                },
            },
            showToolbar: {
                type: Boolean,
                default() {
                    return false;
                },
            },
        },
        data() {
            return {
                localAlert: {},
                promptReference: '',
                suppressionTooltips: [
                    {
                        key: 1,
                        title: [this.$t('sms'), this.$t('FleetLabels.notificationSuppressed')].join(' '),
                        icon: "mdi-message-bulleted-off",
                    },
                    {
                        key: 2,
                        title: [this.$t('email'), this.$t('FleetLabels.notificationSuppressed')].join(' '),
                        icon: "mdi-email-off-outline",
                    },
                    {
                        key: 4,
                        title: [this.$t('app_notification'), this.$t('FleetLabels.notificationSuppressed')].join(' '),
                        icon: "mdi-bell-off",
                    },
                    {
                        key: 7,
                        title: [this.$t('dailer'), this.$t('FleetLabels.notificationSuppressed')].join(' '),
                        icon: "mdi-phone-off",
                    },
                ],
            };
        },
        methods: {
            //#region Data Getters
            getOperatorNameFromStore() {
                if (this.alert.driverId) {
                    let operator = this.$driverStore.getOperatorByID(this.alert.driverId);
                    return operator
                        ? operator.displayName
                        : null;
                } else {
                    return null;
                };
            },
            getAssetNameFromStore() {
                if (this.alert.vehicleId) {
                    let asset = this.$assetStore.getAssetByID(this.alert.vehicleId);
                    return asset
                        ? this.$options.filters.vehicleDisplayText(asset)
                        : null;
                } else {
                    return null;
                };
            },
            readFullAlert() {
                if (this.alertItem.id) {
                    RuleService.getAlertsByIds([this.alertItem.id]).then(result => {
                        let alert = result.find(i => i.id == this.alertItem.id);
                        if (alert) {
                            this.localAlert = alert;
                        };
                    }).catch(err => {
                        this.$toast.error(this.$t('FleetLabels.failedToReadAlert'));
                    });
                };
            },
            //#endregion
            //#region Actions
            itemSelected() {
                if (this.selectable) {
                    this.$emit('showInfo', this.alert);
                };
            },
            showRulePolicy() {
                this.$dialogEventHub.$emit('openCreateAlertPolicyDialog', { id: this.promptReference });
                this.$drawerEventHub.$emit("closeMessageDrawer");
                this.$emit('close');
            },
            showReplay() {
                let replayDate = this.alert.alertGenerateDateTime;
                if (isNaN(Date.parse(this.alert.alertGenerateDateTime))) {
                    replayDate = parse(this.alert.alertGenerateDateTime, this.$getUserDateTimeFormat(), new Date())
                };
               
                const data = {
                    name: 'replaydetails',
                    params:
                    {
                        date: [format(new Date(replayDate), "yyyy-MM-dd"), format(new Date(replayDate), "yyyy-MM-dd")],
                        selected: {
                            vehicles: [this.alert.vehicleId]
                        },
                    }
                };
                this.$router.push(data);
                this.$drawerEventHub.$emit("closeMessageDrawer");
                this.$emit('close');
            },
            //#endregion
            //#region Prompt
            openReplayPrompt() {
                this.promptReference = 'REPLAY';
                this.$refs.prompt.openDialog(this.alert.rulePolicyId, [this.alert.alertType, this.assetName].join("\n "));
            },
            openPrompt(policyId,policyName) {
                this.promptReference = policyId;
                this.$refs.prompt.openDialog(policyId,policyName);
            },
            promptConfirm() {
                if (this.promptReference == 'REPLAY') {
                    this.showReplay();
                } else {
                    this.showRulePolicy();
                };
            },
            //#endregion
            //#region Suppression
            suppressionTooltip(key) {
                let tooltip = this.suppressionTooltips.find(i => i.key == key);
                return tooltip
                    ? tooltip
                    : {
                        key: key,
                        title: this.$t('FleetLabels.notificationSuppressed'),
                        icon: "mdi-comment-remove-outline",
                    };
            },
            //#endregion
        },
        computed: {
            alert() {
                return ({
                    ...this.alertItem,
                    ...this.localAlert
                });
            },
            //#region Display Fields
            assetName() {
                return this.alert.displayName
                    || this.alert.vehicleName
                    || this.getAssetNameFromStore();
            },
            operatorName() {
                return this.alert.driverDisplayName
                    || this.alert.driverName
                    || this.getOperatorNameFromStore();
            },
            dateDisplay() {
                return isNaN(Date.parse(this.alert.alertGenerateDateTime))
                    ? this.alert.alertGenerateDateTime
                    : this.$formatUTCDateTime(this.alert.alertGenerateDateTime);
            },
            beautifiedDetails() {
                let atText = this.$t("LiveMapLabels.at");
                return this.alert.details
                    .split(". (").join(".\n(")
                    .split([")", atText].join(" ")).join([")\n", atText].join(""))
            },
            //#endregion
            //#region Prompt
            promptTitle() {
                return this.promptReplay
                    ? this.$t('LiveMapLabels.replay')
                    : this.$t('FleetLabels.openPolicy.title')
            },
            promptBody() {
                return this.promptReplay
                    ? this.$t('FleetLabels.showReplay.body')
                    : this.$t('FleetLabels.openPolicy.body')
            },
            //#endregion
            //#region Suppression
            supressedNotificationMethods() {
                return this.alert && this.alert.isAlertSuppressed
                    ? [...new Set(this.alert.suppressingPolicies.flatMap(i => i.suppressedRuleActions))].map(suppressedRuleAction => ({
                        key: suppressedRuleAction,
                        policies: this.alert.suppressingPolicies.filter(policy => policy.suppressedRuleActions.includes(suppressedRuleAction)),
                        ...this.suppressionTooltip(suppressedRuleAction)
                    }))
                    : []
            },
            //#endregion
        },
        watch: {
            alertItem:
            {
                immediate: true,
                handler(newValue, oldValue) {
                    if (newValue != oldValue) {
                        this.localAlert = {};
                    };
                    //Check if we have sufficient information
                    let requiredFields = ["alertType", "priority", "statusText"];
                    if (Object.keys(newValue).filter(key => requiredFields.includes(key)).length != requiredFields.length) {
                        this.readFullAlert();
                    };
                }
            },
        },
    }
</script>
<style scoped>
    .priorityLOW {
        border-left: solid 8px #40c1ac;
    }

    .priorityMEDIUM {
        border-left: solid 8px darkorange;
    }

    .priorityHIGH {
        border-left: solid 8px red;
    }

    ::v-deep .v-chip__content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;
        display: flow;
        margin-top: 4px;
    }

    .messageBlock {
        color: rgb(0, 43, 73) !important;
        border-top-right-radius: 16px;
        margin: 16px 4px;
    }

    .policyBlock {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .chipLink {
        color: #002B49;
        cursor: pointer;
        text-decoration: underline;
        text-decoration-color: #002B49;
        text-shadow: 0 0 1px #40C1AC;
    }

    .splitContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .v-list-item__content {
        align-self: self-start;
    }
</style>