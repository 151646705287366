<template>
    <div>
        <v-dialog v-model="showDialog" width="400">
            <v-card>
                <v-card-text>
                    <div class="inseego-timepicker-container">
                        <div class="md-datepicker-body-header" v-if="isDateSelectable">
                            <md-button class="md-icon-button md-dense" @click="previousMonth">
                                <md-icon>chevron_left</md-icon>
                            </md-button>
                            <md-button class="md-icon-button md-dense" @click="nextMonth">
                                <md-icon>chevron_right</md-icon>
                            </md-button>
                        </div>
                        <div class="md-datepicker-body-content inseego-timepicker-body" v-if="isDateSelectable">
                            <div class="md-datepicker-panel md-datepicker-calendar">
                                <div class="md-datepicker-panel md-datepicker-month">
                                    <md-button class="md-dense">{{months[date.month]}} {{date.year}}</md-button>
                                    <div class="inseego-datepicker-week md-datepicker-week">
                                        <span>S</span><span>M</span><span>T</span><span>W</span><span>T</span><span>F</span><span>S</span>
                                    </div>
                                    <div class="md-datepicker-days">
                                        <div v-for="(day, index) in days" class="md-datepicker-day" :key="index" :class="{ 'inseego-datepicker-outside-bounds': !day.current, 'inseego-datepicker-range' : isInRange(day.date) }">
                                            <md-button class="md-datepicker-day-button" @click="selectDate(day.date)" :disabled="!isBetweenConstraints(day.date)" :class="{ 'md-datepicker-today': isToday(day.date), 'md-datepicker-selected': isSelected(day.date) }">{{day.date.getDate()}}</md-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="md-layout md-gutter inseego-timepicker-gutter" v-if="isTimeSelectable">
                            <div class="md-layout-item inseego-timepicker">


                                <kendo-dropdownlist name="hours"
                                                    :data-source="hoursKeyValue"
                                                    :data-text-field="'value'"
                                                    :data-value-field="'key'"
                                                    id="start-hours"
                                                    v-model="start.time.hour"></kendo-dropdownlist>
                                <!-- <md-field>
                <md-select v-model="start.time.hour" name="hours">
                    <div class="inseegoTimeScroll">
                    <md-option v-for="hour in hours" :key="hour" :value="hour">{{leadingZero(hour)}}</md-option>
                </div>
                </md-select>
            </md-field> -->
                            </div>
                            <div class="inseego-timepicker-separator">:</div>
                            <div class="md-layout-item inseego-timepicker">
                                <kendo-dropdownlist :data-source="minutesKeyValue"
                                                    :data-text-field="'value'"
                                                    :data-value-field="'key'"
                                                    id="start-minutes"
                                                    v-model="start.time.minute"></kendo-dropdownlist>
                                <!-- 	<md-field>
                <md-select v-model="start.time.minute" name="minutes">
                        <div class="inseegoTimeScroll" >
                    <md-option v-for="minute in minutes" :key="minute" :value="minute">{{leadingZero(minute)}}</md-option>
                        </div>
                </md-select>
            </md-field> -->
                            </div>
                            <div class="md-layout-item" v-if="is12HourClock">
                                <md-switch v-model="start.time.isAm" class="md-primary">
                                    <span v-if="start.time.isAm">
                                        AM
                                    </span>
                                    <span v-else>
                                        PM
                                    </span>
                                </md-switch>
                            </div>
                        </div>
                        <div class="md-layout md-gutter inseego-timepicker-gutter" v-if="isTimeSelectable && isRange">
                            <div class="md-layout-item inseego-timepicker">
                                <kendo-dropdownlist name="hours"
                                                    id="end-hours"
                                                    v-model="end.time.hour"
                                                    :data-source="hoursKeyValue"
                                                    :data-text-field="'value'"
                                                    :data-value-field="'key'"></kendo-dropdownlist>
                                <!-- <md-field>
                <md-select v-model="end.time.hour" name="hours">
                    <div class="inseegoTimeScroll">
                        <md-option v-for="hour in hours" :key="hour" :value="hour">{{leadingZero(hour)}}</md-option>
                    </div>
                </md-select>
            </md-field> -->
                            </div>
                            <div class="inseego-timepicker-separator">:</div>
                            <div class="md-layout-item inseego-timepicker">
                                <kendo-dropdownlist id="end-minutes"
                                                    v-model="end.time.minute"
                                                    :data-source="minutesKeyValue"
                                                    :data-text-field="'value'"
                                                    :data-value-field="'key'"></kendo-dropdownlist>
                                <!-- <md-field>
                <md-select v-model="end.time.minute" name="minutes">
                    <div class="inseegoTimeScroll" >
                        <md-option v-for="minute in minutes" :key="minute" :value="minute">{{leadingZero(minute)}}</md-option>
                    </div>
                </md-select>
            </md-field> -->
                            </div>
                            <div class="md-layout-item" v-if="is12HourClock">
                                <md-switch v-model="end.time.isAm" class="md-primary">
                                    <span v-if="end.time.isAm">
                                        AM
                                    </span>
                                    <span v-else>
                                        PM
                                    </span>
                                </md-switch>
                            </div>
                        </div>
                        <md-dialog-actions>
                            <md-button class="md-primary" @click="showDialog = false">{{$tc("ReportGeneration.cancel")}}</md-button>
                            <md-button class="md-primary" v-if="isRange" @click="dateChange(startDateRange, endDateRange); showDialog = false; range = formattedDate">{{$tc("ReportGeneration.ok")}}</md-button>
                            <md-button class="md-primary" v-if="!isRange" @click="dateChange(startDateRange); showDialog = false; range = formattedDate">{{$tc("ReportGeneration.ok")}}</md-button>
                        </md-dialog-actions>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <div class="inseego-datepicker-range-container">
            <md-button @click="showDialog = true">
                <md-icon>date_range</md-icon>
                {{range}}
            </md-button>
        </div>
    </div>
</template>

<script>
    import { setTimeout } from 'timers';
    import ClockStrategy12Hour from './DateTimeStrategies/ClockStrategy12Hour.js';
    import ClockStrategy24Hour from './DateTimeStrategies/ClockStrategy24Hour.js';
    import RangeSelectionStrategy from './DateTimeStrategies/RangeSelectionStrategy.js';
    import SingleSelectionStrategy from './DateTimeStrategies/SingleSelectionStrategy.js';
    import moment from "moment-timezone";
    import "@progress/kendo-ui";
    import { DropDownList } from "@progress/kendo-dropdowns-vue-wrapper";

    export default {
        name: "InseegoDateTimePicker",
        data() {
            return {
                hours: [],
                minutes: [],

                hoursKeyValue: [],
                minutesKeyValue: [],

                days: [],
                showDialog: false,
                date:
                {
                    year: 0,
                    month: 0,
                    day: 0
                },
                months: this.$moment.months(),
                startsSunday: true,
                range: '',
                start: new InseegoDate(this),
                end: new InseegoDate(this),
                clockStrategy: null,
                selectionStrategy: null
            };
        },
        props: {
            value: [Object, Date],
            maxDate: {
                type: Date,
                default: () => { return new Date(); }
            },
            minDate: {
                type: Date,
                default: () => { return new Date(2018, 0, 1); }
            },
            maxRange: {
                type: Number,
                default: 0
            },
            format: {
                type: String,
                default: 'YYYY/MM/DD hh:mm A'
            },
            is12HourClock: {
                type: Boolean,
                default: true
            },
            isRange: {
                type: Boolean,
                default: false
            },
            isDateSelectable: {
                type: Boolean,
                default: true
            },
            isTimeSelectable: {
                type: Boolean,
                default: true
            },
            change: {
                type: Function
            },
            changedDate: { type: Date, required: false },
        },
        computed: {
            title() {
                var titleFormat = this.getTitleFormat();
                return this.selectionStrategy.getTitle(titleFormat);
            },
            formattedDate() {
                return this.selectionStrategy.getFormattedDate(this.format);
            },
            startDateRange() {
                return this.start.toNativeDate();
            },
            endDateRange() {
                return this.end.toNativeDate();
            }
        },
        methods: {
            dateChange(startDate, endDate) {
                this.selectionStrategy.dateChange(startDate, endDate);
            },
            getDays(date) {
                var self = this;
                var start = new Date(date.year, date.month, 1);
                var end = new Date(date.year, date.month + 1, 0);
                var adjust = (date) => {
                    var day = date.getDay();
                    if (self.startsSunday) {
                        return day;
                    }
                    day || (day = 7);
                    return --day;
                };
                var first = new Date(start).setDate(start.getDate() - adjust(start));
                var last = new Date(end).setDate(end.getDate() + 6 - adjust(end));
                var days = [];
                var iterator = new Date(first);
                while (iterator <= last) {
                    var day = {
                        date: new Date(iterator),
                        current: !(iterator < start || iterator > end)
                    };
                    days.push(day);
                    iterator.setDate(iterator.getDate() + 1);
                }
                return days;
            },
            isToday(date) {
                var today = this.$moment().format('YYYY-MM-DD');
                date = this.$moment(date).add('+1', 'day').format('YYYY-MM-DD');
                return this.$moment(today).isSame(this.$moment(date), 'd');
            },
            nextMonth() {
                this.date.month++;
                if (this.date.month == 12) {
                    this.date.year++;
                    this.date.month = 0;
                }
                this.days = this.getDays(this.date);
            },
            leadingZero(str) {
                str = str + '';
                return str.length < 2 ? "0" + str : str;
            },
            previousMonth() {
                this.date.month--;
                if (this.date.month == -1) {
                    this.date.year--;
                    this.date.month = 11;
                }
                this.days = this.getDays(this.date);
            },
            populateTimePicker() {
                this.clockStrategy.populateHours();
                for (let minute = 0; minute < 60; minute++) {
                    this.minutes.push(minute);
                    this.minutesKeyValue.push({
                        key: minute,
                        value: this.leadingZero(minute)
                    });
                }

                this.hours.forEach(hours => {
                    this.hoursKeyValue.push({
                        key: hours,
                        value: this.leadingZero(hours)
                    });
                });
            },
            setDate(date, value, useTime) {
                value.date.year = date.getFullYear();
                value.date.month = date.getMonth();
                value.date.day = date.getDate();
                if (useTime) {
                    this.setTime(date, value.time);
                }
            },
            setTime(date, value) {
                this.clockStrategy.setTime(date, value);
            },
            isSelected(date) {
                return this.selectionStrategy.isSelected(date);
            },
            isInRange(date) {
                return this.selectionStrategy.isInRange(date);
            },
            isBetweenConstraints(date) {
                return date >= this.minDate && date <= this.maxDate;
            },
            selectDate(date) {
                this.selectionStrategy.selectDate(date);
            },
            getTitleFormat() {
                var titleFormat = '';
                if (this.isDateSelectable) {
                    titleFormat += this.clockStrategy.titleDateFormat;
                }
                if (this.isTimeSelectable) {
                    if (this.isDateSelectable) {
                        titleFormat += ', ';
                    }
                    titleFormat += this.clockStrategy.titleTimeFormat;
                }
                return titleFormat;
            }
        },
        created() {
            this.clockStrategy = (this.is12HourClock) ? new ClockStrategy12Hour(this) : new ClockStrategy24Hour(this);
            this.selectionStrategy = (this.isRange) ? new RangeSelectionStrategy(this) : new SingleSelectionStrategy(this);
            this.populateTimePicker();
            this.days = this.getDays(this.date);
            this.range = this.formattedDate;
        },
        watch: {
            changedDate: function (data) {
                this.range = moment(data, "x").format("DD-MM-YYYY");
            }
        }

    };

    function InseegoDate(component) {

        this.date = {
            year: 0,
            month: 0,
            day: 0
        };

        this.time = {
            hour: 0,
            minute: 0,
            isAm: true
        };

        this.selected = true;

        this.isDay = function (date) {
            return date.getFullYear() === this.date.year && date.getMonth() === this.date.month && date.getDate() === this.date.day;
        };

        this.toDate = function (useTime) {
            var date = component.$moment().
                year(this.date.year).
                month(this.date.month).
                date(this.date.day).
                hour(0).
                minute(0).
                second(0);
            if (useTime) {
                var hour = component.clockStrategy.getHour(this.time);
                date.hour(hour).
                    minute(this.time.minute);
            }
            return date;
        };

        this.toNativeDate = function () {
            return component.$moment(this.toDate(true)).toDate();
        }
    }

</script>
<style scoped>
</style>
<style>

    .inseego-datepicker-title {
        font-size: 0.8em !important;
    }

    .inseego-datepicker-week {
        margin-top: 8px;
    }

    .inseego-timepicker .md-field {
        width: 120px;
    }

    .inseego-timepicker .k-dropdown {
        width: 120px;
        margin-top: 17px;
    }

    .md-datepicker-body {
        width: 330px;
    }

    .inseego-timepicker-container .md-datepicker-header .md-datepicker-dayname {
        display: inline;
    }

    .inseego-timepicker-container .md-datepicker-header strong {
        color: white;
    }

    .inseego-timepicker-container .md-datepicker-day {
        padding-left: 7px;
    }

    .inseego-timepicker-body {
        height: 350px;
    }

    .inseego-timepicker-gutter {
        padding-left: 20px;
        padding-right: 20px;
    }

    .inseego-timepicker-separator {
        width: 10px;
        padding-top: 30px;
    }

    .inseego-datepicker-outside-bounds {
        background-color: antiquewhite;
    }

    .inseego-datepicker-range {
        background-color: #448aff !important;
    }

    .inseego-datepicker .md-menu-content.md-select-menu {
        z-index: 100
    }

    .inseego-datepicker-range-container {
        display: inline-flex;
        margin: 3px;
    }

    .inseego-datepicker-range-filter {
        padding-left: 0px;
        padding-top: 4px;
    }

    .inseegoTimeScroll {
        height: 350px;
        overflow: auto;
    }
</style>