<template>
    <v-sheet light class="chipGroup" :style="{ 'max-width': maxWidth }">
        <v-chip-group :class="[dark ? 'darkButtons' : '']">
            <v-chip v-for="chip in filterChips"
                    :key="chip.title"
                    class="filterChip"
                    close
                    @click:close="removeFilterEntry(chip)">
                {{chip.title}} : 
                <div v-if="chip.value" class="chipValue">{{chip.value}}</div>
            </v-chip>
        </v-chip-group>
    </v-sheet>
</template>
<script>
    export default {
        name: "QuickFilterChipList",
        props: {
            columns: Array,
            filterChange: Function,
            appliedFilters: {
                type: Object,
                default() {
                    return {
                        filters: [],
                    };
                },
            },
            dark: {
                type: Boolean,
                default() {
                    return false;
                },
            },
            maxWidth: {
                type: String,
                default() {
                    return '55%';
                },
            }
        },
        computed: {
            filterChips() {
                if (this.appliedFilters?.filters?.length > 0) {
                    if (this.appliedFilters?.title == 'CUSTOM') {
                        //Custom Filter
                        return this.appliedFilters.filters.map(filter => ({
                            title: this.columns.find(i => i.field == filter.field)?.title || filter.field,
                            value: filter.value || '',
                            fields: [filter.field],
                        }));
                    } else {
                        //Quick Filter
                        switch (this.appliedFilters.type) {
                            case 'FILTER':
                                return [{
                                    title: this.$t(this.appliedFilters.title),
                                    value: '',
                                    fields: this.appliedFilters.filters.filter(i => i.field).map(i => i.field),
                                }];
                                break;
                            case 'String':
                            case 'CSV':
                                return [{
                                    title: this.$t(this.appliedFilters.title),
                                    value: this.appliedFilters.filters.filter(i => i.value).length > 0
                                        ? this.appliedFilters.filters.map(i => i.value).join(', ')
                                        : this.$t('quickFilter.none').toUpperCase(),
                                    fields: this.appliedFilters.filters.filter(i => i.field).map(i => i.field),
                                }];
                                break;
                            case 'Date':
                            case 'SubGroup':
                            case 'Custom':
                                return [{
                                    title: this.$t(this.appliedFilters.title),
                                    value: this.appliedFilters.filters.filter(i => i.value).length > 0
                                        ? this.appliedFilters.filters.map(i => i.value).join(' > ')
                                        : this.$t('quickFilter.none').toUpperCase(),
                                    fields: this.appliedFilters.filters.filter(i => i.field).map(i => i.field),
                                }];
                                break;
                            default:
                                return this.appliedFilters.filters.map(filter => ({
                                    title: this.columns.find(i => i.field == filter.field)?.title || filter.field,
                                    value: filter.value || '',
                                    fields: this.appliedFilters.filters.filter(i => i.field).map(i => i.field),
                                }));
                                break;
                        };
                    };
                } else {
                    return [];
                };

            },
        },
        methods: {
            removeFilterEntry(entry) {
                if (this.appliedFilters.filters.length < 2) {
                    this.clearFilters();
                } else {
                    this.filterChange({
                        filter: {
                            logic: 'and',
                            filters: this.appliedFilters.filters.filter(v => !entry.fields.includes(v.field)),
                        },
                    });
                };
            },
            clearFilters() {
                this.filterChange({
                    filter: {
                        logic: 'and',
                        filters: [],
                    },
                });
            },
        },
    }
</script>
<style scoped>
    .chipGroup {
        background-color: transparent;
    }
    .filterChip {
        background: #50C2AD !important;
        color: #102b49;
        margin-right: 8px;
    }
    .chipValue {
        padding: 2px 8px;
        border-radius: 2px;
        margin-left: 4px;
        margin-right: 2px;
        background-color: #A6E0D5;
    }
    ::v-deep .v-chip__close {
        font-size: x-large !important;
        color: #102b49;
    }
    .darkButtons {
    }
    ::v-deep .darkButtons > .v-slide-group__next > .mdi-chevron-right {
        color: #6d6d6d !important;
    }
    ::v-deep .darkButtons > .v-slide-group__prev > .mdi-chevron-left {
        color: #6d6d6d !important;
    }

    ::v-deep .mdi-chevron-right {
        color: white;
    }

    ::v-deep .mdi-chevron-left {
        color: white;
    }
</style>