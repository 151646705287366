<template>
    <v-card v-if="calndrType">
        <div class="" v-if="calndrType == 'showDay'">
            <VueDatePicker v-model="dayDate"
                           :max-date="dayMaxDate"
                           fullscreen-mobile
                           inline />
        </div>

        <div class="" v-if="calndrType == 'showWeek'">
            <VueDatePicker v-model="weekDate"
                           :max-date="weekMaxDate"
                           fullscreen-mobile
                           inline />
        </div>

        <div class="" v-if="calndrType == 'showMonth'">
            <VueDatePicker v-model="monthDate"
                           type="month"
                           :max-date="monthMaxDate"
                           fullscreen-mobile
                           inline />
        </div>

        <div class="" v-if="calndrType == 'showQuater'">
            <VueDatePicker v-model="quarterDate"
                           type="quarter"
                           :max-date="quarterMaxDate"
                           fullscreen-mobile
                           inline />
        </div>

        <div class="" v-if="calndrType == 'showYear'">
            <VueDatePicker v-model="yearDate"
                           type="year"
                           :max-date="yearMaxDate"
                           fullscreen-mobile
                           inline />
        </div>

        <div class="" v-if="calndrType == 'showCustomDate'">
            <VueDatePicker v-model="rangeDate"
                           :max-date="rangeMaxDate"
                           range
                           fullscreen-mobile
                           inline />
        </div>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" :disabled="calndrType == 'showCustomDate' ? rangeDate == null : false" @click="applyCalenderFilter">{{$t("Dashboard.apply")}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import { mutations, getters } from "../shared/observeDashboardFilter";
    import { VueDatePicker } from '@mathieustan/vue-datepicker';
    import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
    import { format, startOfDay, endOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfQuarter , endOfQuarter, startOfYear, endOfYear, parse } from "date-fns"
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const quarter = Math.floor(date.getMonth() / 3)

    export default {
        name: 'datepickermodal',
        components: { VueDatePicker },
        data: function () {
            return {
                showDay: false,
                showWeek: false,
                showMonth: false,
                showQuater: false,
                showYear: false,
                showCustomDate: false,
                dayTo: "",
                dayFrom: "",
                weekFrom: "",
                weekTo: "",
                monthFrom: "",
                monthTo: "",
                quarterFrom: "",
                quarterTo: "",
                yearFrom: "",
                yearTo: "",
                customFrom: "",
                customTo: "",
                selectedTo: "",
                selectedFrom: "",
                selectedPanel: "",
                allowedDays: "",
                allowedMonth: "",
                allowedQuarter: "",
                allowedWeeks: "",
                defaultLangCul: "en",
                dayDate: `${year}-${month}-${day - 1}`,
                dayMinDate: `${year - 3}-${month}-1`,
                dayMaxDate: `${year}-${month}-${day - 1}`,
                weekDate: `${year}-${month}-${day - 1}`,
                weekMinDate: `${year - 3}-${month}-1`,
                weekMaxDate: `${year}-${month}-${day - 1}`,
                monthDate: `${year}-${month - 1}`,
                monthMinDate: `${year - 3}-${month}`,
                monthMaxDate: `${year}-${month - 1}`,
                quarterDate: `${year}-${quarter}`,
                quarterMinDate: `${year - 3}-${quarter}`,
                quarterMaxDate: `${year}-${quarter}`,
                yearDate: `${year - 1}`,
                yearMinDate: `${year - 3}`,
                yearMaxDate: `${year - 1}`,
                rangeDate: null,
                rangeMinDate: `${year - 3}-${month}-1`,
                rangeMaxDate: `${year}-${month}-${day - 1}`,
            }
        },
        props: {
            calndrType: { type: String, required: true },
        },
        created() {
            let self = this;
            let DefaultLang = ['de', 'es', 'en', 'fr', 'ru'];
            self.defaultLangCul = DefaultLang.includes(localStorage.getItem('langCulture')) ? localStorage.getItem('langCulture') : "en";
            const today = new Date();
            const yesterdayDate = new Date(today);
            yesterdayDate.setDate(yesterdayDate.getDate() - 1);
            let yesterdayString = yesterdayDate.getFullYear() + '-' + (("0" + (yesterdayDate.getMonth() + 1)).slice(-2)) + '-' + (("0" + yesterdayDate.getDate()).slice(-2));

            // select default date
            let selectedCalndr = getters.getCalendarFilter();
            self.selectedPanel = selectedCalndr.type;
            self.selectedFrom = this.$moment.parseZone(selectedCalndr.from).format("YYYY-MM-DDT00:00:00.000");
            self.selectedTo = this.$moment.parseZone(selectedCalndr.to).format("YYYY-MM-DDT23:59:59.999");

            // set Day
            self.dayFrom = (self.selectedPanel == 'day') ? self.selectedFrom : yesterdayString + "T00:00:00.000";
            self.dayTo = (self.selectedPanel == 'day') ? self.selectedTo : yesterdayString + "T23:59:59.999";
            self.allowedDays = this.$moment().subtract(1, 'days').format("YYYY-MM-DDT23:59:59.999");
            // set Month
            self.allowedMonth = self.allowedDays;
            self.monthFrom = (self.selectedPanel == 'month') ? self.selectedFrom : this.$moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DDT00:00:00.000');
            self.monthTo = (self.selectedPanel == 'month') ? self.selectedTo : this.$moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DDT23:59:59.999');

            // set Quater
            self.allowedQuarter = self.allowedDays;
            if (self.selectedPanel == 'quarter') {
                self.quarterFrom = self.selectedFrom;
                self.quarterTo = self.selectedTo;
            } else {
                self.lastQuarterDates(today);
            }

            // set Week
            self.allowedWeeks = this.$moment().endOf('isoWeek').subtract(1, 'week').format("YYYY-MM-DDT23:59:59.999");
            self.weekFrom = (self.selectedPanel == 'week') ? self.selectedFrom : this.$moment().startOf('isoWeek').subtract(1, 'week').format("YYYY-MM-DDT00:00:00.000");
            self.weekTo = (self.selectedPanel == 'week') ? self.selectedTo : this.$moment().endOf('isoWeek').subtract(1, 'week').format("YYYY-MM-DDT23:59:59.999");
            // set Year
            self.yearFrom = (self.selectedPanel == 'year') ? self.selectedFrom : this.$moment().startOf('year').subtract(1, 'year').format("YYYY-MM-DDT00:00:00.000");
            self.yearTo = (self.selectedPanel == 'year') ? self.selectedTo : this.$moment().endOf('year').subtract(1, 'year').format("YYYY-MM-DDT23:59:59.999");

            // set Custom Range
            self.customFrom = (self.selectedPanel == 'range') ? self.selectedFrom : "";
            self.customTo = (self.selectedPanel == 'range') ? self.selectedTo : "";

        },
        methods: {
            applyCalenderFilter() {
                switch (this.calndrType) {
                    case 'showDay':
                        this.selectedPanel = 'day'
                        this.selectedFrom = format(startOfDay(parse(this.dayDate, "yyyy-MM-dd", new Date())), "yyyy-MM-dd'T'HH:mm:ss")
                        this.selectedTo = format(endOfDay(parse(this.dayDate, "yyyy-MM-dd", new Date())), "yyyy-MM-dd'T'HH:mm:ss")
                        break;
                    case 'showWeek':
                        this.selectedPanel = 'week'
                        this.selectedFrom = format(startOfWeek(parse(this.weekDate, "yyyy-MM-dd", new Date()), { weekStartsOn: 1 }), "yyyy-MM-dd'T'HH:mm:ss")
                        this.selectedTo = format(endOfWeek(parse(this.weekDate, "yyyy-MM-dd", new Date()), { weekStartsOn: 1 }), "yyyy-MM-dd'T'HH:mm:ss")
                        break;
                    case 'showMonth':
                        this.selectedPanel = 'month'
                        this.selectedFrom = format(startOfMonth(parse(this.monthDate, "yyyy-MM", new Date())), "yyyy-MM-dd'T'HH:mm:ss")
                        this.selectedTo = format(endOfMonth(parse(this.monthDate, "yyyy-MM", new Date())), "yyyy-MM-dd'T'HH:mm:ss")
                        break;
                    case 'showQuater':
                        this.selectedPanel = 'quarter'
                        this.selectedFrom = format(startOfQuarter(parse(this.quarterDate, "yyyy-QQ", new Date())), "yyyy-MM-dd'T'HH:mm:ss")
                        this.selectedTo = format(endOfQuarter(parse(this.quarterDate, "yyyy-QQ", new Date())), "yyyy-MM-dd'T'HH:mm:ss")
                        break;
                    case 'showYear':
                        this.selectedPanel = 'year'
                        this.selectedFrom = format(startOfYear(parse(this.yearDate, "yyyy", new Date())), "yyyy-MM-dd'T'HH:mm:ss")
                        this.selectedTo = format(endOfYear(parse(this.yearDate, "yyyy", new Date())), "yyyy-MM-dd'T'HH:mm:ss")
                        break;
                    case 'showCustomDate':
                        this.selectedPanel = 'range'
                        this.selectedFrom = format(startOfDay(parse(this.rangeDate.start, "yyyy-MM-dd", new Date())), "yyyy-MM-dd'T'HH:mm:ss")
                        this.selectedTo = format(endOfDay(parse(this.rangeDate.end, "yyyy-MM-dd", new Date())), "yyyy-MM-dd'T'HH:mm:ss")
                        break;
                }
                this.$emit("selectCalendarOption", this.selectedPanel);
                // apply filter
                mutations.setCalendarFilter(this.selectedTo, this.selectedFrom, this.selectedPanel);
                // this.$router.push({ name: 'driverDashboard' });
            },
            lastQuarterDates(today) {
                let self = this;
                let month = today.getMonth() + 1;
                let quarter = (Math.floor((month - 1) / 3)) + 1;
                let lastQuarter = (quarter > 1) ? quarter - 1 : lastQuarter = 4;
                let year;
                if (lastQuarter < 4) {
                    year = today.getFullYear();
                } else {
                    year = today.getFullYear() - 1;
                }

                let firstDate = year + '-' + ("0" + (((lastQuarter - 1) * 3) + 1)).slice(-2) + '-01';
                let lastDate = year + '-' + ("0" + (((lastQuarter - 1) * 3) + 3)).slice(-2) + ((lastQuarter == 2 || lastQuarter == 3) ? '-30' : '-31');

                self.quarterFrom = firstDate + 'T00:00:00.000';
                self.quarterTo = lastDate + 'T23:59:59.999';
            }
        }
    };

</script>
<style scoped>
    @import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap");

    * {
        font-family: "Roboto",sans-serif;
    }

    .ctrack-cal-btns {
        display: inline-block;
        width: 100%;
        padding: 15px 20px;
    }

    .md-btn-style1, .md-btn-style2 {
        font-size: 14px;
        color: #002B49;
        font-weight: 500;
        padding: 10px;
        text-align: center;
        min-width: 85px;
        outline: 0;
        background-color: #fff;
        border: 1px solid #002B49;
        cursor: pointer;
        border-radius: 5px;
        transition: all 0.5s ease-in-out;
    }

    .md-btn-style1 {
        border-color: #cce6f4;
        background-color: #cce6f4;
    }

        .md-btn-style1:hover {
            border-color: #002B49;
            background-color: #fff;
        }

    .md-btn-style2:hover {
        color: #fff;
        background-color: #002B49;
    }

    .ctrack-text-right {
        text-align: right;
    }

    .ctrack-text-left {
        text-align: left;
    }
    .vd-wrapper--inline {
        width: auto;
        box-shadow: none;
    }
    ::v-deep .vd-picker-header {
        background-color: #002b49 !important;
    }
    ::v-deep .vd-picker__table-day__effect {
        color: #40C1AC !important;
    }
    ::v-deep .vd-picker__years-button.active {
        background-color: #40C1AC !important;
        color: #002b49 !important;
    }
</style>
<style>
    .vd-picker {
        width: 355px !important;
    }

    .md-set-max-height {
        display: inline-block;
        width: 100%;
        max-height: 330px;
        overflow: auto;
    }

    .md-header-datepicker-open .mj-daterange-picker {
        min-width: 100%
    }

    .md-header-datepicker-open .mj-calendar .calendar-header svg {
        display: block;
    }

    .md-header-datepicker-open .mj-calendar .calendar-header {
        padding: 10px;
        background: #ececec;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
    }

        .md-header-datepicker-open .mj-calendar .calendar-header .calendar-month-name {
            font-size: 18px;
        }

    .md-header-datepicker-open .mj-daterange-picker .mj-daterange-picker-controls .mj-daterange-picker-reset {
        display: none;
    }

    .md-header-datepicker-open .mj-daterange-picker .mj-daterange-picker-controls .mj-daterange-picker-submit {
        font-size: 14px;
        color: #002B49;
        font-weight: 500;
        padding: 10px;
        text-align: center;
        min-width: 85px;
        outline: 0;
        background-color: #fff;
        border: 1px solid #002B49;
        cursor: pointer;
        border-radius: 5px;
        transition: all 0.5s ease-in-out;
        font-family: "Roboto",sans-serif;
    }

        .md-header-datepicker-open .mj-daterange-picker .mj-daterange-picker-controls .mj-daterange-picker-submit:hover {
            color: #fff;
            background-color: #002B49;
        }

    .md-header-datepicker-open .mj-daterange-picker .mj-daterange-picker-controls {
        flex-direction: row-reverse;
    }

        .md-header-datepicker-open .mj-daterange-picker .mj-daterange-picker-controls .mj-daterange-picker-submit.is-disabled,
        .md-header-datepicker-open .mj-daterange-picker .mj-daterange-picker-controls .mj-daterange-picker-submit.is-disabled:hover {
            background-color: #d7d7d7;
            border-color: #d7d7d7;
            color: #4e4d4d !important;
            cursor: no-drop;
        }

    @media only screen and (max-width: 767px) {
        .md-header-datepicker-open .mj-calendar .calendar-quarters .quarter .months {
            padding: 2px 10px;
            font-size: 11px;
        }
    }

</style>
