<template>
  <div class="timeline-picker">
    <div class="timeline">
      <div ref="slider" class="slider" :class="{ 'global-slider' : global }">
        <div class="slider-touch-left">
          <span></span>
        </div>
        <div class="slider-touch-right">
          <span></span>
        </div>
        <div class="slider-line">
          <span></span>
        </div>
      </div>
      <div class="timeline-actions">
        <button class="fa fa-filter" @click="filterRange()"></button>
        &nbsp;
        <button class="fa fa-refresh" @click="resetRange()"></button>
      </div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "InseegoTimeBarRangePicker",
  data() {
    return {
      slider: null,
      sliderStartDate: null,
      sliderEndDate: null,
      rangeStartDate: null,
      rangeEndDate: null
    };
  },
  props: {
    value: Object,
    dataitem: Object,
    range: Object,
    changed: Function,
    change: Function,
    filter: Function,
    reset: Function,
    global: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    range: {
      handler: function() {
        this.sliderStartDate = this.range.startDate;
        this.sliderEndDate = this.range.endDate;
        this.rangeStartDate = this.range.startDate;
        this.rangeEndDate = this.range.endDate;
        if (this.slider !== null) {
          this.setup();
        }
      },
      deep: true
    }
  },
  methods: {
    createSlider() {
      if (this.slider !== null) {
        return;
      }
      this.slider = new RangeSlider(this);
      this.setup();
      this.slider.onChange = this.change;
      this.slider.onChanged = this.changed;
    },
    setup() {
      var maxRange = Math.floor(this.sliderEndDate - this.sliderStartDate) + 1;
      this.slider.reset(0, maxRange);
      this.slider.setSliderRange(this.rangeStartDate, this.rangeEndDate);
    },
    updateRange(range) {
      this.$emit("input", range);
      this.rangeStartDate = range.startDate;
      this.rangeEndDate = range.endDate;
      if (this.range === this.value) {
        this.sliderStartDate = range.startDate;
        this.sliderEndDate = range.endDate;
      }
      this.setup();
    },
    resetRange() {
      var range = {
        startDate: this.$moment(this.rangeStartDate).tz(this.$getUserCurrentTimeZone()).startOf('day').toDate(),
        endDate: this.$moment(this.rangeEndDate).tz(this.$getUserCurrentTimeZone()).endOf('day').toDate()
      };
      this.updateRange(range);
      if (this.reset) {
        this.reset();
      }
    },
    filterRange() {
      var range = this.slider.getRange();
      this.updateRange(range);
      if (this.filter) {
        this.filter(this.dataitem, range);
      }
    },
    setRanges() {
      this.sliderStartDate = this.range.startDate;
      this.sliderEndDate = this.range.endDate;
      this.rangeStartDate = this.value.startDate;
      this.rangeEndDate = this.value.endDate;
    }
  },
  created() {
    this.setRanges();
  },
  mounted() {
    this.createSlider();
  },
  destroyed() {
    this.slider.destroyed();
  }
};

function RangeSlider(component) {
  var startDate;
  var endDate;
  var self = this;
  var startX = 0,
    x = 0;
  var slider = component.$refs.slider;
  var actions = slider.parentElement.querySelector(".timeline-actions");
  var touchLeft = slider.querySelector(".slider-touch-left");
  var touchRight = slider.querySelector(".slider-touch-right");
  var lineSpan = slider.querySelector(".slider-line span");
  var sliderLine = slider.querySelector(".slider-line");
  var selectedTouch = null;
  var min = 0;
  var max = 0;
  var maxX = 0;

  self.slider = slider;

  self.getRange = function() {
    return { startDate: startDate, endDate: endDate };
  };

  self.reset = function(minValue, maxValue) {
    min = minValue;
    max = maxValue;
    startX = 0;
    x = 0;
    touchLeft.style.left = "0px";
    touchRight.style.left = slider.offsetWidth - touchLeft.offsetWidth + "px";
    lineSpan.style.marginLeft = "0px";
    lineSpan.style.width = slider.offsetWidth + "px";
    maxX = slider.offsetWidth - touchRight.offsetWidth;
    self.setMinValue(min);
    self.setMaxValue(max);
  };

  self.setMinValue = function(minValue) {
    var sliderRatio = max / slider.clientWidth;
    var ratio =
      (minValue / sliderRatio - min / sliderRatio) /
      (max / sliderRatio - min / sliderRatio);
    touchLeft.style.left = Math.ceil(ratio * slider.offsetWidth) + "px";
    lineSpan.style.marginLeft = touchLeft.offsetLeft + "px";
    lineSpan.style.width =
      touchRight.offsetLeft - touchLeft.offsetLeft + 15 + "px";
    actions.style.left = touchLeft.style.left;
    startDate = component
      .$moment(component.sliderStartDate)
      .add(minValue, "ms")
      .toDate();
  };

  self.setMaxValue = function(maxValue) {
    var sliderRatio = max / slider.clientWidth;
    var ratio =
      (maxValue / sliderRatio - min / sliderRatio) /
      (max / sliderRatio - min / sliderRatio);
    touchRight.style.left = Math.ceil(ratio * slider.offsetWidth) - 15 + "px";
    lineSpan.style.marginLeft = touchLeft.offsetLeft + "px";
    lineSpan.style.width =
      touchRight.offsetLeft - touchLeft.offsetLeft + 15 + "px";
    actions.style.left = touchLeft.style.left;
    endDate = component
      .$moment(component.sliderStartDate)
      .add(maxValue, "ms")
      .toDate();
  };

  self.setSliderRange = function(startDate, endDate) {
    var maxValue = Math.floor(endDate - component.sliderStartDate);
    var minValue = Math.floor(startDate - component.sliderStartDate);
    self.setMinValue(minValue);
    self.setMaxValue(maxValue);
  };

  self.destroyed = function() {
    detachEvents();
  };

  function resize() {
    self.setSliderRange(startDate, endDate);
  }

  function onStart(event) {
    event.preventDefault();
    var eventTouch = event;
    if (event.touches) {
      eventTouch = event.touches[0];
    }
    if (this === touchLeft) {
      x = touchLeft.offsetLeft;
    } else {
      x = touchRight.offsetLeft;
    }
    startX = eventTouch.pageX - x;
    selectedTouch = this;
    document.addEventListener("mousemove", onMove);
    document.addEventListener("mouseup", onStop);
    document.addEventListener("touchmove", onMove);
    document.addEventListener("touchend", onStop);
  }

  function onMove(event) {
    var eventTouch = event;
    if (event.touches) {
      eventTouch = event.touches[0];
    }
    x = eventTouch.pageX - startX;
    if (selectedTouch === touchLeft) {
      if (x > touchRight.offsetLeft - selectedTouch.offsetWidth + 10) {
        x = touchRight.offsetLeft - selectedTouch.offsetWidth + 10;
      } else if (x < 0) {
        x = 0;
      }
      selectedTouch.style.left = x + "px";
    } else if (selectedTouch === touchRight) {
      if (x < touchLeft.offsetLeft + touchLeft.offsetWidth - 10) {
        x = touchLeft.offsetLeft + touchLeft.offsetWidth - 10;
      } else if (x > maxX) {
        x = maxX;
      }
      selectedTouch.style.left = x + "px";
    }
    lineSpan.style.marginLeft = touchLeft.offsetLeft + "px";
    lineSpan.style.width =
      touchRight.offsetLeft - touchLeft.offsetLeft + 15 + "px";
    actions.style.left = touchLeft.style.left;
    var range = calculateValue();
    if (self.onChange) {
      self.onChange.call(component, range.startDate, range.endDate);
    }
    sliderLine.classList.add("slider-move");
  }

  function onStop() {
    selectedTouch = null;
    document.removeEventListener("mousemove", onMove);
    document.removeEventListener("mouseup", onStop);
    document.removeEventListener("touchmove", onMove);
    document.removeEventListener("touchend", onStop);
    var range = calculateValue();
    if (self.onChanged) {
      self.onChanged.call(component, range.startDate, range.endDate);
    }
    sliderLine.classList.remove("slider-move");
  }

  function calculateValue() {
    var sliderRatio = max / slider.clientWidth;
    var minValue = parseInt(touchLeft.style.left.replace("px", ""));
    var maxValue = parseInt(touchRight.style.left.replace("px", ""));
    var startRange = minValue * sliderRatio - min;
    var endRange = maxValue * sliderRatio + 15 * sliderRatio - 1;
    startDate = component
      .$moment(component.sliderStartDate)
      .add(startRange, "ms")
      .toDate();
    endDate = component
      .$moment(component.sliderStartDate)
      .add(endRange, "ms")
      .toDate();
    return { startDate: startDate, endDate: endDate };
  }

  function attachEvents() {
    window.addEventListener("resize", resize, false);
    touchLeft.addEventListener("mousedown", onStart);
    touchRight.addEventListener("mousedown", onStart);
    touchLeft.addEventListener("touchstart", onStart, { passive: true });
    touchRight.addEventListener("touchstart", onStart, { passive: true });
  }

  function detachEvents() {
    window.removeEventListener("resize", resize, false);
    touchLeft.removeEventListener("mousedown", onStart);
    touchRight.removeEventListener("mousedown", onStart);
    touchLeft.removeEventListener("touchstart", onStart, { passive: true });
    touchRight.removeEventListener("touchstart", onStart, { passive: true });
  }

  attachEvents();
}
</script>
<style scoped>
.slider {
  display: block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  z-index: 1000;
  width: 100%;
  display: none;
}
.slider .slider-touch-left,
.slider .slider-touch-right {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  position: absolute;
  height: 15px;
  width: 15px;
  top: 9px;
  z-index: 2;
}
.slider .slider-touch-left span,
.slider .slider-touch-right span {
  display: block;
  width: 100%;
  height: 100%;
  background: #f0f0f0;
  border: 1px solid #a4a4a4;
}

.slider .slider-touch-left span {
  border-bottom-right-radius: 50%;
  box-shadow: 1px 1px #888888;
}

.slider .slider-touch-right span {
  border-bottom-left-radius: 50%;
  box-shadow: 1px 1px #888888;
}

.global-slider .slider-move {
  height: 1000px !important;
}

.global-slider .slider-touch-left,
.global-slider .slider-touch-right {
  top: 0px;
}

.slider .slider-line {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  height: 10px;
  border-radius: 4px;
  background: transparent;
  z-index: 0;
  color: #000;
}
.slider .slider-line span {
  display: block;
  height: 100%;
  background-color: lightblue;
  width: 0%;
  opacity: 0.8;
}

.timeline {
  position: relative;
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.timeline-actions {
  position: absolute;
  top: 40px;
  z-index: 10000;
  background-color: darkgrey;
  padding: 5px;
  display: none;
}

.timeline-actions button {
  border-radius: 3px;
  padding: 5px 10px;
  border: none;
  background: #288dc1 !important;
  color: #fff !important;
}

.timeline-picker {
  width: 100%;
  height: 100%;
}

.timeline-picker .timeline {
  visibility: hidden;
}

.timeline-picker:hover .timeline {
  visibility: visible;
}
</style>