import Vue from 'vue';
import { defineStore } from 'pinia';
import { useDriverStore } from '@/stores/driver-store';
import { useAssetStore } from '@/stores/asset-store';
import { i18n } from '../shared/i18n.js';
import assetManagementService from "@/service/assetmanagement.service";
import store from "@/store/index"
export const useAMMStore = defineStore('amm', {
    state: () => ({
        //#region templates
        templateConfigProperties: {},
        operatorTemplates: [],
        assetTemplates: [],
        //#endregion

        //#region Asset Types
        assetTypes: [],
        //#endregion

        //#region Transaction Categories
        transactionCategories: {
            asset: {
                income: [],
                expense:[],
            },
            operator: {
                income: [],
                expense: [],
            },
        },
        transactionTypes: {
            asset: {
                income: [],
                expense: [],
            },
            operator: {
                income: [],
                expense: [],
            },
        },
        recurrenceOptions: [
            { text: i18n.t('amm.recurrence.ONCE_OFF'), value: 'ONCE_OFF' },
            { text: i18n.t('amm.recurrence.DAILY'), value: 'DAILY' },
            { text: i18n.t('amm.recurrence.WEEKLY'), value: 'WEEKLY' },
            { text: i18n.t('amm.recurrence.MONTHLY'), value: 'MONTHLY' },
            { text: i18n.t('amm.recurrence.ANNUALLY'), value: 'ANNUALLY' },
        ],
        //#endregion

        //#region Transactions
        transactionCacheRanges:[],
        transactions: [],
        profileImages:[],
        //#endregion

        //#region Document Types
        documentTypes: [],
        //#endregion

        //#region Measurement Units
        measurementUnits: [],
        //#endregion

        tenantId: "",
    }),
    //$AMMStore
    actions: {
        getTemplateConfigProps() {
            return this.isValid ? this.templateConfigProperties : null;
        },
        setTemplateConfigProps(value) {
            this.templateConfigProperties = JSON.parse(JSON.stringify(value));
            this.updated();
        },

        //#region Tenant Validation
        updated() {
            this.tenantId = (Vue.prototype.$session.get('tenantDetails')?.tenantId || '');
        },
        isValid() {
            return this.tenantId == (Vue.prototype.$session.get('tenantDetails')?.tenantId || '');
        },
        //#endregion

        //#region Asset Types
      
        /**
         * Read asset Types from DB and sort
         */
        async getAssetTypes() {
            await assetManagementService.getAllAssetTypes().then(result => {
                this.assetTypes = result;
                this.sortAssetTypes();
            });
        },

        /**
         * Return Asset Types if Tenant validation passed
         * @returns Array of Asset Types
         */
        getAssetTypesData() {
            return this.isValid ? this.assetTypes : [];
        },

        /**
         * Adds provided assetType object to the DB
         * @param {assetType} assetType
         */
        addAssetType(assetType) {
            assetManagementService.createAssetType(assetType).then(response => {
                this.assetTypes.push(response);
                this.sortAssetTypes();
                Vue.prototype.$toast.show(i18n.t('amm.assetType.saved'));
            });
        },

        /**
         * Updates DB based on provided assetType Object.
         * Updates Store based on response.
         * @param {assetType} assetType
         */
        updateAssetType(assetType) {
            assetManagementService.updateAssetType(assetType).then(response => {
                this.assetTypes = [...this.assetTypes.filter(i => i.id != response.id), response];
                this.sortAssetTypes();
                Vue.prototype.$toast.show(i18n.t('amm.assetType.saved'));
            });
        },

        /**
         * Removes asset Type
         * @param {guid} id
         */
        deleteAssetType(id) {
            assetManagementService.deleteAssetType(id).then(response => {
                this.assetTypes = this.assetTypes.filter(i => i.id != id);
                this.sortAssetTypes();
                Vue.prototype.$toast.show(i18n.t('amm.assetType.deleted'));
            });
        },

        /**
         * Sort Asset types based on assetType Field
         */
        sortAssetTypes() {
            this.assetTypes = this.assetTypes
                .sort((a, b) => {
                    return String(a.assetType).toLowerCase() < String(b.assetType).toLowerCase()
                        ? -1
                        : 1;
                });
            this.updated();
        },

        //#endregion

        //#region Transaction Categories

        /**
         * Read Transaction Categories from DB and Update Store based on Provided transactionType and entityType
         * @param {INCOME / EXPENSE} transactionType
         * @param {ASSET / OPERATOR} entityType
         */
        async getTransactionCategories(transactionType, entityType) {
            if (store.getters['claimsStore/getClaims'].includes('AMMRead')) {
                let tenantId = (Vue.prototype.$session.get('tenantDetails')?.tenantId || '');
                switch (transactionType) {
                    case 'INCOME':
                        switch (entityType) {
                            case 'ASSET':
                                await assetManagementService.getAllIncomeCategories(tenantId, entityType).then(result => {
                                    this.transactionCategories.asset.income = this.sortTransactionCategory(result);
                                }).catch(err => {
                                    console.log("Failed to read transaction Category", err);
                                    Vue.prototype.$toast.error(i18n.t('amm.error.transactionCategory.read'));
                                });
                                break;
                            case 'OPERATOR':
                                await assetManagementService.getAllIncomeCategories(tenantId, entityType).then(result => {
                                    this.transactionCategories.operator.income = this.sortTransactionCategory(result);
                                }).catch(err => {
                                    console.log("Failed to read transaction Category", err);
                                    Vue.prototype.$toast.error(i18n.t('amm.error.transactionCategory.read'));
                                });
                                break;
                        };
                        break;
                    case 'EXPENSE':
                        switch (entityType) {
                            case 'ASSET':
                                await assetManagementService.getAllExpenseCategories(tenantId, entityType).then(result => {
                                    this.transactionCategories.asset.expense = this.sortTransactionCategory(result);
                                }).catch(err => {
                                    console.log("Failed to read transaction Category", err);
                                    Vue.prototype.$toast.error(i18n.t('amm.error.transactionCategory.read'));
                                });
                                break;
                            case 'OPERATOR':
                                await assetManagementService.getAllExpenseCategories(tenantId, entityType).then(result => {
                                    this.transactionCategories.operator.expense = this.sortTransactionCategory(result);
                                }).catch(err => {
                                    console.log("Failed to read transaction Category", err);
                                    Vue.prototype.$toast.error(i18n.t('amm.error.transactionCategory.read'));
                                });
                                break;
                        };
                        break;
                    default:
                        switch (entityType) {
                            case 'ASSET':
                                await Promise.all([
                                    assetManagementService.getAllIncomeCategories(tenantId, entityType),
                                    assetManagementService.getAllExpenseCategories(tenantId, entityType)
                                ]).then(([incomeType, expenseTypes]) => {
                                    this.transactionCategories.asset.income = this.sortTransactionCategory(incomeType);
                                    this.transactionCategories.asset.expense = this.sortTransactionCategory(expenseTypes);
                                }).catch(err => {
                                    console.log("Failed to read transaction Category", err);
                                    Vue.prototype.$toast.error(i18n.t('amm.error.transactionCategory.read'));
                                });
                                break;
                            case 'OPERATOR':
                                await Promise.all([
                                    assetManagementService.getAllIncomeCategories(tenantId, entityType),
                                    assetManagementService.getAllExpenseCategories(tenantId, entityType)
                                ]).then(([incomeType, expenseTypes]) => {
                                    this.transactionCategories.operator.income = this.sortTransactionCategory(incomeType);
                                    this.transactionCategories.operator.expense = this.sortTransactionCategory(expenseTypes);
                                });

                                break;
                            default:
                                await Promise.all([
                                    assetManagementService.getAllIncomeCategories(tenantId, 'ASSET'),
                                    assetManagementService.getAllExpenseCategories(tenantId, 'ASSET'),
                                    assetManagementService.getAllIncomeCategories(tenantId, 'OPERATOR'),
                                    assetManagementService.getAllExpenseCategories(tenantId, 'OPERATOR')
                                ]).then(([incomeAsset, expenseAsset, incomeOperator, expenseOperator]) => {
                                    this.transactionCategories.asset.income = this.sortTransactionCategory(incomeAsset);
                                    this.transactionCategories.asset.expense = this.sortTransactionCategory(expenseAsset);
                                    this.transactionCategories.operator.income = this.sortTransactionCategory(incomeOperator);
                                    this.transactionCategories.operator.expense = this.sortTransactionCategory(expenseOperator);
                                }).catch(err => {
                                    console.log("Failed to read transaction Category", err);
                                    Vue.prototype.$toast.error(i18n.t('amm.error.transactionCategory.read'));
                                });
                                break;
                        };
                        break;
                };
                this.updated();
            };
        },

        /**
         * Retrieves list of Transaction Categories from store based on provided filters
         * @param {String} transactionType
         * @param {String} entityType
         * @returns Array of TransactionCategories
         */
        getTransactionCategoriesData(transactionType, entityType) {
            if (this.isValid) {
                switch (transactionType) {
                    case 'INCOME':
                        switch (entityType) {
                            case 'ASSET':
                                return this.transactionCategories.asset.income;
                                break;
                            case 'OPERATOR':
                                return this.transactionCategories.operator.income;
                                break;
                            default:
                                return {
                                    assets: this.transactionCategories.asset.income,
                                    operators: this.transactionCategories.operator.income,
                                };
                                break;
                        };
                        break;
                    case 'EXPENSE':
                        switch (entityType) {
                            case 'ASSET':
                                return this.transactionCategories.asset.expense;
                                break;
                            case 'OPERATOR':
                                return this.transactionCategories.operator.expense;
                                break;
                            default:
                                return {
                                    assets: this.transactionCategories.asset.expense,
                                    operators: this.transactionCategories.operator.expense,
                                };
                                break;
                        };
                        break;
                    default:
                        switch (entityType) {
                            case 'ASSET':
                                return {
                                    income: this.transactionCategories.asset.income,
                                    expense: this.transactionCategories.asset.expense,
                                };
                                break;
                            case 'OPERATOR':
                                return {
                                    income: this.transactionCategories.operator.income,
                                    expense: this.transactionCategories.operator.expense,
                                };
                                break;
                            default:
                                return this.transactionCategories;
                                break;
                        };
                        break;
                };
            } else {
                return [];
            }; 
        },

        /**
         * Adds a Transaction Category
         * @param {INCOME / EXPENSE} transactionType
         * @param {ASSET / OPERATOR} entityType
         * @param {String} categoryName
         */
        addTransactionCategory(transactionType, entityType, categoryName) {
            let payload = {
                description: categoryName,
                type: entityType,
            };
            switch (transactionType) {
                case "INCOME":
                    assetManagementService.createIncomeCategory(payload).then(result => {
                        switch (entityType) {
                            case "ASSET":
                                this.transactionCategories.asset.income.push(result);
                                this.transactionCategories.asset.income.sort((a, b) => { return a?.description < b?.description ? -1 : 1 });
                                break;
                            case "OPERATOR":
                                this.transactionCategories.operator.income.push(result);
                                this.transactionCategories.operator.income.sort((a, b) => { return a?.description < b?.description ? -1 : 1 });
                                break;
                        };
                         Vue.prototype.$toast.show(i18n.t('amm.transaction.category.saved'));
                    }).catch(err => {
                        console.log("Failed to add transaction Category", err);
                        Vue.prototype.$toast.error(i18n.t('amm.error.transactionCategory.create'));
                    });
                    break;
                case "EXPENSE":
                    assetManagementService.createExpenseCategory(payload).then(result => {
                        switch (entityType) {
                            case "ASSET":
                                this.transactionCategories.asset.expense.push(result);
                                this.transactionCategories.asset.expense.sort((a, b) => { return a?.description < b?.description ? -1 : 1 });
                                break;
                            case "OPERATOR":
                                this.transactionCategories.operator.expense.push(result);
                                this.transactionCategories.operator.expense.sort((a, b) => { return a?.description < b?.description ? -1 : 1 });
                                break;
                        };
                         Vue.prototype.$toast.show(i18n.t('amm.transaction.category.saved'));
                    }).catch(err => {
                        console.log("Failed to add transaction Category", err);
                        Vue.prototype.$toast.error(i18n.t('amm.error.transactionCategory.create'));
                    });
                    break;
            };
            this.updated();
        },

        /**
         * Updates Transaction Category
         * @param {INCOME / EXPENSE} transactionType
         * @param {ASSET / OPERATOR} entityType
         * @param {transactionCategory} category
         */
        updateTransactionCategory(transactionType, entityType, category) {
            category.type = entityType;
            switch (transactionType) {
                case "INCOME":
                    assetManagementService.updateIncomeCategory(category).then(result => {
                        switch (entityType) {
                            case "ASSET":
                                this.transactionCategories.asset.income = this.sortTransactionCategory([
                                    ...this.transactionCategories.asset.income.filter(i => i.id != result?.id),
                                    result,
                                ]);
                                break;
                            case "OPERATOR":
                                this.transactionCategories.operator.income = this.sortTransactionCategory([
                                    ...this.transactionCategories.operator.income.filter(i => i.id != result?.id),
                                    result,
                                ]);
                                break;
                        };
                         Vue.prototype.$toast.show(i18n.t('amm.transaction.category.saved'));
                    }).catch(err => {
                        console.log("Failed to Update transaction Category", err);
                        Vue.prototype.$toast.error(i18n.t('amm.error.transactionCategory.update'));
                    });
                    break;
                case "EXPENSE":
                    assetManagementService.updateExpenseCategory(category).then(result => {
                        switch (entityType) {
                            case "ASSET":
                                this.transactionCategories.asset.expense = this.sortTransactionCategory([
                                    ...this.transactionCategories.asset.expense.filter(i => i.id != result?.id),
                                    result,
                                ]);
                                break;
                            case "OPERATOR":
                                this.transactionCategories.operator.expense = this.sortTransactionCategory([
                                    ...this.transactionCategories.operator.expense.filter(i => i.id != result?.id),
                                    result,
                                ]);
                                break;
                        };
                         Vue.prototype.$toast.show(i18n.t('amm.transaction.category.saved'));
                    }).catch(err => {
                        console.log("Failed to Update transaction Category", err);
                        Vue.prototype.$toast.error(i18n.t('amm.error.transactionCategory.update'));
                    });
                    break;
            };
            this.updated();
        },

        /**
         * Removes transaction Category
         * @param {INCOME / EXPENSE} transactionType
         * @param {ASSET / OPERATOR} entityType
         * @param {transactionCategory} category
         */
        removeTransactionCategory(transactionType, entityType, category) {
            switch (transactionType) {
                case "INCOME":
                    assetManagementService.deleteIncomeCategory(category.id).then(result => {
                        switch (entityType) {
                            case "ASSET":
                                this.transactionCategories.asset.income = this.transactionCategories.asset.income.filter(i => i.id != category.id);
                                break;
                            case "OPERATOR":
                                this.transactionCategories.operator.income = this.transactionCategories.operator.income.filter(i => i.id != category.id);
                                break;
                        };
                         Vue.prototype.$toast.show(i18n.t('amm.transaction.category.deleted'));
                    }).catch(err => {
                        console.log("Failed to delete transaction Category", err);
                        Vue.prototype.$toast.error(i18n.t('amm.error.transactionCategory.delete'));
                    });
                    break;
                case "EXPENSE":
                    assetManagementService.deleteExpenseCategory(category.id).then(result => {
                        switch (entityType) {
                            case "ASSET":
                                this.transactionCategories.asset.expense = this.transactionCategories.asset.expense.filter(i => i.id != category.id);
                                break;
                            case "OPERATOR":
                                this.transactionCategories.operator.expense = this.transactionCategories.operator.expense.filter(i => i.id != category.id);
                                break;
                        };
                        Vue.prototype.$toast.show(i18n.t('amm.transaction.category.deleted'));
                    }).catch(err => {
                        console.log("Failed to delete transaction Category", err);
                        Vue.prototype.$toast.error(i18n.t('amm.error.transactionCategory.delete'));
                    });
                    break;
            };
            this.updated();
        },

        /**
         * Sorts provided transactionCategory list based on description
         * @param {Array of transactionCategory} list
         * @returns Array of transactionCategory
         */
        sortTransactionCategory(list) {
            return list.sort((a, b) => { return a?.description < b?.description ? -1 : 1 });
        },

        //#endregion

        //#region Transaction Types

        /**
         * Retrieves List of transaction Types from DB and saves into store based on provided filters
         * @param {INCOME / EXPENSE} transactionType
         * @param {ASSET / OPERATOR} entityType
         */
        async getTransactionTypes(transactionType, entityType) {
            if (store.getters['claimsStore/getClaims'].includes('AMMRead')) {
                let tenantId = (Vue.prototype.$session.get('tenantDetails')?.tenantId || '');
                switch (transactionType) {
                    case 'INCOME':
                        switch (entityType) {
                            case 'ASSET':
                                await assetManagementService.getAllIncomeTypes(tenantId, entityType).then(result => {
                                    this.transactionTypes.asset.income = result;
                                }).catch(err => {
                                    console.log("Failed to read transaction type", err);
                                    Vue.prototype.$toast.error(i18n.t('amm.error.transactionType.read'));
                                });
                                break;
                            case 'OPERATOR':
                                await assetManagementService.getAllIncomeTypes(tenantId, entityType).then(result => {
                                    this.transactionTypes.operator.income = result;
                                }).catch(err => {
                                    console.log("Failed to read transaction type", err);
                                    Vue.prototype.$toast.error(i18n.t('amm.error.transactionType.read'));
                                });
                                break;
                        };
                        break;
                    case 'EXPENSE':
                        switch (entityType) {
                            case 'ASSET':
                                await assetManagementService.getAllExpenseTypes(tenantId, entityType).then(result => {
                                    this.transactionTypes.asset.expense = result;
                                }).catch(err => {
                                    console.log("Failed to read transaction type", err);
                                    Vue.prototype.$toast.error(i18n.t('amm.error.transactionType.read'));
                                });
                                break;
                            case 'OPERATOR':
                                await assetManagementService.getAllExpenseTypes(tenantId, entityType).then(result => {
                                    this.transactionTypes.operator.expense = result;
                                }).catch(err => {
                                    console.log("Failed to read transaction type", err);
                                    Vue.prototype.$toast.error(i18n.t('amm.error.transactionType.read'));
                                });
                                break;
                        };
                        break;
                    default:
                        switch (entityType) {
                            case 'ASSET':
                                await Promise.all([
                                    assetManagementService.getAllIncomeTypes(tenantId, entityType),
                                    assetManagementService.getAllExpenseTypes(tenantId, entityType)
                                ]).then(([incomeType, expenseTypes]) => {
                                    this.transactionTypes.asset.income = incomeType;
                                    this.transactionTypes.asset.expense = expenseTypes;
                                }).catch(err => {
                                    console.log("Failed to read transaction type", err);
                                    Vue.prototype.$toast.error(i18n.t('amm.error.transactionType.read'));
                                });
                                break;
                            case 'OPERATOR':
                                await Promise.all([
                                    assetManagementService.getAllIncomeTypes(tenantId, entityType),
                                    assetManagementService.getAllExpenseTypes(tenantId, entityType)
                                ]).then(([incomeType, expenseTypes]) => {
                                    this.transactionTypes.operator.income = incomeType;
                                    this.transactionTypes.operator.expense = expenseTypes;
                                }).catch(err => {
                                    console.log("Failed to read transaction type", err);
                                    Vue.prototype.$toast.error(i18n.t('amm.error.transactionType.read'));
                                });
                                break;
                            default:
                                await Promise.all([
                                    assetManagementService.getAllIncomeTypes(tenantId, 'ASSET'),
                                    assetManagementService.getAllExpenseTypes(tenantId, 'ASSET'),
                                    assetManagementService.getAllIncomeTypes(tenantId, 'OPERATOR'),
                                    assetManagementService.getAllExpenseTypes(tenantId, 'OPERATOR'),
                                ]).then(([incomeAsset, expenseAsset, incomeOperator, expenseOperator]) => {
                                    this.transactionTypes.asset.income = incomeAsset;
                                    this.transactionTypes.asset.expense = expenseAsset;
                                    this.transactionCategories.operator.income = incomeOperator;
                                    this.transactionTypes.operator.expense = expenseOperator;
                                }).catch(err => {
                                    console.log("Failed to read transaction Category", err);
                                    Vue.prototype.$toast.error(i18n.t('amm.error.transactionCategory.read'));
                                });
                                break;
                        };
                        break;
                };
                this.updated();
            };
        },

        /**
         * Retrieve Transaction Types from Store based on provided filters
         * @param {INCOME / EXPENSE} transactionType
         * @param {ASSET / OPERATOR} entityType
         * @returns Array of transactionType
         */
        getTransactionTypesData(transactionType, entityType) {
            if (this.isValid) {
                switch (transactionType) {
                    case 'INCOME':
                        switch (entityType) {
                            case 'ASSET':
                                return this.transactionTypes.asset.income;
                                break;
                            case 'OPERATOR':
                                return this.transactionTypes.operator.income;
                                break;
                            default:
                                return {
                                    assets: this.transactionTypes.asset.income,
                                    operators: this.transactionTypes.operator.income,
                                };
                                break;
                        };
                        break;
                    case 'EXPENSE':
                        switch (entityType) {
                            case 'ASSET':
                                return this.transactionTypes.asset.expense;
                                break;
                            case 'OPERATOR':
                                return this.transactionTypes.operator.expense;
                                break;
                            default:
                                return {
                                    assets: this.transactionTypes.asset.expense,
                                    operators: this.transactionTypes.operator.expense,
                                };
                                break;
                        };
                        break;
                    default:
                        switch (entityType) {
                            case 'ASSET':
                                return {
                                    income: this.transactionTypes.asset.income,
                                    expense: this.transactionTypes.asset.expense,
                                };
                                break;
                            case 'OPERATOR':
                                return {
                                    income: this.transactionTypes.operator.income,
                                    expense: this.transactionTypes.operator.expense,
                                };
                                break;
                            default:
                                return this.transactionTypes;
                                break;
                        };
                        break;
                };
            } else {
                return [];
            };         
        },

        /**
         * Adds a transaction Type to the DB and Store
         * @param {INCOME / EXPENSE} transactionType
         * @param {ASSET / OPERATOR} entityType
         * @param {transactionType} item
         */
        addTransactionType(transactionType, entityType, item) {
            item.type = entityType;
            switch (transactionType) {
                case "INCOME":
                    assetManagementService.createIncomeType(item).then(result => {
                        switch (entityType) {
                            case "ASSET":
                                this.transactionTypes.asset.income.push(result);
                                break;
                            case "OPERATOR":
                                this.transactionTypes.operator.income.push(result);
                                break;
                        };
                        Vue.prototype.$toast.show(i18n.t('amm.transaction.type.saved'));
                    }).catch(err => {
                        console.log("Failed to add transaction type", err);
                        Vue.prototype.$toast.error(i18n.t('amm.error.transactionType.create'));
                    });
                    break;
                case "EXPENSE":
                    assetManagementService.createExpenseType(item).then(result => {
                        switch (entityType) {
                            case "ASSET":
                                this.transactionTypes.asset.expense.push(result);
                                break;
                            case "OPERATOR":
                                this.transactionTypes.operator.expense.push(result);
                                break;
                        };
                        Vue.prototype.$toast.show(i18n.t('amm.transaction.type.saved'));
                    }).catch(err => {
                        console.log("Failed to add transaction type", err);
                        Vue.prototype.$toast.error(i18n.t('amm.error.transactionType.create'));
                    });
                    break;
            };
            this.updated();
        },

        /**
         * Updates Transaction Type
         * @param {INCOME / EXPENSE} transactionType
         * @param {ASSET / OPERATOR} entityType
         * @param {transactionType} item
         */
        updateTransactionType(transactionType, entityType, item) {
            item.type = entityType;
            switch (transactionType) {
                case "INCOME":
                    assetManagementService.updateIncomeType(item).then(result => {
                        switch (entityType) {
                            case "ASSET":
                                this.transactionTypes.asset.income = [
                                    ...this.transactionTypes.asset.income.filter(i => i.id != result?.id),
                                    result,
                                ];
                                break;
                            case "OPERATOR":
                                this.transactionTypes.operator.income = [
                                    ...this.transactionTypes.operator.income.filter(i => i.id != result?.id),
                                    result,
                                ];
                                break;
                        };
                        Vue.prototype.$toast.show(i18n.t('amm.transaction.type.saved'));
                    }).catch(err => {
                        console.log("Failed to Update transaction Type", err);
                        Vue.prototype.$toast.error(i18n.t('amm.error.transactionType.update'));
                    });
                    break;
                case "EXPENSE":
                    assetManagementService.updateExpenseType(item).then(result => {
                        switch (entityType) {
                            case "ASSET":
                                this.transactionTypes.asset.expense = [
                                    ...this.transactionTypes.asset.expense.filter(i => i.id != result?.id),
                                    result,
                                ];
                                break;
                            case "OPERATOR":
                                this.transactionTypes.operator.expense = [
                                    ...this.transactionTypes.operator.expense.filter(i => i.id != result?.id),
                                    result,
                                ];
                                break;
                        };
                        Vue.prototype.$toast.show(i18n.t('amm.transaction.type.saved'));
                    }).catch(err => {
                        console.log("Failed to Update transaction Type", err);
                        Vue.prototype.$toast.error(i18n.t('amm.error.transactionType.update'));
                    });
                    break;
            };
            this.updated();
        },

        /**
         * Removes TransactionType
         * @param {INCOME / EXPENSE} transactionType
         * @param {ASSET / OPERATOR} entityType
         * @param {transactionType} item
         */
        removeTransactionType(transactionType, entityType, item) {
            switch (transactionType) {
                case "INCOME":
                    assetManagementService.deleteIncomeType(item.id).then(result => {
                        switch (entityType) {
                            case "ASSET":
                                this.transactionTypes.asset.income = this.transactionTypes.asset.income.filter(i => i.id != item.id);
                                break;
                            case "OPERATOR":
                                this.transactionTypes.operator.income = this.transactionTypes.operator.income.filter(i => i.id != item.id);
                                break;
                        };
                        Vue.prototype.$toast.show(i18n.t('amm.transaction.type.deleted'));
                    }).catch(err => {
                        console.log("Failed to delete transaction Type", err);
                        Vue.prototype.$toast.error(i18n.t('amm.error.transactionType.delete'));
                    });
                    break;
                case "EXPENSE":
                    assetManagementService.deleteExpenseType(item.id).then(result => {
                        switch (entityType) {
                            case "ASSET":
                                this.transactionTypes.asset.expense = this.transactionTypes.asset.expense.filter(i => i.id != item.id);
                                break;
                            case "OPERATOR":
                                this.transactionTypes.operator.expense = this.transactionTypes.operator.expense.filter(i => i.id != item.id);
                                break;
                        };
                        Vue.prototype.$toast.show(i18n.t('amm.transaction.type.deleted'));
                    }).catch(err => {
                        console.log("Failed to delete transaction Type", err);
                        Vue.prototype.$toast.error(i18n.t('amm.error.transactionType.delete'));
                    });
                    break;
            };
            this.updated();
        },

        //#endregion

        //#region Transactions
        testCache(params) {
            return this.isValid
                && this.transactionCacheRanges.filter(i => params.fromDate >= i.fromDate
                    && params.toDate <= i.toDate).length > 0
        },
        parseWithKnownRanges(params) {
            let self = this;
            let callRange = ({ ...params });        
            self.transactionCacheRanges.forEach((range,index) => {
                //First determine if there is any overlap
                if (params.fromDate < range.fromDate // Starts before range start
                    && params.toDate >= range.fromDate //Ends after range starts
                    && params.toDate <= range.toDate) { //Ends before range ends
                    //Date Expanded Backwards     |--***|***
                    callRange.toDate = range.fromDate;
                    self.transactionCacheRanges[index].fromDate  = callRange.fromDate;
                };
                if (params.fromDate >= range.fromDate // Starts after range starts
                    && params.fromDate <= range.toDate //Starts before range end
                    && params.toDate > range.toDate) { //Ends after range ends
                    //Date expanded Forward     ***|***--|
                    callRange.fromDate = range.toDate;
                    self.transactionCacheRanges[index].toDate = callRange.toDate;
                };
                if (params.fromDate < range.fromDate // Starts before range starts
                    && params.toDate < range.fromDate) {  //ends before range end
                    //Date Moved Back     |---|    ******
                    self.transactionCacheRanges.push(callRange);
                };
                if (params.fromDate > range.toDate // Starts before range starts
                    && params.toDate > range.toDate) {  //ends before range end
                    //Date Moved forward  ******  |---|
                    self.transactionCacheRanges.push(callRange);
                };          
                return true;
            });
            if (!self.testCache(callRange)) {
                self.transactionCacheRanges.push(callRange);
            };
            //Reduce cacheRanges
            ////      |______***|***
            ////   ***|***______|
            ////      |_________|
            ////       --|***|--
            if (self.transactionCacheRanges.length > 1) {
                self.transactionCacheRanges = self.transactionCacheRanges.reduce((list, range) => {
                    self.transactionCacheRanges.forEach(params => {
                        if (params.fromDate < range.fromDate // Starts before range start
                            && params.toDate >= range.fromDate //Ends after range starts
                            && params.toDate <= range.toDate) { //Ends before range ends
                            //Date Expanded Backwards     |--***|***
                            range.fromDate = range.fromDate < params.fromDate ? range.fromDate : params.fromDate;
                            range.toDate = range.toDate > params.toDate ? range.toDate : params.toDate;
                        } else if (params.fromDate >= range.fromDate // Starts after range starts
                            && params.fromDate <= range.toDate //Starts before range end
                            && params.toDate > range.toDate) { //Ends after range ends
                            //Date expanded Forward     ***|***--|
                            range.fromDate = range.fromDate < params.fromDate ? range.fromDate : params.fromDate;
                            range.toDate = range.toDate > params.toDate ? range.toDate : params.toDate;
                        };
                    });
                    list.push(range);
                    return list;
                }, []).reduce((list, entry) => {
                    if (!list.map(i => JSON.stringify(i)).includes(JSON.stringify(entry))) {
                        list.push(entry);
                    };
                    return list;
                },[]);
            };
            return callRange;
        },
        async getTransactions(params) {
            let self = this;
            let callRange = self.parseWithKnownRanges(params);
            return await assetManagementService.getTransactions(callRange).then(response => {
                self.transactions = [
                    ...self.transactions,
                    ...response,
                ];
                //Check for duplicates
                if (self.transactions.length != [...new Set(self.transactions.map(i => i.id))].length) {
                    self.transactions = self.transactions.reduce((list, entry) => {
                        return list.find(i => i.id == entry.id)
                            ? list
                            : [...list, entry]
                    }, []);
                };
                self.updated();
                return self.getTransactionData(params);
            }).catch(err => {
                console.log("Failed to read transactions", err);
                Vue.prototype.$toast.error(i18n.t('amm.error.transactions.read'));
                return [];
            });              
        },
        getTransactionData(params) {
            return this.isValid
                ? (this.transactions.filter(transaction =>
                    transaction?.date >= params.fromDate
                    && transaction?.date <= params.toDate).map(i => ({
                        ...i,
                        amount: i.type == 'INCOME'
                            ? i.amount
                            : parseFloat(i.amount) * -1,
                    }))
                )
                : []
        },
        upsertTransactionByObject(transaction) {
            //Always Upsert Transaction
            this.transactions = [
                ...this.transactions.filter(i => i.id != transaction.id),
                transaction,
            ];
            this.updated();
            try {
                Vue.prototype.$dialogEventHub.$emit('transactionsUpdated');
            } catch (e) { };  
        },
        removeTransactionsByIds(ids) {
            if (!Array.isArray(ids)) { ids = [ids] };
            this.transactions = [
                ...this.transactions
                    .filter(i => !ids.includes(i.id))
            ];
            this.updated();
            try {
                Vue.prototype.$dialogEventHub.$emit('transactionsUpdated');
            } catch (e) { };  
        },

        retrieveProfileImage(id) {
            return this.profileImages.find(i => i.id == id) || '';
        },
        saveProfileImage(profileImage) {
            this.profileImages.push(profileImage);
        },
        //#endregion

        //#region Operator Templates

        /**
         * Retrieves list of operator templates
         */
        async getOperatorTemplates() {
            const driverStore = useDriverStore();
            let driverData = driverStore.getOperatorsData();
            if (driverData.length > 0) {
                let payload = { ids: driverData.map(i => i.id) };
                await assetManagementService.getOperatorConfigList(payload).then(response => {
                    this.operatorTemplates = response;
                }).catch(err => { 
                    Vue.prototype.$toast.error(i18n.t('amm.error.lifeCycle.read'));
                    this.operatorTemplates = [];
                });
            } else {
                this.operatorTemplates = [];             
            };   
            this.updated();
        },

        /**
         * Retrieves Operator Templates Store State
         * @returns
         */
        getOperatorTemplatesData() {
            return this.isValid
                ? this.operatorTemplates
                : [];
        },

        /**
         * Adds an operatorConfig to the store state
         * @param {Array<operatorConfig>} templateList
         */
        async addOperatorTemplatesByObject(templateList) {
            if (!Array.isArray(templateList)) { templateList = [templateList] };
            let targetIds = templateList.map(i => i.id);
            this.operatorTemplates = [
                ...this.operatorTemplates.filter(i => !targetIds.includes(i.id)),
                ...templateList
            ];
        },

        //#endregion

        //#region Asset Templates

        /**
         * Retrieves list of asset templates
         */
        async getAssetTemplates() {
            const assetStore = useAssetStore();
            let assetData = assetStore.getAssetsData();
            if (assetData.length > 0) {
                let payload = { ids: assetData.map(i => i.id) };
                await assetManagementService.getAssetConfigList(payload).then(response => {
                    this.assetTemplates = response;
                }).catch(err => {
                    Vue.prototype.$toast.error(i18n.t('amm.error.lifeCycle.read'));
                    this.assetTemplates = [];
                });
            } else {
                this.assetTemplates = [];
            };
            this.updated();
        },

        /**
         * Retrieves Asset Templates Store State
         * @returns
         */
        getAssetTemplatesData() {
            return this.isValid
                ? this.assetTemplates
                : [];
        },

        /**
         * Adds an assetConfig to the store state
         * @param {Array<assetConfig>} templateList
         */
        async addAssetTemplatesByObject(templateList) {
            if (!Array.isArray(templateList)) { templateList = [templateList] };
            let targetIds = templateList.map(i => i.id);
            this.assetTemplates = [
                ...this.assetTemplates.filter(i => !targetIds.includes(i.id)),
                ...templateList
            ];
        },

        //#endregion

        //#region Document Types
      
        /**
         * Read Document Types from DB and sort
         */
        async getDocumentTypes() {
            await assetManagementService.getAllDocumentTypes((Vue.prototype.$session.get('tenantDetails')?.tenantId || '')).then(result => {
                this.documentTypes = result;
                this.sortDocumentTypes();
            });
        },

        /**
         * Return Document Types if Tenant validation passed
         * @returns Array of Document Types
         */
        getDocumentTypesData() {
            return this.isValid ? this.documentTypes : [];
        },

        /**
         * Adds provided Document object to the DB
         * @param {documentType} documentType
         */
        addDocumentType(documentType) {
            assetManagementService.createDocumentType({
                description: documentType,
                tenantId : (Vue.prototype.$session.get('tenantDetails')?.tenantId || ''),
            }).then(response => {
                this.documentTypes.push(response);
                this.sortDocumentTypes();
                 Vue.prototype.$toast.show(i18n.t('amm.type.saved'));          
            });
        },

        /**
         * Updates DB based on provided DocumentType Object.
         * Updates Store based on response.
         * @param {documentType} documentType
         */
        updateDocumentType(documentType) {
            assetManagementService.updateDocumentType(documentType).then(response => {
                this.documentTypes = [...this.documentTypes.filter(i => i.id != response.id), response];
                this.sortDocumentTypes();
                Vue.prototype.$toast.show(i18n.t('amm.type.saved')); 
            });
        },

        /**
         * Removes asset Type
         * @param {guid} id
         */
        deleteDocumentType(id) {
            assetManagementService.deleteDocumentType(id).then(response => {
                this.documentTypes = this.documentTypes.filter(i => i.id != id);
                this.sortDocumentTypes();
                Vue.prototype.$toast.show(i18n.t('amm.type.deleted')); 
            });
        },

        /**
         * Sort Document types based on DocumentType Field
         */
        sortDocumentTypes() {
            this.documentTypes = this.documentTypes
                .sort((a, b) => {
                    return String(a.description).toLowerCase() < String(b.description).toLowerCase()
                        ? -1
                        : 1;
                });
            this.updated();
        },

        //#endregion

        //#region Measurement Units

        /**
         * Read Measurement Units from DB and sort
         */
        async getMeasurementUnits() {
            await assetManagementService.getAllMeasurementUnits().then(result => {
                this.measurementUnits = result;
                this.sortMeasurementUnits();
            });
        },

        /**
         * Return Measurement Units if Tenant validation passed
         * @returns Array of Measurement Units
         */
        getMeasurementUnitsData() {
            return this.isValid ? this.measurementUnits : [];
        },

        /**
         * Adds provided Measurement Units object to the DB
         * @param {measurementUnit} measurementUnit
         */
        addMeasurementUnit(measurementUnit) {
            assetManagementService.createMeasurementUnit(measurementUnit).then(response => {
                this.measurementUnits.push(response);
                this.sortMeasurementUnits();
                Vue.prototype.$toast.show(i18n.t('amm.measurement.saved'));           
            });
        },

        /**
         * Updates DB based on provided Measurement Units Object.
         * Updates Store based on response.
         * @param {measurementUnit} measurementUnit
         */
        updateMeasurementUnit(measurementUnit) {
            assetManagementService.updateMeasurementUnit(measurementUnit).then(response => {
                this.measurementUnits = [...this.measurementUnits.filter(i => i.id != response.id), response];
                this.sortMeasurementUnits();
                Vue.prototype.$toast.show(i18n.t('amm.measurement.saved'));       
            });
        },

        /**
         * Removes Measurement Units
         * @param {guid} id
         */
        deleteMeasurementUnit(id) {
            assetManagementService.deleteMeasurementUnit(id).then(response => {
                this.measurementUnits = this.measurementUnits.filter(i => i.id != id);
                this.sortMeasurementUnits();
                Vue.prototype.$toast.show(i18n.t('amm.measurement.deleted')); 
            });
        },

        /**
         * Sort Measurement Units based on DocumentType Field
         */
        sortMeasurementUnits() {
            this.measurementUnits = this.measurementUnits
                .sort((a, b) => {
                    return String(a.description).toLowerCase() < String(b.description).toLowerCase()
                        ? -1
                        : 1;
                });
            this.updated();
        },

        //#endregion

    },
})