export default class ClockStrategy12Hour {
	constructor(component) {
		this.titleDateFormat = 'MMMM Do YYYY';
		this.titleTimeFormat = 'h:mm a';
		this.component = component;
	}
	populateHours () {
		for (let hour = 1; hour <= 12; hour++) {
			this.component.hours.push(hour);
		}
	}
	getHour (date) {
		var hour = date.hour;
		if (date.isAm) {
			if (hour == 0) {
				hour = 12;
			}
		}
		else {
			hour = hour + 12;
		}
		return hour;
	}
	setTime (date, value) {
		if (value !== undefined) {
			value.hour = date.getHours();
			if (value.hour >= 12) {
				value.isAm = false;
				value.hour = value.hour - 12;
			}
			if (value.hour == 0) {
				value.isAM = true;
				value.hour = 12;
			}
			value.minute = date.getMinutes();
		}
	}	
}