<template>
    <div style="display: contents;">
        <v-menu offset-y
                ref="quickFilter"
                :close-on-content-click="false"
                z-index="12">
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon
                       :dark="dark"
                       v-bind="attrs"
                       v-on="on"
                       @click="logInsight"
                       auto-id="contact_filter_btn">
                    <v-icon>pageview</v-icon>
                </v-btn>
            </template>
            <QuickFilterList :columns="columns"
                             :persist="persist"
                             :filterChange="filterChange"
                             :gridSettingsKey="gridSettingsKey"
                             :filterShortcuts="filterShortcuts"
                             :dark="dark"
                             :data="data"
                             :appliedFilters="appliedFilters"
                             @closeMenu="closeMenu"></QuickFilterList>
        </v-menu>
    </div>
</template>
<script>
    import QuickFilterList from '@/views/Shared/GridTools/QuickFilterList'
    export default {
        name: "QuickFilter",
        components: {
            QuickFilterList,
        },
        props: {
            columns: Array,
            persist: Function,
            filterChange: Function,
            gridSettingsKey: String,
            filterShortcuts: {
                type: Array,
                default() {
                    return []
                }
            },
            dark: {
                type: Boolean,
                required: false,
                default: true,
            },
            data: {
                type: Array,
                default() {
                    return []
                },
            },
            appliedFilters: {
                type: Object,
                default() {
                    return {
                        filters: [],
                    };
                },
            },
        },
        methods: {
            closeMenu() {
                this.$refs.quickFilter.save();
            },
            logInsight() {
                if (this.gridSettingsKey) {
                    let pageName = this.$columnStore.pageNames[this.gridSettingsKey] || this.gridSettingsKey;
                    this.$insights.trackEvent(pageName, 'Filter Selector Clicked')
                };
            },
        },
    };
</script>
