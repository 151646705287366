import { ConfigurationService } from "../service/index";
import { getters, mutations } from "../shared/observeFilter";
import constant from "./../../constant";

import jwt from "jsonwebtoken";
import jsonabc from "jsonabc"; // For Global Group
import Vue from "vue";
import GroupManangementService from "../service/groupmanagement.service";
import { mapGetters, mapActions } from "vuex";
import RBACService from "../service/rbac.service";


let businessGroupList = [];
export const UserSettings = {
    computed: {
        getAssetsData() { return this.$assetStore.getAssetsData(); },
        ...mapGetters('claimsStore', ["getClaims"])
    },
    methods: {
        async getAssets() { await this.$assetStore.getAssets(); }, 
        ...mapActions('claimsStore', ["setClaims","setOperatorRoleId"]),
        saveUserSetings(userId) {
            return ConfigurationService.getUserConfigProperties(userId).then(response => {
                if (response) {
                    const configProps = response.configProperties;
                    mutations.updateCompanyName(configProps.companyInfo[0].companyName);
                    this.$session.set("configProperties", configProps);
                    this.$setDefaultTimeZone();
                }
                return true;
            })
        },

        async mountedBefourRedirectHome(noGroup = null) {
            let self = this;
            this.catchErrorOnGlobalFilterStateData();
            noGroup = noGroup
                ? noGroup
                : !Vue.prototype.$session.get("groupPolicy")
            let tenantDetails = Vue.prototype.$session.get("tenantDetails");
            mutations.updateUserName(tenantDetails.displayName);

            let isAdmin = false;
            if (this.$checkRBACAccess('companySettings')) {
                isAdmin = true;
            } 
            localStorage.removeItem("globalGroupsHierarchies");
            if (this.$isMainTenant()) {
                if (noGroup) {
                    return await this.getHierarchy();
                } else {
                    if (isAdmin) {
                        return await this.getHierarchy();
                    } else {
                        return await this.getHierarchyByUser();
                    }
                }
            } else {
                return await this.getHierarchyByTenantUser();
            }
        },


        getHierarchy(setGlobalFiltersData = false) {
            //let globalFilterStateData = this.$groupsFilterStore;
            //if (this.$groupsFilterStore.getGroupList().length == 0) {
            return GroupManangementService.getMinimalGroupsHierarchies().then(response => {
                    if (response.length) {                       
                        let originalGroupsHierarchy = jsonabc.sortObj(response[0]);
                        let groupsHierarchy = Object.assign({}, originalGroupsHierarchy);
                        businessGroupList = [];
                        businessGroupList.push({
                            id: groupsHierarchy.id,
                            groupId: groupsHierarchy.currentGroup.id,
                            groupName: groupsHierarchy.currentGroup.groupName
                        });
                        if (groupsHierarchy.groupChildren.length > 0) {
                            this.createGlobalBusinessGroupList(
                                groupsHierarchy.currentGroup.groupName,
                                groupsHierarchy.groupChildren
                            );
                        }
                        localStorage.setItem(
                            "globalBusinessGroups",
                            JSON.stringify(businessGroupList)
                        );
                        let GlobalFiltersData = JSON.parse(
                            localStorage.getItem("GlobalFiltersData")
                        );
                        if (setGlobalFiltersData == false || !GlobalFiltersData) {
                            if (!(GlobalFiltersData && GlobalFiltersData.tenantId && GlobalFiltersData.tenantId == this.$session.get("tenantDetails").tenantId)) {
                                setGlobalFiltersData = true;
                            };
                        };
                        if (setGlobalFiltersData == true) {
                            GlobalFiltersData = {
                                businessGroups: businessGroupList.map(group => group.id), //List of selected group IDs
                                businessGroupName: businessGroupList, //List of selected groups
                                isAll: false, //this.all, //Indicate if all is selected //Fixed to false till claim for EVERYTHING is introduced
                                isAllGroups: true,
                            }
                        };
                        GlobalFiltersData = this.validateGlobalFiltersData(GlobalFiltersData, businessGroupList);
                        return this.getEntitiesBasedOnGroups(GlobalFiltersData.businessGroups, GlobalFiltersData.isAllGroups);
                    } else {
                        this.validateGlobalFiltersData(null, []);
                        return this.getEntitiesBasedOnGroups([], true);
                    }
                });
            //} else {
            //    return this.setFiltersState(globalFilterStateData);
            //}
        },
        catchErrorOnGlobalFilterStateData() {
            try {
                let GlobalFiltersData = JSON.parse(
                    localStorage.getItem("GlobalFiltersData")
                );
            } catch (e) {
                localStorage.removeItem("GlobalFiltersData");
            };
        },
        validateGlobalFiltersData(GlobalFiltersData, globalBusinessGroups) {
            if (!GlobalFiltersData || globalBusinessGroups.length == 0) {
                //GlobalFiltersData should be empty
                GlobalFiltersData = {
                    businessGroups: [], //List of selected group IDs
                    businessGroupName: [], //List of selected groups
                    isAll: false, //this.all, //Indicate if all is selected //Fixed to false till claim for EVERYTHING is introduced
                    isAllGroups: false,
                };
            } else {
                //There is data in GlobalFiltersData
                let groupIdList = globalBusinessGroups.map(i => i.id);
                GlobalFiltersData.businessGroups = GlobalFiltersData.businessGroups.filter(i => groupIdList.includes(i)); // Filter Business groups to ensure selected groups are groups the user is allowed to access
                if (GlobalFiltersData.businessGroups.length == 0 || GlobalFiltersData.businessGroups.length == groupIdList.length) { //If no Groups or all groups
                    //No More Groups - So Make it all
                    GlobalFiltersData.businessGroups = groupIdList;
                    GlobalFiltersData.businessGroupName = globalBusinessGroups;
                    GlobalFiltersData = {
                        businessGroups: groupIdList, //List of selected group IDs
                        businessGroupName: globalBusinessGroups, //List of selected groups
                        isAll: false, 
                        isAllGroups: true,
                    }
                } else {
                    //We have groups to show
                    GlobalFiltersData.businessGroupName = globalBusinessGroups.filter(i => GlobalFiltersData.businessGroups.includes(i.id)) // Filter Group List
                    GlobalFiltersData.isAll = false;
                    GlobalFiltersData.isAllGroups = false;
                };
            };
            localStorage.setItem("GlobalFiltersData", JSON.stringify(GlobalFiltersData));
            return GlobalFiltersData;
        },

        setFiltersState(globalFilterStateData, isLoaded = true) {
            mutations.updateLoadedState(isLoaded);
            this.$groupsFilterStore.setGlobalFilterState(globalFilterStateData);
            /*this.randomNumber = Math.ceil(Math.random() * 10000);*/
            return true;
        },
        allowedGroupFilter(allowedList, groupChildren, groupList, hierarchyName) {      
            groupChildren.forEach(child => {
                let newHierarchyName = (hierarchyName ? (hierarchyName + " > ") : '') + child.currentGroup.groupName;
                if (child.groupChildren.length != 0) {
                    let additionalAllowed = this.allowedGroupFilter([], child.groupChildren, groupList, newHierarchyName);
                    if (additionalAllowed) {
                        allowedList =
                        [...allowedList, ...additionalAllowed];
                    };
                };
                if (groupList.includes(child.id) || groupList.includes(child.currentGroup.id)) {                    
                    allowedList = [{ ...child, hierarchyName: newHierarchyName }, ...allowedList];
                };
            });
            return allowedList;
        },
        async getHierarchyByTenantUser() {
            // Set GlobalFiltersData
            localStorage.removeItem('GlobalFiltersData');
            let tenantRecord = JSON.parse(localStorage.getItem('tenantGroups'));
            let self = this;
            if (tenantRecord) {
                let groupList = tenantRecord.find(i => i.externalTenantId == this.$session.get('tenantDetails').tenantId)
                if (groupList) {
                    //IF GroupPolicy == disabled then SHOW all else show Nothing
                    let groupPolicy = this.$session.get("groupPolicy");
                    if (groupList.externalGroupIds == null) {
                        console.log('elect to load all')
                        if (!groupPolicy) {
                            this.getHierarchy(true);
                        } else {
                            //Group Policy is active, but no groups in list - show nothing
                            this.validateGlobalFiltersData(null, []);
                            this.getEntitiesBasedOnGroups([], false);
                        };
                    } else if (groupList.externalGroupIds.length == 0) {
                        //this.getEntitiesBasedOnGroups([], false); // Return none
                        if (!groupPolicy) {
                            this.getHierarchy(true); // Return all
                            console.log('No Groups Selected')
                        } else {
                            //Group Policy is active, but no groups in list - show nothing
                            this.validateGlobalFiltersData(null, []);
                            this.getEntitiesBasedOnGroups([], false);
                        };                      
                    } else {
                        //Load Hirarchy
                        return GroupManangementService.getMinimalGroupsHierarchies().then(async response => {
                            if (response.length) {
                                //Need to filter out groups not allowed
                                let filter = this.allowedGroupFilter([], [...response], groupList.externalGroupIds);
                                
                                response = filter;
                                //Now set it
                               
                                businessGroupList = response.map(groupsHierarchy => {
                                    if (groupsHierarchy.groupChildren.length > 0) {
                                        this.createGlobalBusinessGroupList(
                                            groupsHierarchy.currentGroup.groupName,
                                            groupsHierarchy.groupChildren
                                        );
                                    }
                                    return {
                                        id: groupsHierarchy.id,
                                        groupId: groupsHierarchy.currentGroup.id,
                                        groupName: groupsHierarchy.hierarchyName
                                            ? groupsHierarchy.hierarchyName
                                            : groupsHierarchy.currentGroup.groupName
                                    };
                                });
                                localStorage.setItem(
                                    "globalBusinessGroups",
                                    JSON.stringify(businessGroupList)
                                );
                                let GlobalFiltersData = this.validateGlobalFiltersData({
                                    businessGroups: businessGroupList.map(group => group.id), //List of selected group IDs
                                    businessGroupName: businessGroupList, //List of selected groups
                                    isAll: false, //this.all, //Indicate if all is selected //Fixed to false till claim for EVERYTHING is introduced
                                    isAllGroups: true,
                                }, businessGroupList);
                                await self.getEntitiesBasedOnGroups(GlobalFiltersData.businessGroups, false);
                                return true;
                            } else {
                                this.validateGlobalFiltersData(null, []);
                                return this.getEntitiesBasedOnGroups([], false);
                            }
                        });
                        //
                       //return this.getEntitiesBasedOnGroups(groupList.externalGroupIds, false);
                    };
                } else {
                    return this.getHierarchyByUser();
                };
            } else {
                return this.getHierarchyByUser();
            };
            //this.getEntitiesBasedOnGroups(groupIds, isAll);
            
        },
        getHierarchyByUser() {
            let globalFilterStateData = this.$groupsFilterStore;
            let userId = Vue.prototype.$session.get("tenantDetails").userId;
            if (globalFilterStateData.groups.length == 0 || globalFilterStateData.userId != userId) {
              
                return GroupManangementService.GetMinimalUserGroupHierarchyAccess(userId).then(response => {
                    if (response && response.length) {                     
                        businessGroupList = [];
                        response.forEach((element) => {
                            let originalGroupsHierarchy = jsonabc.sortObj(
                                element
                            );
                            let groupsHierarchy = Object.assign(
                                {},
                                originalGroupsHierarchy
                            );
                            if (groupsHierarchy.currentGroup) {
                                businessGroupList.push({
                                    id: groupsHierarchy.id,
                                    groupId: groupsHierarchy.currentGroup.id,
                                    groupName:
                                        groupsHierarchy.currentGroup.groupName,
                                });
                            };
                            if (groupsHierarchy.groupChildren.length > 0) {
                                this.createGlobalBusinessGroupList(
                                    groupsHierarchy.currentGroup?.groupName || Vue.prototype.$session.get("tenantDetails").tenantId ,
                                    groupsHierarchy.groupChildren,
                                    userId,
                                );
                            }
                            groupsHierarchy = {};
                        });
                        localStorage.setItem(
                            "globalBusinessGroups",
                            JSON.stringify(businessGroupList)
                        );
                        let IDs = [];
                        businessGroupList.forEach((element) => {
                            IDs.push(element.id);
                        });
                        let GlobalFiltersData = JSON.parse(
                            localStorage.getItem("GlobalFiltersData")
                        );
                        if (!(GlobalFiltersData && GlobalFiltersData.tenantId && GlobalFiltersData.tenantId == this.$session.get("tenantDetails").tenantId)) {
                            GlobalFiltersData = {
                                businessGroups: businessGroupList.map(group => group.id), //List of selected group IDs
                                businessGroupName: businessGroupList, //List of selected groups
                                isAll: false, //this.all, //Indicate if all is selected //Fixed to false till claim for EVERYTHING is introduced
                                isAllGroups: true,
                            };
                        };
                        GlobalFiltersData = this.validateGlobalFiltersData(GlobalFiltersData, businessGroupList);
                        return this.getEntitiesBasedOnGroups(
                            GlobalFiltersData.businessGroups,
                            GlobalFiltersData.isAllGroups, //Used To Be TRUE
                        );
                    } else {
                        this.validateGlobalFiltersData(null, []);
                        return this.getEntitiesBasedOnGroups(
                            [],
                            true,//Used To Be TRUE
                        );
                    }
                });
            } else {
                this.setFiltersState(globalFilterStateData);
                return true;
            }
        },
        getEntitiesBasedOnGroups(groupIds, isAll) {
            let globalFilterStateData = {};
            if (isAll) {
                if (groupIds.length == 0) {
                    localStorage.setItem(
                        "globalBusinessGroups",
                        JSON.stringify([])
                    );
                };
                let groupPolicy = Vue.prototype.$session.get("configProperties")
                    .groupPolicy;
                let isAdmin = false;
                if (this.$checkRBACAccess("companySettings")) {
                    isAdmin = true;
                }
                if (!groupPolicy) {
                    globalFilterStateData = {
                        groups: groupIds,
                        vehicles: ["ALL"],
                        drivers: ["ALL"],
                        locations: ["ALL"],
                        users: ["ALL"],
                        contacts: ["ALL"],
                    };
                    this.setFiltersState(globalFilterStateData);
                    return true;
                } else {
                    if (isAdmin && this.$isMainTenant()) {
                        globalFilterStateData = {
                            groups: groupIds,
                            vehicles: ["ALL"],
                            drivers: ["ALL"],
                            locations: ["ALL"],
                            users: ["ALL"],
                            contacts: ["ALL"],
                        };
                        this.setFiltersState(globalFilterStateData);
                        return true;
                    } else {
                        if (groupIds.length > 0) {
                            return GroupManangementService.GetEntitiesInGroupHierarchy(groupIds).then(
                                (response) => {
                                    let data = response;
                                    data["groups"] = groupIds;
                                    this.setFiltersState(data);
                                    return true;
                                }
                            );
                        } else {
                            globalFilterStateData = {
                                groups: groupIds,
                                vehicles: [],
                                drivers: [],
                                locations: [],
                                users: [],
                                contacts: [],
                            };
                            localStorage.setItem(
                                "globalBusinessGroups",
                                JSON.stringify([])
                            );
                            this.setFiltersState(globalFilterStateData);
                        }
                    }
                }
            } else {
                if (groupIds.length > 0) {
                    return GroupManangementService.GetEntitiesInGroupHierarchy(groupIds).then(
                        (response) => {
                            let data = response;
                            data["groups"] = groupIds;
                            this.setFiltersState(data);
                            return true;
                        }
                    );
                } else {
                    globalFilterStateData = {
                        groups: groupIds,
                        vehicles: [],
                        drivers: [],
                        locations: [],
                        users: [],
                        contacts: [],
                    };
                    localStorage.setItem(
                        "globalBusinessGroups",
                        JSON.stringify([])
                    );
                    this.setFiltersState(globalFilterStateData);
                }
            };
           
        },

        createGlobalBusinessGroupList(hierarchyName, hierarchyData, userId = null) {
            for (let i = 0; i < hierarchyData.length; i++) {
                let data = hierarchyData[i];
                let newHierarchyName = hierarchyName + " > " + data.currentGroup.groupName;
                if (userId
                    ? data.groupEntities.filter(i => i.EntityID == userId && i.EntityType == 'users').length != 0
                : true) {
                    businessGroupList.push({
                        id: data.id,
                        groupId: data.currentGroup.id,
                        groupName: newHierarchyName
                    });
                };   
                if (data.groupChildren.length > 0) {
                    this.createGlobalBusinessGroupList(
                        newHierarchyName,
                        data.groupChildren,
                        userId
                    );
                }
            }
        },

        //  Global Groups Filter Changes Start
        async setDashboardFilters() {
            let self = this;
            let showUnassignCheckbox = false;
            const configProperties = self.$session.get("configProperties");

            let isAdmin = false;
            if (this.$checkRBACAccess("companySettings")) {
                isAdmin = true;
            }
            if (!configProperties.groupPolicy) {
                showUnassignCheckbox = true;
            } else {
                if (isAdmin) {
                    showUnassignCheckbox = true;
                } else {
                    showUnassignCheckbox = false;
                }
            }
            let GlobalGroupsIds = this.$groupsFilterStore.getGroupList();
            let GlobalVehicleIds = this.$groupsFilterStore.vehicles;
            let sentFilterData = {};
            if (GlobalGroupsIds.length) {
                sentFilterData.businessGroups = GlobalGroupsIds;
                sentFilterData.drivers = ["All"];
                if (GlobalVehicleIds.includes("ALL")) {
                    await this.getAssets()
                    let dataToSaved = [];
                    this.getAssetsData.forEach((element) => {
                        dataToSaved.push(element.id);
                    });
                    let globalDashboardStateNew = {};
                    globalDashboardStateNew.businessGroups =
                        sentFilterData.businessGroups;
                    if (showUnassignCheckbox) {
                        globalDashboardStateNew.businessGroups.unshift("unassigned");
                    }
                    globalDashboardStateNew.AllVehicles = dataToSaved;
                    globalDashboardStateNew.businessGroupName = [];
                    globalDashboardStateNew.businessGroupName.push({
                        id: "All",
                        groupName: self.$t("Dashboard.all"),
                    });
                    localStorage.setItem(
                        "globalDashboardState",
                        JSON.stringify(globalDashboardStateNew)
                    );
                    return JSON.stringify(globalDashboardStateNew);
                }
            } else {
                return true
            }
        },

        async saveUserSessionData(isOtpLogin = 0) {
            let report = this.$session.get("tenantDetails");

            mutations.updateUserName(report.displayName);

            if (report.jwtToken != undefined) {
                        report.role = [];
                if (report.jwtTokenRenewal && report.jwtTokenRenewal.roles) {
                    localStorage.setItem('BearerToken', report.jwtTokenRenewal.bearerToken);
                    localStorage.setItem('x-token-time', new Date(report.jwtTokenRenewal.exp).getTime());
                    report.role = report.jwtTokenRenewal.roles;
                        this.$session.set("tenantDetails", report);
                        this.setClaims(report);  
                        this.$groupsFilterStore.clearGroupsStore();
                    RBACService.getApplicationByName()
                        .then(response => {
                            if (response && response.id) {
                                this.$session.set("applicationId", response.id);
                                let params = {
                                    applicationId: response.id
                                };
                                this.setOperatorRoleId(params);
                            }
                        }, err => {

                        }).catch((error) => {

                        });
                }
            }
            await ConfigurationService.getSpecificTenantConfigProps('privacy,groupPolicy').then(result => {
                const config = result.configProperties
                this.$session.set("companyPrivacy", config.privacy);
                this.$session.set("groupPolicy", config.groupPolicy);
            });
            if (report.rolesAssigned != undefined) {
                let roles = report.rolesAssigned;
                let priority;
                roles.forEach(item => {
                    if (item.roleName == "Customer Administrator") {
                        priority = item.roleName;
                    } else if (item.roleName == "Customer Operator") {
                        priority = item.roleName;
                    } else if (item.roleName == "Customer User") {
                        priority = item.roleName;
                    } else if (item.roleName == "Customer Administrator") {
                        priority = item.roleName;
                    }
                });
                this.$session.set("userRole", priority);
            }

            if (!isOtpLogin) {
                this.localisationCulture(this.$route.params.token, report)
                return true;
            } else {
                return true;
            }


        },

        destroySession() {
            this.$session.destroy();
            let lang = localStorage.getItem("lang");
            localStorage.clear();
            localStorage.setItem("lang", lang);
        }

    }
}