<template>
  <div class="HarshCorneringWidget ctrack-h-100" ref="HarshCornering">
    <div id="vueapp" class="vue-app ctrack-h-100">

    <!-- Harsh Cornering -->
    <div class="dBehaviour-inner" :class="widgetColorClass">        
      <div class="dBehaviour-box-col">
        <div class="dBehaviour-box-icon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="30" height="33" viewBox="0 0 30 33"><path d="M749.978,216.008a11.811,11.811,0,1,1,.062,0Zm-2.5-7.059a2.644,2.644,0,0,0-2.068,1.856.923.923,0,0,0,.5,1.278,9.256,9.256,0,0,0,8.2-.011.983.983,0,0,0,.5-1.219,2.718,2.718,0,0,0-1.984-1.876,11.729,11.729,0,0,0-2.608-.293A12.461,12.461,0,0,0,747.478,208.949Zm9.5-5.648c-.969.082-1.521.45-1.7,1.239a7.73,7.73,0,0,0-.226,1.733,2.424,2.424,0,0,0,2.295,2.5.424.424,0,0,0,.439-.266,9.3,9.3,0,0,0,1.112-3.958.982.982,0,0,0-.919-1.194c-.217-.045-.44-.058-.661-.086,0,.01,0,.02,0,.03-.072,0-.144,0-.216,0C757.059,203.3,757.019,203.3,756.979,203.3Zm-15.457.191a.671.671,0,0,0-.431.662,9.137,9.137,0,0,0,1.171,4.351.46.46,0,0,0,.335.189,2.37,2.37,0,0,0,2.318-2.025,5.917,5.917,0,0,0-.175-2.174,1.515,1.515,0,0,0-1.3-1.2c-.25-.049-.508-.064-.761-.094l-.024-.058A10.691,10.691,0,0,0,741.523,203.492Zm11.6-2.513c.61.1,1.211.267,1.815.4a3.036,3.036,0,0,0,2.366-1.042.951.951,0,0,0,.1-1.335,8.907,8.907,0,0,0-14.831.031.911.911,0,0,0,.015,1.174,3.013,3.013,0,0,0,3.018,1.081,15.251,15.251,0,0,1,4.283-.59A20.12,20.12,0,0,1,753.125,200.979ZM736.342,185.21l2.194,2.244a16.974,16.974,0,0,1,22.877-.044l2.138-2.17.116.049L765,194.426l-6.7-3.654,1.716-1.8a14.749,14.749,0,0,0-20.116.051c.624.553,1.227,1.09,1.829,1.626-.02.045-.041.089-.06.134L735,194.427C735.449,191.339,735.89,188.314,736.342,185.21Z" transform="translate(-735 -182.999)"/></svg>
      </div>
        <div class="dBehaviour-box-content">
          <h3>
            <span class="dBehaviour-box-value-icon" :class="widgetIcon"><svg v-if="widgetIcon!=''" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10"><path d="M638.392,193H628.62a.626.626,0,0,1-.527-.962c.213-.383,4.509-8.038,4.876-8.691a.6.6,0,0,1,1.059,0c.269.47,4.587,8.154,4.887,8.711A.623.623,0,0,1,638.392,193Z" transform="translate(-628 -183.028)" /></svg></span>{{widgetValue}}
          </h3>
          <p class="ctrack-text-truncate">{{widgetTitle}}</p>
        </div>
      </div>      
    </div>
    <!-- Harsh Cornering -->

    </div>    
  </div>
</template>

<script>
import dashboardService from "../../../service/dashboard.service";
import { getters } from "../shared/observeDashboardFilter";

export default {
  name: 'harshcorneringwidget',
  components: {},
  data: function() {
        return {
          widgetTitle :'-',
          widgetValue :'',
          widgetIcon:'',
          widgetColorClass:''
        }
  },
  props: {
    widgetData: Object
  },
  created(){
      this.getDriverHarshCorneringWidgetData();        
  },  
  methods: {
      getDriverHarshCorneringWidgetData() {
        let self = this;
        dashboardService.getWidgetData(this.widgetData.api, getters.getFilterParams() ).then(returnResult => {
                 
          self.widgetTitle = returnResult.hwTitle;
          self.widgetValue = returnResult.value + returnResult.type;
          self.widgetIcon =  (returnResult.icon == "down") ? "md-chart-arrow-down" : (returnResult.icon == "up") ? "md-chart-arrow-up" : "";
          self.widgetColorClass = (returnResult.color == "green") ? "success-color" : (returnResult.color == "red") ? "error-color" : "";          
        });      
      
    }
  }

};

</script>
<style scoped>

/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
*{font-family: 'Roboto', sans-serif;}
.ctrack-h-100{height: 100%;}
.error-color .dBehaviour-box-col h3{color:#FA4B4B;}
.success-color .dBehaviour-box-col h3{color:#76CD24;}
[class*="md-chart-arrow"]{display:inline-block;vertical-align:middle;}
[class*="md-chart-arrow"] svg{display:block;}
.md-chart-arrow-down{transform:rotate(180deg); -webkit-transform:rotate(180deg); -moz-transform:rotate(180deg); -ms-transform:rotate(180deg); -o-transform:rotate(180deg);}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}

.dBehaviour-inner{display:inline-block;width:100%;height:100%;background-color:#FFF;padding:13px 20px;border-radius: 5px; box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, .05); -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, .05);}
.dBehaviour-box-row{display:inline-block;width:100%;padding-left:40px;position:relative;}
.dBehaviour-box-col{display:inline-block;vertical-align: middle;position:relative;width:100%;text-align:right;padding-left:40px;}
.dBehaviour-box-icon{position:absolute;color:#656565; left:0;top:50%;transform:translateY(-50%); -webkit-transform:translateY(-50%); -moz-transform:translateY(-50%); -ms-transform:translateY(-50%); -o-transform:translateY(-50%);}
.dBehaviour-box-content{display:block;width:100%;padding-left:5px;}
.dBehaviour-box-content h3{font-size:20px;font-weight:500;}
.dBehaviour-box-value-icon{margin-right:8px;}
.dBehaviour-box-content p{font-size:15px;color:#333333;font-weight:500;}


/* Responsive CSS */
@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1399px){}
@media only screen and (max-width:1299px){}
@media only screen and (max-width:1199px){}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){}
@media only screen and (max-width:767px){}
@media only screen and (max-width:575px){}

</style>
