<template>
    <div class="DistanceFuelChart ctrack-h-100" ref="DistanceFuel">
        <div id="vueapp" class="vue-app ctrack-h-100">

            <!-- Distance vs Fuel Chart -->
            <div class="cd-chart-innerbox">
                <div class="ctrack-md-loader" :class="loaderHide?'ctrack-chart-hide-loader':''"></div>
                <div class="cd-chart-box-top">
                    <div class="cd-chart-box-header">
                        <div class="cd-chart-box-col1">
                            <h3>{{chartTitle}}</h3>
                        </div>
                        <div class="cd-chart-box-col2" :class="loaderHide?'':'ctrack-chart-hide-loader'">                            
                            <div class="md-view-detail-link">
                                <svg version="1.1" width="20" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 89.64 90.91" style="enable-background:new 0 0 89.64 90.91;" xml:space="preserve"><g>
                                    <path d="M5.93,84.99c27.97,0,55.8,0,83.71,0c0,2,0,3.93,0,5.92c-29.87,0-59.73,0-89.64,0C0,60.64,0,30.4,0,0.09
                                    c1.96,0,3.91,0,5.93,0C5.93,28.38,5.93,56.61,5.93,84.99z"/>
                                    <path d="M67.5,80.38c0-21.98,0-43.9,0-65.87c3.52,0,7,0,10.53,0c0,21.97,0,43.88,0,65.87C74.53,80.38,71.06,80.38,67.5,80.38z"/>
                                    <path d="M30.45,25.58c-1.48,1.49-2.94,2.95-4.4,4.42c-2.77,2.77-5.54,5.53-8.3,8.31c-0.93,0.94-2.01,1.29-3.27,0.94
                                    c-2.07-0.58-2.86-3.07-1.52-4.76c0.32-0.4,0.71-0.76,1.07-1.12c3.77-3.78,7.54-7.56,11.33-11.31c0.53-0.52,0.66-0.87,0.28-1.62
                                    C23,15.15,23.27,9.97,26.97,5.33c3.65-4.58,8.59-6.28,14.29-4.83c5.75,1.46,9.26,5.35,10.22,11.18c0.89,5.44-0.86,10.1-5.41,13.36
                                    c-4.63,3.33-9.62,3.59-14.74,1.02C31.07,25.94,30.83,25.79,30.45,25.58z M29.98,13.82c0,4.37,3.46,7.85,7.79,7.86
                                    c4.42,0.01,7.94-3.46,7.95-7.82c0.01-4.27-3.54-7.74-7.91-7.75C33.48,6.1,29.98,9.55,29.98,13.82z"/>
                                    <path d="M41.48,35.67c3.53,0,6.97,0,10.49,0c0,14.87,0,29.69,0,44.59c-3.48,0-6.95,0-10.49,0C41.48,65.41,41.48,50.59,41.48,35.67z
                                    "/>
                                    <path d="M26.12,80.4c-3.5,0-6.94,0-10.46,0c0-8.75,0-17.46,0-26.24c3.45,0,6.92,0,10.46,0C26.12,62.87,26.12,71.58,26.12,80.4z"/>
                                </g>
                                </svg>
                            </div>                
                        </div>
                    </div>
                </div>
                <div class="ctrack-d-flexcol">
                    <div class="ctrack-row chart-with-right-widget">
                        <div class="ctrack-col-lg-7 ctrack-col-xl-8">
                            <div class="d-chart-box-inner-left">                                
                                <div class="cd-chart-box-img">            
                                    <!-- <img style="width:100%" src="../../../assets/images/dashboard/md-distance-vs-fuel-chart.svg" /> -->
                                    <highcharts :options="chartOptions" ref="distanceFuelchart" :class="loaderHide?'':'ctrack-chart-hide-loader'" ></highcharts>
                                </div>
                            </div>
                        </div>
                        <div class="ctrack-col-lg-5 ctrack-col-xl-4">
                            <div class="cd-chart-box-inner-right" :class="loaderHide?'':'ctrack-chart-hide-loader'">
                                <div class="cd-chart-box-right-title">{{$t("Dashboard.legends")}}</div>
                                <div class="cd-chart-indicator-right">
                                    <div class="ctrack-row">                                        
                                        <div class="ctrack-col-12" :key="i" v-for="(series,i) in chartOptions.series">
                                            <div class="cd-chart-indicator-inner" v-if="loaderHide">
                                                <span :style="'background-color:'+series.color" class="cd-chart-indicator-color"></span>{{series.name}} 
                                                <div class="ctrack-right-toggle-btns"><label class="ctrack-switch"><input type="checkbox" checked="checked" @click="toggleSeries(i)"><span class="ctrack-slider ctrack-round"></span></label></div>
                                            </div>
                                        </div>
                                        <!-- <div class="ctrack-col-12">
                                            <div class="cd-chart-indicator-inner">
                                                <span style="background-color:#434348" class="cd-chart-indicator-color"></span>Distance
                                                <div class="ctrack-right-toggle-btns"><label class="ctrack-switch"><input type="checkbox" checked="checked"><span class="ctrack-slider ctrack-round"></span></label></div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Distance vs Fuel Chart -->

        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import HighChartMore from 'highcharts/highcharts-more'
import HighChartAccessibility from 'highcharts/modules/accessibility';
import dashboardService from "../../../service/dashboard.service";
import {
    mutations,
    getters
} from "../shared/observeDashboardFilter";
import JQuery from "jquery";

HighChartMore(Highcharts);
HighChartAccessibility(Highcharts);
Vue.use(HighchartsVue, {});
export default {
    name: 'DistanceFuelChart',
    components: {},
    data: function () {
        return {
            chartTitle: "",
            // showDrill:false,
            hideloaderClass: "",
            loaderHide: false,

            chartOptions: {
                chart: {
                    zoomType: 'xy',
                    type: 'column',
                    options3d: {
                        enabled: true,
                        alpha: 10,
                        beta: 10,
                        depth: 40,
                        viewDistance: 50,
                        fitToPlot: false,
                    }
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                xAxis: {
                    title: {
                        text: '',
                        margin: 20
                    },
                    categories: [],
                    crosshair: true,
                    labels: {
                        style: {
                            color: '#333333',
                            fontSize: "14px",
                            fontFamily: "Roboto, Regular"
                        }
                    }
                },
                yAxis: [{ // Primary yAxis
                    labels: {
                        format: "", // '{value} KM',
                        style: {
                            color: "", // "#434348"
                        }
                    },
                    title: {
                        text: "", // 'Distance',
                        style: {
                            color: "", // "#434348",
                            fontSize: "14px",
                            fontFamily: 'Roboto, Regular',
                        },
                        verticalAlign: "bottom",
                        // margin: 20
                    }
                }, { // Secondary yAxis
                    title: {
                        text: "", // 'Fuel',
                        style: {
                            color: "", // "#7cb5ec",
                            fontSize: "14px",
                            fontFamily: 'Roboto, Regular',
                        },
                    },
                    labels: {
                        format: "", // '{value} ltr',
                        style: {
                            color: "", // "#7cb5ec"
                        }
                    },
                    opposite: true
                }],
                plotOptions: {
                    column: {
                        depth: 25
                    }
                },
                tooltip: {
                    shared: true,
                    backgroundColor: {
                        linearGradient: [0, 0, 0, 60],
                        stops: [
                            [0, '#FFFFFF'],
                            [1, '#FFFFFF']
                        ]
                    },
                    seriesData: [],
                    // headerFormat: '<span style="font-size: 14px;"><strong>{point.key} {point.series.xAxis.userOptions.title.text} </strong></span><br/>',
                    formatter: function () {
                        let fistSeries = this.points[0].series.tooltipOptions.seriesData[0];
                        let secondSeries = this.points[0].series.tooltipOptions.seriesData[1];
                        return '<b>' + this.x + ' ' + this.points[0].series.xAxis.userOptions.title.text + '</b><br/>' +
                            '<span style="color:' + fistSeries.color + '">\u25CF</span> ' + fistSeries.name + ': <b>' + fistSeries.data[this.points[0].point.x] + ' ' + fistSeries.tooltip.valueSuffix + '</b><br/>' +
                            '<span style="color:' + secondSeries.color + '">\u25CF</span> ' + secondSeries.name + ': <b>' + secondSeries.data[this.points[0].point.x] + ' ' + secondSeries.tooltip.valueSuffix + '</b><br/>' +
                            '<span style="color:#fff">\u25CF</span> Mileage: <b>' + ((secondSeries.data[this.points[0].point.x] > 0) ? Number((fistSeries.data[this.points[0].point.x] / secondSeries.data[this.points[0].point.x]).toFixed(2)) : '') + ' ' + fistSeries.tooltip.valueSuffix + '/' + secondSeries.tooltip.valueSuffix.trim() + '</b>'
                    },
                    borderWidth: 0,
                    style: {
                        color: '#333333',
                        fontSize: "14px",
                        fontFamily: "Roboto",
                    },
                },
                legend: {
                    enabled: false,
                },
                series: []
            }
        }
    },
    created() {
        this.getDistanceFuelChartData();
    },
    methods: {
        toggleSeries(id) {
            this.$refs.distanceFuelchart.chart.series[id].setVisible(!this.$refs.distanceFuelchart.chart.series[id].visible);
        },
        getDistanceFuelChartData() {
            let self = this;
            self.loaderHide = false;
            //.log(getters.getFilterParams());
            // dashboardService.getWidgetData(this.widgetData.api, getters.getFilterParams() ).then(returnResult => {  
            setTimeout(() => {
                const returnResult = {
                    "id": "DistanceFuelchart",
                    "data": {
                        "chartTitle": "Distance vs Fuel",
                        "categories": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                        "xAxisTitle": "2020",
                        "series": [{
                            "name": "Distance",
                            "type": "column",
                            "yAxis": 0,
                            "data": [9.9, 51, 96.4, 59.2, 111, 164, 154.6, 48.5, 26.4, 94.1, 195.6, 154.4],
                            "color": "#434348",
                            "tooltip": {
                                "valueSuffix": " KM"
                            }
                        }, {
                            "name": "Fuel",
                            "type": "column",
                            "yAxis": 1,
                            "data": [49.9, 71.5, 106.4, 79.2, 144.0, 176.0, 135.6, 148.5, 56.4, 194.1, 195.6, 174.4],
                            "color": "#7CB5EC",
                            "tooltip": {
                                "valueSuffix": " ltr"
                            }
                        }]
                    }
                };
                self.chartTitle = returnResult.data.chartTitle;
                self.chartOptions.xAxis.categories = returnResult.data.categories;
                self.chartOptions.xAxis.title.text = returnResult.data.xAxisTitle;
                self.chartOptions.series = returnResult.data.series;
                self.chartOptions.tooltip.seriesData = returnResult.data.series,

                    // y-axis label and color
                    returnResult.data.series.forEach((element, index) => {
                        self.chartOptions.yAxis[index].labels.style.color = element.color;
                        self.chartOptions.yAxis[index].labels.format = "{value}" + element.tooltip.valueSuffix;
                        self.chartOptions.yAxis[index].title.text = element.name;
                        self.chartOptions.yAxis[index].title.style.color = element.color;
                    });

                self.loaderHide = true;
            }, 4000)
            /* .catch(err => {
                            self.loaderHide=true
                            self.hideloaderClass = 'cd-chart-ifDataBlank'
                        }) */
            ;
        }
    }
};

</script>


<style scoped="scoped">
    /*
=============================================================
Please put your custom CSS below (CTrack BS Row Structure) 
============================================================
*/
    /*CTrack BS Row Structure (Please don't change or use this CSS)*/
.ctrack-row{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-right:-10px;margin-left:-10px;}
[class*="ctrack-col-"]{position:relative;width:100%;min-height:1px;padding-right:10px;padding-left:10px;}
.ctrack-d-flex{display:-webkit-box!important;display:-ms-flexbox!important;display:flex!important;}
.ctrack-align-items-center{-webkit-box-align:center!important;-ms-flex-align:center!important;align-items:center!important;}
.ctrack-justify-content-center{-webkit-box-pack:center!important;-ms-flex-pack:center!important;justify-content:center!important;}
.ctrack-h-100{height:100%;}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.ctrack-col-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}

@media only screen and (min-width:576px){
.ctrack-col-sm-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-sm-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-sm-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-sm-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-sm-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-sm-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-sm-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-sm-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-sm-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-sm-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-sm-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-sm-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}

@media only screen and (min-width:768px){
.ctrack-col-md-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-md-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-md-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-md-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-md-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-md-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-md-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-md-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-md-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-md-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-md-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-md-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}
@media only screen and (min-width:992px){
.ctrack-col-lg-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-lg-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-lg-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-lg-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-lg-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-lg-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-lg-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-lg-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-lg-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-lg-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-lg-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-lg-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}

@media only screen and (min-width:1200px){
.ctrack-col-xl-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-xl-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-xl-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-xl-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-xl-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-xl-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-xl-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-xl-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-xl-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-xl-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-xl-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-xl-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}
/*CTrack BS Row Structure (Please don't change or use above CSS)*/
 /*=============================================================
 Start your custom CSS from here
 ============================================================*/

/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');*{font-family:'Roboto',sans-serif;}
.ctrack-h-100{height:100%;}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.cd-chart-innerbox{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;width:100%;height:100%;background-color:#FFF;border-radius:5px;align-content:space-between;-webkit-align-content:space-between;}
.cd-chart-box-middle{padding:20px 30px;-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
.cd-chart-box-bottom{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;border-top:1px solid #E8E8E8;margin-top:20px;padding:18px 0 0;}
.cd-chart-box-top{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;padding:15px 20px;border-bottom:1px solid #E8E8E8;}
.cd-chart-box-img{text-align:center;}
.cd-chart-indicator-style1{padding:0 35px 8px;color:#333333;display:inline-block;width:100%;font-size:15px;font-weight:500;}
.cd-chart-indicator-inner{display:inline-block;width:100%;position:relative;padding-left:32px;margin-bottom:12px;}
.cd-chart-indicator-inner .cd-chart-indicator-color{position:absolute;left:0;top:50%;width:25px;height:11px;border-radius:3.5px;transform:translateY(-50%);}

/* Rounded Toggle Button*/
.ctrack-switch{position:relative;display:inline-block;vertical-align:middle;width:31px;height:19px;}
.ctrack-switch input{opacity:0;width:0;height:0;}
.ctrack-slider{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:#D7D7D7;-webkit-transition:0.4s;transition:0.4s;}
.ctrack-slider:before{position:absolute;content:"";height:15px;width:15px;left:2px;bottom:2px;background-color:white;-webkit-transition:0.4s;transition:0.4s;}
.ctrack-switch input:checked + .ctrack-slider{background-color:#40C1AC;}
.ctrack-switch input:focus + .ctrack-slider{box-shadow:0 0 1px #002B49;}
.ctrack-switch input:checked + .ctrack-slider:before{-webkit-transform:translateX(12px);-ms-transform:translateX(12px);transform:translateX(12px);}
.ctrack-slider.ctrack-round{border-radius:10px;}
.ctrack-slider.ctrack-round:before{border-radius:50%;}
.cd-chart-box-header{display:table;width:100%;}
.cd-chart-box-header > div{display:table-cell;}
.cd-chart-box-col2{text-align:right;}
.cd-chart-box-col1 h3{color:#013C5E;font-size:18px;font-weight:700;}
.cd-chart-box-col1 h3 span{color:#979797;font-weight:400;}
.cd-chart-box-col2{font-size:14px;font-weight:bold;color:#333333;}
.cd-chart-indicator-style2{display:inline-block;width:100%;text-align:center;padding:0 25px 15px;}
.driver-rating-indicator-icon{display:inline-block;width:100%;color:#D7D7D7;}
.driver-rating-indicator-title{display:inline-block;width:100%;color:#333333;font-weight:500;}
.driver-rating-indicator-value{display:inline-block;width:100%;color:#999999;font-size:17px;font-weight:500;}
.indicator-success-color{color:#8BC53F;font-size:26px;font-weight:500;}
.driver-rating-indicators{display:inline-block;width:100%;}
.d-chart-box-inner-left{display:inline-block;width:100%;height:100%;padding:12px 0 12px 0;}
.cd-chart-box-inner-right{display:inline-block;width:100%;height:100%;border-left:1px solid #EBEBEB;padding:0 30px 0 20px;}
.cd-chart-box-right-title{display:inline-block;width:100%;color:#CCCCCC;font-size:15px;font-weight:500;text-transform:uppercase;letter-spacing:3px;margin:18px 0 25px;}
.cd-chart-indicator-right{display:inline-block;width:100%;}
.cd-chart-indicator-right .cd-chart-indicator-inner{padding:0 35px 0 40px;color:#333333;font-size:15px;font-weight:500;margin-bottom:30px;position:relative;}
.cd-chart-indicator-right .cd-chart-indicator-inner:before{content:"";position:absolute;bottom:-15px;left:0;width:100%;height:1px;background-color:#EBEBEB;}
.cd-chart-indicator-right .ctrack-right-toggle-btns{position:absolute;right:0;top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%);}
.ctrack-d-flexcol{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}

/* Rounded Toggle Button*/
.ctrack-switch{position:relative;display:inline-block;vertical-align: middle;width:31px;height:19px;}
.ctrack-switch input{opacity:0;width:0;height:0;}
.ctrack-slider{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:#D7D7D7;-webkit-transition:.4s;transition:.4s;}
.ctrack-slider:before{position:absolute;content:"";height:15px;width:15px;left:2px;bottom:2px;background-color:white;-webkit-transition:.4s;transition:.4s;}
.ctrack-switch input:checked + .ctrack-slider{background-color:#40C1AC;}
.ctrack-switch input:focus + .ctrack-slider{box-shadow:0 0 1px #002B49;}
.ctrack-switch input:checked + .ctrack-slider:before{-webkit-transform:translateX(12px);-ms-transform:translateX(12px);transform:translateX(12px);}
.ctrack-slider.ctrack-round{border-radius:10px;}
.ctrack-slider.ctrack-round:before{border-radius:50%;}


.subLegend{display:inline-block;width:100%;font-weight:bold;margin:4px 0 15px;color:#656565;}
.subLegendMain ~ div[class*="ctrack-"] .cd-chart-indicator-inner{margin-bottom:20px;}
.subLegendMain ~ div[class*="ctrack-"] .cd-chart-indicator-inner::before{display:none;}

/* Toggle in Bottom of the charts*/
.cd-chart-toggles-main{display:inline-block;width:100%;padding:0 20px 20px;}
.cd-chart-toggles-main h5{font-size:16px;color:#333333;font-weight:500;}
.cd-chart-check-toggles{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.md-chart-toggle-repeater{position:relative;width:100%;min-height:1px;padding-right:15px;padding-top:10px;-ms-flex-preferred-size:0;flex-basis:0;max-width:100%;white-space:nowrap;}
.md-chart-toggle-repeater .md-chart-toggle-label{display:inline-block;vertical-align:middle;padding-right:8px;font-size: 15px;color: #8a8a8a;}
.md-chart-toggle-repeater .md-chart-toggle-label:last-child{padding-right:0;}

.cd-chart-check-toggles.cd-worst-best-section{display:inline-block;vertical-align:middle;}
.cd-worst-best-section .md-chart-toggle-repeater{padding:0;}
.cd-worst-best-section .md-chart-toggle-repeater .ctrack-switch + .md-chart-toggle-label{padding-left:8px;color:#002B49;}
.cd-worst-best-section .md-chart-toggle-repeater .md-chart-toggle-label{font-size:14px;font-weight:400;color:#ED1C24;}
.cd-worst-best-section .ctrack-switch input:checked + .ctrack-slider{background-color:#40C1AC;}
.cd-worst-best-section .ctrack-slider{background-color:#ED1C24;}

/* Responsive CSS */
@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){
.chart-with-right-widget > .ctrack-col-xl-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 73.666667%;max-width:73.666667%;}
.chart-with-right-widget > .ctrack-col-xl-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 26.333333%;max-width:26.333333%;}
}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1399px){}
@media only screen and (max-width:1299px){}
@media only screen and (max-width:1199px){}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){
.d-chart-box-inner-left{padding-right:15px;}
.cd-chart-box-inner-right{padding:0 15px;}
.cd-chart-box-right-title{margin-top:10px;}
.cd-chart-indicator-right .ctrack-col-12:last-child .cd-chart-indicator-inner:before{display:none;}
}

@media only screen and (max-width:767px){}
@media only screen and (max-width:575px){
.cd-chart-box-middle{padding:15px;}
}

</style>