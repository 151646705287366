<template>
  <div class="ctrack-md-dialog-content filterMainBoxSec">
    <div class="ctrack-modal-header topFilterHeaderM">
      <h4>{{$t("Dashboard.globalFilters")}}</h4>
      <div class="ctrack-col-6 ctrack-text-right applyBtnHdFilter">
        <button type="submit" class="md-btn-style2" @click="submitFilter">
          {{$t("Dashboard.apply")}}
        </button>
      </div>
    </div>
    <div class="ctrack-modal-body">
      <div class="md-filter-modal-form">
        <div class="ctrack-row">
          <div class="ctrack-col-12">
            <div class="md-filter-form-froup">
              <label class="md-filter-form-lbl">{{$t("Dashboard.businessGroup")}}</label>
              <div class="md-selectbox-style2 ctrack-select-icon1">
                <md-field>
                  <md-select
                    v-model="businessGroup"
                    md-class="md-filter-selectMenu globalmenuSelectF"
                    name="GlobalbusinessGroup"
                    id="GlobalbusinessGroup"
                    multiple
                    placeholder="Select"
                    md-dense
                    @md-selected="selectAll('Group')"
                    @md-closed="removeSelectGroupValue('Group')"
                    @md-opened="addSelectGroupValue('Group')"
                  >
                    <md-field class="search-group" md-clearable>
                      <md-input v-model="groupSearch" :placeholder="$tc('Dashboard.searchGroup')"></md-input>
                    </md-field>
                    <!-- <md-option
                      value="All"
                      v-if="groupSearch=='' || ('all').includes(groupSearch.toLowerCase())"
                    >{{$t("Dashboard.all")}}</md-option> -->
                    <md-option
                      v-for="(item, index) in filteredBusinessGroupArray"
                      :key="item.id"
                      :value="((index == 0) && (item.groupName.indexOf(`(${$t('Dashboard.all')})`)   != -1 )) ? 'All' : item.id"
                    >{{item.groupName}}</md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-filter-labels">
                <label
                  class="md-filter-label-items"
                  v-for="(itemData, index) in businessGroupName"
                  :key="index"
                >
                  {{ itemData.groupName }}
                  <span
                    class="md-filter-label-close"
                    v-if="itemData.id=='All'"
                    @click="removeBusinessGroup('all')"
                  >
                    <svg
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                    >
                      <path
                        d="M1100.707,327.293a1,1,0,1,1-1.414,1.414L1097,326.414l-2.293,2.293a1,1,0,0,1-1.414-1.414l2.293-2.293-2.293-2.293a1,1,0,0,1,1.414-1.414l2.293,2.293,2.293-2.293a1,1,0,0,1,1.414,1.414L1098.414,325Z"
                        transform="translate(-1093 -321)"
                      />
                    </svg>
                  </span>
                  <span class="md-filter-label-close" v-else @click="removeBusinessGroup(index)">
                    <svg
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                    >
                      <path
                        d="M1100.707,327.293a1,1,0,1,1-1.414,1.414L1097,326.414l-2.293,2.293a1,1,0,0,1-1.414-1.414l2.293-2.293-2.293-2.293a1,1,0,0,1,1.414-1.414l2.293,2.293,2.293-2.293a1,1,0,0,1,1.414,1.414L1098.414,325Z"
                        transform="translate(-1093 -321)"
                      />
                    </svg>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="ctrack-row">
          <div class="ctrack-col-12">
            <div class="md-filter-form-froup ctrack-modal-btns">
              <div class="ctrack-row ctrack-align-items-center">
                <div class="ctrack-col-6 ctrack-text-left">
                  <button type="reset" class="md-btn-style1" @click="setDefaultFilters">Reset</button>
                </div>
                <div class="ctrack-col-6 ctrack-text-right">
                  <button type="submit" class="md-btn-style2" @click="submitFilter">{{$t("Dashboard.apply")}}</button>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
//import GroupManangementService from "../../../service/groupmanagement.service";
//import { mutations, getters } from "../shared/observeDashboardFilter";
export default {
  name: "filtermodal",
  components: {},
  data: function() {
    return {
      boolean: true,
      businessGroup: [],
      businessGroupName: [],
      FilterData: {
        businessMechanism: [],
        driverMechanism: [],
        vehicalTypeMechanism: [],
        groupsHierarchies: []
      },
      //businessMechanism: this.FilterData.businessMechanism,
      businessMechanism: [],
      getFilterData: {},
      groupsHierarchies: [],
      groupSearch: "",
      breadcrumbList: []
    };
  },
  props: {
    // FilterData : Array
  },
  watch: {
    businessGroup: function(a, b) {
      let _array = new Array();
      let _array1 = new Array();
      this.businessGroupChecked = 0;
      _array = $.grep(a, function(item) {
        return $.inArray(item, b) < 0;
      });
      if (_array.includes("All")) {
        this.businessGroupChecked = 1;
      }
      _array1 = $.grep(b, function(item) {
        return $.inArray(item, a) < 0;
      });
      if (_array1.includes("All")) {
        this.businessGroupChecked = 2;
      }
    }
  },
  updated() {
    let self = this;
    setTimeout(() => {
      if (self.businessGroup.includes("All")) {
        // "All"
        $(".md-select-value[name=GlobalbusinessGroup]").val(
          this.$t("Dashboard.all")
          );
      } else {
        $(".md-select-value[name=GlobalbusinessGroup]").val(
          self.businessGroup.length + '+ ' +  this.$t("ReportGeneration.selected")
        );
      }
    }, 10);
  },
  mounted() {
    let self = this;
    new Promise(function(resolve, reject) {
      self.FilterData.groupsHierarchies = JSON.parse(
        localStorage.getItem("groupsHierarchies")
      );
      self.FilterData.businessMechanism = JSON.parse(
        localStorage.getItem("globalBusinessGroups")
      );
      self.FilterData.businessMechanism[0].groupName = self.FilterData.businessMechanism[0].groupName + ' (' + self.$t("Dashboard.all") + ')';
      resolve(true);
    }).then(results => {
      // let selectedFilters = getters.getFilterParams();
      let selectedFilters = JSON.parse(
        localStorage.getItem("GlobalFiltersData")
      );
      if (!selectedFilters) {
        selectedFilters = { businessGroups: [] };
        self.setDefaultFilters();
      }
      
      new Promise(function(resolve, reject) {
        if (selectedFilters.businessGroups.length) {
          let businessGroupName = [...selectedFilters.businessGroupName];
          if (businessGroupName[0].id == "All") {
            self.FilterData.businessMechanism = JSON.parse(
              localStorage.getItem("globalBusinessGroups")
            );
            self.FilterData.businessMechanism[0].groupName = self.FilterData.businessMechanism[0].groupName + ' (' + self.$t("Dashboard.all") + ')';
            self.businessMechanism = JSON.parse(
              localStorage.getItem("globalBusinessGroups")
            );
            self.businessMechanism[0].groupName = self.businessMechanism[0].groupName + ' (' + self.$t("Dashboard.all") + ')';
          } else {
            // update array for all checkbox selection
            self.FilterData.businessMechanism = JSON.parse(
              localStorage.getItem("globalBusinessGroups")
            );
            self.FilterData.businessMechanism[0].groupName = self.FilterData.businessMechanism[0].groupName + ' (' + self.$t("Dashboard.all") + ')';
            self.businessMechanism = [...selectedFilters.businessGroupName];
          }
          let tempGroupArr = JSON.parse(
            localStorage.getItem("globalBusinessGroups")
          );
          if (selectedFilters.businessGroups.length == tempGroupArr.length) {
            self.businessGroup.push("All");
          } else {
            self.businessGroup = [...selectedFilters.businessGroups];
          }
        }
        resolve(true);
      }).then(results => {});
    });
  },
  computed: {
    filteredBusinessGroupArray: function() {
      let PATTERN = this.groupSearch,
        filtered = this.FilterData.businessMechanism.filter(function(str) {
          return str.groupName.toLowerCase().includes(PATTERN.toLowerCase());
        });
      return filtered;
    }
  },
  methods: {
    addSelectGroupValue(type) {
      let self = this;
      if (type == "Group") {
        let tempGroupArr = JSON.parse(
          localStorage.getItem("globalBusinessGroups")
        );
        if (self.businessMechanism.length < tempGroupArr.length) {
          self.FilterData.businessMechanism = JSON.parse(
            localStorage.getItem("globalBusinessGroups")
          );
          self.FilterData.businessMechanism[0].groupName = self.FilterData.businessMechanism[0].groupName + ' (' + self.$t("Dashboard.all") + ')';
          self.businessMechanism = self.FilterData.businessMechanism;
        }
        if (self.businessGroup.includes("All")) {
          self.businessGroup = [];
          self.businessMechanism.forEach(element => {
            self.businessGroup.push(element.id);
          });
          self.businessGroup.push("All");
        }
      }
    },
    removeSelectGroupValue(type) {
      let self = this;
      if (type == "Group") {
        if (self.businessGroup.includes("All")) {
          setTimeout(() => {
            // "All"
            $(".md-select-value[name=GlobalbusinessGroup]").val(this.$t("Dashboard.all"));
          }, 10);
        } else {
          setTimeout(() => {
            $(".md-select-value[name=GlobalbusinessGroup]").val(
              self.businessGroup.length +  '+ ' +  this.$t("ReportGeneration.selected")
            );
          }, 10);
        }
        if (self.businessGroup.includes("All")) {
          self.businessGroup = [];
          self.businessGroup.push("All");
        }
      }
    },
    setDefaultFilters() {
      let self = this;
      self.businessGroup = [];
      self.businessMechanism.forEach((element, indx) => {
        self.businessGroup.push(element.id);
      });
      self.businessGroup.push("All");
    },
    selectAll(type, stateVal = "N") {
      let self = this;
      if (type == "Group") {
        if (self.businessGroup.length) {
          let businessGroupLength = this.businessGroup.length;
          let businessMechanismLength = this.businessMechanism.length;
          if (
            this.businessGroup.includes("All") &&
            this.businessGroupChecked == 1 &&
            this.businessGroup.slice(-1)[0] == "All"
          ) {
            self.businessGroup = [];
            let tempbusinessGroupArr = [];
            self.businessMechanism.forEach(element => {
              tempbusinessGroupArr.push(element.id);
            });
            tempbusinessGroupArr.push("All");
            self.businessGroup = tempbusinessGroupArr;
            return false;
          } else if (
            !this.businessGroup.includes("All") &&
            this.businessGroupChecked == 2
          ) {
            self.businessGroup = [];
            return false;
          }
          if (
            self.businessGroup.includes("All") &&
            this.businessGroupChecked == 0
          ) {
            businessGroupLength -= 1;
            if (
              businessGroupLength < businessMechanismLength &&
              businessGroupLength > 0
            ) {
              let filteredbusinessGroup = [...self.businessGroup];
              let index = filteredbusinessGroup.indexOf("All");
              filteredbusinessGroup.splice(index, 1);
              let index2 = self.businessGroup.indexOf("All");
              self.businessGroup.splice(index2, 1);
              self.businessGroup = filteredbusinessGroup;
            }
          }
          // add condition for select all checkbox if all item are selected
          else if(businessGroupLength == self.FilterData.businessMechanism.length)
          {            
            self.businessGroup = [];
            let tempbusinessGroupArr = [];
            self.businessMechanism.forEach(element => {
              tempbusinessGroupArr.push(element.id);
            });
            tempbusinessGroupArr.push("All");
            self.businessGroup = tempbusinessGroupArr;
            return false;
          }
        }

        let businessGroupLength = self.businessGroup.length;
        let businessMechanismLength = self.businessMechanism.length;

        self.businessGroupName = [];
        if (self.businessGroup.includes("All")) {
          self.businessGroupName.push({ id: "All", groupName: self.filteredBusinessGroupArray[0].groupName });
        } else {
          self.businessGroup.forEach((element, indx) => {
            var businessGrArr = self.businessMechanism.filter(function(x) {
              return x.id === element;
            });
            if (businessGrArr)
              self.businessGroupName.push({
                id: businessGrArr[0].id,
                groupName: businessGrArr[0].groupName
              });
          });
        }
        return false;
      }
    },
    resetFilter() {
      //   this.businessGroup=[];
      //   this.vehicalTypeGroup=[];
      //   this.driverGroup=[];
      //   this.milesNumber=1000;
      //   this.milesfilter= "miles"
    },
    removeBusinessGroup(index) {
      let self = this;
      new Promise(function(resolve, reject) {
        if (index == "all") {
          self.businessGroup = [];
          self.businessGroupName = [];
        } else {
          self.$delete(self.businessGroupName, index);
          self.$delete(self.businessGroup, index);
        }
        resolve(true);
      }).then(results => {});
    },
    submitFilter() {
      let self = this;
      let filteredbusinessGroup = [...self.businessGroup];

      if (self.businessGroup.includes("All")) {
        filteredbusinessGroup = [];
         self.FilterData.businessMechanism.forEach(element => {
          filteredbusinessGroup.push(element.id);
        });
      }

      let sentFilterData = {
        businessGroups: filteredbusinessGroup,
        businessGroupName: self.businessGroupName,
        isAll:self.businessGroup.includes("All")?true:false
      };

      // mutations.setDashboardFilters(sentFilterData);
      this.$emit("getGlobalGroupsFilters", sentFilterData);
    }
  }
};
</script>
<style scoped="scoped">
/*
=============================================================
Please put your custom CSS below (CTrack BS Row Structure) 
============================================================
*/
/*CTrack BS Row Structure (Please don't change or use this CSS)*/

/*=======Custom CSS======*/
.topFilterHeaderM h4{
  display: table-cell;
  vertical-align: middle;
  width: auto;
}
.applyBtnHdFilter {
    display: table-cell;
    vertical-align: middle;
    padding: 0 !important;
    width: auto !important; 
    float: right;
}
.topFilterHeaderM{
  display: table !important;
}
.ctrack-md-globleGroup-modal .ctrack-md-dialog-content.filterMainBoxSec {
    background-color: white;
}

@media only screen and (max-width: 767px) {
  .ctrack-md-globleGroup-modal .ctrack-md-dialog-content.filterMainBoxSec {
      width: 100% !important;
  }
  .applyBtnHdFilter {
    float: inherit;
  }

}




/*=======Custom CSS======*/

.ctrack-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}
[class*="ctrack-col"] {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}
.ctrack-d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.ctrack-align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.ctrack-justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.ctrack-h-100 {
  height: 100%;
}
.ctrack-text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ctrack-col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.ctrack-col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.ctrack-col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.ctrack-col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.ctrack-col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.ctrack-col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.ctrack-col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.ctrack-col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.ctrack-col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.ctrack-col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}
.ctrack-col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.ctrack-col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.ctrack-col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

@media only screen and (min-width: 576px) {
  .ctrack-col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .ctrack-col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .ctrack-col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ctrack-col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .ctrack-col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .ctrack-col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ctrack-col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .ctrack-col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .ctrack-col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ctrack-col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .ctrack-col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .ctrack-col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .ctrack-col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .ctrack-col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .ctrack-col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ctrack-col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .ctrack-col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .ctrack-col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ctrack-col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .ctrack-col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .ctrack-col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ctrack-col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .ctrack-col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .ctrack-col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media only screen and (min-width: 992px) {
  .ctrack-col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .ctrack-col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .ctrack-col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ctrack-col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .ctrack-col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .ctrack-col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ctrack-col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .ctrack-col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .ctrack-col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ctrack-col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .ctrack-col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .ctrack-col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media only screen and (min-width: 1200px) {
  .ctrack-col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .ctrack-col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .ctrack-col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ctrack-col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .ctrack-col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .ctrack-col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ctrack-col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .ctrack-col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .ctrack-col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ctrack-col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .ctrack-col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .ctrack-col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
/*CTrack BS Row Structure (Please don't change or use above CSS)*/
/*
=============================================================
Start your custom CSS from here
============================================================
*/

/* Custom CSS */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap");
* {
  font-family: "Roboto", sans-serif;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
.md-bg-gray-light {
  background-color: #eef1f6;
}
.md-dashboard-container {
  float: left;
  width: 100%;
  padding: 0 30px;
}
.md-dashboard-header {
  padding: 15px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.md-dashboard-col2 {
  text-align: right;
}
.md-header-right,
.md-header-links,
.md-header-filter,
.md-header-label,
.md-header-right a,
.md-dashboard-menu li,
.md-dashboard-menu li a,
.md-dashboard-menu li a svg,
.md-header-right a svg,
.md-dashboard-menu {
  vertical-align: middle;
  display: inline-block;
}
.md-dashboard-header h1 {
  font-size: 22px;
  color: #293042;
  font-weight: 500;
}
.md-dashboard-menu {
  padding: 0;
}
.md-dashboard-menu li {
  margin: 0 15px;
}
.md-dashboard-menu li:last-child {
  margin-right: 35px;
}
.md-dashboard-menu li a {
  color: #959595;
}
.md-dashboard-menu li a i {
  font-size: 25px;
  vertical-align: middle;
}
.md-dashboard-menu li a:hover,
.md-dashboard-menu li a.active,
.md-dashboard-menu li a.router-link-active {
  color: #002B49;
}
.md-header-right a {
  color: #333333;
}
.md-header-label {
  color: #333333;
  padding-right: 5px;
}
.md-header-links a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #fff;
  text-align: center;
  border-radius: 100%;
  margin: 0 5px;
}
.md-header-links a:hover,
.md-header-links a.active {
  color: #fff;
  background-color: #002B49;
}
.md-header-links {
  position: relative;
  padding: 0 20px;
  margin: 0 20px;
}
.md-header-links:before,
.md-header-links:after {
  content: "";
  position: absolute;
  top: 10%;
  height: 80%;
  width: 1px;
  background: rgba(0, 0, 0, 0.3);
}
.md-header-links:before {
  left: 0;
}
.md-header-links:after {
  right: 0;
}
.md-header-right .md-header-filter a {
  color: #013c5e;
}
.md-header-right .md-header-filter a:hover {
  color: #002B49;
}
.md-header-right .md-header-filter a svg {
  display: inline-block;
  vertical-align: middle;
}
.md-pl-0 {
  padding: 0;
}

/*Modal*/
.ctrack-modal-main {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}
.ctrack-modal-main.ctrack-modal-show {
  opacity: 1;
  display: inline-block;
}
/* .ctrack-modal-fade{opacity:0;transition:opacity 0.15s linear;} */
.ctrack-modal-open .ctrack-modal-main {
  overflow-x: hidden;
  overflow-y: auto;
}
.ctrack-modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.ctrack-modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}
.ctrack-modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 10px;
  outline: 0;
  box-shadow: 1px 2px 16px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 1px 2px 16px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 1px 2px 16px rgba(0, 0, 0, 0.26);
}
.ctrack-md-modal {
  border-radius: 10px;
  outline: 0;
  box-shadow: 1px 2px 16px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 1px 2px 16px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 1px 2px 16px rgba(0, 0, 0, 0.26);
}
.ctrack-md-dialog-content {
  height: 100%;
  display: inline-block;
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.ctrack-modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.3);
  display: none;
}
.ctrack-modal-overlay-show {
  display: block;
}
.md-filter-modal-form {
  display: inline-block;
  width: 100%;
  padding-top: 5px;
}
.ctrack-modal-header,
.ctrack-modal-body {
  display: inline-block;
  width: 100%;
}
.ctrack-modal-body {
  padding: 20px;
}
.ctrack-modal-header {
  padding: 15px 20px;
  border-bottom: 1px solid #d9d9d9;
}
.ctrack-modal-header h4 {
  font-size: 18px;
  color: #013c5e;
  font-weight: 500;
}
.md-filter-form-froup {
  display: inline-block;
  width: 100%;
  position: relative;
}
.md-filter-form-lbl {
  display: inline-block;
  width: 100%;
  color: #333333;
  font-weight: 500;
  margin-bottom: 8px;
}
.md-selectbox-style2 {
  display: inline-block;
  width: 100%;
  position: relative;
}
.ctrack-select-icon1:before {
  content: "";
  position: absolute;
  top: 16px;
  right: 15px;
  z-index: 1;
  border: solid 5px #ffffff;
  border-left: solid 3px transparent;
  border-right: solid 3px transparent;
  border-bottom: transparent;
  -webkit-pointer-events: none;
  -moz-pointer-events: none;
  pointer-events: none;
}
.ctrack-select-icon1 select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.md-filter-modal-form .md-filter-form-froup {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.md-filter-modal-form .ctrack-align-items-center .md-filter-form-lbl {
  margin-bottom: 0;
}
.md-filter-modal-form .md-filter-form-froup.ctrack-modal-btns {
  border: 0;
  margin: 0;
  padding: 10px 0 5px;
}
.md-btn-style1,
.md-btn-style2 {
  font-size: 14px;
  color: #002B49;
  font-weight: 500;
  padding: 10px;
  text-align: center;
  min-width: 85px;
  outline: 0;
  background-color: #fff;
  border: 1px solid #002B49;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}
.md-btn-style1 {
  border-color: #cce6f4;
  background-color: #cce6f4;
}
.md-btn-style1:hover {
  border-color: #002B49;
  background-color: #fff;
}
.md-btn-style2:hover {
  color: #fff;
  background-color: #002B49;
}
.ctrack-text-right {
  text-align: right;
}
.ctrack-text-left {
  text-align: left;
}
.md-header-filter-open {
  max-width: 380px;
  left: inherit;
  right: 20px;
  top: 140px;
  transform: none;
  margin: 0;
}
.ctrack-modal-open {
  overflow: hidden;
}
.md-number-field {
  border: 1px solid #eef1f6;
  background-color: #eef1f6;
  outline: 0;
  border-radius: 3px;
  padding: 5px 0 5px 5px;
  font-size: 12px !important;
  color: #333333;
  font-weight: 500;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  height: 24px;
  max-width: 100%;
}
input[type="number"]::-webkit-inner-spin-button {
  opacity: 1;
}
.ctrack-md-rangeSlider {
  padding: 30px 0 5px;
}

/* Custom Rectangle & Circle CheckBox*/
.md-rectangle-checkbox-main,
.md-circle-checkbox-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.md-rCheck-repeater,
.md-cCheck-repeater {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  width: 100%;
  min-height: 1px;
}
.md-rectangle-checkbox,
.md-circle-checkbox {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.md-rectangle-checkbox-main {
  margin-left: -8px;
  margin-right: -8px;
  padding: 10px 0;
}
.md-rCheck-repeater {
  padding-left: 8px;
  padding-right: 8px;
}
.md-rectangle-check-text {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  background-color: #fff;
  border: 1px solid #999999;
  color: #999999;
  text-align: center;
  border-radius: 5px;
  padding: 9px;
  cursor: pointer;
}
.md-rectangle-checkbox input[type="checkbox"],
.md-rectangle-checkbox input[type="radio"],
.md-circle-checkbox input[type="checkbox"] {
  display: none;
}
.md-cCheck-repeater {
  text-align: center;
}
.md-cCheck-repeater .md-circle-checkbox {
  width: auto;
}
.md-circle-check-text {
  display: inline-block;
  width: 36px;
  height: 36px;
  background-color: #fff;
  border: 1px solid #999999;
  color: #999999;
  border-radius: 100%;
  line-height: 36px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}
.md-circle-checkbox-lg .md-circle-check-text {
  width: 48px;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
}
.md-check-repeater-first {
  padding-right: 4px;
}
.md-check-repeater-first .md-circle-checkbox {
  padding: 10px 4px 10px 0px;
  border-right: 1px solid rgba(217, 217, 217, 0.5);
}
.md-mid-check-active.md-circle-checkbox .md-circle-check-text {
  background-color: #fff;
  border-color: #002B49;
  color: #002B49;
}
.md-rectangle-checkbox
  input[type="checkbox"]:checked
  ~ .md-rectangle-check-text,
.md-rectangle-checkbox input[type="radio"]:checked ~ .md-rectangle-check-text,
.md-circle-checkbox input[type="checkbox"]:checked ~ .md-circle-check-text {
  background-color: #002B49;
  border-color: #002B49;
  color: #ffffff;
}
.md-field.search-group.md-theme-default.md-has-placeholder {
  width: 80%;
  margin: 0 auto;
}
.md-menu-content {
  max-height: 35vh !important;
  min-height: 35vh;
  background: #fff;
  min-width: 200px;
  max-width: 360px !important;
}

.md-menu-content.md-select-menu .md-list {
  min-width: 240px;
  min-height: 190px;
}

/* Responsive CSS */
@media only screen and (min-width: 576px) {
  .ctrack-modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .ctrack-modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
}

@media only screen and (max-width: 1399px) {
  .md-dashboard-menu li {
    margin: 0 8px;
  }
  .md-dashboard-menu li:last-child {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1299px) {
  .md-dashboard-col2 {
    display: table;
    width: 100%;
  }
  .md-dashboard-col2 .md-dashboard-menu,
  .md-dashboard-col2 .md-header-right {
    display: table-cell;
  }
  .md-dashboard-col2 .md-dashboard-menu {
    text-align: left;
  }
  .md-dashboard-menu li:first-child {
    margin-left: 0;
  }
  .md-header-links:before {
    display: none;
  }
  .md-header-filter-open {
    right: 30px;
    top: 170px;
  }
}
@media only screen and (max-width: 1199px) {
  .md-header-links {
    padding: 0 10px;
    margin: 0 10px;
  }
  .md-header-links:before {
    display: block;
  }
}
@media only screen and (max-width: 1024px) {
  .md-header-links:before {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .md-header-links:before {
    display: block;
  }
  .md-dashboard-menu li {
    margin: 0 8px;
  }
  .md-header-links a {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
  .md-dashboard-container {
    padding: 0 20px;
  }
}
@media only screen and (max-width: 767px) {
  .md-dashboard-container {
    padding: 0 15px;
  }
  .md-dashboard-col2 {
    padding: 5px 0;
  }
  .md-dashboard-col2 .md-dashboard-menu,
  .md-dashboard-col2 .md-header-right,
  .md-dashboard-col1 {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  .md-header-right {
    margin-top: 15px;
  }
  .md-dashboard-menu li {
    margin: 0 6px;
  }
  .md-header-links {
    margin-left: 0;
    padding-left: 0;
  }
  .md-header-links:before {
    display: none;
  }
  .md-header-links a {
    width: 25px;
    height: 25px;
    line-height: 25px;
    margin: 0 3px;
  }
  .md-dashboard-col1 {
    border: solid 1px #c5cedc;
    padding: 2px;
    border-radius: 2px;
    margin-bottom: 5px;
    background-color: #fff;
  }
  .md-filter-modal-form .ctrack-align-items-center .md-filter-form-lbl {
    margin-bottom: 8px;
  }
  .md-header-filter-open {
    right: initial;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .ctrack-filterRange {
    padding-top: 40px;
    width: 100%;
    padding: 40px 15px 0;
  }
  .ctrack-md-rangeSlider {
    padding-top: 10px;
  }
  .md-filter-modal-form
    .ctrack-md-rangeSlider
    .ctrack-align-items-center
    .md-filter-form-lbl {
    margin-bottom: 0;
  }
  .md-rectangle-checkbox-main {
    padding: 0;
  }
  .md-check-repeater-first .md-circle-checkbox {
    padding: 5px 4px 5px 0px;
  }
  .md-number-field {
    height: 30px;
    padding-left: 10px;
  }
}
@media only screen and (max-width: 575px) {
  .md-dashboard-menu li {
    margin: 0 5px;
  }
  .md-dialog-fullscreen {
    max-width: 100%;
    max-height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    transform: none;
  }
  .md-circle-checkbox-main {
    padding-left: 60px;
    position: relative;
  }
  .md-check-repeater-first {
    position: absolute;
    left: 0;
    width: auto;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
  }
  .md-cCheck-repeater {
    padding: 3px 5px;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
</style>
<style>

/*-----Custom CSS-----*/
/*body .md-filter-selectMenu.globalmenuSelectF {
    max-height: 23vh !important;
}*/
body .md-filter-selectMenu.globalmenuSelectF {
    top: 240px !important;
}

/*-----Custom CSS-----*/

/*Filter */
.md-selectbox-style2 .md-input {
  font-size: 14px !important;
  -webkit-text-fill-color: #fff !important;
  color: #fff !important;
  font-weight: 500;
  background-color: #002B49;
  border-radius: 5px;
  outline: 0;
  border: 0;
  padding: 10px 30px 10px 10px;
  width: 100%;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  height: 40px;
}
.md-filter-form-froup .md-field {
  min-height: initial;
  margin: 0;
  padding: 0;
}
.md-filter-form-froup .md-field:before,
.md-filter-form-froup .md-field:after,
.md-filter-form-froup .md-field .md-icon,
.md-selectbox-style2 .md-icon {
  display: none;
}
.md-filter-form-froup .ctrack-select-white .md-input {
  -webkit-text-fill-color: #fff !important;
}
.md-filter-labels {
  display: inline-block;
  width: 100%;
}
.md-filter-label-items {
  display: inline-block;
  position: relative;
  padding: 3px 30px 3px 10px;
  background-color: #e1e1e1;
  color: #8f8f8f;
  font-size: 12px;
  font-weight: 500;
  border-radius: 3px;
  margin: 10px 10px 0 0;
}
.md-filter-label-close {
  position: absolute;
  right: 10px;
  top: 7px;
  cursor: pointer;
  transition: all 0.5s ease;
}
.md-filter-label-close svg {
  display: block;
}
.md-filter-label-close:hover {
  color: #ff242d;
}
.md-filter-selectMenu.md-menu-content {
  max-height: 35vh !important;
  min-height: 100px;
  background: #fff;
  min-width: 200px;
  max-width: 360px !important;
}
.md-list-item-text {
  white-space: normal;
}
.md-selectbox-style2 .md-list-item-text {
  color: #000000de;
}
.md-selectbox-style2 .md-input::placeholder {
  color: #fff !important;
  font-weight: 500 !important;
  font-family: "Roboto", sans-serif;
}
.md-selectbox-style2 .md-input:-ms-input-placeholder {
  color: #fff !important;
  font-weight: 500 !important;
  font-family: "Roboto", sans-serif;
}
.md-selectbox-style2 .md-input::-ms-input-placeholder {
  color: #fff !important;
  font-weight: 500 !important;
  font-family: "Roboto", sans-serif;
}
.md-selectbox-style2 .md-field.md-theme-default.md-has-value .md-input {
  -webkit-text-fill-color: #fff !important;
}
.ctrack-filterRange {
  display: block;
  width: 84%;
  margin: 0 auto;
}
.md-pl-0 {
  padding-left: 0;
}
.md-list-item-content {
  padding: 4px 5px;
}
.md-field.search-group.md-theme-default.md-has-placeholder:before {
  display: none;
}

@media only screen and (max-width: 575px) {
  .md-filter-selectMenu.md-menu-content {
    max-width: 85% !important;
  }
}
</style>