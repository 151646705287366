<template>

</template>

<script>
    export default {
        props: {
            value: Object
        },
    }
</script>