import _ from 'lodash';
import { LiveProductivityDashboardService, WorkingHoursService } from "@/services";
import { useGroupsFilterStore } from '@/stores/groups-store';
import { useDriverStore } from '@/stores/driver-store';
import { useAssetStore } from '@/stores/asset-store';

const state = () => ({
    context : 0,
    items: [],
    movements: [],
    workingHours: [],
    ids: []
})

const getters = {
}

const getIds = (store, args) => {

    if (args.selectedIds) {
        //selection Provided
        return new Promise((resolve, reject) => {
            resolve(args.selectedIds);
        });
    } else {
        //No Selection Provided
        //const selected = globalFilter.getGlobalFilterState(args.context == 0 ? "vehicles" : "drivers");
        const groupFilterStore = useGroupsFilterStore();
        const selected = args.context == 0 ? groupFilterStore.vehicles : groupFilterStore.drivers
        if (selected.length > 0 && selected.at(0) != "ALL") {
            return new Promise((resolve, reject) => {
                resolve(selected);
            });
        }
        if (args.allowAll == false) {
            return new Promise((resolve, reject) => {
                resolve([]);
            });
        };
        //All Entities are only allowed when mainTenant and no group policies and lists are less than 100.
        const driverStore = useDriverStore();
        const assetStore = useAssetStore();
        let data = (args.context == 0)
            ? assetStore.getAssetsSelectorData()
            : driverStore.getOperatorsSelectorData();
        if (data.length == 0) {
            const resolver = (args.context == 0)
                ? assetStore.getAssetSelectionData()
                : driverStore.getOperatorsSelectionData();
            return resolver.then(() => {
                return (args.context == 0)
                    ? assetStore.getAssetsSelectorData()
                    : driverStore.getOperatorsSelectorData();
            }).then((response) => {
                return _.map(response, c => c.id);
            });
        }
        return new Promise((resolve, reject) => {
            resolve(_.map(data, c => c.id));
        });
    };
};

const actions = {
    getAll({ commit, state }, args) {
        commit('setContext', args.context);
        return getIds(this, args).then((ids) => {
            commit('setIds', ids);
            var requests = [];
            var chunks = []
            let loadedIds = state.items.map(i => state.context === 0
                ? i.vehicleInfo
                    ? i.vehicleInfo.id
                    : null
                : i.driverInfo
                    ? i.driverInfo.id
                    : null
            );
            let pendingIds = ids.filter(i => !loadedIds.includes(i));
            //Group Filter
            const groupFilterStore = useGroupsFilterStore();
            if (state.context === 0) {
                if (!groupFilterStore.vehicles.includes('ALL')) {
                    pendingIds = pendingIds.filter(i => groupFilterStore.vehicles.includes(i))
                };
            } else {
                if (!groupFilterStore.drivers.includes('ALL')) {
                    pendingIds = pendingIds.filter(i => groupFilterStore.drivers.includes(i))
                };
            };
            if (pendingIds.length > 0) {
                    chunks = [...chunks, pendingIds.splice(0, 20)]
                };
            if (pendingIds.length > 0) {
                chunks = [...chunks, ..._.chunk(pendingIds, 50)]
            };
            for (let c = 0; c < chunks.length; c++) {
                let chunk = chunks[c];
                var request = LiveProductivityDashboardService.getAll(
                    args.startDate,
                    args.endDate,
                    this.state.lpd.movements,
                    args.context,
                    chunk).then((response) => {
                        const driverStore = useDriverStore();
                        let driversInStore = driverStore.getOperatorsSelectorData();
                        commit('setItems', response.map(i => {
                            if (i.driverInfo) {
                                let driverId = driversInStore.find(operator => operator.id == i.driverInfo.id)?.driverId
                                if (driverId) {
                                    i.driverInfo.driverId = driverId;
                                };
                            };
                            return i;
                        }
                        ));
                    });
                requests.push(request);
            }
            if (chunks.length != 0) {
                return Promise.all(requests)
            } else {
                return new Promise((resolve, reject) => {
                    resolve([]);
                });
            };

        });
    },
    getWorkingHours({ commit, state }, args) {
        const workingHours = (args.context == 0)
            ? WorkingHoursService.getVehicles
            : WorkingHoursService.getDrivers;
        let knownIds = state.workingHours.map(i => i.workingHourBasedOn == 'Vehicle' ? i.vehicleId : i.driverId);
        let pendingIds = state.ids.filter(i => !knownIds.includes(i));
        if (pendingIds.length > 0) {
            workingHours(pendingIds).then((response) => {
                commit('setWorkingHours', response);
            });
        };
    },
    getMovements({ commit }) {
        const movements = LiveProductivityDashboardService.getMovements();
        commit('setMovements', movements);
    },
    clearStore({ commit }) {
         commit('clearItems');
    },
}

const mutations = {
    setContext(state, context) {
        state.context = context;
    },
    setItems(state, items) {
        const exists = (value) => {
            if (state.context === 0 && value.vehicleInfo) {
                return state.items.filter(c => c.vehicleInfo && c.vehicleInfo.id === value.vehicleInfo.id).length > 0;
            } else if (value.driverInfo) {
                return state.items.filter(c => c.driverInfo && c.driverInfo.id === value.driverInfo.id).length > 0;
            }
            return false;
        };
        items.forEach(function (value) {
            if (!exists(value)) state.items.push(value);
        });
    },
    setMovements(state, movements) {
        state.movements = movements;
    },
    setWorkingHours(state, workingHours) {
        let knownIds = state.workingHours.map(i => i.workingHourBasedOn == 'Vehicle' ? i.vehicleId : i.driverId);
        workingHours.forEach(function (value) {
            if (!knownIds.includes(value.workingHourBasedOn == 'Vehicle' ? value.vehicleId : value.driverId)) {
                state.workingHours.push(value);
            };
        });
    },
    setIds(state, ids) {
        state.ids = ids;
    },
    clearItems(state) {
        state.ids.splice(0);
        state.items.splice(0);
        state.workingHours.splice(0);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};