import Vue from 'vue'
import { defineStore } from 'pinia'
import rbacService from "@/service/rbac.service"

export const useTenantStore = defineStore('tenant', {
    state: () => ({
        tenants: localStorage.getItem('tenants') ? JSON.parse(localStorage.getItem('tenants')) : [],
        tenantGroups: localStorage.getItem('tenantGroups') ? JSON.parse(localStorage.getItem('tenantGroups')) : [],
    }),

    actions: {
        async getTenants() {
            if (Vue.prototype.$session.get('tenantDetails').mainTenantId === Vue.prototype.$session.get('tenantDetails').tenantId) {
                await rbacService.getUserTenantAccessForSwitch().then(results => {
                    this.tenants = results.map(item => {
                        return {
                            tenantId: item.externalTenantId,
                            tenantName: item.tenantName,
                            errors: item.externalTenantId == item.tenantId
                                ? []
                                : Array.isArray(item?.externalRoles)
                                    ? item?.externalRoles.length > 0
                                        ? []
                                        : ['This Tenant has not assigned you to any roles.','Please contact the tenant to re-issue access.']
                                    : ['This Tenant has not assigned you to any roles.', 'Please contact the tenant to re-issue access.']
                        }
                    });
                    localStorage.setItem("tenants", JSON.stringify(this.tenants))
                    this.tenantGroups = results.map(i => ({
                        externalTenantId: i.externalTenantId,
                        externalGroupIds: i.externalGroupIds
                    }));
                    localStorage.setItem("tenantGroups", JSON.stringify(this.tenantGroups));
                });
            };
        },
        addTenant(tenant) {
            this.tenants.push({
                tenantId: tenant.externalTenantId,
                tenantName: tenant.tenantName,
                errors: tenant.externalTenantId == tenant.tenantId
                    ? []
                    : Array.isArray(tenant?.externalRoles)
                        ? tenant?.externalRoles.length > 0
                            ? []
                            : ['This Tenant has not assigned you to any roles.', 'Please contact the tenant to re-issue access.']
                        : ['This Tenant has not assigned you to any roles.', 'Please contact the tenant to re-issue access.']
            });
            localStorage.setItem("tenants", JSON.stringify(this.tenants))
            //Save Groups
            this.tenantGroups.push({
                externalTenantId: tenant.externalTenantId,
                externalGroupIds: tenant.externalGroupIds
            });
            localStorage.setItem("tenantGroups", JSON.stringify(this.tenantGroups));           
        },
        removeTenant(tenant) {
            var index = this.tenants.findIndex(t => t.tenantId === tenant.externalTenantId);
            if (index !== -1) {
                this.tenants.splice(index, 1);
                localStorage.setItem("tenants", JSON.stringify(this.tenants))
            };

            var groupIndex = this.tenantGroups.findIndex(t => t.externalTenantId === tenant.externalTenantId);
            if (groupIndex !== -1) {
                this.tenantGroups.splice(groupIndex, 1);
                localStorage.setItem("tenantGroups", JSON.stringify(this.tenantGroups));
            }
        },
        tenantList() {
            return this.tenants.map(tenant => ({
                tenantId: tenant.tenantId,
                tenantName: tenant.tenantId == tenant.tenantName || tenant.tenantName.includes(tenant.tenantId)
                    ? tenant.tenantName
                    : [
                        tenant.tenantId,
                        tenant.tenantName,
                    ].join('_'),
                errors: tenant.errors,
            }));

        },
    },
})