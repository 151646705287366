import Vue from 'vue';

var GeoPoint = {

    dec2deg: function (value, max) {
        var sign = value < 0 ? -1 : 1;
        var abs = Math.abs(Math.round(value * 1000000));
        if (abs > (max * 1000000)) {
            return NaN;
        }
        var dec = abs % 1000000 / 1000000;
        var deg = Math.floor(abs / 1000000) * sign;
        var min = Math.floor(dec * 60);
        var sec = (dec - min / 60) * 3600;
        var CHAR_DEG = "\u00B0";
        var CHAR_MIN = "\u0027";
        var CHAR_SEC = "\u0022";
        var CHAR_SEP = "\u0020";
        return `${deg}${CHAR_DEG}${CHAR_SEP}${min}${CHAR_MIN}${CHAR_SEP}${sec.toFixed(2)}${CHAR_SEC}`;
    },

    toLatDeg: function (lat) {
        return this.dec2deg(parseFloat(lat), 90);
    },

    toLngDeg: function (lng) {
        return this.dec2deg(parseFloat(lng), 180);
    }
};


Vue.filter('latitude', function (lat) {
    return GeoPoint.toLatDeg(lat);
});

Vue.filter('longitude', function (lng) {
    return GeoPoint.toLngDeg(lng);
});
