<template>
    <td :class="className">
        <v-menu v-model="menu"
                offset-y
                :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="secondary"
                       v-bind="attrs"
                       v-on="on">
                    {{value.length}}&nbsp;{{$t('amm.transaction.documents')}}
                </v-btn>
            </template>
            <v-list>
                <v-list-group v-for="(group,index) in groupHeaders"
                              no-action
                              :key="index">
                    <template v-slot:activator>
                        <v-list-item-title>{{group.type}}&nbsp;({{group.count}})</v-list-item-title>
                    </template>
                    <v-list-item v-for="(doc, index) in group.attachments"
                                 :key="index"
                                 link>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{doc.fileName}}
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action  v-if="isImage(doc.link)">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon
                                           v-bind="attrs"
                                           v-on="on"
                                           @click="showAttachment(doc)">
                                        <v-icon color="grey lighten-1">mdi-card-search-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{$t('amm.documents.preview')}}</span>
                            </v-tooltip>
                        </v-list-item-action>
                        <v-list-item-action  v-if="doc.link">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon
                                           v-bind="attrs"
                                           v-on="on"
                                           @click="downloadAttachment(doc)">
                                        <v-icon color="grey lighten-1">mdi-download-box</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{$t('ScheduleReport.download')}}</span>
                            </v-tooltip>
                        </v-list-item-action>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </v-menu>
    </td>
</template>
<script>
    export default {
        name: "ammAttachmentCell",
        props: {
            field: String,
            dataItem: Object,
            format: String,
            className: String,
            columnIndex: Number,
            columnsCount: Number,
            rowType: String,
            level: Number,
            expanded: Boolean,
            editor: String
        },
        data() {
            return {
                menu:false,
            }
        },
        methods: {
            isImage(link) {
                return link
                    ? [
                        '.png',
                        '.jpg',
                        '.jpeg',
                    ].filter(i => link.toLowerCase().includes(i)).length > 0
                    : false
            },
            showAttachment(doc) {
                this.menu = false;
                this.$emit(this.editor, { attachment: doc, download: false, dataItem: this.dataItem });
            },
            downloadAttachment(doc) {
                this.menu = false;
                this.$emit(this.editor, { attachment: doc, download: true, dataItem: this.dataItem });

            },
        },
        computed: {
            value() {
                return this.dataItem[this.field];
            },
            chipList() {
                return this.value
                    ? Array.isArray(this.value)
                        ? this.value.sort()
                        : this.value.includes(',')
                            ? this.value.split(',').sort()
                            : [this.value]
                    : []
            },
            groupHeaders() {
                return this.dataItem[this.field].reduce((list, entry) => {
                    if (list.map(i => i.type).includes(entry.type)) {
                        list.find(i => i.type == entry.type).count += 1;
                        list.find(i => i.type == entry.type).attachments.push(entry);
                    } else {
                        list.push({
                            type: entry.type,
                            count: 1,
                            attachments: [entry],
                        });
                    };
                    return list;
                }, []);
            },
        },

    };
</script>
<style scoped>
    .chipStyle {
        background: #40C1AC !important;
        color: #002B49;
        margin: 2px;
    }
</style>
