<template>
    <v-dialog v-model="dialog"
              max-width="500"
              @click:outside="closeDialog">
        <v-card>
            <v-toolbar dark
                       color="#002B49">
                <v-toolbar-title>{{$t('FleetLabels.commentAudit')}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon
                       @click="closeDialog"
                       style="margin: 0 !important;">
                    <v-icon>mdi-close-thick</v-icon>
                </v-btn>
            </v-toolbar>
            <commentAudit ref="commentAudit"
                          :alertItem="alertItem"
                          style="min-height: 330px;"
                          @fail="dialog = false">
            </commentAudit>
            <v-card-actions>
                <v-btn @click="$refs.commentAudit.exportComments()">
                    {{$t("Location.exportExcel")}}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primaryButton"
                       @click="closeDialog">
                    {{$t("jobList.close")}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import commentAudit from "@/components/fleet-alerts/comment-audit";
    export default {
        components: {
            commentAudit,
        },
        data() {
            return {
                dialog: false,
                alertItem: null,
            }
        },
        methods: {
            openDialog(alertItem) {
                this.alertItem = alertItem;
                this.dialog = true;
            },
            closeDialog() {
                this.dialog = false;
                this.alertItem = null;
            },
        },
    }
</script>
<style scoped>
    .col {
        padding: 0 24px;
    }

    .v-card__title {
        padding-top: 0px;
        padding-bottom: 8px;
    }
</style>