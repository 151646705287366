import axios from 'axios';
import Vue from 'vue';

class CommonService {

    deleteImage(entityType, id) {
        return axios.delete(`/api/image/DeleteImage/${entityType}/${id}`);
    }

    getTimeZones() {
        return axios.get(`/api/common/GetTimeZones`);
    }

    arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

    async uploadProfileImage(entityType, file, id) {
        var arrayBuffer = await file.arrayBuffer();
        const base64String = this.arrayBufferToBase64(arrayBuffer);

        var body = {
            'applicationId': Vue.prototype.$session.get("applicationId"),
            "fileName": file.name,
            "entityType": entityType,
            "file": base64String,
            "id": id
        }

        return axios.post(`/api/image/UploadProfileImage`, body);
    }

    getAppInsightsKey() {
        return axios.get(`/api/common/getAppInsightsKey`);
    }
    getTollgates() {
        return axios.get('/api/livemap/tollgates');
    }
}

export default new CommonService();