<template>
    <td :class="className">
        <div class="gridLink" @click="clickHandler">
            <pre> {{dataItem[field]}} </pre> 
        </div>
    </td>
</template>
<script>
export default {
  name: "FormatNavigateCell",
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  methods:{
    clickHandler() {
      this.$emit(this.editor, this.dataItem);
    }
  }
};
</script>
<style scoped>
    .gridLink pre {
        color: #40C1AC;
        cursor: pointer;
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        font-family: Myriad;
        background: transparent;
    }
    .gridLink {
        color: #40C1AC;
        cursor: pointer;
    }
</style>