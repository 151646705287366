import { useGroupsFilterStore } from '@/stores/groups-store';
import axios from 'axios';

class UserService {
    getUserById(id) {
        return axios.get(`/api/user/${id}`);
    }

    getUserByIdFast(id) {
        return axios.get(`/api/user/getUserByIdFast/${id}`);
    }

    getSecurityRolesForUserByID(id) {
        return axios.get(`/api/user/${id}/securityRoles`);
    }
    checkUserDependencies(applicationId, ids) {
        return axios.post(`/api/user/${applicationId}/delete/test`, ids);
    }
    deleteUsersByIds(applicationId, ids , validate = true, resolve = false) {
        return axios.post(`/api/user/${applicationId}/delete/validate/${validate}/resolve/${resolve}`, ids);
    }
    managedDriverDeletion(applicationId, userDeletionRequest, validate = true, resolve = false) {
         return axios.post(`/api/user/${applicationId}/manageddriverdeletion/validate/${validate}/resolve/${resolve}`, userDeletionRequest);
    };

    UnlockUserbyId(id) {
        return axios.put(`/api/user/${id}/unlock`);
    }

    changePassword(userId, payload) {
        return axios.put(`/api/user/${userId}/ChangePassword`, payload);
    }

    updateSecurityAnwers(userId, payload) {
        return axios.put(`/api/user/${userId}/UpdateSecurityAnwers`, payload);
    }

    createUser(payload, id) {
        if (id) {
            return axios.put(`/api/user/${id}/edit`, payload);
        } else {
            return axios.post(`/api/user/create`, payload);
        }
    }

    getUsersByIds(applicationId, ids) {
        const groupFilterStore = useGroupsFilterStore();
        if (ids?.length > 0 || groupFilterStore.users.length > 0) {
            return axios.post(`/api/user/${applicationId}/all`, ids ? ids : groupFilterStore.users);
        } else {
            return Promise.resolve([]);
        };     
    }
    getPersonaByIds(applicationId, ids) {
        const groupFilterStore = useGroupsFilterStore();
        if (ids?.length > 0 || groupFilterStore.users.length > 0) {
            return axios.post(`/api/user/${applicationId}/includingOperators`, ids ? ids : groupFilterStore.users);
        } else {
            return Promise.resolve([]);
        };     
    }
    getAllUsersListed(applicationId) {
        return axios.get(`/api/user/${applicationId}/getAllUsersListed`);
    }
    removeRoles(applicationId, UserRoleRemovalRequest) {
        return axios.post(`/api/user/${applicationId}/removeRoles`, UserRoleRemovalRequest);
    }
}

export default new UserService();