import axios from 'axios';
import Vue from 'vue';

class BulkImportService {

    downloadFile(body) {
        return fetch(`/api/import/DownloadFile?importType=${body}`)
            .then(response => response.arrayBuffer());
    }

    downloadGridasFIle(body, preType) {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        };
        return fetch(`/api/import/downloadGridasFile?preType=${preType}`, requestOptions)
            .then(response => response.arrayBuffer());
    }

    getDraftGridData(params) {
        return axios.get('/api/import/GetDraftGridData', { params: params });
    }

    checkUploadProgress(params) {
        return axios.get('/api/import/CheckUploadProgress', { params: params });
    }

    async getFileGridData(body, preType) {
        var arrayBuffer = await body.arrayBuffer();
        const base64String = btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));

        let params = {
            "preType": preType,
            'applicationId': Vue.prototype.$session.get("applicationId"),
            "fileName": body.name
        }
        return axios.post('/api/import/GetFileGridData', { file: base64String }, { params: params });
    }

    async GetFileRecords(filename, body, type) {
        var arrayBuffer = await body.arrayBuffer();
        const base64String = btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));

        let params = {
            "type": type,
            filename: filename
        }

        return axios.post('/api/import/GetFileRecords', { file: base64String }, { params: params })
    }

    uploadGenericFile(filename, data) {
        let a =
        {
            name: filename,
            data: data
        };
        return axios.post('/api/import/upload', a);
    }
    uploadJobFile(filename, data) {
        let a =
        {
            name: filename,
            data: data
        };
        return axios.post('/api/import/uploadJob', a);
    }

    uploadVendorFile(filename, data) {
        let a =
        {
            name: filename,
            data: data
        };
        return axios.post('/api/import/uploadVendor', a);
    }
    uploadTransactionsFile(filename, data) {
        let a =
        {
            name: filename,
            data: data
        };
        return axios.post('/api/import/uploadTransactions', a);
    }

    uploadRouteFile(filename, data) {
        let a =
        {
            name: filename,
            data: data
        };
        return axios.post('/api/import/uploadRoute', a);
    }

    checkGridData(body, preType) {
        let params = {
            "preType": preType,
            'applicationId': Vue.prototype.$session.get("applicationId"),
        }
        return axios.post('/api/import/CheckGridData', body, { params: params });
    }

    uploadGridData(body, preType) {
        let params = {
            "preType": preType,
            'applicationId': Vue.prototype.$session.get("applicationId"),
        }
        return axios.post('/api/import/UploadGridData', body, { params: params });
    }

    validateLocationData(body) {
        return axios.post('/api/import/ValidateLocationData', body);
    }

    importLocationData(body) {
        return axios.post('/api/import/ImportLocationData', body);
    }
}

export default new BulkImportService();