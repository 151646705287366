<template>
    <v-menu offset-y
            ref="columnSelector"
            :close-on-content-click="false"
            z-index="12">
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon
                   :dark="dark"
                   v-bind="attrs"
                   v-on="on"
                   @click="logInsight"
                   auto-id="contact_col_sel">
                <v-icon>mdi-cog</v-icon>
            </v-btn>
        </template>
        <columnSelectorList :columnShortcuts="columnShortcuts"  
                            :columns="columns"
                            :persist="persist"
                            :getDefaults="getDefaults"
                            @close="$refs.columnSelector.save()">
        </columnSelectorList>
    </v-menu>
</template>
<script>
    import columnSelectorList from "@/views/Shared/GridTools/ColumnSelectorList";
    export default {
        name: "ColumnSelector",
        components: {
            columnSelectorList,
        },
        props: {
            columns: Array,
            getDefaults: Function,
            persist: Function,
            additionalClass: String,
            dark: {
                type: Boolean,
                required: false,
                default: true
            },
            columnShortcuts: {
                type: Array
                , default() {
                    return []
                }
            },
            gridSettingsKey: {
                type: String,
                default() {
                    return null;
                },
            },
        },
        methods: {
            logInsight() {
                if (this.gridSettingsKey) {
                    let pageName = this.$columnStore.pageNames[this.gridSettingsKey] || this.gridSettingsKey;               
                    this.$insights.trackEvent(pageName, 'Column Selector Clicked')
                };
            },
        },
    };
</script>
<style scoped>
    .customSettingsScroll {
        top: 170px !important;
    }

    .setToDefault {
        color: #002B49;
        background-color: rgb(255, 255, 255);
        border: 2px solid #002B49;
    }
    /*==========css for Setting column on vehicle page=============*/
    .customSettingsScroll.bottomauto {
        bottom: auto !important;
        top: 150px !important;
    }

        .customSettingsScroll.bottomauto > > > .md-menu-content-container {
            max-height: 400px !important
        }
    /*==========end=============*/


    
</style>