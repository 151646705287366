<template>
    <td :class="columnError" :title="columnTitle">
        <v-select v-model="dataItem.location.geozone"
                  :items="items"></v-select>
    </td>
</template>

<script>
    export default {
        props: {
            field: String,
            dataItem: Object,
            format: String,
            className: String,
            columnIndex: Number,
            columnsCount: Number,
            rowType: String,
            level: Number,
            expanded: Boolean,
            editor: String,
            icon: String
        },
        data() {
            return {

            }
        },
        computed: {
            items() {
                if (this.dataItem.location.type === 'line') {
                    return [
                        { value: "crossinginfo", text: this.$t('LocationLabels.crossingLineInfo') },
                        { value: "crossingalert", text: this.$t('LocationLabels.crossingLineAlert') },
                        { value: "crossingalarm", text: this.$t('LocationLabels.crossingLineAlarm') }
                    ]
                } else {
                    return [
                        { value: "None", text: this.$t('LocationLabels.none') },
                        { value: "waypoint", text: this.$t('LocationLabels.wayPoint') },
                        { value: "nogo", text: this.$t('LocationLabels.noGo') },
                        { value: "preferred", text: this.$t('LocationLabels.preferred') },
                    ]
                }
            },
            columnError() {
                if (this.dataItem.location.geozoneError) {
                    return "critical"
                }
                return ""
            },
            columnTitle() {
                if (this.dataItem.location.geozoneErrorMessage) {
                    return this.dataItem.location.geozoneErrorMessage.errors
                }
                return ""
            }
        },
        methods: {
            clickHandler(subDevice) {
                this.$emit(this.editor, subDevice, this.dataItem);
            }
        }
    };
</script>
<style scoped>
    .critical {
        background-color: #fdd !important;
    }
</style>
