<template>
    <td :class="className">
        <div class="gridLink" @click.stop="clickHandler">
            <v-icon>{{icon}}</v-icon>
            {{dataItem[field]}}
        </div>
    </td>
</template>
<script>
    export default {
        name: "LocationIconNameCell",
        props: {
            field: String,
            dataItem: Object,
            format: String,
            className: String,
            columnIndex: Number,
            columnsCount: Number,
            rowType: String,
            level: Number,
            expanded: Boolean,
            editor: String
        },
        computed: {
            icon() {
                switch (this.dataItem.type) {
                    case "MARKER":
                        return "mdi-map-marker"
                    case "LINE":
                        return "mdi-vector-line"
                    case "CIRCLE":
                        return "mdi-circle-outline"
                    case "POLYGON":
                        return "mdi-vector-polygon"
                    default:
                        return ""
                }
            }
        },
        methods: {
            clickHandler() {
                this.$emit(this.editor, this.dataItem);
            }
        }
    };
</script>
<style scoped>
    .gridLink {
        color: #002B49;
        cursor: pointer;
        text-decoration: underline;
        text-decoration-color: #002B49;
        text-shadow: 0 0 1px #40C1AC;
    }

        .gridLink:hover {
            color: #29897d;
        }
</style>