import axios from 'axios';

class ReplayService {

    detailedTripSummary(id, tripStatus, startTime, endTime) {
        let params = {
            "tripStatus": tripStatus,
            "startTime": startTime,
            "endTime": endTime
        }
        return axios.get(`/api/report/DetailedTripSummary`, { params: params })
    }
}

export default new ReplayService();