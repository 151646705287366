import Vue from 'vue'

const errorCodes = {
    default: {
        statusCode: "Default error",
        statusText: "Unable to get record.",
    },
    200: {
        statusCode: "Ok",
        statusText: "Record fetched successfully.",
    },
    201: {
        statusCode: "Ok",
        statusText: "Record created successfully.",
    },
    400: {
        statusCode: "Bad Request",
        statusText: "Unable to get record due to the bad request.",
    },
    404: {
        statusCode: "Not Found",
        statusText: "No record found for given search criteria.",
    },
    405: {
        statusCode: "Method Not Allowed",
        statusText: "Request method is not allowed.",
    },
    409: {
        statusCode: "Conflict",
        statusText: "There is a conflict for the requested data.",
    },
    500: {
        statusCode: "Internal Server Error",
        statusText: "Server is not responding currently. Please try again later.",
    },

}

const toast = {

    getToastOptions(reload = false) {

        const reloadAction = {
            text: "Reload",
            onClick: () => {
                Vue.prototype.$router.go();
            }
        };
        const dismissAction = {
            text: "Dismiss",
            onClick: (e,toastObject) => {
                toastObject.goAway(0);
            }
        };
        const actions = [];
        if (reload) {
            actions.push(reloadAction);
        }
        actions.push(dismissAction);
        const actionsOption = {
            action: actions
        };
        return {
            theme: "outline",
            position: "top-center",
            ...actionsOption
        };
    },

    httpError(error, duration = 3000) {
        let toastOptions = this.getToastOptions(true);
        if(duration > 0) {
            toastOptions = Object.assign(toastOptions,{duration: duration});
        }
        const errorStatus = (error.response !== undefined) ? error.response.status : -1;
        if (errorStatus == 401) {
            Vue.prototype.$router.push("/");
        } else {
            var errorMessage = '';
            if (errorStatus > -1) {
                const statusObject = errorCodes[errorStatus] ? errorCodes[errorStatus] : errorCodes["default"];
                errorMessage = statusObject.statusText;
                if (error.response && error.response.data) {
                    if (error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    if (error.response.data.apiMessage) {
                        errorMessage = error.response.data.apiMessage;
                    }
                }
            } else {
                errorMessage = error.message;
            }
            Vue.prototype.$toasted.clear();
            Vue.prototype.$toasted.error(errorMessage, toastOptions);
        }
    },

    info(message, duration = 3000) {
        let toastOptions = this.getToastOptions();
        if(duration > 0) {
           toastOptions = Object.assign(toastOptions,{duration: duration});
        }
        Vue.prototype.$toasted.info(message, toastOptions);
    },
    error(message, duration = 3000) {
        let toastOptions = this.getToastOptions();
        if(duration > 0) {
            toastOptions = Object.assign(toastOptions,{duration: duration});
        }
        Vue.prototype.$toasted.error(message, toastOptions);
    },
    show(message, duration = 3000) {
        let toastOptions = this.getToastOptions();
        if(duration > 0) {
            toastOptions = Object.assign(toastOptions,{duration: duration});
        }
        Vue.prototype.$toasted.show(message, toastOptions);
    },
    clear(message, duration = 3000) {
        let toastOptions = this.getToastOptions();
        if(duration > 0) {
            toastOptions = Object.assign(toastOptions,{duration: duration});
        }
        Vue.prototype.$toasted.clear(message, toastOptions);
    }
};

export default toast;