import Vue from 'vue';
import VeeValidate from 'vee-validate';

Vue.use(VeeValidate);

VeeValidate.Validator.extend('numberOnly', (value) => {
    return /^[0-9+]*$/.test(value);
});

VeeValidate.Validator.extend('contactNumber', (value) => {
    return /^[+]?[0-9+]*$/.test(value);
});

VeeValidate.Validator.extend('name', (value) => {
    return /^[a-zA-Z0-9 +]*$/.test(value);
});

VeeValidate.Validator.extend('emailList', (value) => {
    for (var i = 0; i < value.length; i++) {
        var email = value[i];
        var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regex.test(String(email).toLowerCase())) {
            return false;
        }
    }
    return true;
});

VeeValidate.Validator.extend('specialCharsOnly', (value) => {
    return /^(?:([\!\"\\\$\%\&\(\)\*\+\,\-\.\/\:\;\#\<\>\?\_\@\\])(?!.*\1))*$/.test(value);
});

VeeValidate.Validator.extend('equals', {
    getMessage: (field, value) => `${field} must be ${value}`,
    validate: (value, value1) => {
        if (value && value1) {
            return value.toString() === value1.toString()
        }

    }
});

VeeValidate.Validator.extend('mns', {
    getMessage: (field, value) => `${field} should be ${value}`,
    validate: (value, value1) => {
        if (value && value1) {
            return value.toString() === value1.toString()
        }
    }
});

VeeValidate.Validator.extend('mxs', {
    getMessage: (field, value) => `${field} exceeded by ${value}`,
    validate: (value, value1) => {
        if (value && value1) {
            return value.toString() === value1.toString()
        }
    }
});


VeeValidate.Validator.extend('internationalContactNumber', (value) => {
    return /^(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})*$/.test(value);
});
