<template>
  <div class="replayflexContainer">
    <div class="replaytopStatus">
      <div
        :key="itemIndex"
        v-for="(item,itemIndex) in statusIndicator"
        class="flexContent statusIndicator"
      >
        <span :class="item.class"></span>
        <span class="dotText">{{item.label}}</span>
      </div>
      <div class="freeSpace"></div>
      <div class="flexContent">
        <slot name="otherContent">{{$t("CustomeViews.options")}}</slot>
      </div>
    </div>
    <div class="replayBottomflexContainer">
      <div class="dateWrapper">
        <slot name="dateRangeSelector"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TimeLine",
  computed: {
    statusIndicator: function() {
      const statusIndicator = [
        { label: this.$tc('Replay.driving'), class: "dot greenDot" },
        { label: this.$tc('Replay.idle'), class: "dot blueDot" },
        { label: this.$tc('Replay.stopped'), class: "dot grayedDot" },
        { label: this.$tc('Replay.violations'), class: "dot redDot" },
        { label: this.$tc('Replay.businessHours'), class: "legendLine lightgray" },
        { label: this.$tc('Replay.privateMode'), class: "legendLine black" },
        { label: this.$tc('Replay.businessMode'), class: "legendLine blue" }
      ];
      return statusIndicator;
    }
  }
};
</script>
<style scoped>
.legendLine {
  top: 0px;
}
.replaytopStatus {
    display:flex;
    flex:1;
    width:100%;
    flex-wrap:wrap;
    padding-top: 35px;
  }
  .statusIndicator{
    margin-top: 0px !important;
  }
  .replayBottomflexContainer{
      display: flex;
      align-items: center;
      color: #fff;
      justify-content: center;
  }
  .dateWrapper{
      display: flex;
      align-items: center;
      cursor:pointer;
      font-size:20px;
  }
  .dateWrapper .md-icon.md-theme-default.md-icon-font{
    color: #fff !important;
  }
  .greenDot {
    background-color: #6FB30A;
  }
</style>