export default class ClockStrategy24Hour {
	constructor(component) {
		this.titleDateFormat = 'MMMM Do YYYY';
		this.titleTimeFormat = 'HH:mm';
		this.component = component;
	}
	populateHours () {
		for (let hour = 0; hour < 24; hour++) {
			this.component.hours.push(hour);
		}
	}
	getHour (date) {
		return date.hour;
	}
	setTime (date, value) {
		if (value !== undefined) {
			value.hour = date.getHours();
			value.minute = date.getMinutes();
		}
	}
}
