import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios';
import af from './i18n/cached/af.json';
import de from './i18n/cached/de.json'
import en from './i18n/cached/en.json'
import fr from './i18n/cached/fr.json'
import it from './i18n/cached/it-IT.json'
import nl from './i18n/cached/nl.json'
import st from './i18n/cached/st.json'
import zu from './i18n/cached/zu.json'

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale:"en",
    fallbackLocale:"en",
    messages: en
});

i18n.setLocaleMessage("af", af);
i18n.setLocaleMessage("de", de);
i18n.setLocaleMessage("en", en);
i18n.setLocaleMessage("fr", fr);
i18n.setLocaleMessage("it-IT", it);
i18n.setLocaleMessage("nl", nl);
i18n.setLocaleMessage("st", st);
i18n.setLocaleMessage("zu", zu);

const loadedLanguages = ['en'] // our default language that is preloaded

function setI18nLanguage (lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync(lang) {
    loadedLanguages.push(lang)
    setI18nLanguage(lang)
    return Promise.resolve(true)
}

export function getReportKeysAsync() {

    

  let ReportKeys = {
    controllerNotInitialized: Keys != null?Keys['ReportViewer.controllerNotInitialized']:'',
    noReportInstance: Keys != null?Keys['ReportViewer.noReportInstance']:'',
    missingTemplate: Keys != null?Keys['ReportViewer.missingTemplate']:'',
    noReport: Keys != null?Keys['ReportViewer.noReport']:'',
    noReportDocument: Keys != null?Keys['ReportViewer.noReportDocument']:'',
    missingOrInvalidParameter: Keys != null?Keys['ReportViewer.missingOrInvalidParameter']:'',
    invalidParameter: Keys != null?Keys['ReportViewer.invalidParameter']:'',
    invalidDateTimeValue: Keys != null?Keys['ReportViewer.invalidDateTimeValue']:'',
    parameterIsEmpty: Keys != null?Keys['ReportViewer.parameterIsEmpty']:'',
    cannotValidateType: Keys != null?Keys['ReportViewer.cannotValidateType']:'',
    loadingFormats: Keys != null?Keys['ReportViewer.loadingFormats']:'',
    loadingReport: Keys != null?Keys['ReportViewer.loadingReport']:'',
    preparingDownload: Keys != null?Keys['ReportViewer.loadingReport']:'',
    preparingPrint: Keys != null?Keys['ReportViewer.preparingPrint']:'',
    errorLoadingTemplates: Keys != null?Keys['ReportViewer.errorLoadingTemplates']:'',
    errorServiceUrl:  Keys != null?Keys['ReportViewer.errorServiceUrl']:'',
    loadingReportPagesInProgress: Keys != null?Keys['ReportViewer.loadingReportPagesInProgress']:'',
    loadedReportPagesComplete:  Keys != null?Keys['ReportViewer.loadedReportPagesComplete']:'',
    noPageToDisplay: Keys != null?Keys['ReportViewer.noPageToDisplay']:'',
    errorDeletingReportInstance: Keys != null?Keys['ReportViewer.errorDeletingReportInstance']:'',
    errorRegisteringViewer: Keys != null?Keys['ReportViewer.errorRegisteringViewer']:'',
    noServiceClient: Keys != null?Keys['ReportViewer.errorRegisteringViewer']:'',
    errorRegisteringClientInstance: Keys != null?Keys['ReportViewer.errorRegisteringClientInstance']:'',
    errorCreatingReportInstance: Keys != null?Keys['ReportViewer.errorCreatingReportInstance']:'',
    errorCreatingReportDocument: Keys != null?Keys['ReportViewer.errorCreatingReportDocument']:'',
    unableToGetReportParameters: Keys != null?Keys['ReportViewer.unableToGetReportParameters']:'',
    errorObtainingAuthenticationToken: Keys != null?Keys['ReportViewer.errorObtainingAuthenticationToken']:'',
    clientExpired: Keys != null?Keys['ReportViewer.clientExpired']:'',
    promisesChainStopError: Keys != null?Keys['ReportViewer.promisesChainStopError']:'',
    parameterEditorSelectNone: Keys != null?Keys['ReportViewer.loadingReport']:'',
    parameterEditorSelectAll: Keys != null?Keys['ReportViewer.parameterEditorSelectAll']:'',
    parametersAreaPreviewButton: Keys != null?Keys['ReportViewer.parametersAreaPreviewButton']:'',
    menuNavigateBackwardText: Keys != null?Keys['ReportViewer.menuNavigateBackwardText']:'',
    menuNavigateBackwardTitle: Keys != null?Keys['ReportViewer.menuNavigateBackwardTitle']:'',
    menuNavigateForwardText: Keys != null?Keys['ReportViewer.menuNavigateForwardText']:'',
    menuNavigateForwardTitle: Keys != null?Keys['ReportViewer.menuNavigateForwardTitle']:'',
    menuRefreshText: Keys != null?Keys['ReportViewer.menuRefreshText']:'',
    menuRefreshTitle: Keys != null?Keys['ReportViewer.menuRefreshTitle']:'',
    menuFirstPageText: Keys != null?Keys['ReportViewer.menuFirstPageText']:'',
    menuFirstPageTitle: Keys != null?Keys['ReportViewer.menuFirstPageTitle']:'',
    menuLastPageText: Keys != null?Keys['ReportViewer.menuLastPageText']:'',
    menuLastPageTitle: Keys != null?Keys['ReportViewer.menuLastPageTitle']:'',
    menuPreviousPageTitle: Keys != null?Keys['ReportViewer.menuPreviousPageTitle']:'',
    menuNextPageTitle: Keys != null?Keys['ReportViewer.menuNextPageTitle']:'',
    menuPageNumberTitle: Keys != null?Keys['ReportViewer.menuPageNumberTitle']:'',
    menuDocumentMapTitle: Keys != null?Keys['ReportViewer.menuDocumentMapTitle']:'',
    menuParametersAreaTitle: Keys != null?Keys['ReportViewer.menuParametersAreaTitle']:'',
    menuZoomInTitle: Keys != null?Keys['ReportViewer.menuZoomInTitle']:'',
    menuZoomOutTitle: Keys != null?Keys['ReportViewer.menuZoomOutTitle']:'',
    menuPageStateTitle: Keys != null?Keys['ReportViewer.menuPageStateTitle']:'',
    menuPrintText: Keys != null?Keys['ReportViewer.menuPrintText']:'',
    menuContinuousScrollText: Keys != null?Keys['ReportViewer.menuContinuousScrollText']:'',
    menuSendMailText: Keys != null?Keys['ReportViewer.menuSendMailText']:'',
    menuPrintTitle: Keys != null?Keys['ReportViewer.menuPrintTitle']:'',
    menuContinuousScrollTitle: Keys != null?Keys['ReportViewer.menuContinuousScrollTitle']:'',
    menuSendMailTitle: Keys != null?Keys['ReportViewer.menuSendMailTitle']:'',
    menuExportText: Keys != null?Keys['ReportViewer.menuExportText']:'',
    menuExportTitle: Keys != null?Keys['ReportViewer.menuExportTitle']:'',
    menuPrintPreviewText: Keys != null?Keys['ReportViewer.menuPrintPreviewText']:'',
    menuPrintPreviewTitle: Keys != null?Keys['ReportViewer.menuPrintPreviewTitle']:'',
    menuSearchText: Keys != null?Keys['ReportViewer.menuSearchText']:'',
    menuSearchTitle: Keys != null?Keys['ReportViewer.menuSearchTitle']:'',
    menuSideMenuTitle: Keys != null?Keys['ReportViewer.menuSideMenuTitle']:'',
    sendEmailFromLabel: Keys != null?Keys['ReportViewer.sendEmailFromLabel']:'',
    sendEmailToLabel: Keys != null?Keys['ReportViewer.sendEmailToLabel']:'',
    sendEmailCCLabel: Keys != null?Keys['ReportViewer.sendEmailCCLabel']:'',
    sendEmailSubjectLabel: Keys != null?Keys['ReportViewer.sendEmailSubjectLabel']:'',
    sendEmailFormatLabel: Keys != null?Keys['ReportViewer.sendEmailFormatLabel']:'',
    sendEmailSendLabel: Keys != null?Keys['ReportViewer.sendEmailSendLabel']:'',
    sendEmailCancelLabel: Keys != null?Keys['ReportViewer.sendEmailCancelLabel']:'',
    ariaLabelPageNumberSelector: Keys != null?Keys['ReportViewer.ariaLabelPageNumberSelector']:'',
    ariaLabelPageNumberEditor: Keys != null?Keys['ReportViewer.ariaLabelPageNumberEditor']:'',
    ariaLabelExpandable: Keys != null?Keys['ReportViewer.ariaLabelExpandable']:'',
    ariaLabelSelected: Keys != null?Keys['ReportViewer.ariaLabelSelected']:'',
    ariaLabelParameter: Keys != null?Keys['ReportViewer.ariaLabelParameter']:'',
    ariaLabelErrorMessage: Keys != null?Keys['ReportViewer.ariaLabelErrorMessage']:'',
    ariaLabelParameterInfo: Keys != null?Keys['ReportViewer.ariaLabelParameterInfo']:'',
    ariaLabelMultiSelect: Keys != null?Keys['ReportViewer.ariaLabelMultiSelect']:'',
    ariaLabelMultiValue: Keys != null?Keys['ReportViewer.ariaLabelMultiValue']:'',
    ariaLabelSingleValue: Keys != null?Keys['ReportViewer.ariaLabelSingleValue']:'',
    ariaLabelParameterDateTime: Keys != null?Keys['ReportViewer.ariaLabelParameterDateTime']:'',
    ariaLabelParameterString: Keys != null?Keys['ReportViewer.ariaLabelParameterString']:'',
    ariaLabelParameterNumerical: Keys != null?Keys['ReportViewer.ariaLabelParameterNumerical']:'',
    ariaLabelParameterBoolean: Keys != null?Keys['ReportViewer.ariaLabelParameterBoolean']:'',
    ariaLabelParametersAreaPreviewButton: Keys != null?Keys['ReportViewer.ariaLabelParametersAreaPreviewButton']:'',
    ariaLabelMainMenu: Keys != null?Keys['ReportViewer.ariaLabelMainMenu']:'',
    ariaLabelCompactMenu: Keys != null?Keys['ReportViewer.ariaLabelCompactMenu']:'',
    ariaLabelSideMenu: Keys != null?Keys['ReportViewer.ariaLabelSideMenu']:'',
    ariaLabelDocumentMap: Keys != null?Keys['ReportViewer.ariaLabelDocumentMap']:'',
    ariaLabelPagesArea: Keys != null?Keys['ReportViewer.ariaLabelPagesArea']:'',
    ariaLabelSearchDialogArea: Keys != null?Keys['ReportViewer.ariaLabelSearchDialogArea']:'',
    ariaLabelSendEmailDialogArea: Keys != null?Keys['ReportViewer.ariaLabelSendEmailDialogArea']:'',
    ariaLabelSearchDialogStop: Keys != null?Keys['ReportViewer.ariaLabelSearchDialogStop']:'',
    ariaLabelSearchDialogOptions: Keys != null?Keys['ReportViewer.ariaLabelSearchDialogOptions']:'',
    ariaLabelSearchDialogNavigateUp: Keys != null?Keys['ReportViewer.ariaLabelSearchDialogNavigateUp']:'',
    ariaLabelSearchDialogNavigateDown: Keys != null?Keys['ReportViewer.ariaLabelSearchDialogNavigateDown']:'',
    ariaLabelSearchDialogMatchCase: Keys != null?Keys['ReportViewer.ariaLabelSearchDialogMatchCase']:'',
    ariaLabelSearchDialogMatchWholeWord: Keys != null?Keys['ReportViewer.ariaLabelSearchDialogMatchWholeWord']:'',
    ariaLabelSearchDialogUseRegex: Keys != null?Keys['ReportViewer.ariaLabelSearchDialogUseRegex']:'',
    ariaLabelMenuNavigateBackward: Keys != null?Keys['ReportViewer.ariaLabelMenuNavigateBackward']:'',
    ariaLabelMenuNavigateForward: Keys != null?Keys['ReportViewer.ariaLabelMenuNavigateForward']:'',
    ariaLabelMenuRefresh: Keys != null?Keys['ReportViewer.ariaLabelMenuRefresh']:'',
    ariaLabelMenuFirstPage: Keys != null?Keys['ReportViewer.ariaLabelMenuFirstPage']:'',
    ariaLabelMenuLastPage: Keys != null?Keys['ReportViewer.ariaLabelMenuLastPage']:'',
    ariaLabelMenuPreviousPage: Keys != null?Keys['ReportViewer.ariaLabelMenuPreviousPage']:'',
    ariaLabelMenuNextPage: Keys != null?Keys['ReportViewer.ariaLabelMenuNextPage']:'',
    ariaLabelMenuPageNumber: Keys != null?Keys['ReportViewer.ariaLabelMenuPageNumber']:'',
    ariaLabelMenuDocumentMap: Keys != null?Keys['ReportViewer.ariaLabelMenuDocumentMap']:'',
    ariaLabelMenuParametersArea: Keys != null?Keys['ReportViewer.ariaLabelMenuParametersArea']:'',
    ariaLabelMenuZoomIn: Keys != null?Keys['ReportViewer.ariaLabelMenuZoomIn']:'',
    ariaLabelMenuZoomOut: Keys != null?Keys['ReportViewer.ariaLabelMenuZoomOut']:'',
    ariaLabelMenuPageState: Keys != null?Keys['ReportViewer.ariaLabelMenuPageState']:'',
    ariaLabelMenuPrint: Keys != null?Keys['ReportViewer.ariaLabelMenuPrint']:'',
    ariaLabelMenuContinuousScroll: Keys != null?Keys['ReportViewer.ariaLabelMenuContinuousScroll']:'',
    ariaLabelMenuSendMail: Keys != null?Keys['ReportViewer.ariaLabelMenuSendMail']:'',
    ariaLabelMenuExport: Keys != null?Keys['ReportViewer.ariaLabelMenuExport']:'',
    ariaLabelMenuPrintPreview: Keys != null?Keys['ReportViewer.ariaLabelMenuPrintPreview']:'',
    ariaLabelMenuSearch: Keys != null?Keys['ReportViewer.ariaLabelMenuSearch']:'',
    ariaLabelMenuSideMenu: Keys != null?Keys['ReportViewer.ariaLabelMenuSideMenu']:'',
    ariaLabelSendEmailFrom: Keys != null?Keys['ReportViewer.ariaLabelSendEmailFrom']:'',
    ariaLabelSendEmailTo: Keys != null?Keys['ReportViewer.ariaLabelSendEmailTo']:'',
    ariaLabelSendEmailCC: Keys != null?Keys['ReportViewer.ariaLabelSendEmailCC']:'',
    ariaLabelSendEmailSubject: Keys != null?Keys['ReportViewer.ariaLabelSendEmailSubject']:'',
    ariaLabelSendEmailFormat: Keys != null?Keys['ReportViewer.ariaLabelSendEmailFormat']:'',
    ariaLabelSendEmailSend: Keys != null?Keys['ReportViewer.ariaLabelSendEmailSend']:'',
    ariaLabelSendEmailCancel: Keys != null?Keys['ReportViewer.ariaLabelSendEmailCancel']:'',
    searchDialogTitle: Keys != null?Keys['ReportViewer.searchDialogTitle']:'',
    searchDialogSearchInProgress: Keys != null?Keys['ReportViewer.searchDialogSearchInProgress']:'',
    searchDialogNoResultsLabel: Keys != null?Keys['ReportViewer.searchDialogNoResultsLabel']:'',
    searchDialogResultsFormatLabel: Keys != null?Keys['ReportViewer.searchDialogResultsFormatLabel']:'',
    searchDialogStopTitle: Keys != null?Keys['ReportViewer.searchDialogStopTitle']:'',
    searchDialogNavigateUpTitle: Keys != null?Keys['ReportViewer.searchDialogNavigateUpTitle']:'',
    searchDialogNavigateDownTitle: Keys != null?Keys['ReportViewer.searchDialogNavigateDownTitle']:'',
    searchDialogMatchCaseTitle: Keys != null?Keys['ReportViewer.searchDialogMatchCaseTitle']:'',
    searchDialogMatchWholeWordTitle: Keys != null?Keys['ReportViewer.searchDialogMatchWholeWordTitle']:'',
    searchDialogUseRegexTitle: Keys != null?Keys['ReportViewer.searchDialogUseRegexTitle']:'',
    searchDialogCaptionText: Keys != null?Keys['ReportViewer.searchDialogCaptionText']:'',
    searchDialogPageText: Keys != null?Keys['ReportViewer.searchDialogPageText']:'',
    sendEmailDialogTitle: Keys != null?Keys['ReportViewer.sendEmailValidationEmailRequired']:'',
    sendEmailValidationEmailRequired: Keys != null?Keys['ReportViewer.ariaLabelMenuPageState']:'',
    sendEmailValidationEmailFormat: Keys != null?Keys['ReportViewer.sendEmailValidationEmailFormat']:'',
    sendEmailValidationSingleEmail: Keys != null?Keys['ReportViewer.sendEmailValidationSingleEmail']:'',
    sendEmailValidationFormatRequired: Keys != null?Keys['ReportViewer.sendEmailValidationFormatRequired']:'',
    errorSendingDocument: Keys != null?Keys['ReportViewer.errorSendingDocument']:'',
  };
  return ReportKeys 
}

