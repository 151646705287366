// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

const opts = {
    theme: {
        themes: {
            light: {
                primary: "#002B49", // #E53935 Dark Blue
                secondary: "#40C1AC", // #FFCDD2 //Teal
                accent: "#40C1AC", // Teal
            },
            default: {
                primary: "#002B49", // #E53935 Dark Blue
                secondary: "#40C1AC", // #FFCDD2 //Teal
                accent: "#40C1AC", // Teal
            },
        },
    }
}

export default new Vuetify(opts)
