import Vue from 'vue'
import { defineStore } from 'pinia'
import { useDriverStore } from '@/stores/driver-store';
import { useAssetStore } from '@/stores/asset-store';
import { useLocationStore } from '@/stores/location-store';
import { useUserStore } from '@/stores/user-store';
import { useContactStore } from '@/stores/contact-store';
export const useGroupsFilterStore = defineStore('groups', {
    state: () => ({
        vehicles: [], //'All'
        drivers: [], //'All'
        locations: [],
        users: [],
        contacts: [],
        groups: [],
        vendors: ['ALL'],
        tenantId: "",
        userId:"",
        maxBeforeSelection: {
            assets: 100,
            operators : 2000,
        },
    }),
    //$groupsFilterStore
    actions: {

        //#region Bulk

        /** Clears the store
         */
        clearGroupsStore() {
            this.groups = [];
            this.vehicles = [];
            this.drivers = [];
            this.locations = [];
            this.users = [];
            this.contacts = [];
            this.tenantId = '';
            this.userId = '';
        },

        /** Sets the store states and triggers update is state changed         
         * @param {{groups:[], vehicles : [], drivers :[], locations :[],users:[],contacts:[]} stateData
         */
        setGlobalFilterState(stateData) {
            this.groups = stateData.groups;
            if (this.updateIds('vehicles', stateData.vehicles) == true) {
                this.vehicleStateChanged();
            };
            if (this.updateIds('drivers', stateData.drivers) == true) {
                this.driverStateChanged();
            };
            if (this.updateIds('locations', stateData.locations) == true) {
                this.locationStateChanged();
            };
            if (this.updateIds('users', stateData.users) == true) {
                this.userStateChanged();
            };
            if (this.updateIds('contacts', stateData.contacts) == true) {
                this.contactStateChanged();
            };
            this.tenantId = Vue.prototype.$session.get('tenantDetails')?.tenantId || '';
            this.userId = Vue.prototype.$session.get("tenantDetails")?.userId || '';
        },
        getAssetList() {
            return this.vehicles;
        },

        //#endregion

        //#region Generic

        /** Adds ID's to State
         * Function must manually call change state afterwards
         * Primarily userd where a loop is required
         * @param {String} targetState
         * @param {[String]} ids
         */
        addIds(targetState, ids) {
            this[targetState] = [...new Set([...this[targetState], ...(Array.isArray(ids) ? ids : [ids])])].sort();
        },

        /** Removes ID's from State
         * Function must manually call change state afterwards
         * Primarily userd where a loop is required
         * @param {String} targetState
         * @param {[String]} ids
         */
        removeIds(targetState, ids) {
            ids = !Array.isArray(ids) ? [ids] : ids;
            this[targetState] = this[targetState].filter(id => !ids.includes(id)).sort();
        },   
        
        /** Updates ID's in a state
         * Primarily used for bulk updates        
         * @param {String} targetState
         * @param {[String]} ids
         * @returns {Boolean} Showing if the update caused a change in the state
         */
        updateIds(targetState, ids) {
            let newState = [...new Set([...(Array.isArray(ids) ? ids : [ids])])].sort();
            let changed = !(this[targetState].sort().join(',') == newState.join(','));
            //What about when we move from all to all?
            if (this.tenantId != Vue.prototype.$session.get('tenantDetails')?.tenantId || '') {
                changed = true; //Force change as tenant updated.
            };
            //
            this[targetState] = newState;
            return changed;
        },
    
        //#endregion

        //#region vehicles
        async vehicleStateChanged() {
            const assetStore = useAssetStore();
            //Confirm and invalidate if required
            if (this.tenantId != Vue.prototype.$session.get('tenantDetails').tenantId) {
                assetStore.clearAssetsStore();
            };
            //Read Entity List From Store
            let knownAssets = assetStore.getAssetsData().map(i => i.id);
            let allowedAssets = this.vehicles;
            //Remove From Store
            let toRemove = knownAssets.filter(assetId => !allowedAssets.includes(assetId)); // In Store, not in state.
            if (toRemove.length > 0 && !allowedAssets.includes("ALL")) {
                assetStore.removeAssetsByIds(toRemove);
            };
            //Add To Store
            let toAdd = allowedAssets.filter(assetId => !knownAssets.includes(assetId));  // In State, not in store
            if (knownAssets.length > 1) { toAdd = toAdd.filter(i => i != "ALL") }; 
            if (toAdd.length > 0) {       
                await assetStore.addAssetsByIds(toAdd);
            };
            //Emits
            try {
                Vue.prototype.$replayEventHub.$emit('reload');
            } catch (e) { };
           
        },
        //#endregion

        //#region drivers
        async driverStateChanged() {
            try {
                const driverStore = useDriverStore();
                //Confirm and invalidate if required
                if (this.tenantId != Vue.prototype.$session.get('tenantDetails').tenantId) {
                    driverStore.clearOperatorsStore();
                };
                //Read Entity List From Store
                let knownOperators = driverStore.getOperatorsData().map(i => i.id);
                let allowedOperators = this.drivers;
                //Remove From Store
                let toRemove = knownOperators.filter(operatorId => !allowedOperators.includes(operatorId)); // In Store, not in state.
                if (toRemove.length > 0 && !allowedOperators.includes("ALL")) {
                    driverStore.removeOperatorsByIds(toRemove);
                };
                //Add To Store
                let toAdd = allowedOperators.filter(operatorId => !knownOperators.includes(operatorId));  // In State, not in store
                if (knownOperators.length > 1) { toAdd = toAdd.filter(i => i != "ALL") }; 
                if (toAdd.length > 0) {
                    await driverStore.addOperatorsByIds(toAdd);
                };
                //Emits
                try {
                    Vue.prototype.$replayEventHub.$emit('reload');
                } catch (e) { };
            } catch (err) {
                console.log("driverStateChanged", err);
            };
        },
        //#endregion

        //#region Location

        /** Adds the provided ids to the location store
         * @param {[String]} ids
         * @param {Boolean} refresh
         */
        addLocationIds(ids,refresh = true) {
            this.locations = [...new Set([...this.locations, ...(!Array.isArray(ids) ? [ids] : ids)])].sort();
            if (refresh == true) {
                this.locationStateChanged();
            };
        },

        /** Remove the provided ids from the location store       
         * @param {[String]} ids
         * @param {Boolean} refresh
         */
        removeLocationIds(ids, refresh = true) {
            ids = !Array.isArray(ids) ? [ids] : ids;
            this.locations = this.locations.filter(locationId => !ids.includes(locationId)).sort();
            if (refresh == true) {
                this.locationStateChanged();
            };
        },

        /** Run when the location state has changed
         * To be used to update lists where required
         */
        async locationStateChanged() {
            try {
                const locationStore = useLocationStore();
                //Confirm and invalidate if required
                if (this.tenantId != Vue.prototype.$session.get('tenantDetails').tenantId) {
                    locationStore.clearLocationStore();
                };
                //Read Entity List From Store       
                let knownLocations = locationStore.getLocationsData().map(i => i.id);
                let allowedLocations = this.locations;
                //Remove From Store
                let toRemove = knownLocations.filter(locationId => !allowedLocations.includes(locationId)); // In Store, not in state.
                if (toRemove.length > 0 && !allowedLocations.includes("ALL")) {
                    locationStore.removeLocationsById(toRemove);
                };
                //Add To Store
                let toAdd = allowedLocations.filter(locationId => !knownLocations.includes(locationId));  // In State, not in store
                if (knownLocations.length > 1) { toAdd = toAdd.filter(i => i != "ALL") }; 
                if (toAdd.length > 0) {
                    await locationStore.addLocationsByIds(toAdd);
                };
                //emits
                Vue.prototype.$reportEventHub.$emit('refreshViewer');
                Vue.prototype.$livemapEventHub.$emit('refreshLocations');
            } catch (err) {
                console.log("locationStateChanged", err);
            }; 
        },

        //#endregion

        //#region users
        async userStateChanged() {
            try {
                const userStore = useUserStore();
                //Confirm and invalidate if required
                if (this.tenantId != Vue.prototype.$session.get('tenantDetails').tenantId) {
                    userStore.clearUsersStore();
                };
                //Read Entity List From Store
                let knownUsers = userStore.getUsersData().map(i => i.id);
                let allowedUsers = this.users;
                //Remove From Store
                let toRemove = knownUsers.filter(contactId => !allowedUsers.includes(contactId)); // In Store, not in state.
                if (toRemove.length > 0 && !allowedUsers.includes("ALL")) {
                    userStore.removeUsersByIds(toRemove);
                };
                //Add To Store
                let toAdd = allowedUsers.filter(contactId => !knownUsers.includes(contactId));  // In State, not in store
                if (knownUsers.length > 1) { toAdd = toAdd.filter(i => i != "ALL") }; 
                if (toAdd.length > 0) {
                    await userStore.addUsersByIds(toAdd);
                };
                //emits
            } catch (err) {
                console.log("userStateChanged", err);
            }; 
        },
        //#endregion

        //#region contacts
        async contactStateChanged() {
            try {
                const contactStore = useContactStore();
                //Confirm and invalidate if required
                if (this.tenantId != Vue.prototype.$session.get('tenantDetails').tenantId) {
                    contactStore.clearContactsStore();
                };
                //Read Entity List From Store
                let knownContacts = contactStore.getContactsData().map(i => i.id);
                let allowedContacts = this.contacts;
                //Remove From Store
                let toRemove = knownContacts.filter(contactId => !allowedContacts.includes(contactId)); // Known Not Allowed.
                if (toRemove.length > 0 && !allowedContacts.includes("ALL")) {
                    contactStore.removeContactsByIds(toRemove);
                };
                //Add To Store
                let toAdd = allowedContacts.filter(contactId => !knownContacts.includes(contactId));  // Allowed Not Known
                if (knownContacts.length > 1) { toAdd = toAdd.filter(i => i != "ALL") }; 
                if (toAdd.length > 0) {
                    await contactStore.addContactsByIds(toAdd);
                };
                //emits
            } catch (err) {
                console.log("contactStateChanged", err);
            }; 
        },
        //#endregion

        //#region Groups
        getGroupList() {
            return this.tenantId == (Vue.prototype.$session.get('tenantDetails')?.tenantId || '')
                ? this.groups
                : [];
        },
        //#endregion

    },
    persist:true,
})
