import {
    filterBy,
    orderBy
} from '@progress/kendo-data-query';
import {
    saveExcel
} from '@progress/kendo-vue-excel-export';
import GridPersistence from './grid-persistence';
import {
    getGridPdf
} from './grid-exports';
import {
    NotificationService
} from "../../service/index";
import MessageService from "../../service/message.service"
import { isNumber } from 'highcharts';
import Vue from 'vue';
export default {
    data() {
        return {
            dataItems: [],
            filtered: [],
            result: [],
            skip: 0,
            take: 10,
            sort: [],
            allowUnsort: true,
            pageable: {
                buttonCount: 5,
                info: true,
                type: 'numeric',
                pageSizes: [100, 50, 25, 10],
                previousNext: true
            },
            filter: {
                filters: [],
                logic: 'and'
            }
        };
    },
    mixins: [GridPersistence],
    computed: {
        selected: {
            get() {
                return this.dataItems.filter(item => { // use to be this.filtered
                    return item.selected;
                });
            }
        },
        isAllSelected: {
            get() {
                return this.dataItems.findIndex(item => item.selected === false) === -1;
            },
            set(value) {
                if (this.filter.filters.length != 0) {
                    this.dataItems.forEach(c => {
                        if (this.filtered.includes(c)) {
                            c.selected = value;
                        }; 
                    });
                } else {
                    this.dataItems.forEach(c => {
                        c.selected = value;
                    });
                };
            }
        },
        sortable() {
            return {
                allowUnsort: this.allowUnsort,
                mode: 'single'
            };
        },
        total() {
            return this.filtered ? this.filtered.length : 0;
        }
    },
    watch: {
        dataItems() {
            this.setResult();
        }
    },
    created() {
        this.take = this.getPageSize();
        this.setColumnVisibilityAndOrder();
        this.setColumnWidths();
        this.setColumnFilters();
    },
    methods: {
        setColumnConfig() {
            this.columnConfig.widths = this.columns.map(c => c.field + ':' + (c.width === undefined ? '0' : c.width)).toString();
            this.columnConfig.sort = this.sort.map(s => s.field + ':' + s.dir).toString();
            this.columnConfig.columns = this.columns.filter(c => !c.hidden).sort((a, b) => {
                if (a.orderIndex === undefined || b.orderIndex === undefined) {
                    return 1;
                }
                return (a.orderIndex > b.orderIndex) ? 1 : -1;
            }).map(s => s.field).toString();

            this.saveColumnConfig();
        },
        setColumnVisibilityAndOrder() {
            if (this.columnConfig.columns === undefined) {
                return;
            }
            this.columns.forEach(c => {
                c.hidden = true;
                c.visible = false;
            });
            let columns = this.columnConfig.columns.split(',');
            for (let orderIndex = 0; orderIndex < columns.length; orderIndex++) {
                let column = columns[orderIndex];
                this.columns.forEach(c => {
                    if (c.field === column || c.field === 'selected') {
                        c.orderIndex = (c.field === 'selected') ? 0 : orderIndex;
                        c.hidden = false;
                        c.visible = true;
                    }
                    if (c.field === '' || c.field === undefined) {
                        c.orderIndex = orderIndex;
                        c.hidden = false;
                        c.visible = true;
                    }
                });
            }
        },
        setColumnWidths() {
            if (this.columnConfig.widths === undefined) {
                return;
            }
            const columns = this.columnConfig.widths.split(',');
            columns.forEach(c => {
                const fragments = c.split(':');
                const field = fragments[0];
                const width = fragments[1];
                const column = this.columns.find(c => c.field === field);
                if (column && column.resizable === undefined) {
                    column.resizable = true;
                }
                if (width !== undefined && width != 0 && column && column.resizable) {
                    column.width = width;
                }
            });
        },
        setColumnFilters() {
            if (this.columnConfig.sort === undefined || this.columnConfig.sort === null) {
                return;
            }
            this.sort.length = 0;
            let sorts = this.columnConfig.sort.split(',');
            sorts.forEach(v => {
                let sort = v.split(':');
                this.sort.push({
                    field: sort[0],
                    dir: sort[1]
                });
            });
        },
        columnResize(e) {
            if (e.end) {
                this.columns.forEach(column => {
                    let mapped = e.columns.find(c => c.field === column.field);
                    if (mapped !== undefined) {
                        if (mapped.width !== undefined) {
                            if (isNaN(mapped.width)) {
                                column.width = mapped.width;
                            } else {
                                column.width = Math.floor(mapped.width) + 'px';
                            }
                        }
                    }
                });
                this.setColumnConfig();
            }
        },
        columnReorder(options) {
            this.columns = this.columns.map(column => {
                let fields = options.columns.filter(c => c.field === column.field);
                if (fields.length > 0) {
                    column.orderIndex = fields[0].orderIndex;
                } else {
                    delete column.orderIndex;
                }
                return column;
            });
            this.setColumnConfig();
        },
        exportPDF() {
            this.$refs.exporter.save(this.selected);
        },
        exportExcel(fileNames = "export") {
            var columns = Array.from(this.columns);
            columns.shift();
            saveExcel({
                data: this.selected.filter(function (c) {
                    return c.selected;
                }),
                fileName: typeof (fileNames) != 'string' ? "export" : fileNames,
                columns: columns,
                avoidLinks: true
            });
        },
        exportEmail(email) {

            getGridPdf(this.$refs.dataGrid, {
                paperSize: "auto",
                margin: {
                    left: "1cm",
                    top: "1cm",
                    right: "1cm",
                    bottom: "1cm"
                }
            }, this.selected, function (result) {
                MessageService.sendEmail(email, result, Vue.prototype.$session.get("tenantDetails").userId, Vue.prototype.$session.get("tenantDetails").tenantId).
                    then(function (response) {
                        //this.$toast.show(response.apiMessage);
                    });
            });
        },
        pageChangeHandler: function (event) {
            this.skip = event.page.skip;
            this.take = event.page.take;
            if (this.columnConfig.pagination !== this.take) {
                this.columnConfig.pagination = this.take;
                this.setColumnConfig();
            }
            this.setResult();
        },
        filterChange: function (ev) {
            this.skip = 0;
            if (ev.filter) {
                ev.filter.title = ev.title || 'CUSTOM';
                ev.filter.type = ev.dataType || 'FILTER';
                ev.filter.filters = ev.filter.filters.flatMap(i => i.filters || i);
            };    
            this.filter = ev.filter;
            this.setResult();
        },
        sortChangeHandler: function (e) {
            this.sort = e.sort;
            this.setColumnConfig();
            this.setResult();
        },
        onHeaderSelectionChange(event) {
            this.isAllSelected = !this.isAllSelected;
        },
        onSelectionChange(event) {
            event.dataItem.selected = !event.dataItem.selected;
        },
        onRowClick(event) {
            event.dataItem.selected = !event.dataItem.selected;
        },
        ensureFilter() {
            if (this.filter === null) {
                this.filter = {
                    filters: [],
                    logic: 'and'
                };
            }
        },
        filterByField(value, field) {
            this.ensureFilter();
            var index = this.filter.filters.findIndex(c => c.field === field)
            if (value == -1) {
                if (index !== -1) {
                    this.filter.filters.splice(index, 1);
                }
                this.setResult();
                return;
            }
            if (index === -1) {
                this.filter.filters.push({
                    field: field,
                    operator: 'eq',
                    value: value
                });
            } else {
                this.filter.filters[index].value = value;
            }
            this.setResult();
        },
        filterDateRange(startDate, endDate, field) {
            this.ensureFilter();
            var startIndex = this.filter.filters.findIndex(c => c.field === field && c.operator == "ge");
            var endIndex = this.filter.filters.findIndex(c => c.field === field && c.operator == "le");
            if (startIndex == -1) {
                this.filter.filters.push({
                    field: field,
                    operator: "ge",
                    value: startDate.toJSON(),
                });
                this.filter.filters.push({
                    field: field,
                    operator: "le",
                    value: endDate.toJSON(),
                });
            } else {
                this.filter.filters[startIndex].value = startDate.toJSON();
                this.filter.filters[endIndex].value = endDate.toJSON();
            }
            this.setResult();
        },
        getFilterResults() {
            let results;
            if (!Array.isArray(this.dataItems)) {
                return [];
            } else {
                return filterBy(this.dataItems, this.filter);
            };
        },
        setResult() {
            let results;
            if (!Array.isArray(this.dataItems)) {
                this.result = [];
                return [];
            }
            this.filtered = filterBy(this.dataItems, this.filter);
            let knownFields = this.dataItems?.length > 0 ? Object.keys(this.dataItems[0]) : [];
            if (this.sort && this.sort.length > 0 && knownFields.map(i => i.toLowerCase()).includes(this.sort[0].field.toLowerCase() + 'utc')) {
                //We have a sort on a field that has a UTC Partner - Sort based on the UTC Partner
                let newSort = this.sort.map(i => ({
                    ...i,
                    field: knownFields.map(i => i.toLowerCase()).includes(this.sort[0].field.toLowerCase() + 'utc')
                        ? knownFields.find(f => f.toLowerCase() == this.sort[0].field.toLowerCase() + 'utc')
                        : i.field
                }));
                results = orderBy(this.filtered, newSort);
            }
            else if (this.filtered?.length > 0 && this.sort
                ? this.sort && this.sort.length > 0 ? typeof this.dataItems[0][this.sort[0].field] === 'object' : false
                : false) {
                let sortBy = this.sort[0].field
                let tempResult = [...this.filtered.map(i => {
                    i[sortBy] = JSON.stringify(i[sortBy])
                    return i;
                })];
                let sortedResult = orderBy(tempResult, this.sort)
                results = sortedResult.map(i => {
                    i[sortBy] = JSON.parse(i[sortBy])
                    return i;
                });
            } else {
                results = orderBy(this.filtered, this.sort);
            }
            if (isNaN(this.take)) { this.take = 10 };
            results = results.slice(this.skip, this.take + this.skip);
            // Kendo grids doesn't take row index changes properly into account
            this.applyResults(results);
        },

        //Added debounce to manage multiple keystrokes
        applyResults: _.debounce(async function (results) {
            //Set results to a spread array for a shallow copy.
            this.result = [...results];
            //this.result = [];
            //this.$nextTick(() => {
            //    this.result = results;
            //});
        }, 100),


        requiredColumns(columns, mandatoryField) {
            var columnList = [...new Set([
                ...this.columnConfig.columns.split(","),
                ...(Array.isArray(mandatoryField)
                    ? mandatoryField
                    : [mandatoryField || null])].filter(i => i))];
            let columnsUsedForCalculations = columns
                .filter(i => columnList.includes(i.field))
                .map(i => i.dataFields || i.field)
                .join(",")
                .split(",")
                .filter(i => i)
            if (columnsUsedForCalculations.length > 0) {
                columnList = [...new Set([
                    ...columnList,
                    ...columnsUsedForCalculations,
                ])];
            };
            console.log("Required Coluns", columnList);
            return columnList;
        },

        stripFields(data, columnList) {
            console.time('StrippingFields');
            let result = data.map(item => {
                let response = {};
                columnList.forEach(columnKey => {
                    response[columnKey] = item[columnKey]
                });
                return response
            });
            console.timeEnd("StrippingFields")
            return result;
        },
    }
}