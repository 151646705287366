<template>
    <td :class="className">{{result}}</td>
</template>
<script>
    export default {
        name: "LicenseNotificationCell",
        props: {
            field: String,
            dataItem: Object,
            format: String,
            className: String,
            columnIndex: Number,
            columnsCount: Number,
            rowType: String,
            level: Number,
            expanded: Boolean,
            editor: String
        },

        computed: {
            result() {
                return this.dataItem.notificationCounter != null
                    ? this.dataItem.notificationCounter == this.dataItem.noOfAlerts ? "No" : "Yes"
                    : "Yes";
            },
        },
    };
</script>