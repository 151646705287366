<template>
  <div v-if="totalCount > 0" class="paginationContainer">
    <div>
      <ul>
        <li
          @click="previousPageSet();"
          :class="{dissabledLink : minPageNumber == 1 }"
          style="cursor:pointer"
        >&lt;&lt;</li>
        <li
          @click="previousPage()"
          :class="{dissabledLink : selectedPage == 1 }"
          style="cursor:pointer"
        >&lt;</li>
        <li
          v-for="page in filteredPages"
          :key="page"
          @click="selectedPage = page"
          :class="{paginationSuggested : selectedPage == page }"
          style="cursor:pointer"
        >{{page}}</li>
        <li
          style="cursor:pointer"
          @click="nextPage()"
          :class="{dissabledLink : selectedPage == totalPages }"
        >&gt;</li>
        <li
          style="cursor:pointer"
          @click="nextPageSet();"
          :class="{dissabledLink : maxPageNumber == totalPages }"
        >&gt;&gt;</li>
      </ul>
    </div>
    <div class="pagesizeText">Page Size</div>
    <div class="formContainer min-formContainer">
      <md-field>
        <md-select v-model="pageSize" name="pageSize" id="pageSize">
          <md-option :key="option" v-for="option in pageSizeOptions" :value="option">{{option}}</md-option>
        </md-select>
      </md-field>
    </div>
  </div>
</template>
<script>
export default {
  name: "OldBasePagination",
  props: {
    sortOrder: { required: false, default: "asc" },
    sort: { required: false },
    search: { type: Function, required: true }
  },
  watch: {
    sort() {
      this.onSortingChange();
    },
    sortOrder() {
      this.onSortingChange();
    },
    selectedPage(selectedPage) {
      this.onPageChange();
    },
    pageSize(pageSize) {
      this.selectedPage = 0;
      this.resetData();
      this.callService();
    }
  },
  data() {
    return {
      minPageNumber: 1,
      maxPageNumber: 1,
      maxPageButtons: 5,
      totalPages: 1,
      selectedPage: 1,
      pageSize: 10,
      listItems: [],
      totalCount: 0,
      pageSizeOptions: [100, 50, 25, 10],
      pageTokens: {},
      pageTokenIndex: {},
      apiRecordCount: 0,
      defaultTokenValue: "default",
      retainedDataIndex: {},
      apiCallInProgress: false
    };
  },
  computed: {
    filteredPages() {
      const filteredPages = [];
      for (
        let startPageIndex = this.minPageNumber;
        startPageIndex <= this.maxPageNumber;
        startPageIndex++
      ) {
        filteredPages.push(startPageIndex);
      }
      return filteredPages;
    }
  },
  created() {
    this.maxPageNumber = this.maxPageButtons;
    this.callService();
  },
  methods: {
    callService(token = null) {
      token = token == this.defaultTokenValue ? null : token;
      this.emitPagedItems([]);
      if (this.apiCallInProgress == false) {
        this.apiCallInProgress = true;

        let searchResponse = this.search(token);
        if (!searchResponse) {
          return;
        }
        const isArray = Array.isArray(searchResponse);
        if (isArray == true) {
          searchResponse = {
            count: searchResponse.length,
            data: searchResponse
          };
        }
        const self = this;
        Promise.resolve(searchResponse).then(response => {
          self.processServiceRequest(response);
          self.apiCallInProgress = false;
        });
      }
    },
    onPageChange() {
      if (this.selectedPageDataExist()) {
        this.onPageSelect();
      } else {
        const tokenKey = this.pageTokenIndex[this.selectedPage];
        const serviceToken = tokenKey ? this.pageTokens[tokenKey] : null;
        this.callService(serviceToken);
      }
    },
    processServiceRequest(response) {
      this.totalCount = response.count;
      this.listItems = [...response.data];
      this.sortList();
      const token = response.nextLink;
      this.calculateTotalPages();
      if (token) {
        this.apiRecordCount = response.data.length;
        this.createPageTokenIndexes();
        this.savePageToken(response.nextLink);
      }
      this.resetDataRetainIndex();
      this.updateDataRetainIndex();
      this.onPageSelect();
    },
    nextPage() {
      if (this.selectedPage == this.maxPageNumber) {
        this.nextPageSet();
      } else {
        ++this.selectedPage;
      }
    },
    nextPageSet() {
      this.initializePageRanges(true);
      this.selectedPage = this.minPageNumber;
    },
    previousPage() {
      if (this.selectedPage == this.minPageNumber) {
        this.previousPageSet();
      } else {
        --this.selectedPage;
      }
    },
    previousPageSet() {
      this.initializePageRanges(false);
      this.selectedPage = this.maxPageNumber;
    },
    calculateTotalPages() {
      this.totalPages = Math.ceil(this.totalCount / this.pageSize);
      this.initializePageRanges();
    },
    createPageTokenIndexes() {
      const pagePopulateCount = Math.ceil(this.apiRecordCount / this.pageSize);
      let count = 1;
      let min = 0;
      for (let index = 1; index <= this.totalPages; index++) {
        if (count == 1) {
          min = index;
        }

        if (
          (index == this.totalPages || count == pagePopulateCount) &&
          min != 0
        ) {
          const key = `${min}-${index}`;
          this.pageTokens[key] = null;
          for (let tokenIndex = min; tokenIndex <= index; tokenIndex++) {
            this.pageTokenIndex[tokenIndex] = key;
          }
          count = 1;
          min = 0;
          continue;
        }
        count++;
      }
      for (var prop in this.pageTokens) {
        this.pageTokens[prop] = this.defaultTokenValue;
        break;
      }
    },

    savePageToken(token) {
      const tokenRangeKeys = Object.keys(this.pageTokens);
      const tokenKey = this.pageTokenIndex[this.selectedPage];
      const serviceToken = this.pageTokens[tokenKey];
      const pagePopulateCount = Math.ceil(this.apiRecordCount / this.pageSize);

      if (serviceToken == this.defaultTokenValue) {
        this.pageTokens[tokenRangeKeys[1]] = token;
      }

      if (!serviceToken && token) {
        const tokenRanges = tokenKey.split("-");
        const min = tokenRanges[0] + 1 + pagePopulateCount;
        const max = tokenRanges[1] + 1 + pagePopulateCount;
        const updatedToken = `${min}-${max}`;
        this.pageTokens[updatedToken] = token;
      }
    },
    selectedPageDataExist() {
      const tokenKey = this.pageTokenIndex[this.selectedPage];
      const value = this.retainedDataIndex[tokenKey];
      return value == true;
    },
    updateDataRetainIndex() {
      const tokenKey = this.pageTokenIndex[this.selectedPage];
      this.retainedDataIndex[tokenKey] = true;
    },
    resetDataRetainIndex() {
      const pageRangeDataIndexes = Object.keys(this.pageTokens);
      if (pageRangeDataIndexes && pageRangeDataIndexes.length > 0) {
        pageRangeDataIndexes.forEach(key => {
          this.retainedDataIndex[key] = false;
        });
      }
    },
    getPageMinMaxRange(next = null) {
      let minPageNumber = this.minPageNumber;
      let maxPageNumber = this.maxPageNumber;
      const totalPages = this.totalPages;
      const maxPageButtons = this.maxPageButtons;

      if (next == true) {
        minPageNumber += maxPageButtons;
        maxPageNumber += maxPageButtons;
      } else if (next == false) {
        minPageNumber -= maxPageButtons;
        maxPageNumber -= maxPageButtons;
      }

      if (maxPageNumber > totalPages) {
        maxPageNumber = totalPages;
        minPageNumber = maxPageNumber - maxPageButtons + 1;
      }

      if (maxPageNumber >= minPageNumber) {
        minPageNumber = maxPageNumber - maxPageButtons + 1;
      }

      if (minPageNumber < 1) {
        minPageNumber = 1;
        const max = minPageNumber + maxPageButtons - 1;
        maxPageNumber = max > totalPages ? totalPages : max;
      }

      return {
        minPageNumber: minPageNumber,
        maxPageNumber: maxPageNumber
      };
    },
    initializePageRanges(next = null) {
      const pageRange = this.getPageMinMaxRange(next);
      this.minPageNumber = pageRange.minPageNumber;
      this.maxPageNumber = pageRange.maxPageNumber;
    },
    onSortingChange() {
      this.sortList();
      this.selectedPage = 1;
      this.onPageChange();
    },
    onPageSelect() {
      const tokenKey = this.pageTokenIndex[this.selectedPage];
      let min = 1;
      if (tokenKey) {
        const ranges = tokenKey.split("-");
        min = ranges[0];
      }
      const listPageNumber = this.selectedPage - min;

      const paginatedList = this.paginateData(
        this.listItems,
        listPageNumber,
        this.pageSize
      );
      this.emitPagedItems(paginatedList);
    },
    emitPagedItems(list) {
      this.$emit("pageItems", list);
    },
    paginateData(list, page, pageCount) {
      let startIndex = 0;
      const selectedPage = page;
      if (selectedPage != 0) {
        startIndex = selectedPage * pageCount;
      }
      const endIndex = startIndex + pageCount;
      return list.slice(startIndex, endIndex);
    },
    resetData() {
      this.resetDataRetainIndex();
      this.pageTokens = {};
      this.pageTokenIndex = {};
      this.selectedPage = 1;
      this.totalCount = 0;
      this.listItems = [];
      this.minPageNumber = 1;
      this.maxPageNumber = this.maxPageButtons;
    },
    sortList() {
      const sort = this.sort;
      if (!this.sort) {
        return;
      }
      if (this.sortOrder == "desc") {
        this.listItems.sort(function(a, b) {
          return (b[sort] || "!!!")
            .toUpperCase()
            .localeCompare((a[sort] || "!!!").toUpperCase());
        });
      } else {
        this.listItems.sort(function(a, b) {
          return (a[sort] || "|||")
            .toUpperCase()
            .localeCompare((b[sort] || "|||").toUpperCase());
        });
      }
    }
  }
};
</script>
<style scoped>
.paginationLabel {
  padding-top: 3px;
  margin-right: 10px;
}
.pagesize {
  background: none;
  border: none;
  border: 1px solid #ccc;
  color: #666;
  padding: 5px;
  height: 30px;
  border-radius: 3px;
  margin-top: 6px;
  width: 63px;
}
.paginationContainer {
  display: flex;
  align-items: center;
  bottom: 0px !important;
  position: absolute !important;
  padding: 5px !important;
}
.paginationContainer ul {
  padding-left: 10px;
}
.paginationContainer ul li {
  border-radius: 3px;
  padding: 5px 15px;
  margin-right: 10px;
  background: #c5cace;
  color: #666;
  display: inline-block;
  margin-top: 10px;
}
.paginationSuggested {
  background: #288dc1 !important;
  color: #fff !important;
}
.dissabledLink {
  color: #fff !important;
  pointer-events: none !important;
}
.gotoPage {
  display: flex;
  align-items: center;
  color: #666;
}
.simpleSelect .md-layout-item {
  border: 1px solid #ccc;
  height: 30px;
  border-radius: 3px;
  padding-left: 5px;
}
.simpleSelect .md-icon {
  margin: 0;
}

.simpleSelect .md-field.md-theme-default:after {
  background: transparent !important;
}
.simpleSelect {
  padding-top: 10px;
}
.simpleSelect .md-menu.md-select {
  border-bottom: none !important;
  height: 28px !important;
  overflow: hidden;
  width: 80px !important;
}
.simpleSelect .md-field {
  margin: 0 !important;
  min-height: 30px;
  padding-top: 0 !important;
}
.min-formContainer {
  width: 80px !important;
  margin-left: 10px;
  height: 32px;
}
</style>


