import { ConfigurationService } from "../service";

const state = () => ({})

const getters = {}

const actions = {
    saveUserConfig({ commit }, payload) {
        return ConfigurationService.saveUserConfig(payload.id, payload.body).then(() => {
            ///? TODO: Update User Config
        });
    },
}

const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
