<template>
    <md-drawer class="md-right whiteTab messageNotificationDrawer overflow"
               :class="{'display-none':showNotificationlist?false:true}"
               :md-active.sync="showNotificationlist"
               md-swipeable
               md-right
               style="background-color: #f6f8f8 !important; z-index:200 !important;">
        <v-tabs v-model="selectedTab" grow style="background-color : #002B49" color="#002B49">
            <v-tab v-show="selectedTab == 0">
                {{$t('Dashboard.message')}}
            </v-tab>
            <v-tab v-show="selectedTab == 1">

                {{$t("CustomeViews.alertNotifications")}}
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="selectedTab">
            <v-tab-item>
                <div v-if="showCurrentChat == false">
                    <div class="messageactionArea">
                        <div class="messageactionAreaCol" v-if="showInput == false">
                            <md-button class="md-icon-button md-primary" v-on:click="showInputbox()">
                                <md-icon>add</md-icon>
                            </md-button>
                        </div>
                        <div class="messageactionAreaCol" v-if="showInput == true">
                            <md-button class="md-icon-button md-primary" @click="closeInput()">
                                <md-icon>remove</md-icon>
                            </md-button>
                        </div>
                        <div class="messageactionAreaCol" v-if="showInput == true">
                            <autocomplete v-if="driverList"
                                          ref="autocomplete"
                                          :placeholder="$t('GroupLable.search')"
                                          :source="driverList"
                                          class="form-control"
                                          @selected="openNewChannel"></autocomplete>
                        </div>
                        <div class="messageactionAreaCol" v-if="showInput == false">
                            <md-button class="md-icon-button md-primary" @click="close()">
                                <md-icon>close</md-icon>
                            </md-button>
                        </div>
                    </div>

                    <div class="conversation">
                        <div class="conversationCount">{{$t("CustomeViews.conversation")}}:{{totalConversation}}</div>
                        <md-divider></md-divider>
                        <v-badge :content="msg.unreadMessages"
                                 :value="msg.unreadMessages"
                                 color="#00C1D4"
                                 inline
                                 v-for="(msg,index) in messages"
                                 :key="msg.id"
                                 style="width: 100%; border-bottom: 1px solid #999999; ">
                            <div class="conversationList"
                                 v-on:click="openChannel(msg)">
                                <div class="listsortName">{{msg.receiverName.length > 10 ? `${msg.receiverName.substring(0, 10)}...` : msg.receiverName}}</div>
                                <div class="messagelistDescription">
                                    <div class="messageDescName">{{msg.message.length > 20 ? `${msg.message.substring(0, 20)}...` : msg.message}}</div>
                                    <div class="messageDescDate">{{getDateTime(msg.timestamp)}}</div>
                                </div>
                            </div>
                        </v-badge>
                    </div>
                </div>

                <div v-if="showCurrentChat == true">

                    <div class="closeMessageBtn">
                        <div class="closeMessageName">{{displayName}}</div>
                        <v-spacer></v-spacer>
                        <md-button @click="hideMsg()">
                            <md-icon>close</md-icon>
                        </md-button>
                    </div>
                    <md-divider></md-divider>

                    <div v-if="messagesList.length == 0">
                        <div class="listsortName">{{selectedDriver}}</div>
                    </div>
                    <div v-if="showmessagelist == false" class="conversationOverflow" id="messages">
                        <div v-for="(msg,index) in messagesList" :key="msg.id">
                            <div class="receivedMessage" v-if="msg.senderId != userData.userId">
                                <div class="received-bubble">
                                    <p style="color: darkslategray">{{msg.senderName}}</p>
                                    <p v-if="checklocation(msg.message) == true">{{msg.message}}</p>
                                    <p v-if="checklocation(msg.message) == false" v-on:click="openlocation(msg.message)"><button style="font-size:24px">{{$t("CustomeViews.sharedLocation")}}<i class="fa fa-map-marker"></i></button></p>
                                    <p>{{getDateTime(msg.timestamp)}}</p>
                                </div>
                            </div>
                            <div class="sendMessage" v-if="msg.senderId == userData.userId">
                                <div class="send-bubble">
                                    <p style="color: darkslategray">{{msg.senderName}}</p>
                                    <p v-if="checklocation(msg.message) == true">{{msg.message}}</p>
                                    <p v-if="checklocation(msg.message) == false" v-on:click="openlocation(msg.message)"><button style="font-size:24px">{{$t("CustomeViews.sharedLocation")}}<i class="fa fa-map-marker"></i></button></p>
                                    <p>{{getDateTime(msg.timestamp)}}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div v-if="showCurrentChat"
                     class="messageEntryPoint replyFrame">
                    <div style="display:flex; flex:1;">
                        <input type="text"
                               name="message"
                               class="messageTextbox"
                               :placeholder="$t('CustomeViews.enterMessage')"
                               v-model="newMessage" maxlength="100" />
                    </div>
                    <!--<v-btn v-if="showShareLocation()"
                       @click="shareLocation"
                       icon><v-icon>location_on</v-icon></v-btn>-->
                    <v-btn @click="sendMsg"
                           icon><v-icon>send</v-icon></v-btn>
                </div>
                </div>
            </v-tab-item>
            <v-tab-item>
                <AlertNotification @count="$emit('count',$event)"></AlertNotification>
            </v-tab-item>
        </v-tabs-items>
    </md-drawer>
</template>
<script>
    import moment from "moment";
    import Autocomplete from "vuejs-auto-complete";
    import MessageService from "../../../service/message.service";

    export default {
        name: "Message",
        components: {
            Autocomplete
        },
        props: {
            value: { type: Boolean, required: false, default: false },
            data: { required: false }
        },
        watch: {
            showNotificationlist: async function (showNotificationlist) {
                await clearInterval(this.getMessagesInterval)
                this.$emit("input", showNotificationlist);
            },
            value: function (value) {
                this.showNotificationlist = value;
                if (value) {
                    if ((!this.driverList) || (!this.driverList.length)) {
                        this.getAllDriver();
                    }
                } else {
                    if (this.selectedTab == "notification") {
                        this.$eventBus.$emit("notificationUpdate", this.selectedTab);
                    }
                }
            },
            data:
            {
                immediate: true,
                handler(value) {
                    if (value == "messaging") {
                        this.selectedTab = 0
                    } else {
                        this.selectedTab = 1
                    }
                    
                }
            },
            showCurrentChat: {
                handler(value) {
                    if (value && this.showNotificationlist) {
                        clearInterval(this.getMessagesInterval)
                        this.getMessagesInterval = setInterval(() => {
                            this.messagesList.splice(0)
                            MessageService.getAllMessagesInThread(this.threadId).then(response => {
                                response.messages.forEach(message => {
                                    this.messagesList.push({
                                        senderId: message.senderId,
                                        receiverId: message.recipients[0].receiverId,
                                        receiverName: message.recipients[0].ReceiverName,
                                        message: message.message,
                                        senderName: message.senderName,
                                        timestamp: message.timeStampEpoch,
                                    })
                                    this.receiverId = message.senderId != this.userData.userId ? message.senderId : message.recipients[0].receiverId
                                })
                                MessageService.UpdateReadAcknowledgementForUser(this.threadId)
                            })
                        }, 30000)
                    } else {
                        clearInterval(this.getMessagesInterval)
                    }
                }
            }
        },
        data() {
            return {
                window: {
                    width: 0,
                    height: 0
                },
                // showNotificationlist: false,
                messages: [],
                previousMsg: false,
                showInput: false,
                newMessage: "",
                showCurrentChat: false,
                activeChatObject: {},
                sortedObject: [],
                messagedrawer: false,
                showNotificationlist: this.value,
                userData: this.$session.get("tenantDetails"),
                channel: "",
                currentUser: "",
                showmessagelist: false,
                msgList: [],
                totalConversation: "",
                messagesList: [],
                selectedDriver: '',
                threadId: null,
                receiverId: null,
                selectedTab: "notification",
                getMessagesInterval: null,
                displayName: null,
            };
        },
        computed: {
            getOperatorsData() { return this.$driverStore.getOperatorsData(); },
            driverList() {
                return this.getOperatorsData.map(datalist => ({
                    id: datalist.id,
                    name: datalist.displayName
                }));
            },
        },
        methods: {
            async getOperators() { await this.$driverStore.getOperators(); },
            close() {
                this.threadId = null
                this.showNotificationlist = false;
            },

            handleResize() {
                this.window.height = window.innerHeight - 355;

            },
            openlocation(msg) {
                this.$parent.messageDrawer = false;
                let checklocationString = msg.split('^');
                if (this.$checkRBACAccess('livemapRead')) {
                    const data = {
                        name: "livemapRead",
                        params: {
                            latitude: checklocationString[0],
                            longitude: checklocationString[1],
                        }
                    };
                    this.$router.push(data);
                }
            },
            displayLocation(msg) {
                let checklocationString = msg.split('^');
                if (checklocationString.length > 1) {
                    return '<button style="font-size:24px">Shared Location<i class="fa fa-map-marker"></i></button>';
                }
            },
            checklocation(msg) {
                let checklocationString = msg.split('^');
                if (checklocationString.length > 1) {
                    return false;
                } else {
                    return true
                }
            },
            getDateTime(timeStamp) {
                return moment.unix(timeStamp).format(this.$getUserDateTimeFormat(false));
            },
            showInputbox() {
                this.showInput = true;
            },
            closeInput() {
                this.threadId = null
                this.showInput = false;
            },
            hideMsg() {
                this.threadId = null
                this.loadMessages()
                this.showCurrentChat = false;
                this.showmessagelist = false;
            },
            openNewChannel(user) {
                this.messagesList.splice(0)
                this.threadId = null
                MessageService.getAllSenderMessages().then(response => {
                    if (response) {
                        response.forEach(item => {
                            if (item.messages[item.messages.length - 1].senderId == user.value || item.messages[item.messages.length - 1].recipients[0].receiverId == user.value) {
                                MessageService.getAllMessagesInThread(item.id).then(results => {
                                    results.messages.forEach(msg => {
                                        this.messagesList.push({
                                            senderId: msg.senderId,
                                            receiverId: msg.recipients[0].receiverId,
                                            receiverName: msg.recipients[0].ReceiverName,
                                            message: msg.message,
                                            senderName: msg.senderName,
                                            timestamp: msg.timeStampEpoch,
                                        })
                                    })
                                })
                                this.threadId = item.id
                            }
                            
                        })
                    }
                    this.receiverId = user.value
                    this.displayName = user.display
                    this.showCurrentChat = true;
                })
            },
            async openChannel(msg) {
                this.messagesList.splice(0)
                await MessageService.getAllMessagesInThread(msg.threadId).then(response => {
                    response.messages.forEach(message => {
                        this.messagesList.push({
                            senderId: message.senderId,
                            receiverId: message.recipients[0].receiverId,
                            receiverName: message.recipients[0].ReceiverName,
                            message: message.message,
                            senderName: message.senderName,
                            timestamp: message.timeStampEpoch,
                        })
                        this.receiverId = message.senderId != this.userData.userId ? message.senderId : message.recipients[0].receiverId
                    })
                })
                if (msg.unreadMessages) {
                    await MessageService.UpdateReadAcknowledgementForUser(msg.threadId)
                    this.$emit("messageCount");
                }
                
                this.threadId = msg.threadId
                this.displayName = msg.receiverName
                this.showCurrentChat = true;
                setTimeout(() => {
                    var element = document.getElementById("messages");
                    element.scroll({ top: element.scrollHeight, behavior: 'smooth' });
                }, 100)
            },
            sendMsg() {
                if (this.newMessage) {
                    let body = {
                        title: this.$t("CustomeViews.newMessage"),
                        body: this.newMessage,
                        senderId: this.userData.userId,
                        userIds: [this.receiverId],
                        tenantId: this.userData.tenantId,
                        auditRequired: true,
                        sendMessage: true,
                        threadId: this.threadId,
                        data: {type: 1}
                    };

                    MessageService.sendPushNotification(body).then(response => {
                        this.newMessage = "";
                        this.openChannel({ threadId: response.Body[0].threadId})
                        //  toast.show(this.$tc("Notification.notificationSend"));
                    },
                        error => {
                            this.newMessage = "";
                            this.threadId = null
                            // toast.error(error.response.data.apiMessage);
                            // return;
                        });
                }
            },
            async getAllDriver() {
                const self = this;
                if (self.getOperatorsData.length == 0) {
                    await self.getOperators()
                }
                self.getOperatorsData.forEach(datalist => {
                    if (self.userData.userId == datalist.id) {
                        self.currentUser = datalist;
                    }
                });
            },
            getLastObject(list) {
                let lastItem = list[list.length - 1];
                return lastItem;
            },
            loadMessages() {
                const userDetail = this.userData;
                this.$emit("messageCount");

                this.messages = []
                MessageService.getAllSenderMessages().then(response => {
                    if (response) {
                        response.forEach(item => {
                            this.messages.push({
                                senderId: item.messages[item.messages.length - 1].senderId,
                                receiverId: item.messages[item.messages.length - 1].recipients[0].receiverId,
                                receiverName: item.messages[item.messages.length - 1].recipients[0].receiverId != this.userData.userId ? item.messages[item.messages.length - 1].recipients[0].ReceiverName : item.messages[item.messages.length - 1].senderName,
                                message: item.messages[item.messages.length - 1].message,
                                senderName: item.messages[item.messages.length - 1].senderName,
                                timestamp: item.messages[item.messages.length - 1].timeStampEpoch,
                                threadId: item.id,
                                unreadMessages: item.numberOfUnreadMessages
                            })
                            
                        })
                    }
                })
            },
            getDriverName(id) {
                return this.driverList.find(item => item.id === id);
            },
            shareLocation() {
                this.$eventBus.$emit('showMap', 'openReplay');
            },

            showShareLocation() {
                if (this.$route.name == 'livemapRead' || this.$route.name == 'replaydetails') {
                    return true;
                } else {
                    return false;
                }
            },
            messageWindow(messageWindow) {
                // const driverName = this.getDriverName(messageWindow);
                this.driverList.find(driverName => {
                    if (driverName.id == messageWindow) {
                        let selectedChannel = {
                            display: driverName.name,
                            selectedObject: {
                                id: driverName.id,
                                name: driverName.name,
                            },
                            value: driverName.id
                        }
                        this.channel = selectedChannel;
                        this.selectedDriver = driverName.name;
                    }
                })
            },
            chatmessageWindow(chatmessageWindow) {
                this.$parent.messageDrawer = true;
                this.newMessage = chatmessageWindow.latlng.lat + "^" + chatmessageWindow.latlng.lng;
                this.sendMsg();
                chatmessageWindow.target.remove();
            },
        },
        created() {
            this.getAllDriver();
            this.loadMessages();
            window.addEventListener('resize', this.handleResize)
            this.handleResize();
            this.$drawerEventHub.$on("openMessageDrawer", this.openNewChannel)
            this.$drawerEventHub.$on("closeMessageDrawer", this.close)
        },
        mounted() {
            let self = this;
            this.$eventBus.$on("messageWindow", this.messageWindow);
            this.$eventBus.$on("chatmessageWindow", this.chatmessageWindow);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.handleResize)
            this.$drawerEventHub.$off("openMessageDrawer", this.openNewChannel)
            this.$drawerEventHub.$on("closeMessageDrawer", this.close)
            this.$eventBus.$off("messageWindow", this.messageWindow);
            this.$eventBus.$off("chatmessageWindow", this.chatmessageWindow);
        },
    };
</script>
<style>
    .overflow {
        overflow-y: auto;
        height: calc(100vh - 80px);
    }

    .conversationOverflow {
        overflow-y: auto;
        height: calc(100vh - 244px);
    }

    .replyFrame {
        position: fixed;
        bottom: 0;
        width: 100%;
    }

    .conversation {
        overflow-y: auto;
        height: calc(100vh - 188px);
        background-color: #ccc;
    }

    .md-overlay {
        z-index: 8 !important;
    }

    .conversationCount {
        color: #000;
        font-size: 17px;
        font-weight: bold;
        padding: 20px;
    }

    .conversationList {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 100%
    }

    .extendedconversation {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        border-bottom: 1px solid #999999;
    }

    .listsortName {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 17px;
        white-space: nowrap;
        width: 150px;
        overflow: hidden;
    }

    .messageDescName {
        flex: 1;
        font-size: 16px;
        color: #40C1AC;
        font-weight: normal;
        white-space: nowrap;
    }

    .extendedmessageDescName {
        flex: 1;
        font-size: 20px;
        color: #40C1AC;
        font-weight: normal;
    }

    .sendMessage {
        padding: 10px 30px;
        overflow: hidden;
    }

    .receivedMessage {
        padding: 10px 40px;
        overflow: hidden;
    }

    .send-bubble {
        position: relative;
        background: #40C1AC;
        border-radius: 3px;
        padding: 10px;
        color: #fff;
        float: right;
        min-width: 100px;
        word-break: break-all;
        max-width: 300px;
    }

        .send-bubble:after {
            content: "";
            position: absolute;
            right: 0;
            top: 50%;
            width: 0;
            height: 0;
            border: 20px solid transparent;
            border-left-color: #40C1AC;
            border-right: 0;
            border-bottom: 0;
            margin-top: -10px;
            margin-right: -20px;
        }

    .received-bubble {
        position: relative;
        background: rgb(183, 184, 184);
        border-radius: 3px;
        color: #000;
        padding: 10px;
        float: left;
        min-width: 100px;
        word-break: break-all;
        max-width: 300px;
    }

        .received-bubble:after {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            width: 0;
            height: 0;
            border: 20px solid transparent;
            border-right-color: rgb(183, 184, 184);
            border-left: 0;
            border-bottom: 0;
            margin-top: -10px;
            margin-left: -20px;
        }

    .messageEntryPoint {
        padding: 10px 20px;
        border-top: 1px solid #999999;
        border-bottom: 1px solid #999999;
        display: flex;
        align-items: center;
    }

    .getPreviousMessage {
        border: 1px solid #288dc1;
        padding: 10px;
        color: #288dc1;
        overflow: hidden;
        float: left;
        margin: 10px;
    }

    .messageTextbox {
        background: none;
        border: none;
        border-radius: 3px;
        border: 1px solid #999999;
        padding: 10px;
        color: #666;
        background: #fff;
        display: flex;
        flex: 1;
    }

    .tabcolor {
        color: white;
        background-color: #002B49;
    }

    .buttonsRow {
        height: 50%;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .whiteTab .md-tabs .md-tabs-navigation {
        display: flex !important;
        background: #288dc0 !important;
    }

        .whiteTab .md-tabs .md-tabs-navigation .md-ripple .md-button-content {
            color: #fff !important;
        }

        .whiteTab .md-tabs .md-tabs-navigation .md-button {
            display: flex !important;
            flex: 1 !important;
        }

    .messageactionArea {
        display: flex;
        padding: 10px 20px;
        background: #fff;
    }

    .md-tabs-container {
        background: #ccc !important;
    }

    .messageactionAreaCol {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
    }

    .autocomplete__inputs {
        padding: 10px !important;
    }

    .messageNotificationDrawer .autocomplete__results {
        max-height: 200px;
        height: 200px;
        overflow-x: auto;
    }

    .closeMessageBtn {
        display: flex;
        width: 100%;
        flex: 1;
        align-items: center;
        /*justify-content: flex-end;*/
        background: #fff;
    }

    .closeMessageName {
        padding-left: 12px;
    }

    .messageNotificationDrawer {
        top: 80px;
        overflow-y: hidden;
    }

    .messageNotificationDrawer .md-tabs-content {
        height: calc(100vh - 128px) !important;
    }

    .send-bubble button {
        background: none;
        border: none;
        background-color: #fff;
        color: #666;
        padding: 5px;
        font-size: 15px !important;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

        .send-bubble button i {
            margin-left: 10px;
            font-size: 20px;
        }

    .display-none {
        display: none;
    }
</style>