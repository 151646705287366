import Vue from 'vue';
import axios from 'axios';

class dashboardService {

    getAllDashboard() {
        return axios.get(`/api/dashboard/GetAllDashboard`);
    }

    getDashboardById(body) {
        const confiqData = Vue.prototype.$session.get("configProperties");
        body.config = {
            "distanceMesurementUnit": confiqData.distanceMesurementUnit === "km" ? "Km" : "Miles",
            "timeFormat": confiqData.timeFormat,
            "dateFormat": confiqData.dateFormat,
            "driverDisplayName": confiqData.driverDisplayName,
            "driverInfoFormat": confiqData.driverInfoFormat,
            "defaultVehicleId": confiqData.defaultVehicleId,
            "selectedTimeZone": confiqData.timeZone
        }
        return axios.post(`/api/dashboard/GetDashboardById`, body);
    }

    getWidgetData(url, body) {
        const confiqData = Vue.prototype.$session.get("configProperties");
        body.config = {
            "distanceMesurementUnit": confiqData.distanceMesurementUnit === "km" ? "Km" : "Miles",
            "timeFormat": confiqData.timeFormat,
            "dateFormat": confiqData.dateFormat,
            "driverDisplayName": confiqData.driverDisplayName,
            "driverInfoFormat": confiqData.driverInfoFormat,
            "defaultVehicleId": confiqData.defaultVehicleId,
            "selectedTimeZone": confiqData.timeZone
        }
        return axios.post(`/api/dashboard/GetWidgetData`, body, { params: { url: url } });
    }

    getMappedDriverFromVehicles(body) {
        return axios.post(`/api/dashboard/GetMappedDriverFromVehicles`, body);
    }

}
export default new dashboardService();