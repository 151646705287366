<template>
    <v-card>
        <div class="ctrack-md-dialog-content">
            <v-toolbar dark color="#002B49" style="margin-bottom: 16px;">
                <v-toolbar-title>{{$t("Dashboard.filter")}} </v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <div class="ctrack-modal-body">
                <div class="md-filter-modal-form">

                    <div class="ctrack-row">
                        <div class="ctrack-col-12">
                            <div class="md-filter-form-froup">
                                <label class="md-filter-form-lbl">{{$t("Dashboard.businessGroup")}}</label>
                                <div class="md-selectbox-style2 ctrack-select-icon1">
                                    <md-field>
                                        <md-select v-model="businessGroup" md-class="md-filter-selectMenu" name="businessGroup" id="businessGroup"
                                                   multiple placeholder="Select" md-dense @md-selected="selectAll('Group')" @md-closed="removeSelectGroupValue('Group')" @md-opened="addSelectGroupValue('Group')">
                                            <md-field class="search-group" md-clearable>
                                                <md-input v-model="groupSearch" :placeholder="$tc('Dashboard.searchGroup')"></md-input>
                                            </md-field>
                                            <md-option value="All" v-if="groupSearch=='' || ('all').includes(groupSearch.toLowerCase())">{{$t("Dashboard.all")}}</md-option>
                                            <!-- <md-option value="AllUnassigned" v-if="groupSearch=='' || ('all').includes(groupSearch.toLowerCase())">{{$t("Dashboard.AllUnassigned")}}</md-option>                         -->
                                            <md-option v-for="item in filteredBusinessGroupArray" :key="item.id" :value="item.id">
                                                {{item.groupName}}
                                            </md-option>
                                        </md-select>
                                    </md-field>
                                    <!-- <Multiselect v-model="businessGroup" :multiple="true" :options="businessMechanism"></Multiselect> -->
                                </div>
                                <div class="md-filter-labels">
                                    <label class="md-filter-label-items" v-for="(itemData, index) in businessGroupName" :key="index">
                                        {{ (itemData.groupName=='All') ? $t("Dashboard.all") : itemData.groupName}}
                                        <span class="md-filter-label-close" v-if="itemData.groupName=='All'" @click="removeBusinessGroup('all')">
                                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8"><path d="M1100.707,327.293a1,1,0,1,1-1.414,1.414L1097,326.414l-2.293,2.293a1,1,0,0,1-1.414-1.414l2.293-2.293-2.293-2.293a1,1,0,0,1,1.414-1.414l2.293,2.293,2.293-2.293a1,1,0,0,1,1.414,1.414L1098.414,325Z" transform="translate(-1093 -321)" /></svg>
                                        </span>
                                        <span class="md-filter-label-close" v-else @click="removeBusinessGroup(index)">
                                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8"><path d="M1100.707,327.293a1,1,0,1,1-1.414,1.414L1097,326.414l-2.293,2.293a1,1,0,0,1-1.414-1.414l2.293-2.293-2.293-2.293a1,1,0,0,1,1.414-1.414l2.293,2.293,2.293-2.293a1,1,0,0,1,1.414,1.414L1098.414,325Z" transform="translate(-1093 -321)" /></svg>
                                        </span>

                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="ctrack-row">
                        <div class="ctrack-col-12">
                            <div class="md-filter-form-froup">
                                <div class="ctrack-row ctrack-align-items-center">
                                    <div class="ctrack-col-md-6 ctrack-col-lg-5">
                                        <label class="md-filter-form-lbl">{{$t("Dashboard.vehicle")}}</label>
                                    </div>
                                    <div class="ctrack-col-md-6 ctrack-col-lg-7">
                                        <div class="md-selectbox-style2 ctrack-select-icon1">
                                            <md-field>
                                                <md-select md-class="md-filter-selectMenu" v-model="vehicalTypeGroup" name="vehicalTypeGroup" id="vehicalTypeGroup"
                                                           md-dense="md-dense" multiple placeholder="Select" @md-selected="selectAll('Vehicle')" @md-closed="removeSelectGroupValue('Vehicle')" @md-opened="addSelectGroupValue('Vehicle')">
                                                    <div class="md-layout-item">
                                                        <md-field class="search-group" md-clearable>
                                                            <md-input v-model="vehicalSearch" :placeholder="$tc('Dashboard.searchVehicals')"></md-input>
                                                        </md-field>
                                                        <md-option value="All" v-if="vehicalSearch=='' || ('all').includes(vehicalSearch.toLowerCase())">{{$t("Dashboard.all")}}</md-option>
                                                        <md-option v-for="item in filteredVehicalArray" :key="item.id"
                                                                   :value="item.id">{{item.name}}</md-option>
                                                    </div>
                                                </md-select>
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="ctrack-row">
                        <div class="ctrack-col-12">
                            <div class="md-filter-form-froup">
                                <div class="ctrack-row ctrack-align-items-center">
                                    <div class="ctrack-col-md-6 ctrack-col-lg-5">
                                        <label class="md-filter-form-lbl">{{$t("Dashboard.driver")}}</label>
                                    </div>
                                    <div class="ctrack-col-md-6 ctrack-col-lg-7">
                                        <div class="md-selectbox-style2 ctrack-select-icon1">
                                            <md-field>
                                                <md-select md-class="md-filter-selectMenu" v-model="driverGroup" name="driverGroup" id="driverGroup"
                                                           md-dense="md-dense" multiple placeholder="Select" @md-selected="selectAll('Driver')" @md-closed="removeSelectGroupValue('Driver')" @md-opened="addSelectGroupValue('Driver')">
                                                    <md-field class="search-group" md-clearable>
                                                        <md-input v-model="driverSearch" :placeholder="$tc('Dashboard.searchDrivers')"></md-input>
                                                    </md-field>
                                                    <md-option value="All" v-if="driverSearch=='' || ('all').includes(driverSearch.toLowerCase())">{{$t("Dashboard.all")}}</md-option>
                                                    <md-option v-for="item in filteredDriverArray" :key="item.id"
                                                               :value="item.id">{{item.displayName}}</md-option>

                                                </md-select>
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="ctrack-row">
                <div class="ctrack-col-12">
                <div class="md-filter-form-froup">
                    <div class="ctrack-row ctrack-align-items-center">
                    <div class="ctrack-col-md-6 ctrack-col-lg-5">
                        <label class="md-filter-form-lbl">Max Distance</label>
                    </div>
                    <div class="ctrack-col-md-6 ctrack-col-lg-7">
                            <md-field>
                                <md-input min="100" v-model.number="distanceMaxValue" type="number" id="distance-max-value" class="md-number-field" @change="updateOtherValue" @input="updateOtherValue"></md-input>
                            </md-field>
                    </div>
                    </div>
                </div>
                </div>
            </div> -->
                    <div class="ctrack-row">
                        <div class="ctrack-col-12">
                            <div class="md-filter-form-froup">
                                <div class="ctrack-row ctrack-align-items-center ctrack-md-rangeSlider">
                                    <div class="ctrack-col-md-6 ctrack-col-lg-5">
                                        <div class="ctrack-row ctrack-align-items-center">
                                            <div class="ctrack-col-12">
                                                <label class="md-filter-form-lbl">{{$t("Dashboard.distance")}} <span class="miles-filter">({{milesfilter}})</span></label>
                                            </div>
                                            <div class="ctrack-col-12">
                                                <div class="ctrack-row distanceMinDiv">
                                                    <div class="ctrack-col-5 miles-filter"><strong>{{$t("Dashboard.min")}}</strong></div>
                                                    <div class="md-distance-maxRange ctrack-col-7">
                                                        <md-field>
                                                            <md-input min="0" v-model.number="distanceMinValue" type="number" class="md-number-field" @keydown="filterKey" @paste.prevent @change="updateOtherValue" @input="updateOtherValue"></md-input>
                                                        </md-field>
                                                    </div>
                                                </div>
                                                <div class="ctrack-row distanceMinDiv">
                                                    <div class="ctrack-col-5 miles-filter"><strong>{{$t("Dashboard.max")}}</strong></div>
                                                    <div class="md-distance-maxRange ctrack-col-7">
                                                        <md-field>
                                                            <md-input min="1" v-model.number="distanceMaxValue" type="number" id="distance-max-value" class="md-number-field" @keydown="filterKey" @paste.prevent @change="updateOtherValue" @input="updateOtherValue"></md-input>
                                                        </md-field>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <!-- <div class="ctrack-col-md-2 ctrack-col-lg-3">
                                <md-field>
                                <md-input min="0" v-model="milesNumber" type="number" class="md-number-field"></md-input>
                                </md-field>
                            </div>
                            <div class="ctrack-col-md-4 ctrack-col-lg-4">
                                <div class="md-selectbox-style2 ctrack-select-icon1 ctrack-select-white">
                                    <md-field>
                                    <md-select v-model="milesfilter" name="milesoption" id="milesoption" md-dense="md-dense" placeholder="Select">
                                    <div class="md-layout-item">
                                        <md-option value="miles">Miles</md-option>
                                        <md-option value="km">Kilometers</md-option>
                                    </div>
                                    </md-select>
                                    </md-field>
                                </div>
                            </div> -->
                                    <div class="ctrack-col-md-6 ctrack-col-lg-7">
                                        <div class="ctrack-filterRange">
                                            <vue-slider v-model="distanceValue" :min="distanceMinValue" :max="distanceMaxValue" :tooltip="'always'" :tooltip-placement="['top', 'top']"></vue-slider>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="ctrack-row">
                        <div class="ctrack-col-12">
                            <div class="md-filter-form-froup">
                                <div class="md-rectangle-checkbox-main">
                                    <div class="md-rCheck-repeater">
                                        <label class="md-rectangle-checkbox">
                                            <input name="md-distance-radio" type="radio" v-model="tripType" value="all" />
                                            <span class="md-rectangle-check-text">{{$t("Dashboard.all")}}</span>
                                        </label>
                                    </div>
                                    <div class="md-rCheck-repeater">
                                        <label class="md-rectangle-checkbox">
                                            <input name="md-distance-radio" v-model="tripType" value="business" type="radio" />
                                            <span class="md-rectangle-check-text">{{$t("Dashboard.business")}}</span>
                                        </label>
                                    </div>
                                    <div class="md-rCheck-repeater">
                                        <label class="md-rectangle-checkbox">
                                            <input name="md-distance-radio" v-model="tripType" value="private" type="radio" />
                                            <span class="md-rectangle-check-text">{{$t("Dashboard.private")}}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="ctrack-row">
                        <div class="ctrack-col-12">
                            <div class="md-filter-form-froup">
                                <div class="md-circle-checkbox-main">
                                    <div class="md-cCheck-repeater md-check-repeater-first">
                                        <label class="md-circle-checkbox md-mid-check-active md-circle-checkbox-lg">
                                            <input type="checkbox" v-model="allWeekDay" />
                                            <span class="md-circle-check-text">{{$t("Dashboard.all")}}</span>
                                        </label>
                                    </div>
                                    <div class="md-cCheck-repeater">
                                        <label class="md-circle-checkbox">
                                            <input type="checkbox" v-model="selectedWeekDay" value="sun" />
                                            <span class="md-circle-check-text">{{$t("Dashboard.sun")}}</span>
                                        </label>
                                    </div>
                                    <div class="md-cCheck-repeater">
                                        <label class="md-circle-checkbox">
                                            <input type="checkbox" v-model="selectedWeekDay" value="mon" />
                                            <span class="md-circle-check-text">{{$t("Dashboard.mon")}}</span>
                                        </label>
                                    </div>
                                    <div class="md-cCheck-repeater">
                                        <label class="md-circle-checkbox">
                                            <input type="checkbox" v-model="selectedWeekDay" value="tue" />
                                            <span class="md-circle-check-text">{{$t("Dashboard.tue")}}</span>
                                        </label>
                                    </div>
                                    <div class="md-cCheck-repeater">
                                        <label class="md-circle-checkbox">
                                            <input type="checkbox" v-model="selectedWeekDay" value="wed" />
                                            <span class="md-circle-check-text">{{$t("Dashboard.wed")}}</span>
                                        </label>
                                    </div>
                                    <div class="md-cCheck-repeater">
                                        <label class="md-circle-checkbox">
                                            <input type="checkbox" v-model="selectedWeekDay" value="thu" />
                                            <span class="md-circle-check-text">{{$t("Dashboard.thu")}}</span>
                                        </label>
                                    </div>
                                    <div class="md-cCheck-repeater">
                                        <label class="md-circle-checkbox">
                                            <input type="checkbox" v-model="selectedWeekDay" value="fri" />
                                            <span class="md-circle-check-text">{{$t("Dashboard.fri")}}</span>
                                        </label>
                                    </div>
                                    <div class="md-cCheck-repeater">
                                        <label class="md-circle-checkbox">
                                            <input type="checkbox" v-model="selectedWeekDay" value="sat" />
                                            <span class="md-circle-check-text">{{$t("Dashboard.sat")}}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="ctrack-row">
                        <div class="ctrack-col-12">
                            <div class="md-filter-form-froup ctrack-modal-btns">
                                <div class="ctrack-row ctrack-align-items-center">
                                    <div class="ctrack-col-6 ctrack-text-left">
                                        <button type="reset" class="md-btn-style1" @click="setDefaultFilters('reset')">{{$t("Dashboard.reset")}}</button>
                                    </div>
                                    <div class="ctrack-col-6 ctrack-text-right">
                                        <!-- add disabled class -->
                                        <v-btn color="primary" :class="submitButtonDisabled" @click="submitFilter">
                                            {{$t("Dashboard.apply")}}
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-card>
</template>

<script>

//import GroupManangementService from "../../../service/groupmanagement.service";
import DashboardService from "../../../service/dashboard.service";
import { mutations, getters } from "../shared/observeDashboardFilter";
export default {
    name: 'filtermodal',
    components: {},
    data: function() {
        return { 
            boolean: true,
            businessGroup:[],       
            businessGroupName:[],
            FilterData:{businessMechanism:[],driverMechanism:[],vehicalTypeMechanism:[],groupsHierarchies:[]},       
            //businessMechanism: this.FilterData.businessMechanism,
            businessMechanism: [],
            vehicalTypeGroup: [],
            vehicalTypeMechanism:[],
            vehicalTypeList: {},
            vehiclesAssignedListOriginal: [],
            driverGroup: [],
            driverList: [],
            driversAssignedListOriginal: [],
            //driverMechanism: this.FilterData.driverMechanism,
            driverMechanism: [],
            milesfilter:this.$tc("Dashboard.miles"), 
            milesNumber:"",            
            getFilterData :{},  
            groupsHierarchies:[],  
            distanceMinValue : 0,  
            distanceMaxValue : 100000000000,  
            distanceValue: [0, 500],  
            driverChecked: 0,  
            vehicalChecked: 0,  
            tripType : "all",
            selectedWeekDay : [],
            groupSearch : "",
            vehicalSearch : "",
            driverSearch : "",
            breadcrumbList : [],
            groupsOpened : 0,
            vehiclesOpened : 0,
            driversOpened : 0,
            previousSelectVehicles : [],
            previousSelectGroups : [],
            submitButtonDisabled:"",
            assignedInGroupVehicles:[],
            unAssignedInGroupVehicles:[],            
            showUnassignCheckbox:false           
        }
    },
    props: { 
       // FilterData : Array   
    },
    watch: {        
        driverGroup : function(a,b)
        {
            let _array = new Array();
            let _array1 = new Array();
            this.driverChecked = 0;
            _array = $.grep(a, function (item) {
                return $.inArray(item, b) < 0;
            });
            if(_array.includes("All"))
            {
                this.driverChecked = 1
            }
            _array1 = $.grep(b, function (item) {
                return $.inArray(item, a) < 0;
            });
            
            if(_array1.includes("All"))
            {                
                this.driverChecked = 2
            }
            
        },
        vehicalTypeGroup : function(a,b)
        {
            let _array = new Array();
            let _array1 = new Array();
            this.vehicalChecked = 0;
            _array = $.grep(a, function (item) {
                return $.inArray(item, b) < 0;
            });
            if(_array.includes("All"))
            {
                this.vehicalChecked = 1
            }
            _array1 = $.grep(b, function (item) {
                return $.inArray(item, a) < 0;
            });
            
            if(_array1.includes("All"))
            {                
                this.vehicalChecked = 2
            }
        },
        businessGroup : function(a,b)
        {
            let _array = new Array();
            let _array1 = new Array();
            this.businessGroupChecked = 0;
            _array = $.grep(a, function (item) {
                return $.inArray(item, b) < 0;
            });
            if(_array.includes("All"))
            {
                this.businessGroupChecked = 1
            }
            _array1 = $.grep(b, function (item) {
                return $.inArray(item, a) < 0;
            });
            
            if(_array1.includes("All"))
            {                
                this.businessGroupChecked = 2
            }
        },
    }, 
    updated()
    {
        let self = this;        
        if(self.businessGroup.includes('All')){
            $('.md-select-value[name=businessGroup]').val(this.$t("Dashboard.all"));   
        }
        else
        {
            if(self.businessGroup.length)
                $('.md-select-value[name=businessGroup]').val(self.businessGroup.length + "+ "+ this.$t("Dashboard.groups_selected") ); 
            else
                $('.md-select-value[name=businessGroup]').val(self.businessGroup.length + " "+ this.$t("Dashboard.groups_selected")); 
        }

        if(self.vehicalTypeGroup.includes('All')){
            $('.md-select-value[name=vehicalTypeGroup]').val(this.$t("Dashboard.all"));   
        }
        else
        {
            if(self.vehicalTypeGroup.length)
                $('.md-select-value[name=vehicalTypeGroup]').val(self.vehicalTypeGroup.length + "+ "+ this.$t("Dashboard.vehicles_selected")); 
            else
                $('.md-select-value[name=vehicalTypeGroup]').val(self.vehicalTypeGroup.length + " "+ this.$t("Dashboard.vehicles_selected")); 
        }

        if(self.driverGroup.includes('All')){
            $('.md-select-value[name=driverGroup]').val(this.$t("Dashboard.all"));   
        }
        else
        {
            if(self.driverGroup.length)
                $('.md-select-value[name=driverGroup]').val(self.driverGroup.length + "+ "+ this.$t("Dashboard.drivers_selected")); 
            else
                $('.md-select-value[name=driverGroup]').val(self.driverGroup.length + " "+ this.$t("Dashboard.drivers_selected")); 
        }

        /* if(self.businessGroup.includes('All')){
            $('.md-select-value[name=businessGroup]').val("All");   
        } */


    },   
    mounted()
    {
        let self = this;
        const configProperties = self.$session.get("configProperties");

        let isAdmin = false; 
        if (this.$checkRBACAccess('companySettings')){
            isAdmin= true
        }
        if (!configProperties.groupPolicy) {
            self.showUnassignCheckbox = true;
        } else {
            if(isAdmin){
                self.showUnassignCheckbox = true;
            }else{
                self.showUnassignCheckbox = false;
            }
        }
        //self.FilterData.businessMechanism = JSON.parse(localStorage.getItem('businessMechanism'));
        //self.businessMechanism = self.FilterData.businessMechanism;
        new Promise(function(resolve, reject) {            
            self.FilterData.driverMechanism = JSON.parse(localStorage.getItem('driverMechanism'));
            self.FilterData.vehicalTypeMechanism = JSON.parse(localStorage.getItem('vehicalTypeMechanism'));
            self.FilterData.groupsHierarchies = JSON.parse(localStorage.getItem('groupsHierarchies'));   
                       
            self.setDefaultFilters();
            resolve(true);
        }).
        then(results => {               
            let selectedFilters = getters.getFilterParams();
            new Promise(function(resolve, reject) {
                if(selectedFilters.businessGroups.length) 
                {    
                    
                    if(!self.businessMechanism.length)
                    {
                        let businessGroupName = [...selectedFilters.businessGroupName];                    
                        if(businessGroupName[0].id=="All")
                        {
                            self.FilterData.businessMechanism = JSON.parse(localStorage.getItem('businessMechanism'));                            
                            if (self.showUnassignCheckbox) {
                                self.FilterData.businessMechanism.unshift({groupId: "unassigned",groupName: self.$tc("Dashboard.unAssignedVehicles"),id: "unassigned"});
                            }                            
                            self.businessMechanism = self.FilterData.businessMechanism;
                            // self.businessMechanism = JSON.parse(localStorage.getItem('businessMechanism'));
                        }
                        else{                        
                            self.FilterData.businessMechanism = [...selectedFilters.businessGroupName];
                            self.businessMechanism = [...selectedFilters.businessGroupName];
                        }
                        
                    
                    }                
                    self.businessGroups = [];
                    let tempGroupArr =  JSON.parse(localStorage.getItem('businessMechanism'));
                    /* if(self.businessMechanism.length < tempGroupArr.length)
                    { */
                    let tempGroupArrLength = tempGroupArr.length;
                    if (self.showUnassignCheckbox) {
                        tempGroupArrLength++;
                    }
                    else
                    {
                        if(selectedFilters.businessGroups.includes('unassigned')){     
                            let index = selectedFilters.businessGroups.indexOf("unassigned")           
                            selectedFilters.businessGroups.splice(index, 1);
                        }
                    }
                    if(selectedFilters.businessGroups.length == tempGroupArrLength)
                    {
                        self.businessGroup.push("All");
                    }
                    else{
                        self.businessGroup = [...selectedFilters.businessGroups];
                    }            
                    self.selectAll("Group","Y")
                    
                }
                let assignedVehicles = [];
                let orgBusinessMechanismArr =  JSON.parse(localStorage.getItem('businessMechanism'));
                orgBusinessMechanismArr.forEach(element => {                        
                    let groupHierarchy = self.findGroupHierarchyById(element.id,self.FilterData.groupsHierarchies[0],false)
                                        
                    if(groupHierarchy && groupHierarchy.groupEntities.length)
                    {
                        groupHierarchy.groupEntities.forEach((entity,entityIndex) => {
                            switch (entity.EntityType) {
                                case 'vehicles':
                                if (self.vehicalTypeList[entity.EntityID]) {
                                    assignedVehicles[entity.EntityID] = self.vehicalTypeList[entity.EntityID];                                    
                                }
                                break;
                            }
                        });
                    }
                });
                self.assignedInGroupVehicles = Object.keys(assignedVehicles);
                let unAssignedVehicles = Object.keys(self.vehicalTypeList).filter(x => !Object.keys(assignedVehicles).includes(x));
                self.unAssignedInGroupVehicles = unAssignedVehicles;
                if(selectedFilters.businessGroups.includes('unassigned') && selectedFilters.vehicles.length == self.assignedInGroupVehicles.length){  
                    selectedFilters.vehicles.push(...self.unAssignedInGroupVehicles);
                }
               
                self.tripType = selectedFilters.tripType;
                self.milesfilter = selectedFilters.distanceUnit;
                self.selectedWeekDay = selectedFilters.selectedWeekDay;
                self.distanceMaxValue = selectedFilters.distanceMaxValue; 
                self.distanceMinValue = selectedFilters.distance[0]; 
                resolve(true);
            }).
            then(results => {
                new Promise(function(resolve, reject) {
                    if(selectedFilters.vehicles.length && !selectedFilters.vehicles.includes("All")) 
                    {
                            self.vehicalTypeGroup = [];
                            if(selectedFilters.vehicles.length == self.vehicalTypeMechanism.length)
                            {
                                setTimeout(() => {
                                    self.vehicalTypeGroup = [...selectedFilters.vehicles];        
                                    self.vehicalTypeGroup.push("All")
                                }, 10); 
                            }
                            else
                            {
                                setTimeout(() => {
                                    self.vehicalTypeGroup = [...selectedFilters.vehicles];
                                }, 10);                        
                            }          
                    }
                    else if(!selectedFilters.vehicles.length)
                    {
                        self.vehicalTypeGroup = [];            
                    }
                    resolve(true);
                }).
                then(results => {
                    setTimeout(() => {
                        new Promise(function(resolve, reject) {
                            self.getDriverFromVehiclesIds("1")
                            resolve(true);
                        })
                        .then(results => {                            
                                       
                        });
                    }, 100); 
                   
                });             
                self.distanceValue = selectedFilters.distance;                
            });
        }); 
    },
    computed: {
        allWeekDay: {
            get: function () {
                return this.selectedWeekDay ? 7 == this.selectedWeekDay.length : false;
            },
            set: function (value) {                
                if (value) {
                    this.selectedWeekDay = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]
                }
                else
                {  
                    this.selectedWeekDay = [];
                }
            }
        },        
        filteredBusinessGroupArray : function () {            
            let PATTERN = this.groupSearch,
            filtered =  this.FilterData.businessMechanism.filter(function (str) { return str.groupName.toLowerCase().includes(PATTERN.toLowerCase()); });;
            return filtered;
            //return Number((this.idleTime * this.idleFuelUse).toFixed(2))
        },
        filteredVehicalArray : function () {            
            let PATTERN = this.vehicalSearch,
            filtered =  this.vehicalTypeMechanism.filter(function (str) { return str.name.toLowerCase().includes(PATTERN.toLowerCase()); });
            // Sort vehicles  array
            return this.sortData(filtered);
        },
        filteredDriverArray : function () {              
            let PATTERN = this.driverSearch,
            filtered =  this.driverMechanism.filter(function (str) { return str.displayName.toLowerCase().includes(PATTERN.toLowerCase()); });;
            // Sort driver array
            let filtered1 =  filtered.sort((a, b) => {
                return a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1;
            });
            return filtered1;
        },
    },
    methods: { 
        // Add Function to sort Array
        sortData(data){
            return data.sort((a, b) => {
                return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
            });
        },     
        addSelectGroupValue(type)
        {
            let self = this;
            // update disabled class if any select box is opened
            self.submitButtonDisabled = "disabled"
            if(type=="Group")
            {                
                let tempGroupArr =  JSON.parse(localStorage.getItem('businessMechanism')); 
                let tempGroupArrLength = tempGroupArr.length;
                if (self.showUnassignCheckbox) {
                    tempGroupArrLength++;
                }               
                if(self.businessMechanism.length < tempGroupArrLength)
                {                    
                    self.FilterData.businessMechanism = JSON.parse(localStorage.getItem('businessMechanism'));
                    self.businessMechanism = self.FilterData.businessMechanism;
                    if (self.showUnassignCheckbox) {
                        self.FilterData.businessMechanism.unshift({groupId: "unassigned",groupName: "unassigned",id: "unassigned"});
                    }
                }
                self.previousSelectGroups = [...self.businessGroup];                
                if(self.businessGroup.includes('All')){                     
                    self.businessGroup = [];
                    self.businessMechanism.forEach(element => {                
                        self.businessGroup.push(element.id);
                    }); 
                    self.businessGroup.push("All");
                }
                
                self.groupsOpened = 1;

            }
            else if(type=="Vehicle")
            {
               /*  if(self.vehicalTypeGroup.includes('All')){ 
                    self.vehicalTypeGroup = [];
                    self.vehicalTypeMechanism.forEach(element => {                
                        self.vehicalTypeGroup.push(element.id);
                    }); 
                    self.vehicalTypeGroup.push("All");
                } */
                this.previousSelectVehicles = [...this.vehicalTypeGroup];
                this.vehiclesOpened = 1;

            }
            else if(type=="Driver")
            {
               /*  if(self.driverGroup.includes('All')){ 
                    self.driverGroup = [];
                    self.driverMechanism.forEach(element => {                
                        self.driverGroup.push(element.id);
                    }); 
                    self.driverGroup.push("All");
                } */
                this.driversOpened = 1;
            }
        },
        getDriverFromVehiclesIds(type){            
            let self = this;
            let selectedFilters = getters.getFilterParams(); 
            let vehiclesIds = [...self.vehicalTypeGroup];
            let startTime = new Date(selectedFilters.calendar.from + " 00:00:00").getTime();
            let endTime = new Date(selectedFilters.calendar.to + " 23:59:59").getTime();
            let paramJson = {
            "ids": vehiclesIds,
            "startTime": startTime,
            "endTime": endTime
            }
            $(".md-dialog.md-theme-default").addClass("lowZindex");
            if(vehiclesIds.length)
            {
                DashboardService.getMappedDriverFromVehicles(paramJson).then(result => {                                  
                    let tempRes = Object.values(result);                    
                    let driversAssignedListOriginal = [];
                    tempRes.forEach(element => {
                       if(element.length)
                       {
                           element.forEach(elementNew => {
                                if (self.driverList[elementNew.driverId]) {
                                    driversAssignedListOriginal[elementNew.driverId] = self.driverList[elementNew.driverId];
                                }
                                else // code commented if assign driver is not available in current driver list
                                {
                                   driversAssignedListOriginal[elementNew.driverId] = {id: elementNew.driverId,displayName: elementNew.driverId}
                                }
                           });
                       }
                    });
                    
                    if(Object.entries(driversAssignedListOriginal).length)
                    { 
                        self.driverMechanism = [];
                        if(type!=1)
                        self.driverGroup = [];                    
                        let tempdriverArr = [];                       
                        for (const [EntityID, data] of Object.entries(driversAssignedListOriginal)) {
                            self.driverMechanism.push(data);
                            if(type!=1)
                            tempdriverArr.push(data.id)
                        }  
                        if(type!=1)                     
                        tempdriverArr.push("All");
                        self.driverGroup = tempdriverArr;
                    }
                    else
                    {
                        self.driverMechanism = [];
                        self.driverGroup = [];                        
                    }
                    if(type==1)
                    {
                        if(selectedFilters.drivers.length && !selectedFilters.drivers.includes('All')) 
                        {                              
                            if(selectedFilters.drivers.length == self.driverMechanism.length)
                            {                       
                                self.driverGroup = [];
                                self.driverGroup = [...selectedFilters.drivers];
                                self.driverGroup.push("All")
                            }
                            else
                            {                                
                                setTimeout(() => {
                                    self.driverGroup = [];
                                    self.driverGroup = [...selectedFilters.drivers];
                                }, 100); 
                            }
                        }
                        else if(!selectedFilters.drivers.length)
                        {
                            self.driverGroup = [];            
                        }
                        else
                        {
                            //self.driverGroup = [];
                            setTimeout(() => {
                                let tempdriverArr = [];                       
                                self.driverMechanism.forEach(element => {
                                    tempdriverArr.push(element.id)
                                });
                                tempdriverArr.push("All");
                                self.driverGroup = tempdriverArr;
                            }, 100);
                        }
                    }
                    $(".md-dialog.md-theme-default").removeClass("lowZindex");
                });
            }
            else
            {
                self.driverMechanism = [];
                self.driverGroup = [];    
                $(".md-dialog.md-theme-default").removeClass("lowZindex");               
            }            
        },
        removeSelectGroupValue(type)
        {            
            let self = this;
            if(type=="Group")
            {   
                setTimeout(() => {
                    if(self.businessGroup.includes('All')){
                        $('.md-select-value[name=businessGroup]').val(this.$t("Dashboard.all"));   
                    }
                    else
                    {                       
                        if(self.businessGroup.length)
                            $('.md-select-value[name=businessGroup]').val(self.businessGroup.length + "+ "+  this.$t("Dashboard.groups_selected") ); 
                        else
                            $('.md-select-value[name=businessGroup]').val(self.businessGroup.length + " "+   this.$t("Dashboard.groups_selected") );
                    }  
                }, 10);             
                if(self.businessGroup.includes('All')){ 
                    self.businessGroup = [];
                    self.businessGroup.push("All");
                }                

                let _array = [];
                let _array1 = [];
                _array = $.grep(self.businessGroup, function (item) {
                    return $.inArray(item, self.previousSelectGroups) < 0;
                });
                _array1 = $.grep(self.previousSelectGroups, function (item) {
                    return $.inArray(item, self.businessGroup) < 0;
                });

                if(self.groupsOpened && (_array.length || _array1.length))
                {                    
                    self.getDriverFromVehiclesIds(); 
                    self.groupsOpened = 0;            
                }
                
            }
            else if(type=="Vehicle")
            {
                setTimeout(() => {
                    if(self.vehicalTypeGroup.includes('All')){
                        $('.md-select-value[name=vehicalTypeGroup]').val(this.$t("Dashboard.all"));   
                    }
                    else
                    { 
                        if(self.vehicalTypeGroup.length)
                            $('.md-select-value[name=vehicalTypeGroup]').val(self.vehicalTypeGroup.length + "+ "+ this.$t("Dashboard.vehicles_selected")); 
                        else
                            $('.md-select-value[name=vehicalTypeGroup]').val(self.vehicalTypeGroup.length + " "+ this.$t("Dashboard.vehicles_selected"));
                    }  
                }, 1);
                let _array = [];
                let _array1 = [];
                _array = $.grep(self.vehicalTypeGroup, function (item) {
                    return $.inArray(item, self.previousSelectVehicles) < 0;
                });
                _array1 = $.grep(self.previousSelectVehicles, function (item) {
                    return $.inArray(item, self.vehicalTypeGroup) < 0;
                });
                if(self.vehiclesOpened && (_array.length || _array1.length))
                {
                    self.getDriverFromVehiclesIds(); 
                    self.vehiclesOpened = 0;            
                }
               
                /* if(self.vehicalTypeGroup.includes('All')){ 
                    self.vehicalTypeGroup = [];
                    self.vehicalTypeGroup.push("All");
                } */
            }
            else if(type=="Driver")
            {
                setTimeout(() => {
                    if(self.driverGroup.includes('All')){
                        $('.md-select-value[name=driverGroup]').val(this.$t("Dashboard.all"));   
                    }
                    else
                    {                      
                        if(self.driverGroup.length)
                            $('.md-select-value[name=driverGroup]').val(self.driverGroup.length + "+ "+  this.$t("Dashboard.drivers_selected")); 
                        else
                            $('.md-select-value[name=driverGroup]').val(self.driverGroup.length + " "+  this.$t("Dashboard.drivers_selected")); 
                    }  
                }, 10);
                self.driversOpened = 0;    
                /* if(self.driverGroup.includes('All')){ 
                    self.driverGroup = [];
                    self.driverGroup.push("All");
                } */
            } 
            // remove disabled class if any select box is opened
            self.submitButtonDisabled = ""           
        },
        filterKey(e){
            const key = e.key;

            // If is '.' key, stop it
            if (key === '.' || key === '-' || key === '+')
                return e.preventDefault();            
        },
        updateOtherValue(e)
        {
            let self = this;
            self.distanceValue =  [self.distanceMinValue,0];
            this.$nextTick(() => {
                if(!self.distanceMaxValue || self.distanceMaxValue==0)
                    self.distanceMaxValue = 1;

                if(!self.distanceMinValue || self.distanceMinValue==0)
                    self.distanceMinValue = 0;

                if(self.distanceMaxValue < self.distanceMinValue)
                {
                    let maxValue = Math.max(self.distanceMaxValue,self.distanceMinValue);             
                    self.distanceMaxValue =  maxValue;
                }
                this.$nextTick(() => {
                    self.distanceValue =  [self.distanceMinValue,self.distanceMaxValue];
                });
            });     
        },              
        setDefaultFilters(action = null)
        {           
            let self = this; 
            self.vehicalTypeMechanism=[];
            self.vehicalTypeGroup=[];
            self.driverMechanism=[];
            self.driverGroup=[];
            self.businessGroups=[];
            let vehicalArr = [];
            self.FilterData.vehicalTypeMechanism.forEach(element => {
                self.vehicalTypeMechanism.push({
                    id: element.id,
                    name: element.name
                });
                vehicalArr.push(element.id);
                self.vehicalTypeList[element.id] = {
                    id: element.id,
                    name: element.name
                }
            });
            vehicalArr.push("All");
            self.vehicalTypeGroup = vehicalArr;
            
            self.FilterData.driverMechanism.forEach(element => {
                self.driverMechanism.push({
                    id: element.id,
                    displayName: element.displayName
                });
                //self.driverGroup.push(element.id);
                self.driverList[element.id] = {
                    id: element.id,
                    displayName: element.displayName
                }
            }); 
            
            if(action == 'reset')
            {
                let GlobalFiltersData = this.$groupsFilterStore
                
                let sentFilterData = {};
                if(GlobalFiltersData.groups)
                {
                    let selectedGroups = GlobalFiltersData.groups
                    
                    self.businessGroup = [];
                    
                    let tempGroupArr =  JSON.parse(localStorage.getItem('businessMechanism'));
                    
                    if(GlobalFiltersData.groups.length == tempGroupArr.length)
                    {
                        self.vehicalTypeGroup = [];
                        self.businessGroup.push("All"); 
                        //self.selectAll("Group","Y")       
                        setTimeout(() => {
                            let globalDashboardStateNew = JSON.parse(localStorage.getItem('globalDashboardState'))
                            if(globalDashboardStateNew && globalDashboardStateNew.vehicles != undefined && globalDashboardStateNew.vehicles.length)
                            {
                                self.vehicalTypeGroup = globalDashboardStateNew.vehicles;
                                self.vehicalTypeGroup.push("All");
                            }
                            self.getDriverFromVehiclesIds();                          
                        }, 200);
                         
                    }
                    else{
                        
                        setTimeout(() => {
                            self.businessGroup = selectedGroups;
                            //self.selectAll("Group","Y") 
                        }, 100);

                        setTimeout(() => {
                            let globalDashboardStateNew = JSON.parse(localStorage.getItem('globalDashboardState'))
                            if(globalDashboardStateNew && globalDashboardStateNew.vehicles != undefined && globalDashboardStateNew.vehicles.length)
                            {
                                self.vehicalTypeGroup = globalDashboardStateNew.vehicles;
                                self.vehicalTypeGroup.push("All");
                            }
                            self.getDriverFromVehiclesIds(); 
                        }, 500);
                    }                    
                }                
            }
            //self.driverGroup.push("All");
            self.milesfilter =  this.$tc("Dashboard.miles"); 
            self.milesNumber=""; 
            self.tripType = "all";
            self.selectedWeekDay = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
            self.distanceMaxValue = 100000000000;
            self.distanceMinValue = 0;
        },
        selectAll(type,stateVal="N") {
            let self=this;
            
            if(type=="Group")
            { 
                if(self.businessGroup.length)
                {
                    let businessGroupLength = this.businessGroup.length;
                    let businessMechanismLength = this.businessMechanism.length;
                    if(this.businessGroup.includes('All') && this.businessGroupChecked == 1 && this.businessGroup.slice(-1)[0]=='All')
                    {
                        self.businessGroup = [];
                        let tempbusinessGroupArr = [];
                        // tempbusinessGroupArr.push("AllUnassigned");
                        self.businessMechanism.forEach(element => {
                            tempbusinessGroupArr.push(element.id)
                        });                        
                        tempbusinessGroupArr.push("All");
                        self.businessGroup = tempbusinessGroupArr;                                            
                        return false;
                    }
                    else if(!this.businessGroup.includes('All') && this.businessGroupChecked == 2 )
                    {
                        self.businessGroup = [];                        
                        return false;
                    } 
                    let orgBusinessMechanismLength = JSON.parse(localStorage.getItem('businessMechanism')).length;
                    if (self.showUnassignCheckbox) {
                        orgBusinessMechanismLength++;
                    }
                    if(self.businessGroup.includes('All') && this.businessGroupChecked == 0){                       
                        businessGroupLength -= 1;
                        
                        if(businessGroupLength < businessMechanismLength && businessGroupLength >0)
                        {                            
                            let filteredbusinessGroup = [...self.businessGroup];
                            let index = filteredbusinessGroup.indexOf("All")           
                            filteredbusinessGroup.splice(index, 1);
                            let index2 = self.businessGroup.indexOf("All")           
                            self.businessGroup.splice(index2, 1);                            
                            self.businessGroup =   filteredbusinessGroup;
                        }
                    }
                    // Condition to select checkbox all if all value are selected
                    else if(businessGroupLength == orgBusinessMechanismLength)
                    {            
                        self.businessGroup = [];
                        let tempbusinessGroupArr = [];
                        self.businessMechanism.forEach(element => {
                        tempbusinessGroupArr.push(element.id);
                        });
                        tempbusinessGroupArr.push("All");
                        self.businessGroup = tempbusinessGroupArr;
                        return false;
                    }
                    self.vehiclesAssignedListOriginal = [];
                    self.driversAssignedListOriginal = [];
                    let businessGroupArray = [];  
                    if(self.businessGroup.includes('All'))
                    {
                        self.businessMechanism.forEach(element => {
                            businessGroupArray.push(element.id)
                        });  
                    }
                    else
                    {
                        businessGroupArray = [...self.businessGroup];
                    } 
                    new Promise(function(resolve, reject) {
                        businessGroupArray.forEach(element => {  
                            if(element == 'unassigned')
                            {                                
                                if(self.unAssignedInGroupVehicles.length > 0)
                                {
                                    self.unAssignedInGroupVehicles.forEach(vehId => {
                                        if (self.vehicalTypeList[vehId]) {
                                            self.vehiclesAssignedListOriginal[vehId] = self.vehicalTypeList[vehId];                                    
                                        }                                        
                                    });
                                }                                
                            }
                            else
                            {
                                let groupHierarchy = self.findGroupHierarchyById(element,self.FilterData.groupsHierarchies[0],false)                                             
                                if(groupHierarchy && groupHierarchy.groupEntities.length)
                                {
                                    groupHierarchy.groupEntities.forEach((entity,entityIndex) => {
                                        switch (entity.EntityType) {
                                            case 'vehicles':
                                            if (self.vehicalTypeList[entity.EntityID]) {
                                                self.vehiclesAssignedListOriginal[entity.EntityID] = self.vehicalTypeList[entity.EntityID];                                    
                                            }
                                            break;
                                            case 'drivers':
                                            if (self.driverList[entity.EntityID]) {
                                            self.driversAssignedListOriginal[entity.EntityID] = self.driverList[entity.EntityID];
                                            }
                                            break; 
                                        }
                                    });
                                }

                            }                   
                            
                        });
                        let filtersParam = getters.getFilterParams();
                        if(Object.entries(self.vehiclesAssignedListOriginal).length)
                        {                        
                            self.vehicalTypeMechanism = [];
                            self.vehicalTypeGroup = [];
                            let tempVehicalArr = [];
                            for (const [EntityID, data] of Object.entries(self.vehiclesAssignedListOriginal)) {
                                self.vehicalTypeMechanism.push(data);
                                tempVehicalArr.push(data.id)
                            }
                            tempVehicalArr.push("All");
                            self.vehicalTypeGroup = tempVehicalArr;
                            
                            let _array1 = $.grep(filtersParam.businessGroups, function (item) {
                                return $.inArray(item, businessGroupArray) < 0;
                            });
                            
                            if(JSON.stringify(filtersParam.businessGroups)==JSON.stringify(businessGroupArray)) 
                            {  
                                if(filtersParam.vehicles.length && !filtersParam.vehicles.includes("All")) 
                                { 
                                            
                                    if(filtersParam.vehicles.length == self.vehicalTypeMechanism.length)
                                    {          
                                        self.vehicalTypeGroup.push("All")
                                    }
                                    else
                                    {
                                        
                                    setTimeout(() => {
                                        //self.vehicalTypeGroup = [];
                                        self.vehicalTypeGroup = [...filtersParam.vehicles];
                                    }, 10);                        
                                    }
                                }
                            }                                
                        }
                        else
                        {                        
                            self.vehicalTypeMechanism = [];
                            self.vehicalTypeGroup = [];                       
                        }
                        
                        if(Object.entries(self.driversAssignedListOriginal).length)
                        { 
                                                  
                            /* self.driverMechanism = [];
                            self.driverGroup = [];                    
                            let tempdriverArr = [];                       
                            for (const [EntityID, data] of Object.entries(self.driversAssignedListOriginal)) {
                                self.driverMechanism.push(data);
                                tempdriverArr.push(data.id)
                            }                       
                            tempdriverArr.push("All");
                            self.driverGroup = tempdriverArr;
                            if(JSON.stringify(filtersParam.businessGroups)==JSON.stringify(businessGroupArray)) 
                            { 
                                if(filtersParam.drivers.length && !filtersParam.drivers.includes('All')) 
                                {
                                    self.driverGroup = [];
                                    if(filtersParam.drivers.length == self.driverMechanism.length)
                                    {
                                        self.driverGroup.push("All")
                                    }
                                    else
                                    {
                                        setTimeout(() => {
                                            self.driverGroup = [...filtersParam.drivers];
                                        }, 100); 
                                    }
                                }
                            } */
                            
                        }
                        else
                        {
                            
                            self.driverMechanism = [];
                            self.driverGroup = [];                        
                        }                        
                        resolve(true);
                    }).
                    then(results => {                       
                        self.removeSelectGroupValue("Vehicle");
                        self.removeSelectGroupValue("Driver"); 
                    });                   
                }
                else
                {
                    new Promise(function(resolve, reject) {                        
                        self.vehicalTypeMechanism=[];
                        self.vehicalTypeGroup=[]; 
                        self.driverMechanism=[];
                        self.driverGroup=[];  
                        let tempVehicalArr = [];                  
                        self.FilterData.vehicalTypeMechanism.forEach(vehicalElement => {
                            /* self.vehicalTypeMechanism.push({
                                id: vehicalElement.id,
                                name: vehicalElement.name
                            });  */
                            tempVehicalArr.push(vehicalElement.id)                   
                        });
                        // tempVehicalArr.push("All");
                        // self.vehicalTypeGroup = tempVehicalArr;
                            
                        let tempdriverArr = [];
                        self.FilterData.driverMechanism.forEach(driverElement => {                        
                            /* self.driverMechanism.push({
                                id: driverElement.id,
                                displayName: driverElement.displayName
                            }); */
                            tempdriverArr.push(driverElement.id)
                        });
                        tempdriverArr.push("All");
                        //self.driverGroup = tempdriverArr;
                        resolve(true);
                    }).
                    then(results => {                       
                        self.removeSelectGroupValue("Vehicle");
                        self.removeSelectGroupValue("Driver"); 
                    });   
                                       
                }                
                let businessGroupLength = self.businessGroup.length;
                let businessMechanismLength = self.businessMechanism.length;

                self.businessGroupName = [];
                if(self.businessGroup.includes('All')){ 
                    self.businessGroupName.push({id:"All",groupName:"All"});
                }
                else
                {
                    /* if(!self.businessMechanism.length)
                    {
                        self.FilterData.businessMechanism = JSON.parse(localStorage.getItem('businessMechanism'));
                        self.businessMechanism = self.FilterData.businessMechanism;
                    } */
                    self.businessGroup.forEach(element => {                    
                    var businessGrArr = self.businessMechanism.filter(function(x) {
                        return x.id === element;
                    });
                    if(businessGrArr.length)
                        self.businessGroupName.push({id:businessGrArr[0].id,groupName:businessGrArr[0].groupName});                        
                    });
                    
                }
                return false;           
            }
            else if(type=="Vehicle")
            {                  
                if(this.vehicalTypeMechanism.length)
                {
                    let vehicalTypeGroupLength = this.vehicalTypeGroup.length;
                    let vehicalTypeMechanismLength = this.vehicalTypeMechanism.length;
                    if(this.vehicalTypeGroup.includes('All') && this.vehicalChecked == 1 && this.vehicalTypeGroup.slice(-1)[0]=='All')
                    {
                        self.vehicalTypeGroup = [];
                        let tempVehicalArr = [];
                        self.vehicalTypeMechanism.forEach(element => {
                            tempVehicalArr.push(element.id)
                        });
                        tempVehicalArr.push("All");
                        self.vehicalTypeGroup = tempVehicalArr;
                        return false;
                    }
                    else if(!this.vehicalTypeGroup.includes('All') && this.vehicalChecked == 2 )
                    {
                        self.vehicalTypeGroup = [];
                        return false;
                    } 
                    if(self.vehicalTypeGroup.includes('All') && this.vehicalChecked == 0){ 
                        vehicalTypeGroupLength -= 1;
                        if(vehicalTypeGroupLength < vehicalTypeMechanismLength /* && vehicalTypeGroupLength >0 */)
                        {
                            let filteredvehicleGroup = [...self.vehicalTypeGroup];
                            let index = filteredvehicleGroup.indexOf("All")           
                            filteredvehicleGroup.splice(index, 1);
                            let index2 = self.vehicalTypeGroup.indexOf("All")           
                            self.vehicalTypeGroup.splice(index2, 1);                            
                            self.vehicalTypeGroup =   filteredvehicleGroup;
                        }
                    }
                    // Condition to select checkbox all if all value are selected
                    else if(vehicalTypeGroupLength == vehicalTypeMechanismLength)
                    {            
                        self.vehicalTypeGroup = [];
                        let tempVehicalArr = [];
                        self.vehicalTypeMechanism.forEach(element => {
                            tempVehicalArr.push(element.id)
                        });
                        tempVehicalArr.push("All");
                        self.vehicalTypeGroup = tempVehicalArr;
                        return false;
                    }
                }                
                return false;
            }
            else if(type=="Driver")
            {
                if(this.driverMechanism.length)
                {
                    let driverGroupLength = this.driverGroup.length;
                    let driverMechanismLength = this.driverMechanism.length;
                    if(this.driverGroup.includes('All') && this.driverChecked == 1 && this.driverGroup.slice(-1)[0]=='All')
                    {                        
                        self.driverGroup = [];
                        let tempdriverArr = [];                       
                        self.driverMechanism.forEach(element => {
                            tempdriverArr.push(element.id)
                        });
                        tempdriverArr.push("All");
                        self.driverGroup = tempdriverArr;             
                        return false;
                    }
                    else if(!this.driverGroup.includes('All') && this.driverChecked == 2 )
                    {                        
                        self.driverGroup = [];
                        return false;
                    }

                    if(self.driverGroup.includes('All') && this.driverChecked == 0){                                            
                        driverGroupLength -= 1;                       
                        if(driverGroupLength < driverMechanismLength /* && driverGroupLength >0 */)
                        {
                            let filtereddriverGroup = [...self.driverGroup];
                            let index = filtereddriverGroup.indexOf("All")           
                            filtereddriverGroup.splice(index, 1);
                            let index2 = self.driverGroup.indexOf("All")           
                            self.driverGroup.splice(index2, 1);                            
                            self.driverGroup =   filtereddriverGroup;
                        }                        
                    }  
                    // Condition to select checkbox all if all value are selected
                    else if(driverGroupLength == driverMechanismLength && this.driversOpened == 1 && this.driverChecked == 0)
                    {  
                        self.driverGroup = [];
                        let tempDriverArr = [];
                        self.driverMechanism.forEach(element => {
                            tempDriverArr.push(element.id)
                        });
                        tempDriverArr.push("All");
                        self.driverGroup = tempDriverArr;
                        return false;
                        
                    }
                }
                return false;
            }    
        },
        resetFilter(){
        //   this.businessGroup=[];
        //   this.vehicalTypeGroup=[];
        //   this.driverGroup=[];
        //   this.milesNumber=1000;
        //   this.milesfilter= "miles"
        },
        findGroupHierarchyById(groupId, hierarchyObject, foundHierarchy) 
        {
            let self = this;

            if (foundHierarchy) {
                return foundHierarchy;
            } else if (hierarchyObject.id == groupId) {
                return hierarchyObject
            }
            for (let group of hierarchyObject.groupChildren) {
                if (group.id == groupId) {
                foundHierarchy = group;
                return foundHierarchy
                }
                foundHierarchy = self.findGroupHierarchyById(groupId, group, foundHierarchy);
            };
            return foundHierarchy;
        },
        removeBusinessGroup(index){  
            let self = this;
            new Promise(function(resolve, reject) {
                if(index=="all")
                {
                    self.businessGroup = [];
                    self.businessGroupName = [];
                }
                else{
                    self.$delete(self.businessGroupName, index);
                    self.$delete(self.businessGroup, index);
                }
                resolve(true);
            }).
            then(results => {
                self.removeSelectGroupValue("Vehicle");
                self.removeSelectGroupValue("Driver"); 
                self.getDriverFromVehiclesIds()
            }) 
        },
        submitFilter(){
            let self = this;
            let filteredvehicalTypeGroup = [...self.vehicalTypeGroup]; 
            let filtereddriverGroup = [...self.driverGroup];   
            let filteredbusinessGroup = [...self.businessGroup];   
            
            
            if(self.vehicalTypeGroup.includes('All')){                      
                // let index = filteredvehicalTypeGroup.indexOf("All")           
                // filteredvehicalTypeGroup.splice(index, 1);
                filteredvehicalTypeGroup = [];   
                self.vehicalTypeMechanism.forEach(element => {                
                    filteredvehicalTypeGroup.push(element.id);
                });            
            }  

            if(self.driverGroup.includes('All')){                    
                // let index = filtereddriverGroup.indexOf("All")           
                // filtereddriverGroup.splice(index, 1); 
                filtereddriverGroup = [];   
                self.driverMechanism.forEach(element => {                
                    filtereddriverGroup.push(element.id);
                });            
            }
            
            if(self.businessGroup.includes('All')){ 
                filteredbusinessGroup = [];   
                self.businessMechanism.forEach(element => {                
                    filteredbusinessGroup.push(element.id);
                });            
            }
                       

            let sentFilterData = {
                businessGroups:filteredbusinessGroup,
                vehicals:filteredvehicalTypeGroup,
                drivers:filtereddriverGroup,                
                distanceUnit:self.milesfilter,
                distance: self.distanceValue,
                distanceMaxValue: self.distanceMaxValue,
                tripType: self.tripType,
                selectedWeekDay: self.selectedWeekDay,
                businessGroupName: self.businessGroupName,
                // distance:self.milesNumber,
            }            
            mutations.setDashboardFilters(sentFilterData);
            this.$emit("getFilters", sentFilterData);        
        },  
    }
};

</script>
<style scoped="scoped">

    /*
=============================================================
Please put your custom CSS below (CTrack BS Row Structure) 
============================================================
*/
/*CTrack BS Row Structure (Please don't change or use this CSS)*/
.ctrack-row{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-right:-10px;margin-left:-10px;}
[class*="ctrack-col"]{position:relative;width:100%;min-height:1px;padding-right:10px;padding-left:10px;}
.ctrack-d-flex{display:-webkit-box!important;display:-ms-flexbox!important;display:flex!important;}
.ctrack-align-items-center{-webkit-box-align:center!important;-ms-flex-align:center!important;align-items:center!important;}
.ctrack-justify-content-center{-webkit-box-pack:center!important;-ms-flex-pack:center!important;justify-content:center!important;}
.ctrack-h-100{height: 100%;}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}

.ctrack-col{-ms-flex-preferred-size:0;flex-basis:0;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;max-width:100%;}
.ctrack-col-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
.ctrack-col-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
.ctrack-col-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
.ctrack-col-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
.ctrack-col-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
.ctrack-col-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
.ctrack-col-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
.ctrack-col-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
.ctrack-col-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
.ctrack-col-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
.ctrack-col-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
.ctrack-col-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}

@media only screen and (min-width:576px){
  .ctrack-col-sm-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
  .ctrack-col-sm-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
  .ctrack-col-sm-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
  .ctrack-col-sm-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
  .ctrack-col-sm-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
  .ctrack-col-sm-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
  .ctrack-col-sm-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
  .ctrack-col-sm-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
  .ctrack-col-sm-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
  .ctrack-col-sm-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
  .ctrack-col-sm-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
  .ctrack-col-sm-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}
@media only screen and (min-width:768px){
  .ctrack-col-md-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
  .ctrack-col-md-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
  .ctrack-col-md-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
  .ctrack-col-md-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
  .ctrack-col-md-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
  .ctrack-col-md-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
  .ctrack-col-md-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
  .ctrack-col-md-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
  .ctrack-col-md-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
  .ctrack-col-md-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
  .ctrack-col-md-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
  .ctrack-col-md-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}
@media only screen and (min-width:992px){
  .ctrack-col-lg-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
  .ctrack-col-lg-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
  .ctrack-col-lg-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
  .ctrack-col-lg-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
  .ctrack-col-lg-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
  .ctrack-col-lg-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
  .ctrack-col-lg-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
  .ctrack-col-lg-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
  .ctrack-col-lg-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
  .ctrack-col-lg-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
  .ctrack-col-lg-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
  .ctrack-col-lg-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}
@media only screen and (min-width:1200px){
  .ctrack-col-xl-1{-webkit-box-flex:0;-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%;}
  .ctrack-col-xl-2{-webkit-box-flex:0;-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%;}
  .ctrack-col-xl-3{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
  .ctrack-col-xl-4{-webkit-box-flex:0;-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%;}
  .ctrack-col-xl-5{-webkit-box-flex:0;-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%;}
  .ctrack-col-xl-6{-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
  .ctrack-col-xl-7{-webkit-box-flex:0;-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%;}
  .ctrack-col-xl-8{-webkit-box-flex:0;-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%;}
  .ctrack-col-xl-9{-webkit-box-flex:0;-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%;}
  .ctrack-col-xl-10{-webkit-box-flex:0;-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%;}
  .ctrack-col-xl-11{-webkit-box-flex:0;-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%;}
  .ctrack-col-xl-12{-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}
/*CTrack BS Row Structure (Please don't change or use above CSS)*/
/*
=============================================================
Start your custom CSS from here
============================================================
*/

/* Custom CSS */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap");
*{font-family:"Roboto",sans-serif;}
.fa{display:inline-block;font:normal normal normal 14px/1 FontAwesome;font-size:inherit;text-rendering:auto;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
a{transition:all 0.5s ease-in-out;-webkit-transition:all 0.5s ease-in-out;-moz-transition:all 0.5s ease-in-out;-ms-transition:all 0.5s ease-in-out;-o-transition:all 0.5s ease-in-out;}
.md-bg-gray-light{background-color:#eef1f6;}
.md-dashboard-container{float:left;width:100%;padding:0 30px;}
.md-dashboard-header{padding:15px 0;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;justify-content:space-between;-webkit-justify-content:space-between;width:100%;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.md-dashboard-col2{text-align:right;}
.md-header-right,.md-header-links,.md-header-filter,.md-header-label,.md-header-right a,.md-dashboard-menu li,.md-dashboard-menu li a,.md-dashboard-menu li a svg,.md-header-right a svg,.md-dashboard-menu{vertical-align:middle;display:inline-block;}
.md-dashboard-header h1{font-size:22px;color:#293042;font-weight:500;}
.md-dashboard-menu{padding: 0}
.md-dashboard-menu li{margin:0 15px;}
.md-dashboard-menu li:last-child{margin-right:35px;}
.md-dashboard-menu li a{color:#959595;}
.md-dashboard-menu li a i{font-size:25px;vertical-align:middle;}
.md-dashboard-menu li a:hover,.md-dashboard-menu li a.active,.md-dashboard-menu li a.router-link-active{color:#002B49;}
.md-header-right a{color:#333333;}
.md-header-label{color:#333333;padding-right:5px;}
.md-header-links a{width:40px;height:40px;line-height:40px;background-color:#fff;text-align:center;border-radius:100%;margin:0 5px;}
.md-header-links a:hover,.md-header-links a.active{color:#fff;background-color:#002B49;}
.md-header-links{position:relative;padding:0 20px;margin:0 20px;}
.md-header-links:before,.md-header-links:after{content:"";position:absolute;top:10%;height:80%;width:1px;background:rgba(0,0,0,0.3);}
.md-header-links:before{left:0;}
.md-header-links:after{right:0;}
.md-header-right .md-header-filter a{color:#013c5e;}
.md-header-right .md-header-filter a:hover{color:#002B49;}
.md-header-right .md-header-filter a svg{display:inline-block;vertical-align:middle;}
.md-pl-0{padding:0 ;}


/*Modal*/
.ctrack-modal-main{position:fixed;top:0;right:0;bottom:0;left:0;z-index:1050;
display:none;
overflow:hidden;outline:0;}
.ctrack-modal-main.ctrack-modal-show{opacity:1;display:inline-block;}
/* .ctrack-modal-fade{opacity:0;transition:opacity 0.15s linear;} */
.ctrack-modal-open .ctrack-modal-main{overflow-x:hidden;overflow-y:auto;}
.ctrack-modal-dialog{position:relative;width:auto;margin:0.5rem;pointer-events:none;}
.ctrack-modal-dialog-centered{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;min-height:calc(100% - (0.5rem * 2));}
.ctrack-modal-content{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;width:100%;pointer-events:auto;background-color:#fff;background-clip:padding-box;border-radius:10px;outline:0;box-shadow:1px 2px 16px rgba(0,0,0,0.26);-webkit-box-shadow:1px 2px 16px rgba(0,0,0,0.26);-moz-box-shadow:1px 2px 16px rgba(0,0,0,0.26);}
.ctrack-md-modal{border-radius:10px;outline:0;box-shadow:1px 2px 16px rgba(0,0,0,0.26);-webkit-box-shadow:1px 2px 16px rgba(0,0,0,0.26);-moz-box-shadow:1px 2px 16px rgba(0,0,0,0.26);}
.ctrack-md-dialog-content{height:100%;display:inline-block;width:100%;overflow: auto;-webkit-overflow-scrolling: touch;}

.ctrack-modal-overlay{position:fixed;top:0;right:0;bottom:0;left:0;z-index:1040;background-color:rgba(0,0,0,0.3);display:none;}
.ctrack-modal-overlay-show{display:block;}
.md-filter-modal-form{display:inline-block;width:100%;padding-top:5px;}
.ctrack-modal-header,.ctrack-modal-body{display:inline-block;width:100%;}
.ctrack-modal-body{padding:20px;}
.ctrack-modal-header{padding:15px 20px;border-bottom:1px solid #d9d9d9;}
.ctrack-modal-header h4{font-size:18px;color:#013c5e;font-weight:500;}
.md-filter-form-froup{display:inline-block;width:100%;position:relative;}
.md-filter-form-lbl{display:inline-block;width:100%;color:#333333;font-weight:500;margin-bottom:8px;}
.md-selectbox-style2{display:inline-block;width:100%;position:relative;}
.ctrack-select-icon1:before{content:"";position:absolute;top:16px;right:15px;z-index:1;border:solid 5px #ffffff;border-left:solid 3px transparent;border-right:solid 3px transparent;border-bottom:transparent;-webkit-pointer-events:none;-moz-pointer-events:none;pointer-events:none;}
.ctrack-select-icon1 select{-webkit-appearance:none;-moz-appearance:none;appearance:none;}
.md-filter-modal-form .md-filter-form-froup{border-bottom:1px solid #d9d9d9;padding-bottom:10px;margin-bottom:10px;}
.md-filter-modal-form .ctrack-align-items-center .md-filter-form-lbl{margin-bottom:0;}
.md-filter-modal-form .md-filter-form-froup.ctrack-modal-btns{border:0;margin:0;padding:10px 0 5px;}
.md-btn-style1,.md-btn-style2{font-size:14px;color:#002B49;font-weight:500;padding:10px;text-align:center;min-width:85px;outline:0;background-color:#fff;border:1px solid #002B49;cursor:pointer;border-radius:5px;transition:all 0.5s ease-in-out;}
.md-btn-style1{border-color:#cce6f4;background-color:#cce6f4;}
.md-btn-style1:hover{border-color:#002B49;background-color:#fff;}
.md-btn-style2:hover{color:#fff;background-color:#002B49;}
.ctrack-text-right{text-align:right;}
.ctrack-text-left{text-align:left;}
.md-header-filter-open{max-width:380px;left:inherit;right:20px;top:140px;transform:none;margin:0;z-index: 998 !important;}
.ctrack-modal-open{overflow:hidden;}
.md-number-field{border:1px solid #EEF1F6;background-color:#EEF1F6;outline:0;border-radius:3px;padding:5px 0 5px 5px;font-size:12px !important;color:#333333;font-weight:500;display:inline-block;width:100%;vertical-align:middle;height:24px;max-width:100%}
input[type=number]::-webkit-inner-spin-button{opacity:1;}
.ctrack-md-rangeSlider{padding:5px 0 5px;}
.distanceMinDiv{margin-bottom:5px;}
.ctrack-row.ctrack-row.distanceMinDiv {padding-left: 2%;  margin: 5px 0 0;}
.distanceMinDiv .md-distance-maxRange { padding: 0;}
/* Custom Rectangle & Circle CheckBox*/
.md-rectangle-checkbox-main,.md-circle-checkbox-main{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.md-rCheck-repeater,.md-cCheck-repeater{-ms-flex-preferred-size:0;flex-basis:0;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;max-width:100%;position:relative;width:100%;min-height:1px;}
.md-rectangle-checkbox,.md-circle-checkbox{display:inline-block;width:100%;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;}
.md-rectangle-checkbox-main{margin-left:-8px;margin-right:-8px;padding: 10px 0;}
.md-rCheck-repeater{padding-left:8px;padding-right:8px}
.md-rectangle-check-text{display:inline-block;width:100%;font-size:14px;font-weight:500;background-color:#FFF;border:1px solid #999999;color:#999999;text-align:center;border-radius:5px;padding:9px;cursor:pointer;}
.md-rectangle-checkbox input[type="checkbox"], .md-rectangle-checkbox input[type="radio"],.md-circle-checkbox input[type="checkbox"]{display:none}
.md-cCheck-repeater{text-align:center;}
.md-cCheck-repeater .md-circle-checkbox{width:auto;}
.md-circle-check-text{display:inline-block;width:36px;height:36px;background-color:#FFF;border:1px solid #999999;color:#999999;border-radius:100%;line-height:36px;text-align:center;font-size:12px;font-weight:500;cursor:pointer;}
.md-circle-checkbox-lg .md-circle-check-text{width:48px;height:48px;line-height:48px;font-size:14px;}
.md-check-repeater-first{padding-right:4px;}
.md-check-repeater-first .md-circle-checkbox{padding:10px 4px 10px 0px;border-right:1px solid rgba(217,217,217,0.50);}
.md-mid-check-active.md-circle-checkbox .md-circle-check-text{background-color:#FFF;border-color:#002B49;color:#002B49;}
.md-rectangle-checkbox input[type="checkbox"]:checked ~ .md-rectangle-check-text, .md-rectangle-checkbox input[type="radio"]:checked ~ .md-rectangle-check-text, .md-circle-checkbox input[type="checkbox"]:checked ~ .md-circle-check-text{background-color:#002B49;border-color:#002B49;color:#FFFFFF;}
.md-field.search-group.md-theme-default.md-has-placeholder{width:80%;margin:0 auto;}
.md-menu-content{max-height: 35vh !important;min-height:35vh;background:#FFF;min-width:200px;max-width: 360px !important;}
.miles-filter{font-weight: normal;font-size: 12px}


.md-menu-content.md-select-menu .md-list {    
    min-width: 240px;
    min-height: 190px;
}



/* Responsive CSS */
@media only screen and (min-width: 576px) {
  .ctrack-modal-dialog-centered{min-height:calc(100% - (1.75rem * 2));}
  .ctrack-modal-dialog{max-width:500px;margin:1.75rem auto;}

}

@media only screen and (max-width: 1399px) {
  .md-dashboard-menu li{margin:0 8px;}
  .md-dashboard-menu li:last-child{margin-right:0;}

}
@media only screen and (max-width: 1299px) {
  .md-dashboard-col2{display:table;width:100%;}
  .md-dashboard-col2 .md-dashboard-menu,.md-dashboard-col2 .md-header-right{display:table-cell;}
  .md-dashboard-col2 .md-dashboard-menu{text-align:left;}
  .md-dashboard-menu li:first-child{margin-left:0;}
  .md-header-links:before{display:none;}
  .md-header-filter-open{right: 30px;top: 170px;}

}
@media only screen and (max-width: 1199px) {
  .md-header-links{padding:0 10px;margin:0 10px;}
  .md-header-links:before{display:block;}

}
@media only screen and (max-width: 1024px) {
  .md-header-links:before{display:none;}

}
@media only screen and (max-width: 991px) {
  .md-header-links:before{display:block;}
  .md-dashboard-menu li{margin:0 8px;}
  .md-header-links a{width:35px;height:35px;line-height:35px;}
  .md-dashboard-container{padding:0 20px;}

}
@media only screen and (max-width: 767px) {
  .md-dashboard-container{padding:0 15px;}
  .md-dashboard-col2{padding:5px 0;}
  .md-dashboard-col2 .md-dashboard-menu,.md-dashboard-col2 .md-header-right,.md-dashboard-col1{display:inline-block;width:100%;text-align:center;}
  .md-header-right{margin-top:15px;}
  .md-dashboard-menu li{margin:0 6px;}
  .md-header-links{margin-left:0;padding-left:0;}
  .md-header-links:before{display:none;}
  .md-header-links a{width:25px;height:25px;line-height:25px;margin:0 3px;}
  .md-dashboard-col1{border:solid 1px #c5cedc;padding:2px;border-radius:2px;margin-bottom:5px;background-color:#fff;}
  .md-filter-modal-form .ctrack-align-items-center .md-filter-form-lbl{margin-bottom:8px;}
  .md-header-filter-open{right:initial;top:50%;left:50%;transform:translate(-50%,-50%);}

  .ctrack-filterRange{padding-top:40px;width:100%;padding:40px 15px 0;}
  .ctrack-md-rangeSlider{padding-top:10px;}
  .md-filter-modal-form .ctrack-md-rangeSlider .ctrack-align-items-center .md-filter-form-lbl{margin-bottom:0;}
  .md-rectangle-checkbox-main{padding:0;}
  .md-check-repeater-first .md-circle-checkbox{padding:5px 4px 5px 0px;}
  .md-number-field{height: 30px;padding-left:10px}


}
@media only screen and (max-width: 575px) {
  .md-dashboard-menu li{margin:0 5px;}
  .md-dialog-fullscreen{max-width:100%;max-height:100%;position:fixed;top:0;right:0;bottom:0;left:0;border-radius:0;transform:none;}
  .md-circle-checkbox-main{padding-left:60px;position:relative;}
  .md-check-repeater-first{position:absolute;left:0;width:auto;top:50%;transform:translateY(-50%);padding:0;}
  .md-cCheck-repeater{padding:3px 5px;-webkit-box-flex:0;-ms-flex-positive:0;flex-grow:0;}



}


</style>
<style>
/*Filter */
.md-selectbox-style2 .md-input{font-size:14px !important;-webkit-text-fill-color:#FFF !important;color:#fff !important;font-weight:500;background-color:#002B49 !important;border-radius:5px;outline:0;border:0;padding:10px 30px 10px 10px;width:100%;display:inline-block;font-family:"Roboto",sans-serif;height: 40px;}
.md-filter-form-froup .md-field{min-height:initial;margin:0;padding:0;}
.md-filter-form-froup .md-field:before,.md-filter-form-froup .md-field:after, .md-filter-form-froup .md-field .md-icon, .md-selectbox-style2 .md-icon{display:none;}
.md-filter-form-froup .ctrack-select-white .md-input{-webkit-text-fill-color:#FFF !important}
.md-filter-labels{display:inline-block;width:100%;}
.md-filter-label-items{display:inline-block;position:relative;padding:3px 30px 3px 10px;background-color:#E1E1E1;color:#8F8F8F;font-size:12px;font-weight:500;border-radius:3px;margin:10px 10px 0 0;}
.md-filter-label-close{position:absolute;right:10px;top:7px;cursor:pointer;transition:all .5s ease;}
.md-filter-label-close svg{display:block;}
.md-filter-label-close:hover{color:#ff242d;}
.md-filter-selectMenu.md-menu-content{max-height: 35vh !important;min-height:100px;background:#FFF;min-width:360px;max-width: 360px !important; right: 30px !important;left: initial !important;}
.md-list-item-text{white-space:normal;}
.md-selectbox-style2 .md-list-item-text{color:#000000de}
.md-selectbox-style2 .md-input::placeholder{color:#fff !important;font-weight: 500 !important;font-family:"Roboto",sans-serif;}
.md-selectbox-style2 .md-input:-ms-input-placeholder{color:#fff !important;font-weight: 500 !important;font-family:"Roboto",sans-serif;}
.md-selectbox-style2 .md-input::-ms-input-placeholder{color:#fff !important;font-weight: 500 !important;font-family:"Roboto",sans-serif;}
.md-selectbox-style2 .md-field.md-theme-default.md-has-value .md-input {-webkit-text-fill-color: #FFF !important;}
.ctrack-filterRange{display:block;width:84%;margin:55px auto 0;}
.md-pl-0{padding-left: 0;}
.md-list-item-content{padding:4px 5px;}
.md-field.search-group.md-theme-default.md-has-placeholder:before{display:none;}
.md-select-menu.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-filter-selectMenu.md-theme-default {
    z-index: 99999999 !important;
}

@media only screen and (max-width: 575px) {
.md-filter-selectMenu.md-menu-content{max-width: 85% !important;}
}
</style>