<template>
    <td :class="className">
        <div :class="{ gridLink : editor }" @click="clickHandler">
            <!-- Display Initials Has Display, no image -->
            <span v-if="dataItem[cellValue.displayField]"
                  v-show="!dataItem[cellValue.imageField]"
                  class="circleShape">
                {{dataItem[cellValue.displayField] | nameInitials}}
            </span>
            <!-- Display image - Has Display Has Image -->
            <span v-if="dataItem[cellValue.displayField]" v-show="dataItem[cellValue.imageField]" class="circleShape">
                <img :src="imageToDisplay" />
            </span>
            <!-- Dont know - Has display -->
            <span v-if="dataItem[cellValue.displayField]" v-show="dataItem[cellValue.displayField]" class="cricle-displayname">
                {{dataItem[cellValue.displayField]}}
            </span>
        </div>
    </td>
</template>
<script>
    export default {
        name: "ImageCell",
        data() {
            return {}
        },
        props: {
            field: String,
            dataItem: Object,
            format: String,
            className: String,
            columnIndex: Number,
            columnsCount: Number,
            rowType: String,
            level: Number,
            expanded: Boolean,
            editor: String
        },
        computed: {
            cellValue() {
                var fields = this.format.split(",");
                return {
                    displayField: fields[0],
                    imageField: fields[1]
                };
            },
            imageToDisplay() {
                return this.$getBlobStorageImage(this.dataItem[this.cellValue.imageField])
            },
        },
        methods: {
            clickHandler() {
                if (this.editor !== undefined) {
                    this.$emit(this.editor, this.dataItem);
                }
            }
        },
        filters: {
            nameInitials: function (value) {
                var names = value.split(" "),
                    initials = names[0].substring(0, 1).toUpperCase();
                if (names.length > 1) {
                    initials += names[names.length - 1].substring(0, 1).toUpperCase();
                }
                return initials;
            }
        }
    };
</script>
<style scoped>
    .circleShape img {
        border-radius: 50%;
    }

    .gridLink {
        color: #002B49;
        cursor: pointer;
        text-decoration: underline;
        text-decoration-color: #002B49;
        text-shadow: 0 0 1px #40C1AC;
    }

        .gridLink:hover {
            color: #29897d;
        }
</style>