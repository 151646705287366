import { VideoService } from "../service";

const state = () => ({})

const getters = {}

const actions = {
    add({ commit }, payload) {
        return VideoService.Add(
            payload.deviceId,
            payload.status,
            payload.eventText,
            payload.latitude,
            payload.longitude,
            payload.eventTime
        ).then((result) => {
            return result;
        });
    },
    retry({ commit }, payload) {
        return VideoService.Retry(payload.requestId);
    },
    getDownloadUrl({ commit }, payload) {
        return VideoService.GetDownloadUrl(payload.requestId);
    },
    getImages({ commit }, payload) {
        return VideoService.GetImages(payload.requestId);
    },
    getVideoUrl({ commit }, payload) {
        return VideoService.GetVideoUrl(payload.requestId);
    },
    getRangeByDevice({ commit }, payload) {
        return VideoService.GetRangeByDevice(payload.id, payload.startDate, payload.endDate);
    },
    getAssignment({ commit }, payload) {
        return VideoService.GetAssignment(payload.id);
    },
    getLive({ commit }, payload) {
        return VideoService.GetLive(payload.deviceId);
    }
}

const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
