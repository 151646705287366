<template>
  <div class="DistanceTravelWidget ctrack-h-100" ref="DistanceTravel">
    <div id="vueapp" class="vue-app ctrack-h-100">

      <!-- Distance Travel Widget -->
      <div class="dBehaviour-inner" :class="widgetColorClass">        
        <div class="dBehaviour-box-col">
          <div class="dBehaviour-box-icon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="40" height="33" viewBox="0 0 40 33"><path d="M130.729,685.461h-15.46a3.734,3.734,0,0,1-7.27-1.269,3.734,3.734,0,0,1,7.27-1.27h15.46a3.809,3.809,0,1,1,0,2.538Zm2.591-8.037c-.8-.906-7.82-8.982-7.82-13.54a8.752,8.752,0,1,1,17.5,0c0,4.558-7.021,12.633-7.822,13.54a1.236,1.236,0,0,1-1.859,0Zm-2.82-13.54a3.751,3.751,0,1,0,3.751-3.809A3.781,3.781,0,0,0,130.5,663.884Zm-19.68,13.54c-.8-.906-7.82-8.982-7.82-13.54a8.751,8.751,0,1,1,17.5,0c0,4.558-7.02,12.633-7.82,13.54a1.236,1.236,0,0,1-1.859,0ZM108,663.884a3.751,3.751,0,1,0,3.751-3.809A3.78,3.78,0,0,0,108,663.884Z" transform="translate(-102.999 -654.998)" /></svg></div>
          <div class="dBehaviour-box-content">
            <h3>
              <span class="dBehaviour-box-value-icon" :class="widgetIcon"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10"><path d="M638.392,193H628.62a.626.626,0,0,1-.527-.962c.213-.383,4.509-8.038,4.876-8.691a.6.6,0,0,1,1.059,0c.269.47,4.587,8.154,4.887,8.711A.623.623,0,0,1,638.392,193Z" transform="translate(-628 -183.028)" /></svg></span>{{widgetValue}}
            </h3>
            <p class="ctrack-text-truncate">{{widgetTitle}}</p>
          </div>
        </div>        
      </div>
      <!-- Distance Travel Widget -->

    </div>    
  </div>
</template>

<script>
export default {
  name: 'distancetravelwidget',
  components: {},
  data: function() {
        return {}
  },  
  created(){
      this.getDistanceTravelWidgetData();        
  },
  methods: {
      getDistanceTravelWidgetData(){
          let self = this;
          const returnResult = {
              title: "Distance Travel",
              value: "15",
              type:"%",
              icon: "down",
              color: "green"
          };
          
          self.widgetTitle = returnResult.title;
          self.widgetValue = returnResult.value + returnResult.type;
          self.widgetIcon =  (returnResult.icon == "down") ? "md-chart-arrow-down" : "md-chart-arrow-up";
          self.widgetColorClass = (returnResult.color == "green") ? "success-color" : "error-color";
      }
  }
};

</script>
<style scoped>
/* Custom CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
*{font-family: 'Roboto', sans-serif;}
.ctrack-h-100{height: 100%;}
.error-color .dBehaviour-box-col h3{color:#FA4B4B;}
.success-color .dBehaviour-box-col h3{color:#76CD24;}
[class*="md-chart-arrow"]{display:inline-block;vertical-align:middle;}
[class*="md-chart-arrow"] svg{display:block;}
.md-chart-arrow-down{transform:rotate(180deg); -webkit-transform:rotate(180deg); -moz-transform:rotate(180deg); -ms-transform:rotate(180deg); -o-transform:rotate(180deg);}
.ctrack-text-truncate{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}

.dBehaviour-inner{display:inline-block;width:100%;height:100%;background-color:#FFF;padding:13px 20px;border-radius: 5px; box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, .05); -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, .05);}
.dBehaviour-box-row{display:inline-block;width:100%;padding-left:40px;position:relative;}
.dBehaviour-box-col{display:inline-block;vertical-align: middle;position:relative;width:100%;text-align:right;padding-left:40px;}
.dBehaviour-box-icon{position:absolute;color:#656565; left:0;top:50%;transform:translateY(-50%); -webkit-transform:translateY(-50%); -moz-transform:translateY(-50%); -ms-transform:translateY(-50%); -o-transform:translateY(-50%);}
.dBehaviour-box-content{display:block;width:100%;padding-left:5px;}
.dBehaviour-box-content h3{font-size:20px;font-weight:500;}
.dBehaviour-box-value-icon{margin-right:8px;}
.dBehaviour-box-content p{font-size:15px;color:#333333;font-weight:500;}


/* Responsive CSS */
@media only screen and (min-width:1200px){}
@media only screen and (min-width:1300px){}
@media only screen and (min-width:1400px){}
@media only screen and (max-width:1399px){}
@media only screen and (max-width:1299px){}
@media only screen and (max-width:1199px){}
@media only screen and (max-width:1024px){}
@media only screen and (max-width:991px){}
@media only screen and (max-width:767px){}
@media only screen and (max-width:575px){}

</style>
