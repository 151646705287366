<template>
    <!-- eslint-disable -->
    <div class="logoWithSearch" data-app>
        <div class="childHeader" v-show="!mini">
            <div class="logo" style="cursor:pointer">
                <img style="height:68px" src="../../assets/images/crystal/logo-white.png" @click="gotoLandingPage()">
            </div>
        </div>
        <v-btn v-if="$checkRBACAccess('globalSearchRead')" dark icon class="searchIcon" @click="showSearch()">
            <v-icon>search</v-icon>
        </v-btn>
        <v-slide-x-transition>
            <v-combobox placeholder="Start typing to Search"
                        class="searchBox included"
                        :menu-props="{
                            contentClass: 'searchBox-content',
                            closeOnContentClick: true
                        }"
                        v-show="show"
                        :items="searchResults"
                        label="Search"
                        :search-input.sync="searchQuery"
                        no-filter solo flat hide-details>
                <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                        <v-list-item v-if="data.item.component === 'outsideOfGroup'"
                                     @click="return false">
                            <v-list-item-content>
                                <outsideOfGroup></outsideOfGroup>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-else
                                     @click="onClick(data.item)"
                                     style="padding-left: 30px">
                            <v-list-item-content>
                                <v-list-item-title>{{data.item.firstHeading}}</v-list-item-title>
                                <v-list-item-subtitle>{{data.item.firstSubtext}}</v-list-item-subtitle>
                                <v-list-item-subtitle>{{data.item.firstSubtext2}}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-content v-show="mini === false">
                                <v-list-item-title>{{data.item.secondHeading}}</v-list-item-title>
                                <v-list-item-subtitle>{{data.item.secondSubtext}}</v-list-item-subtitle>
                                <v-list-item-subtitle>{{data.item.secondSubtext2}}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-content v-show="mini === false">
                                <v-list-item-title>{{data.item.thirdHeading}}</v-list-item-title>
                                <v-list-item-subtitle>{{data.item.thirdSubtext}}</v-list-item-subtitle>
                                <v-list-item-subtitle>{{data.item.thirdSubtext2}}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <component :is="data.item.component" v-model="data.item" v-on:toggleSearchItems="toggleSearchItems(false)"></component>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                </template>
            </v-combobox>
        </v-slide-x-transition>
        <v-menu min-width="350" max-width="515" offset-y left content-class="filterMenu" :close-on-content-click="closeOnContentClick" style="z-index: 11">
            <template v-slot:activator="{ on, attrs }">
                <v-slide-x-transition>
                    <v-badge color="primaryDot"
                             v-show="show"
                             :value="isFiltering"
                             dot>
                        <v-btn icon v-show="show" v-on="on" class="filterIcon">
                            <v-icon>filter_alt</v-icon>
                        </v-btn>
                    </v-badge>     
                </v-slide-x-transition>
            </template>
            <v-list>
                <v-list-item>
                    <v-btn text outlined block @click="menuDefaults()">All/None</v-btn>
                </v-list-item>
                <v-row no-gutters>
                    <v-col v-for="(item, index) in items"
                           :key="index"
                           cols="12"
                           sm="4">
                        <v-list-item>
                            <v-list-item-icon>
                                <kendo-switch @change="filterSearch(item)" v-model="item.search"></kendo-switch>
                            </v-list-item-icon>
                            <v-list-item-content class="filterMenuItems">
                                <v-list-item-title>{{ item.text }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                </v-row>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
    import _ from "lodash";
    import mapAddress from "../../components/quicklinks/address"
    import contact from "../../components/quicklinks/contact"
    import device from "../../components/quicklinks/device"
    import driver from "../../components/quicklinks/driver"
    import instalation from "../../components/quicklinks/instalation"
    import job from "../../components/quicklinks/job"
    import location from "../../components/quicklinks/location"
    import user from "../../components/quicklinks/user"
    import vehicle from "../../components/quicklinks/vehicle"
    import vehicleStatus from "../../components/quicklinks/vehicle-status"
    import outsideOfGroup from "../../components/quicklinks/outsideOfGroup"

    export default {
        components: {
            mapAddress,
            contact,
            device,
            driver,
            instalation,
            job,
            location,
            user,
            vehicle,
            vehicleStatus,
            outsideOfGroup
        },
        props: {
            persistedItems: Array,
            getDefaults: Function,
            persist: Function,
            searchResults: Array,
        },
        data() {
            return {
                typing: true,
                searchQuery: "",
                searchText: "Test",
                focused: false,
                clickType: "row",
                miniSearch: false,
                show: false,
                filter: false,
                searchBarWidth: null,
                closeOnContentClick: false,
                toggleSearchItemsBoolean: false,
                searchObject: {
                    id: null,
                    component: null
                },
            };
        },
        computed: {
            searchBoxSize() {
                return 'max-width: ' + this.searchBarWidth + 'px !important';
            },
            mini() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return true
                    case 'sm': return true
                    case 'md': return false
                    case 'lg': return false
                    case 'xl': return false
                }
            },
            items() {
                let searchOptions = []

                if (this.showSearchOption('vehiclesRead')) { searchOptions.push({ title: 'Vehicles', text: this.$t('ReportGeneration.assets'), search: false }) }
                if (this.showSearchOption('vehiclesRead')) { searchOptions.push({ title: 'Devices', text: this.$t('Dashboard.devices'), search: false }) }
                if (this.showSearchOption('jobsRead')) { searchOptions.push({ title: 'Jobs', text: this.$t('Dashboard.jobs'), search: false }) }
                if (this.showSearchOption('vehiclesRead')) { searchOptions.push({ title: 'Vehiclestatus', text: this.$t('Dashboard.assetStatus'), search: false }) }
                if (this.showSearchOption('vehiclesRead')) { searchOptions.push({ title: 'Installations', text: this.$t('Dashboard.installations'), search: false }) }
                if (this.showSearchOption('locationRead')) { searchOptions.push({ title: 'Locations', text: this.$t('Dashboard.locations'), search: false }) }
                if (this.showSearchOption('contactRead')) { searchOptions.push({ title: 'Contacts', text: this.$t('Dashboard.contact'), search: false }) }
                if (this.showSearchOption('userRead')) { searchOptions.push({ title: 'Users', text: this.$t('Dashboard.users'), search: false }) }
                if (this.showSearchOption('driversRead')) { searchOptions.push({ title: 'Drivers', text: this.$t('ReportGeneration.operators'), search: false }) }

                searchOptions.push({ title: 'Address', text: 'Address', search: false })

                return searchOptions
            },
            isFiltering() {
                return this.items.length != this.items.filter(i => i.search == true).length && this.items.filter(i => i.search == true).length != 0
            },
            groups() {
                return this.$groupsFilterStore.groups;
            },
        },
        created() {
            this.loadPersistedItems();
        },
        methods: {
            loadPersistedItems() {
                if (this.persistedItems.length > 0) {
                    for (let i = 0; i < this.items.length; i++) {
                        this.items[i].search = this.persistedItems.includes(this.items[i].title)
                    }
                } else {
                    for (let i = 0; i < this.items.length; i++) {
                        this.items[i].search = true;
                    }
                }
                this.$emit("update-items", this.items);
            },
            showSearchOption(claim) {
                return this.$checkRBACAccess(claim);
            },
            gotoLandingPage() {
                this.$insights.trackEvent('Menu Bar', 'Crystal Icon Click');
                this.$router.push(this.$getLandingPage());
            },
            showSearch() {
                this.show = !this.show;
                if (this.show == true) {
                    this.$insights.trackEvent('Menu Bar', 'Search Click');
                };
            },
            showFilterMenu() {
                this.filter = !this.filter;
            },
            filterSearch(item) {
                item.search = !item.search;
                this.$emit("update-items", this.items);
                if (this.persist !== undefined) {
                    this.persist();
                }
                this.$emit("onInputChange", this.searchQuery);
            },
            complete(i) {
                if (i == undefined) {
                    for (let row of this.data) {
                        if (this.filter(row)) {
                            this.select(row);
                            return;
                        }
                    }
                }
                this.select(this.data[i]);
            },
            select(row) {
                row["selectedButton"] = this.clickType;
                this.$emit("selected", row);
                this.selected = true;
                this.focus(false);
            },
            focus(f) {
                this.focused = f;
            },
            onInput() {
                this.typing = true;
            },
            onClick(item) {
                if (item.component == "mapAddress") {
                    if (this.$route.name === "livemapRead") {
                        this.$livemapEventHub.$emit("display-address", item);
                    } else {
                        this.$router.push('/dashboard/livemap').then((results) => {
                            this.$livemapEventHub.$emit("display-address", item);
                        });
                    }
                }
                else {
                    this.searchObject = item;
                    this.$drawerEventHub.$emit("open-drawer", this.searchObject);
                }
            },
            hidePanel(status) {
                this.showDrawer = status;
            },
            getUniqueKey(id) {
                return id + "_" + Date.now();
            },
            menuDefaults() {
                this.$emit("update:items", this.getDefaults());
                this.$emit("onInputChange", this.searchQuery);
            },
            toggleSearchItems(bool) {
                if (bool !== null) {
                    this.toggleSearchItemsBoolean = bool
                } else {
                    this.toggleSearchItemsBoolean = !this.toggleSearchItemsBoolean
                }
            },
            runSearch() {
                this.typing = false;
                if (this.searchQuery && this.searchQuery.length > 2) {
                    this.$emit("onInputChange", this.searchQuery);
                } else if (!this.searchQuery) {
                    this.$emit("onInputChange", this.searchQuery);
                }
            },
        },
        watch: {
            searchQuery: _.debounce(function () {
                this.runSearch();
            }, 800),
            groups:
            {
                handler(value, oldvalue) {
                    this.runSearch();
                }
            },
        }
    };
</script>
<style>
    .searchBox-content {
        width: 100% !important;
        left: 10% !important;
        top: 80px !important;
    }
</style>
<style scoped>
    .icons {
        max-width: 120px;
    }
    .autoSuggestContainer {
        background: #fff;
        position: absolute;
        top: 51px;
        z-index: 999;
        right: 0;
        left: 0;
        color: #666;
        border-top: 1px solid #448aff;
    }

        .autoSuggestContainer table tr {
            cursor: pointer;
        }

        .autoSuggestContainer table {
            width: 100%;
            border: none;
        }

            .autoSuggestContainer table tr td {
                padding: 10px 10px 0 10px;
            }

            .autoSuggestContainer table tr:nth-child(even) {
                background: #f1f1f1;
            }

            .autoSuggestContainer table tr:hover {
                background: #448aff;
                color: #fff !important;
            }

    .childHeader {
        display: flex;
        align-items: center;
    }

    .searchNotShow {
        display: none;
    }

    .searchIcon {
        margin-right: 20px;
        margin-bottom: 20px;
    }

    .filterIcon {
        margin-left: -45px;
        margin-top: 16px;
    }

    .searchBox {
        background-color: white !important;
        height: 50px;
        border-radius: 10px;
        padding-right: 50px;
        padding-left: 10px;
        max-width: 100%;
        margin-top: 9px;
    }


    .filterMenu {
        top: 70px !important;
    }

    .filterMenuItems {
        margin-left: 10px;
    }

    .setToDefault {
        color: #002B49;
        background-color: rgb(255, 255, 255);
        border: 2px solid #002B49;
        width: 96%;
        margin-left: 2%;
    }

    .searchBoxContent {
        max-width: 100% !important;
        width: 100% !important;
    }
    ::v-deep .primaryDot {
        background-color: #40C1AC;
        inset: auto auto calc(100% - 24px) calc(100% - 18px) !important;
    }
</style>
