<template>
    <v-dialog v-model="value"
              max-width="450"
              @click:outside="closeDialog">

        <v-card class="v-application">
            <v-card-title>
                {{title}}
            </v-card-title>
            <v-card-text>
                {{content}}
            </v-card-text>
            <v-card-actions>
                <v-spacer>
                </v-spacer>
                <v-btn color="primary" @click="$emit('confirm')">
                    {{confirmText}}
                </v-btn>
                &nbsp;
                <v-btn color="secondary" @click="closeDialog(); $emit('close')">
                    {{cancelText}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'ConfirmDialog',
        components: {
        },
        props: {
            value: Boolean,
            title: String,
            content: String,
            confirmText: String,
            cancelText: String
        },
        data() {
            return {
            }
        },
        created() {
        },
        methods: {
            closeDialog() {
                this.$emit('input', false);
            }
        }
    }

</script>